import sandbox from 'sandbox';
import React from 'react';
import { utilities, Rect } from 'components/common/canvas';

const { async } = sandbox;

const isUndefined = o => typeof o === 'undefined';
const deg2rad = deg => deg * Math.PI / 180;
const rad2deg = rad => rad * 180 / Math.PI;
const compare = (a, b) => {
  return a < b ? -1 :
    a > b ? 1 :
      0;
};
const getTopLeftPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[0];
};
const getBottomRightPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[points.length - 1];
};

export const loadImage = url => {
  const image = new Image();
  return new Promise((resolve, reject) => {
    image.src = url;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = reject;
  });
};

export const getData = url => {
  const dfd = async.deferred();
  const baseData = require('base/data');

  // request = baseData.ajax({
  //   url: webAppPath + '/servlet/GenericCommandServlet',
  //   type: 'GET',
  //   data: requestParams
  // });  
  const request = baseData.ajax({
    url: url,
  });
  request.done(function (data) {
    dfd.resolve(data);
  });
  return dfd.promise();
}

export const loadFlipbookImage = (url, id, ref, isPano, pageNumber) => {
  const dfd = async.deferred();
  const image = new Image();
  image.id = id;
  image.ref = ref;
  image.isPano = isPano;
  image.pageNumber = pageNumber;
  image.onload = function () {
    dfd.resolve(image);
  };
  image.onerror = dfd.reject;
  image.src = url;
  return dfd.promise();
};

export const rgb2cmyk = (r, g, b) => {
  var computedC = 0;
  var computedM = 0;
  var computedY = 0;
  var computedK = 0;

  //remove spaces from input RGB values, convert to int
  var r = parseInt(('' + r).replace(/\s/g, ''), 10);
  var g = parseInt(('' + g).replace(/\s/g, ''), 10);
  var b = parseInt(('' + b).replace(/\s/g, ''), 10);

  if (r == null || g == null || b == null ||
    isNaN(r) || isNaN(g) || isNaN(b)) {
    //console.log ('Please enter numeric RGB values!');
    return;
  }
  if (r < 0 || g < 0 || b < 0 || r > 255 || g > 255 || b > 255) {
    //console.log ('RGB values must be in the range 0 to 255.');
    return;
  }

  // BLACK
  if (r == 0 && g == 0 && b == 0) {
    computedK = 1;
    return [0, 0, 0, 1];
  }

  computedC = 1 - (r / 255);
  computedM = 1 - (g / 255);
  computedY = 1 - (b / 255);

  var minCMY = Math.min(computedC,
    Math.min(computedM, computedY));
  computedC = (computedC - minCMY) / (1 - minCMY);
  computedM = (computedM - minCMY) / (1 - minCMY);
  computedY = (computedY - minCMY) / (1 - minCMY);
  computedK = minCMY;

  return [computedC, computedM, computedY, computedK];
}

export const getGraySource = (color, image, documentRef) => {
  const canvas_t = documentRef.createElement('canvas');
  const ctx_t = canvas_t.getContext("2d");
  canvas_t.width = image.width;
  canvas_t.height = image.height;
  ctx_t.drawImage(image, 0, 0);
  const data_x = ctx_t.getImageData(0, 0, image.width, image.height);

  //console.log('windowRef == window =>', (windowRef == window));

  // do not convert to black in Internet Explorer because pixel operations are very slow in IE
  // when performed in the child window
  // if (!browser.isIE()) {
  const t1 = performance.now();

  const length = data_x.data.length;

  var i;
  switch (color) {
    case 'cyan':
      for (i = 0; i < length; i += 4) {
        data_x.data[i + 1] = data_x.data[i + 2] = data_x.data[i];
      }
      break;
    case 'magenta':
      for (i = 0; i < length; i += 4) {
        data_x.data[i] = data_x.data[i + 2] = data_x.data[i + 1];
      }
      break;
    case 'yellow':
      for (i = 0; i < length; i += 4) {
        data_x.data[i] = data_x.data[i + 1] = data_x.data[i + 2];
      }
      break;
    default:
      for (i = 0; i < length; i += 4) {
        const avg = (data_x.data[i] + data_x.data[i + 1] + data_x.data[i + 2]) / 3;
        data_x.data[i] = avg;
        data_x.data[i + 1] = avg;
        data_x.data[i + 2] = avg;
      }
      break;
  }
  const t2 = performance.now();
  // }
  ctx_t.putImageData(data_x, 0, 0);
  return canvas_t.toDataURL("image/png");
}

function getMergeColor(k, c, m, y, t) {
  var mergeColor = t;
  if (k) {
    mergeColor += 1;
  }
  if (c) {
    mergeColor += 2;
  }
  if (m) {
    mergeColor += 4;
  }
  if (y) {
    mergeColor += 8;
  }
  return mergeColor;
}

//return the source of the merged image
export const mergeImages = (imagesToMerge, documentRef, grayMerge) => {
  //no images passed get out now
  if (!imagesToMerge || imagesToMerge.length === 0) {
    return null;
  }

  const canvas_t = documentRef.createElement('canvas');
  const ctx_t = canvas_t.getContext("2d");
  canvas_t.width = imagesToMerge[0].image.width;
  canvas_t.height = imagesToMerge[0].image.height;

  //only 1 image so no need to merge
  if ((imagesToMerge.length === 1 && !grayMerge)) {
    return imagesToMerge[0].image.src;
  }

  imagesToMerge.forEach(img => {
    if (!img.data) {
      ctx_t.drawImage(img.image, 0, 0);
      img.data = ctx_t.getImageData(0, 0, img.image.width, img.image.height).data;
    }
  })

  let mergedImage = ctx_t.getImageData(0, 0, canvas_t.width, canvas_t.height);
  const bytesLength = mergedImage.data.length;
  const numSeps = imagesToMerge.length;
  for (let i = 0; i < bytesLength; i++) {
    let sum = 0;
    for (let j = 0; j < numSeps; j++) {
      sum += grayMerge && imagesToMerge[j].key === 'black' ? (255 - imagesToMerge[j].data[i]) / 2 : 255 - imagesToMerge[j].data[i];
    }
    mergedImage.data[i] = Math.max(255 - sum, 0);
  }

  ctx_t.putImageData(mergedImage, 0, 0);
  return canvas_t.toDataURL("image/png");
}

export const mergeImagesHires = (imagesToMerge, documentRef, grayMerge) => {
  //no images passed get out now
  if (!imagesToMerge || imagesToMerge.length === 0) {
    return null;
  }

  const canvas_t = documentRef.createElement('canvas');
  const ctx_t = canvas_t.getContext("2d");
  canvas_t.width = imagesToMerge[0].image.width;
  canvas_t.height = imagesToMerge[0].image.height;

  imagesToMerge.forEach(img => {
    ctx_t.drawImage(img.image, 0, 0);
    img.data = ctx_t.getImageData(0, 0, img.image.width, img.image.height).data;
  })

  const mappedImagesToMerge = imagesToMerge.reduce((acc, image) => {
    acc[image.key] = image;
    return acc;
  }, {})

  let mergedImage = ctx_t.getImageData(0, 0, canvas_t.width, canvas_t.height);
  const bytesLength = mergedImage.data.length;
  const cyan = mappedImagesToMerge['cyan'];
  const magenta = mappedImagesToMerge['magenta'];
  const yellow = mappedImagesToMerge['yellow'];
  const black = mappedImagesToMerge['black'];
  for (let i = 0; i < bytesLength; i += 4) {
    let c = 0;
    let m = 0;
    let y = 0;
    let k = 0;

    if (black && black.data[i] === 0) {
      k = 255;
    }

    if (k === 255) {
      if (grayMerge) {
        c = m = y = 128;
      } else {
        c = m = y = 255;
      }
    } else {
      if (cyan && cyan.data[i] === 0) {
        c = 255;
      }

      if (magenta && magenta.data[i] === 0) {
        m = 255;
      }

      if (yellow && yellow.data[i] === 0) {
        y = 255;
      }
    }

    mergedImage.data[i] = 255 - c;
    mergedImage.data[i + 1] = 255 - m;
    mergedImage.data[i + 2] = 255 - y;
  }

  ctx_t.putImageData(mergedImage, 0, 0);
  return canvas_t.toDataURL("image/png");
}

export const getViewportPoints = (image, flipHorizontal, flipVertical, rotation, zoom, offsetPoint, mainCanvasWidth, mainCanvasHeight, mainCanvasInstance) => {
  if (isUndefined(image) || isUndefined(image.width) || isUndefined(image.height) || isUndefined(mainCanvasInstance)) {
    return;
  }

  const flipHorizontalValue = !!flipHorizontal ? -1 : 1;
  const flipVerticalValue = !!flipVertical ? -1 : 1;
  const canvasBoundingClientRect = mainCanvasInstance.getBoundingClientRect();
  const canvasHalfWidth = mainCanvasWidth / 2;
  const canvasHalfHeight = mainCanvasHeight / 2;
  const topLeftPointOnCanvas = mainCanvasInstance.canvasUtilities.getCanvasByScreenPoint(
    canvasBoundingClientRect.left - offsetPoint.x,
    canvasBoundingClientRect.top - offsetPoint.y
  );
  const bottomRightPointOnCanvas = mainCanvasInstance.canvasUtilities.getCanvasByScreenPoint(
    canvasBoundingClientRect.left + mainCanvasWidth - offsetPoint.x,
    canvasBoundingClientRect.top + mainCanvasHeight - offsetPoint.y
  );

  const mainCanvasTopLeftPoint = utilities.getPointByRotation({
    x: topLeftPointOnCanvas.x / zoom * flipHorizontalValue,
    y: topLeftPointOnCanvas.y / zoom * flipVerticalValue
  }, { x: 0, y: 0 }, -rotation);
  const mainCanvasBottomRightPoint = utilities.getPointByRotation({
    x: bottomRightPointOnCanvas.x / zoom * flipHorizontalValue,
    y: bottomRightPointOnCanvas.y / zoom * flipVerticalValue
  }, { x: 0, y: 0 }, -rotation);

  const imageHalfWidth = image.width / 2;
  const imageHalfHeight = image.height / 2;

  const imagePointA = { x: -imageHalfWidth * flipHorizontalValue, y: -imageHalfHeight * flipVerticalValue };
  const imagePointB = { x: imageHalfWidth * flipHorizontalValue, y: -imageHalfHeight * flipVerticalValue };
  const imagePointC = { x: imageHalfWidth * flipHorizontalValue, y: imageHalfHeight * flipVerticalValue };
  const imagePointD = { x: -imageHalfWidth * flipHorizontalValue, y: imageHalfHeight * flipVerticalValue };
  const pointA = utilities.getPointByRotation(imagePointA, { x: 0, y: 0 }, rotation);
  const pointB = utilities.getPointByRotation(imagePointB, { x: 0, y: 0 }, rotation);
  const pointC = utilities.getPointByRotation(imagePointC, { x: 0, y: 0 }, rotation);
  const pointD = utilities.getPointByRotation(imagePointD, { x: 0, y: 0 }, rotation);

  const calculatedTopLeftPoint = getTopLeftPoint(pointA, pointB, pointC, pointD);
  const calculatedTopLeftPointOnStage = utilities.getPointByRotation(calculatedTopLeftPoint, { x: 0, y: 0 }, -rotation);

  const calculatedBottomRightPoint = getBottomRightPoint(pointA, pointB, pointC, pointD);
  const calculatedBottomRightPointOnStage = utilities.getPointByRotation(calculatedBottomRightPoint, {
    x: 0,
    y: 0
  }, -rotation);

  const viewportTopLeft = {
    x: Math.abs(calculatedTopLeftPointOnStage.x) < Math.abs(mainCanvasTopLeftPoint.x) ? calculatedTopLeftPointOnStage.x * flipHorizontalValue : mainCanvasTopLeftPoint.x,
    y: Math.abs(calculatedTopLeftPointOnStage.y) < Math.abs(mainCanvasTopLeftPoint.y) ? calculatedTopLeftPointOnStage.y * flipVerticalValue : mainCanvasTopLeftPoint.y
  };

  const viewportBottomRight = {
    x: Math.abs(calculatedBottomRightPointOnStage.x) < Math.abs(mainCanvasBottomRightPoint.x) ? calculatedBottomRightPointOnStage.x * flipHorizontalValue : mainCanvasBottomRightPoint.x,
    y: Math.abs(calculatedBottomRightPointOnStage.y) < Math.abs(mainCanvasBottomRightPoint.y) ? calculatedBottomRightPointOnStage.y * flipVerticalValue : mainCanvasBottomRightPoint.y
  };

  const width = viewportBottomRight.x - viewportTopLeft.x;
  const height = viewportBottomRight.y - viewportTopLeft.y;

  const viewportTopRight = {
    x: viewportTopLeft.x + width,
    y: viewportTopLeft.y
  };

  const viewportBottomLeft = {
    x: viewportBottomRight.x - width,
    y: viewportBottomRight.y
  };

  return {
    topLeft: viewportTopLeft,
    topRight: viewportTopRight,
    bottomLeft: viewportBottomLeft,
    bottomRight: viewportBottomRight,
    viewportWidth: width,
    viewportHeight: height,
    imageWidth: image.width,
    imageHeight: image.height
  }
};


function getNavFitToContentZoom1(image, rotation, mainCanvasInstance) {
  // const { image, rotation } = this.props;

  if (isUndefined(image) || isUndefined(mainCanvasInstance)) return 1;

  const canvasNavHalfWidth = CANVAS_NAV_WIDTH / 2;
  const canvasNavHalfHeight = CANVAS_NAV_HEIGHT / 2;
  const imageHalfWidth = image.width / 2;
  const imageHalfHeight = image.height / 2;
  const canvasNavCenterPoint = {
    x: canvasNavHalfWidth,
    y: canvasNavHalfHeight
  };

  const pointA = utilities.getPointByRotation({
    x: canvasNavHalfWidth - imageHalfWidth,
    y: canvasNavHalfHeight - imageHalfHeight
  }, canvasNavCenterPoint, rotation);
  const pointB = utilities.getPointByRotation({
    x: canvasNavHalfWidth + imageHalfWidth,
    y: canvasNavHalfHeight - imageHalfHeight
  }, canvasNavCenterPoint, rotation);
  const pointC = utilities.getPointByRotation({
    x: canvasNavHalfWidth + imageHalfWidth,
    y: canvasNavHalfHeight + imageHalfHeight
  }, canvasNavCenterPoint, rotation);
  const pointD = utilities.getPointByRotation({
    x: canvasNavHalfWidth - imageHalfWidth,
    y: canvasNavHalfHeight + imageHalfHeight
  }, canvasNavCenterPoint, rotation);

  return Math.min(
    CANVAS_NAV_WIDTH / (Math.max(pointA.x, pointB.x, pointC.x, pointD.x) - Math.min(pointA.x, pointB.x, pointC.x, pointD.x)),
    CANVAS_NAV_HEIGHT / (Math.max(pointA.y, pointB.y, pointC.y, pointD.y) - Math.min(pointA.y, pointB.y, pointC.y, pointD.y))
  );
};

export const centerPoint = (x, y, width, height) => {
  return { x: x + width / 2, y: y + height / 2 };
};

