import PropTypes from 'prop-types';
import React from 'react';
import Table, { Column, headers, filters } from 'widgets/ReactDataGrid';
import { FILTER_TYPE, ROW_HEIGHT, HEADER_HEIGHT, HEADER_HEIGHT_WITH_FILTER } from 'widgets/ReactDataGrid/utils';

const { GenericHeader, HeaderCaption } = headers;
const { TextInputFilter, DateFilter, MultiselectFilter } = filters;
const COLUMNKEY = 'rowId';
const MIN_COLUMN_WIDTH = 20;

export default function View({ module, viewModel, historyTableColumns = [], win, onTableRowContextMenu }) {

  const renderHistoryPageColumnFilter = column => {

    const { filter } = column;

    if (!filter) {
      return;
    }

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:
        const selectedValues = Array.isArray(filter.selected) && filter.selected || [];
        return (
          <MultiselectFilter
            selectedValues={selectedValues}
            options={filter.options}
            dataType={filter.dataType}
            onSelect={(event, values, index) => module.handleHistoryPageColumnFilterChange(column, { selected: values })}
          />
        );
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            textValue={filter.textValue}
            onChange={(e, textValue) => module.handleHistoryPageColumnFilterChange(column, { textValue })}
          />
        );
      case FILTER_TYPE.DATE:
        return (
          <DateFilter
            filter={filter}
            onApply={filter => module.handleHistoryPageColumnFilterChange(column, filter)}
          />
        );
    }
  }

  const renderHistoryPageColumnHeaderFilter = column => {

    if (!module.filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {renderHistoryPageColumnFilter(column)}
      </div>
    );
  }
  const renderHistoryPageColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={multiSort => handleClick(column.key, column.sortValueGetter, multiSort)} sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  }

  const renderHistoryPageColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={renderHistoryPageColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={renderHistoryPageColumnHeaderFilter(column)}
      />
    );
  }
  const { columnsToSortBy, filtersEnabled } = module;

  const headerHeight = filtersEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;


  return <div className='history-view'>
    <div className='history-view-main'>

      <Table
        rows={viewModel.filteredHistoryRows}
        columnKey={COLUMNKEY}
        virtualScroll={true}
        onRowClick={function (rowIndex, rowContent, ev) { }}
        onRowContextMenu={(rowIndex, rowContent, selRows, ev) => onTableRowContextMenu(rowIndex, [rowContent], ev)}
        selectableRows={false}
        fixed={false}
        win={win}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={headerHeight}
        rowHeight={ROW_HEIGHT}
        onColumnsFilter={(columnKey, visible, columns) => module.handleHistoryPageColumnsFilter(columns)}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleHistoryPageColumnOrder(columns, oldIndex, newIndex)}
        onColumnResize={(columns, columnKey) => module.handleHistoryPageTableColumnResize(columns)}
        showFilterOptionInHeaderMenu={true}
        filtersEnabled={filtersEnabled}
        onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
      >
        {historyTableColumns.map(col => {
          const index = columnsToSortBy.findIndex(column => column.key === col.key)
          const sortDirection =
            col.sortType && index !== -1
              ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
              : undefined;
          const handleClick = col.sortType
            ? module.handleHistoryPageColumnClick(col.sortType)
            : undefined;
          return <Column
            key={col.key}
            title={col.caption}
            columnKey={col.key}
            width={col.width}
            visible={col.visible}
            header={renderHistoryPageColumnHeader(col, handleClick, sortDirection, index)}
            cell={col.cell}
            cellDataGetter={col.cellDataGetter}
          />;
        })}
      </Table>
    </div>
  </div>;
};

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  historyTableColumns: PropTypes.array,
  win: PropTypes.object,
  onTableRowContextMenu: PropTypes.func
};

