/**
 * @name PagesView
 * @author tzvika
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import { savePreferences } from 'core/managers/preferences';
import { getAllPages, getPageInputFileName, getPageInputTime } from './utils';
import jsutils from 'base/jsUtils';
import TickableModel from '../TickableModel';
import UnplanTable from './UnplanTable';
import { makeUnplanTableColumns } from './columnsCreator';
import { arrayToObject, moveItem } from '../../utilities/array';
import { fromServerDate } from 'core/dates';
import pubsub from 'core/managers/pubsub';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';
import { createGhostElement } from 'utilities/dragAndDrop';
import {
  checkNumberCondition,
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  checkDateCondition,
  applyColumnSortPreferences,
  extractColumnSortPreferences,
  reduceColumnsToSortBy
} from 'widgets/ReactDataGrid/utils';
import { composeComparators, createObjectComparator } from 'core/comparators';
import { DEFAULT_SORT } from './constants';
import { startModule } from 'core/managers/module';

const THROTTLE_WAIT = 1000;
const DROP_FAILURE_TIMEOUT = 30000;
const DROP_FAILURE_FACTOR = 5000;

const removeDeletedPagesFromSelected = (pages, selectedPages) => selectedPages.filter(page => pages.some(p => p.nwid === page.nwid));

export default AbstractModule.extend({
  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.enabledRename = this.isEnableRename();
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.unsubscribeDropOnPage = pubsub.subscribe('drop-on-page', data => this.handleDropOnPage(data));
    this.timerId = undefined;
    this.droppedPages = {};
    this.handleOpenUnplanViewInNewWindow = this.handleOpenUnplanViewInNewWindow.bind(this);
  },

  destroy: function () {
    this._super();
    this.unsubscribeDropOnPage();
    clearTimeout(this.timerId);
    this.reactRoot.unmount();
  },

  handleDropOnPage: function ({ unplannedPages, success }) {
    if (success) {
      clearTimeout(this.timerId);
      unplannedPages.forEach(page => this.droppedPages[page.nwid] = Date.now());
      this.updateSelected([]);
      this.timerId = setTimeout(() => {
        if (Object.keys(this.droppedPages).length > 0) {
          this.buildViewModel();
        }
      }, DROP_FAILURE_TIMEOUT);
    } else {
      unplannedPages.forEach(page => delete this.droppedPages[page.nwid]);
    }

    this.buildViewModel();

  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = (typeof data.preferences.columnsToSortBy === 'undefined' || data.preferences.columnsToSortBy.length === 0) ?
      DEFAULT_SORT : data.preferences.columnsToSortBy;

    this.filtersEnabled = (this.preferences.table || {}).filtersEnabled || false;

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  filterDroppedPagesFromViewModel: function () {
    const filteredPagesArr = [];
    const newDropppedPages = {};
    const failedPages = [];
    this.viewModel.allPages.forEach(page => {
      if (this.droppedPages[page.nwid]) {
        if (Date.now() - this.droppedPages[page.nwid] < DROP_FAILURE_TIMEOUT) {
          newDropppedPages[page.nwid] = this.droppedPages[page.nwid];
        } else {
          filteredPagesArr.push(page);
          failedPages.push(page.name);
        }
      } else {
        filteredPagesArr.push(page);
      }
    });
    this.droppedPages = newDropppedPages;
    if (failedPages.length > 0) {
      toastService.errorToast(translate(`${failedPages.length} page(s) failed to drop:`), failedPages.join(',\r\n'));
    }
    return filteredPagesArr;
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();
    this.viewModel = {};
    this.viewModel.allPages = getAllPages(model);
    if (Array.isArray(this.selected) && this.selected.length > 0) {
      this.updateSelected(removeDeletedPagesFromSelected(this.viewModel.allPages, this.selected));
    }
    this.viewModel.pages = Object.keys(this.droppedPages).length > 0 ? this.filterDroppedPagesFromViewModel() : this.viewModel.allPages;

    const numOfPages = this.viewModel.pages.length;
    this.tab && this.tab.setState({
      itemCount: numOfPages > 0 ? numOfPages : '',
      openInNewWindowHandler: this.handleOpenUnplanViewInNewWindow
    });
    this.viewModel.pages.forEach(page => page.inputTime = getPageInputTime(page));
    this.viewModel.pages.forEach(page => page.inputFileName = getPageInputFileName(page));
    this.viewModel.pages.forEach(page => {
      page.iconUID = page.pageContent?.versionNwid;
    });
    this.viewModel.pagesByNwid = arrayToObject(this.viewModel.pages, 'nwid');
    this.viewModel.sortedPages = [...this.viewModel.pages];

    this.unplanTableColumns = makeUnplanTableColumns(this.viewModel, this);
    this.unplanTableColumnsByKeys = arrayToObject(this.unplanTableColumns);

    this.sortPages();

    this.filterPages();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  sortPages: function () {
    if (this.columnsToSortBy && this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.unplanTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));

      this.viewModel.sortedPages = this.viewModel.sortedPages.sort(comparator);
    };
  },

  filterPages: function () {
    if (!this.filtersEnabled) {
      this.viewModel.pages = this.viewModel.sortedPages;
    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.unplanTableColumns);

      this.viewModel.pages = this.viewModel.sortedPages.filter(page => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = col.filterValueGetter(page);
              match = filter.selected.some(s => s === filterValue);
            }
          } else if (filter.type === FILTER_TYPE.DATE) {
            const time = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(page) : page[col.key];
            if (time) {
              const date = fromServerDate(time);
              match = checkDateCondition(date, filter);
            }
          } else if (filter.type === FILTER_TYPE.TEXT) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(page) : page[col.key];
              match = checkTextCondition(text, filter);
            } else if (filter.dataType === FILTER_DATA_TYPE.NUMBER) {
              const number = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(page) : page[col.key];
              match = checkNumberCondition(number, filter);
            }
          }

          if (!match) {
            break;
          }
        }

        return match;
      });
    }

    this.render();
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.buildViewModel();

    this.saveUnplanColumnPreferences();
  },

  saveUnplanColumnPreferences: function () {
    const unplanColumns = extractColumnPreferences(this.unplanTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        unplanColumns
      }
    });
  },

  handleUnplanTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.unplanTableColumns, oldIndex, newIndex);

    this.saveUnplanColumnPreferences();

    this.render();
  },

  handleUnplanTableSelect: function (selectedRows) {
    const pages = selectedRows.map(row => row && this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(pages.filter(e => !!e));
  },

  handleUnplanTableContextMenu: function (page, selected, e) {
    this.showContextMenu(page, selected, e);
  },

  handleUnplanTableColumnResize: function (columns) {
    this.unplanTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveUnplanColumnPreferences();
  },

  handleUnplanTableColumnsFilter: function (columns) {
    this.unplanTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveUnplanColumnPreferences();
  },

  handleUnplanColumnFilterChange: function (column, columnFilter) {

    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.filterPages();

    this.saveUnplanColumnPreferences();
  },

  handleUnplanColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.unplanTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortPages();
      this.filterPages();
      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
    };
  },

  handleDragStart: function (rowIndex, rowContent, e) {
    e.dataTransfer.effectAllowed = 'move';
    const dataToTransfer = this.selected && this.selected.length > 1 ? this.selected : [rowContent];
    const customGhostElement = createGhostElement(dataToTransfer);
    e.dataTransfer.setDragImage(customGhostElement, 0, 0);
    e.dataTransfer.setData('Text', JSON.stringify(dataToTransfer));
  },

  handleUnplanPageDoubleClick(page, e) {
    this.navigateByViewLink(page, undefined, e);
  },

  handleUnplanPageNameBlur: function (page) {
    this.renameAction.execute.call(this.renameAction, [page]);
  },

  isEnableRename: function () {
    for (var i = 0; i < this.viewActions.length; i++) {
      var action = this.viewActions[i];
      if (action.actionClass === 'RenameUnplanAction') {
        this.renameAction = action;
        return true;
      }
    }
    return false;
  },

  handleUnplanTableDeleteKey: function (selectedRows, e) {
    const actions = this.getRelevantActions(this.selected[0]);
    const deleteAction = actions.find(a => a.actionClass === 'DeleteAction');
    if (deleteAction) {
      const res = deleteAction.execute(this.selected);
      res.then(() => this.updateSelected([]));
    }
  },

  handleOpenUnplanViewInNewWindow: function () {
    const startParameters = {
      target: 'window',
      viewClass: 'UnplanTableView',
      noNavigator: true
    };

    startModule(this.nwid, null, startParameters);
  },

  handleChangeBounds: function ({ left, top, width, height }) {
    this.savePreferences({
      ...this.preferences,
      selectedPagesDialog: {
        left,
        top,
        width,
        height
      }
    });
  },

  render: function () {
    this.reactRoot.render(
      <UnplanTable
        module={this}
        viewModel={this.viewModel}
        unplanTableColumns={this.unplanTableColumns}
      />);
  },
});