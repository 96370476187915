import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import pubsub from 'core/managers/pubsub';
import AbstractModule from 'AbstractModule';
import panelsDefinitions from 'components/private/info/panelsDefinitions';
import EmptyPanel from 'src/components/private/info/panels/emptyComponent/EmptyComponent';
import PanelsInfo from './components/PanelsInfo';
import { restGet } from 'core/managers/rest2';
import PlanSetup from 'sandbox/planSetup';

const availableTypes = ['page', 'form'];

const panels = {
  'default': [],
  'page': ['Info', 'Links', 'Colors', 'Sites', 'Ads'],
  'AD': ['Fragments Info', 'Variants', 'Links', 'Colors'],
  'EDITORIAL': ['Fragments Info', 'Variants', 'Links', 'Colors'],
  'form': ['Info', 'Links', 'Colors', 'Sites', 'Plates', 'Layout', 'Creep', 'Pages', 'Fanout']
};

const isUndefined = (o) => typeof o === 'undefined';

const getElementType = (model) => {
  let elementType = 'default';

  if (model?.object?.elementType) {
    elementType = model.object.elementType;
  } else if (model?.contentType === 'AD' || model?.contentType === 'EDITORIAL') {
    elementType = model?.contentType;
  }

  return elementType;
};

const getPropFromModel = (model, propFromModelPath) => {
  const cursor = propFromModelPath.split('.');
  let propFromModel = model;
  if (propFromModelPath !== 'model') {
    cursor.map((element, index) => {
      propFromModel = propFromModel[element];
    });
  }
  return propFromModel;
};

const getPanelProp = (model, PanelProps) => {
  let props = {};
  for (var prop in PanelProps) {
    const propFromModel = getPropFromModel(model, PanelProps[prop]);
    props[prop] = propFromModel;
  }
  return props;
};

const getPanels = (model) => {
  const panelNames = panels[getElementType(model)];

  return panelNames.map(panelName => {
    const panelDefinitions = panelsDefinitions[panelName];
    const icon = panelDefinitions.icon;
    const title = panelDefinitions.title;
    const PanelComp = panelDefinitions.comp;
    const props = getPanelProp(model, panelDefinitions.props);
    const shouldAdd = typeof panelDefinitions.shouldAdd === 'function' ? panelDefinitions.shouldAdd(model, props) : true;

    if (!shouldAdd) {
      return undefined;
    }

    return {
      panelName,
      icon,
      title,
      PanelComp,
      props
    };
  }).filter(panel => !isUndefined(panel));
};

const getNewItemPanelIndex = (oldItemPanels = [], newItemPanels = [], oldItemSelectedPanelIndex) => {
  if (oldItemSelectedPanelIndex < 0) {
    return 0;
  }

  const oldItemSelectedPanel = oldItemPanels[oldItemSelectedPanelIndex] || {};
  const newItemSelectedPanelIndex = newItemPanels.findIndex(panel => panel.panelName === oldItemSelectedPanel.panelName);

  return newItemSelectedPanelIndex < 0 ? 0 : newItemSelectedPanelIndex;
};

export default AbstractModule.extend({

  init: function () {
    this.render = this.render.bind(this);
    this.restRequest = this.restRequest.bind(this);
    this.refreshDataFunction = this.refreshDataFunction.bind(this);
    this.openCustomInfoDialog = this.openCustomInfoDialog.bind(this);
    this.handleSelectedPanelChange = this.handleSelectedPanelChange.bind(this);
  },

  handleActiveSelectionChanged: function ({ selected, moduleTarget }) {
    // Handle changes to the active item in main tabs and new windows only
    if (moduleTarget === 'new' || moduleTarget === 'main' || moduleTarget === '') {
      if (Array.isArray(selected) && selected.length > 0 && !selected[0].aggregated && selected[0].nwid) {
        this.extraInfo = selected[0].extraInfo;
        this.selected = selected;
        this.restRequest(selected[0].nwid);
      } else {
        this.state = { model: {}, panels: [], headerBreadcrumbs: '', loading: false, selectedNwid: undefined, selectedPanelIndex: 0 };
        this.render();
      }
    }
  },

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.state = { model: {}, panels: [], headerBreadcrumbs: '', loading: false, selectedNwid: undefined, selectedPanelIndex: 0 };
    this.unsubscribeActiveSelectionChanged = pubsub.subscribe('active-selection-changed', this.handleActiveSelectionChanged.bind(this), true);
    this.render();
  },

  openCustomInfoDialog: function (nwid) {
    const showCustomInfoAction = this.viewActions.find(action => action.actionDefinitionName === 'ShowCustomInfoCR');
    if (typeof showCustomInfoAction === 'undefined' || typeof showCustomInfoAction.execute !== 'function') {
      return;
    }

    showCustomInfoAction.execute([{ nwid }]);
  },

  restRequest: function (nwid) {
    const oldItemPanels = this.state.panels || [];

    this.state.loading = true;
    this.render();

    restGet(this.nwid, `CustomInfo/getCustomInfo?nwid=${nwid}&name=${this.name ? this.name : 'default'}`, {}, { version: 'v1' })
      .then(model => {
        if (typeof model.object !== 'undefined' && availableTypes.findIndex(type => type === model.object.elementType) >= 0) {
          model.links = {};
          model.object.extraInfo = this.extraInfo;
          if (this.selected[0].variant) {
            model.object.name = model.variantName;
          }
          for (var modelElement in model) {
            if (modelElement === 'children' || modelElement === 'masters' || modelElement === 'remoteRelatives' || modelElement === 'siblings' || modelElement === 'contents' || modelElement === 'copies') {
              model.links[modelElement] = model[modelElement];
            }
          }
          model.separations.forEach((separation) => {
            const colorNameObj = sandbox.colors.getColorByName(separation.name);
            const colorRGB = sandbox.colors.getUIColorRGB(colorNameObj);
            separation['type'] = colorNameObj.colorType;
            separation['UIColorRGB'] = colorRGB;
          });

          sandbox.sorting.sort(model.separations, 'cmyk', 'name');
          !isUndefined(model.pages) ? model.pages.forEach((page) => {
            sandbox.sorting.sort(page.separations, 'cmyk');
          }) : undefined;

          const newItemPanels = getPanels(model);
          const newItemsSelectedPanelIndex = getNewItemPanelIndex(oldItemPanels, newItemPanels, this.state.selectedPanelIndex);

          this.state.model = model;
          this.state.panels = newItemPanels;
          this.state.headerBreadcrumbs = model.object.name;
          this.state.loading = false;
          this.state.selectedNwid = nwid;
          this.state.selectedPanelIndex = newItemsSelectedPanelIndex;
          this.render();
        } else if (model.contentType === 'AD' || model.contentType === 'EDITORIAL') {
          model.links = { pages: model.pages };
          model.separations.forEach((separation) => {
            const colorNameObj = sandbox.colors.getColorByName(separation.name);
            const colorRGB = sandbox.colors.getUIColorRGB(colorNameObj);
            separation['type'] = colorNameObj.colorType;
            separation['UIColorRGB'] = colorRGB;
          });

          sandbox.sorting.sort(model.separations, 'cmyk', 'name');

          const newItemPanels = getPanels(model);
          const newItemsSelectedPanelIndex = getNewItemPanelIndex(oldItemPanels, newItemPanels, this.state.selectedPanelIndex);

          this.state.model = model;
          this.state.panels = newItemPanels;
          this.state.headerBreadcrumbs = model.name;
          this.state.loading = false;
          this.state.selectedNwid = nwid;
          this.state.selectedPanelIndex = newItemsSelectedPanelIndex;
          this.render();
        } else {
          this.state.loading = false;
          this.state.model = {};
          this.state.selectedPanelIndex = 0;
          this.render();
        }
      })
      .catch(res => {
        this.state.loading = false;
        this.state.model = {};
        this.state.selectedPanelIndex = 0;
        this.render();
      });
  },

  handleSelectedPanelChange: function (selectedPanelIndex) {
    this.state.selectedPanelIndex = selectedPanelIndex;
    this.render();
  },

  firstTickReceived: function (data) {
  },

  tickUpdate: function (data) {
  },

  tickCommit: function () {
  },

  destroy: function () {
    this._super();
    this.unsubscribeActiveSelectionChanged();
    this.reactRoot.unmount();
  },

  refreshDataFunction: function () {
    const { selectedNwid } = this.state;
    this.restRequest(selectedNwid);
  },

  render: function () {
    const { model, panels, headerBreadcrumbs, loading, selectedPanelIndex } = this.state;
    if (Object.keys(model).length != 0) {
      this.reactRoot.render(<PanelsInfo model={model}
        panels={panels}
        selectedPanelIndex={selectedPanelIndex}
        headerBreadcrumbs={headerBreadcrumbs}
        refreshData={this.refreshDataFunction}
        openCustomInfoDialog={this.openCustomInfoDialog}
        loading={loading}
        onSelectedPanelChange={this.handleSelectedPanelChange}
      />);
    }
    else {
      this.reactRoot.render(<EmptyPanel loading={loading} />);
    }
  }
});
