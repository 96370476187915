/**
 * @name CommCenterView Module
 * @author Liat
 */

define(['sandbox', 'ember', 'AbstractModule', 'TableWidget', './controllers/MonitorProfilesViewController', 'logger'],
  function (sandbox, Ember, AbstractModule, TableWidget, Controller, logger) {

    'use strict';

    var log = logger.getDefaultLogger();

    function getDefaultColumns() {
      return [
        { title: sandbox.localization.translate("Monitor Name"), field: "monitorName", width: '20%' },
        { title: sandbox.localization.translate("Monitor Profile"), field: "monitorProfileName", width: '40%' }
      ];
    }

    function initialTickHandler(model, preferences) {
      // build map between id and model for updates
      this._map = sandbox.appUtils.flattenModel(model, {});

      // set content to the controller
      this.ctrl.set('content', model);
      this.ctrl.set('module', this);

      // append RenamingRulesView view container
      sandbox.dom.addClass(this.element, 'pro-modules-monitor-profiles');
      sandbox.dom.empty(this.element);

      // sort
      var sortingDescription = model['monitorprofiles' + ":sorting"];
      if (sortingDescription) {
        var sortingTokens = sortingDescription.split(":");
        if (sortingTokens.length === 2) {
          sandbox.sorting.sort(this.ctrl.get('monitorprofiles'), sortingTokens[1], sortingTokens[0]);
        }
      }

      // create table widget
      this.monitorTable = new TableWidget({
        element: this.element,
        data: this.ctrl.get('monitorprofiles'),
        selection: 'multiple row',
        filterable: true,
        columns: getDefaultColumns.call(this)
      });

      this.monitorTable.bind('delete', function (selected) {
        this.ctrl.deleteMonitorProfile(selected);
      }, this);

      this.monitorTable.bind('dblclick', function (selected) {
        this.navigateByAction(selected);
      }, this);

      this.monitorTable.bind('contextmenu', function () {
        this.showContextMenu.apply(this, arguments);
      }, this);
    }

    function handleAdd(model) {

      this._map[model.id] = model;

      if (model.hasOwnProperty("parentId")) {
        var parentId = model['parentId'];   // resource
        var parent = this._map[parentId];
        if (typeof parent === 'undefined') {
          log.info("RenamingRulesView.handleAdd: Parent entity not found - " + parentId);
          return;
        }

        var childProperty = model["childPropertyId"];
        var objToUpdate;
        if (parent.hasOwnProperty(childProperty)) {
          objToUpdate = parent[childProperty];
        } else {
          objToUpdate = [];
          Ember.set(parent, childProperty, objToUpdate); // flat
        }

        // If sorted, insert the item
        var found = false;
        var sortingDescription = this.ctrl.get('monitorprofiles' + ":sorting");
        if (sortingDescription) {
          var sortingTokens = sortingDescription.split(":");
          if (sortingTokens.length === 2) {
            var comparator = sandbox.sorting.getComparator(sortingTokens[1], sortingTokens[0]);
            for (var j = 0; j < objToUpdate.length; j++) {
              if (comparator(model, objToUpdate[j]) < 1) {
                objToUpdate.insertAt(j, model);
                found = true;
                break;
              }
            }
          }
        }
        if (!found) {
          objToUpdate.pushObject(model);
        }
      }
    }

    function handleUpdate(update) {
      var progressInt = update.progress;

      var cached = this._map[update.id];
      if (typeof cached === 'undefined') {
        return;
      }
      for (var key in update) {
        if (update.hasOwnProperty(key) && key !== 'action') {
          Ember.set(cached, key, update[key]);
        }
      }
    }

    function handleRemove(model) {

      var idsToDelete = model["ids"];

      var parent = this._map[model["parentId"]];
      var childProperty = model["childPropertyId"];

      var objToUpdate = parent[childProperty];

      var updateArray = Array.isArray(objToUpdate);

      if (idsToDelete) {
        for (var i = 0; i < idsToDelete.length; i++) {
          var idToDelete = idsToDelete[i];
          var o = this._map[idToDelete];
          if (o.monitorName === window.localStorage.getItem('monitorProfile')) {
            window.localStorage.setItem('monitorProfile', '');
          }
          if (updateArray) {
            objToUpdate.removeObject(o);
            delete this._map[idToDelete];
          } else {
            this._map[idToDelete] = null;
            var existingObj = Ember.get(parent, childProperty);
            if (existingObj) {
              var existingId = existingObj["id"];
              if (existingId === idToDelete) {
                Ember.set(parent, childProperty, null);
              }
            }
          }
        }
      }
    }

    return AbstractModule.extend({

      initDone: function () {
        this.ctrl = Controller.create();
        this.views = {};
        this.toolbar = this.createToolbar();
      },

      init: function () {
        this._super();
        this.viewType = '';
        this.tableModel = null;
      },

      firstTickReceived: function (data) {
        initialTickHandler.call(this, data.model, data.preferences);
        this.selection.applyTableSelection(this, this.monitorTable);
      },

      tickUpdate: function (data) {
        var models = Array.isArray(data.model) ? data.model : [data.model];
        for (var i = 0, leni = models.length; i < leni; i += 1) {
          switch (models[i].action) {
            case 'Add':
              handleAdd.call(this, models[i]);
              break;
            case 'Update':
              handleUpdate.call(this, models[i]);

              break;
            case 'Remove':
              handleRemove.call(this, models[i]);
              break;
          }
        }
      },

      destroy: function () {
        this._super();
        if (typeof this.monitorTable !== 'undefined') {
          this.monitorTable.destroy();
        }
        this.ctrl.destroy();
      }

    });
  });