import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { CanvasUtilities } from './utilities';

const CANVAS_CLASS_NAME = 'crtx-canvas';

function Canvas({
  style,
  className = '',
  clearBeforeDraw = true,
  width,
  height,
  onMouseDown = () => { },
  onMouseWheel = () => { },
  onLoad = () => { },
  children,
}, ref) {

  const childInstances = useRef([]);
  const canvasRef = useRef(null);
  const canvasUtilitiesRef = useRef(null);
  const [canvasStyle, setCanvasStyle] = useState(style);

  useEffect(() => {
    canvasUtilitiesRef.current = new CanvasUtilities();
  }, []);

  useEffect(() => {
    const canvasBoundingClientRect = getBoundingClientRect();

    canvasUtilitiesRef.current.setCanvasSize(width, height)
      .setCanvasOffset(canvasBoundingClientRect.left, canvasBoundingClientRect.top)
      .setCanvasClientOffset(canvasRef.current.clientLeft, canvasRef.current.clientTop);

    draw();
  });

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (style !== canvasStyle) {
      setCanvasStyle(style)
    }
  }, [style]);

  const subscribeToCanvas = child => {
    childInstances.current = childInstances.current.concat(child);

    return () => childInstances.current = childInstances.current.filter(childInstance => childInstance !== child);
  };

  useImperativeHandle(ref, () => ({
    get canvasUtilities() {
      return canvasUtilitiesRef.current
    },
    getBoundingClientRect
  }))

  const draw = () => {
    if (typeof canvasRef === 'undefined' || canvasRef.current === null) {
      return;
    }
    const context = canvasRef.current.getContext('2d');

    if (clearBeforeDraw) {
      context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
    childInstances.current.forEach(child => child.draw(context, canvasRef.current));
  };

  const getBoundingClientRect = () => typeof canvasRef === 'undefined' ? undefined : canvasRef.current.getBoundingClientRect();

  return <canvas ref={canvasRef}
    style={canvasStyle}
    className={`${CANVAS_CLASS_NAME} ${className}`}
    width={width}
    height={height}
    onMouseDown={onMouseDown}
    onWheel={onMouseWheel}
  >
    {React.Children.map(children, child => typeof child === 'undefined' || child === null ? child : React.cloneElement(child, { subscribe: subscribeToCanvas, canvasDraw: draw }))}
  </canvas>;

}

const CanvasWithRef = forwardRef(Canvas);

CanvasWithRef.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  clearBeforeDraw: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  onMouseDown: PropTypes.func,
  onMouseWheel: PropTypes.func,
  onLoad: PropTypes.func
}

export default CanvasWithRef;