define(['ReactSimpleSetup', 'react', 'react-dom', './src/OutputPlatesSetup', 'sandbox'],
  function (ReactSimpleSetup, React, ReactDOM, OutputPlatesSetup, sandbox) {
    'use strict';

    var selectorToProperty = {
      "forms": "formName",
      "separations": "separationName",
      "presses": "pressName",
      "towers": "towerName",
      "cylinders": "cylinderName",
      "locations": "locationOnCylinder",
      "sectors": "cylinderSector"
    }

    function getUImodel(model) {
      var rows = [];
      var formsSelector = [];
      var separationsSelector = [];
      var pressesSelector = [];
      var towersSelector = [];
      var cylindersSelector = [];
      var locationsSelector = [];
      var sectorsSelector = [];
      var selectors = {
        "forms": formsSelector,
        "separations": separationsSelector,
        "presses": pressesSelector,
        "towers": towersSelector,
        "cylinders": cylindersSelector,
        "locations": locationsSelector,
        "sectors": sectorsSelector
      };
      var forms = model.forms;
      for (var i = 0; i < forms.length; i++) {
        var separations = forms[i].separations;
        for (var j = 0; j < separations.length; j++) {
          var separationName = separations[j].name;
          var separationnwid = separations[j].nwid;
          var plates = separations[j].plates;
          for (var q = 0; q < plates.length; q++) {
            var row = {
              "selected": !plates[q].disabled || plates[q].mustBe,
              "formName": forms[i].name,
              "formNwid": forms[i].nwid,
              "separationName": separations[j].separation,
              "separationNwid": separations[j].nwid,
              "disabled": plates[q].disabled,
              "formVersion": plates[q].formVersion,
              "plateVersion": plates[q].plateVersion,
              "plateNwid": plates[q].nwid,
              "pressName": plates[q].pressName,
              "towerName": plates[q].towerName,
              "cylinderName": plates[q].cylinderName,
              "locationOnCylinder": plates[q].locationOnCylinder,
              "flowStepName": plates[q].status.flowStepName,
              "cylinderSector": plates[q].cylinderSector,
              "statusTime": plates[q].status.time,
              "resource": plates[q].resource,
              "status": plates[q].status.statusType,
              "flowStepIconName": plates[q].status.flowStepIconName,
              "mustBe": plates[q].mustBe
            };
            updateSelectors(selectors, row)
            rows.push(row);
          }
        }
      }
      sortSelectors(selectors);
      var uiModel = {
        "rows": rows,
        "selectors": selectors,
        "reasons": model.reasons ? getReasons.call(this, model.reasons) : [],
        "resources": model.resources ? getResources.call(this, model.resources.availableResources) : [],
        "resourceNwid": this.startParameters.actionConfig && this.startParameters.actionConfig.defaultResource === 'nextAvailable' ? '0' : '-1',
        "reason": model.reasons ? model.reasons[0] : '',
        "showOnlyPlates": model.showOnlyPlates
      };
      return uiModel;
    }

    function getReasons(reasons) {
      return reasons.map(function (reason) {
        return { value: reason, text: reason };
      });
    }

    function getResources(resources) {
      let ret = resources.map(function (resource) {
        return { value: resource.nwid, text: resource.name };
      });
      let dontChange = {
        value: '-1', text: sandbox.localization.translate("Don't Change")
      };
      let any = {
        value: '0', text: sandbox.localization.translate('Next Available')
      };
      this.startParameters.actionConfig && this.startParameters.actionConfig.defaultResource === 'nextAvailable' ? ret.unshift(any, dontChange) : ret.unshift(dontChange, any);
      return ret;
    }

    function sortSelectors(selectors) {
      sandbox.sorting.sort(selectors.forms, 'alpha');
      sandbox.sorting.sort(selectors.separations, 'alpha');
      sandbox.sorting.sort(selectors.presses, 'alpha');
      sandbox.sorting.sort(selectors.towers, 'alpha');
      sandbox.sorting.sort(selectors.cylinders, 'alpha');
      sandbox.sorting.sort(selectors.locations, 'alpha');
      sandbox.sorting.sort(selectors.sectors, 'alpha');
    }

    function updateSelectors(selectors, row) {
      addUniqueToArray(selectors.forms, row.formName);
      addUniqueToArray(selectors.separations, row.separationName);
      addUniqueToArray(selectors.presses, row.pressName);
      addUniqueToArray(selectors.towers, row.towerName);
      addUniqueToArray(selectors.cylinders, row.cylinderName);
      addUniqueToArray(selectors.locations, row.locationOnCylinder);
      addUniqueToArray(selectors.sectors, row.cylinderSector);
    }

    function addUniqueToArray(array, str) {
      if (array.indexOf(str) === -1) {
        array.push(str);
      }
    }

    function doesRowMatchSelectors(row, selectorsState) {
      var match = true;
      for (var selector in selectorsState) {
        var rowValue = row[selectorToProperty[selector]];
        if (selectorsState[selector] && selectorsState[selector].length > 0 && selectorsState[selector].indexOf(rowValue) === -1) {
          match = false;
          break;
        }
      }
      return match;
    }

    return ReactSimpleSetup.extend({

      model: {},

      actions: {
        changeReason: function (reason) {
          this.model.reason = reason;
          this._render(this.model);
        },

        changeResource: function (resourceNwid) {
          this.model.resourceNwid = resourceNwid;
          this._render(this.model);
        },

        applySelectors: function (selectorsState) {
          this.model.rows.forEach(function (row) {
            row.selected = doesRowMatchSelectors.apply(this, [row, selectorsState]);
          });
          this._render(this.model);
        },

        rowSelected: function (index) {
          this.model.rows[index].selected = !this.model.rows[index].selected;
          this._render(this.model);
        }
      },

      _render: function (model) {
        this.render(React.createElement(OutputPlatesSetup, { model: model, actions: this.actions, module: this }, null));
      },


      dataReceived: function (data) {
        var that = this;
        let treeObjectNwid = 'undefind';
        let treeObjectType = 'undefind';
        if (this.parentModule.selected.length === 0) {
          treeObjectNwid = data.model.nwid;
          treeObjectType = data.model.type;
        }
        sandbox.request.getGenericCommand({
          data: {
            command: 'getOutputPlates',
            params: JSON.stringify({
              forms: this.parentModule.selected.map(form => form.nwid).join(','),
              treeObjectNwid:treeObjectNwid,
              treeObjectType: treeObjectType,            
              actionInstanceNwId: this.startParameters.initiatingActionNwid
            })
          }
        }).done((result) =>  {
          var rest = sandbox.request.rest(that.nwid);
          let plateNwIds = [];
          let forms = result.data.model.forms;
          for (var i = 0; i < forms.length; i++) {
            var separations = forms[i].separations;
            for (var j = 0; j < separations.length; j++) {
              var plates = separations[j].plates;
              for (var p = 0; p < plates.length; p++) {
                plateNwIds.push(plates[p].nwid)
              }
            }
          }
          rest.post('re-output-plates/resource', {
            data: JSON.stringify({
              nwids: plateNwIds
            }),
            success: (resources) => {
              let forms = result.data.model.forms;
              for (var i = 0; i < forms.length; i++) {
                var separations = forms[i].separations;
                for (var j = 0; j < separations.length; j++) {
                  var plates = separations[j].plates;
                  for (var q = 0; q < plates.length; q++) {
                    let plateNwid = plates[q].nwid;
                    for (var key in resources) {
                      if (key === plateNwid) {
                        plates[q].resource = resources[plateNwid];
                        break;
                      }
                    }
                  }
                }
              }
              that.model = getUImodel.call(this, result.data.model);
              that._oldModel = sandbox.jsUtils.cloneDeep(result.data.model);
              that._render(that.model);
            }
          })
        });
      },

      apply: function () {
        var newModel = {};
        newModel.reason = this.model.reason;
        if (this.model.resourceNwid !== "-1") {
          newModel.resourceNwid = this.model.resourceNwid;
        }
        var selectedPlates = this.model.rows.filter(function (row) {
          return row.selected;
        })

        var plates = selectedPlates.map(function (row) {
          if (row.selected) {
            return {
              nwid: row.plateNwid,
              plateVersion: row.plateVersion,
              formVersion: row.formVersion
            };
          }
        });
        newModel.plates = plates;
        return newModel;
      }
    });
  });


