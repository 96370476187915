import React from 'react';
import Mustache from 'mustache';
import StyleConverter from '../styleConverter';
import colorsManager from '../managers/colorsManager';
import componentsStyleMap from '../models/componentstylemap';
import TextComponent from './elements/text/textcomponent';
import Version from './elements/text/version';
import SeparationBox from './elements/separationsBox/separationbox';
import Separations from './elements/separations/separations';
import SeparationsProgress from './elements/separationsProgress/separationsProgress';
import StatusIcon from './elements/statusIcon/statusicon';
import Indicator from './elements/indicator/indicator';
import PagesThumbnail from './elements/pagesThumbnail/pagesThumbnail';
import { AdditionalStatuses } from './elements/additionalStatuses/additionalStatuses';
import { getMainStatus, getExistingStatuses } from 'utilities/statuses';

var styleConverter = new StyleConverter(componentsStyleMap);

export default class BirdeyeElement extends React.Component {
  elementStyle = (definition, model) => {
    if (typeof definition === 'undefined' && definition === null) {
      return undefined;
    }

    return styleConverter.toStyle(definition, model);
  };

  textElement = (model, definition, point, parentsStyle, style) => {
    var className = model.ignore === true ? 'ignored-text' : '';
    var parentBakground = parentsStyle.find(function (style) {
      return typeof style !== 'undefined' && typeof style.background !== 'undefined' && style.background !== '';
    });
    if (typeof style.color === 'undefined') {
      style.color = typeof parentBakground !== 'undefined' ?
        colorsManager.getContrastColor(colorsManager.toRGB(parentBakground.background)) :
        colorsManager.black;
    }

    return <TextComponent point={point} style={style} className={className}>
      {Mustache.render(definition.value, model)}
    </TextComponent>;
  };

  version = (model, definition, point, parentsStyle, style) => {
    var parentBakground = parentsStyle.find(function (style) {
      return typeof style !== 'undefined' && typeof style.background !== 'undefined' && style.background !== '';
    }),
      content = model.pageContent || model.formContent,
      version = content.externalVersion;
    if (typeof style === 'undefined') {
      style = {};
    }
    if (typeof style.color === 'undefined') {
      style.color = typeof parentBakground !== 'undefined' ?
        colorsManager.getContrastColor(colorsManager.toRGB(parentBakground.background)) :
        colorsManager.black;
    }

    return <Version point={point} style={style} version={content.externalVersion}>
      {Mustache.render(definition.value, content)}
    </Version>;
  };

  separationBox = (model, definition, point, style) => {
    var separations = model.separations || [],
      style = this.elementStyle(definition, model);

    return <SeparationBox point={point} separations={separations} style={style} />;
  };

  separations = (model, style) => {
    var separations = model.separations;

    return <Separations separations={separations} style={style} />;
  };

  separationProgress = (
    model,
    pairModel,
    selectedSeparationIndex,
    handlers,
    definition,
    parentsStyle,
    style,
  ) => {
    var parentBakground = parentsStyle.find(function (style) {
      return typeof style !== 'undefined' && typeof style.background !== 'undefined' && style.background !== '';
    });
    var separations = model.separations;
    const statuses = getExistingStatuses(model);
    var pairStatus = typeof pairModel === 'undefined' ? undefined : getMainStatus(pairModel);

    if (typeof style === 'undefined') {
      style = {};
    }
    if (typeof style.color === 'undefined') {
      style.color = typeof parentBakground !== 'undefined' ?
        colorsManager.getContrastColor(colorsManager.toRGB(parentBakground.background)) :
        colorsManager.black;
    }

    return <SeparationsProgress
      approvalIndication={model.approvalIndication}
      approvalIndicationComplete={model.approvalIndicationComplete}
      approvalIndicationReject={model.approvalIndicationReject}
      approvalCompleteVersion={model.approvalCompleteVersion}
      separations={separations}
      statuses={statuses}
      pairStatus={pairStatus}
      definition={definition}
      style={style}
      onSeparationClick={handlers.handleSeparationClicked}
      selectedSeparationIndex={selectedSeparationIndex}
      model={model}
    />;
  };

  status = (model, style) => {
    var status = getMainStatus(model) || {};

    return <StatusIcon status={status} style={style} />;
  };

  indicator = (model, definition, handlers, point, style) => {
    return <Indicator model={model} definition={definition} handlers={handlers} flow={point.flow} style={style} />;
  };

  additionalStatuses = model => {
    return <AdditionalStatuses item={model} />;
  };

  thumbnail = (
    model,
    thumbnailState,
    selectedSeparationIndex,
    definition,
    containerSize,
    viewConfiguration,
  ) => {
    var thumbnailSize = [containerSize[0] - 100, containerSize[1] - 54];

    return <PagesThumbnail thumbnail={model}
      thumbnailState={thumbnailState}
      thumbnailSize={thumbnailSize}
      selectedSeparationIndex={selectedSeparationIndex}
      definition={definition}
      viewConfiguration={viewConfiguration}
    />;
  };

  render() {
    var {
      point,
      model,
      pairModel,
      definition,
      viewConfiguration,
      containerSize,
      parentsStyle,
      thumbnailState,
      selectedSeparationIndex,
      handlers
    } = this.props,
      style = this.elementStyle(definition, model),
      type = typeof definition.type === 'string' ? definition.type.trim().toLowerCase() : undefined;

    if (model.ignore === true && type !== 'text') return null;

    switch (type) {
      case 'text':
        return this.textElement(model, definition, point, parentsStyle, style);
        break;
      case 'version':
        return this.version(model, definition, point, parentsStyle, style);
        break;
      case 'separation-box':
        return this.separationBox(model, definition, point, style);
        break;
      case 'separations':
        return this.separations(model, style);
        break;
      case 'separations-progress':
        return this.separationProgress(model, pairModel, selectedSeparationIndex, handlers, definition, parentsStyle, style);
        break;
      case 'status':
        return this.status(model, style);
        break;
      case 'indicator':
        return this.indicator(model, definition, handlers, point, style);
        break;
      case 'additional-statuses':
        return this.additionalStatuses(model);
        break;
      case 'thumbnail':
        return this.thumbnail(model, thumbnailState, selectedSeparationIndex, definition, containerSize, viewConfiguration);
        break;
      default:
        return <div></div>;
    }

    return <div></div>;
  }
}