import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import AbstractModule from 'AbstractModule';
import reducer from './reducers/reducer';
import Iframe from './components/Iframe';
import { composeImageUrl } from 'utilities/image';
import TickableModel from '../TickableModel';
import { retriveAndSetEditionNavigatorItems, retriveAndSetSectionNavigatorItems } from '../NewPageView/navigatorControls';
import settingsManager from 'core/managers/settings';
import { getContent } from '../NewPageView/NewPageView';
import { fetchAndSetContentBreadcrumbs } from 'utilities/breadcrumbs';
import { getParentViewRoot } from 'utilities/view';

const isFunction = o => typeof o === 'function';
const isUndefined = o => typeof o === 'undefined';
const missingPageFull = '../kernel/assets/img/module/PageView/missing_page.png';

export default AbstractModule.extend({
  init: function () {
    this._super();

    for (var key in this) {
      if (key !== '_super' && isFunction(this[key])) {
        this[key] = this[key].bind(this);
      }
    }
  },

  initDone: function () {
    // used in the derived classes
    this.tickableModel = new TickableModel();
    this.tickableModel.register(['page'], this.handlePageTick);
    this.tickableModel.register(['form'], this.handlePageTick);
    this.tickableModel.register(['fragment/content'], this.handlePageTick);

    this.toolbar = this.createToolbar();
    this.reactRoot = createRoot(this.domElement);
    this.store = createStore(reducer, {
      projectorId: this.projectorId,
      windowNwid: this.nwid,
      viewId: this.id,
      windowRef: this.win
    });
    this.store.subscribe(this.render);
    this.render();
  },

  setBreadcrumbs: function (model) {
    fetchAndSetContentBreadcrumbs(this, getContent(model));
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.preferences.filters = data.preferences.filters || [];
    this.tickableModel.firstTickHandler(data.model);
    const version = getContent(data.model).version;

    let params = {
      pageAction: data.model.pageAction,
      viewId: data.viewId,
      version: version,
      rootType: data.model.type,
      imageType: data.model.imageType,
      nwid: getContent(data.model).nwid,
      projectorId: this.projectorId
    };

    // Image name should not be empty to compose URL that can be properly processed by REST call

    let imageUrl = '';
    this.pageMissing = false;

    if (version !== '000') {
      let fileName = getContent(data.model).inputFileName || 'NOT_SPECIFIED';
      imageUrl = composeImageUrl(this.nwid, fileName, params);
    } else {
      imageUrl = missingPageFull;
      this.pageMissing = true;
    }

    this.store.dispatch({
      type: 'ADD_IMAGE_URL',
      imageUrl
    });

    let bShowAllSections = settingsManager.get('softproofShowSections');
    if (data.model.section && data.model.section.zone && data.model.section.zone.sections) {
      const parentRoot = getParentViewRoot(this);
      if (parentRoot.type === 'edition') {
        retriveAndSetEditionNavigatorItems(this, this.preferences.filters, data.model, parentRoot);
      } else {
        retriveAndSetSectionNavigatorItems(this, this.preferences.filters, data.model, bShowAllSections, data.model.section.currentSectionName);
      }
    }
  },

  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);
  },

  handlePageTick: function (action, item) {
    if (item !== item.getRoot()) {
      return;
    }

    this.updateSelected([item]);
  },

  render: function () {
    if (isUndefined(this)) return;
    const { imageUrl, windowRef } = this.store.getState();

    this.reactRoot.render(
      <Iframe imageUrl={imageUrl}
        windowRef={windowRef}
        pageMissing={this.pageMissing}
      />);
  }
});
