/**
 * Created by moshemal on 6/16/14.
 */

import logger from 'logger';
import helpMap from 'assets/help/map.json';
import { coretexPath } from 'utilities/url';

const log = logger.getDefaultLogger();

function getHelpRootPath() {
  //TODO: when there will be more languages get Language Code from localization:
  const lang = "en-us";
  return coretexPath('/html/kernel/assets/help', lang, '/');
}

export function openModuleHelp(moduleName, rootType, domWindow) {
  log.info("opening help moduleName: " + moduleName + " rootType: " + rootType);

  let relativeUrl = helpMap["homePage"];
  const modulesMap = helpMap['modules'];

  if (modulesMap[moduleName] && modulesMap[moduleName][rootType]) {
    relativeUrl = modulesMap[moduleName][rootType];
  } else if (modulesMap[moduleName] && modulesMap[moduleName]['default']) {
    relativeUrl = modulesMap[moduleName]['default']
  }

  (domWindow || window).open(getHelpRootPath() + relativeUrl, "_blank", "width=1100, height=900, toolbar=yes, scrollbars=yes, resizable=yes");
}
