import React from 'react';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import { fromServerDate } from 'core/dates';
import TickableModel from '../TickableModel';
import { createStore } from 'redux';
import reducer from './reducer';
import FlatButton from 'components/common/buttons/FlatButton';
import { DataSource } from 'components/private/planeView';
import { createRoot } from 'react-dom/client';

const translate = sandbox.localization.translate;

export const DEFAULT_BACKGROUND_COLOR = '#f0f0f0';
export const WAITING_FOR_APPROVAL_COLOR = '#5eced5';

const itemCreator = (item, flowStepNwid) => {
  const deadline = fromServerDate(item.DeadLine);
  const eventTime = fromServerDate(item.EventTime[flowStepNwid]);
  const title = item.EventTitle[flowStepNwid] || item.name;

  return {
    nwid: item.nwid,
    type: item.type,
    title,
    deadline,
    eventTime
  };
};

export default AbstractModule.extend({
  init: function () {
    this._super();
    for (var prop in this) {
      if (prop !== '_super' && typeof this[prop] === 'function') this[prop] = this[prop].bind(this);
    }
  },

  handleAddItem: function (item) {
    const { flowStepNwid } = this.store.getState();

    this.store.dispatch({
      type: 'addItem',
      item: itemCreator(item, flowStepNwid)
    });
  },

  handleUpdateItem: function (item) {
    const { flowStepNwid } = this.store.getState();

    this.store.dispatch({
      type: 'updateItem',
      item: itemCreator(item, flowStepNwid)
    });
  },

  handleRemoveItem: function (item) {
    const { items } = this.store.getState();

    this.store.dispatch({
      type: 'removeItem',
      nwid: item.nwid,
    });
  },

  handleFlowStep: function (action, flowStep) {
    if (action === 'update') {
      this.store.dispatch({
        type: 'updateFlowStep',
        flowStep: { isSkipOn: flowStep.isSkipOn }
      });
    }
  },

  handlePage: function (action, page) {
    if (action === 'add') this.handleAddItem(page);
    if (action === 'update') this.handleUpdateItem(page);
    if (action === 'remove') this.handleRemoveItem(page);
  },

  initDone: function () {
    sandbox.dom.addClass(this.domElement, 'pro-modules-flowstep-overview');

    this.tickableModel = new TickableModel();
    this.tickableModel.register(['workflow/step/flow/approval'], this.handleFlowStep);

    this.tickableModel.register(['eventqueue', 'page'], this.handlePage);

    this.tickableModel.register(['eventqueue', 'form'], this.handlePage);
  },

  firstTickReceived: function (data) {
    const model = data.model;
    this.viewLinks = model.viewLinks;
    this.toggleSkipAction = this.viewActions.find((a) => a.actionDefinitionName === 'ToggleSkipDeviceActionCR');
    const template = model.iconName && model.iconName !== 'void' ? `${model.type}/${model.iconName}` : model.type;
    this.iconUrl = sandbox.icons.getTemplateIcon(template);

    this.store = createStore(reducer, {
      items: new DataSource({
        items: [],
      }),

      flowStepName: model.flowStepName,
      flowStepNwid: model.nwid,
      flowStepType: model.type,
      isSkipOn: model.isSkipOn,
    });

    this.titleBarElement = sandbox.dom.find('.item-title', this.domElement)[0];
    this.contentElement = sandbox.dom.find('.item-content', this.domElement)[0];
    this.statusBarElement = sandbox.dom.find('.item-statusbar', this.domElement)[0];
    this.titleBarRoot = createRoot(this.titleBarElement);
    this.contentRoot = createRoot(this.contentElement);
    this.statusBarRoot = createRoot(this.statusBarElement);

    this.tickableModel.firstTickHandler(model);

    this.store.subscribe(this.render);
    this.render();

    this.updateBackgroundColor();
  },

  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);

    this.updateBackgroundColor();
  },

  updateBackgroundColor: function () {
    const { items } = this.store.getState();

    const backgroundColor = items.length > 0 ? WAITING_FOR_APPROVAL_COLOR : DEFAULT_BACKGROUND_COLOR;
    if (backgroundColor !== this.lastBackgroundColor) {
      this.lastBackgroundColor = backgroundColor;
      this.domElement.style.backgroundColor = backgroundColor;
    }
  },

  handleToggleSkip: function (event) {
    const state = this.store.getState();
    if (this.toggleSkipAction) {
      this.toggleSkipAction.execute([{ nwid: state.flowStepNwid, type: state.flowStepType, isSkipOn: state.isSkipOn }]);
    }
  },

  handleContextMenu: function (event, item, index) {
    const selectedIem = this.tickableModel.getByNwid(item.nwid);
    this.updateSelected([selectedIem]);
    this.showContextMenu(selectedIem, [selectedIem], event).then(selectedAction => {
      this.render();
    });

    this.render();
  },

  handleDoubleClick: function (event, item, index) {
    const selectedIem = this.tickableModel.getByNwid(item.nwid);

    this.navigateByViewLink(selectedIem);
  },

  renderTitleBar: function () {
    const state = this.store.getState();
    const imageSrc = sandbox.icons.getGeneralIcon('flowStep', state.isSkipOn ? 'skipon' : 'skipoff');
    const tooltip = state.isSkipOn ? translate('Process Workflow Step') : translate('Skip Workflow Step');

    this.titleBarRoot.render(
      <div>
        {this.toggleSkipAction ?
          <FlatButton className='centered left' onClick={event => this.handleToggleSkip(event)} tooltip={tooltip}>
            <img src={imageSrc} />
          </FlatButton> : null}
        <img className='left' style={{ marginLeft: '3px' }} src={this.iconUrl} />
        <div className='title-text'>{state.flowStepName}</div>
      </div>
    );
  },

  renderItem: function (item, index) {
    return (
      <div key={index}
        className='item'
        onContextMenu={event => this.handleContextMenu(event, item, index)}
        onDoubleClick={event => this.handleDoubleClick(event, item, index)}
      >
        {item.title}
      </div>
    );
  },

  renderContent: function () {
    const { items } = this.store.getState();

    this.contentRoot.render(
      <div style={{ width: '100%', height: '100%' }}>
        {items.map((item, index) => this.renderItem(item, index))}
      </div>
    );
  },

  renderStatusBar: function () {
    const state = this.store.getState();

    this.statusBarRoot.render(
      <table summary="events status bar" className=''>
        <tbody>
          <tr>
            <td className='' title={translate('Waiting')}>
              <img style={{ marginRight: '3px' }} src={sandbox.icons.getGeneralIcon('status', 'wait')} />
              {state.items.length}
            </td>
          </tr>
        </tbody>
      </table>
    );
  },

  render: function () {
    this.renderTitleBar();
    this.renderContent();
    this.renderStatusBar();
  },

  destroy: function () {
    this._super();
    this.titleBarRoot.unmount();
    this.contentRoot.unmount();
    this.statusBarRoot.unmount();
  }
});