import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import iconService from 'core/services/iconService';
import StyleConverter from '../styleConverter';
import thumbnailStyleMap from '../models/thumbnailstylemap';
import segmentStyleMap from '../models/segmentStyleMap';
// import AlignmentPanel from './alignmentpanel';
import BirdeyeElement from './birdeyeelement';
import SelectedElement from './selectedElement';
import ClickableElement from './clickableElement';
import AlignmentPoint from './alignmentPoint';
import DroppableArea from './droppableArea';
import pubsub from 'core/managers/pubsub';
import toastService from 'core/services/toastService';

const translate = sandbox.localization.translate;
// const DropErrorMessages = {
//   'DROP_OLD_VERSION': 'The file is older then existing one',
//   'DROP_SAME_VERSION': 'The file has the same version as existing one',
//   'DROP_VERSION_FINISHED_FLOW_PROGRESS': 'Drop is disabled due to drop version policy'
// };

var thumbnailStyleConverter = new StyleConverter(thumbnailStyleMap),
  segmentStyleConverter = new StyleConverter(segmentStyleMap);

export default class Thumbnail extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    thumbnail: undefined,
    scale: undefined,
    thumbnailSize: undefined,
    gridAlign: undefined,
    thumbnailDefinition: undefined,
    alignmentPoints: undefined,
    onClick: undefined,
    onContextMenu: undefined,
    onDoubleClick: undefined
  };

  state = {
    selectedSeparationIndex: null,
    showOnlyPagesInfo: false
  };

  handleSeparationClicked = (ev, separationIndex) => {
    if (this.props.thumbnail.aggregated) {
      return;
    }
    this.setState({
      selectedSeparationIndex: separationIndex
    });

    ev.preventDefault();
    ev.stopPropagation();
  };

  handleIndicatorNewVersionClicked = (ev) => {
    this.setState({
      showOnlyPagesInfo: !this.state.showOnlyPagesInfo
    });

    ev.preventDefault();
    ev.stopPropagation();
  };

  birdeyeElementHandlers = () => {
    return {
      handleSeparationClicked: this.handleSeparationClicked,
      handleIndicatorNewVersionClicked: this.handleIndicatorNewVersionClicked
    };
  };

  getGeneralProperties = (thumbnailDefinition) => {
    return thumbnailDefinition.generalProperties || {};
  };

  getFormViewportDefinition = (thumbnailDefinition, form) => {
    var pageInfoOnForm = form.PageInfoOnForm,
      matrix = pageInfoOnForm.matrix,
      horizontalImageCount = matrix.horizontalImageCount,
      verticalImageCount = matrix.verticalImageCount,
      defaultViewport = typeof thumbnailDefinition.double === 'undefined' ? thumbnailDefinition : thumbnailDefinition.double;

    if (verticalImageCount > horizontalImageCount) {
      return typeof thumbnailDefinition['double-vertical'] === 'undefined' ? defaultViewport : thumbnailDefinition['double-vertical'];
    }

    return defaultViewport;
  };

  getPageViewportDefinition = (thumbnailDefinition, page) => {
    var isPanorama = page.pageContent.isPanorama,
      pageFormat = page.pageContent.format,
      defaultViewport = typeof thumbnailDefinition.single === 'undefined' ? thumbnailDefinition : thumbnailDefinition.single;

    if (isPanorama) {
      if (pageFormat === 'Tabloid') {
        return typeof thumbnailDefinition['double-vertical'] === 'undefined' ? defaultViewport : thumbnailDefinition['double-vertical'];
      }
      return typeof thumbnailDefinition['double'] === 'undefined' ? defaultViewport : thumbnailDefinition['double'];
    }

    return defaultViewport;
  };

  getThumbnailViewportDefinition = (thumbnailDefinition, thumbnail) => {
    if (thumbnail.type === 'form') {
      return this.getFormViewportDefinition(thumbnailDefinition, thumbnail);
    }
    return this.getPageViewportDefinition(thumbnailDefinition, thumbnail);
  };

  getThumbnailStyleDefinition = (thumbnail, generalProperties, controller) => {
    var styleHelperFunctions = controller.styleHelperFunctions || {};
    var pairNwid = thumbnail.pairNwId;
    var pairThumbnail = controller.getByNwid(pairNwid);

    return thumbnailStyleConverter.toStyle(generalProperties, thumbnail, pairThumbnail, styleHelperFunctions, controller);
  };

  getThumbnailContentStyle = (scale, size, align, ignore, styleDefinition) => {
    var style = styleDefinition;
    style.transform = scale !== 1 ? `scale(${scale})` : undefined;// translate(${(scale - 1) * 100}%, ${(scale - 1) * 100}%)` : undefined;
    style.left = scale < 1 ? `-${(size[0] * (1 - scale)) / 2}px` : undefined;
    style.top = scale < 1 ? `-${(size[1] * (1 - scale)) / 2}px` : undefined;
    style.width = size[0];
    style.height = size[1];
    style.float = align;
    style.backgroundImage = ignore ? 'url(' + iconService.getModuleIcon('MyBirdeye', 'ignore_xl') + ')' : null;
    style.backgroundPosition = ignore ? '50% 50%' : null;
    style.backgroundRepeat = ignore ? 'no-repeat' : null;

    return style;
  };

  getElements = (
    alignmentPointDefinition,
    thumbnailContentStyle,
    thumbnail,
    thumbnailSize,
    segmentStyle,
    viewConfiguration,
  ) => {
    var that = this;
    var controller = this.context.controller;
    var thumbnailPairNwid = thumbnail.pairNwId;
    var thumbnailPair = typeof thumbnailPairNwid === 'undefined' ? undefined : controller.getByNwid(thumbnailPairNwid);
    return alignmentPointDefinition.elements.map(function (elementDefinition, elementIndex) {
      return <BirdeyeElement key={[thumbnail.id, alignmentPointDefinition.location, elementIndex].join('.')}
        point={alignmentPointDefinition}
        model={thumbnail}
        pairModel={thumbnailPair}
        containerSize={thumbnailSize}
        definition={elementDefinition}
        viewConfiguration={viewConfiguration}
        parentsStyle={[segmentStyle, thumbnailContentStyle]}
        thumbnailState={that.state}
        selectedSeparationIndex={that.state.selectedSeparationIndex}
        handlers={that.birdeyeElementHandlers()}
      >
      </BirdeyeElement>;
    });
  };

  getAlignedElements = (
    alignmentPointDefinition,
    thumbnailContentStyle,
    thumbnail,
    thumbnailSize,
    segmentLocation,
    segmentStyle,
    viewConfiguration,
  ) => {
    var elements = this.getElements(alignmentPointDefinition, thumbnailContentStyle, thumbnail, thumbnailSize, segmentStyle, viewConfiguration);

    return <AlignmentPoint key={[thumbnail.id, alignmentPointDefinition.location].join('.')} style={segmentStyle} location={segmentLocation} definition={alignmentPointDefinition}>
      {elements}
    </AlignmentPoint>;
  };

  getSegments = (
    thumbnailDefinition,
    alignmentPoints,
    thumbnail,
    thumbnailContentStyle,
    thumbnailSize,
    viewConfiguration,
  ) => {
    var that = this;
    var alignedSegments = [];
    var notAlignedSegments = {};

    if (typeof thumbnailDefinition.segments === 'undefined') {
      return {
        alignedSegments,
        notAlignedSegments
      };
    }

    thumbnailDefinition.segments.forEach(function (segmentDefinition) {
      var segmentLocation = segmentDefinition.location,
        alignmentPointDefinition = Object.assign(segmentDefinition, alignmentPoints[segmentLocation]),
        alignmentPoint,
        segmentStyle = segmentStyleConverter.toStyle(alignmentPointDefinition, thumbnail);

      if (typeof alignmentPoints[segmentLocation] !== 'undefined') {
        alignmentPoint = that.getAlignedElements(alignmentPointDefinition, thumbnailContentStyle, thumbnail, thumbnailSize, segmentLocation, segmentStyle, viewConfiguration);
        alignedSegments.push(alignmentPoint);
      } else {
        alignmentPoint = that.getElements(alignmentPointDefinition, thumbnailContentStyle, thumbnail, thumbnailSize, {}, viewConfiguration);
        notAlignedSegments[segmentLocation] = alignmentPoint;
      }
    });

    return {
      alignedSegments,
      notAlignedSegments
    };
  };

  droppableArea = (thumbnail, alignedSegments) => {
    if (thumbnail.type === 'page') {
      return <DroppableArea onDrop={this.onDrop}>
        {alignedSegments}
      </DroppableArea>;
    }
    return alignedSegments;
  };

  onDrop = (ev) => {

    ev.preventDefault();
    const { thumbnail } = this.props;
    if (thumbnail.aggregated) {
      return;
    }
    const unplannedPages = JSON.parse(ev.dataTransfer.getData('Text'));
    if (unplannedPages[0].publicationName && unplannedPages[0].publicationName.toLowerCase() !== thumbnail.publication.toLowerCase()) {
      sandbox.dialog.alert(translate('The source and target publications do not match'), translate('Cannot drop file'));
    } else {
      this.context.controller.mapUnplannedPage(thumbnail, unplannedPages).then((result) => {
        if (!result.data.success) {
          pubsub.publish('drop-on-page', { unplannedPages, success: false });
          toastService.errorToast(result.data.subject, result.data.message);
        }
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    var controller = this.context.controller;
    var pairNwId = typeof nextProps.thumbnail !== 'undefined' ? nextProps.thumbnail.pairNwId : undefined;
    var pairThumbnail = typeof pairNwId !== 'undefined' ? controller.getByNwid(pairNwId) || {} : {};
    return (
      (nextProps.thumbnail.aggregated && nextProps.thumbnail.relatedPages.some(rp => rp.__isDirty === true)) ||
      //if has changed
      nextProps.thumbnail.__isDirty === true ||
      //if has pair and he is dirty
      pairThumbnail.__isDirty === true ||
      //if selectedSeparationIndex has changed
      this.state.selectedSeparationIndex !== nextState.selectedSeparationIndex ||
      this.props.showOnlyPagesInfo !== nextProps.showOnlyPagesInfo ||
      this.state.showOnlyPagesInfo !== nextState.showOnlyPagesInfo ||
      this.props.scale !== nextProps.scale
    );
  }

  render() {
    var {
        thumbnail,
        scale,
        thumbnailSize,
        gridAlign,
        thumbnailDefinition,
        viewConfiguration,
        alignmentPoints,
        onClick,
        onContextMenu,
        onDoubleClick
      } = this.props,
      controller = this.context.controller,
      thumbnailViewportDefinition = this.getThumbnailViewportDefinition(thumbnailDefinition, thumbnail),
      generalProperties = this.getGeneralProperties(thumbnailDefinition),
      thumbnailContentStyleDefinition = this.getThumbnailStyleDefinition(thumbnail, generalProperties, controller),
      thumbnailContentStyle = this.getThumbnailContentStyle(scale, thumbnailSize, gridAlign, thumbnail.ignore, thumbnailContentStyleDefinition),
      selectionColor = generalProperties.selectionColor,
      {
        alignedSegments,
        notAlignedSegments
      } = this.getSegments(thumbnailViewportDefinition, alignmentPoints, thumbnail, thumbnailContentStyle, thumbnailSize, viewConfiguration);

    return <div className="Form" id={thumbnail.id} key={thumbnail.id}>
      <div className="FormHeader">
        {notAlignedSegments['above']}
      </div>

      <div className="FormContent" style={thumbnailContentStyle}>
        <ClickableElement onClick={onClick}
          onContextMenu={onContextMenu}
          onDoubleClick={onDoubleClick}>

          <SelectedElement model={thumbnail} style={{ borderColor: selectionColor }}>
          </SelectedElement>
          {this.droppableArea(thumbnail, alignedSegments)}

        </ClickableElement>
      </div>

      <div className="FormFooter">
        {notAlignedSegments['under']}
      </div>
    </div>;
  }
}

Thumbnail.propTypes = {
  thumbnail: PropTypes.object,
  scale: PropTypes.number,
  thumbnailDefinition: PropTypes.object,
  viewConfiguration: PropTypes.object,
  alignmentPoints: PropTypes.object,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func,
  thumbnailSize: PropTypes.array,
  gridAlign: PropTypes.string,
  showOnlyPagesInfo: PropTypes.bool,
};