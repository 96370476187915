import React, { Component } from 'react';
import PropTypes from 'prop-types';

class State extends Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
    name: PropTypes.string,
  }

  render() {
    return null
  }
};

module.exports = State;