/**
 * @name imageService
 * @file Provides image related helper functions
 *
 * @author Boris
 * @since: 2020-10-27
 */

import {coretexPath, webAppPath, appendParameters} from 'utilities/url';

export function composeImageUrl(viewOrActionNwid, imageName, params = {}) {
  let url = coretexPath('rest', 'v1', 'image-actions', 'read-image', imageName);
  url = appendParameters(url, {
    viewOrActionNwId: viewOrActionNwid,  // viewOrActionNwId key is case sensitive!
    ...params
  });

  return url;
}
