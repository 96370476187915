import React from 'react';

export default class extends React.Component {
  static defaultProps = {
    min: 0,
    max: 100,
    current: 0,
    color: undefined
  };

  getPercent = (min, max, current) => {
    return (((current - min) * 100) / (max - min)) + '%';
  };

  render() {
    var {
            min,
      max,
      current,
      style,
      children,
      color
          } = this.props,
      width = this.getPercent(min, max, current);

    return <div className="progress-bar-border" style={style}>
      <div className="progress-bar">
        <div className="progress" style={{ width: width, backgroundColor: color }}>
        </div>
        <div className="progress-text">
          {children}
        </div>
      </div>
    </div>;
  }
}