import { translate } from 'core/services/localization';

const ICON_NAME_BY_HOLD_TYPE = {
  content: 'hold_content',
  partial: 'hold_partial',
  scheduled: 'hold_scheduled',
  sorting: 'hold_sorter',
  structure: 'hold_structure',
};

const ICON_TOOLTIP_BY_HOLD_TYPE = {
  content: translate('Content hold'),
  partial: translate('Partial hold'),
  scheduled: translate('Scheduled hold'),
  sorting: translate('Sorter hold'),
  structure: translate('Structure Hold'),
};

export const getHoldIconName = (holdType) => ICON_NAME_BY_HOLD_TYPE[holdType] || '';

export const getHoldIconTooltip = (holdType) => ICON_TOOLTIP_BY_HOLD_TYPE[holdType] || '';

