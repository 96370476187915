import React from 'react';
import PropTypes from 'prop-types';

export default function GenericOption({ columnData, title }) {
  return <div className='crtx-filter-multiselect-item-generic-option' title={title}>
    {columnData}
  </div>
}

GenericOption.propTypes = {
  columnData: PropTypes.any,
  title: PropTypes.string
}