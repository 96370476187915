/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */
import { fromServerDate } from 'core/dates';
import sandbox from 'sandbox';
import Ember from 'ember';
import ProgressBarWidget from 'ProgressBarWidget';
import PlatesProgressBarTemplate from 'text!../templates/PlatesProgressBar.hbs';

var Component = Ember.Component.extend({
  template: Ember.Handlebars.compile(PlatesProgressBarTemplate),
  classNames: ['production-run-progressbar'],
  classNameBindings: ['prograssBarRedColor:production-run-progressbar-red'],
  attributeBindings: ['title'],
  _timer: undefined,
  init: function () {
    this._super();
  },
  totalPlates: function () {
    if (this.get('productionRun') === undefined || this.get('productionRun').get('aggregate') === undefined || this.get('productionRun').get('aggregate') === null) return 0;
    return this.get('productionRun').get('aggregate').get('totalPlates');
  }.property('productionRun.aggregate.totalPlates'),
  totalFinishedPlates: function () {
    if (this.get('productionRun') === undefined || this.get('productionRun').get('aggregate') === undefined || this.get('productionRun').get('aggregate') === null) return 0;
    return this.get('productionRun').get('aggregate').get('totalFinishedPlates');
  }.property('productionRun.aggregate.totalFinishedPlates'),
  progressBarValue: function () {
    if (this.get('productionRun') === undefined || this.get('productionRun').get('aggregate') === undefined || this.get('productionRun').get('aggregate') === null) return 0;
    let ttlFinished = this.get('productionRun').get('aggregate').get('totalFinishedPlates');
    let ttlPlates = this.get('productionRun').get('aggregate').get('totalPlates');
    if (ttlFinished === ttlPlates) {
      this.set('ttlChanged', !this.get('ttlChanged'));
    }
    return ttlFinished / ttlPlates;
  }.property('productionRun.aggregate.totalFinishedPlates', 'productionRun.aggregate.totalPlates'),
  deadline: function () {
    if (!this.get('productionRun') || !this.get('productionRun').get('deadline')) {
      return null;
    }

    return fromServerDate(this.get('productionRun').get('deadline'));
  }.property('productionRun.deadline'),
  deadlinePassed: function () {
    return this.get('productionRun') && this.get('productionRun').get('deadlinePassed') || false;
  }.property('productionRun.deadlinePassed'),
  title: function () {
    return sandbox.localization.toLocaleShortDateTime(this.get('deadline'));
  }.property('deadline'),
  prograssBarRedColor: function () {
    let deadlinePassed = this.get('deadlinePassed');
    let ttlFinished = this.get('productionRun').get('aggregate').get('totalFinishedPlates');
    let ttlPlates = this.get('productionRun').get('aggregate').get('totalPlates');

    return deadlinePassed && ttlFinished !== ttlPlates;
  }.property('deadlinePassed', 'totalPlates', 'totalFinishedPlates'),
  contextMenu: function (ev) {
    var fnc = this.get('contextMenuPlates') || function () { };
    fnc.call(this.get('module') || {}, ev);
  },
  doubleClick: function (ev) {
    var fnc = this.get('openPlates') || function () { };
    fnc.call(this.get('module') || {}, ev);
  },
  pressName: function () {
    return this.get('productionRun').get('_model').press;
  }.property('productionRun._model.press')
});

Ember.Handlebars.helper('ZoneOverview-Plates-ProgressBar', Component);

module.exports = Component;
