import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';
import StyleConverter from '../styleConverter';
import formStyleMap from '../models/formstylemap';
import segmentStyleMap from '../models/segmentStyleMap';
import BirdeyeElement from './birdeyeelement';
import SelectedElement from './selectedElement';
import ClickableElement from './clickableElement';
import AlignmentPoint from './alignmentPoint';

const formStyleConverter = new StyleConverter(formStyleMap);
const segmentStyleConverter = new StyleConverter(segmentStyleMap);

export default class Form extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    form: undefined,
    formSize: undefined,
    gridAlign: undefined,
    formDefinition: undefined,
    alignmentPoints: undefined,
    onClick: undefined,
    onContextMenu: undefined,
    onDoubleClick: undefined
  };

  getGeneralProperties = (formDefinition) => {
    return formDefinition.generalProperties || {};
  };

  getFormViewportDefinition = (formDefinition, form) => {
    var pageInfoOnForm = form.PageInfoOnForm,
      matrix = pageInfoOnForm.matrix,
      horizontalImageCount = matrix.horizontalImageCount,
      verticalImageCount = matrix.verticalImageCount,
      defaultViewport = typeof formDefinition.double === 'undefined' ? formDefinition : formDefinition.double;

    if (verticalImageCount > horizontalImageCount) {
      return typeof formDefinition['double-vertical'] === 'undefined' ? defaultViewport : formDefinition['double-vertical'];
    }

    return defaultViewport;
  };

  getFormStyleDefinition = (form, generalProperties, controller) => {
    var styleHelperFunctions = controller.styleHelperFunctions || {};

    return formStyleConverter.toStyle(generalProperties, form, styleHelperFunctions, controller);
    //return formStyleConverter.toStyle(generalProperties, form);
  };

  getFormContentStyle = (size, align, ignore, styleDefinition) => {
    var style = styleDefinition;
    style.width = size[0];
    style.height = size[1];
    style.float = align;
    style.backgroundImage = ignore ? 'url(' + iconService.getModuleIcon('MyBirdeye', 'ignore_l') + ')' : null;
    style.backgroundPosition = ignore ? '50% 50%' : null;
    style.backgroundRepeat = ignore ? 'no-repeat' : null;

    return style;
  };

  getElements = (alignmentPointDefinition, formContentStyle, form, formSize, segmentStyle) => {
    return alignmentPointDefinition.elements.map(function (elementDefinition, elementIndex) {
      return <BirdeyeElement key={[form.id, alignmentPointDefinition.location, elementIndex].join('.')}
        point={alignmentPointDefinition}
        model={form}
        containerSize={formSize}
        definition={elementDefinition}
        parentsStyle={[segmentStyle, formContentStyle]}>
      </BirdeyeElement>;
    });
  };

  getAlignedElements = (
    alignmentPointDefinition,
    formContentStyle,
    form,
    formSize,
    segmentLocation,
    segmentStyle,
  ) => {
    var elements = this.getElements(alignmentPointDefinition, formContentStyle, form, formSize, segmentStyle);

    return <AlignmentPoint key={[form.id, alignmentPointDefinition.location].join('.')} style={segmentStyle} location={segmentLocation} definition={alignmentPointDefinition}>
      {elements}
    </AlignmentPoint>;
  };

  getSegments = (formDefinition, alignmentPoints, form, formContentStyle, formSize) => {
    var that = this,
      alignedSegments = [],
      notAlignedSegments = {};

    if (typeof formDefinition.segments === 'undefined') {
      return {
        alignedSegments,
        notAlignedSegments
      };
    }

    formDefinition.segments.forEach(function (segmentDefinition) {
      var segmentLocation = segmentDefinition.location,
        alignmentPointDefinition = Object.assign(segmentDefinition, alignmentPoints[segmentLocation]),
        alignmentPoint,
        segmentStyle = segmentStyleConverter.toStyle(alignmentPointDefinition, form);

      if (typeof alignmentPoints[segmentLocation] !== 'undefined') {
        alignmentPoint = that.getAlignedElements(alignmentPointDefinition, formContentStyle, form, formSize, segmentLocation, segmentStyle);
        alignedSegments.push(alignmentPoint);
      } else {
        alignmentPoint = that.getElements(alignmentPointDefinition, formContentStyle, form, formSize, {});
        notAlignedSegments[segmentLocation] = alignmentPoint;
      }
    });

    return {
      alignedSegments,
      notAlignedSegments
    };
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.form.__isDirty === true;
  }

  render() {
    var {
        form,
        formSize,
        gridAlign,
        formDefinition,
        alignmentPoints,
        onClick,
        onContextMenu,
        onDoubleClick
      } = this.props,
      controller = this.context.controller,
      formViewportDefinition = this.getFormViewportDefinition(formDefinition, form),
      generalProperties = this.getGeneralProperties(formDefinition),
      formContentStyleDefinition = this.getFormStyleDefinition(form, generalProperties, controller),
      formContentStyle = this.getFormContentStyle(formSize, gridAlign, form.ignore, formContentStyleDefinition),
      selectionColor = generalProperties.selectionColor,
      overColor = generalProperties.overColor,
      {
        alignedSegments,
        notAlignedSegments
      } = this.getSegments(formViewportDefinition, alignmentPoints, form, formContentStyle, formSize);

    return <div className="Form" id={form.id} key={form.id}>
      <div className="FormHeader">
        {notAlignedSegments['above']}
      </div>
      <div className="FormContent" style={formContentStyle}>
        <ClickableElement onClick={onClick}
          onContextMenu={onContextMenu}
          onDoubleClick={onDoubleClick}
          selectionColor={selectionColor}
          overColor={overColor}>
          <SelectedElement model={form} style={{ borderColor: selectionColor }}>
          </SelectedElement>

          {alignedSegments}
        </ClickableElement>
      </div>
      <div className="FormFooter">
        {notAlignedSegments['under']}
      </div>
    </div>;
  }
}

Form.propTypes = {
  form: PropTypes.object,
  formSize: PropTypes.array,
  gridAlign: PropTypes.string,
  formDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func
};