import React, { Component } from 'react';
import PropTypes from 'prop-types';
import jsUtils from 'sandbox/jsUtils';
import GridItem from './griditem';
import Page from './page';

var DEFAULT_SINGALE_PAGE_SIZE = [80, 80],
  DEFAULT_DOUBLE_PAGE_SIZE = [160, 80];
  // DEFAULT_DOUBLE_VERTICAL_PAGE_SIZE = [80, 160];

export default class PageWrapper extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static propTypes = {
    page: function (props, propName) {
      var value = props[propName];

      if (!jsUtils.isObject(value) || typeof value.type !== 'string' || value.type.toLowerCase() !== 'page') {
        return new Error(propName + ', is not a valid page');
      }
    }
  };

  static defaultProps = {
    onClick: function () { },
    page: undefined,
    pageDefinition: {},
    alignmentPoints: [],
    gridSize: undefined,
    gridMargin: undefined,
    gridAlign: undefined
  };

  state = {
    updateTime: Date.now()
  };

  getPageSize = page => page.pageContent.isPanorama ? DEFAULT_DOUBLE_PAGE_SIZE : DEFAULT_SINGALE_PAGE_SIZE;

  isVisible = (page) => {
    var filterArgument = this.context.module.ctrl.filterArgument;
    if (typeof filterArgument !== 'undefined' && !filterArgument(page)) {
      return false;
    }
    return true;
  };

  onClick = (page, e) => {
    if (typeof this.props.onClick !== 'function') {
      return;
    }
    this.props.onClick(page, e);
  };

  onContextMenu = (page, e) => {
    if (typeof this.props.onContextMenu !== 'function') {
      return;
    }
    this.props.onContextMenu(page, e);
  };

  onDoubleClick = (page, e) => {
    if (typeof this.props.onDoubleClick !== 'function') {
      return;
    }
    this.props.onDoubleClick(page, e);
  };

  render() {
    var {
        page,
        pageDefinition,
        alignmentPoints,
        gridSize,
        gridMargin,
        gridAlign,
        onClick,
        onContextMenu,
        onDoubleClick,
        onOpenChange,
        onAggregatedItemClick
      } = this.props,
      pageSize = this.getPageSize(page),
      isVisible = this.isVisible(page);

    return <GridItem gridSize={gridSize}
      gridMargin={gridMargin}
      gridAlign={gridAlign}
      size={pageSize}
      isVisible={isVisible}
      aggregated={page.aggregated}
      itemNwid={page.nwid}
      onOpenChange={onOpenChange}
      onClick={onClick}
      onAggregatedItemClick={onAggregatedItemClick}
      itemDefinition={pageDefinition}
      alignmentPoints={alignmentPoints}
      numOfRelatedPages={page.aggregated ? page.relatedPages.length : 0}>

      <Page page={page}
        pageSize={pageSize}
        gridAlign={gridAlign}
        pageDefinition={pageDefinition}
        alignmentPoints={alignmentPoints}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}>
      </Page>
    </GridItem>;
  }
}

PageWrapper.propTypes = {
  page: PropTypes.object,
  pageDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  onOpenChange: PropTypes.func,
  onClick: PropTypes.func,
  onAggregatedItemClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func,
  gridSize: PropTypes.array,
  gridMargin: PropTypes.number,
  gridAlign: PropTypes.string,
};