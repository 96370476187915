/**
 * @name UserInfo
 * @file User Info module rendered in the upper-left corner
 *
 * @author Boris
 * @since: 2017-07-16
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { translate } from 'core/services/localization';
import { startModule } from 'core/managers/module';
import sandbox from 'sandbox';
import commCenterService from 'core/services/commCenterService';
import chatService from 'core/services/chatService';
import UserInfoPanel from './components/UserInfoPanel';
import settingsManager from 'core/managers/settings';
import viewManager from 'core/managers/views';
import toastService from 'core/services/toastService';

const TOAST_DELAY = 10000;
const AbstractModule = require('AbstractModule');

module.exports = AbstractModule.extend({

  initialModel: null,
  user: null,
  logoUrl: '',
  unreadMailCount: 0,
  unreadMessages: 0,

  init: function () {
  },

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.user = settingsManager.get('user');
    this.logoUrl = this.getAppLogoUrl();
    this.menuItems = [
      {
        id: 'setup',
        text: translate('User Preferences'),
        iconName: 'settings',
        onSelect: () => {
          const action = this.viewActions.find((a) => a.actionDefinitionName === 'OpenUserConfigCR');
          if (action) {
            action.execute([this.initialModel]);
          }
        }
      },
      {
        id: 'profile',
        text: translate('Change Password'),
        iconName: 'account_circle',
        onSelect: () => {
          const action = this.viewActions.find((c) => c.actionDefinitionName === 'OpenChangeUserPasswordCR');
          if (action) {
            action.execute([this.initialModel]);
          }
        }
      },
      {
        id: 'logout',
        text: translate('Logout'),
        iconName: 'exit_to_app',
        onSelect: () => {
          sandbox.logout();
        }
      }
    ];
  },

  firstTickReceived: function (data) {
    this.initialModel = data.model;
    this.unregisterCommCenter = commCenterService.register((mails) => {
      this.unreadMailCount = this.countUnreadMails(mails);

      if (settingsManager.get('afterLogin') && !this.commCenterToastDone && this.unreadMailCount > 0) {
        toastService.commCenterToast(translate('You have {1} unread messages', this.unreadMailCount), translate('Open the comm center to read them'), { delay: TOAST_DELAY });
      }
      this.commCenterToastDone = true;

      this.render();
    });

    this.unregisterChat = chatService.register((chats) => {
      this.unreadMessages = this.countUnreadMessages(chats);
      if (settingsManager.get('afterLogin') && !this.chatToastDone && this.unreadMessages > 0) {
        toastService.chatToast(translate('You have {1} unread chat messages', this.unreadMessages), translate('Open the chat to read them'), { delay: TOAST_DELAY });
      }
      this.chatToastDone = true;

      this.render();
    });

    this.render();
  },

  tickUpdate: function () {

  },

  countUnreadMails: function (mails) {
    if (!mails) {
      return 0;
    }

    return mails.reduce((count, mail) => mail.isRead ? count : count + 1, 0);
  },


  countUnreadMessages: function (chats) {
    if (!chats) {
      return 0;
    }

    return chats.totalNewMessagesCount;
  },

  getAppLogoUrl: function () {
    const iconName = settingsManager.get('productSkinName') === 'newsway' ? 'logo_ProImage' : 'logo_Agfa';
    return sandbox.icons.getModuleIcon('Userinfo', iconName);
  },

  handleGlobalSetupButtonClick: function (e) {
    if (this.initialModel.globalSetupLink) {
      const globalSetupLink = viewManager.getViewInfo(this.initialModel.globalSetupLink);
      startModule(globalSetupLink.nwid, null, globalSetupLink);
    }
  },

  handleCommCenterButtonClick: function (e) {
    if (this.initialModel.commCenterViewLink) {
      const commCenterViewLink = viewManager.getViewInfo(this.initialModel.commCenterViewLink);
      startModule(commCenterViewLink.nwid, null, commCenterViewLink);
    }
  },

  handleChatButtonClick: function (e) {
    if (this.initialModel.chatViewLink) {
      const chatViewLink = viewManager.getViewInfo(this.initialModel.chatViewLink);
      startModule(chatViewLink.nwid, null, chatViewLink);
    }
  },

  handleUserConfig: function () {
    const action = this.viewActions.find((a) => a.actionDefinitionName === 'OpenUserConfigCR');
    if (action) {
      action.execute([this.initialModel]);
    }
  },

  handleLogout: function () {
    sandbox.logout();
  },

  destroy: function () {
    this._super();
    this.unregisterCommCenter();
    this.unregisterChat();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <UserInfoPanel
        initialModel={this.initialModel}
        user={this.user}
        logoUrl={this.logoUrl}
        unreadMailCount={this.unreadMailCount}
        unreadMessages={this.unreadMessages}
        onGlobalSetupButtonClick={(e) => this.handleGlobalSetupButtonClick(e)}
        onCommCenterButtonClick={(e) => this.handleCommCenterButtonClick(e)}
        onChatButtonClick={(e) => this.handleChatButtonClick(e)}
        menuItems={this.menuItems}
      />);
  }

});
