/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define( [ 'sandbox', 'ember' ],
  function ( sandbox, Ember ) {
    'use strict';

    function StatusModel ( model, parentId ) {
      if ( model === undefined || model === null || model.id === undefined || model.nwid === undefined ) return undefined;
      return Ember.Object.create({
        id: model.id,
        nwid: model.nwid,
        type: model.type || 'status',
        message: model.message,
        progress: model.progress,
        statusType: model.statusType,
        flowStepType: model.flowStepType,
        parentId: parentId,
        '_model': model
      });
    }

    return StatusModel;
  });