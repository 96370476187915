/**
 * @name FlowStep
 * @file FlowStep component
 *
 * @author Boris
 * @since: 2019-11-05
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox, { localization, icons } from 'sandbox';
import { isEqual } from 'base/jsUtils';
import { classNames } from 'utilities/classNames';
import Table, { Column, cells, headers, filters } from 'widgets/ReactDataGrid';
import FlatButton from 'components/common/buttons/FlatButton';
import ProgressBar from 'components/common/progress/ProgressBar';
import IndeterminateProgressBar from 'components/common/progress/IndeterminateProgressBar';
import {
  STATUS_BY_QUEUE_TYPE,
  LABEL_BY_QUEUE_TYPE,
  EVENT_COUNTS_ORDER,
} from './constants';
import { getEventTableCellData, getEventTableCellTooltip } from './columnsCreator';
import { FILTER_TYPE, ROW_HEIGHT } from 'widgets/ReactDataGrid/utils';
import iconService from 'core/services/iconService';
import IconButton from 'components/common/buttons/IconButton';
import { MediaTypeDialog } from './MediaTypeDialog';

const { GenericHeader, HeaderCaption } = headers;
const { MultiselectFilter, DateFilter, TextInputFilter } = filters;

const { Text, IconCell } = cells;

const { translate } = localization;

const TABLE_WIDTH = 1600;
const SPLITTER_SIZE = 7;

const MIN_COLUMN_WIDTH = 20;

function getInProgressEventName(event) {
  if (!event) {
    return '';
  }

  return (event.Name || event.Breadcrumbs || event.object && event.object.name || '') + (event.colorName || '');
}

function getCellComponent(column) {
  return (column.key === 'objectType' || column.key === 'state') ? IconCell : Text;
}

const FILTER_ICON_URL = iconService.getActionIcon('filter_list.svg');

export default class FlowStep extends Component {
  static propTypes = {
    model: PropTypes.object
  };

  state = {
    editMediaType: false
  };

  componentDidMount() {
    this.initSplitter();
  }

  hasResourceTable() {
    const { module } = this.props;

    return !module.isApprovalStep();
  }

  initSplitter() {
    if (!this.hasResourceTable()) {
      return;
    }

    const { module } = this.props;

    const splitterContainer = sandbox.dom.find('.flow-step-main', module.domElement);
    splitterContainer.kendoSplitter({
      orientation: 'vertical',
      panes: module.preferences.panes || [
        {
          size: '20%',
          collapsible: true,
          collapsed: false,
          resizable: true
        },
        {
          collapsible: true,
          collapsed: false,
          resizable: true
        }
      ],
      resize: () => {
        const splitter = sandbox.dom.data(splitterContainer, 'kendoSplitter');
        let panes = (splitter?.options?.panes || []).map(pane => ({ ...pane }));
        if (!this.prevPanes) {
          this.prevPanes = panes.map(pane => ({ ...pane }));
        }

        const totalHeight = splitter?.element[0]?.clientHeight;
        let size = panes[0]?.size || '';
        if (size.includes('px') && !isNaN(totalHeight)) {
          size = parseInt(size);
          const sizePercent = size / (totalHeight - SPLITTER_SIZE) * 100;
          panes[0].size =  Math.min(Math.max(sizePercent, 0), 100) + '%';
          if (size > totalHeight) {
            splitter.size('.k-pane:first', panes[0].size);
          }
        }

        if (!isEqual(panes, this.prevPanes)) {
          this.prevPanes = panes;
          module.savePreferences({ panes });
        }
      }
    });
  }

  getStatusBarItems() {
    const { viewModel } = this.props;

    const result = [];
    EVENT_COUNTS_ORDER.map(queueType => {
      const queue = viewModel.queuesByType[queueType] || {};
      const eventCount = (queue.events || []).length;
      const eventCountInTable = viewModel.eventCountByQueueType[queueType];
      const mismatch = eventCount !== eventCountInTable;
      const mismatchTitle = mismatch ? `${translate('Mismatch: number of events in table is')} ${eventCountInTable}` : '';

      result.push({
        queueType,
        eventCount,
        mismatch,
        mismatchTitle,
        icon: icons.getGeneralIcon('status', STATUS_BY_QUEUE_TYPE[queueType]),
        iconTitle: LABEL_BY_QUEUE_TYPE[queueType]
      });
    });

    return result;
  }

  getMaxInProgressEvents() {
    const { viewModel } = this.props;

    return viewModel.resources.reduce((maxEvents, r) => Math.max(maxEvents, r.maxInProgressEvents), 1);
  }

  getInProgressEventTooltip(event) {
    if (!event) {
      return '';
    }

    const { viewModel, eventTableColumns } = this.props;

    return eventTableColumns.map(col => `${col.caption}: ${getEventTableCellTooltip(event, col, viewModel)}`).join('\n');
  }

  renderProgressBar(event, vertical) {
    let result;

    const className = vertical ? 'vertical-progress-bar' : 'progress-bar';

    if (!event) {
      result = <div className={className} />;
    } else {
      const progress = event.adjustedProgress;
      if (progress >= 0) {
        result = <ProgressBar
          className={className}
          value={progress}
          vertical={vertical}
          animated={true}
        />;
      } else {
        result = <IndeterminateProgressBar
          className={className}
          vertical={vertical}
        />
      }
    }

    return result;
  }

  renderInProgressCells(resource) {
    let cells;

    const maxInProgressEvents = this.getMaxInProgressEvents();
    if (maxInProgressEvents <= 1) {
      const event = resource.events[0];
      cells = [
        <td key='name' title={this.getInProgressEventTooltip(event)}>
          {getInProgressEventName(event)}
        </td>,
        <td key='progress' className='progress'>
          {this.renderProgressBar(event, false)}
        </td>
      ];
    } else {
      cells = Array.from({ length: maxInProgressEvents }, (v, k) => {
        const event = resource.events[k];

        return (
          <td key={k}>
            {k < resource.maxInProgressEvents ?
              <div className={'vertical-progress-bar-with-text'}>
                {this.renderProgressBar(event, true)}
                <div className={'text'} title={this.getInProgressEventTooltip(event)}>
                  {getInProgressEventName(event)}
                </div>
              </div> : undefined
            }
          </td>
        );
      });
    }

    return cells;
  }

  handleEditResourceMediaTypes = resource => {
    this.currentResource = resource;
    this.setState({ editMediaType: true });
  };

  renderMediaTypeCell(resource) {
    const { mediaState: { mediaCounts = {}, currentCtpMediaType = '' } = {}, curentMediaType: currentMediaType = '', minimumPlates = 0, disabledMediaTypes = [] } = resource;

    if (!resource.on) {
      return <td key='media-type' />;
    }

    // ***TEST
    // const currentMediaType = 'Doubleeeeeeeeeeeee';
    // const mediaState = { mediaCounts: { "Single": "10", "Doubleeeeeeeeeeeee": "2" }, currentCtpMediaType: 'Single' };
    // const { mediaCounts = {}, currentCtpMediaType = '' } = mediaState;
    // const minimumPlates = '2';

    const activeMediaType = currentCtpMediaType || currentMediaType;

    const mediaTypes = Object.entries(mediaCounts).map(([name, count]) => ({
      name,
      count,
      current: name === activeMediaType,
      warning: parseInt(count) <= parseInt(minimumPlates),
      blink: name === activeMediaType && currentCtpMediaType && currentMediaType && currentCtpMediaType !== currentMediaType,
      disabled: resource.disabledMediaTypes?.some(item => item === name),
    }));

    let title, content;
    if (mediaTypes.length > 0) {
      title = mediaTypes.map(({ name, count }) => `${name} (${count})`).join(', ');
      content = mediaTypes.reduce((acc, { name, count, current, warning, blink, disabled }, index, arr) => {
        const value = `${name} (${count})`;
        acc.push(<span key={index} className={classNames({ current, warning, blink, disabled })}>{value}</span>);
        index < arr.length - 1 && acc.push(', ');

        return acc;
      }, []);
    } else if (activeMediaType) {
      content = `${translate('Active Media:')} ${activeMediaType}`;
      title = content;
    }

    const showEditButton = mediaTypes.length > 0;

    return (
      <td key='media-type' title={title}>
        <div className='media-type-cell-container'>
          <label className='media-type-cell-content-wrapper'>{content}</label>
          {showEditButton && <IconButton
            key='edit'
            className='media-type-cell-edit-resource-button'
            iconName='edit'
            tooltip={translate('Edit')}
            onClick={() => this.handleEditResourceMediaTypes(resource)}
          />}
        </div>
      </td>
    );
  }

  renderResourceRow = (resource) => {
    const { module } = this.props;

    const ledIcon = module.getResourceLedIcon(resource);

    return (
      <tr key={resource.id}
        onContextMenu={e => module.handleResourceTableContextMenu(resource, [resource], e)}>
        <td key='toggle' className='centered'>
          <FlatButton
            className={'flat-button'}
            tooltip={translate('Toggle Resource')}
            onClick={e => module.toggleResource(resource)}
          >
            <img src={icons.getGeneralIcon('resource', resource.on ? 'on' : 'off')} />
          </FlatButton>
        </td>
        <td key='abort' className='centered'>
          <FlatButton
            className={'flat-button'}
            tooltip={translate('Abort Resource')}
            onClick={e => module.abortResource(resource)}
          >
            <img src={icons.getGeneralIcon('resource', 'abort_resource')} />
          </FlatButton>
        </td>
        <td key='led' className='centered'>
          <img src={ledIcon.url} title={ledIcon.title} alt={ledIcon.title} />
        </td>
        <td key='name' title={resource.name}>{resource.name}</td>
        {module.hasResourcePathColumn() ? <td title={resource.desc}>{resource.desc}</td> : undefined}
        <td key='lock' className='centered' title={resource.locked}>{resource.locked}</td>
        <td key='message' title={resource.message}>{resource.message}</td>
        {module.hasMediaTypeColumn() ? this.renderMediaTypeCell(resource) : undefined}
        {this.renderInProgressCells(resource)}
        <td key='edit' className='centered'>
          <FlatButton
            className={'flat-button'}
            tooltip={translate('Edit Resource')}
            disabled={!module.canEditResource(resource)}
            onClick={e => module.editResource(resource)}
          >
            <img src={icons.getGeneralIcon(null, 'edit')} />
          </FlatButton>
        </td>
        {module.canRemoveResource(resource) ?
          <td key='remove' className='centered'>
            <FlatButton
              className={'flat-button'}
              tooltip={translate('Remove Resource')}
              disabled={!module.canRemoveResource(resource)}
              onClick={e => module.removeResource(resource)}
            >
              <img src={icons.getGeneralIcon(null, 'delete')} />
            </FlatButton>
          </td>
          : undefined
        }
      </tr>
    );
  };

  renderInProgressHeaders() {
    let headers;

    const maxInProgressEvents = this.getMaxInProgressEvents();
    if (maxInProgressEvents <= 1) {
      headers = [
        <th key='name' className='event-name'>{translate('Name')}</th>,
        <th key='progress' className='progress'>{translate('Progress')}</th>
      ];
    } else {
      headers = Array.from({ length: maxInProgressEvents }, (v, k) => <th key={k} />);
    }

    return headers;
  }

  renderResourceTable() {
    if (!this.hasResourceTable()) {
      return;
    }

    const { module, viewModel } = this.props;

    return (
      <div className='flow-step-resources'>
        <div className='flow-step-resources-scrollable'>
          <table>
            <thead>
              <tr>
                <th key='states' colSpan='3' className='states' />
                <th key='name' className='name'>{translate('Resource')}</th>
                {module.hasResourcePathColumn() ? <th key='path' className='path'>{translate('Path')}</th> : undefined}
                <th key='lock' className='lock' title={translate('Locked')}>
                  <img src={icons.getGeneralIcon('status', 'locked')} alt={translate('Locked')} />
                </th>
                <th key='message' className='message'>{translate('Device Message')}</th>
                {module.hasMediaTypeColumn() ?
                  <th key='media-type' className='media-type'>{translate('Media Type')}</th> : undefined}
                {this.renderInProgressHeaders()}
                <th key='edit' className='edit' />
                {module.hasRemoveResourceColumn() ? <th key='remove' className='remove' /> : undefined}
              </tr>
            </thead>

            <tbody className='flow-step-resources-tbody'>
              {viewModel.resources.map(this.renderResourceRow)}
            </tbody>

          </table>
        </div>
      </div>
    );
  }

  renderColumnFilter(column) {

    const { filter } = column;

    if (!filter) {
      return;
    }

    const { module } = this.props;

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:
        const selectedValues = Array.isArray(filter.selected) && filter.selected || [];
        return (
          <MultiselectFilter
            selectedValues={selectedValues}
            options={filter.options}
            dataType={filter.dataType}
            shouldSortOptions={filter.shouldSortOptions}
            onSelect={(event, values, index) => module.handleColumnFilterChange(column, { selected: values })}
          />
        );
      case FILTER_TYPE.DATE:
        return (
          <DateFilter
            filter={filter}
            onApply={(filter) => module.handleColumnFilterChange(column, filter)}
          />
        );
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            textValue={filter.textValue}
            onChange={(e, textValue) => module.handleColumnFilterChange(column, { textValue })}
          />
        );
    }
  }

  renderColumnHeaderCaption(column) {
    return (
      <HeaderCaption tooltip={column.caption}>
        {column.caption}
      </HeaderCaption>
    );
  }

  renderColumnHeaderFilter(column) {
    const { module } = this.props;

    if (!module.filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {this.renderColumnFilter(column)}
      </div>
    );
  }

  renderColumnHeader(column) {
    return (
      <GenericHeader
        captionRenderer={this.renderColumnHeaderCaption(column)}
        filterRenderer={this.renderColumnHeaderFilter(column)}
      />
    );
  }

  renderEventTable() {
    const { module, viewModel, eventTableColumns } = this.props;

    const defaultWidth = TABLE_WIDTH / (eventTableColumns.length || 1);
    const headerHeight = module.filtersEnabled ? 61 : 31;

    return (
      <div className='flow-step-events'>
        <Table
          rows={viewModel.events}
          columnKey='id'
          virtualScroll={true}
          fixed={true}
          minColumnWidth={MIN_COLUMN_WIDTH}
          headerHeight={headerHeight}
          rowHeight={ROW_HEIGHT}
          onColumnsFilter={(columnKey, visible, columns) => module.handleEventTableColumnsFilter(columns)}
          onColumnsOrder={(columns, oldIndex, newIndex) => module.handleEventTableColumnOrder(columns, oldIndex, newIndex)}
          onSelect={selectedRows => module.handleEventTableSelect(selectedRows)}
          onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleEventTableContextMenu(rowContent, selectedRows, e)}
          onColumnResize={(columns, columnKey) => module.handleEventTableColumnResize(columns)}
          onDeleteKey={(selectedRows, e) => module.handleEventTableDeleteKey(selectedRows, e)}
          showFilterOptionInHeaderMenu={true}
          filtersEnabled={module.filtersEnabled}
          onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
        >
          {eventTableColumns.map((col, index) => {
            return <Column
              key={col.key}
              title={col.caption}
              columnKey={col.key}
              width={col.width || defaultWidth}
              align={col.align}
              visible={col.visible}
              header={this.renderColumnHeader(col)}
              cell={getCellComponent(col)}
              cellDataGetter={(rowIndex, columnKey) => getEventTableCellData(viewModel.events[rowIndex], col, viewModel)}
              shouldCellUpdate={col.shouldCellUpdate}
            />;
          })}
        </Table>
      </div>
    );
  }

  renderStatusBarItem = (item, index) => {
    const className = `flow-step-status-bar-item ${item.mismatch ? 'mismatch' : ''}`;

    return (
      <div key={index} className={className}>
        <img src={item.icon} title={item.iconTitle} />
        <span title={item.mismatchTitle}>{item.eventCount}</span>
      </div>
    );
  };

  renderStatusBar() {
    return (
      <div className='flow-step-status-bar'>
        {this.getStatusBarItems().map(this.renderStatusBarItem)}
        <div className='flow-step-status-bar-filtered-events'>
          <img src={FILTER_ICON_URL} />
          <label>{translate('Filtered events:')}</label>
          <label>{this.props.viewModel.events.length}</label>
        </div>

      </div>
    );
  }

  render() {
    return (
      <div className='flow-step-view'>
        {this.state.editMediaType && <MediaTypeDialog
          onClose={() => this.setState({ editMediaType: false })}
          title={translate('Enable / Disable Media Type')}
          resource={this.currentResource}
          moduleNwid={this.props.module.nwid}
        />}
        <div className='flow-step-main'>
          {this.renderResourceTable()}
          {this.renderEventTable()}
        </div>
        {this.renderStatusBar()}
      </div>
    );
  }
}