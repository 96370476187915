import React, { Component } from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';
import Table, { Column, cells, headers, filters } from 'widgets/ReactDataGrid';
import utils from '../utils';
import SimpleFormComponent from '../SimpleFormComponent';
import actions from '../actions';

const { SortableHeader, GenericHeader, HeaderCaption } = headers;
const { IconCell } = cells;
const { TextInputFilter, DateFilter } = filters;

const ADD_ROW_ICON_SRC = iconService.getGeneralIcon('', 'add');
const REMOVE_ROW_ICON_SRC = iconService.getGeneralIcon('', 'delete');

export default SimpleFormComponent(class extends Component {
  static propTypes = {

    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,

    bind: PropTypes.string,

    disabled: PropTypes.bool,

    className: PropTypes.string,

    headerHeight: PropTypes.number,

    rowHeight: PropTypes.number,

    items: PropTypes.array,

    sortColumnKey: PropTypes.string,

    sortOrder: PropTypes.string,

    showLoadingProgress: PropTypes.bool,

    sortRows: PropTypes.func,

    addRow: PropTypes.bool,

    removeRow: PropTypes.bool,

    onAddRow: PropTypes.func,

    onRemoveRow: PropTypes.func,

    newRowData: PropTypes.func,

    onColumnFilterChange: PropTypes.func,

  };

  static defaultProps = {

    bind: '',

    headerHeight: 20,

    rowHeight: 30,

    addRow: false,

    removeRow: false,

    newRowData: function (index) {
      return {
        index
      };
    }

  };

  handleChange = (rowIndex, columnKey, val) => {

    var { store, bind } = this.props;

    var bindStr = bind + '[' + rowIndex + '].' + columnKey;
    store.dispatch(actions.update(bindStr, val));

  };

  cellDataGetter = (props) => {

    var that = this;

    return function (rowIndex, columnKey) {

      var { store, bind } = that.props;
      var items = store.get(bind);

      var elProps = utils.isObject(props) ? props :
        utils.isFunction(props) ? props(bind, rowIndex, columnKey) :
          {};

      return {
        columnData: items[rowIndex][columnKey],
        onChange: utils.isFunction(elProps.onChange) ? elProps.onChange : that.handleChange,
        ...elProps
      };

    }

  };

  shouldCellUpdate = (nextProps, props) => {

    return nextProps.columnData !== props.columnData;

  };

  getElementByType = (Element, props) => {

    var { bind } = this.props;

    return <Element />;

  };

  addIconActionColumn = (columnKey, iconActionDef, showHeaderContent) => {

    var { bind } = this.props;
    var { title, icon, iconClassName, actionFuncHeader, actionFuncRow } = iconActionDef;

    var header = (props) => <SortableHeader {...props}>
      <img src={icon}
        className={iconClassName}
        title={title}
        onClick={() => (utils.isFunction(actionFuncHeader) ? actionFuncHeader(bind, 0) : {})} />
    </SortableHeader>;

    if (showHeaderContent === false) {
      header = (props) => <SortableHeader {...props} />;
    }

    return <Column key={columnKey}
      columnKey={columnKey}
      width={37}
      align="center"
      autoWidth={false}
      header={header}
      cell={
        <IconCell icon={icon}
          iconClassName={iconClassName}
          title={title}
          onClick={(index, columnKey, ev) => actionFuncRow(bind, columnKey === 'add' ? index + 1 : index)}
        />
      }
      cellDataGetter={(rowIndex, columnKey) => {
        return {
          columnData: rowIndex
        };
      }
      }
      shouldCellUpdate={(nextProps, props) => {
        return false;
      }}
      resizable={false}
      fixed="right"
    />;

  };

  handleRowSelection = (selectedRows) => {

    var {
      bind,
      onSelectRow
    } = this.props;

    if (utils.isFunction(onSelectRow)) {
      onSelectRow(bind, selectedRows);
    }

  };

  handleAddRow = (bind, index) => {

    let { store, newRowData } = this.props;

    store.dispatch({
      type: 'ADD',
      bind,
      index,
      value: newRowData(index)
    });

  };

  handleRemoveRow = (bind, index) => {

    let { store } = this.props;

    store.dispatch({
      type: 'REMOVE',
      bind,
      index
    });

  };

  areFiltersEnabled = () => {
    const { onColumnFilterChange, sortAndFilterEnabled } = this.props;

    return typeof onColumnFilterChange === 'function' && sortAndFilterEnabled;
  };

  renderColumnHeader = (column) => {

    return (
      <GenericHeader
        captionRenderer={this.renderColumnHeaderCaption(column)}
        filterRenderer={this.renderColumnHeaderFilter(column)}
      />
    );
  };


  renderColumnHeaderCaption(column) {
    const { onColumnSortChange, sortAndFilterEnabled } = this.props;
    const sortDirection = column.sort ? column.sort.ascending ? 'asc' : 'desc' : undefined;
    const sortable = sortAndFilterEnabled && typeof column.sortType !== 'undefined';

    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={column.sort?.order} tooltip={column.title}
        onSort={multiSort => onColumnSortChange(column, multiSort)} sortable={sortable}
      >
        {column.title}
      </HeaderCaption>
    );
  };

  renderColumnHeaderFilter(column) {
    if (!this.areFiltersEnabled()) {
      return null;
    }

    return (
      <div className='column-header-filter'>
        {this.renderColumnFilter(column)}
      </div>
    );
  };

  renderColumnFilter(column) {
    const { onColumnFilterChange } = this.props;
    const { filter = {}, filterType = '' } = column;

    if (!filterType) {
      return;
    }

    switch (filterType) {
      case 'date':
        return (
          <DateFilter
            filter={filter}
            onApply={filter => onColumnFilterChange(column, filter)}
          />
        );
      default:
        return (
          <TextInputFilter
            textValue={filter.textValue}
            onChange={(e, textValue) => onColumnFilterChange(column, { textValue })}
          />
        );
    }

  };

  renderColumns = (columnDefinitions, addRow, onAddRow, removeRow, onRemoveRow) => {

    var columns = columnDefinitions.map((columnDefinition, index) => {
      let { columnKey, title, width, cell, header, props, shouldCellUpdate, autowidth, align, sortable, resizable, flex, ...columnDefinitionProps } = columnDefinition;

      return <Column
        key={index}
        columnKey={columnKey}
        width={width}
        align={align}
        autoWidth={autowidth}
        header={header || this.renderColumnHeader(columnDefinition)}
        cell={this.getElementByType(cell, props)}
        cellDataGetter={this.cellDataGetter(props)}
        shouldCellUpdate={utils.isFunction(shouldCellUpdate) ? shouldCellUpdate : this.shouldCellUpdate}
        resizable={resizable}
        flex={flex}
        fixed={columnDefinition.pinColumnForScroll ? 'left' : undefined}
        {...columnDefinitionProps}
      />;
    });

    if (addRow || utils.isFunction(onAddRow)) {
      columns.push(this.addIconActionColumn("add", {
        title: "Add",
        icon: ADD_ROW_ICON_SRC,
        iconClassName: "icon-add",
        actionFuncHeader: utils.isFunction(onAddRow) ? onAddRow : this.handleAddRow,
        actionFuncRow: utils.isFunction(onAddRow) ? onAddRow : this.handleAddRow
      }));
    }
    if (removeRow || utils.isFunction(onRemoveRow)) {
      columns.push(this.addIconActionColumn("remove", {
        title: "Delete",
        icon: REMOVE_ROW_ICON_SRC,
        iconClassName: "icon-remove",
        actionFuncRow: utils.isFunction(onRemoveRow) ? onRemoveRow : this.handleRemoveRow
      }, false));
    }

    return columns;

  };

  render() {

    var {
      store,
      bind,
      columnKey,
      style,
      disabled,
      visible,
      className,
      columnDefinitions,
      addRow,
      removeRow,
      onAddRow,
      onRemoveRow,
      newRowData,
      headerHeight,
      rowHeight,
      fixed,
      selectableRows,
      multiSelect,
      virtualScroll,
      showLoadingProgress,
      onSelectRow,
      sortingKey,
      spinnerMessage,
      ...props
    } = this.props;

    return <div className={utils.combineClassNames(["table-container", className])} style={style}>
      <div className="table">
        <Table ref="table"
          columnKey={columnKey}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          rows={store.get(bind)}
          selectableRows={selectableRows}
          multiSelect={multiSelect}
          onSelect={this.handleRowSelection}
          virtualScroll={virtualScroll}
          showSpinner={showLoadingProgress}
          fixed={fixed}
          spinnerMessage={spinnerMessage || "Loading...."}
          isImmutable={true}
          {...props}
        >
          {this.renderColumns(columnDefinitions, addRow, onAddRow, removeRow, onRemoveRow)}
        </Table>
      </div>
    </div>;

  }
});
