import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThumbnailImage from './thumbnailImage';

export default class PagesThumbnail extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    thumbnail: undefined,
    thumbnailState: {},
    thumbnailSize: undefined,
    definition: undefined
  };

  getPages = (thumbnail) => {
    var controller = this.context.controller,
      pages;
    if (thumbnail.type === 'form') {
      pages = thumbnail.pages || [];

      return pages.map(page => {
        const pageFromModel = controller.getByNwid(page.nwid);
        return pageFromModel?.pageContent ? pageFromModel : page;
      });

      //return thumbnail.pages;
    }
    return [thumbnail];
  };

  getPageCoords = (page, singlePageSize, matrix, indexInForm, pageOrietation, orietationDelta) => {
    var pageRow = (indexInForm / matrix.horizontalImageCount) | 0,
      pageCol = (indexInForm % matrix.horizontalImageCount);

    return [
      (pageCol * singlePageSize[0]),
      (pageRow * singlePageSize[1])
    ];
  };

  singlePage = (pageSize) => {
    return [pageSize[0] - 3, pageSize[1] - 3];
  };

  doublePage = (pageSize) => {
    return [pageSize[0] * 2 - 3, pageSize[1] - 3];
  };

  doubleVerticalPage = (pageSize) => {
    return [pageSize[0] - 3, pageSize[1] * 2 - 3];
  };

  getPageSize = (page, thumbnail, singlePageSize, matrix, pageOrietation, orietationDelta) => {
    const thumbnailType = thumbnail.type;
    const isPanorama = thumbnailType === 'form' ? page.pageContent.isPanoramaOnForm : page.pageContent.isPanorama;
    if (!isPanorama) {
      return this.singlePage(singlePageSize);
    }

    if (thumbnail.type === 'page') {
      return this.doublePage(singlePageSize);
    }
    if (matrix.horizontalImageCount * matrix.verticalImageCount === 1) {
      return this.singlePage(singlePageSize);
    }
    if (pageOrietation === 'vertical') {
      return this.doubleVerticalPage(singlePageSize);
    }
    if (pageOrietation === 'horizontal') {
      return this.doublePage(singlePageSize);
    }
    //if (page.pageContent.format === 'Tabloid') return this.doubleVerticalPage(singlePageSize);

    return this.doublePage(singlePageSize);
  };

  textRotate = (rotate) => {
    if (rotate === 0) {
      return '0deg';
    }
    if (rotate === 1) {
      return '90deg';
    }
    if (rotate === 2) {
      return '180deg';
    }
    if (rotate === 3) {
      return '270deg';
    }
  };

  renderPage = (
    page,
    thumbnail,
    thumbnailSize,
    matrix,
    indexInForm,
    pageOrietation,
    rotate,
    viewConfiguration,
    index,
  ) => {
    var controller = this.context.controller,
      singlePageSize = [
        thumbnailSize[0] / matrix.horizontalImageCount,
        thumbnailSize[1] / matrix.verticalImageCount
      ],
      orietationDelta = (singlePageSize[0] / 7) | 0,
      pageSize = this.getPageSize(page, thumbnail, singlePageSize, matrix, pageOrietation, orietationDelta),
      pageCoords = this.getPageCoords(page, singlePageSize, matrix, indexInForm, pageOrietation, orietationDelta),
      pageStatusDescription = controller.getPageStatusDescription(page),
      style = {
        position: 'absolute',
        left: pageCoords[0],
        top: pageCoords[1],
        width: pageSize[0],
        height: pageSize[1]
      },
      textRotate = ['rotate(', this.textRotate(rotate), ')'].join(''),
      textStyle = {
        transform: textRotate,
        WebkitTransform: textRotate,
        textDecoration: rotate > 0 & (page.numberInSection === 6 || page.numberInSection === 9) ? 'underline' : 'none'
      },
      name = thumbnail.type === 'page' ? page.name : viewConfiguration.numberMode === 'zone' ? page.numberInZone : viewConfiguration.numberMode === 'section' ? page.numberInSection : page.name;
    var is8UpOrMore = matrix.horizontalImageCount >= 4 && matrix.verticalImageCount >= 2;
    var trimmedName = is8UpOrMore && name.length > 3 ? '...' + name.substr(3) : name;
    var pageTextClass = is8UpOrMore ? ['thumbnail-page-text', 'small'].join(' ') : 'thumbnail-page-text';
    return <div title={name} className={['thumbnail-page', pageStatusDescription].join(' ')} style={style} key={`${page.id}-${index}`}>
      <span className={pageTextClass} style={textStyle} >
        {trimmedName}
      </span>
    </div>;
  };

  renderPages = (pages, thumbnail, thumbnailSize, viewConfiguration) => {
    var that = this,
      controller = this.context.controller,
      matrix = controller.getMatrix(thumbnail),
      formIndexes = controller.getFormIndexes(thumbnail),
      orietations = controller.getOrietations(thumbnail),
      rotations = controller.getRotations(thumbnail);
    return pages.filter(page => !!page).map(page => {
      var nwid = thumbnail.type === 'page' ? thumbnail.nwid : (page.pageContent?.nwid || page.nwid),
        indexesInForm = formIndexes[nwid] || [],
        pageOrietation = orietations[nwid],
        rotate = rotations[nwid];
      return indexesInForm.map((pageIndexInForm, index) => {
        return that.renderPage(page, thumbnail, thumbnailSize, matrix, pageIndexInForm, pageOrietation[index], rotate[index], viewConfiguration, index);
      });
    });
  };

  renderThumbnailContent = (pages, thumbnail, thumbnailState, thumbnailSize, viewConfiguration) => {
    var controller = this.context.controller;
    var selectedSeparationIndex = this.props.selectedSeparationIndex;
    var thumbnailContentNode = (
      (thumbnail.separations.length === 0) || (
        (selectedSeparationIndex === null || selectedSeparationIndex === undefined) &&
        (thumbnail.separations.length > selectedSeparationIndex)
      )
    ) ?
      thumbnail :
      thumbnail.separations[selectedSeparationIndex];
    var thumbnailContent = controller.getThumbnailContent(thumbnailContentNode);
    var version = thumbnailContent.versionNwid;
    var contentVersionMajorIndex = thumbnailContent.contentVersionMajorIndex;
    //var thumbnailImage = controller.getThumbnailUrl(thumbnailContent);

    if (controller.showThumbnailImage(thumbnailContent) && !thumbnailState.showOnlyPagesInfo) {
      return <ThumbnailImage version={version}
        contentVersionMajorIndex={contentVersionMajorIndex}
        thumbnail={thumbnailContentNode}
        selectedSeparationIndex={selectedSeparationIndex}
      />;
    }

    return this.renderPages(pages, thumbnail, thumbnailSize, viewConfiguration);
  };

  render() {
    var { thumbnail, thumbnailState,  definition, viewConfiguration } = this.props,
      controller = this.context.controller,
      thumbnailSingleSize = [definition.singleWidth, definition.singleHeight],
      thumbnailSize = controller.getThumbnailElementSize(thumbnail, thumbnailSingleSize),
      pages = this.getPages(thumbnail),
      style = { width: thumbnailSize[0], height: thumbnailSize[1] };

    return <div className="thumbnail" style={style}>
      {this.renderThumbnailContent(pages, thumbnail, thumbnailState, thumbnailSize, viewConfiguration)}
    </div>;
  }
}

PagesThumbnail.propTypes = {
  thumbnail: PropTypes.object,
  thumbnailState: PropTypes.object,
  thumbnailSize: PropTypes.array,
  definition: PropTypes.object,
  viewConfiguration: PropTypes.object,
  selectedSeparationIndex: PropTypes.number,
};