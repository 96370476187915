import PropTypes from 'prop-types';
define(['react'], function (React) {
  'use strict';

  const { Component } = React;

  return class MultiSelectDropdownOptionItem extends Component {

    static propTypes = {

      text: PropTypes.string,

      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),

      index: PropTypes.number,

      selected: PropTypes.bool,

      onClick: PropTypes.func

    };

    static defaultProps = {
      onClick: function () { }
    };

    constructor(props) {
      super(props);
    }

    handleClick = (event) => {
      var { value, index, onClick, selected } = this.props;
      onClick(value, index, event);
    }

    handleCheckboxChanged = (event) => {
      //This should be an empty function for React not to give a warning. The click handle is bounded to the li item, so
      //there is no need for another handling inside the li element.
    };

    render() {
      var { text, selected } = this.props;
      var className = 'dropdown-list-item';

      return <li className={className} onClick={this.handleClick} title={text}>
        <input type="checkbox" checked={selected} onChange={this.handleCheckboxChanged} />
        {text}
      </li>;
    }

  }

});