import React, { Component } from 'react';
import { cells } from 'widgets/ReactDataGrid/';
import { COMPARE_TYPE } from 'core/comparators';
import { applyColumnPreferences, FILTER } from 'widgets/ReactDataGrid/utils';
import { toLocaleShortDateTime } from 'core/services/localization';
import { translate } from 'core/services/localization';
import { STATUS_OPTIONS, EMPTY_VALUE_ICON } from './constants';
import iconService from 'core/services/iconService';
import { SvgIcon } from 'components/common/buttons';
import { getUIColorRGB } from 'core/services/colorService';
import { createObjectComparator } from 'core/comparators';
import { ICON_SPRITE } from 'components/common/buttons/SvgIcon';
const { Text } = cells;


class ContentNameCell extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.columnData !== this.props.columnData);//&& nextProps.type !== this.props.type && nextProps.color !== this.props.color);
  }

  render() {
    const { columnData, rowContent } = this.props;
    const { color, type, contentType } = rowContent;

    var url = iconService.getTemplateIcon(type, 'tiny');

    const getIcon = () => {
      const style = {
        backgroundColor: (type === 'page/separation' || type === 'form/separation' || type === 'plate') ?
          getUIColorRGB(color) : null
      };
      const fragmentStyle = {
        color: (type === 'fragment/separation') ?
          getUIColorRGB(color) : '#666',
        marginRight: 5,
        marginLeft: -3
      };

      if ((type === 'fragment' || type === 'fragment/content') && contentType === 'AD') {
        return <SvgIcon name='ad_20' sprite={ICON_SPRITE.TEMPLATE} style={fragmentStyle} />;
      } else if ((type === 'fragment' || type === 'fragment/content') && contentType === 'EDITORIAL') {
        return <SvgIcon name='editorial_20' sprite={ICON_SPRITE.TEMPLATE} style={fragmentStyle} />;
      } else if ((type === 'fragment/separation' || type === 'fragment/content/separation') && contentType === 'AD') {
        return <SvgIcon name='ad_separation_20' sprite={ICON_SPRITE.TEMPLATE} style={fragmentStyle} />;
      } else if ((type === 'fragment/separation' || type === 'fragment/content/separation') && contentType === 'EDITORIAL') {
        return <SvgIcon name='editorial_separation_20' sprite={ICON_SPRITE.TEMPLATE} style={fragmentStyle} />;
      } else {
        return <img src={url} style={style} />;
      }
    };

    return <div className='cell'>
      <div className='cell-content'>
        <div className='cell-content-padding'>
          <div className='history-view-icon-with-text' >
            {getIcon()}
            {columnData}
          </div>
        </div>
      </div>
    </div >;
  }
}

class FlowStepNameCell extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.columnData !== this.props.columnData && nextProps.flowStepTemplate !== this.props.flowStepTemplate && nextProps.flowStepIcon !== this.props.flowStepIcon);
  }

  render() {

    var { columnData, flowStepTemplate, flowStepIcon } = this.props;
    let url = iconService.getTemplateIcon(flowStepTemplate, 'tiny', flowStepIcon);

    return <div className='cell'>
      <div className='cell-content'>
        <div className='cell-content-padding'>
          <div className='history-view-icon-with-text'  >
            {columnData && <React.Fragment><img src={url} />
              {columnData}
            </React.Fragment>
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

export const makeHistoryTableColumns = (viewModel, module) => {
  const columnPrefs = module.preferences.table?.historyColumns || [];

  const isRejected = (status, flowStepTemplate) => {
    return status === 'Error' && flowStepTemplate.toLowerCase() === 'workflow/step/flow/approval';
  };

  const getNameDistinctiveValue = row => {
    return row.contentName || '';
  };

  const getStepDistinctiveValue = row => {
    return row.flowStepName || '';
  };

  const getStatusDistinctiveValue = row => {
    const { status, flowStepTemplate } = row;
    let value = '';

    switch (status) {
      case 'Success':
        value = 'finished';
        break;
      case 'Waiting':
        value = 'wait';
        break;
      case 'Processing':
        value = 'in_progress';
        break;
      case 'Reject':
        value = 'reject';
        break;
      case 'Hold':
        value = 'hold';
        break;
      case 'Error':
        if (isRejected(status, flowStepTemplate)) {
          value = 'reject';
          break;
        } else {
          value = 'error';
          break;
        }
    }
    return value;
  };

  const getStatusIcon = (status, flowStepTemplate) => {
    let icon = {};

    if (status === 'Success') {
      icon = {
        url: iconService.getGeneralIcon('status', 'finished'),
        title: translate('Success'),
      };
    } else if (status === 'Error') {
      if (isRejected(status, flowStepTemplate)) {
        icon = {
          url: iconService.getGeneralIcon('status', 'reject'),
          title: translate('Reject'),
        };
      } else {
        icon = {
          url: iconService.getGeneralIcon('status', 'error'),
          title: translate('Error'),
        };
      }

    } else if (status === 'Waiting') {
      icon = {
        url: iconService.getGeneralIcon('status', 'wait'),
        title: translate('Waiting'),
      };
    } else if (status === 'Processing') {
      icon = {
        url: iconService.getGeneralIcon('status', 'in_progress'),
        title: translate('Processing'),
      };
    } else if (status === 'Hold') {
      icon = {
        url: iconService.getGeneralIcon('status', 'hold'),
        title: translate('Hold'),
      };
    } else if (status === 'Reject') {
      icon = {
        url: iconService.getGeneralIcon('status', 'reject'),
        title: translate('Reject'),
      };
    } else if (status === '') {
      icon = EMPTY_VALUE_ICON;
    }

    return icon;
  };

  const createSiteNameOptions = (historyData) => {
    const mergedSiteNames = historyData.reduce((acc, history) => {

      if (history.site && !acc[history.site]) {
        acc[history.site] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedSiteNames).map(name => ({
      value: name,
      data: { columnData: name || NO_VALUE }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };

  const createFlowStepNameOptions = (historyData) => {
    const mergedFlowStepNames = historyData.reduce((acc, history) => {
      if (!history.flowStepName) {
        acc[''] = history;
      } else if (!acc[history.flowStepName]) {
        acc[history.flowStepName] = history;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedFlowStepNames).map(name => ({
      value: name,
      data: mergedFlowStepNames[name].flowStepName ?
        { icon: iconService.getTemplateIcon(mergedFlowStepNames[name].flowStepTemplate, 'tiny', mergedFlowStepNames[name].flowStepIcon), title: name } :
        { icon: EMPTY_VALUE_ICON.icon, title: EMPTY_VALUE_ICON.title, iconSprite: EMPTY_VALUE_ICON.sprite, iconStyle: EMPTY_VALUE_ICON.iconStyle }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };


  const createNamesOptions = (historyData) => {
    const mergedNames = historyData.reduce((acc, history) => {

      if (history.contentName && !acc[history.contentName]) {
        acc[history.contentName] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedNames).map(name => ({
      value: name,
      data: { columnData: name || NO_VALUE }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };

  const createResourcesOptions = (historyData) => {
    const mergedResources = historyData.reduce((acc, history) => {

      if (history.resource && !acc[history.resource]) {
        acc[history.resource] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedResources).map(name => ({
      value: name,
      data: { columnData: name || NO_VALUE }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };

  const createUsersOptions = (historyData) => {
    const mergedUsers = historyData.reduce((acc, history) => {

      if (history.user && !acc[history.user]) {
        acc[history.user] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedUsers).map(name => ({
      value: name,
      data: { columnData: name || NO_VALUE }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };

  const createIpsOptions = (historyData) => {
    const mergedIps = historyData.reduce((acc, history) => {

      if (history.ip && !acc[history.ip]) {
        acc[history.ip] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedIps).map(name => ({
      value: name,
      data: { columnData: name || NO_VALUE }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };

  const createLaneOptions = (historyData) => {
    const mergedLanes = historyData.reduce((acc, history) => {

      if (history.lane && !acc[history.lane]) {
        acc[history.lane] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedLanes).map(name => ({
      value: name,
      data: { columnData: name || NO_VALUE }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };

  const createExternalVersionsOptions = (historyData) => {
    const mergedExternalVersions = historyData.reduce((acc, history) => {

      if (history.externalVersions && !acc[history.externalVersions]) {
        acc[history.externalVersions] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedExternalVersions).map(name => ({
      value: name,
      data: { columnData: name || NO_VALUE }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };



  let columns = [
    {
      key: 'time',
      width: 140,
      resizable: true,
      sortType: COMPARE_TYPE.DATES,
      filter: FILTER.DATE,
      caption: translate('Time'),
      cell: Text,
      cellDataGetter: (rowIndex, columnKey) => {
        const date = viewModel.filteredHistoryRows[rowIndex][columnKey] || '';
        return {
          columnData: toLocaleShortDateTime(date),
        };
      },
    },
    {
      key: 'contentName',
      width: 120,
      caption: translate('Name'),
      cell: <ContentNameCell />,
      filter: {
        options: createNamesOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_TEXT,
      },
      filterValueGetter: getNameDistinctiveValue,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
    {
      key: 'site',
      width: 100,
      align: 'center',
      caption: translate('Site'),
      cell: Text,
      filter: {
        options: createSiteNameOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_TEXT,
      },
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
    {
      key: 'flowStepName',
      width: 130,
      caption: translate('Step'),
      cell: props => (<FlowStepNameCell {...props}
        flowStepTemplate={viewModel.filteredHistoryRows[props.rowIndex].flowStepTemplate}
        flowStepIcon={viewModel.filteredHistoryRows[props.rowIndex].flowStepIcon} />),
      filter: {
        options: createFlowStepNameOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_ICON,
      },
      filterValueGetter: getStepDistinctiveValue,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData && nextProps.flowStepTemplate !== props.flowStepTemplate && nextProps.flowStepIcon !== props.flowStepIcon
    },

    {
      key: 'status',
      width: 105,
      align: 'center',
      caption: translate('Status'),
      cell: Text,
      filter: {
        options: STATUS_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      filterValueGetter: getStatusDistinctiveValue,
      cellDataGetter: (rowIndex, columnKey) => {
        let row = viewModel.filteredHistoryRows[rowIndex];
        const statusIcon = getStatusIcon(row[columnKey], row.flowStepTemplate);
        const title = isRejected(row[columnKey], row.flowStepTemplate) ?
          translate('Reject') : row[columnKey] || '';
        return {
          columnData: (<div className='history-view-icon-with-text' >
            <img src={statusIcon.url} title={title} />
            {title}
          </div >),
          title: title
        };
      },

    },
    {
      key: 'lane',
      width: 120,
      cell: Text,
      caption: translate('Lane'),
      filter: {
        options: createLaneOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_TEXT,
      },
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },

    {
      key: 'resource',
      width: 150,
      caption: translate('Resource'),
      cell: Text,
      filter: {
        options: createResourcesOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_TEXT,
      },
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
    {
      key: 'user',
      width: 100,
      align: 'center',
      caption: translate('User'),
      cell: Text,
      filter: {
        options: createUsersOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_TEXT,
      },
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex].isRemote ? `${translate('Remote')}: ${viewModel.filteredHistoryRows[rowIndex][columnKey]}` : viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
    {
      key: 'ip',
      width: 80,
      caption: translate('IP'),
      cell: Text,
      filter: {
        options: createIpsOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_TEXT,
      },
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
    {
      key: 'inputFileName',
      width: 200,
      caption: translate('Input Files'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
    {
      key: 'externalVersions',
      width: 80,
      align: 'center',
      caption: translate('External Version'),
      cell: Text,
      filter: {
        options: createExternalVersionsOptions(viewModel.filteredHistoryRows),
        ...FILTER.MULTISELECT_TEXT,
      },
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
    {
      key: 'comment',
      width: 350,
      caption: translate('Message'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
        };
      },
    },
  ];

  if (module.showBinAndSorterColumns) {
    columns = columns.concat(
      [
        {
          key: 'bin',
          width: 80,
          caption: translate('Bin'),
          cell: Text,
          filter: FILTER.TEXT_TEXT,
          cellDataGetter: (rowIndex, columnKey) => {
            return {
              columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
            };
          },
        },
        {
          key: 'sorter',
          width: 80,
          caption: translate('Sorter'),
          cell: Text,
          filter: FILTER.TEXT_TEXT,
          cellDataGetter: (rowIndex, columnKey) => {
            return {
              columnData: viewModel.filteredHistoryRows[rowIndex][columnKey]
            };
          },
        }
      ]);
  }


  columns = applyColumnPreferences(columns, columnPrefs);
  return columns;
};