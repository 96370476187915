/**
 * Created with JetBrains WebStorm.
 * User: tzvikas
 * Date: 4/12/15
 * Time: 9:43 AM
 * To change this template use File | Settings | File Templates.
 */

const { is } = require("date-fns/locale");

/**
 * Created by moshemal on 9/4/14.
 */

define(['sandbox', 'sandbox/planSetup', './../../../utils/wizardUtils'],function(sandbox, PlanSetup, wizardUtils){
  'use strict';

  function getCommonId(hierarchy){
    return (
      hierarchy.publication.displayName + "-" +
      hierarchy.edition.displayName + "-" +
      hierarchy.zone.displayName + "-" +
      hierarchy.section.displayName + "-" +
      hierarchy.page.displayName
    );
  }

  function getHierarchy(mappedModel, page){
    var ret = {};
    ret['page'] = page;
    ret['section'] = mappedModel[page.parent];
    ret['zone'] = mappedModel[ret['section'].parent];
    ret['edition'] = mappedModel[ret['zone'].parent];
    ret['publication'] = mappedModel[ret['edition'].parent];
    return ret;
  }

  function validModel(mappedModel, sections){    
    for (var i=0;i<sections.length;i++){
      var valid = true;
      var validObjectsCheck = {};
      var multiplePages = sections[i].zonedPages;
      for (var j=0;j<multiplePages.length;j++){
        var zoneMap = multiplePages[j].zoneMap;
        for (var zoneName in zoneMap){
          var pageDescriptor = zoneMap[zoneName];
          if (pageDescriptor.masterPage){            
            var key = pageDescriptor.myZone + "/" + pageDescriptor.mySection + ":" + pageDescriptor.masterPage; 
            //pageDescriptor.myZone + ":" + pageDescriptor.masterPageZone + "/" + pageDescriptor.masterSection + "/" + pageDescriptor.masterPageName;
            
            var links = validObjectsCheck[key] || [];
            if (links.length > 0){
              valid = false;
            } else {
              validObjectsCheck[key] = links;
            }
            links.push(pageDescriptor.myZone + "/" + pageDescriptor.mySection + "/" + pageDescriptor.title);
            
            //validObjectsCheck[zoneName + ":" + pageDescriptor.masterPage] = 1;
          }          
        }

      }
      if (!valid){
        var str = "";
        var masters = {};
        for (var key in validObjectsCheck){
          var items = validObjectsCheck[key];
          
          if (items.length > 1){
            var master = key.split(":")[1];
            if (masters[master]){
              continue;
            }
            masters[master] = 1;
            var mp = this.mappedModel[master];
            if (mp != null){
              master = mp.myZone + "/" + mp.mySection + "/" + mp.title;
            }
            str += master + " has multiple links: ";
            for (var i=0;i<items.length;i++){
              str += items[i] + " ";
            }
            str += "<br>"
          }
          
          
        }
        
        sandbox.dialog.alert(str);
        return false;
      }
    }

    return true;
  }
  function updatePageCommonId(mappedModel, page){
    var isLocal = false;
    var myHierarchy = null, masterHierarchy = null;
    var commonId = null, masterCommonId = null;
    page.separations.forEach(function(separation){
      var masterSeparation = separation.masterSeparation;
      if (masterSeparation === null || typeof masterSeparation === 'undefind'){
        isLocal = true;
        if (myHierarchy === null){
          myHierarchy = getHierarchy(mappedModel, page);
          commonId = getCommonId(myHierarchy);
        }
        separation.commonId = commonId + "-" + masterSeparation.displayName;
      } else {
        if (masterHierarchy === null){
          masterHierarchy = getHierarchy(mappedModel, page.masterPage);
          masterCommonId = getCommonId(masterHierarchy);
        }
        separation.commonId = masterCommonId + "-" + masterSeparation.displayName;
      }
    });
    page.commonId = isLocal ? commonId : masterCommonId;
  }

  function getKillInfo(section){
    var ret = {};
    ret.exceptionNumber = section.exceptionNumber ? section.exceptionNumber : 0;
    ret.exceptionType = section.exceptionType ? section.exceptionType : "";
    ret.process5 = section.process5 ? section.process5 : false;

    return ret;
  }
  

  function getTitle (page, continueNumbers, single){
    //continueNumbers = false;
    if (this.namingPattern.length > 0){
      return wizardUtils.resolveNaming(page, this.namingPattern, this.fullMappedModel);
    }
    var title = continueNumbers ? page.number : page.sectionCode + page.number;
    title = continueNumbers ? page.pagination : page.sectionCode + page.pagination;
    var insertChar = page.insertChar;
    if (insertChar !== null && typeof insertChar !== "undefined"){
      title = continueNumbers ? page.pagination : page.sectionCode + page.pagination;
      title += insertChar;
    }
    var splitChar = page.split;
    if (splitChar !== null && typeof splitChar !== "undefined"){
      title += splitChar;
    }
    var virtualTitle = page.virtualTitle;
    if (!single && virtualTitle !== null && typeof virtualTitle !== "undefined"){
      title = title + "-" + virtualTitle
    }
    return title;
  }

  function getCustomProperties(page){
    if (page.customProperties === null && typeof page.customProperties === "undefined"){
      return {};
    }
    var ret = {};
    for (var property in page.customProperties){
      ret[property] = page.customProperties[property];
    }
    return ret;
  }


  if (!Array.prototype.find) {
    Array.prototype.find = function (predicate) {
      if (this === null) {
        throw new TypeError('Array.prototype.find called on null or undefined');
      }
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }
      var list = Object(this);
      var length = list.length >>> 0;
      var thisArg = arguments[1];
      var value;

      for (var i = 0; i < length; i++) {
        value = list[i];
        if (predicate.call(thisArg, value, i, list)) {
          return value;
        }
      }
      return undefined;
    };
  }

  function zones (){
    var ret = [];
    var publication = this.model.publication;
    var editions = publication.editions;
    for (var i=0;i<editions.length;i++){
      var zones = editions[i].zones;
      ret.pushObjects(zones);
    }
    return ret;
  }

  /*

  convert wizard model to step model

   */

  function findBySourceId (sourceId){
    // console.log(this.mappedModel[sourceId]);
  }

  function convertToStepModel  (model, stepProperties) {
    var zoneArray = this.zones();
    this.fullMappedModel = wizardUtils.mapSourceIdToObject(model);
    var originalStructure = [];
    var genSections = createSectionModel.call(this, zoneArray, stepProperties, originalStructure);
    this.stepModel = {sections:convertZonedPagesToArray.call(this, genSections), zones:mapZoneNames(zoneArray), mapPages:this.mapPages};
    this.mappedModel = wizardUtils.mapSourceIdToObject(this.stepModel.sections);
    this.originalStructure = originalStructure;
    return this.stepModel;
  }

  function mapZoneNames(zones){
    var ret = [];
    zones.forEach(function (zone) {
      ret.push(zone.code);
    });
    return ret;
  }

  function createSectionModel (zoneArray, stepProperties, originalStructure) {
    var genSections = [];
    var that = this;
    var showDinky = stepProperties.showDinky;
    that.mappedTitles = {};
    that.zonesBaseZonesData = {};
    that.pagesPerRow = {};
    that.pagesPerSection = {};
    
    that.mapPages = {};
    var allPagesMap = that.mapPages;

    zoneArray.forEach(function (zone) {
        var oZone = {title:zone.code, code:zone.code, displayName:zone.code, sourceId:zone.sourceId, sections:[], order:zone.order};
        originalStructure.push(oZone);
        var mappedModel = wizardUtils.mapSourceIdToObject(zone);
        zone.sections.forEach(function (section) {
          allPagesMap[zone.code + ":" + section.displayName] = [];
          var genSection = genSections.find(function (genSection) {   // find the generated section object
            return section.displayName === genSection.displayName;
          });
          if (genSection === undefined) {                             // generate a new section if needed
            genSection = {
              displayName: section.displayName,              
              zones: [],
              zonedPagesMap: {},
              code: section.code === null ? "" : section.code,
              killInfo: getKillInfo (section),
              dataSection: section.dataSection
            };
            genSections.push(genSection);  // we assume the order of section will be correct here ? maybe it's better to build the section as map, and sort later ?
          }

          var oSection = {title:section.displayName, code:section.code, displayName:section.displayName, sourceId:section.sourceId, pages:[]};
          oZone.sections.push(oSection);
          var masterZone = null, baseZoneName = null;
          if (typeof zone.masterZone !== undefined && zone.masterZone !== null){
            masterZone = zone.masterZone;
            var zones = that.zones();
            for (var i=0;i<zones.length;i++){
              if (zones[i].sourceId === masterZone){
                baseZoneName = zones[i].code;
              }
            }
          }
          that.zonesBaseZonesData[zone.code] = baseZoneName;
          genSection.zones.push({title:zone.displayName, code:zone.code,displayName:zone.code, color:zone.color, order:zone.order, masterZone:masterZone, sourceId:zone.sourceId, sectionSourceId:section.sourceId, hidden: zone.hidden,});

          
          var exceptionPages = 0;
          section.pages.forEach(function (page) {                     // create a map of zoned pages based on the display name (should be changed...)            

            page.sectionCode = section.code;
            if (page.panorama && typeof page.virtualTitle === 'undefined'){
              var virtualPage = mappedModel[page.virtualPage];
              if (typeof virtualPage !== 'undefined'){
                virtualPage.sectionCode = section.code;
                page.virtualTitle = getTitle.call(that, virtualPage, that.continueNumbers);
              }
            }
            var title = getTitle.call(that, page, that.continueNumbers); //getPageTitle(page);
            page.displayName = title;
            var customProperties = getCustomProperties (page);

            var genPage = genSection.zonedPagesMap[title];
            if (genPage === undefined) {
              genSection.zonedPagesMap[title] = genPage = {};
            }
            allPagesMap[zone.code + ":" + section.displayName].push({title:title, sourceId:page.sourceId});

            if (showDinky){
              page.dinky = page.isDinky;
            }

            var oPage = {title:title, sourceId:page.sourceId, adsKey: page.adsKey, numColors:page.separations.length, virtual:page.virtual};
            oSection.pages.push(oPage);

            var pageMasterPageName = page.masterPageName;
            var pageMasterPage = that.fullMappedModel[page.masterPage];
            if (pageMasterPage){
              pageMasterPageName = getTitle.call(that, pageMasterPage, that.continueNumbers);
            }
            genPage[zone.code] = {
              sourceId: page.sourceId,
              masterPage: page.masterPage,
              masterZone: page.masterZone,
              masterSection: page.masterSection,
              masterPageZone: page.masterPageZone,
              masterPageName: pageMasterPageName,
              jumpShare: page.jumpShare,
              pagination: page.pagination,
              folioNumber: page.folioNumber,
			        number: page.number,
              dinky: page.dinky,
              paginationInZone: page.paginationInZone,
              numInserts: page.numInserts,
              title: title,
              myZone: zone.code,
              mySection: section.displayName,
              split: page.split,              
              virtualPage: page.virtualPage,
              panorama: page.panorama,
              virtual: page.virtual,
              virtualTitle: page.virtualTitle,
              panoramaPage: page.panoramaPage,
              customProperties: customProperties,
              sectionCode: page.sectionCode,
              //isMasterOptional: (masterZone !== null && typeof masterZone !== 'undefined') || page.basePage || page.prevBasePage,
              isMasterOptional: true, 
              basePage: page.basePage,
              prevBasePage: page.prevBasePage,
              isChase: page.isChase,
              links: page.links,
              directMaster: page.directMaster,
              followers: page.followers,
              separations: {},
              slip: page.slip,
              slipText: page.slipText,
              kill: page.kill,
              killText: page.killText,
              parent: page.parent,
              hidden: section.hidden,
              adsKey: page.adsKey,
              jumpShareDirectMaster: page.jumpShareDirectMaster,
              nwxLocal: page.nwxLocal,
              removedColors: getRemovedColors(page.removedColors || [])
            };

            if (page.insertChar && page.insertChar.length > 0){
              genPage[zone.code].insertChar = page.insertChar;
            }

            if (page.slip || page.slipText || page.kill || page.killText){
              exceptionPages++;
            }
            
            page.separations.forEach(function (separation) {
              genPage[zone.code].separations[separation.displayName] = {
                sourceId: separation.sourceId,
                masterSeparation: separation.masterSeparation,
                masterZone: separation.masterZone,
                masterPageZone: separation.masterPageZone,
                prevBaseSeparation: separation.prevBaseSeparation,
                baseSeparation: separation.baseSeparation,
                isChase: separation.isChase
              };
            });
            
            that.pagesPerRow[page.sourceId] = genSection.zonedPagesMap[title];
            that.pagesPerSection[page.sourceId] = genSection;
            // if (!page.hidden){
            //   genSection
            // }


          });

          genSection.killInfo.exceptionPages = exceptionPages;
        });
      }
    )
    ;

    return genSections;
  }

  function convertZonedPagesToArray (sectionArray) {
    sectionArray.forEach(function (section) {
      section.zonedPages = [];
      var zonedPagesKeys = Object.keys(section.zonedPagesMap).sort(function (page1, page2){
        var s1 = page1.indexOf("-") > -1 ? page1.split("-")[0] : page1;
        var s2 = page2.indexOf("-") > -1 ? page2.split("-")[0] : page2;
        var aN = parseInt(s1.replace(/[^0-9]/g, ""), 10);
        var bN = parseInt(s2.replace(/[^0-9]/g, ""), 10);

        var last1 = s1.slice(-1);
        var last2 = s2.slice(-1);
        if (aN == bN){
          var n1 = parseInt(last1, 10);
          var n2 = parseInt(last2, 10);
          if (!isNaN(n1)){
            return -1;
          } else if (!isNaN(n2)){
            return 1;
          } else {
            return last1.charCodeAt(0) - last2.charCodeAt(0);
          }
        }
        // if (aN == bN && last1 === "A" && last2 === "B"){
        //   return -1; 
        // } else if (aN == bN && last2 === "A" && last1 === "B"){
        //   return 1;
        // } else if (aN == bN && (last1 === "A" || last1 === "B")){
        //   return 1;
        // } else if (aN == bN && (last2 === "A" || last2 === "B")){
        //   return -1;
        // }
        return aN === bN ? 0 : aN > bN ? 1 : -1;
        //var intVal = parseInt(page1, 10) - parseInt(page2, 10);
        //return intVal === 0 ? ((page1 < page2) ? -1 : 1) : intVal;
      });

      section.zonedPages = zonedPagesKeys.map(function (zonedPageKey) {
        var hidden = true;
        Object.keys(section.zonedPagesMap[zonedPageKey]).forEach(function(key,index) {
          // key: the name of the object key
          // index: the ordinal position of the key within the object 
          var page = section.zonedPagesMap[zonedPageKey][key];
          if (!page.hidden && !page.virtual){
            hidden = false;
          }
          
        });
        
        return {
          displayName: zonedPageKey,
          zoneMap: section.zonedPagesMap[zonedPageKey],
          hidden: hidden
        };
      });

      delete section.zonedPagesMap;
    });

    return sectionArray;
  }

  /*

    convertFromStepModel
   */

  function updatePanoramaPage(panorama, virtual){
    panorama.virtualPage = virtual.sourceId;
    panorama.panoramaChanged = !panorama.panorama;
    panorama.panorama = true;
    panorama.virtualTitle = getTitle.call(this, virtual, this.continueNumbers);
    panorama.single = getTitle.call(this, panorama, this.continueNumbers, true);
    virtual.virtual = true;
    virtual.panoramaPage = panorama.sourceId;
  }

  function updateBase(mappedModel, pageDescriptor, page ,masterPage){
    page.basePage = pageDescriptor.basePage;
    page.prevBasePage = pageDescriptor.prevBasePage;
    for (var i=0;i<page.separations.length;i++){
      var sepName = page.separations[i].displayName;
      var sepDescriptor = pageDescriptor.separations[sepName];
      //if (sepDescriptor.masterSeparation !== null && typeof sepDescriptor.masterSeparation !== "undefined"){
        page.separations[i].baseSeparation = sepDescriptor.baseSeparation;
        page.separations[i].prevBaseSeparation = sepDescriptor.prevBaseSeparation;
        page.separations[i].isChase = sepDescriptor.isChase;
      //}
    }
  }
  
  function getRemovedColors(removedColors){
    var ret = {};
    for (var i=0;i<removedColors.length;i++){
      ret[removedColors[i].name] = removedColors[i];
    }
    return ret;
  }

  function addRemovedColors(removedColors){
    var ret = [];
    for (let key in removedColors){
      let removedColor = removedColors[key];
      ret.push({sourceId:removedColor.sourceId, name:key, prevBaseSeparation:removedColor.prevBaseSeparation, basePageSourceId:removedColor.basePageSourceId});
    }
    return ret;
  }


  function updateSharing(mappedModel, pageDescriptor, page ,masterPage){
    page.masterZone = pageDescriptor.masterZone;
    page.masterPage = masterPage;
    page.masterPageZone = pageDescriptor.masterPageZone;
    page.basePage = pageDescriptor.basePage;
    page.prevBasePage = pageDescriptor.prevBasePage;
    page.masterSection = pageDescriptor.masterSection;
    page.masterPageName = pageDescriptor.masterPageName;
    page.jumpShare = pageDescriptor.jumpShare;
    for (var i=0;i<page.separations.length;i++){
      var sepName = page.separations[i].displayName;
      var sepDescriptor = pageDescriptor.separations[sepName];
      if (sepDescriptor.masterSeparation !== null && typeof sepDescriptor.masterSeparation !== "undefined"){
        page.separations[i].masterZone = sepDescriptor.masterZone;
        //page.separations[i].masterSeparation = mappedModel[sepDescriptor.masterSeparation].sourceId;
        page.separations[i].masterSeparation = sepDescriptor.masterSeparation;
        page.separations[i].masterPageZone = sepDescriptor.masterPageZone;

        page.separations[i].baseSeparation = sepDescriptor.baseSeparation;
        page.separations[i].prevBaseSeparation = sepDescriptor.prevBaseSeparation;
      } else {
        delete page.separations[i].masterZone;
        delete page.separations[i].masterSeparation;
        delete page.separations[i].masterPageZone;
      }
    }

    var masterPageObj = mappedModel[masterPage];
    if (masterPageObj.links === null || typeof masterPageObj.links === 'undefined'){
      masterPageObj.links = [];
    }
    masterPageObj.links.push(page.sourceId);
  }

  function removeBase(pageDescriptor, page){
    delete page.basePage;

    for (var i=0;i<page.separations.length;i++){

      var pageDescriptorSeparation =  pageDescriptor.separations[page.separations[i].displayName];
      if (pageDescriptorSeparation.baseSeparation === null || typeof pageDescriptorSeparation.baseSeparation === 'undefined'){
        delete page.separations[i].baseSeparation;
      } else {
        page.separations[i].baseSeparation = pageDescriptorSeparation.baseSeparation;
      }
      page.separations[i].isChase = pageDescriptor.isChase;
    }
  }

  function removeSharing(mappedModel, pageDescriptor, page){

    var masterPageObj = mappedModel[page.masterPage];
    if (masterPageObj!== null && typeof masterPageObj !== 'undefined'){
      if (masterPageObj.links !== null && typeof masterPageObj.links !== 'undefined'){
        var index = masterPageObj.links.indexOf(page.sourceId);
        if (index > -1) {
          masterPageObj.links.splice(index, 1);
        }
      }

    }


    delete page.masterZone;
    delete page.masterPage;
    delete page.masterPageZone;
    delete page.jumpSharing;
    delete page.masterPageName;
    for (var i=0;i<page.separations.length;i++){

      var pageDescriptorSeparation =  pageDescriptor.separations[page.separations[i].displayName];
      if (pageDescriptorSeparation.masterSeparation === null || typeof pageDescriptorSeparation.masterSeparation === 'undefined'){
        delete page.separations[i].masterZone;
        delete page.separations[i].masterSeparation;
        delete page.separations[i].masterPageZone;
      } else {
        page.separations[i].masterZone = pageDescriptorSeparation.masterZone;
        //page.separations[i].masterSeparation = mappedModel[sepDescriptor.masterSeparation].sourceId;
        page.separations[i].masterSeparation = pageDescriptorSeparation.masterSeparation;
        page.separations[i].masterPageZone = pageDescriptorSeparation.masterPageZone;
      }


    }
  }

  function updateCustomProperties(pageDescriptor, page){
    page.numInserts = pageDescriptor.numInserts;
    if (page.customProperties === null || typeof page.customProperties === 'undefined'){
      page.customProperties = {};
    }
    for (var property in pageDescriptor.customProperties){
      page.customProperties[property] = pageDescriptor.customProperties[property];
    }
  }

  function updateColors(pageDescriptor, page){
    var matched = {};
    var isChase = false;
    for (var i=page.separations.length-1;i>=0;i--){
      var sepName = page.separations[i].displayName;
      if (!pageDescriptor.separations.hasOwnProperty(sepName)){
        // separation removed
        page.separations.splice(i, 1);
      } else {
        matched[sepName] = 1;
      }
    }
    for (var sepName in pageDescriptor.separations){
      if (!matched.hasOwnProperty(sepName)){
        var sep = {sourceId:pageDescriptor.separations[sepName].sourceId, displayName:sepName, parent:pageDescriptor.sourceId};
        page.separations.push(sep);
      }
    }
  }

  function createPageFromDescriptor(mappedModel, pageDescriptor){
    var originators = pageDescriptor.originators;
    if (originators === null || typeof originators === 'undefined' ){
      return null;
    }
    var originatorPage = mappedModel[originators[0]];
    var section = mappedModel[originatorPage.parent];
    var page = {parent:section.sourceId, sectionName:section.code};
    for (var propName in pageDescriptor){
      if (propName === "separations"){
        page.separations = [];
        for (var sepName in pageDescriptor.separations){
          var sep = {sourceId:pageDescriptor.separations[sepName].sourceId, displayName:sepName, parent:pageDescriptor.sourceId};
          page.separations.push(sep);
        }
      } else {
        page[propName] = pageDescriptor[propName];
      }
    }
    if (pageDescriptor.masterPage !== null && typeof pageDescriptor.masterPage !== 'undefined'){
      updateSharing(mappedModel, pageDescriptor, page ,pageDescriptor.masterPage)
    }

    section.pages.push(page);
    var removedItems = pageDescriptor.removedItems;
    var insertChar = page.insertChar;
    if (insertChar == null || typeof insertChar === "undefined"){
      for (var i=0;i<originators.length;i++){
        var index = section.pages.indexOf(mappedModel[originators[i]]);
        if (index > -1) {
          section.pages.splice(index, 1);
        }
      }
    }
    if (removedItems !== null && typeof removedItems !== 'undefined'){
      for (var i=0;i<removedItems.length;i++){
        var index = section.pages.indexOf(mappedModel[removedItems[i]]);
        if (index > -1) {
          section.pages.splice(index, 1);
        }
      }
    }
    page.sectionCode = section.code;
    page.displayName = getTitle.call(this, page, this.continueNumbers);
    page.title = getTitle.call(this, page, this.continueNumbers);    
    mappedModel[page.sourceId] = page;
    return page;
  }

  function updateBooks(zone, section){
    
      
      if (zone.books) {
        for (var k = zone.books.length - 1; k >= 0; k--) {
          var bookSections = zone.books[k].sections;
          var removeBook = false;
          for (var b = 0; b < bookSections.length; b++) {
            if (bookSections[b].sectionSourceId === section.sourceId) {
              removeBook = true;
              break;
            }
          }
          if (removeBook) {
            var defaultLayout = zone.books[k].defaultLayout;
            zone.books.splice(k, 1);
            delete section.onBooks;
            section.defaultLayout = defaultLayout;
          }
        }
      }
    
  }

  function convertFromStepModel(model, stepSettings){

    var mappedModel = wizardUtils.mapSourceIdToObject(model);
    var sections = this.stepModel.sections;

    if (this.validModel && !validModel.call(this, mappedModel, sections)){
      return false;
    }

    var showDinky = stepSettings.showDinky;
    var unshared = [];
    var exceptions = ["slip", "slipText", "kill", "killText"];
    var sectionsExceptions = {};
    var unMappedVirtuals = {};
    for (var i=0;i<sections.length;i++){
      var multiplePages = sections[i].zonedPages;
      for (var j=0;j<multiplePages.length;j++){
        var zoneMap = multiplePages[j].zoneMap;
        for (var zoneName in zoneMap){
          var pageDescriptor = zoneMap[zoneName];
          var page = mappedModel[pageDescriptor.sourceId];
          if (typeof page === 'undefined'){
            page = createPageFromDescriptor.call(this, mappedModel, pageDescriptor);
            if (page.insertChar){
              var mySection = mappedModel[page.parent];
              mySection.numPagesChanged = true;
            }
            if (page === null){
              throw "Error In EVAL page " + pageDescriptor.title;
            }

            if (page.panorama){
              var virtualPage = mappedModel[pageDescriptor.virtualPage];
              if (!virtualPage){
                for (var jj=j;jj<multiplePages.length;jj++){
                  var tempZoneMap = multiplePages[jj].zoneMap;
                  for (var tempZoneName in tempZoneMap){
                    var vPageDescriptor = tempZoneMap[tempZoneName];
                    if (vPageDescriptor.sourceId === pageDescriptor.virtualPage){
                      var vPage = mappedModel[vPageDescriptor.sourceId];
                      if (typeof vPage === 'undefined'){
                        vPage = createPageFromDescriptor.call(this, mappedModel, vPageDescriptor);
                        mappedModel[vPage.sourceId] = vPage;
                        break;
                      }
                      
                    }
                    
                  }
                }
              }
            }
          } else {
            //page.split = pageDescriptor.split;
            //var section = mappedModel[page.parent];
            //var removedItems = pageDescriptor.removedItems;
            //if (removedItems !== null && typeof removedItems !== 'undefined'){
            //  for (var i=0;i<removedItems.length;i++){
            //    var index = section.pages.indexOf(mappedModel[removedItems[i]]);
            //    if (index > -1) {
            //      section.pages.splice(index, 1);
            //    }
            //  }
            //}
          }
          updateColors(pageDescriptor, page);
          page.removedColors = addRemovedColors(pageDescriptor.removedColors);
          updateCustomProperties(pageDescriptor, page);
          if (unMappedVirtuals[page.sourceId]){
            updatePanoramaPage.call(this, unMappedVirtuals[page.sourceId], page);
          } else if (pageDescriptor.panorama){
            console.log ("looking for:" + pageDescriptor.virtualPage);
            var virtual = mappedModel[pageDescriptor.virtualPage];
            if (!virtual){
              unMappedVirtuals[pageDescriptor.virtualPage] = page;
            } else {
              updatePanoramaPage.call(this, page, virtual);
            }            
          } else {
            page.panoramaChanged = page.panorama;
            page.panorama = false;
            page.virtualTitle = null;
          }
          if (!pageDescriptor.virtual){
            page.panoramaPage = null;
          }
          page.virtual = pageDescriptor.virtual;
          page.isChase = pageDescriptor.isChase;
          if (showDinky){
            page.isDinky = pageDescriptor.dinky;
          }
          if (pageDescriptor.masterPage !== null && typeof pageDescriptor.masterPage !== "undefined"){
            var masterPage = pageDescriptor.masterPage;
            if (masterPage === null || typeof masterPage === "undefined"){
              unshared.push(pageDescriptor);
            } else {
              updateSharing(mappedModel, pageDescriptor, page ,masterPage);
            }
          } else {
            removeSharing(mappedModel, pageDescriptor, page);
          }

          if (pageDescriptor.basePage !== null && typeof pageDescriptor.basePage !== "undefined"){
            var basePage = pageDescriptor.basePage;
            updateBase(mappedModel, pageDescriptor, page ,basePage);
          } else {
            removeBase(pageDescriptor, page);
          }
          //page.displayName = getTitle(page, this.continueNumbers);
          page.title = getTitle.call(this, page, this.continueNumbers);   
          for (var e=0;e<exceptions.length;e++){
            page[exceptions[e]] = pageDescriptor[exceptions[e]];
            if (page[exceptions[e]]){
              var exceptionType = exceptions[e] === "slip" || exceptions[e] === "slipText" ? "slip" : "kill";
              sectionsExceptions[sections[i].displayName] = exceptionType;
            }
          }
          page.nwxLocal = pageDescriptor.nwxLocal;
          //updatePageCommonId(mappedModel, page);
        }

      }
    }

    var publication = this.model.publication;
    var editions = publication.editions;

    for (var i=0;i<editions.length;i++) {
      var zones = editions[i].zones;
      for (var j=0;j<zones.length;j++){
        var zoneCounter = 0;
        var sections = zones[j].sections;
        for (var k=0;k<sections.length;k++){
          var section = sections[k];
          if (section.numPagesChanged){
            updateBooks.call(this, zones[j], section);
            delete section.numPagesChanged;
          }
          var exceptionType = sectionsExceptions[section.displayName];
          section.exceptionType = exceptionType;
          section.pages.sort(function (page1, page2){
            var pagination1 = page1.pagination;
            var pagination2 = page2.pagination;
            if (pagination1 === pagination2){
              var insertChar1 = page1.insertChar === null || typeof page1.insertChar === 'undefined' ? "" : page1.insertChar;
              var insertChar2 = page2.insertChar === null || typeof page2.insertChar === 'undefined' ? "" : page2.insertChar;
              if (insertChar1 === insertChar2) {
                var splitChar1 = page1.split === null || typeof page1.split === 'undefined' ? "" : page1.split;
                var splitChar2 = page2.split === null || typeof page2.split === 'undefined' ? "" : page2.split;
                return (splitChar1 < splitChar2) ? -1 : 1
              }
              return (insertChar1 < insertChar2) ? -1 : 1
            }
            return parseInt(pagination1, 10) - parseInt(pagination2, 10);
          });
          var sectionCounter = 0, paginationCounter = 0;
          var sectionCounter1 = 0, paginationCounter1 = 0;
          var startWith = parseInt(section.startPage, 10);
          var splitNumPages = section.split ?  parseInt(section.splitNumPages, 10) : 9999;
          for (var n=0;n<section.pages.length;n++){
            page = section.pages[n];

            


            var splitChar = page.split === null || typeof page.split === 'undefined' ? "" : page.split;
            var insertChar = page.insertChar === null || typeof page.insertChar === 'undefined' ? "" : page.insertChar;
            if (splitChar !== "B"){
              zoneCounter++;
              sectionCounter++;
              sectionCounter1++;
            }
            if (insertChar === ""){
              paginationCounter++;
              paginationCounter1++;
            }

            if (sectionCounter == splitNumPages + 1){
              startWith = parseInt(section.splitStartPage, 10);
              paginationCounter1 = 1;
              sectionCounter1 = 1;
            }

            page.numberInSection = sectionCounter;
            page.numberInZone = zoneCounter;
            page.paginationInZone = zoneCounter;    
                    
            page.number = startWith + sectionCounter1 - 1;
            page.pagination = startWith + paginationCounter1 - 1;            
          }
        }
        calcNumbers(sections);
        
      }
    }

    return true;


  }


  function calcNumbers( sections) {
    var numberInZone = 1; 
    var doneSections = {};
    var sectionsSourceIds = {};
    for (var i=0;i<sections.length;i++) {
      sectionsSourceIds[sections[i].sourceId] = sections[i];
    }
    for (var i=0;i<sections.length;i++) {
      var section = sections[i];
      if (doneSections[section.sourceId] === "1") {
        continue;
      }
      numberInZone = calcSection(sectionsSourceIds, doneSections, section, numberInZone);
    }
    
    //printSections(sections);
    
  }
  
  function printSections(sections){
    for (var i=0;i<sections.length;i++) {      
      for (var j=0;j<sections[i].pages.length;j++) {
        var page = sections[i].pages[j];        
      }
    }
  }

  function calcSection (sectionsSourceIds, doneSections, section, numberInZone) {
    var numberInSection = 1;
    doneSections[section.sourceId] = "1";
    for(var i=0;i<section.pages.length;i++){
      var page = section.pages[i];      
      var splitNumPages = section.split ?  parseInt(section.splitNumPages, 10) : 9999;
      var splitAfter = section.splitAfter;
      var splitChar = page.split === null || typeof page.split === 'undefined' ? "" : page.split;
      if (numberInSection == section.splitNumPages && splitAfter !== section.sourceId) {
        // page.numberInSection = numberInSection++;
        numberInSection++;
        page.numberInZone = numberInZone++;		        
        numberInZone = calcSection(sectionsSourceIds, doneSections, sectionsSourceIds[splitAfter], numberInZone);								
      } else {
        // page.numberInSection = numberInSection++;
        numberInSection++;
        page.numberInZone = (splitChar === "A") ? numberInZone : numberInZone++;
        console.log (page.splitChar + ":" + page.numberInZone);
      }				
    }
    return numberInZone;
  }


  return {

    convertToStepModel: convertToStepModel,

    convertFromStepModel: convertFromStepModel,

    zones: zones,

    setModel: function (model){
      this.model = model;
      this.continueNumbers = this.model.continueNumbers;
      this.useCode = this.model.clientSettings && this.model.clientSettings.hasOwnProperty("useCode") ? this.model.clientSettings.useCode : true;
      this.sourceIdBreadcrumb = this.model.clientSettings && this.model.clientSettings.sourceIdBreadcrumb ? this.model.clientSettings.sourceIdBreadcrumb : false; 
      this.namingPattern = this.model.clientSettings && this.model.clientSettings.pageNamingPattern ? this.model.clientSettings.pageNamingPattern :
        [];
      this.validModel = this.model.clientSettings && this.model.clientSettings.validModel ? this.model.clientSettings.validModel : false; 
    },

    isValid: function(){
      return true;
    },

    findBySourceId: findBySourceId

  };

});
