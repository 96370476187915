import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ProgressBar extends Component {
  static propTypes = {
    percent: PropTypes.number,
    text: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    style: {}
  };

  render() {
    const { percent, text, className, style } = this.props;
    const cls = `uv-progress ${className}`;
    const s = { style, width: `${percent}%` };

    return (
      <div className={cls}>
        <div className='uv-progress-bar' style={s}>
          {text}
        </div>
      </div>
    );
  }

};
