import { COMPARE_TYPE } from 'core/comparators';
import { isEqual } from 'base/jsUtils';
import { cells } from 'widgets/ReactDataGrid/';
import { toLocaleShortDateTime } from 'core/services/localization';
import { applyColumnPreferences, FILTER, NO_VALUE } from 'widgets/ReactDataGrid/utils';
import { getTemplateIcon, getGeneralIcon, getModuleIcon } from 'core/services/iconService';
import { createObjectComparator } from 'core/comparators';
import { translate } from 'core/services/localization';
import { getMainStatus } from 'utilities/statuses';
import icons from 'core/services/iconService';
import {
  WAITING,
  ERROR,
  SUCCESS,
  NOT_ASSIGNED,
  STATUS_FILTER_OPTIONS,
  PAGE_COLOR_OPTIONS,
} from './constants';
const { Text, Thumbnail, IconCell } = cells;

export const defaultShouldCellUpdate = (nextProps, props) => {
  return nextProps.columnData !== props.columnData || nextProps.backgroundColor !== props.backgroundColor ||
    nextProps.title !== props.title || nextProps.icon !== props.icon || nextProps.name !== props.name ||
    !isEqual(nextProps.style, props.style) || nextProps.iconUID !== props.iconUID;
};

export const makeFragmentsTableColumns = (model, module) => {
  const columnPrefs = module.preferences.table?.fragmentColumns || [];
  const isRejected = (status, flowStepTemplate) => {
    return status === 'Error' && flowStepTemplate.toLowerCase() === 'workflow/step/flow/approval';
  };

  const isWaitingForApproval = (status, flowStepTemplate) => {
    return status === 'Waiting' && flowStepTemplate.toLowerCase() === 'workflow/step/flow/approval';
  };

  const getStatusDistinctiveValue = fragment => {
    const { defaultStatus: { statusType, flowStepType } } = fragment;

    let value = '';

    switch (statusType) {
      case 'Success':
        value = 'Success';
        break;

      case 'Waiting':
        if (isWaitingForApproval(statusType, flowStepType)) {
          value = 'waiting_for_approval';
          break;
        } else {
          value = 'wait';
          break;
        }
      case 'Processing':
        value = 'in_progress';
        break;
      case 'Reject':
        value = 'reject';
        break;
      case 'NotAssigned':
        value = 'NotAssigned';
        break;
      case 'Error':
        if (isRejected(statusType, flowStepType)) {
          value = 'reject';
          break;
        } else {
          value = 'error';
          break;
        }
    }
    return value;
  };

  const getFragmentContentType = fragment => fragment && fragment.contentType || '';


  const getColorValue = (fragment) => {
    let colorValue = '';

    if (fragment.separationCount > 1) {
      colorValue = 'color';
    } else {
      colorValue = 'bw';
    }
    return colorValue;
  };

  const separationCellDataGetter = (fragment) => {
    let icon, title, iconStyle;

    const colorValue = getColorValue(fragment);

    if (colorValue === 'bw') {
      icon = getModuleIcon('TableView', 'bw');
      title = translate('Black and white');
    } else if (colorValue === 'color') {
      icon = getModuleIcon('TableView', 'color');
      title = translate('Color');
    }


    return {
      icon,
      title,
      iconStyle
    };
  };

  let columns = [
    {
      key: 'thumbnail',
      caption: translate('Thumbnail'),
      width: 100,
      align: 'center',
      cell: Thumbnail,
      cellDataGetter: (rowIndex, key) => {
        const fragment = model.filteredFragments[rowIndex];
        if (!fragment) {
          return;
        }

        return fragment.versionNwid === '000' ? {} : {
          columnData: fragment.nwid,
          iconUID: fragment.versionNwid,
          template: fragment.type,
          style: { textAlign: 'center' }
          // onDoubleClick: (rowIndex, columnKey, e) => module.handleAdsTableThumbnailDoubleClick(page)
        };

      }

    },
    {
      key: 'name',
      width: 150,
      caption: translate('Name'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const name = model.filteredFragments[rowIndex]?.name || '';

        return {
          columnData: name,
        };
      }
    },
    {
      key: 'contentType',
      width: 200,
      caption: translate('Type'),
      cell: Text,
      filter: {
        options: createTypeOptions(model.filteredFragments),
        ...FILTER.MULTISELECT_TEXT,
      },
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filterValueGetter: getFragmentContentType,
      sortValueGetter: getFragmentContentType,
      cellDataGetter: (rowIndex, key) => {
        const columnData = getFragmentContentType(model.filteredFragments[rowIndex]);

        return {
          columnData
        };
      }
    },
    {
      key: 'expectedName',
      width: 150,
      caption: translate('Expected File Name'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const expectedName = model.filteredFragments[rowIndex]?.expectedName || '';

        return {
          columnData: expectedName,
        };
      }
    },
    {
      key: 'mainVersionInputFileName',
      width: 100,
      caption: translate('Input File Name'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const mainVersionInputFileName = model.filteredFragments[rowIndex]?.mainVersionInputFileName || '';

        return {
          columnData: mainVersionInputFileName,
        };
      }
    },
    {
      key: 'externalVersion',
      width: 100,
      caption: translate('Version'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const externalVersion = model.filteredFragments[rowIndex]?.externalVersion || '';

        return {
          columnData: externalVersion,
        };
      }
    },
    {
      key: 'width',
      width: 70,
      caption: translate('Width'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const width = model.filteredFragments[rowIndex]?.width || '';

        return {
          columnData: width,
        };
      }
    },
    {
      key: 'height',
      width: 70,
      caption: translate('Height'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const height = model.filteredFragments[rowIndex]?.height || '';

        return {
          columnData: height,
        };
      }
    },
    {
      key: 'separationCount',
      caption: translate('Color'),
      cell: IconCell,
      width: 60,
      align: 'center',
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: {
        options: PAGE_COLOR_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },

      sortValueGetter: getColorValue,

      filterValueGetter: getColorValue,

      cellDataGetter: (rowIndex, key) => {
        const fragment = model.filteredFragments[rowIndex];

        return separationCellDataGetter(fragment);
      },
    },
    {
      key: 'statusType',
      width: 70,
      caption: translate('Status'),
      cell: IconCell,
      align: 'center',
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: {
        options: STATUS_FILTER_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      sortValueGetter: fragment => (getMainStatus(fragment) || {}).statusType,

      filterValueGetter: getStatusDistinctiveValue,

      cellDataGetter: (rowIndex, key) => {
        const fragment = model.filteredFragments[rowIndex];
        const status = getMainStatus(fragment) || {};
        const { statusType = NOT_ASSIGNED, flowStepType } = status;

        let icon;
        if (statusType !== NOT_ASSIGNED) {
          console.log(statusType, flowStepType);
          if (statusType === WAITING && flowStepType === 'workflow/step/flow/approval') {
            icon = getGeneralIcon('status', 'waiting_for_approval');
          } else if (statusType === SUCCESS) {
            icon = getGeneralIcon('status', 'finished');
          } else if (statusType === ERROR && flowStepType === 'workflow/step/flow/approval') {
            icon = getModuleIcon('Thumbnail', 'reject-small');
          } else {
            icon = getGeneralIcon('status', statusType.toLowerCase());
          }
        }

        return {
          icon
        };
      }
    },

    {
      key: 'flowStepType',
      caption: translate('Flow Step'),
      cell: IconCell,
      align: 'center',
      width: 100,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: fragment => (getMainStatus(fragment) || {}).flowStepType,
      cellDataGetter: (rowIndex, key) => {
        const fragment = model.filteredFragments[rowIndex];
        const status = getMainStatus(fragment) || {};
        const { statusType = NOT_ASSIGNED, flowStepType, flowStepIconName } = status;

        let icon;
        if (flowStepType && statusType !== NOT_ASSIGNED) {
          icon = getTemplateIcon(flowStepType, 'tiny', flowStepIconName);
        }
        const title = flowStepType;
        return {
          icon,
          title
        };
      }
    },
    {
      key: 'completionTime',
      caption: translate('Time'),
      width: 150,
      resizable: true,
      cell: Text,
      sortType: COMPARE_TYPE.DATES,
      filter: FILTER.DATE,

      cellDataGetter: (rowIndex, key) => {
        const date = model.filteredFragments[rowIndex]?.completionTime || '';

        return {
          columnData: toLocaleShortDateTime(date)
        };
      }
    }
  ];

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};


const createTypeOptions = (fragments) => {

  const mergedTypes = fragments.reduce((acc, fragment) => {

    if (!acc[fragment.contentType]) {
      acc[fragment.contentType] = true;
    }

    return acc;
  }, []);

  const filterOptions = Object.keys(mergedTypes).map(type => ({
    value: type,
    data: { columnData: type || NO_VALUE }
  }));

  return filterOptions.sort(createObjectComparator('value'));
};



