import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import { fromServerDate } from 'core/dates';
import TickableModel from '../TickableModel';
import { createStore } from 'redux';
import reducer from './reducer';
import PlaneView, { DataSource } from 'components/private/planeView';
import ApprovalThumbnail from 'components/private/thumbnails/Approval';
import SelectableBorder from 'components/common/borders/SelectableBorder';
import { SIZES, pageSize, formSize, getImageUrl, getIsPlanedSeparation, getFlowStepImageUrl, getHoldImageUrl, getPreFlightImageUrl, getLockImageUrl, getPreApprovedImageUrl, getSkipFlowStepImageUrl, getMultiApproversImageUrl } from 'utilities/thumbnails';
import clientConfiguration from 'utilities/clientConfiguration';
import { restGet } from 'core/managers/rest2';
import { getMainStatus } from 'utilities/statuses';

const translate = sandbox.localization.translate;
const labels = {
  hold: translate('Hold'),
  preFlight: translate('Pre Flight'),
  lock: translate('Lock'),
  preApproved: translate('Pre Approved'),
  skipFlowstep: translate('Skip Flowstep'),
  MultiAdvertising: translate('Advertising'),
  MultiEditorial: translate('Editorial'),
  "advertiesr-A": translate('Advertising'),
  "advertiesr-E": translate('Editorial')
};

const getStatus = item => getMainStatus(item) || {};

const isUndefined = o => typeof o === 'undefined';
const isFunction = o => typeof o === 'function';

const DEFAULT_ITEM_ZOOM = 1;
const GRID_ITEM_SIZES = {
  1: { width: 112, height: 116 },
  2: { width: 131, height: 130 },
  3: { width: 150, height: 145 }
  // small: { width: 106, height: 114 },
}
const ITEM_PADDING = 2;

const getClientConfigurationZoom = (type = 'page') => {
  const approvalThumbnailViewConfiguration = clientConfiguration('approvalThumbnailView') || {};
  const zoom = approvalThumbnailViewConfiguration[`${type}Zoom`] || DEFAULT_ITEM_ZOOM;

  return zoom;
};
const getItemContent = item => item.pageContent || item.formContent || {};

const getSize = item => item.type === 'page' ? pageSize(getItemContent(item)) :
  item.type === 'form' ? formSize(item.PageInfoOnForm.matrix) :
    SIZES.SINGLE;

const getFlowStepStatusTypeCode = ({ flowStepType, statusType }) => {
  const statusTypes = {
    'success': 1,
    'waiting': 0,
    'hold': -1,
    'error': -1
  };

  return !flowStepType ? statusTypes.waiting : statusTypes[statusType.toLowerCase()] || statusTypes.waiting;
};

const getSeparations = ({ separations }) => {
  return sandbox.sorting.sort(separations.concat([]), 'cmyk', 'name')
    .map(separation => {
      const status = getStatus(separation);
      return {
        name: separation.name,
        color: sandbox.colors.getUIColorRGB(sandbox.colors.getColorByName(separation.name)),
        version: separation && separation.separationContent ? separation && separation.separationContent ? separation.separationContent.externalVersion : undefined : undefined,
        filled: getIsPlanedSeparation(status.progress, separation && separation.separationContent ? separation.separationContent.externalVersion : 0),
        stepImageUrl: getFlowStepImageUrl(status),
        stepStatuses: [getFlowStepStatusTypeCode(status)]
      };
    });
};

const getIndicators = (item) => {
  let indicators = [];
  const itemContent = getItemContent(item);

  // multi approvers - page only
  if (!isUndefined(item.approvalIndication)) {
    item.approvalIndication.trim().split(',').forEach(indicator => indicators = indicators.concat({ label: indicator, name: indicator, indicatorType: 'text' }))
  };

  //lock indicator - form only
  const lockImageUrl = getLockImageUrl(item.allowedResources);
  if (!isUndefined(lockImageUrl)) indicators = indicators.concat({ imageUrl: lockImageUrl, name: labels.lock, indicatorType: 'image' });

  //hold indicator - page and form
  const holdImageUrl = getHoldImageUrl(item.holdType);
  if (!isUndefined(holdImageUrl)) indicators = indicators.concat({ imageUrl: holdImageUrl, name: labels.hold, indicatorType: 'image' });

  //preflight indicator - page only
  const preflightReportType = itemContent && itemContent.preflightReportInfo ? itemContent.preflightReportInfo.preflightReportType : undefined;
  const preFlightImageUrl = getPreFlightImageUrl(preflightReportType);
  if (!isUndefined(preFlightImageUrl)) indicators = indicators.concat({ imageUrl: preFlightImageUrl, name: labels.preFlight, indicatorType: 'image' });

  //pre approved - page and form
  const preApprovedImageUrl = getPreApprovedImageUrl(itemContent.isPreApproved);
  if (!isUndefined(preApprovedImageUrl)) indicators = indicators.concat({ imageUrl: preApprovedImageUrl, name: labels.preApproved, indicatorType: 'image' });

  //skip flow step - page and form
  const skipFlowstepsImageUrl = getSkipFlowStepImageUrl(itemContent.skipSteps);
  if (!isUndefined(skipFlowstepsImageUrl)) indicators = indicators.concat({ imageUrl: skipFlowstepsImageUrl, name: labels.skipFlowstep, indicatorType: 'image' });

  return indicators;
};

const renderIndicator = (indicator, index) => {
  if (indicator.indicatorType === 'image') return <img key={index} className="crtx-approval-indicator"
    src={indicator.imageUrl}
    title={indicator.name} />;

  if (indicator.indicatorType === 'text') return <span key={index} style={{ float: left, textAlign: center, marginRight: '2px' }}
    title={indicator.name}>{indicator.label}</span>

};

const itemCreator = (item, flowStepNwid, viewId, projectorId) => {
  const deadline = fromServerDate(item.DeadLine);
  const eventTime = fromServerDate(item.EventTime[flowStepNwid]);
  const title = item.EventTitle[flowStepNwid] || item.name;
  const itemContent = getItemContent(item);
  const status = getStatus(item);

  return {
    nwid: item.nwid,
    title,
    columns: getSize(item) === SIZES.SINGLE ? 1 : 2,
    imageUrl: getImageUrl({
      contentNwid: itemContent.nwid,
      contentType: itemContent.type,
      versionNwid: itemContent.versionNwid,
      contentVersionMajorIndex: itemContent.contentVersionMajorIndex,
      viewId: viewId,
      projectorId: projectorId
    }),
    stepImageUrl: getFlowStepImageUrl(status),
    stepStatuses: [getFlowStepStatusTypeCode(status)],
    separations: getSeparations(item),
    indicators: getIndicators(item).map(renderIndicator),
    deadline,
    eventTime
  };
};

export default AbstractModule.extend({
  init: function () {
    this._super();
    for (var prop in this) {
      if (prop !== '_super' && isFunction(this[prop])) this[prop] = this[prop].bind(this);
    }
  },
  handleKeyDown: function (event) {
    if (event.keyCode === 65 && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      if (isUndefined(this.store)) return;

      const { items, lastSelectedNwid } = this.store.getState();
      const newSelectNwids = items.reduce((selectedNwid, item) => {
        return {
          ...selectedNwid,
          [item.nwid]: true
        };
      }, {});

      this.updateSelected(items.map(item => this.tickableModel.getByNwid(item.nwid)));

      this.store.dispatch({
        type: 'select',
        nwid: lastSelectedNwid,
        selectNwids: newSelectNwids
      });
    }
  },
  handleAddItem: function (item) {
    const { flowStepNwid } = this.store.getState();

    this.store.dispatch({
      type: 'addItem',
      item: itemCreator(item, flowStepNwid, this.id, this.projectorId)
    });
  },
  handleUpdateItem: function (item) {
    const { flowStepNwid } = this.store.getState();

    this.store.dispatch({
      type: 'updateItem',
      item: itemCreator(item, flowStepNwid, this.id, this.projectorId)
    });
  },
  handleRemoveItem: function (item) {
    const { items, selectNwids } = this.store.getState();
    const isSelected = this.isSelectedNwid(selectNwids, item.nwid);
    const newSelectNwids = isSelected ? this.filerSelectedNwid(selectNwids, item.nwid) : selectNwids;

    if (isSelected) {
      this.updateSelected(items.filter(selectedItem => newSelectNwids[selectedItem.nwid] === true).map(selectedItem => this.tickableModel.getByNwid(selectedItem.nwid)));
    }

    this.store.dispatch({
      type: 'removeItem',
      nwid: item.nwid,
      selectNwids: newSelectNwids
    });
  },
  handlePage: function (action, page) {
    if (action === 'add') this.handleAddItem(page);
    if (action === 'update') this.handleUpdateItem(page);
    if (action === 'remove') this.handleRemoveItem(page);
  },
  handleInnerPageStructure: function (action, item) {
    const state = this.store.getState();
    const parentItem = item.getParent('page');
    const foundItemInState = state.items.findIndex(item => item.nwid === parentItem.nwid) >= 0;

    if (foundItemInState) this.handleUpdateItem(parentItem);
  },
  handleInnerFormStructure: function (action, item) {
    const state = this.store.getState();
    const parentItem = item.getParent('form');
    const foundItemInState = state.items.findIndex(item => item.nwid === parentItem.nwid) >= 0;

    if (foundItemInState) this.handleUpdateItem(parentItem);
  },
  initDone: function () {
    this.createToolbar();

    this.domElement.tabIndex = 2;
    this.domElement.addEventListener('keydown', this.handleKeyDown);
    this.reactRoot = createRoot(this.domElement);

    this.tickableModel = new TickableModel();
    this.tickableModel.register(['eventqueue', 'page'], this.handlePage);
    this.tickableModel.register(['eventqueue', 'page', 'page/content'], this.handleInnerPageStructure);
    this.tickableModel.register(['eventqueue', 'page', 'status_queue/value'], this.handleInnerPageStructure);
    this.tickableModel.register(['eventqueue', 'page', 'page/separation'], this.handleInnerPageStructure);
    this.tickableModel.register(['eventqueue', 'page', 'page/separation', 'status_queue/value'], this.handleInnerPageStructure);
    this.tickableModel.register(['eventqueue', 'page', 'page/separation/content'], this.handleInnerPageStructure);

    this.tickableModel.register(['eventqueue', 'form'], this.handlePage);
    this.tickableModel.register(['eventqueue', 'form', 'form/content'], this.handleInnerFormStructure);
    this.tickableModel.register(['eventqueue', 'form', 'status_queue/value'], this.handleInnerFormStructure);
    this.tickableModel.register(['eventqueue', 'form', 'form/separation'], this.handleInnerFormStructure);
    this.tickableModel.register(['eventqueue', 'form', 'form/separation', 'status_queue/value'], this.handleInnerFormStructure);
    this.tickableModel.register(['eventqueue', 'form', 'form/separation/content'], this.handleInnerFormStructure);
  },
  firstTickReceived: function (data) {
    this.store = createStore(reducer, {
      items: new DataSource({
        items: [],
        itemColumnsGetter: item => item.columns
      }),
      selectNwids: {},
      flowStepNwid: data.model.nwid,
      zoom: getClientConfigurationZoom('page')
    });
    this.tickableModel.firstTickHandler(data.model);

    restGet(this.nwid, 'conversion-tables/Approvers/data')
      .then(approversData => {
        this.approvers = approversData;
        this.store.subscribe(this.render);
        this.render();
      });

  },
  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);
  },
  updateModuleSelect: function (selectNwids) {
    const { items } = this.store.getState();

    this.updateSelected(items.filter(item => selectNwids[item.nwid] === true));
  },
  handleViewClick: function () {
    this.updateSelected([]);

    this.store.dispatch({
      type: 'select',
      nwid: undefined,
      selectNwids: {}
    });
  },
  handlePlaneViewResize: function ({ height, width }) {
    const { items, zoom } = this.store.getState();
    const columns = Math.floor(width / GRID_ITEM_SIZES[zoom].width);
    items.setColumns(columns);
    this.render();
  },
  isSelectedNwid: function (selectNwids, nwid) {
    return !!selectNwids[nwid];
  },
  filerSelectedNwid: function (selectNwids, nwid) {
    let newSelectNwids = { ...selectNwids };
    delete newSelectNwids[nwid];

    return newSelectNwids;
  },
  getNwidsBetween: function (lastSelectedNwid, nwid) {
    const { items } = this.store.getState();
    const lastSelectedIndex = items.findIndex(item => item.nwid === lastSelectedNwid);
    const currentSelectedIndex = items.findIndex(item => item.nwid === nwid);

    if (lastSelectedIndex < 0) return { [nwid]: true };

    return items.slice(Math.min(lastSelectedIndex, currentSelectedIndex), Math.max(lastSelectedIndex, currentSelectedIndex) + 1)
      .reduce((selectedNwids, item) => {
        return {
          ...selectedNwids,
          [item.nwid]: true
        };
      }, {});
  },
  handleSelectableBorderClick: function (item) {
    return event => {
      const { items, selectNwids, lastSelectedNwid } = this.store.getState();
      const operator = event.metaKey === true || event.ctrlKey === true ? 'ctrl' :
        event.shiftKey === true ? 'shift' :
          undefined;
      const newSelectNwids = isUndefined(operator) ? { [item.nwid]: true } :
        operator === 'ctrl' ? selectNwids[item.nwid] === true ? this.filerSelectedNwid(selectNwids, item.nwid) : { ...selectNwids, [item.nwid]: true } :
          operator === 'shift' ? this.getNwidsBetween(lastSelectedNwid, item.nwid) :
            { ...selectNwids };

      this.updateSelected(items.filter(item => newSelectNwids[item.nwid] === true).map(item => this.tickableModel.getByNwid(item.nwid)));

      this.store.dispatch({
        type: 'select',
        nwid: item.nwid,
        selectNwids: newSelectNwids
      });

      event.stopPropagation();
    };
  },
  handleSelectableContextMenu: function (selectedItem) {
    return event => {
      const { items, selectNwids } = this.store.getState();
      const isSelected = this.isSelectedNwid(selectNwids, selectedItem.nwid);
      const selectedItems = isSelected ? items.filter(item => selectNwids[item.nwid] === true) : [selectedItem];
      const selectedItemsFromTick = selectedItems.map(item => this.tickableModel.getByNwid(item.nwid));

      if (!isSelected) {
        this.updateSelected([selectedItem].map(item => this.tickableModel.getByNwid(item.nwid)));

        this.store.dispatch({
          type: 'select',
          nwid: selectedItem.nwid,
          selectNwids: this.getNwidsBetween(selectedItem.nwid, selectedItem.nwid)
        });
      }

      this.showContextMenu(this.tickableModel.getByNwid(selectedItem.nwid), selectedItemsFromTick, event);

      event.stopPropagation();
    };
  },
  handleSelectableDoubleClick: function (selectedItem) {
    return event => {
      const modelItem = this.tickableModel.getByNwid(selectedItem.nwid);

      this.navigateByViewLink(modelItem, undefined, event);
    };
  },
  render: function () {
    const { items, selectNwids, zoom } = this.store.getState();

    this.reactRoot.render(
      <div style={{ width: '100%', height: '100%', minWidth: 400 }} onClick={this.handleViewClick}>
        <PlaneView
          gridItemWidth={GRID_ITEM_SIZES[zoom].width}
          gridItemHeight={GRID_ITEM_SIZES[zoom].height}
          itemPadding={ITEM_PADDING}
          items={items}
          itemRenderer={item => <SelectableBorder selected={!!selectNwids[item.nwid]}
            onClick={this.handleSelectableBorderClick(item)}
            onContextMenu={this.handleSelectableContextMenu(item)}
            onDoubleClick={this.handleSelectableDoubleClick(item)}
          >
            <ApprovalThumbnail
              title={item.title}
              separations={item.separations}
              imageUrl={item.imageUrl}
              stepImageUrl={item.stepImageUrl}
              stepStatuses={item.stepStatuses}
              indicators={item.indicators}
            />
          </SelectableBorder>}
          onResize={this.handlePlaneViewResize}
        />
      </div>
    );
  },

  destroy: function () {
    this._super();
    this.domElement.removeEventListener('keydown', this.handleKeyDown);
    this.reactRoot.unmount();
  }
});