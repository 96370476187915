import React, { Component } from 'react';
import Popup from 'components/common/popup/Popup';
import Checkbox from 'components/common/inputs/Checkbox';
import PropTypes from 'prop-types';
import SeparatorOption from './SeparatorOption';
import { createObjectComparator, createValueComparator, COMPARE_TYPE } from 'core/comparators';
import IconOption from './IconOption';
import TextOption from './TextOption';
import GenericOption from './GenericOption';
import sandbox from 'sandbox';
import FlatButton from 'components/common/buttons/FlatButton';
import SvgIcon from 'components/common/buttons/SvgIcon';
import { SearchInput } from 'components/common/inputs/SearchInput';

const { translate } = sandbox.localization;

const stringObjectComparator = createObjectComparator('value');
const numericComparator = createValueComparator(COMPARE_TYPE.NUMBERS);

const NO_VALUE = '_NO_VALUE_';
const SEPARATOR = 'separator';
const TEXT = 'text';
const ICON = 'icon';
const GENERIC = 'generic';
const SHORT_DATE = 'shortDate';
const FILTER_LIST_ICON = 'filter_list';
const VALUE = 'value';

const labels = {
  none: translate('Blanks')
};
export default class MultiselectFilter extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, searchValue: '', sortedOptions: [], filteredOptions: [] };
    this.mappedOptions = this.mapOptions();
  }

  static propTypes = {
    selectedValues: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.any
    ]),
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.any
    ]),
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
    shouldSortOptions: PropTypes.bool,
    dataType: PropTypes.string,
  };

  static defaultProps = {
    selectedValues: [],
    options: [],
    shouldSortOptions: false
  };

  handleSearchValueChange = (event, newValue) => {
    const { sortedOptions } = this.state;
    const filteredOptions = this.filterOptions(sortedOptions, newValue);
    this.setState({
      searchValue: newValue,
      filteredOptions
    });
  };

  shouldRemoveSeparator = (option, index, filteredOptions) => {
    return typeof option === 'object' && option.type === SEPARATOR && (index === 0 || index === filteredOptions.length - 1);
  };

  removeUnnecessarySeparator = filteredOptions => {
    return filteredOptions.filter((option, index, filteredOptions) => !this.shouldRemoveSeparator(option, index, filteredOptions));
  };

  filterOptions = (sortedOptions, newValue) => {
    const { dataType } = this.props;
    let filteredOptions = sortedOptions;
    if (dataType === TEXT && newValue) {
      const filteredOptionsByDataType = sortedOptions.filter(option => {
        return typeof option[VALUE] !== 'string' ? option : option[VALUE].toLowerCase().includes(newValue.toLowerCase());
      });

      filteredOptions = this.removeUnnecessarySeparator(filteredOptionsByDataType);
    }
    return filteredOptions;
  };

  renderOptions = () => {
    const { dataType } = this.props;
    const { searchValue, filteredOptions } = this.state;
    return <div className='crtx-filter-multiselect-popup-div'>
      {dataType === TEXT && <SearchInput className='crtx-filter-multiselect-search-input' onChange={this.handleSearchValueChange} value={searchValue} />}
      <ul className='crtx-multiselect-items'>
        {filteredOptions.map(this.createOption)}
      </ul>
    </div>;
  };

  renderOptionBody = (option, dataType) => {
    return dataType === ICON ? <IconOption data={option.data} /> :
      dataType === TEXT ? <TextOption text={option.data.columnData} /> :
        <GenericOption columnData={option.data.columnData} title={option.data.title} />;
  };

  createOptionTooltip = (option, dataType) => dataType === TEXT ? option.data.columnData : option.data.title || option.data.columnData;

  createOption = (option, index) => {
    const { dataType } = this.props;
    const value = typeof option[VALUE] !== 'undefined' ? option[VALUE] : option;
    const optionTooltip = option.type !== SEPARATOR && this.createOptionTooltip(option, dataType);
    const mappedSelectedOptions = this.mapSelectedOptions();
    const checked = mappedSelectedOptions[value] ? true : false;
    return option.type === SEPARATOR ? <SeparatorOption key={index} /> : (<div key={index}>
      <li value={value} className='crtx-filter-multiselect-item' title={optionTooltip}>
        <label className='crtx-filter-multiselect-item-label'>
          <Checkbox className='crtx-multiselect-checkbox' onChange={(event, checked) => this.handleSelectedItem(event, checked, option)} checked={checked} />
          {this.renderOptionBody(option, dataType)}
        </label>
      </li>
    </div>);
  };

  handleSelectedItem = (event, checked, option) => {
    const { selectedValues, onSelect } = this.props;

    let updatedSelectedValues = [...selectedValues];

    if (checked) {
      updatedSelectedValues.push(option.value);
    } else {
      updatedSelectedValues = updatedSelectedValues.filter(selectedValue => selectedValue !== option.value);
    }
    if (typeof onSelect === 'function') {
      onSelect(event, updatedSelectedValues);
    }
  };

  getComperator = dataType => dataType === SHORT_DATE ? numericComparator : stringObjectComparator;

  mapOptions = () => {
    const { options } = this.props;
    const mappedOptions = {};
    options.forEach(option => {
      mappedOptions[option.value] = option.value === NO_VALUE ? { ...option, data: { ...option.data, columnData: labels.none } } : option;
    });

    return mappedOptions;
  };

  mapSelectedOptions = () => {
    const { selectedValues } = this.props;
    const mapSelectedOptions = {};
    selectedValues.forEach(value => {
      mapSelectedOptions[value] = this.mappedOptions[value];
    });
    return mapSelectedOptions;
  };

  sortOptions = () => {
    const { dataType, shouldSortOptions } = this.props;
    this.mappedOptions = this.mapOptions();
    const mappedSelectedOptions = this.mapSelectedOptions();
    const checkedOptions = [];
    const uncheckedOptions = [];
    const noValueOptions = [];
    const mappedOptionsKeys = Object.keys(this.mappedOptions);

    mappedOptionsKeys.forEach(key => {
      if (this.mappedOptions[key].value === NO_VALUE) {
        noValueOptions.push({ value: NO_VALUE, data: { columnData: labels.none } });
      } else if (mappedSelectedOptions[this.mappedOptions[key].value]) {
        checkedOptions.push(this.mappedOptions[key]);
      } else {
        uncheckedOptions.push(this.mappedOptions[key]);
      }
    });

    let sortedCheckedOptions = [...checkedOptions];
    let sortedUncheckedOptions = [...uncheckedOptions];

    if (shouldSortOptions) {
      const sortComparator = this.getComperator(dataType);
      sortedCheckedOptions = checkedOptions.sort(sortComparator);
      sortedUncheckedOptions = uncheckedOptions.sort(sortComparator);
    }

    const separatorOptions = sortedCheckedOptions.length > 0 && sortedUncheckedOptions.length > 0 ? [{ type: SEPARATOR }] : [];
    const sortedOptions = [...noValueOptions, ...sortedCheckedOptions, ...separatorOptions, ...sortedUncheckedOptions];

    return sortedOptions;
  };

  togglePopup = () => {
    const { disabled, searchValue } = this.state;

    if (!disabled) {
      const visible = !this.state.visible;
      if (visible) {
        const sortedOptions = this.sortOptions();
        const filteredOptions = this.filterOptions(sortedOptions, searchValue);
        this.setState({
          sortedOptions,
          filteredOptions
        });
      }
      this.setState({
        visible
      });
    }
  };

  getSelectedOptionsData = () => {
    const { selectedValues } = this.props;
    const selectedOptionsData = [];

    selectedValues.forEach(value => this.mappedOptions[value] && selectedOptionsData.push(this.mappedOptions[value].data));
    return selectedOptionsData;
  };

  createTooltip = dataType => {
    const selectedOptionsData = this.getSelectedOptionsData();
    let tooltip = '';
    if (dataType === GENERIC && selectedOptionsData.length > 1) {
      tooltip = translate('{1} options selected', selectedOptionsData.length);
    } else {
      tooltip = dataType === TEXT ? selectedOptionsData.map(option => `${option.columnData}`).join(', ') : selectedOptionsData.map(option => `${option.title || option.columnData}`).join(', ');
    }
    return tooltip;
  };

  createFilterCaption = dataType => {
    const selectedOptionsData = this.getSelectedOptionsData();
    const numSelectedOptions = selectedOptionsData.length;

    return numSelectedOptions > 1 && dataType === GENERIC ? <label>{translate('{1} options selected', numSelectedOptions)}</label>
      : selectedOptionsData.map((option, index) => <label key={index}>
        {dataType === ICON ?
          (option.iconSprite ? <SvgIcon name={option.icon} sprite={option.iconSprite} style={option.iconStyle} /> :
            <img className='crtx-filter-multiselect-content-icon' src={option.icon} style={option.iconStyle} />
          )
          : option.columnData}
        {(numSelectedOptions > 1 && index < numSelectedOptions - 1) ? dataType === ICON ? <span> </span> : <span>, </span> : undefined}
      </label>);
  };



  handleClearFilter = event => {
    const { onSelect } = this.props;

    if (typeof onSelect === 'function') {
      onSelect(event, []);
    }
    event.stopPropagation();
  };

  render() {
    const { className, disabled, dataType, selectedValues, style } = this.props;
    const { visible } = this.state;
    const disabledClassName = disabled ? 'disabled' : '';
    const active = visible ? 'active' : '';
    const tooltip = this.createTooltip(dataType);
    const numSelectedValues = selectedValues.length;
    const filterCaption = this.createFilterCaption(dataType);

    return <div ref='multiselect' className={`crtx-multiselect crtx-filter-multiselect ${className} ${disabledClassName} ${active}`} style={style} title={tooltip} onClick={this.togglePopup}>
      <div className='crtx-filter-multiselect-content'>{(numSelectedValues > 1 && dataType === TEXT) && `(${numSelectedValues}) `}{filterCaption}</div>
      {numSelectedValues === 0 ? <SvgIcon
        key={FILTER_LIST_ICON}
        name={FILTER_LIST_ICON}
        className='crtx-filter-multiselect-filter-list-icon'
      /> :
        <FlatButton className='crtx-filter-multiselect-clear-filter-button' onClick={this.handleClearFilter}>×</FlatButton>}
      <Popup width='auto' className='crtx-filter-multiselect-popup' opener={this.refs.multiselect} visible={visible} onClickedOutside={this.togglePopup} filterEnabled={true}>
        {visible && this.renderOptions()}
      </Popup>
    </div>;
  }
};
