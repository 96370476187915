import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { SORT_DIRECTION } from 'core/comparators';

export default class HeaderCaption extends Component {
  static propTypes = {
    sortable: PropTypes.bool,
    sortDirection: PropTypes.string,
    sortOrder: PropTypes.number,
    sortIcon: PropTypes.object,
    tooltip: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    onSort: PropTypes.func,
    autoWidth: PropTypes.bool
  };

  static defaultProps = {
    sortable: false,
    sortDirection: SORT_DIRECTION.NONE,
    sortOrder: -1,
    sortIcon: {
      [SORT_DIRECTION.ASC]: {
        className: 'material-icons',
        content: 'arrow_downward',
      },
      [SORT_DIRECTION.DESC]: {
        className: 'material-icons',
        content: 'arrow_upward',
      },
    },
    onSort: () => {
    }
  };

  handleCaptionClick = (e) => {
    const { onSort, sortable } = this.props;

    if (sortable) {
      const multiSort = e.ctrlKey || e.metaKey || e.shiftKey;
      onSort(multiSort);
    }
  };

  handleIconClick = (e) => {
    const { onSort, sortable } = this.props;

    if (sortable) {
      e.stopPropagation();
      const multiSort = true;
      onSort(multiSort);
    }
  };

  renderSortIcon() {
    const { sortable, sortDirection, sortOrder, sortIcon } = this.props;

    if (!sortable) {
      return;
    }

    const icon = sortIcon[sortDirection];
    const className = `sort-icon ${icon ? 'active' : ''}`;

    return (
      <div className={className}
        onClick={this.handleIconClick}>
        {icon && sortOrder > 0 ? <div>{sortOrder}</div> : undefined}
        {icon ? <i className={icon.className}>{icon.content}</i> : undefined}
      </div>
    );
  }

  render() {
    const { sortable, tooltip, children, className, autoWidth } = this.props;


    return (
      <div className={classNames('column-header-caption-with-sort-icon', className, { sortable: sortable })} onClick={this.handleCaptionClick} title={tooltip}>
        <div className={classNames('column-header-caption-text', { 'column-content': autoWidth })} title={tooltip}>
          {children}
        </div>
        {this.renderSortIcon()}
      </div>
    );
  }
}