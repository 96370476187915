/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define( [ 'sandbox', 'ember' ],
  function ( sandbox, Ember ) {
    'use strict';

    function BookModel ( model ) {
      if ( model === undefined || model.id === undefined || model.nwid === undefined ) return undefined;
      return Ember.Object.create({
        id: model.id,
        index: model.index,
        nwid: model.nwid,
        type: model.type || 'book',
        name: model.name,
        'forms:sorting': model['forms:sorting'],
        forms: [],
        '_model': model
      });
    }

    return BookModel;
  });