/**
 * Authorization  Manager performs login and logout of the user.
 *
 * @name Auth Manager
 * @namespace
 * @author sergey
 */
import dom from 'base/dom';
import baseData from 'base/data';
import appPromises from 'core/managers/appPromises';
import pubsub from 'core/managers/pubsub';

import tickFacade from 'core/facades/tick';
import sys from 'core/sys';
import logger from 'logger';
import windowsManager from 'core/managers/windows';
import settingsManager from 'core/managers/settings';
import dialogService from 'core/services/dialogService';
import { translate } from 'core/services/localization';

'use strict';

const moduleManager = require('core/managers/module');
var log, BASIC_LOGIN_PARAMS, URL;

log = logger.getDefaultLogger();
BASIC_LOGIN_PARAMS = {
  actionType: 'actionTypeLogin',
  dynamicGroupAssignment: false,
  login_type: 'login_normal_user',
  clientAppFolder: location.pathname.split('/')[3]
};
URL = '/authenticationService';

function responseHandler(res) {
  log.info('Server response on Authentication Service', res);

  //Due to Flex version compatibility, status of the response arrives in the message body
  if (res.status === '200') {
    appPromises.resolve(appPromises.states.LOGGED_IN, res);
  } else {
    dom.hide('#splash');
    if (res.message.toLowerCase().slice(0, "update-password".length) === "update-password") {

      settingsManager.set('update-password-message', res.message);

      const tokens = res.message.split('&');

      if (tokens[4] === 'password validation error') {
        return;
      }

      settingsManager.set('passwordPolicy', JSON.parse(tokens[2]));
      settingsManager.set('user', tokens[1]);
      settingsManager.set('reset-reason-message', tokens[3]);

      moduleManager.stopInternalModule('Login');
      moduleManager.startInternalModule('PasswordResetView');

    } else {
      appPromises.reject(appPromises.states.LOGGED_IN, res);

      dialogService.openAlertDialog(translate(res.message), translate('Login Failed'))
        .then(() => logout());
    }
  }
}

/**
 * Perform ajax request to the authenticationService
 * with basic parameters and passed parameters to
 * request authorization
 * @param {string}  username    Name of a user filled in the input at login screen
 * @param {string}  password    Specified password
 */
function login(username, password, updatedPassword, oldPassword, afterReset = false) {
  if (typeof username === "undefined" || typeof password === "undefined") {
    throw new Error('Login: username and password must be defined');
  }

  pubsub.publish('phantom', 'loginInitiated');

  const params = {
    ...BASIC_LOGIN_PARAMS,
    user: username,
    password: password,
    requestVersion: settingsManager.get('requestVersion')
  };

  if (typeof updatedPassword !== "undefined") {
    params["updatedPassword"] = updatedPassword;
    params["oldPassword"] = oldPassword || "";
  }

  dom.hide('#auth');
  !afterReset && sys.inform('Info', translate('Performing login. Please, wait.'));
  dom.show('#splash');

  return baseData.ajax({
    url: settingsManager.get('webAppPath') + URL,
    type: 'POST',
    dataType: 'json',
    data: params,
    success: res => {
      responseHandler({ afterLogin: true, ...res })
    }
  });
}

function logout() {
  sys.inform('Info', translate('Performing logout. Please, wait.'));
  tickFacade.stopChannel('logout');
  var params = {
    actionType: 'actionTypeLogout',
    requestVersion: settingsManager.get('requestVersion')
  };

  function clbk(res) {
   
    windowsManager.closeAll();
    pubsub.deleteAllChannels();

    if (typeof res !== "undefined") {
      // the res is a string that includes a url to okta logout (+ the ID token, etc)
      window.location.replace(res);
    } else {
      location.reload();
    }

  }

  return baseData.ajax({
    url: settingsManager.get('webAppPath') + URL,
    type: 'GET',
    dataType: 'text',
    data: params,
    success: res => {clbk(res)},
    error: res => {clbk(res)}
  });
}

function getInitialInformation() {
  var dfd = baseData.deferred();
  baseData.ajax({
    url: settingsManager.get('webAppPath') + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: {
      command: 'getInitialInformation',
      requestVersion: settingsManager.get('requestVersion'),
      clientAppFolder: location.pathname.split('/')[3]
    },
    success: function (raw) {
      log.info('Server response on getInitialInformation ');
      if (raw.statusCode !== 200) {
        dfd.reject();
        return;
      }
      var res = JSON.parse(raw.data);
      responseHandler(res);
      dfd.resolve(res);
    },
    error: function () {
      log.warn('Auth worker: failed to acquire initial user information');
      dfd.reject();
    }
  });
  return dfd.promise();
}

module.exports = {
  _name: 'auth',
  _type: 'manager',
  getInitialInformation,
  login,
  logout
};