import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactComponents from 'widgets/ReactComponents/src/index';
import utils from '../utils';
import SimpleFormComponent from '../SimpleFormComponent';
import actions from '../actions';

var { Dropdown } = ReactComponents;

export default SimpleFormComponent(class extends Component {
  static propTypes = {
    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,
    bind: PropTypes.string,
    bound: PropTypes.string,
    textProp: PropTypes.string,
    valueProp: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    emptyValue: PropTypes.any,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    onChange: PropTypes.func,
    actionType: PropTypes.string
  };

  static defaultProps = {
    bind: '',
    col: 12,
    offset: 0,
    actionType: 'UPDATE'
  };

  state = {
    cursor: utils.getCursorFromStringKey(this.props.bind),
    boundValue: ''
  };

  handleChange = (val) => {
    var { store, bind, onChange, actionType } = this.props;
    if (typeof this.props.onChange === 'function') {
      return this.props.onChange(bind, val);
    }
    var model = store.get(bind);
    var newValue = !utils.isObject(model) ? val : {
      ...model,
      value: val
    };
    if (typeof onChange === 'function')
      onChange(bind, newValue);
    //store.actions.update(bind, newValue);
    else
      store.dispatch(actions.update(bind, newValue, actionType));
  };

  getOptions = (model, options) => {

    var { store, bound, emptyValue } = this.props;
    var boundValue = utils.isString(bound) ? store.get(bound) : null;

    var opts = utils.isObject(options) || Array.isArray(options) ? options :
      model && utils.isObject(model.options) || Array.isArray(model.options) ?
        model.options : [];

    opts = boundValue !== null && Array.isArray(opts[boundValue]) ? opts[boundValue] :
      Array.isArray(opts) ? opts :
        [];

    var ret = Object.assign([], opts);

    if (!utils.isUndefined(emptyValue)) {
      ret.splice(0, 0, emptyValue);
    }

    return ret;

  };

  clearStoreValue = () => {

    var { store, bind, actionType } = this.props;

    store.dispatch(actions.update(bind, '', actionType));
    //store.actions.update(bind, '');

  };

  componentWillReceiveProps(nextProps) {

    var { store, bind, bound } = this.props;

    if (utils.isString(bound)) {
      let boundValue = store.get(bound);
      if (this.state.boundValue !== boundValue) {
        this.setState({
          boundValue: boundValue
        });
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.boundValue !== prevState.boundValue) {
      this.clearStoreValue();
    }

  }

  render() {

    var { store, bind, bound, textProp, valueProp, options, visible, style, className, col, offset, disabled, ...props } = this.props;
    var model = store.get(bind);
    const value = !utils.isObject(model) ? model : model.value;

    return <div className={utils.combineClassNames(['input-dropdown', className])} style={style}>
      <Dropdown options={this.getOptions(model, options)}
        {...props}
        textProp={textProp}
        valueProp={valueProp}
        value={value}
        style={{ width: '100%' }}
        disabled={disabled}
        onChange={this.handleChange}
      />
    </div>;

  }
});
