/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 */

define(['ember', 'text!../templates/title.hbs'], function (Ember, TitleTemplate) {
  'use strict';
  return Ember.View.extend({
    template: Ember.Handlebars.compile(TitleTemplate)
  });
});