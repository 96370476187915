import PropTypes from 'prop-types';
import React from 'react';
import CanvasComponent from './canvasComponent';

const isUndefined = o => typeof o === 'undefined';

export default class CanvasImage extends CanvasComponent {
  static propTypes = {
    image: PropTypes.any,
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    })
  };

  static defaultProps = {
    point: {
      x: 0,
      y: 0
    }
  };

  draw = (ctx) => {
    const { image, point } = this.props;

    //Draw image
    ctx.save();

    ctx.translate((-image.width / 2) , (-image.height / 2)); 
    // ctx.translate((-image.width / 2) + point.x, (-image.height / 2) + point.y);

    ctx.drawImage(image, 0, 0);

    ctx.restore();

  };
}