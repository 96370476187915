/**
 * @name
 * @fileOverview
 * @author sergey
 */

import viewManager from 'core/managers/views';
import tickFacade from 'core/facades/tick';
import appPromises from 'core/managers/appPromises';
import idWorker from 'core/workers/idWorker';
import logger from 'logger';
import {createObjectComparator, composeComparators} from 'core/comparators';

'use strict';


var log = logger.getDefaultLogger(),
  colorServiceLink = {};


var model = {
  colors: []
};

function findColor(propName, value) {
  for (var i = 0, leni = model.colors.length; i < leni; i += 1) {
    for (var j = 0, lenj = model.colors[i].names.length; j < lenj; j += 1) {
      var propValue = model.colors[i].names[j][propName];
      if (!propValue || !value) {
        return {
          wrapper: model.colors[0],
          object: model.colors[0].names[0]
        };
      }
      if (propValue.toLowerCase() === value.toLowerCase()) {
        return {
          wrapper: model.colors[i],
          object: model.colors[i].names[j]
        };
      }
    }
  }
  return false;
}

function getColorsByColorType(colorType) {
  var colors = getAllColors();
  var ret = [];
  colors.forEach(function (color) {
    if (color.colorType === colorType) {
      ret.push(color);
    }
  });
  return ret;
}

function getColorObject(propName, value) {
  var color = findColor(propName, value);
  if (typeof color.wrapper === "undefined") {
    return {
      cyan: 0,
      magenta: 0,
      yellow: 0,
      black: 0,
      colorType: "unknown"
    }
  }
  color.object.cmyk = {
    cyan: color.wrapper.cyan,
    magenta: color.wrapper.magenta,
    yellow: color.wrapper.yellow,
    black: color.wrapper.black
  };
  color.object.rgb = convertCMYKtoRGB(color.object.cmyk);
  color.object.colorType = color.wrapper.colorType;
  return color.object;
}

function convertCMYKtoRGB(cmykColor) {
  var valueRed = 1 - Math.min(1, cmykColor.cyan * (1 - cmykColor.black) + cmykColor.black),
    valueGreen = 1 - Math.min(1, cmykColor.magenta * (1 - cmykColor.black) + cmykColor.black),
    valueBlue = 1 - Math.min(1, cmykColor.yellow * (1 - cmykColor.black) + cmykColor.black);
  return {
    red: Math.round(valueRed * 255),
    green: Math.round(valueGreen * 255),
    blue: Math.round(valueBlue * 255)
  };
}

function getProcessColorsDefaultNames() {
  var ret = [];
  for (var i = 0, leni = model.colors.length; i < leni; i += 1) {
    var colorType = model.colors[i].colorType;
    if (isProcessColor(model.colors[i])) {
      for (var j = 0, lenj = model.colors[i].names.length; j < lenj; j += 1) {
        if (model.colors[i].names[j].default) {
          ret.push(model.colors[i].names[j].name);
        }
      }
    }
  }
  return ret;
}

function getProcessColors() {
  var ret = [];
  var process = getProcessColorsDefaultNames();
  for (var i = 0; i < process.length; i++) {
    ret.push(getColorObject("name", process[i]));
  }
  return ret;
}

function getAllColors() {
  var ret = [];
  for (var i = 0, leni = model.colors.length; i < leni; i += 1) {
    var colorType = model.colors[i].colorType;
    for (var j = 0, lenj = model.colors[i].names.length; j < lenj; j += 1) {
      ret.push(getColorObject("name", model.colors[i].names[j].name));
    }
  }
  return ret.sort(createColorComparator());
}

function createColorComparator() {

  const colorTypeComparator = (c1, c2) => {
    const a = {baseColor: c1.names && c1.names[0].default || c1.default || false, colorType: c1.colorType};
    const b = {baseColor: c2.names && c2.names[0].default || c2.default || false, colorType: c2.colorType};

    let res = 0;
    if (a.baseColor && !b.baseColor) {
      res = -1;
    } else if (!a.baseColor && b.baseColor) {
      res = 1;
    } else if (a.baseColor && b.baseColor || a.colorType !== 'Spot' && b.colorType !== 'Spot') {
      if (a.colorType < b.colorType) {
        res = -1;
      } else if (a.colorType > b.colorType) {
        res = 1;
      }
    } else if (a.colorType !== 'Spot' && b.colorType === 'Spot') {
      res = -1;
    } else if (a.colorType === 'Spot' && b.colorType !== 'Spot') {
      res = 1;
    }

    return res;
  }

  return composeComparators([colorTypeComparator, createObjectComparator(color => color.name ? color.name : color.names && color.names[0].name)]);
}

function getUIColorRGB(color) {
  var rgb;
  switch ((color.colorType || '').toLowerCase()) {
    case "black":
      rgb = "rgb(0, 0, 0)";
      break;
    case "cyan":
      rgb = "rgb(0, 174, 239)";
      break;
    case "magenta":
      rgb = "rgb(236, 0, 140)";
      break;
    case "yellow":
      rgb = "rgb(255, 198, 0)";
      break;
    default:
      if (color.rgb === undefined) {
        color.rgb = convertCMYKtoRGB(color);
      }
      rgb = "rgb(" + [color.rgb.red, color.rgb.green, color.rgb.blue].join(',') + ")";
  }
  return rgb;
}

function isProcessColor(color) {
  return (color.colorType.toLowerCase() !== "spot");
}


// ===================== Tick ===========================

function handleAdd(add) {
  if (add.actionLinks) {
    model.actionLinks = add.actionLinks;
  }

  var sorting = require('core/sorting');
  if (add.hasOwnProperty("childrenNames")) {
    var childrenNames = add['childrenNames'];
    if (Array.isArray(childrenNames) && childrenNames.contains("colors") && add.hasOwnProperty("colors")) {

      // Sort
      var sortingDescription = add['userSystemMails' + ":sorting"];
      if (sortingDescription) {
        var sortingTokens = sortingDescription.split(":");
        if (sortingTokens.length === 2) {
          sorting.sort(add.userSystemMails, sortingTokens[1], sortingTokens[0]);
        }
      }
      sorting.sort(add.colors, "cmyk", "colorType");

      // Add To Model
      add.colors.forEach(function (color) {
        model.colors.pushObject(color);
      });
    }
  }
  else if (add.hasOwnProperty("parentId") && add.hasOwnProperty("childPropertyId") && add["childPropertyId"] === "colors") {
    model.colors.pushObject(add);
  }
}

function handleUpdate(update) {
  var idToUpdate = update.id;
  if (idToUpdate) {
    var source = model.colors;
    var objToUpdate = findById(source, idToUpdate);
    if (typeof objToUpdate !== 'undefined') {
      for (var key in update) {
        if (update.hasOwnProperty(key) && key !== 'action') {
          Ember.set(objToUpdate, key, update[key]);
          //objToUpdate[key] = update[key];
        }
      }
    }
  }
}

function handleRemove(remove) {
  var idsToDelete = remove["ids"];
  var childProperty = remove["childPropertyId"];
  if (idsToDelete && model.hasOwnProperty(childProperty)) {
    var source = model[childProperty];
    for (var i = 0; i < idsToDelete.length; i++) {
      var idToDelete = idsToDelete[i];
      var objToDelete = findById(source, idToDelete);
      if (typeof objToDelete !== 'undefined') {
        source.removeObject(objToDelete);
        //var index = source.indexOf(objToDelete);
        //if (index > -1) {
        //  source.splice(index, 1);
        //}
      }
    }
  }
}

function findById(source, id) {
  var compareProperty = "id";
  return source.find(function (item) {
    return item[compareProperty] === id;
  });
}

var ColorTable = {
  start: function () {
    if (typeof colorServiceLink === 'undefined') {
      log.info("ColorService.startService: ColorService instance not found, couldn't start ColorService");
      return;
    }
    this.nwid = colorServiceLink.nwid;
    this.id = idWorker.generateId();
    var requestParams = {
      rootType: 'controlpanel/colortable'
    };

    tickFacade.addProjector(this, undefined, this.tickUpdate, this.tickCommit, requestParams);
  },

  tickUpdate: function (data) {
    var models = Array.isArray(data.model) ? data.model : [data.model];
    for (var i = 0, leni = models.length; i < leni; i += 1) {
      switch (models[i].action) {
        case 'Add':
          handleAdd.call(this, models[i]);
          break;
        case 'Update':
          handleUpdate.call(this, models[i]);
          break;
        case 'Remove':
          handleRemove.call(this, models[i]);
          break;
      }
    }
  }
};


function registerModule(module) {
  module.model = model;

  module.projectorId = ColorTable.projectorId;
}

// ===================== Event Subscription ===========================

appPromises.getPromise(appPromises.states.CHANNEL_OPENED).then(function () {
  ColorTable.start();
});

appPromises.getPromise(appPromises.states.LOGGED_IN).then(function (res) {
  var viewLink = res.serviceLinks['ColorServiceCR'];
  if (typeof viewLink !== 'undefined') {
    colorServiceLink = viewManager.getViewInfo(viewLink);
  }
});

module.exports = {
  _name: 'colorTable',
  _type: 'service',
  registerModule: registerModule,
  getColorByName: function (name) {
    return getColorObject('name', name);
  },
  getColorByCode: function (code) {
    return getColorObject('code', code);
  },
  getColorsByColorType,
  getProcessColorsDefaultNames,
  getProcessColors,
  getAllColors,
  getUIColorRGB,
  createColorComparator

};