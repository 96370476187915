import React from 'react';
import colorService from 'core/services/colorService';
import icons from 'core/services/iconService';
import localization from 'core/services/localization';
import { arrayToDistinctEntries, arrangeItemsByKeys } from 'utilities/array';
import { cells } from 'widgets/ReactDataGrid';
import { fromServerDate } from 'core/dates';
import { PAGE_STATUS_OPTIONS, FILE_TYPE_OPTIONS } from './constants';
import jsUtils from 'base/jsUtils';
import { applyColumnPreferences, FILTER, NO_VALUE } from 'widgets/ReactDataGrid/utils';
import { COMPARE_TYPE } from 'core/comparators';
import { getMainStatus } from 'utilities/statuses';

const { Text, IconCell, EditableCell, GenericCell, Thumbnail } = cells;

const { translate } = localization;

export const makeUnplanTableColumns = (viewModel, module) => {
  const columnPrefs = module.preferences.table && module.preferences.table.unplanColumns || [];
  let columns = [
    {
      key: 'thumbnail',
      width: 150,
      align: 'center',
      caption: translate('Thumbnail'),
      cell: Thumbnail,
      cellDataGetter: (rowIndex, key) => {
        const page = viewModel.pages[rowIndex];

        if (!page) {
          return;
        };

        return {
          columnData: page.pageContent && page.pageContent.nwid,
          iconUID: page.iconUID,
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData || nextProps.iconUID !== props.iconUID
    },
    {
      key: 'name',
      width: 150,
      align: 'left',
      filter: FILTER.TEXT_TEXT,
      caption: translate('Name'),
      cell: EditableCell,
      onChange: (rowIndex, columnKey, value) => viewModel.pages[rowIndex].name = value,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.pages[rowIndex] && viewModel.pages[rowIndex].name || '',
          enabledRename: module.enabledRename,
          onBlur: (rowIndex, columnKey, value) => module.handleUnplanPageNameBlur(viewModel.pages[rowIndex]),
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'originalName',
      width: 150,
      align: 'left',
      filter: FILTER.TEXT_TEXT,
      filterValueGetter: getOriginalName,
      caption: translate('Original Name'),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: getOriginalName,
      cellDataGetter: (rowIndex, key) => {
        const page = viewModel.pages[rowIndex];
        if (!page) {
          return;
        }
        const columnData = getOriginalName(page);
        const title = page.contentType === 'pdf' ? columnData : getOriginalNameToolTip(page);
        return {
          columnData,
          title
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'type',
      width: 150,
      align: 'left',
      caption: translate('Type'),
      filter: {
        options: FILE_TYPE_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      filterValueGetter: getPageType,
      cell: IconCell,
      sortType: COMPARE_TYPE.NUMBERS,
      sortValueGetter: page => page && page.contentType === 'pdf' ? 0 : 1,
      cellDataGetter: (rowIndex, key) => {
        const contentType = viewModel.pages[rowIndex] && viewModel.pages[rowIndex].contentType || '';
        if (!contentType) {
          return;
        }
        return {
          icon: contentType,
          iconSprite: 'general',
          title: viewModel.pages[rowIndex] && viewModel.pages[rowIndex].contentType || '',
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'separations',
      width: 150,
      align: 'left',
      filter: {
        options: createMultiselectFilterOptions(viewModel.pages, getPageSeparations),
        ...FILTER.MULTISELECT_GENERIC,
      },
      filterValueGetter: getPageSeparations,
      caption: translate('Separations'),
      cell: GenericCell,
      cellDataGetter: (rowIndex, key) => {
        const seps = viewModel.pages[rowIndex] && viewModel.pages[rowIndex].separations || '';
        if (!seps) {
          return;
        }
        return {
          columnData: getSeparationsCellData(seps),
          title: getSeparationsTooltip(seps)
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'fileSize',
      width: 150,
      align: 'left',
      caption: translate('Size'),
      cell: Text,
      sortType: COMPARE_TYPE.NUMBERS,
      sortValueGetter: page => page && getTotalFileSize(getFileSize(page)),
      cellDataGetter: (rowIndex, key) => {
        const fileSize = getFileSize(viewModel.pages[rowIndex]);

        if (fileSize.length === 0) {
          return;
        };

        const formattedFileSize = getFormattedFileSize([getTotalFileSize(fileSize)]);
        const title = getFormattedFileSize(fileSize);

        return {
          columnData: formattedFileSize,
          title
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'version',
      width: 150,
      align: 'left',
      filter: FILTER.TEXT_NUMBER,
      filterValueGetter: getPageVersion,
      caption: translate('Version'),
      cell: Text,
      sortType: COMPARE_TYPE.NUMBERS,
      sortValueGetter: page => page && page.versionIndex,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.pages[rowIndex] && viewModel.pages[rowIndex].versionIndex || '',
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'inputTime',
      width: 150,
      align: 'left',
      filter: FILTER.DATE,
      caption: translate('Input Time'),
      cell: Text,
      sortType: COMPARE_TYPE.DATES,
      cellDataGetter: (rowIndex, key) => {
        const date = viewModel.pages[rowIndex] && viewModel.pages[rowIndex].inputTime;

        if (!date) {
          return;
        }

        return {
          columnData: localization.toLocaleShortDateTime(date),
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'flowStep',
      width: 150,
      align: 'left',
      caption: translate('Flow Step'),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        const page = viewModel.pages[rowIndex];
        const status = getMainStatus(page);
        const flowStep = status?.flowStepType || '';
        if (!flowStep) {
          return;
        }
        let flowStepIconName = status.flowStepIconName;
        if (flowStepIconName === 'void') {
          flowStepIconName = '';
        }
        const title = getPageFlowStepName(status);
        return {
          icon: icons.getTemplateIcon(flowStep, 'tiny', flowStepIconName),
          title
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'status',
      width: 150,
      align: 'left',
      filter: {
        options: PAGE_STATUS_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      filterValueGetter: getPageStatus,
      caption: translate('Status'),
      cell: IconCell,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: getPageStatus,
      cellDataGetter: (rowIndex, key) => {
        let status = getPageStatus(viewModel.pages[rowIndex]);
        if (!status) {
          return;
        }
        if (status === 'notassigned') {
          return;
        }
        const flowStep = status.flowStepType;

        return {
          icon: getStatusCellData(status, flowStep),
          title: translate(status)
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'statusTime',
      width: 150,
      align: 'left',
      filter: FILTER.DATE,
      filterValueGetter: getPageStatusTime,
      caption: translate('Status Time'),
      cell: Text,
      sortType: COMPARE_TYPE.DATES,
      sortValueGetter: page => {
        const time = getPageStatusTime(page);
        if (!time) {
          return;
        };
        return fromServerDate(time);
      },
      cellDataGetter: (rowIndex, key) => {
        const time = getPageStatusTime(viewModel.pages[rowIndex]);
        if (!time) {
          return;
        }
        const date = fromServerDate(time);
        return {
          columnData: localization.toLocaleShortDateTime(date),
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'publication',
      width: 150,
      align: 'left',
      filter: FILTER.TEXT_TEXT,
      filterValueGetter: getPublication,
      caption: translate('Publication'),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: getPublication,
      cellDataGetter: (rowIndex, key) => {
        const publicationName = getPublication(viewModel.pages[rowIndex]);
        return {
          columnData: publicationName,
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    }
  ];

  const keys = columnPrefs.map(col => col.key);
  columns = arrangeItemsByKeys(columns, keys);

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};

const getSeparationsCellData = seps => {
  return <div style={{ display: 'flex' }}>
    {seps.map(sep => {
      const { rgb = {} } = colorService.getColorByName(sep.separation);
      return <img key={sep.nwid} style={{ backgroundColor: `rgb(${rgb.red}, ${rgb.green}, ${rgb.blue})` }} src={icons.getTemplateIcon(sep.type)} />;
    })}
  </div>;
};

const getPublication = page => page && page.publicationName || '';

const getOriginalName = page => page && page.inputFileName || '';

const getOriginalNameToolTip = page => {
  let tooltip = '';
  if (page.separations && page.separations.length > 0) {

    const sepsNamesArr = page.separations.map(sep =>
      sep && sep.pageSeparationContent && sep.pageSeparationContent.version &&
      sep.pageSeparationContent.version.inputFileName || '');

    tooltip = sepsNamesArr.join(', ');
  };

  return tooltip;
};

const getSeparationsTooltip = seps => seps.map(sep => sep.separation).join(', ');

const getFileSize = page => {
  if (!page) {
    return [];
  };

  let fileSizeArr = [];

  if (page.contentType === 'pdf') {
    const { version, multiPageVersion } = page.pageContent || {};
    const fileSize = version ? version.size : multiPageVersion?.size;
    fileSizeArr = [parseInt(fileSize || '0')];
  } else {
    if (page.separations) {
      fileSizeArr = page.separations.map(sep => {
        const fileSize = sep.pageSeparationContent && sep.pageSeparationContent.version && sep.pageSeparationContent.version.size || '0';
        return parseInt(fileSize);
      });
    };
  }

  return fileSizeArr;
};

const getFormattedFileSize = fileSizeArr => fileSizeArr.map(item => jsUtils.formatFileSize(item)).join(', ');

const getTotalFileSize = fileSizeArr => fileSizeArr.reduce((acc, item) => {
  acc += item;
  return acc;
}, 0);



const getStatusCellData = (status, flowStep) => {
  let statusType = status;
  if (statusType === 'waiting') {
    statusType = 'wait';
  } else if (statusType === 'processing') {
    statusType = 'in_progress';
  } else if (statusType === 'success') {
    statusType = 'finished';
  } else if (statusType === 'error') {
    if (flowStep === 'workflow/step/flow/approval') {
      return icons.getModuleIcon('Thumbnail', 'reject-small');
    }
  }
  return icons.getGeneralIcon('status', statusType);
};

const getUnplanSeparationsOptionsData = (page, column) => {
  const columnData = getSeparationsCellData(page.separations) || NO_VALUE;
  const title = getSeparationsTooltip(page.separations);
  return {
    columnData,
    title
  };
};

const getPageVersion = page => page.versionIndex || '';

const getPageType = page => page.contentType || '';

const getPageStatus = page => getMainStatus(page)?.statusType.toLowerCase() || '';

const getPageFlowStepName = status => status.flowStepName || '';

const getPageStatusTime = page => getMainStatus(page)?.time || '';

const getPageSeparations = page => page && page.separations && page.separations.map(sep => sep.separation).join(', ') || NO_VALUE;

const createMultiselectFilterOptions = (pages, filterValueGetter) => {

  const entries = arrayToDistinctEntries(pages, filterValueGetter);
  const filterOptions = entries.map(([value, page]) => ({
    value,
    data: getUnplanSeparationsOptionsData(page)
  }));

  return filterOptions;
};
