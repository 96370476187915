import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from 'components/common/buttons';

export default class IconOption extends React.Component {
  render() {
    const { iconStyle, icon, title, iconSprite } = this.props.data;

    return <div className='crtx-filter-multiselect-item-icon-option' >
      {iconSprite ? <SvgIcon name={icon} sprite={iconSprite} style={iconStyle} /> :
        <img style={{ ...iconStyle }} className='crtx-filter-multiselect-item-icon-option-icon' src={icon} />}
      <span className='crtx-filter-multiselect-item-icon-option-title' >{title}</span>
    </div>;
  }
}

IconOption.propTypes = {
  data: PropTypes.any
};