import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import { moveItem } from 'utilities/array';
import View from './View';
import { restGet } from 'core/managers/rest2';
import { makeAuditsTableColumns } from './columnsCreator';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { fromServerDate } from 'core/dates';
import { savePreferences } from 'core/managers/preferences';

import {
  checkDateCondition,
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

import { translate } from 'core/services/localization';

const DEFAULT_SORT = [
  {
    ascending: false,
    key: 'time'
  }
];
export default AbstractModule.extend({

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = DEFAULT_SORT;
    this.filtersEnabled = this.preferences.table?.filtersEnabled || false;
    this.toolbar = this.createToolbar();
    this.initToolbar();
    this.receiveAuditData();
    this.reactRoot = createRoot(this.domElement);
  },

  tickUpdate: function () { },

  receiveAuditData: function () {
    return restGet(this.nwid, '/audit/all').then(res => {
      this.buildViewModel(res);
    }
    );
  },

  initToolbar: function () {
    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list',
      iconSprite: 'general',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  buildViewModel: function (data) {

    this.viewModel = {
      audits: data.audits.map((audit, index) => {
        return {
          ...audit,
          time: fromServerDate(audit.time),
          index,
          folders: audit.folders?.split(',') || []
        };
      }),
    };

    this.viewModel.sortedAudits = [...this.viewModel.audits];
    this.viewModel.filteredAudits = [...this.viewModel.sortedAudits];

    this.auditsTableColumns = makeAuditsTableColumns(this.viewModel, this);
    this.sortAudits();
    this.filterAudits();
    this.render();
  },

  sortAudits: function () {
    if (this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.auditsTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));
      this.viewModel.sortedAudits = this.viewModel.sortedAudits.sort(comparator);
    }
  },

  filterAudits: function () {
    if (!this.filtersEnabled) {
      this.viewModel.filteredAudits = this.viewModel.sortedAudits;
    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.auditsTableColumns);
      this.viewModel.filteredAudits = this.viewModel.sortedAudits.filter(audit => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (filter.type === FILTER_TYPE.DATE) {
            const time = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(audit) : audit[col.key];
            if (time) {
              match = checkDateCondition(time, filter);
            }
          } else if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(audit) : audit[col.key];
              match = filter.selected.some(s => filterValue.find(folderName => folderName === s));
            }
          } else if (FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              if (col.key === 'breadcrumbs') {
                if (filter.textValue && audit[col.key]) {
                  const strings = filter.textValue.trim().toLowerCase().split(/\s+/);
                  const breadcrumbsLower = audit[col.key].toLowerCase();
                  match = strings.every(s => breadcrumbsLower.indexOf(s) >= 0);
                }
              } else {
                const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(audit) : audit[col.key];
                match = checkTextCondition(text, filter);
              }
            }
          }
          if (!match) {
            break;
          }
        }
        return match;
      });
    }
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.filterAudits();

    this.saveAuditsColumnPreferences();
    this.render();
  },

  handleAuditColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.auditsTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortAudits();
      this.filterAudits();
      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
      this.render();
    };
  },

  saveAuditsColumnPreferences: function () {
    const auditColumns = extractColumnPreferences(this.auditsTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        auditColumns,
      }
    });
  },

  savePreferences: function (preferences) {
    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  handleAuditsTableColumnsFilter: function (columns) {
    this.auditsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveAuditsColumnPreferences();
  },

  handleAuditsTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.auditsTableColumns, oldIndex, newIndex);
    this.saveAuditsColumnPreferences();
    this.render();
  },

  handleAuditsTableColumnResize: function (columns) {
    this.auditsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveAuditsColumnPreferences();
  },

  handleAuditColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.saveAuditsColumnPreferences();
    this.filterAudits();
    this.render();
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(<View module={this}
      viewModel={this.viewModel}
      auditsTableColumns={this.auditsTableColumns}
    />);
  },
});
