
import iconService from 'core/services/iconService';
import { translate } from 'core/services/localization';
import { ICON_SPRITE } from 'components/common/buttons/SvgIcon';

export const EMPTY_VALUE_ICON = {
  icon: 'empty',
  title: translate('N/A'),
  sprite: ICON_SPRITE.GENERAL,
  iconStyle: { height: '18', width: '18', marginLeft: '-1px' }
};

export const STATUS_OPTIONS = [
  {
    value: '',
    data: { icon: EMPTY_VALUE_ICON.icon, title: EMPTY_VALUE_ICON.title, iconSprite: EMPTY_VALUE_ICON.sprite, iconStyle: EMPTY_VALUE_ICON.iconStyle }
  },
  {
    value: 'error',
    data: { icon: iconService.getGeneralIcon('status', 'error'), title: translate('Error') }
  },
  {
    value: 'reject',
    data: { icon: iconService.getGeneralIcon('status', 'reject'), title: translate('Reject') }
  },
  {
    value: 'wait',
    data: { icon: iconService.getGeneralIcon('status', 'wait'), title: translate('Waiting') }
  },
  {
    value: 'hold',
    data: { icon: iconService.getGeneralIcon('status', 'hold'), title: translate('Hold') }
  },

  {
    value: 'in_progress',
    data: { icon: iconService.getGeneralIcon('status', 'in_progress'), title: translate('Processing') }
  },
  {
    value: 'finished',
    data: { icon: iconService.getGeneralIcon('status', 'finished'), title: translate('Success') }
  }

];

export const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'time'
  }
];

