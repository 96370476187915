import React from 'react';
import SeparationHeaderStatusIcon from './separationHeaderStatusIcon';

export default class extends React.Component {
  static defaultProps = {
    separations: [],
    statuses: [],
    pairStatus: undefined,
    definition: undefined,
    approvalIndication: undefined,
    approvalIndicationComplete: '',
    approvalIndicationReject: '',
    approvalCompleteVersion: '0'
  };

  renderElements = (status, pairStatus) => {
    var elements = [];

    elements.push(<SeparationHeaderStatusIcon status={status} key={0} />);
    if (typeof pairStatus !== 'undefined') {
      elements.push(<SeparationHeaderStatusIcon status={pairStatus} key={1} />);
    }

    return elements;
  };

  renderMultipleApprovalStatuses = (
    statuses,
    approvalIndication,
    approvalIndicationComplete,
    approvalIndicationReject,
    approvalCompleteVersion,
    separations
  ) => {
    const filteredStatuses = statuses.filter(s => s.statusType !== 'NotAssigned');
    if (filteredStatuses.length === 0) {
      return undefined;
    }
    return (filteredStatuses.map(status => <SeparationHeaderStatusIcon
      status={status}
      approvalIndication={approvalIndication}
      approvalIndicationComplete={approvalIndicationComplete}
      approvalIndicationReject={approvalIndicationReject}
      separations={separations}
    />
    ));
  };

  render() {
    var {
      separations,
      statuses,
      pairStatus,
      definition,
      approvalIndication,
      approvalIndicationComplete,
      approvalIndicationReject,
      approvalCompleteVersion
    } = this.props;

    if (!definition.showStatus) {
      return <li className='separation-progress-status' />;
    };

    const approvalStatuses = statuses.filter(s => s.flowStepType === 'workflow/step/flow/approval');

    const mainStatus = statuses.find(s => s.name === 'default');

    return (
      <li className='separation-progress-status'>
        {typeof approvalIndication !== 'undefined' && approvalIndication !== '' && approvalStatuses.length > 0
          ? this.renderMultipleApprovalStatuses(
            approvalStatuses,
            approvalIndication,
            approvalIndicationComplete,
            approvalIndicationReject,
            approvalCompleteVersion,
            separations
          )
          : mainStatus && this.renderElements(mainStatus, pairStatus)}
      </li>
    );
  }
}
