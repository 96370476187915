import React from 'react';
import Autocomplete from 'components/common/dropdown/Autocomplete';
import InputCell from './InputCell';

function AutocompleteCell(props) {

  const handleDropdownSelect = (e, option, index) => {
    const { onChange, rowIndex, columnKey } = props;

    if (typeof onChange === 'function') {
      onChange(rowIndex, columnKey, option);
    }
  };

  return (
    <InputCell
      {...props}
      InputComponent={Autocomplete}
      onSelect={handleDropdownSelect}
    />
  );
}

export default AutocompleteCell;
