import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorReactPicker from 'coloreact';
import { classNames } from 'utilities/classNames';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';
import SwitchButton from '../buttons/switchButton/SwitchButton';
import State from '../buttons/switchButton/State';
import Cards from '../cards/Cards';
import Card from '../cards/Card';

const PALETTE = 'palette';
const PICKER = 'picker';
const BOTH = 'both';
const MORE_COLORS = 'More colors';
const LESS_COLORS = 'Less colors';

class ColorPicker extends Component {
  static propTypes = {
    value: PropTypes.string,
    type: PropTypes.oneOf([PALETTE, PICKER, BOTH]),
    colorsOptions: PropTypes.array,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func
  };

  static defaultProps = {
    value: '#ffffff',
    type: BOTH,
    colorsOptions: [
      '#ffcdd2', '#f8bbd0', '#e1bee7', '#c5cae9', '#bbdefb', '#b2ebf2', '#c8e6c9', '#fff9c4', '#ffe0b2', '#f5f5f5',
      '#ef9a9a', '#f48fb1', '#ce93d8', '#9fa8da', '#90caf9', '#80deea', '#a5d6a7', '#fff59d', '#ffcc80', '#eeeeee',
      '#ef5350', '#ec407a', '#ab47bc', '#5c6bc0', '#42a5f5', '#26c6da', '#66bb6a', '#ffee58', '#ffa726', '#bdbdbd',
      '#f44336', '#e91e63', '#9c27b0', '#3f51b5', '#2196f3', '#00bcd4', '#4caf50', '#ffeb3b', '#ff9800', '#9e9e9e',
      '#d32f2f', '#c2185b', '#7b1fa2', '#303f9f', '#1976d2', '#0097a7', '#388e3c', '#fbc02d', '#f57c00', '#616161',
      '#b71c1c', '#880e4f', '#4a148c', '#1a237e', '#0d47a1', '#006064', '#1b5e20', '#f57f17', '#e65100', '#000000'
    ],
    disabled: false,
    onChange: () => {
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      componentToRender: props.colorsOptions.includes(props.value) ? PALETTE : PICKER
    };
  }

  togglePopover = () => {
    if (!this.props.disabled) {
      this.setState(prevState => ({ visible: !prevState.visible }));
    }
  };

  closePopover = () => {
    this.setState({
      visible: false
    });
  };

  handleChange = (color) => {
    const { onChange } = this.props;
    const selectedColor = color.hexString;
    onChange(selectedColor);
  };

  handleButtonClick = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  selectedOption = (option, index) => {
    return (event) => {
      this.setState({ visible: false });
      this.props.onChange(option);
    };
  };

  createColorOption = (option, index) => {
    const selectedValue = this.props.value;
    const selected = option === selectedValue ? 'selected' : '';

    return <div
      key={index}
      data-value={option}
      className={`crtx-color-palette-picker-option ${selected}`}
      style={{ backgroundColor: option }}
      onClick={this.selectedOption(option, index)}
    />;
  };

  renderPalette = () => {
    const colorsOptions = this.props.colorsOptions;
    return <div className='crtx-color-palette-picker-options'>
      {colorsOptions.map(this.createColorOption)}
    </div>;
  };

  renderPicker = () => {
    const { value } = this.props;
    return <ColorReactPicker
      className='crtx-color-react-picker'
      color={value}
      onChange={this.handleChange}
      opacity={false}
    />;
  };

  handleSwitchButtonClick = (event, switchButtonValue) => {
    const newValue = switchButtonValue === MORE_COLORS ? PICKER : PALETTE;
    this.setState({ componentToRender: newValue });
  };

  renderBoth = () => {
    const { componentToRender } = this.state;
    return <div className='crtx-color-picker-both'>
      <Cards className='crtx-color-picker-both-component' state={componentToRender}>
        <Card name={PALETTE}>{this.renderPalette()}</Card>
        <Card name={PICKER}>{this.renderPicker()}</Card>
      </Cards>
      <SwitchButton
        className='crtx-color-picker-both-switch-button'
        state={componentToRender}
        onClick={this.handleSwitchButtonClick}
      >
        <State name={PALETTE}>{MORE_COLORS}</State>
        <State name={PICKER}>{LESS_COLORS}</State>
      </SwitchButton>
    </div>;
  };

  renderColorPicker = () => {
    const { type } = this.props;
    switch (type) {
      case PALETTE:
        return this.renderPalette();
      case PICKER:
        return this.renderPicker();
      case BOTH:
        return this.renderBoth();
      default:
        return <div></div>;
    }
  };

  render() {
    const { value, className, style, disabled, type } = this.props;
    const { visible } = this.state;

    return (
      <Popover
        placement={'bottom-start'}
        open={visible}
        onOpenChange={this.closePopover}
      >
        <PopoverTrigger onClick={this.togglePopover}>
          <div className={classNames('crtx-color-picker', className, { disabled })} style={style}>
            <div className='crtx-color-picker-content' style={{ backgroundColor: value }}></div>
            <div className={classNames('crtx-color-picker-handler', { disabled })}>▼</div>
          </div>
        </PopoverTrigger>

        <PopoverContent>
          <div className={classNames('crtx-color-picker-options', { 'crtx-both-components': type === BOTH })}>
            {this.renderColorPicker()}
          </div>
        </PopoverContent>
      </Popover>
    );
  }
}

export default ColorPicker;