/**
 * @fileOverview
 * @author sergey
 */

import sandbox from 'sandbox';
import AbstractAction from 'AbstractAction';

export default AbstractAction.extend({

  isApplicable: function (objs) {
    if (!Array.isArray(objs) || objs.length === 0) {
      return false;
    }
    if (objs[0].type === 'edition' || objs[0].type === 'zone' || objs[0].type === 'section' || objs[0].type === 'book') {
      return true;
    }
    let isOnHold = false;
    for (const obj of objs) {
      const holdType = obj.holdType;
      if (obj.type === 'page') {
		  if (holdType === "content") {
				return true;  
		  } else {
			  return false;
		  }		  
	  }
      
      if (typeof holdType !== 'undefined' && holdType !== 'none') {
		  return true;
	  }
    }
    return false;
  },

  execute: function (objs) {
    var viewInstanceNwId = this.module.nwid;
    var deviceNwId = this.module.viewSettings.rootId;
    var projectorId = this.module.projectorId;

    for (var index = 0; index < objs.length; index++) {
      var obj = objs[index];
      if (typeof obj !== 'undefined') {
        const type = obj.type;
        const nwid = obj.nwid;
        const holdType = obj.holdType;
        var request = sandbox.request.releaseElement(viewInstanceNwId, this.nwid, nwid, type, projectorId,
          {
            config: this.config,
            type,
            nwid,
            holdType,
            purge: true,
            parameters: this.parameters
          }
        );
      }
    }

    if (this.module.name.toLowerCase() === 'pageview') {
      setTimeout(function () {
        window.close();
      }, 1500);
    }
  }
});

