// @flow
import PropTypes from 'prop-types';
define(['react', 'react-dom', './DropdownOptionItem', 'components/common/popup/Popup'],
  function (React, ReactDOM, DropdownOptionItem, Popup) {
    'use strict'

    const { Component } = React;

    function getSelectedValue(options = [], selectedIndex = -1, valueProp) {
      const selectedOption = options.length > selectedIndex ? options[selectedIndex] : undefined;
      return typeof selectedOption !== 'undefined' ? typeof selectedOption === 'string' ? selectedOption : selectedOption[valueProp] : undefined;
    }

    return class Dropdown extends Component {

      static propTypes = {
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]),
        textProp: PropTypes.string,
        valueProp: PropTypes.string,
        options: PropTypes.array,
        defaultOption: PropTypes.any,
        className: PropTypes.string,
        style: PropTypes.object,
        prefix: PropTypes.any,
        itemComponent: PropTypes.func,
        handlerText: PropTypes.string,
        height: PropTypes.number,
        onValueClick: PropTypes.func,
        disabled: PropTypes.bool,
        onChange: PropTypes.func
      };

      static defaultProps = {
        textProp: 'text',
        valueProp: 'value',
        options: [],
        onChange: function () { },
        handlerText: '▼',
        height: 200,
        prefix: '',
        disabled: false
      };

      constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
          selectedIndex: this.getOptionIndexByValue(this.props.options, this.props.value),
          hoverIndex: null
        };
      }

      getOptionIndexByValue = (options, value) => {
        const { defaultOption } = this.props;
        const optionsToRender = typeof defaultOption === 'undefined' ? options : [].concat(defaultOption, options);

        var index = optionsToRender.findIndex(function (option) {
          return (option[this.props.valueProp] === value) || (option === value);
        }.bind(this));

        return Math.max(index, 0);
      };

      getTextByValue = (options, value) => {
        var index = this.getOptionIndexByValue(options, value);
        return index < 0 ? '' : (options[index][this.props.textProp] || options[index]);
      };

      openStateChanged = (prevIsOpenState, isOpenState) => {
        return prevIsOpenState === isOpenState ? false :
          isOpenState === true ? 'open' :
            'close';
      };

      toggleOpenState = () => {
        var { selectedIndex } = this.state;
        var nextIsOpenState = !this.state.isOpen;
        var hoverIndex = nextIsOpenState === false ? null : selectedIndex;
        this.setState({
          isOpen: nextIsOpenState,
          hoverIndex: hoverIndex
        });
      };

      handleValueClick = (event) => {
        var { disabled, onValueClick } = this.props;
        if (disabled) return;
        if (typeof onValueClick !== 'function') {
          this.handleHandlerClick(event);
          return;
        }
        onValueClick(event);
      };

      handleHandlerClick = (event) => {
        const { disabled } = this.props;
        if (disabled) return;
        this.toggleOpenState();
        event.stopPropagation();
      };

      handleClickedOutside = () => {
        this.setState({
          isOpen: false,
          hoverIndex: null
        });
      };

      handleOptionClick = (optionValue, optionIndex, shouldClosePopup, event) => {
        var { value, disabled, onChange } = this.props;
        if (disabled) return;
        if (shouldClosePopup) {
          this.setState({
            isOpen: false,
            hoverIndex: null,
            // selectedIndex: optionIndex,
          });
        }
        if (value === optionValue) return;
        onChange(optionValue, event);
      };

      handleOptionHover = (optionValue, optionIndex, event) => {
        const { disabled } = this.props;
        if (disabled) return;
        this.setState({
          hoverIndex: optionIndex
        });
      };

      componentWillReceiveProps(nextProps) {
        const selectedIndex = this.state.selectedIndex;
        const valueProp = nextProps.valueProp;
        const prevSelectedValue = getSelectedValue(this.props.options, selectedIndex, valueProp);
        const nextSelectedValue = getSelectedValue(nextProps.options, selectedIndex, valueProp);

        if (
          //if value has changed
          (nextProps.value !== this.props.value) ||
          //if selectedIndex is out of range in nextProps.option
          (nextProps.options.length <= selectedIndex) ||
          // if options has changed and the option in selectedIndex is not the same
          (prevSelectedValue !== nextSelectedValue)
          // (nextProps.options !== this.props.options && (nextProps.options[selectedIndex].value !== this.props.options[selectedIndex].value) || (nextProps.options[selectedIndex] !== this.props.options[selectedIndex]))
        ) {
          this.setState({
            selectedIndex: this.getOptionIndexByValue(nextProps.options, nextProps.value),
            hoverIndex: null,
            isOpen: false
          });
        }
      };

      renderOptionsList = (options, hoverIndex, itemComponent, isOpen) => {
        const { textProp, valueProp, defaultOption } = this.props;
        const ItemComponent = itemComponent ? itemComponent : DropdownOptionItem;
        if (!isOpen) return <div></div>;
        return <ul className="dropdown-list">
          {
            options.map((option, index) => {
              const text = typeof option === 'string' || typeof option === 'number' ?  option : option[textProp];
              const value = typeof option === 'string' || typeof option === 'number' ? option : option[valueProp];
              return <ItemComponent key={index}
                text={text}
                value={value}
                index={index}
                onClick={this.handleOptionClick}
                onMouseHover={this.handleOptionHover}
                selected={hoverIndex === index}
              />
            })
          }
        </ul>;
      };

      render() {
        const { className, style, handlerText, options, defaultOption, prefix, itemComponent, height, textProp, valueProp, disabled } = this.props;
        const { selectedIndex, hoverIndex, isOpen } = this.state;
        const optionsToRender = typeof defaultOption === 'undefined' ? options : [].concat(defaultOption, options);
        const selectedOptionText = optionsToRender.length === 0 ? '' : (optionsToRender[selectedIndex][textProp] || optionsToRender[selectedIndex]);
        const disabledClassName = disabled === true ? 'disabled' : '';
        const dropdownClassName = ['dropdown', disabledClassName, className].join(' ');
        const optionsList = this.renderOptionsList(optionsToRender, hoverIndex, itemComponent, isOpen);
        const dropdownContent = [prefix, selectedOptionText].join(' ');
        const tabIndex = !disabled ? '-1' : undefined;

        return <div ref="dropdown" className={dropdownClassName} style={style} tabIndex={tabIndex} title={dropdownContent} onClick={this.handleValueClick}>
          <div className="dropdown-content">
            {dropdownContent}
          </div>
          <div className="dropdown-handler" onClick={this.handleHandlerClick}>
            <span className="dropdown-handler-inner">
              {handlerText}
            </span>
          </div>
          <Popup visible={isOpen} className="dropdown-popup" opener={this.refs.dropdown} height={height} onClickedOutside={this.handleClickedOutside}>
            {optionsList}
          </Popup>
        </div>;
      };
    }
  });
