import { MSEC_BY_TIME_UNITS, getFolderCurrentDate } from 'core/dates';
import { arrayToObject } from 'utilities/array';
import { formatNewswayDateTime, parseNewswayDateTime } from 'core/dates';

export const FILTER_TYPE = {
  TEXT: 'text',
  MULTISELECT: 'multiselect',
  DATE: 'date',
};

export const NO_VALUE = '_NO_VALUE_';

export const ROW_HEIGHT = 30;
export const ROW_HEIGHT_INCLUDING_THUMBNAIL = 35;

export const HEADER_HEIGHT = 31;
export const HEADER_HEIGHT_WITH_FILTER = 61;

export const FILTER_DATA_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  ICON: 'icon',
  GENERIC: 'generic',
  SHORT_DATE_TIME: 'shortDateTime',
};

export const FILTER = {
  TEXT_TEXT: { type: FILTER_TYPE.TEXT, dataType: FILTER_DATA_TYPE.TEXT },
  TEXT_NUMBER: { type: FILTER_TYPE.TEXT, dataType: FILTER_DATA_TYPE.NUMBER },
  MULTISELECT_TEXT: { type: FILTER_TYPE.MULTISELECT, dataType: FILTER_DATA_TYPE.TEXT },
  MULTISELECT_ICON: { type: FILTER_TYPE.MULTISELECT, dataType: FILTER_DATA_TYPE.ICON },
  MULTISELECT_GENERIC: { type: FILTER_TYPE.MULTISELECT, dataType: FILTER_DATA_TYPE.GENERIC },
  DATE: { type: FILTER_TYPE.DATE },
};

export function reduceColumnsToSortBy(allColumns, currentColumnsToSortBy = [], columnKey, multiSort = false, columnKeyName = 'key') {
  let columnsToSortBy = currentColumnsToSortBy.map(col => ({ ...col }));

  let column = columnsToSortBy.find(col => col[columnKeyName] === columnKey);
  if (column) {
    column.ascending = !column.ascending;
    if (!multiSort) {
      columnsToSortBy = [column];
    }
  } else {
    const col = allColumns.find(col => col[columnKeyName] === columnKey);
    column = { ...col, ascending: true };

    if (multiSort) {
      columnsToSortBy.push(column);
    } else {
      columnsToSortBy = [column];
    }
  }

  return columnsToSortBy;
}

export function reduceColumnsToFilterBy(columns) {
  const columnsToFilterBy = columns.reduce((acc, col) => {
    let filled = false;

    const filter = col.filter;
    if (filter) {
      switch (filter.type) {
        case FILTER_TYPE.TEXT:
          filled = !!filter.textValue;
          break;
        case FILTER_TYPE.MULTISELECT:
          filled = !!filter.selected && filter.selected.length > 0;
          break;
        case FILTER_TYPE.DATE:
          filled = !!filter.option && filter.option !== 'nofilter';
          break;
      }

      filled && acc.push(col);
    }


    return acc;
  }, []);

  return columnsToFilterBy;
}

export function checkDateCondition(date, filter) {
  let result = true;

  const now = getFolderCurrentDate().getTime();
  if (filter.option === 'last') {
    if (filter.value && MSEC_BY_TIME_UNITS[filter.units]) {
      result = date ? date.getTime() >= now - filter.value * MSEC_BY_TIME_UNITS[filter.units] : false;
    }
  } else if (filter.option === 'before') {
    if (filter.value && MSEC_BY_TIME_UNITS[filter.units]) {
      result = date ? date.getTime() <= now - filter.value * MSEC_BY_TIME_UNITS[filter.units] : false;
    }
  } else if (filter.option === 'between') {
    if (filter.dateFrom) {
      result = date ? date.getTime() >= filter.dateFrom.getTime() : false;
    }

    if (result && filter.dateTo) {
      result = date ? date.getTime() <= filter.dateTo.getTime() : false;
    }
  } else if (filter.option === 'range') {
    const msec = MSEC_BY_TIME_UNITS[filter.units];
    if (msec) {
      if (filter.fromValue) {
        result = date ? date.getTime() >= now + filter.fromValue * msec : false;
      }

      if (result && filter.toValue) {
        result = date ? date.getTime() <= now + filter.toValue * msec : false;
      }
    }
  }

  return result;
}

export function checkTextCondition(text, filter) {
  if (!filter.textValue) {
    return true;
  }

  return typeof text !== 'undefined' && String(text).toLowerCase().indexOf(filter.textValue.toLowerCase()) >= 0;
}

export function checkNumberCondition(number, filter) {
  if (!filter.textValue) {
    return true;
  }

  return typeof number !== 'undefined' && !(typeof number === 'string' && number === '') &&
    Number(number) === Number(filter.textValue);
}

export function applyColumnSortPreferences(columns, sortPreferences = []) {
  const columnsByKeys = arrayToObject(columns);

  const columnsToSortBy = sortPreferences.reduce((acc, pref) => {
    const col = columnsByKeys[pref.key];
    if (col) {
      acc.push({
        ...col,
        ...pref
      });
    }

    return acc;

  }, []);

  return columnsToSortBy;
}

export function extractColumnSortPreferences(columnsToSortBy = []) {
  return columnsToSortBy.map(({ key, ascending }) => ({ key, ascending }));
}

export function applyColumnPreferences(columns, columnPreferences = []) {
  const prefsByKeys = arrayToObject(columnPreferences);

  columns.forEach(col => {
    const pref = prefsByKeys[col.key];
    if (pref) {
      col.visible = pref.visible !== false;

      if (col.resizable !== false) {
        col.width = pref.width >= 0 ? pref.width : col.width;
      }

      if (pref.filter) {
        if (pref.filter.selected && pref.filter.selected.length > 0 && typeof pref.filter.selected[0] !== 'string') {
          col.filter = {
            selected: pref.filter.selected.map(item => item.value),
            ...col.filter
          }
        } else {
          col.filter = {
            ...pref.filter,
            ...col.filter
          }
        }
        if (col.filter.option === 'between') {
          col.filter = {
            ...col.filter,
            dateFrom: parseNewswayDateTime(col.filter.dateFrom),
            dateTo: parseNewswayDateTime(col.filter.dateTo)
          }
        }
      }
    }
  });

  return columns;
}

export function extractColumnPreferences(columns) {
  const columnPrefs = columns.map(col => {
    const pref = {
      key: col.key,
      visible: col.visible,
    };

    if (col.resizable !== false && col.width >= 0) {
      pref.width = col.width;
    }

    let filter;
    if (col.filter) {
      if (col.filter.textValue) {
        filter = { textValue: col.filter.textValue };
      } else if (col.filter.selected && col.filter.selected.length > 0) {
        filter = { selected: col.filter.selected };
      } else if (col.filter.option === 'between') {
        filter = {
          ...col.filter,
          dateFrom: formatNewswayDateTime(col.filter.dateFrom),
          dateTo: formatNewswayDateTime(col.filter.dateTo)
        };
      } else if (!!col.filter.option && col.filter.option !== 'nofilter') {
        filter = { ...col.filter };
      }
    }

    if (filter) {
      pref.filter = filter;
    }

    return pref;
  });

  return columnPrefs;
}
