import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import Dropdown from 'components/common/dropdown/Dropdown';
import Checkbox from 'components/common/inputs/Checkbox';
import TextInput from 'components/common/inputs/TextInput';
import NumericInput from 'components/common/inputs/NumericInput';
import { observer } from 'mobx-react';
import FlatButton from 'components/common/buttons/FlatButton';
import iconService from 'core/services/iconService';
import { toJS } from 'mobx';
import { Validator } from 'components/private/forms';
import { getFormSizeOptions } from '../model/model';

export const BOOKS_MODE = {
  saddleStitch: 'saddle stitch',
  perfectBinding: 'perfect binding'
};

const labels = {
  name: translate('Name'),
  skipApproval: translate('Skip Approval'),
  layoutGroup: translate('Layout Group'),
  pmi: translate('PMI'),
  sections: translate('Sections'),
  addBook: translate('Add Book'),
  paperType: translate('Paper Type'),
  commonProperties: translate('Common Properties'),
  propertyA: translate('Property A'),
  propertyB: translate('Property B'),
  propertyC: translate('Property C'),
  propertyD: translate('Property D'),
  removeBook: translate('Remove Book'),
  addSection: translate('Add Section'),
  removeSection: translate('Remove Section'),
  editPMITemplate: translate('Edit PMI Template'),
};

class Book extends Component {

  static propTypes = {
    model: PropTypes.object,
    booksMode: PropTypes.string,
    onPmiClick: PropTypes.func,
    book: PropTypes.object,
    index: PropTypes.number,
  };

  handleRemoveBook = (index) => {
    const { model } = this.props;

    model.books.remove(model.books[index]);
    model.updateZoneInStepModel(model);
  };

  handleAddBookSection = (book) => {
    const { model } = this.props;
    const newSection = {
      code: '',
      displayName: '',
      sectionSourceId: '',
      range: ''
    };

    book.sections.push(newSection);
  };

  handleRemoveBookSection = (book, index) => {
    const { model } = this.props;

    book.sections.remove(book.sections[index]);
  };

  handleEditBookPmi = (book, index) => {
    const { onPmiClick } = this.props;

    onPmiClick(book, index);
  };

  handleNumPagesChange = (value, index) => {
    const { model } = this.props;

    model.books[index].numPages = value;
    model.updateZoneInStepModel(model);

  };

  handleSingleSectionNumPagesChange = (event, book) => {
    const { model } = this.props;

    model.handleSingleSectionNumPagesChange(book);
  };

  handleBlurNumPages = () => {
    const { model } = this.props;

    model.updateZoneInStepModel(model, true);
  };

  handleBookLayoutSelect = (book, index) => (event, value) => {
    const { model } = this.props;

    book.defaultLayout = value;
    book.layoutType = model.configuration.groups.find(group => group.name === value) ? model.configuration.groups.find(group => group.name === value).impositionType : '';
    if (book.layoutType !== 'custom') {
      book.pmiTemplate = undefined;
    }
    model.books[index] = toJS(book);
  };

  renderBookSections = (book) => {
    const { model } = this.props;
    let sectionsOptions = model.sections.map(section => {
      return { "text": section.displayName, "value": section.displayName };
    });

    sectionsOptions.push({ "text": '---', "value": '' });

    return book.sections.map((section, index) => this.renderBookSection(section, index === book.sections.length - 1, book.sections.length > 1, book, index));

  };

  renderBookSectionsLabels = (book) => {
    const { model } = this.props;

    return book.sections.map((section, index) => this.renderBookSectionLabel(section, index));

  };


  renderBookSection = (section, showAddButton, showRemoveButton, book, index) => {
    const { model } = this.props;

    const sectionsOptions = model.sections.map(section => {

      return { "text": section.displayName, "value": section.displayName };
    });

    return <React.Fragment key={book.sourceId + model.displayName + index}>
      <Dropdown options={sectionsOptions}
        value={section.displayName || ''}
        onSelect={(event, value) => { section.displayName = value; section.code = ''; section.sectionSourceId = ''; }} />

      <TextInput className='crtx-form-element-small-width'
        value={section.range}
        onChange={(event, value) => { section.range = value; }} onBlur={(event, value) => { this.handleSingleSectionNumPagesChange(event, book); }} />

      {showAddButton ? <i className="material-icons" title={labels.addSection} onClick={(e) => this.handleAddBookSection(book)}>add</i> : <div></div>}
      {showRemoveButton ? <i className="material-icons" title={labels.removeSection} onClick={(e) => this.handleRemoveBookSection(book, index)}>close</i> : <div></div>}
    </React.Fragment>;
  };

  renderBookSectionLabel = (section, index) => {
    const { model } = this.props;

    return <React.Fragment key={model.displayName + index + 'bookSectionLabel'}>
      <label>{section.displayName}:</label>
      <label>{section.range}</label>

    </React.Fragment>;
  };

  createKey = (book, prop, index) => {
    return book.sourceId + prop.name + index;
  };

  renderCustomCheckbox = (book, prop, index) => {

    return <Checkbox key={this.createKey(book, prop, index)} className='crtx-form-centered-content' checked={book[prop.name] || false} onChange={(event, value) => { book[prop.name] = value; }} />;
  };

  renderCustomSelect = (book, prop, index) => {

    let opts = prop.options.map(opt => {
      return { "text": opt.label, "value": opt.name };
    });

    opts.push({ "text": '---', "value": '' });

    return <Dropdown options={opts} key={this.createKey(book, prop, index)}
      value={book[prop.name] || ''}
      onSelect={(event, value) => { book[prop.name] = value; }} />;
  };

  renderCustomText = (book, prop, index) => {

    return <TextInput key={this.createKey(book, prop, index)} className='crtx-form-element-large'
      value={book[prop.name]} onChange={(event, value) => { book[prop.name] = value; }} />;
  };

  renderCustomNumeric = (book, prop, index) => {

    return <NumericInput key={this.createKey(book, prop, index)}
      className="crtx-form-element-xxsmall"
      value={book[prop.name]}
      onChange={(event, value) => { book[prop.name] = value; }} />;
  };

  filterPmiTemplates = (pmiTemplates, options, pageCount) => {


    return pmiTemplates.filter(
      t => t.numPages === pageCount &&
        t.sheets.every(s => options.some(o => o.rows === s.rows && o.columns === s.columns)));
  };

  renderCustomProperty = (book) => (prop, index) => {
    if (prop.type === 'checkbox') {

      return this.renderCustomCheckbox(book, prop, index);
    } else if (prop.type === 'select') {

      return this.renderCustomSelect(book, prop, index);
    } else if (prop.type === 'text') {

      return this.renderCustomText(book, prop, index);
    } else if (prop.type === 'numeric') {

      return this.renderCustomNumeric(book, prop, index);
    } else return;

  };

  render() {
    const { book, model, index, booksMode } = this.props;
    const isPmiAvailable = model.books.some(book => book.layoutType === 'custom');
    const pagesNumberIsValid = model.books.reduce((sum, book) => { return sum + book.numPages; }, 0) === model.numPages;

    const layouts = model.configuration.optionalImpositions[book.defaultLayout];
    const formSizeOptions = layouts && isPmiAvailable ? getFormSizeOptions(layouts) : [];

    let groupsOptions = model.configuration.groups.map(group => {

      return { "text": group.name, "value": group.name };
    });
    groupsOptions.push({ "text": '---', "value": '' });

    let pmiOptions = this.filterPmiTemplates(model.configuration.pmiTemplates || [], formSizeOptions, book.numPages).map(pmi => {

      return { "text": pmi.name, "value": pmi.name };
    });
    pmiOptions.push({ "text": '---', "value": '' });

    let paperTypeOptions = model.configuration.paperTypeOptions.map(type => {

      return { "text": type, "value": type };
    });
    paperTypeOptions.push({ "text": '---', "value": '' });

    const checkValidPages = () => (num) => {

      return pagesNumberIsValid && num > 0;
    };

    return <React.Fragment key={book.sourceId}>
      <Validator rules={['required']}>
        <TextInput className='crtx-form-element-small'
          value={book.displayName}
          onChange={(event, value) => { book.displayName = value; }} />
      </Validator>
      <Checkbox className='crtx-form-centered-content' checked={book.skip || false} onChange={(event, value) => { book.skip = value; }} />
      <Validator rules={['required']}>
        <Dropdown options={groupsOptions}
          optionsWidth='auto'
          className='crtx-form-element-auto-width'
          value={book.defaultLayout || ''}
          onSelect={this.handleBookLayoutSelect(book, index)} />
      </Validator>

      {book.layoutType === 'custom' ?
        <div className='crtx-form-composed-element'>
          <Dropdown options={pmiOptions}
            optionsWidth='auto'
            className='crtx-form-element-auto-width'
            value={book.pmiTemplate || ''}
            onSelect={(event, value) => { book.pmiTemplate = value; }} />
          <FlatButton className='crtx-form-margin-left-xxsmall'
            tooltip={labels.editPMITemplate} onClick={() => this.handleEditBookPmi(book, index)}>
            <img src={iconService.getActionIcon('edit')} />
          </FlatButton>
        </div> :
        (isPmiAvailable ? <div></div> : undefined)
      }

      {model.configuration.paperTypeOptions && model.configuration.paperTypeOptions.length > 0 && <Dropdown options={paperTypeOptions}
        value={book.paperType || ''}
        onSelect={(event, value) => { book.paperType = value; }} />}

      {booksMode === BOOKS_MODE.saddleStitch &&
        <Validator rules={[checkValidPages()]} >
          <NumericInput
            className="crtx-form-element-xxsmall"
            value={book.numPages}
            onChange={(event, value) => this.handleNumPagesChange(value, index)}
            onBlur={(event) => this.handleBlurNumPages()}
          />
        </Validator>}

      {booksMode === BOOKS_MODE.saddleStitch ?
        (pagesNumberIsValid && book.numPages > 0 ? <div className='crtx-form-two-columns'>{this.renderBookSectionsLabels(book)}</div> : <div></div>) :
        <div className='crtx-form-four-columns'>{this.renderBookSections(book)}</div>}

      {model.configuration.customFields && model.configuration.customFields.length > 0 && model.configuration.customFields.map(this.renderCustomProperty(book))}

      <i className="material-icons" title={labels.removeBook} onClick={e => this.handleRemoveBook(index)}>delete</i>

    </React.Fragment>;
  }
}

export default observer(Book);
