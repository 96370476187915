import React, { Component } from 'react';
import { translate } from 'core/services/localization';
import { Store, Form } from 'components/private/forms';
import AuthManager from 'core/managers/authentication';
import Button from 'components/common/buttons/Button';
import PasswordReset from 'components/private/PasswordReset/PasswordReset';
import settingsManager from 'core/managers/settings';
import dom from 'base/dom';

const labels = {
  resetPassword: translate('Reset Password'),
  updatePassword: translate('Update Password'),
};

export default class extends Component {

  constructor(props) {
    super(props);

    const viewModel = {
      oldPassword: '',
      newPassword: '',
      confirmedPassword: '',
      authFailed: false,
      authFailedReason: ''
    };

    this.store = new Store(viewModel);
    this.FormHoc = Form(PasswordReset);
  }

  submitUpdatedPassword = () => {
    const user = settingsManager.get('user');
    if (this.formRef.validate()) {
      AuthManager.login(user, this.store.state.newPassword, this.store.state.confirmedPassword, this.store.state.oldPassword, true)
        .then(res => {
          this.store.setState('authFailed', false);
          this.store.setState('authFailedReason', '');

          if (res.status !== '200') {
            const tokens = res.message.split("&");
            const authFailedReason = tokens[3];

            if (authFailedReason) {
              dom.show('#auth');
              this.store.setState('authFailed', true);
              this.store.setState('authFailedReason', authFailedReason)
            }
          }
        });
    }
  };

  render() {

    const resetReasonMessage = settingsManager.get('reset-reason-message');
    const FormHoc = this.FormHoc;
    const resetFailureReason = this.store.state.authFailedReason;

    return (
      <div className='crtx-password-reset-container'>
        <div className='crtx-password-reset-form'>
          <div className='crtx-form-section-container'>
            <div className='crtx-form-section'>
              <div className='crtx-password-reset-form-title'>{labels.resetPassword}</div>

              {resetReasonMessage && <div className='crtx-password-reset-reason'>{translate(resetReasonMessage)}</div>}

              <div className='crtx-form-two-columns crtx-form-margin-top-small'>
                <FormHoc ref={formRef => {
                  this.formRef = formRef;
                }}
                         store={this.store} />
              </div>

              <div className='crtx-form-centered-content crtx-form-margin-top-medium'>
                <Button className={'primary'}
                        onClick={(e) => {
                          this.submitUpdatedPassword()
                        }}>
                  {labels.updatePassword}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

