import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThumbnailSections from './ThumbnailSections';
import ThumbnailBooks from './ThumbnailBooks';

const isUndefined = o => typeof o === 'undefined';

export default class BirdeyeMain extends Component {
  static childContextTypes = {
    controller: PropTypes.object,
    module: PropTypes.object,
  };

  static defaultProps = {
    module: undefined,
    model: undefined,
    pageDefinition: undefined,
    formDefinition: undefined,
    thumbnailFormsDefinition: undefined,
    thumbnailPagesDefinition: undefined,
    alignmentPoints: undefined,
    scale: undefined
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      model: this.props.model
    };
  }

  setInitialState = () => {
    return {
      time: Date.now()
    };
  };

  getChildContext() {
    const { ctrl } = this.props.module;
    return {
      controller: ctrl,
      module: this.props.module
    };
  }

  getModel = () => {
    //return this.state.model;
    return this.props.model;
  };

  getModelType = (model) => {
    var type = typeof model === 'undefined' || typeof model.type !== 'string' ?
      undefined :
      model.type.toLowerCase();

    if (typeof type === 'undefined') {
      return undefined;
    }

    return type;
  };

  getModelElements = (model, elementsType, elementsName, type) => {
    var elements = [];

    if (type === 'productionrun') {
      elements = isUndefined(model.zone) ? model[elementsName] || [] : model.zone[elementsName] || [];
    }
    if (type === 'zone' || type === 'edition') {
      elements = model[elementsName] || [];
    }
    if (type === elementsType) {
      elements.push(model);
    }

    return elements;
  };

  seprator = (sections, books, filterZoneViewType) => {
    if (sections !== undefined && books !== undefined && sections.length > 0 && books.length > 0 && filterZoneViewType === undefined) {
      return <div>
        <br /><br />
        <hr />
        <br /><br />
      </div>;
    }
    return null;
  };

  onClick = (e) => {
    this.props.module.ctrl.onClick(undefined, undefined, e);
  };

  handleClose = () => this.props.module.handleClose();

  render() {
    var model = this.getModel(),
      controller = this.props.module.ctrl,
      type = this.getModelType(model),
      scale = this.props.scale,
      //pageDefinition = this.props.pageDefinition,
      //formDefinition = this.props.formDefinition,
      thumbnailPagesDefinition = this.props.thumbnailPagesDefinition,
      thumbnailFormsDefinition = this.props.thumbnailFormsDefinition,
      alignmentPoints = this.props.alignmentPoints,
      viewConfiguration = model.viewConfiguration || {},
      sections = this.getModelElements(model, 'section', 'sections', type)
        .map(function (section) {
          return <ThumbnailSections key={section.id}
            model={section}
            scale={scale}
            viewConfiguration={viewConfiguration}
            thumbnailDefinition={thumbnailPagesDefinition}
            alignmentPoints={alignmentPoints}>
          </ThumbnailSections>;
        }),
      books = this.getModelElements(model, 'book', 'books', type)
        .map(function (book) {
          return <ThumbnailBooks key={book.id}
            model={book}
            scale={scale}
            viewConfiguration={viewConfiguration}
            thumbnailDefinition={thumbnailFormsDefinition}
            alignmentPoints={alignmentPoints}>
          </ThumbnailBooks>;
        });

    return <div className='crtx-thumbnail-layers-view-shrink' onClick={(e) => this.onClick(e)}>
      {sections}
      {this.seprator(sections, books, controller.filterZoneViewType)}
      {books}
    </div>;
  }
}

BirdeyeMain.propTypes = {
  module: PropTypes.object,
  scale: PropTypes.number,
  thumbnailPagesDefinition: PropTypes.object,
  thumbnailFormsDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  model: PropTypes.object
};