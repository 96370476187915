import { utilities as canvasUtilities } from 'components/common/canvas';
import { getViewportPoints } from "../utilities";

const isUndefined = o => typeof o === 'undefined';

const updateSelectedBox = (state, action) => {
  return {
    ...state,
    overlayBoxes: {
      ...state.overlayBoxes,
      [action.overlayBoxesName]: [
        ...state.overlayBoxes[action.overlayBoxesName].slice(0, action.boxIndex),
        {
          ...state.overlayBoxes[action.overlayBoxesName][action.boxIndex],
          isSelected: action.checked
        },
        ...state.overlayBoxes[action.overlayBoxesName].slice(action.boxIndex + 1)
      ]
    }
  };
};

const updateSelectedGuideline = (state, action) => {
  return {
    ...state,
    guidelines: state.guidelines.map(guideline => guideline.name === action.guidelineName ? {
      ...guideline,
      isSelected: action.checked
    } : guideline)
  };
};

const addImageHires = (state, action) => {
  if (isUndefined(state.images[action.imageTiles.key])) state.images[action.imageTiles.key] = [];
  if (isUndefined(state.images[action.imageTiles.key].imageTiles)) state.images[action.imageTiles.key].imageTiles = [];
  const tilePositionRowCol = action.imageTiles.tilePositionRowCol;

  const newLoadingTiles = !isUndefined(state.loadingTiles[action.imageTiles.key]) ?
    {
      ...state.loadingTiles,
      [action.imageTiles.key]: state.loadingTiles[action.imageTiles.key].filter(loadingTile => !(loadingTile.row === tilePositionRowCol.row && loadingTile.column === tilePositionRowCol.column))
    } :
    state.loadingTiles;
  return {
    ...state,
    images: {
      ...state.images,
      [action.imageTiles.key]: {
        ...state.images[action.imageTiles.key],
        imageTiles: [...state.images[action.imageTiles.key].imageTiles, action.imageTiles],
        resolution: action.resolution || state.resolution,
        key: action.imageTiles.key || state.key,
        height: action.height || state.height,
        width: action.width || state.width
      }
    },
    loadingTiles: newLoadingTiles
  };
};


const addImagesHires = (state, action) => {
  if (isUndefined(state.images[action.key])) state.images[action.key] = [];
  if (isUndefined(state.images[action.key].imageTiles)) state.images[action.key].imageTiles = [];

  const newLoadingTiles = !isUndefined(state.loadingTiles[action.key]) ?
    {
      ...state.loadingTiles,
      [action.key]: state.loadingTiles[action.key].filter(loadingTile => {
        const tileToRemove = action.images.find(tile => tile.imageTiles.tilePositionRowCol.row === loadingTile.row && tile.imageTiles.tilePositionRowCol.column === loadingTile.column);
        return isUndefined(tileToRemove);
      })
    } :
    state.loadingTiles;

  return {
    ...state,
    images: {
      ...state.images,
      [action.key]: {
        ...state.images[action.key],
        imageTiles: [...state.images[action.key].imageTiles, ...action.images.map(image => image.imageTiles)],
        resolution: action.resolution || state.resolution,
        key: action.key || state.key,
        height: action.height || state.height,
        width: action.width || state.width
      }
    },
    loadingTiles: newLoadingTiles
  };
};

const addLoadingTile = (state, action) => {
  if (isUndefined(state.loadingTiles[action.key])) state.loadingTiles[action.key] = [];
  const newLoadingTiles = {
    ...state.loadingTiles,
    [action.key]: [...state.loadingTiles[action.key], action.loadingTile]
  };
  return {
    ...state,
    loadingTiles: newLoadingTiles,
    loading: newLoadingTiles[action.key].length > 0
  };
};

const removeLoadingTile = (state, action) => {
  const newLoadingTiles = !isUndefined(state.loadingTiles[action.key]) ?
    {
      ...state.loadingTiles,
      [action.key]: state.loadingTiles[action.key].filter(loadingTile => !(loadingTile.row === action.loadingTile.row && loadingTile.column === action.loadingTile.column))
    } :
    state.loadingTiles;
  return {
    ...state,
    loadingTiles: newLoadingTiles
  };
};

const clearMergeImage = (state, action) => {
  if (isUndefined(state.images.mergedImage)) return { ...state };
  if (isUndefined(state.images.mergedImage.imageTiles)) return { ...state };
  return {
    ...state,
    images: {
      ...state.images,
      mergedImage: {
        ...state.images.mergedImage,
        imageTiles: []
      }
    }
  };
};

const addHiresNavigatorImage = (state, action) => {
  return {
    ...state,
    hiresNavigatorImage: action.hiresNavigatorImageData
  };
};

const getSelectedImage = (state) => {
  const { images = {}, selectedImageKey } = state;

  return images[selectedImageKey]?.image;
};

const getImageWidthHeight = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return;
  }

  const { width, height } = image;

  return { width, height };
};

const getImagePoints = (image, rotation) => {
  const halfImageWidth = image.width / 2;
  const halfImageHeight = image.height / 2;
  const stageUtilities = (new canvasUtilities.StageUtilities()).setRotation(rotation);
  const pointA = stageUtilities.getCanvasByStagePoint(-halfImageWidth, -halfImageHeight);
  const pointB = stageUtilities.getCanvasByStagePoint(halfImageWidth, -halfImageHeight);
  const pointC = stageUtilities.getCanvasByStagePoint(halfImageWidth, halfImageHeight);
  const pointD = stageUtilities.getCanvasByStagePoint(-halfImageWidth, halfImageHeight);

  return { pointA, pointB, pointC, pointD };
};

const updateViewportPoints = (state, imageWidthHeight) => {
  imageWidthHeight = imageWidthHeight ?? getImageWidthHeight(state);
  if (!imageWidthHeight) {
    return state;
  }

  const {
    flipHorizontal,
    flipVertical,
    rotation,
    zoom,
    offsetPoint,
    mainCanvasWidth,
    mainCanvasHeight,
    mainCanvasInstance
  } = state;

  const viewportPoints = getViewportPoints(imageWidthHeight, flipHorizontal, flipVertical, rotation, zoom, offsetPoint, mainCanvasWidth, mainCanvasHeight, mainCanvasInstance);

  return {
    ...state,
    viewportPoints
  };
};

const fitToWidth = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const { mainCanvasWidth, rotation } = state;

  const { pointA, pointB, pointC, pointD } = getImagePoints(image, rotation);

  const zoom = mainCanvasWidth / (Math.max(pointA.x, pointB.x, pointC.x, pointD.x) - Math.min(pointA.x, pointB.x, pointC.x, pointD.x));

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const fitToHeight = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const { mainCanvasHeight, rotation } = state;

  const { pointA, pointB, pointC, pointD } = getImagePoints(image, rotation);

  const zoom = mainCanvasHeight / (Math.max(pointA.y, pointB.y, pointC.y, pointD.y) - Math.min(pointA.y, pointB.y, pointC.y, pointD.y));

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const fitToView = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const { mainCanvasWidth, mainCanvasHeight, rotation } = state;

  const { pointA, pointB, pointC, pointD } = getImagePoints(image, rotation);

  const fitWidthZoom = mainCanvasWidth / (Math.max(pointA.x, pointB.x, pointC.x, pointD.x) - Math.min(pointA.x, pointB.x, pointC.x, pointD.x));
  const fitHeightZoom = mainCanvasHeight / (Math.max(pointA.y, pointB.y, pointC.y, pointD.y) - Math.min(pointA.y, pointB.y, pointC.y, pointD.y));
  const zoom = Math.min(fitWidthZoom, fitHeightZoom);

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const toActualSize = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const zoom = 1;

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const updateZoom = (state) => {
  const { fitMode } = state;

  switch (fitMode) {
    case 'fit_to_width':
      state = fitToWidth(state);
      break;
    case 'fit_to_height':
      state = fitToHeight(state);
      break;
    case 'fit_to_view':
      state = fitToView(state);
      break;
    case 'actual_size':
      state = toActualSize(state);
      break;
  }

  return state;
};

export default (state, action) => {
  const { type, ...payload } = action;

  switch (type) {
    case "STARTUP_STATE":
      return {
        ...state,
        ...payload,
      };
    case "ADD_IMAGE":
      return {
        ...state,
        images: { ...state.images, [action.imageData.key]: action.imageData },
        resolution: action.resolution || state.resolution
      };
    case "LOAD_IMAGE":
      return updateZoom({ ...state, ...payload });
    case "UPDATE_VERSIONS":
      //note: the key is the versionNumber
      return { ...state, versions: { ...state.versions, [action.versionData.key]: action.versionData } };
    case "LOAD_OVERLAY_BOXES":
      return { ...state, overlayBoxes: action.overlayBoxes };
    case "LOAD_GUIDELINES":
      return { ...state, guidelines: action.guidelines };
    case "LOAD_GRID_CELLS":
      return { ...state, gridCells: action.gridCells };
    case "CANVAS_RESIZE":
      return updateZoom({ ...state, ...payload });
    case "FIT_TO_WIDTH":
      return state.fitMode === 'fit_to_width' ?
        { ...state, fitMode: '' } :
        fitToWidth({ ...state, fitMode: 'fit_to_width' });
    case "FIT_TO_HEIGHT":
      return state.fitMode === 'fit_to_height' ?
        { ...state, fitMode: '' } :
        fitToHeight({ ...state, ...payload, fitMode: 'fit_to_height' });
    case "FIT_TO_VIEW":
      return state.fitMode === 'fit_to_view' ?
        { ...state, fitMode: '' } :
        fitToView({ ...state, ...payload, fitMode: 'fit_to_view' });
    case "ACTUAL_SIZE":
      return state.fitMode === 'actual_size' ?
        { ...state, fitMode: '' } :
        toActualSize({ ...state, ...payload, fitMode: 'actual_size' });
    case "ZOOM":
      return { ...state, ...payload, fitMode: '' };
    case "ROTATE":
      return { ...state, rotation: action.rotation };
    case "UPDATE_ROTATION_DEGREE":
      return { ...state, rotationDegree: action.rotationDegree };
    case "FLIP_HORIZONTAL":
      return { ...state, flipHorizontal: action.flipHorizontal };
    case "FLIP_VERTICAL":
      return { ...state, flipVertical: action.flipVertical };
    case "MOVE_TOOL":
      return {
        ...state,
        isDistanceTool: action.isDistanceTool,
        isMoveTool: action.isMoveTool,
        isDensityTool: action.isDensityTool
      };
    case "DISTANCE_TOOL":
      return {
        ...state,
        isDistanceTool: action.isDistanceTool,
        isMoveTool: action.isMoveTool,
        isDensityTool: action.isDensityTool
      };
    case "DENSITY_TOOL":
      return {
        ...state,
        isDistanceTool: action.isDistanceTool,
        isMoveTool: action.isMoveTool,
        isDensityTool: action.isDensityTool
      };
    case "TOGGLE_NAVIGATOR":
      return { ...state, showNavigator: action.showNavigator };
    case "MOUSE_ON_NAVIGATOR":
      return { ...state, isMouseOnNavigator: action.isMouseOnNavigator };
    case "DISTANCE_POINT_CHANGE":
      return { ...state, distanceToolPoints: action.distanceToolPoints };
    case "MOVE_POINT_CHANGE":
      return {
        ...state,
        isMouseOnNavigator: action.isMouseOnNavigator,
        offsetPoint: action.offsetPoint,
        mouseLastPosition: action.mouseLastPosition
      };
    case "DENSITY_POINT_CHANGE":
      return { ...state, mouseLastPosition: action.mouseLastPosition, mouseDensityPosition: action.mouseDensityPosition, densityData: action.densityData };
    case "MEASUREMENT_UNIT_CHANGE":
      return { ...state, measurementUnit: action.measurementUnit };
    case "MEDIA_BOXES_CHANGED":
      return { ...state, showMediaBoxes: action.showMediaBoxes };
    case "GUIDELINES_CHANGED":
      return { ...state, showGuidelines: action.showGuidelines };
    case "PAGE_LABELS_CHANGED":
      return { ...state, showPageLabels: action.showPageLabels };
    case "PDF_BOXES_CHANGED":
      return { ...state, showPDFBoxes: action.showPDFBoxes };
    case "COMPARE_DATA":
      return { ...state, compareData: action.compareData };
    case 'UPDATE_ACTIVE_AND_SELECTED_INDEX':
      return {
        ...state,
        activeVersionNumber: action.activeVersionNumber,
        selectedVersionNumber: action.selectedVersionNumber
      };
    case 'UPDATE_SELECTED_VERSION_INDEX':
      return { ...state, selectedVersionNumber: action.selectedVersionNumber };
    case 'UPDATE_ACTIVE_VERSION_INDEX':
      return { ...state, activeVersionNumber: action.activeVersionNumber };
    case 'UPDATE_LOADING':
      return { ...state, loading: action.loading };
    case 'LOAD_INITIAL_DATA':
      return {
        ...state,
        ...payload,
      };
    case 'UPDATE_HOLD':
      return { ...state, isHold: action.isHold, };
    case 'UPDATE_COMPARE_VERSIONS_NUMBERS_SELECTED':
      return { ...state, compareVersionsNumbersSelected: action.compareVersionsNumbersSelected };
    case 'UPDATE_TOOGLING_BETWEEN_COMPARE_VERSIONS_INDEX':
      return { ...state, togglingBetweenCompareVersionsIndex: action.togglingBetweenCompareVersionsIndex };
    case 'UPDATE_SELECTED_BOX':
      return updateSelectedBox(state, action);
    case 'UPDATE_SELECTED_GUIDELINE':
      return updateSelectedGuideline(state, action);
    case 'COLOR_MANAGEMENT_CHANGED':
      return { ...state, colorManagement: action.colorManagement };
    case 'LOAD_HIRES_DATA':
      return { ...state, hiresData: action.hiresData };
    case 'ADD_IMAGE_HIRES':
      return addImageHires(state, action);
    case 'UPDATE_SHOW_VERSIONS':
      return { ...state, showVersions: action.showVersions };
    case 'SET_SEPARATIONS':
      return { ...state, separations: action.separations };
    case 'ADD_IMAGES_HIRES':
      return addImagesHires(state, action);
    case 'UPDATE_VIEWPORT_POINTS':
      return updateViewportPoints(state, action.imageWidthHeight);
    case 'ADD_LOADING_TILE':
      return addLoadingTile(state, action);
    case 'REMOVE_LOADING_TILE':
      return removeLoadingTile(state, action);
    case 'CLEAR_MERGE_IMAGES':
      return clearMergeImage(state, action);
    case 'ADD_NAVIGATOR_IMAGE'://for hires view
      return addHiresNavigatorImage(state, action);
    case 'LOADING_STATUS':
      return { ...state, loadingStatus: action.loadingStatus };
    case 'SET_MAIN_CANVAS_INSTANCE':
      return { ...state, ...payload };
    case 'UPDATE_NAVIGATOR_FILTER':
      return { ...state, ...payload };
    default:
      return state;
  }
};