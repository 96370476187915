import React, { Component } from 'react';
import Properties from 'src/components/private/info/Properties';
import sandbox from 'sandbox';
import Header from 'src/components/common/headers/Header';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import PropTypes from 'prop-types';
import {formatBreadcrumbs} from 'utilities/breadcrumbs';

const translate = sandbox.localization.translate;
const linkIcon = sandbox.icons.getModuleIcon('MyBirdeye', 'link');

const labels = {
  properties: translate('Properties'),
  additionalInfo: translate('Additional Info'),
  expectedFileName: translate('Expected File Name'),
  local: translate('Local'),
  inputFileName: translate('Input File Name'),
  page: translate('Page'),
  form: translate('Form'),
  nwid: translate('Nwid'),
  path: translate('Path'),
  colors: translate('Colors'),
  info: translate('Info'),
  version: translate('Version'),
  none: translate('none'),
  breadcrumbs: translate('Breadcrumbs'),
  expected: translate('Expected'),
  input: translate('Input'),
  skipSteps: translate('Skip steps'),
  skipLevel: translate('Skip level')
};

const isUndefined = o => typeof o === 'undefined';

module.exports = class Info extends Component {
  constructor(props) {
    super(props);
    this.isEmpty = this.isEmpty.bind(this);
    this.isColors = this.isColors.bind(this);
    this.getPreview = this.getPreview.bind(this);
    this.getColorsPreview = this.getColorsPreview.bind(this);
    this.getSpotsPreview = this.getSpotsPreview.bind(this);
    this.getProperties = this.getProperties.bind(this);
    this.getAdditionalInfo = this.getAdditionalInfo.bind(this);
    this.getInputFileName = this.getInputFileName.bind(this);
    this.getExpectedFileName = this.getExpectedFileName.bind(this);
  }

  static propTypes = {
    object: PropTypes.any,
    separations: PropTypes.any,
    links: PropTypes.any,
    properties: PropTypes.any,
    title: PropTypes.string,
    advanceInfo: PropTypes.bool,
    openerType: PropTypes.string//dialog or rightPanel
  }

  isEmpty(obj) {
    for (var prop in obj) {
      return false;
    }
    return true;
  }

  isColors(color, index) {
    return color.type === 'Black' || color.type === 'Magenta' || color.type === 'Cyan' || color.type === 'Yellow';
  }

  getPreview(seperationPreview, index) {
    const seperationPreviewComp = <SeperationPreview key={index} colorName={seperationPreview.name} shared={seperationPreview.shared} />;
    return seperationPreviewComp;
  }

  getColorsPreview() {
    const { separations } = this.props;
    const colors = separations.filter(this.isColors);
    const colorsPreview = colors.map(this.getPreview);
    return colorsPreview;
  }

  getSpotsPreview() {
    const { separations } = this.props;
    const spots = separations.reject(this.isColors);
    const spotsPreview = spots.map(this.getPreview);
    return spotsPreview.length != 0 ? <span>Spots: {spotsPreview}</span> : undefined;
  }
  getProperties() {
    let { properties } = this.props;
    const isEmptyObj = this.isEmpty(properties);
    if (!isEmptyObj) {
      return <div className='custom-info-row'><span className='custom-info-row-title'>{labels.properties}: </span><span className='custom-info-row-content'><Properties properties={properties} /></span></div>;
    }
    return;
  }
  getAdditionalInfo() {
    const { object } = this.props;
    const isEmptyObj = this.isEmpty(object.custom);
    if (!isEmptyObj) {
      return <div className='custom-info-row'><span className='custom-info-row-title'>{labels.additionalInfo}: </span><span className='custom-info-row-content'><Properties properties={object.custom} /></span></div>;
    }
    return;
  }

  getInputFileName() {
    const { object, openerType } = this.props;
    const elementType = object.elementType;
    const inputFileName = openerType === 'dialog' ? labels.inputFileName : labels.input;
    if (elementType === 'page' || object.inputFileName !== undefined) {
      return <div className='custom-info-row'><span className='custom-info-row-title' title={labels.inputFileName}>{inputFileName}: </span><span className='custom-info-row-content ellipsis' title={object.inputFileName}>{object.inputFileName || ''}</span></div>;
    }
  }
  getExpectedFileName() {
    const { object, openerType } = this.props;
    const elementType = object.elementType;
    const expectedFileName = openerType === 'dialog' ? labels.expectedFileName : labels.expected;
    if (elementType === 'page' || object.expectedName !== undefined) {

      return object.masterExpectedFileName ?
        <div>
          <div className='custom-info-row'><span className='custom-info-row-title' title={labels.expectedFileName}>{expectedFileName}: </span><span className='custom-info-row-content ellipsis' title={object.masterExpectedFileName}>{object.masterExpectedFileName || ''}</span></div>
          <div className='custom-info-row disabled'><span className='custom-info-row-title' title={labels.local}>{labels.local}: </span><span className='custom-info-row-content ellipsis' title={object.expectedName}>{object.expectedName || ''}</span></div>
        </div> :
        <div className='custom-info-row'><span className='custom-info-row-title' title={labels.expectedFileName}>{expectedFileName}: </span><span className='custom-info-row-content ellipsis' title={object.expectedName}>{object.expectedName || ''}</span></div>;
    }
  }

  renderSkipStepsInfo() {
    const { skipStepsInfo } = this.props;
    return !isUndefined(skipStepsInfo) ? <div>
      <div className='custom-info-row'><span className='custom-info-row-title' title={labels.skipSteps}>{labels.skipSteps}: </span><span className='custom-info-row-content ellipsis' title={skipStepsInfo.skipSteps.join(', ')}>{skipStepsInfo.skipSteps.join(', ') || ''}</span></div>
      <div className='custom-info-row'><span className='custom-info-row-title' title={labels.skipLevel}>{labels.skipLevel}: </span><span className='custom-info-row-content ellipsis' title={skipStepsInfo.skipLevel}>{skipStepsInfo.skipLevel || ''}</span></div>
    </div> : undefined;

  }


  render() {
    const { object, properties, title, advanceInfo } = this.props;
    const sharedIcon = object.shared ? <img className='sharedIcon' src={linkIcon} /> : '';
    const elementType = object.elementType;
    const transElementType = elementType === 'page' ? labels.page : labels.form;
    const pageIcon = sandbox.icons.getTemplateIcon('page', 'tiny');
    const formIcon = sandbox.icons.getTemplateIcon('form', 'tiny');
    const elementTypeIcon = elementType === 'page' ? pageIcon : formIcon;

    const nwid = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.nwid}: </span><span className='custom-info-row-content ellipsis' title={object.nwid}>{object.nwid || ''}</span></div> : "";
    const path = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.path}: </span><span className='custom-info-row-content ellipsis-before' title={object.actualPath}>{object.actualPath || ''}</span></div> : "";

    const breadcrumbs = formatBreadcrumbs(object.breadcrumbs);

    return <div className='info-custom-info'>
      <Header>{title}</Header>
      <div className='custom-info-row'><span className='custom-info-row-title'>{transElementType}:</span><span className='custom-info-row-content'>{sharedIcon}<img className='info-page-icon' src={elementTypeIcon} />{object.name || ''}</span></div>
      <div className='custom-info-row'><span className='custom-info-row-title' title={labels.version}>{labels.version}: </span><span className='custom-info-row-content ellipsis' title={object.externalVersion || labels.none}>{object.externalVersion || labels.none}</span></div>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.colors}: </span><span className='custom-info-row-content'><span className='regular-colors-content'>{this.getColorsPreview()}</span><span className="spot-colors-content">{this.getSpotsPreview()}</span></span></div>
      {this.getProperties()}
      {this.getAdditionalInfo()}
      {this.getInputFileName()}
      {this.getExpectedFileName()}

      <div className='custom-info-row'>
        <span className='custom-info-row-title'>{labels.breadcrumbs}:</span>
        <span className='custom-info-row-content'>
          <span className='custom-info-breadcrumbs ellipsis-before' title={breadcrumbs}>
            {breadcrumbs}
          </span>
        </span>
      </div>


      {this.renderSkipStepsInfo()}
      {nwid}
      {path}
    </div>;
  }

}