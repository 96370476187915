import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/common/popup/Popup';

module.exports = class BreadcrumbsList extends Component {
  static propTypes = {
    cursor: PropTypes.array,
    onClick: PropTypes.func
  }

  constructor(props) {
    super(props);
  }

  state = {
    isOpen: false
  };


  buttonHandleClick = (event) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClickedOutside = () => {
    this.setState({ isOpen: false });
  };

  liHandleClick = (item, index) => {
    return function (event) {
      this.setState({ isOpen: false });
      this.props.onClick(event, item, index);
    }.bind(this);
  };

  componentWillReceiveProps(nextProps) {
    var is_same = (this.props.cursor.length == nextProps.cursor.length) && this.props.cursor.every(function (element, index) {
      return element === nextProps.cursor[index];
    });
    if (!is_same) {
      this.setState({ isOpen: false })
    }
  }

  createElement = (item, index) => {
    return <li key={index} title={item.displayName} onClick={this.liHandleClick(item, index)}>{item.displayName}</li>;
  };

  render() {
    const dropdown = this.state.isOpen === false ? 'crtx-closeDropdown' : 'crtx-openDropdown'
    const cursor = this.props.cursor;

    return <div ref="list" className="crtx-breadcrumbs-list">
      <div ref="button" className="crtx-breadcrumbs-list-button" onClick={this.buttonHandleClick}>
        <img alt='arrow-down' />
      </div>
      <Popup opener={this.refs.list} className="crtx-breadcrumbs-list-popup" width={200} visible={this.state.isOpen} onClickedOutside={this.handleClickedOutside}>
        <ul className="crtx-breadcrumbs-list-dropdown">
          {cursor.map(this.createElement)}
        </ul>
      </Popup>
    </div>;
  }
}