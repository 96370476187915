import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { sum } from 'utilities/array';
import BinGroup from './BinGroup';

function Sorter({ module, scale = 1, runNwid, runBreadcrumbs, name, groups = [], notFoundBinNames = [] }) {

  const getStyle = () => {
    const columnCount = groups.some(group => group.side === 'front' && !group.skipped)
    &&
    groups.some(group => group.side === 'back' && !group.skipped) ? 2 : 1;

    return {
      gridTemplateColumns: columnCount === 2 ? 'auto auto' : 'auto',
    };
  };

  const binCount = sum(groups, group => group.bins.length + group.specialBins.length);
  if (binCount <= 0) {
    return null;
  }

  return (
    <div key={name} className='sorter' style={getStyle()}>
      {groups.map((group, index) => {
        return group.skipped ? null :
          <BinGroup
            key={`${group.level}-${group.side}`}
            module={module}
            scale={scale}
            runNwid={runNwid}
            runBreadcrumbs={runBreadcrumbs}
            sorterName={name}
            {...group}
          />
      })}
      {notFoundBinNames.length > 0 && <div className='sorter-error'>
        {translate("The following bins were not found in the sorter '{1}': {2}", name, notFoundBinNames.join(', '))}
      </div>}
    </div>
  );
}

Sorter.propTypes = {
  module: PropTypes.object,
  scale: PropTypes.number,
  runNwid: PropTypes.string,
  runBreadcrumbs: PropTypes.string,
  name: PropTypes.string,
  groups: PropTypes.array,
  notFoundBinNames: PropTypes.array,
};

export default Sorter;