import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';

const TEXT = 'AB';

// Orientation options: https://imglab.io/docs/render/orientation
// Values: https://documentation.globalgraphics.com/support/hqnc/manuals/harlequin-extensions-manual/imposition-features/hqnimpose2-tactics/hqnimpose2-l2rt2b-l2rb2t-r2lt2b-r2lb2t-t2bl2r-b2tl2r-t2br2l-b2tr2l-tactics
const OPTIONS = [
  { value: 'L2RT2B', text: TEXT, tooltip: translate('Horizontal (normal)') },
  { value: 'R2LT2B', text: TEXT, tooltip: translate('Mirror horizontal') },
  { value: 'R2LB2T', text: TEXT, tooltip: translate('Rotate 180°') },
  { value: 'L2RB2T', text: TEXT, tooltip: translate('Mirror vertical') },

  { value: 'B2TR2L', text: TEXT, tooltip: translate('Mirror horizontal and rotate 270°') },
  { value: 'T2BR2L', text: TEXT, tooltip: translate('Rotate 90°') },
  { value: 'T2BL2R', text: TEXT, tooltip: translate('Mirror horizontal and rotate 90°') },
  { value: 'B2TL2R', text: TEXT, tooltip: translate('Rotate 270°') },
];

class Orientation extends Component {

  static propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    value: OPTIONS[0].value,
    className: ''
  };

  state = {
    visible: false
  };

  findOptionText(value) {
    const option = OPTIONS.find((opt) => opt.value === value);

    return !option ? '' : option.text;
  }

  handleOptionClick(value, index) {
    this.setState({ visible: false });
    this.props.onSelect(value);
  }

  togglePopover = () => {
    if (!this.props.disabled) {
      this.setState(prevState => ({ visible: !prevState.visible }));
    }
  };

  closePopover = () => {
    this.setState({
      visible: false
    });
  };

  renderOption = (option, index) => {
    const { value } = this.props;
    const selectedCls = option.value === value ? 'selected' : '';
    const className = `crtx-orientation-option ${selectedCls}`;
    const contentClassName = `option-content ${option.value}`;

    return (
      <li key={index} className={className} onClick={() => this.handleOptionClick(option.value)}>
        <div className={contentClassName} title={option.tooltip}>{option.text}</div>
      </li>
    );
  };

  renderOptions() {
    return (
      <ul className='crtx-orientation-options'>
        {OPTIONS.map(this.renderOption)}
      </ul>
    );
  }

  render() {
    const { value, style, disabled } = this.props;
    const { visible } = this.state;
    const disabledCls = disabled ? 'disabled' : '';
    const className = `crtx-orientation ${this.props.className} ${disabledCls}`;
    const contentClassName = `option-content ${value}`;
    const handlerClassName = `crtx-orientation-handler ${disabledCls}`;

    return (
      <div className={className} style={style}>
        <Popover
          placement={'bottom-start'}
          open={visible}
          onOpenChange={this.closePopover}
        >
          <PopoverTrigger onClick={this.togglePopover}>
            <div className='crtx-orientation-container'>
              <div className='crtx-orientation-content'>
                <div className={contentClassName}>{this.findOptionText(value)}</div>
              </div>
              <div className={handlerClassName}>▼</div>
            </div>
          </PopoverTrigger>

          <PopoverContent>
            {this.renderOptions()}
          </PopoverContent>
        </Popover>
      </div>
    );
  }
}

module.exports = Orientation;