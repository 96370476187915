import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers, filters } from '../../widgets/ReactDataGrid';
import { FILTER_TYPE, ROW_HEIGHT, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';
import { SelectedPagesDialog } from './SelectedPagesDialog';
import { translate } from '../../core/services/localization';

const { GenericHeader, HeaderCaption } = headers;
const { MultiselectFilter, TextInputFilter, DateFilter } = filters;

const TABLE_WIDTH = 1600;

const MIN_COLUMN_WIDTH = 20;

const getRowHeight = unplanTableColumns => {
  const thumbnailColumn = unplanTableColumns.find(col => col.key === 'thumbnail');
  return thumbnailColumn.visible ? ROW_HEIGHT_INCLUDING_THUMBNAIL : ROW_HEIGHT;
};


export default function UnplanTable({ module, viewModel, unplanTableColumns }) {

  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  const [rowHeight, setRowHeight] = useState(getRowHeight(unplanTableColumns));

  const tableRef = useRef(null);

  const { selectedPagesDialog = {} } = module.preferences;

  useEffect(() => {
    if (selectedRowsCount !== module.selected.length) {
      setSelectedRowsCount(module.selected.length);
      if (module.selected.length === 0) {
        tableRef.current.setSelectedRows([]);
      }
    };
  }, [module.selected.length]);

  const renderUnplanColumnFilter = column => {

    const { filter } = column;

    if (!filter) {
      return;
    };

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:
        const selectedValues = Array.isArray(filter.selected) && filter.selected || [];
        return (
          <MultiselectFilter
            selectedValues={selectedValues}
            options={filter.options}
            dataType={filter.dataType}
            onSelect={(event, values, index) => module.handleUnplanColumnFilterChange(column, { selected: values })}
          />
        );
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            textValue={filter.textValue}
            onChange={(e, textValue) => module.handleUnplanColumnFilterChange(column, { textValue })}
          />
        );
      case FILTER_TYPE.DATE:
        return (
          <DateFilter
            filter={filter}
            onApply={filter => module.handleUnplanColumnFilterChange(column, filter)}
          />
        );
    };
  };

  const renderUnplanColumnHeaderFilter = column => {

    if (!module.filtersEnabled) {
      return;
    };

    return (
      <div className='column-header-filter'>
        {renderUnplanColumnFilter(column)}
      </div>
    );
  };

  const renderUnplanColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={multiSort => handleClick(column.key, column.sortValueGetter, multiSort)} sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  const renderUnplanColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={renderUnplanColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={renderUnplanColumnHeaderFilter(column)}
      />
    );
  };

  const handleTableRowSelect = selectedRows => {
    module.handleUnplanTableSelect(selectedRows);
    setSelectedRowsCount(selectedRows.length);
  };

  const handleTableColumnsFilter = (columnKey, visible, columns) => {
    module.handleUnplanTableColumnsFilter(columns);
    const currentRowHeight = getRowHeight(unplanTableColumns);
    setRowHeight(currentRowHeight);
  };

  const handleChangeBounds = ({ left, top, width, height, end }) => {
    if (end) {
      module.handleChangeBounds({ left, top, width, height });
    }
  };

  function renderUnplanTable() {

    const { columnsToSortBy, filtersEnabled } = module;

    const defaultWidth = TABLE_WIDTH / (unplanTableColumns.length || 1);
    const headerHeight = filtersEnabled ? 61 : 31;

    return (
      <Table
        rows={viewModel.pages}
        columnKey='id'
        ref={tableRef}
        virtualScroll={true}
        fixed={true}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        onColumnsFilter={handleTableColumnsFilter}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleUnplanTableColumnOrder(columns, oldIndex, newIndex)}
        onSelect={handleTableRowSelect}
        onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleUnplanTableContextMenu(rowContent, selectedRows, e)}
        onColumnResize={(columns, columnKey) => module.handleUnplanTableColumnResize(columns)}
        onDoubleClickRow={(rowIndex, page, e) => module.handleUnplanPageDoubleClick(page, e)}
        onDeleteKey={(selectedRows, e) => module.handleUnplanTableDeleteKey(selectedRows, e)}
        filtersEnabled={filtersEnabled}
        onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
        showFilterOptionInHeaderMenu={true}
        onDragStart={(rowIndex, rowContent, e) => module.handleDragStart(rowIndex, rowContent, e)}
      >
        {
          unplanTableColumns && unplanTableColumns.map(col => {
            const index = columnsToSortBy.findIndex(column => column.key === col.key)
            const sortDirection =
              col.sortType && index !== -1
                ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
                : undefined;
            const handleClick = col.sortType
              ? module.handleUnplanColumnClick(col.sortType)
              : undefined;
            return <Column
              key={col.key}
              title={col.caption}
              columnKey={col.key}
              width={col.width || defaultWidth}
              align={col.align}
              visible={col.visible}
              header={renderUnplanColumnHeader(col, handleClick, sortDirection, index)}
              cell={col.cell}
              cellDataGetter={col.cellDataGetter}
              shouldCellUpdate={col.shouldCellUpdate}
              onChange={col.onChange ? col.onChange : undefined}
            />
          })
        }
      </Table>
    );
  };

  return (
    <div className='unplan-table-view'>
      {selectedRowsCount > 1 && <SelectedPagesDialog selectedPages={module.selected}
        initialLeft={selectedPagesDialog.left}
        initialTop={selectedPagesDialog.top}
        initialWidth={selectedPagesDialog.width}
        initialHeight={selectedPagesDialog.height}
        title={translate('Selected Pages')}
        onChangeBounds={rect => handleChangeBounds(rect)}
      />}
      {renderUnplanTable()}
    </div>
  );
};

UnplanTable.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  unplanTableColumns: PropTypes.array,
};
