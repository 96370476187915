/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 */

import sandbox from 'sandbox';
import base from 'base';
import Ember from 'ember';
import logger from 'logger';

const log = logger.getDefaultLogger();

function getQueue(model, name) {
  return model.filter(function (q) {
    return (q.queueType === name)
  })[0];
}

const ERROR_COLOR = '#f3c6c6';
const NO_ERROR_COLOR = '#f0f0f0';
const ERROR_TIMEOUT = 5000;

module.exports = Ember.ObjectController.extend({
  existingChanged: false,

  hasResourceWithError: false,

  actions: {
    toggleDevice: function (device) {
      var toggleAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ToggleDeviceActionCR');
      if (typeof toggleAction === 'undefined') {
        log.error('Toggle Device action instance is not found');
        return;
      }
      toggleAction.execute([device]);
    },
    toggleSkipDevice: function (device) {
      var toggleSkipAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ToggleSkipDeviceActionCR');
      if (typeof toggleSkipAction === 'undefined') {
        log.error('Toggle Skip Device action instance is not found');
        return;
      }
      toggleSkipAction.execute([device]);
    },
    toggleResource: function (resource) {
      var toggleAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ToggleResourceActionCR');
      if (typeof toggleAction === 'undefined') {
        log.error('Toggle resource action instance is not found');
        return;
      }
      toggleAction.execute([resource]);
    }
  },
  stepIcon: function () {
    var type = this.get('model.type');
    var iconName = this.get('model.iconName');
    if (typeof iconName !== 'undefined' && iconName !== 'void') {
      type += '/' + iconName;
    }
    return sandbox.icons.getTemplateIcon(type);
  }.property('model.type'),

  flowStepIcon: function () {
    return sandbox.icons.getGeneralIcon("flowStep", this.get('model.isOn') ? "on" : "off");
  }.property('model.isOn'),

  flowStepSkipIcon: function () {
    return sandbox.icons.getGeneralIcon("flowStep", this.get('model.isSkipOn') ? "skipon" : "skipoff");
  }.property('model.isSkipOn'),

  finished: function () {
    return getQueue.apply(this, [this.content.queues, "queFinished"]).size;
  }.property('content.queues.@each.size'),

  wait: function () {
    return getQueue.apply(this, [this.content.queues, "queWait"]).size;
  }.property('content.queues.@each.size', 'existingChanged'),

  hold: function () {
    return getQueue.apply(this, [this.content.queues, "queHold"]).size;
  }.property('content.queues.@each.size'),

  error: function () {
    return getQueue.apply(this, [this.content.queues, "queError"]).size;
  }.property('content.queues.@each.size'),

  isApprovalStep: function () {
    return this.get('model.type') === 'workflow/step/flow/approval';
  }.property(),

  isSkippableStep: function () {
    var modelType = this.get('model.type');
    return modelType === 'workflow/step/flow/approval' || modelType === 'workflow/step/image/preflight' || modelType === 'workflow/step/image/optimization' || modelType === 'workflow/step/output/cache';
  }.property(),

  notOnOfToggableStep: function () {
    var modelType = this.get('model.type');
    return modelType === 'workflow/step/flow/approval' || modelType === 'workflow/step/input/status';
  }.property(),

  getResourceStatus: function (resource) {
    const {isOn, led} = resource;

    if (isOn === true && led === true) return 'on';
    if (isOn === true && led === false) return 'error';
    return 'off';
  },

  observeResourceInError: function () {
    const resources = this.get('content.resources') || [];
    const hasResourceWithError = resources.some(resource => {
      return this.getResourceStatus(resource) === 'error';
    });

    if (this.hasResourceWithError !== hasResourceWithError) {
      this.hasResourceWithError = hasResourceWithError;

      if (this.errorTimeoutId) {
        clearTimeout(this.errorTimeoutId);
        this.errorTimeoutId = 0;
      }

      this.errorTimeoutId = setTimeout(() => {
        this.module.moduleTabState.setError(hasResourceWithError ? 'hasResourceWithError' : '');

        if (hasResourceWithError) {
          $(this.module.domElement).addClass('error-overview');
        } else {
          $(this.module.domElement).removeClass('error-overview');
        }

      }, hasResourceWithError ? ERROR_TIMEOUT : 0);
    }
  }.observes('content.resources.@each.isOn', 'content.resources.@each.led'),

});
