import wizardUtils from './../../../utils/wizardUtils';
import NupPanel from './../../../n-up/NupPanel';
import { openAlertDialog } from 'core/services/dialogService';
import dialogService from 'core/services/dialogService';
import $ from 'jquery';
import sections from '../../../../BirdEyeLayers/src/sections';

/*
  private methods
*/
function findSection(displayName, sections) {
  for (let i in sections) {
    if (sections[i].displayName == displayName) {
      return sections[i].sourceId;
    }
  }
  return null;
}

function expandRanges(rangesString) {
  if (!rangesString) {
    return [];
  }
  const result = [];
  const ranges = rangesString.split(';');
  ranges.forEach((r) => {
    if (r.indexOf('-') > 0) {
      var numbers = r.split('-');
      var from = parseInt(numbers[0], 10);
      var to = parseInt(numbers[1], 10);
      for (var i = from; i <= to && i > 0; i++) {
        result[i] = true;
      }
    } else {
      var num = parseInt(r, 10);
      if (num > 0) {
        result[num] = true;
      }
    }
  });

  return result;
}

function convertPages(book) {
  if (!book || !book.sections) {
    return [];
  }

  const pages = [];
  var number = 1;
  book.sections.forEach((s) => {
    var nums = expandRanges(s.range);
    for (var i = 0; i < nums.length; i++) {
      if (nums[i]) {
        pages.push({
          number,
          name: s.code + i,
          assignedCount: 0
        });
        number++;
      }
    }
  });

  return pages;
}

function filterPmiTemplates(stepModel, book, layouts, options, pageCount) {
  const layoutGroup = book.defaultLayout;

  const pmiTemplates = stepModel.pmiTemplates || [];

  return pmiTemplates.filter(
    t => t.numPages === pageCount &&
      t.sheets.every(s => options.some(o => o.rows === s.rows && o.columns === s.columns)));
}

function getFormSizeOptions(layouts) {
  //*TEST
  // return [
  //   {
  //     rows: 1,
  //     columns: '1'
  //   },
  //   {
  //     rows: 2,
  //     columns: '2'
  //   },
  // ];

  const result = layouts.reduce((options, layout) => {
    var opt = {
      rows: layout.cellGrid.rows,
      columns: layout.cellGrid.columns
    };

    if (!options.find((o) => o.rows === opt.rows && o.columns === opt.columns)) {
      options.push(opt);
    }

    return options;
  }, []);

  return result.sort((a, b) => a.rows !== b.rows ? a.rows - b.rows : a.columns - b.columns);
}

function getLayouts(stepModel, layoutGroup) {
  return stepModel.optionalImpositions[layoutGroup] || [];
}

function resolveImpositions(groups, impositions) {
  var ret = {};
  var impositionNames = [];
  for (var i = 0; i < groups.length; i++) {
    var groupName = groups[i].name;
    var layoutLinks = groups[i].layoutLinks;
    for (var j = 0; j < layoutLinks.length; j++) {
      var imposition = impositions[layoutLinks[j].nwid];
      if (!imposition) {
        continue;
      }
      var impositionName = layoutLinks[j].name;
      var impositionNwid = layoutLinks[j].nwid;
      // TODO need to valid layout
      if (!ret.hasOwnProperty(groupName)) {
        ret[groupName] = [];
      }
      ret[groupName].addObject({ value: impositionNwid, text: impositionName, cellGrid: imposition.cellGrid });
    }
  }
  return ret;
}

function resolveLayoutType(groups, name) {
  for (var i = 0; i < groups.length; i++) {
    if (groups[i].name === name) {
      return groups[i].impositionType;
    }
  }
  return null;
}

function resolveLayoutNwId(groups, name) {
  for (var i = 0; i < groups.length; i++) {
    if (groups[i].name === name) {
      return groups[i].nwid;
    }
  }
  return null;
}

function resolveImpositionNwId(groups, name, impositionName) {
  for (var i = 0; i < groups.length; i++) {
    if (groups[i].name === name) {
      var group = groups[i];
      var layoutLinks = group.layoutLinks;
      for (var j = 0; j < layoutLinks.length; j++) {
        if (layoutLinks[j].name === impositionName) {
          return layoutLinks[j].nwid;
        }
      }
    }
  }
  return null;
}

function getZones(stepModel) {
  var ret = [];
  for (var i = 0; i < stepModel.editions.length; i++) {
    var zones = stepModel.editions[i].zones;
    ret.pushObjects(zones);
  }
  return ret;
}



function buildZonePages(modelZone) {
  var ret = {};
  modelZone.sections.forEach(function (section) {
    if (!ret.hasOwnProperty(section.sourceId)) {
      ret[section.sourceId] = {};
    }
    section.pages && section.pages.forEach(function (page) {
      var n = parseInt(page.number, 10);
      var pages = ret[section.sourceId][n - 1];
      if (pages === null || typeof pages === 'undefined') {
        pages = [];
        ret[section.sourceId][n - 1] = pages;
      }
      pages.push(page.sourceId);
    });
  });
  return ret;
}

function mapNumberPerNumberInSection(sections, mappedModel) {
  var ret = {};
  sections.forEach(function (section) {
    var modelSection = mappedModel[section.sourceId];
    var pages = modelSection.pages;
    var map = {};
    ret[modelSection.sourceId] = map;
    pages.forEach(function (page) {
      map["page" + page.number] = page.numberInSection;
    });
  });
  return ret;
}

function validModel(stepModel, mappedModel, zones, continueNumbers) {
  for (var i = 0; i < zones.length; i++) {
    var books = zones[i].books;
    var sections = zones[i].sections;

    var numberPerNumberInSection = mapNumberPerNumberInSection(sections, mappedModel);

    if (books.length === 0) {
      //alert("Please enter book name in zone " + zones[i].displayName);
      dialogService.openAlertDialog("Please enter book name in zone " + zones[i].displayName);
      return false;
    }
    var assignPages = {};
    for (var j = 0; j < books.length; j++) {
      var displayName = books[j].displayName;
      if (!displayName || displayName.length == 0) {
        dialogService.openAlertDialog("Book name is empty in zone " + zones[i].displayName);
        return false;
      }
      var defaultLayout = books[j].defaultLayout;

      if (defaultLayout === null || typeof defaultLayout === "undefined" || defaultLayout.length === 0) {
      	// alert("Please select layout in zone " + zones[i].displayName);
        dialogService.openAlertDialog("Please select layout in zone " + zones[i].displayName);
        return false;
      }

      var layoutType = resolveLayoutType(stepModel.groups, defaultLayout);

      var existsPmi = books[j].pmiTemplate && books[j].pmiTemplate !== "---";
      if (layoutType === "custom" && (!existsPmi || existsPmi === "---" || existsPmi.length === 0)) {
        dialogService.openAlertDialog("Please select pmi template in zone " + zones[i].displayName);
        return false;
      }
      //console.log ("####################################### " + layoutType + ":" + existsPmi);
      if (displayName === null || typeof displayName === "undefined") {
        dialogService.openAlertDialog("Please enter books name in zone " + zones[i].displayName);
        return false;
      }
      var bookPages = [];
      var ttlNumPages = 0;
      var bookSections = books[j].sections;
      bookSections.forEach(function (bookSection) {
        let sourceId = bookSection.sectionSourceId || findSection(bookSection.displayName, sections);
        var modelSection = mappedModel[sourceId];
        if (!modelSection) {
          dialogService.openAlertDialog("Please select section name " + zones[i].displayName);
          return false;
        }
        var range = bookSection.range;//translateNumbers([modelSection], bookSection.displayName, bookSection.range, continueNumbers, 1);////bookSection.range;

        if (range === null || typeof range === 'undefined' || range.length === 0) {
          var a = books[j].sections;
          if (a.length > 1) {
            a.removeObject(bookSection);
            Ember.set(a[a.length - 1], 'isLastSection', true);
          } else {
            dialogService.openAlertDialog("Information missing for sections");
            return false;
          }
          return;
        }
        var params = range.split(";");
        for (var p = 0; p < params.length; p++) {
          var rangeValue = params[p];
          if (rangeValue.indexOf("-") === -1) {
            var num = numberPerNumberInSection[modelSection.sourceId]["page" + rangeValue];
            if (assignPages.hasOwnProperty(modelSection.sourceId + ":" + num)) {
              dialogService.openAlertDialog("Page was defined twice in zone " + zones[i].displayName);
              return false;
            }
            bookPages.push(0);
            assignPages[modelSection.sourceId + ":" + num] = books[j].sourceId;
          } else {
            var rangeValues = rangeValue.split("-");
            var first = parseInt(rangeValues[0], 10);
            var last = parseInt(rangeValues[1], 10);
            first = numberPerNumberInSection[modelSection.sourceId]["page" + first];
            last = numberPerNumberInSection[modelSection.sourceId]["page" + last];
            for (var n = first; n <= last; n++) {
              if (assignPages.hasOwnProperty(modelSection.sourceId + ":" + n)) {
                dialogService.openAlertDialog("Page was defined twice in zone " + zones[i].displayName);
                return false;
              }
              bookPages.push(0);
              assignPages[modelSection.sourceId + ":" + n] = books[j].sourceId;
            }
          }
        }
        ttlNumPages += bookPages.length;
      });
      if (stepModel.pmiTemplates && stepModel.pmiTemplates.length > 0 && books[j].pmiTemplate
        && books[j].pmiTemplate !== "---") {
        var pmiTemplate = resolvePmiTemplate(stepModel.pmiTemplates, books[j].pmiTemplate);
        var pmiPages = parseInt(pmiTemplate.numPages, 10);
        if (pmiPages != bookPages.length) {
          dialogService.openAlertDialog("Book " + books[i].displayName + " has " +
            bookPages.length + " pages with template for " + pmiPages + " pages.");
          return false;
        }
      }

    }
    var modelZone = mappedModel[zones[i].sourceId];
    //modelZone.sections.forEach(function (section){
    for (var s = 0; s < modelZone.sections.length; s++) {
      var section = modelZone.sections[s];
      for (var p = 0; p < section.pages.length; p++) {
        if (!assignPages.hasOwnProperty(section.sourceId + ":" + section.pages[p].numberInSection)) {
          dialogService.openAlertDialog("Page " + section.displayName + "/" + section.pages[p].number + " in zone " + zones[i].displayName + " is not associated to any book.");
          return false;
        }
      }

    }
    //var firstPage = parseInt(zones[i].firstPage, 10);
    //var lastPage = parseInt(zones[i].lastPage, 10);
    //
    //for (var p=firstPage;p<=lastPage;p++){
    //  if (!assignPages.hasOwnProperty(p + "")){
    //    alert("Page " + p + " in zone " + zones[i].displayName + " missing book.");
    //    return false;
    //  }
    //}

  }
  return true;
}
/* public methods */



function convertFromStepModel(model, stepModel, settings) {

  // const updatedZones = model.editions[0].zones;

  // stepModel.editions[0].zones = updatedZones;


  var zones = getZones(stepModel);
  var mappedModel = wizardUtils.mapSourceIdToObject(model);
  if (!validModel(stepModel, mappedModel, zones, model.continueNumbers)) {
    return false;
  }

  var customFields = settings.customFields || [];
  var fields = [];
  customFields.forEach(function (h) {
    fields.push(h.name);
  });

  for (var i = 0; i < zones.length; i++) {
    var modelZone = mappedModel[zones[i].sourceId];
    var existBooks = {};
    modelZone.books.forEach(function (book) {
      existBooks[book.sourceId] = 0;
    });
    var stepBooks = zones[i].books;
    var stepBooksCodes = {};
    var booksNamesCounter = {};
    for (var j = 0; j < stepBooks.length; j++) {
      var stepBook = stepBooks[j];
      booksNamesCounter[stepBook.displayName] = (booksNamesCounter[stepBook.displayName] || 0) + 1;
      var bookCode = stepBook.code ? stepBook.code : stepBook.displayName + (booksNamesCounter[stepBook.displayName] > 1 ? booksNamesCounter[stepBook.displayName] : "");
      stepBook.code = bookCode;
    }
    modelZone.booksMode = zones[i].booksMode;

    var zonePages = buildZonePages(modelZone);
    for (var j = 0; j < stepBooks.length; j++) {
      var stepBook = stepBooks[j];
      var sourceId = (modelZone.sourceId + "/books/" + stepBook.code).toLowerCase();
      stepBook.sourceId = sourceId;
      var book = mappedModel[sourceId];
      if (book === null || typeof book === 'undefined') {
        book = {};
        modelZone.books.push(book);
      } else {
        existBooks[book.sourceId] = 1;
      }


      var prevPmiTemplate = book.pmiTemplate;
      var prevLayoutType = book.layoutType;
      var prevLayout = book["defaultLayout"];

      delete book.pmiTemplate;

      book.customFields = fields;
      fields.forEach(function (field) {
        if (book[field] && book[field].length > 0)
          book["_" + field + "_oldValu"] = book[field];

      });
      for (var propertyName in stepBook) {
        if (propertyName === "defaultLayout") {
          var pmtMode = model.pmtMode;
          var layoutType = pmtMode ? model.pmtInfo[stepBook[propertyName]] :
            resolveLayoutType(model.groups, stepBook[propertyName]);
          var layoutNwId = pmtMode ? null :
            resolveLayoutNwId(model.groups, stepBook[propertyName]);

          book.layoutType = layoutType;
          book.layoutNwId = layoutNwId;
          if (prevLayoutType !== book.layoutType) {
            book.forms = [];
          } else {
            var layout = stepBook[propertyName];
            if (prevLayout && layout !== prevLayout) {
              book.rebuildForms = true;
              book.forms.forEach(function (form) {
                form.layoutGroup = layout;
                var layoutNwId = resolveImpositionNwId(model.groups, layout, form.layout);
                if (layoutNwId) {
                  form.layoutNwId = layoutNwId;
                } else {
                  delete form.layoutNwId;
                }
              });
            }
          }
        } else if (propertyName === "pmiTemplate") {

          if (prevPmiTemplate !== stepBook[propertyName]) {
            book.forms = [];
          }

        } else if (propertyName === "pmiTemplateSaved" && stepBook[propertyName]) {
          book.forms = [];
        }
        book[propertyName] = stepBook[propertyName];
      }

      var bookSections = stepBook.sections;
      var bookPages = [];
      bookSections.forEach(function (bookSection) {
        var bookSectionSourceId = bookSection.sectionSourceId || findSection(bookSection.displayName, modelZone.sections);
        var modelSection = mappedModel[bookSectionSourceId];
        var range = bookSection.range;//translateNumbers([modelSection], bookSection.displayName, bookSection.range, that.continueNumbers, 1);//
        bookSection.range = range;
        // bookSection.range;
        var params = range.split(";");
        for (var p = 0; p < params.length; p++) {
          var rangeValue = params[p];
          if (rangeValue.indexOf("-") === -1) {
            var n = parseInt(rangeValue, 10);
            bookPages.push(zonePages[bookSectionSourceId][n - 1]);
          } else {
            var rangeValues = rangeValue.split("-");
            var first = parseInt(rangeValues[0], 10);
            var last = parseInt(rangeValues[1], 10);
            for (var n = first; n <= last; n++) {
              bookPages.push(zonePages[bookSectionSourceId][n - 1]);
            }
          }
        }
      });
      book.pages = bookPages;
      if (book.pmiTemplate) {
        var pmiTemplate = resolvePmiTemplate(stepModel.pmiTemplates, book.pmiTemplate);
        book.pmiTemplateDescription = pmiTemplate;
      }

      console.log("bookPages.length=" + bookPages.length);
      console.log("book.pmiTemplate=" + book.pmiTemplate);
    }
    Object.keys(existBooks).forEach(function (key) {
      if (existBooks[key] === 0) {
        var book = wizardUtils.matchBySourceId(key, modelZone.books);
        if (book !== null) {
          modelZone.books.removeObject(book);
        }
      }
    });
  }

  // model.pmiTemplates = model.pmiTemplates;
  return true;

}

function convertToStepModel(model, settings, rootId, nwid) {
  var stepModel = { editions: [] };

  stepModel.groups = model.groupsByPublications[model.publication.displayName] || model.groups;
  stepModel.pmiTemplates = (model.pmiTemplates || []).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  stepModel.paperTypeOptions = model.paperTypeOptions;
  stepModel.nwid = nwid;
  stepModel.rootId = rootId;
  stepModel.folderNwI = model.folderPreFix ? model.folderPreFix + model.folder + "_" : "";

  if (model.clientSettings) {
    stepModel.sourceIdBreadcrumb = model.clientSettings.sourceIdBreadcrumb;
  }

  let editions = model.publication.editions;
  var mappedModel = wizardUtils.mapSourceIdToObject(model);

  var customFields = settings.customFields || [];
  stepModel.customFields = customFields;
  var fields = [];
  customFields.forEach(function (h) {
    fields.push(h.name);
  });

  var k, i, zones;
  for (i = 0; i < editions.length; i++) {
    var stepEdition = { type: 'edition', displayName: editions[i].displayName, sourceId: editions[i].sourceId, zones: [] };
    zones = editions[i].zones;
    for (var j = 0; j < zones.length; j++) {
      var stepZone = { type: 'zone', parent: editions[i].sourceId, displayName: zones[j].displayName, sourceId: zones[j].sourceId, books: [] };
      stepZone.booksMode = zones[j].booksMode ? zones[j].booksMode : (zones[j].wrapped ? "saddle stitch" : "perfect binding");
      var books = zones[j].books;
      var sections = zones[j].sections;

      for (k = 0; k < books.length; k++) {

        var stepBookSections = [];

        for (var p = 0; p < books[k].sections.length; p++) {
          var bookSection = books[k].sections[p];
          var stepBookSection = {
            code: bookSection.code,
            displayName: bookSection.displayName,
            sectionSourceId: bookSection.sectionSourceId,
            range: bookSection.range
          };
          stepBookSections.push(stepBookSection);
        }
        if (stepBookSections.length > 0) {
          stepBookSections[stepBookSections.length - 1].isLastSection = true;
        }

        let defaultLayout = books[k].defaultLayout;
        if (defaultLayout){
          var isLayoutValid = stepModel.groups.some(function (group) {
            return group.name === defaultLayout;
          });
          if (!isLayoutValid){
            delete books[k].defaultLayout;
            defaultLayout = null;
          }
        }        

        let layoutType = books[k].layoutType;        
        if (defaultLayout && !layoutType) {
          layoutType = resolveLayoutType(model.groups, defaultLayout);
        }

        
        var stepBook = {
          displayName: books[k].displayName,
          code: books[k].code,
          range: books[k].range,
          skip: books[k].skip,
          layoutType: layoutType,
          pmiTemplate: books[k].pmiTemplate,
          paperType: books[k].paperType,
          defaultLayout: defaultLayout,
          sourceId: books[k].sourceId,
          sections: stepBookSections,
          numPages: books[k].numPages
        };

        fields.forEach(function (field) {
          stepBook[field] = books[k][field] ? books[k][field] : "";
          delete books[k]["_" + field + "_oldValu"];
        });

        stepZone.books.addObject(stepBook);
      }




      stepZone.sections = [];
      let orderedPages = [];
      let numPages = 0;
      sections.forEach(function (section) {
        var stepSection = { displayName: section.displayName, sourceId: section.sourceId, startPage: section.startPage };
        var stepPages = [];
        section.pages.forEach(function (page) {
          if (!page.split || page.split == "A") {
            stepPages.push({ number: page.number });
            numPages++;

            orderedPages.push({
              numberInZone: page.numberInZone, number: page.number, section: section.displayName,
              code: section.code, sectionSourceId: section.sourceId
            });
          }
        });
        stepSection.pages = stepPages;
        stepZone.sections.push(stepSection);
        orderedPages.sort((a, b) => a.numberInZone - b.numberInZone);
        stepZone.numPages = numPages;
        stepZone.orderedPages = orderedPages;
      });
      stepEdition.zones.addObject(stepZone);
    }
    stepModel.editions.addObject(stepEdition);
  }
  stepModel.optionalImpositions = resolveImpositions(model.groups, model.impositions);
  return stepModel;
}

function mapZoneBooks(mappedBooks, zone) {
  for (var i = 0; i < zone.books.length; i++) {
    mappedBooks[zone.displayName + zone.books[i].displayName] = zone.books[i].sourceId;
  }
  return mappedBooks;
}

function copySections(targetZone, srcSections) {
  var targetSections = [];
  srcSections.forEach(function (srcSection) {
    var targetSection = wizardUtils.matchByName(targetZone.sections, srcSection.displayName);
    if (targetSection === null) {
      return;
    }
    var section = { displayName: targetSection.displayName, range: srcSection.range, sectionSourceId: targetSection.sourceId };
    if (srcSection.isLastSection) {
      section.isLastSection = true;
    }
    targetSections.push(section);
  });
  if (targetSections.length === 0) {
    targetSections.push({});
  }
  return targetSections;
}

function getPerfectBindingModel(zone) {
  zone.books = [];
  zone.sections.forEach(function (section) {
    var sourceId = (zone.sourceId + "/books/" + (section.code || section.displayName)).toLowerCase();
    var book = {
      displayName: section.displayName, sourceId: sourceId,
      parent: zone.sourceId, pages: [], copies: 1
    };


    var numPages = section.pages.length;

    var startPage = parseInt(section.startPage || 1, 10);
    var lastPage = startPage + numPages - 1;
    var rangeUI = startPage + "-" + lastPage;
    book.sections = [{
      code: section.code,
      displayName: section.displayName,
      sectionSourceId: section.sourceId,
      range: rangeUI,
      rangeUI: rangeUI
    }];
    book.code = section.code === null || typeof section.code === 'undefined' ? section.displayName : section.code;
    // section.onBooks = [book.sourceId];
    zone.books.push(book);
  });



}

function getSaddleStitchModel(selectedZone) {
  let n = 0;
  selectedZone.error = false;
  let orderedPages = selectedZone.orderedPages;

  if (selectedZone.isFirstSaddleTransform && selectedZone.modeWasChanged && selectedZone.books.length > 0) {
    selectedZone.books = [selectedZone.books[0]];
    selectedZone.books[0].numPages = orderedPages.length;
  }

  let numBooks = selectedZone.books.length;
  let ttlNumPages = 0;
  let emptyBook = false;

  selectedZone.books.forEach(function (book) {
    let numPages = book.numPages || 0;
    if (numPages == 0) {
      emptyBook = true;
    }
    ttlNumPages += numPages;
    book.sections = [];
  });


  if (emptyBook || ttlNumPages != selectedZone.numPages) {
    selectedZone.error = true;
    return;
  }

  let i = 0;
  let j = orderedPages.length - 1 - i;
  selectedZone.books.forEach(function (book) {
    let bookPages = [];
    let numPages = book.numPages;

    for (var n = 0; n < numPages / 2; n++) {
      bookPages[n] = orderedPages[i];
      bookPages[numPages - 1 - n] = orderedPages[j];

      i++;
      j--;
    }

    let prevSec = null, prevNumber = 0;
    let range = "";
    let bookSections = [];
    let lastPage = bookPages[bookPages.length - 1];
    for (var n = 0; n < bookPages.length; n++) {
      let page = bookPages[n];
      let myNumber = page.number;
      let mySec = page.section;
      if (prevSec && prevSec != mySec) {
        let prevPage = bookPages[n - 1];
        range += prevPage.number;
        bookSections.push({ displayName: prevPage.section, code: prevPage.sectionCode ? prevPage.sectionCode : prevPage.code, sectionSourceId: prevPage.sectionSourceId, range: range });
        range = page.number + "-";
        prevSec = mySec;
        prevNumber = myNumber;
      } else {
        if (!prevSec) {
          range = page.number + "-";
        } else if (myNumber - prevNumber > 1) {
          range += prevNumber;
          bookSections.push({ displayName: page.section, code: page.sectionCode ? page.sectionCode : page.code, sectionSourceId: page.sectionSourceId, range: range });
          range = page.number + "-";
        }
        prevSec = mySec;
        prevNumber = myNumber;
      }
    }

    range += lastPage.number;
    bookSections.push({ displayName: lastPage.section, code: lastPage.sectionCode ? lastPage.sectionCode : lastPage.code, sectionSourceId: lastPage.sectionSourceId, range: range });
    book.sections = bookSections;
    // ttlNumPages += numPages;
  });
}

function applyAll(selectedZone, stepModel) {

  var zones = getZones(stepModel);
  var i, j, key;
  var mappedBooks = {};

  for (j = 0; j < zones.length; j++) {
    if (zones[j].sourceId === selectedZone.sourceId) {
      // continue;
      // zones[j].books = selectedZone.books;
      continue;
    }
    mappedBooks = mapZoneBooks(mappedBooks, zones[j]);
    zones[j].books.clear();
    zones[j].booksMode = selectedZone.booksMode;
  }

  for (i = 0; i < selectedZone.books.length; i++) {
    var sourceBook = selectedZone.books[i];
    for (j = 0; j < zones.length; j++) {
      if (zones[j].sourceId === selectedZone.sourceId) {
        continue;
      }

      key = zones[j].displayName + sourceBook.displayName;
      var targetBook = {
        displayName: sourceBook.displayName,
        code: sourceBook.code,
        range: sourceBook.range,
        skip: sourceBook.skip,
        layoutType: sourceBook.layoutType,
        pmiTemplate: sourceBook.pmiTemplate,
        paperType: sourceBook.paperType,
        defaultLayout: sourceBook.defaultLayout,
        pmiTemplateSaved: sourceBook.pmiTemplateSaved,
        sections: copySections(zones[j], sourceBook.sections),
        hidden: sourceBook.hidden,
        numPages: sourceBook.numPages
      };

      let customFields = stepModel.customFields || [];
      customFields.forEach(cf => {
        targetBook[cf.name] = sourceBook[cf.name];
      }
      );
      targetBook.sourceId = mappedBooks.hasOwnProperty(key) ? mappedBooks[key] : wizardUtils.getSourceId();

      zones[j].books.addObject(targetBook);
    }
  }

}

function hideWizardButtons() {
  $('.actions').css('display', 'none');
}

function unhideWizardButtons() {
  $('.actions').css('display', 'inline-block');
}

function getOtherTabs() {
  return $(this.element).closest("[role='tablist']").children("ul").children("li").not("[aria-selected='true']");
}

function addCodeToSections(zone, book, model) {
  let sectionsMap = {};
  for (let i = 0; i < zone.sections.length; i++) {
    sectionsMap[zone.sections[i].displayName] = zone.sections[i].sourceId;
  }
  // var mappedModel = wizardUtils.mapSourceIdToObject(model);

  // book.sections.forEach(s => {
  //   s.code = sectionsMap[s.displayName].code;
  //   return s;
  // }
  // );
}

function resolvePmiTemplate(templates, pmiTemplate) {
  for (var i = 0; i < templates.length; i++) {
    if (templates[i].name === pmiTemplate) {
      return templates[i];
    }
  }
}

const handleNupPanelOkClick = (stepModel, zone, reactRoot, renderTabView, bookIndex, zoneIndex) => (output) => {

  var zones = getZones(stepModel);
  // zones[zoneIndex] = zone;
  zones[zoneIndex].books[bookIndex].pmiTemplate = output.selectedPmiTemplateName;

  updatePmiTemplates(stepModel, output);

  editBookPmiDone(stepModel, reactRoot, renderTabView);
};

const handleNupPanelCancelClick = (stepModel, reactRoot, renderTabView) => (output) => {

  updatePmiTemplates(stepModel, output, true);

  editBookPmiDone(stepModel, reactRoot, renderTabView);
};

function editBookPmiDone(stepModel, reactRoot, renderTabView) {

  if (!reactRoot) {
    return;
  }
  renderTabView(stepModel);
  unhideWizardButtons();
}

// function updateStepModel(zone, zoneIndex, stepModel) {
//   var zones = getZones(stepModel);
//   zones[zoneIndex] = zone;
// }


function editBookPmi(book, bookIndex, stepModel, reactRoot, zone, zoneIndex, callback) {

  if (!book.defaultLayout) {
    dialogService.openAlertDialog(translate('Choose Layout Group to continue'), translate('Edit PMI Template'));
    return false;
  }

  hideWizardButtons();
  addCodeToSections(zone, book, stepModel);

  let nupModel = convertToNupModel(stepModel, book);

  const props = {
    ...nupModel,
    book,
    onOkClick: handleNupPanelOkClick(stepModel, zone, reactRoot, callback, bookIndex, zoneIndex),
    onCancelClick: handleNupPanelCancelClick(stepModel, reactRoot, callback)
  };

  renderNupPanel(props, reactRoot);
}

function renderNupPanel(props, reactRoot) {
  NupPanel.renderComponent(props, reactRoot);
}



function getFilteredPmi(stepModel, book) {
  const layouts = getLayouts(stepModel, book.defaultLayout);
  const formSizeOptions = getFormSizeOptions(layouts);
  const pages = convertPages(book);
  const options = getFormSizeOptions(layouts);
  return filterPmiTemplates(stepModel, book, layouts, options, pages.length);

}

function convertToNupModel(stepModel, book) {

  const nwid = stepModel.nwid;
  const rootId = stepModel.rootId;

  const layouts = getLayouts(stepModel, book.defaultLayout);
  const formSizeOptions = getFormSizeOptions(layouts);
  const pages = convertPages(book);
  const options = getFormSizeOptions(layouts);
  const pmiTemplates = filterPmiTemplates(stepModel, book, layouts, options, pages.length);
  const selectedPmiTemplateName = book.pmiTemplate;

  const layoutGroup = book.defaultLayout;

  const selectedPageNumber = pages.length > 0 ? pages[0].number : 0;

  const forms = [];

  return {
    nwid,
    rootId,
    forms,
    formSizeOptions,
    pages,
    selectedPageNumber,
    pmiTemplates,
    selectedPmiTemplateName
  };
}

function updatePmiTemplates(stepModel, output, cancelled = false) {
  // TODO: Implement cancelled case

  const pmiTemplates = stepModel.pmiTemplates;
  output.pmiTemplates.forEach(pmi => {
    const idx = pmiTemplates.findIndex(t => t.name.toLowerCase() === pmi.name.toLowerCase());
    if (idx < 0) {
      pmiTemplates.push(pmi);
    } else {
      pmiTemplates[idx] = pmi;
    }

  });

  const removedItems = [];
  // for (var i=pmiTemplates.length-1;i>=0;i--){
  //   const idx = output.pmiTemplates.findIndex(t => t.name.toLowerCase() === pmiTemplates[i].name.toLowerCase());
  //   if (idx < 0){
  //     removedItems.push(i);
  //   }
  // }  
  // removedItems.forEach(index => {
  //   pmiTemplates.splice(index, 1);
  // });
  stepModel.pmiTemplates = pmiTemplates;

}

module.exports = {

  convertToStepModel,
  convertFromStepModel,
  applyAll,
  editBookPmi,
  updatePmiTemplates,
  convertToNupModel,
  getSaddleStitchModel,
  getPerfectBindingModel,
  getFormSizeOptions
};