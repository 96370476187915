import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'core/services/localization';
import iconService from 'core/services/iconService';
import { parseBreadcrumbs } from 'utilities/breadcrumbs';
import AggregateObject from './AggregateObject';
import DeadlineBar from 'components/private/deadline/DeadlineBar';

const labels = {
  changeDeadLine: translate('Change deadline'),
  pages: translate('Pages'),
  formes: translate('Formes'),
  plates: translate('Plates'),
  sorting: translate('Sorting:'),
  allocation: translate('Allocation:')
};

function ProductionRun({
  productionRun,
  showExpectedPlatesOnly,
  style,
  onChangeDeadline,
  viewInfo,
  onContextMenu
}) {

  const runAggregate = productionRun.aggregate || {};
  const breadcrumbs = parseBreadcrumbs(productionRun.breadcrumbs);
  breadcrumbs.unshift({ name: productionRun.press });

  const totalPlates = showExpectedPlatesOnly ? runAggregate.totalActualPlates : runAggregate.totalPlates;
  const inProgressPlates = showExpectedPlatesOnly ? runAggregate.totalActualPlatesInProgress : runAggregate.totalPlatesInProgress;
  const finishedPlates = showExpectedPlatesOnly ? runAggregate.totalActualPlatesFinished : runAggregate.totalFinishedPlates;
  const errorsPlates = showExpectedPlatesOnly ? runAggregate.totalActualErrorPlates : runAggregate.totalErrorPlates;

  return (
    <div className='production-run' style={style} onContextMenu={(e) => onContextMenu(e, productionRun)}>
      <div className='pr-container'>

        <div className='crtx-production-run-name' title={productionRun.shortLabel}>{productionRun.shortLabel}</div>

        <div className='aggregates'>

          {/* Do not render Plates aggregate if this is a dummy run. */}
          {!productionRun.dummy &&
            <AggregateObject
              icon={iconService.getTemplateIcon('plate', 'large')}
              total={Number(totalPlates) || 0}
              title={labels.plates}
              inProgress={Number(inProgressPlates) || 0}
              finished={Number(finishedPlates) || 0}
              waitingForApproval={0}
              held={0}
              errors={Number(errorsPlates) || 0}
              viewInfo={viewInfo}
            />
          }
        </div>
        <div className='crtx-info-tools-container'>
          <DeadlineBar
            deadlineDate={productionRun.deadlineDate}
            deadlinePassed={productionRun.deadlinePassed}
            holdType={productionRun.holdType}
            holdUntilDate={productionRun.holdUntilDate}
            releaseOffset={parseInt(productionRun.releaseOffset)}
            onChangeDeadline={onChangeDeadline}
          />
        </div>
        {productionRun.sortingTemplateName &&
          <div className='crtx-sorting-template'>
            <span className='caption'>{labels.sorting} </span>
            <span className='unit-label'>{productionRun.sortingTemplateName}</span>
          </div>
        }
         {productionRun.sorterAllocation &&
          <div className='crtx-sorting-template'>
            <span className='caption'>{labels.allocation} </span>
            <span className='unit-label'>{productionRun.sorterAllocation}</span>
          </div>
        }
      </div>
    </div>
  );
}

ProductionRun.propTypes = {
  productionRun: PropTypes.any,
  showExpectedPlatesOnly: PropTypes.bool,
  style: PropTypes.object,
  onChangeDeadline: PropTypes.func,
  viewInfo: PropTypes.any
};

export default ProductionRun;