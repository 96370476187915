import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import IconButton from 'components/common/buttons/IconButton';
import ContextMenu from 'components/common/menu/ContextMenu';
import { STATUS, getBinWidth, getBinHeight, getPlateClassName } from './utils';

const labels = {
  manual: translate('Manual'),
  revision: translate('Revision'),
  holdAction: translate('Hold'),
  releaseAction: translate('Release'),
  openPlatesDetails: translate('Plates details'),
  binNameTooltip: (name, position) => translate('Bin name: {1}, position: {2}', name, position === '' ? '-' : position),
};

function Bin(
  {
    module,
    scale = 1,
    runNwid,
    runBreadcrumbs,
    id,
    name,
    position,
    size,
    doubleSize = false,
    hold = false,
    expected,
    inBinCount,
    inProgressCount,
    missingCount,
    errorCount,
    isManualBin = false,
    isRevisionBin = false,
    planned = true,
    errorMessage,
    onToggleHold,
    onOpenPlatesDetails,
  }) {

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuLeft, setMenuLeft] = useState();
  const [menuTop, setMenuTop] = useState();

  const getBinStyle = () => {
    const style = {
      width: getBinWidth(1, doubleSize),
      height: getBinHeight(1),
    };

    if (scale !== 1) {
      style.transform = `scale(${scale})`;
      style.transformOrigin = 'top left';
    }

    return style;
  };

  const getPlatesStyle = (count = 0) => {
    const style = { flex: count };
    if (count <= 0) {
      style.display = 'none';
    }

    return style;
  };

  const getErrorPlatesStyle = (count) => {
    return getPlatesStyle(count);
  };

  const getMissingPlatesStyle = (count) => {
    return isManualBin || isRevisionBin ? getPlatesStyle(5) : getPlatesStyle(count);
  };

  const getInProgressPlatesStyle = (count) => {
    return getPlatesStyle(count);
  };

  const getInBinPlatesStyle = (count) => {
    return getPlatesStyle(count);
  };

  const getBinCaption = () => {
    const arr = [];
    isManualBin && arr.push('M');
    isRevisionBin && arr.push('R');

    return arr.length > 0 ? arr.join('') : expected;
  };

  const getBinCaptionTooltip = () => {
    const arr = [];
    isManualBin && arr.push(labels.manual);
    isRevisionBin && arr.push(labels.revision);

    if (arr.length > 0) {
      return arr.join(', ');
    }
  };

  const getBinNameTooltip = () => {
    return labels.binNameTooltip(name, position);
  };

  const canToggleHold = () => {
    return typeof onToggleHold === 'function';
  };

  const hasDetailsView = () => {
    return typeof onOpenPlatesDetails === 'function';
  };

  const getMenuItems = () => {
    return [
      { value: 'hold', text: labels.holdAction, disabled: !canToggleHold() || hold },
      { value: 'release', text: labels.releaseAction, disabled: !canToggleHold() || !hold },
      { value: 'openPlatesDetails', text: labels.openPlatesDetails, disabled: !hasDetailsView() },
    ];
  };

  const handleContextMenu = (event) => {
    setMenuVisible(true);
    setMenuLeft(event.pageX);
    setMenuTop(event.pageY);
  };

  const handleSelectContextMenu = (event, item) => {
    if (item.value === 'openPlatesDetails') {
      onOpenPlatesDetails();
    } else {
      onToggleHold();
    }
  };

  return (
    <Fragment>
      <div
        className='bin'
        title={errorMessage}
        style={getBinStyle()}
        onContextMenu={handleContextMenu}
      >
        {planned && <div className='bin-caption' title={getBinCaptionTooltip()}>
          {getBinCaption()}

          {hasDetailsView() && <IconButton
            key='details-icon'
            className='crtx-size-12 left'
            iconName={'details'}
            tooltip={labels.openPlatesDetails}
            onClick={onOpenPlatesDetails}
          />}

          {hold && <IconButton
            key='hold-icon'
            className='crtx-size-12 right visible'
            iconName={'hold_sorter'}
            tooltip={labels.releaseAction}
            onClick={onToggleHold}
          />}

          {!hold && canToggleHold() && <IconButton
            key='release-icon'
            className='crtx-size-12 right'
            iconName={'hold_outline'}
            tooltip={labels.holdAction}
            onClick={onToggleHold}
          />}
        </div>}

        <Fragment>
          <div className={getPlateClassName(STATUS.ERROR)} style={getErrorPlatesStyle(errorCount)}>
            {errorCount}
          </div>
          <div className={getPlateClassName(STATUS.MISSING)} style={getMissingPlatesStyle(missingCount)}>
            {isManualBin || isRevisionBin ? '' : missingCount}
          </div>
          <div className={getPlateClassName(STATUS.IN_PROGRESS)} style={getInProgressPlatesStyle(inProgressCount)}>
            {inProgressCount}
          </div>
          <div className={getPlateClassName(STATUS.IN_BIN)} style={getInBinPlatesStyle(inBinCount)}>
            {inBinCount}
          </div>
        </Fragment>

        {!!errorMessage && <IconButton key='error-icon' iconName='error_white_outline' className='error-icon visible' />}

        <div className='bin-name' title={getBinNameTooltip()}>
          <div>{name}</div>
        </div>

        {planned && <ContextMenu
          visible={menuVisible}
          left={menuLeft}
          top={menuTop}
          items={getMenuItems()}
          onSelect={handleSelectContextMenu}
          onClose={() => setMenuVisible(false)}
        />}

      </div>

    </Fragment>
  );
}

Bin.propTypes = {
  module: PropTypes.object,
  scale: PropTypes.number,
  runNwid: PropTypes.string,
  runBreadcrumbs: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  size: PropTypes.string,
  doubleSize: PropTypes.bool,
  hold: PropTypes.bool,
  expected: PropTypes.number,
  inBinCount: PropTypes.number,
  inProgressCount: PropTypes.number,
  missingCount: PropTypes.number,
  errorCount: PropTypes.number,
  isManualBin: PropTypes.bool,
  isRevisionBin: PropTypes.bool,
  planned: PropTypes.bool,
  errorMessage: PropTypes.string,
  onToggleHold: PropTypes.func,
  onOpenPlatesDetails: PropTypes.func,
};

export default Bin;