import sandbox from 'sandbox';
import {translate} from 'core/services/localization';

const EPS = 0.00005;
const PDF_ICON_URL = sandbox.icons.getModuleIcon('TableView', 'pdf');
const TIF_ICON_URL = sandbox.icons.getModuleIcon('TableView', 'tif');
const ICONS_BY_FILE_TYPE = {
  pdf: [PDF_ICON_URL],
  tif: [TIF_ICON_URL],
  both: [PDF_ICON_URL, TIF_ICON_URL]
};

const SYMBOL_BY_UNIT = {
  inch: translate('in'),
  mm: translate('mm'),
  pt: translate('pt'),
};

const ALIGNMENTS_CLOCKWISE = ['top-left', 'top-center', 'top-right', 'center-right', 'bottom-right', 'bottom-center', 'bottom-left', 'center-left'];

// User units: 'inch', 'mm', 'pt'
var userUnits = 'inch';

// Canvas DPI: number of canvas pixels that can be placed in a line within the span of 1 inch
var canvasDpi = 96;

// Conversion factor from user to canvas units (pixels)
var conversionFactor = 1.0;

function resetConversionFactor() {
  conversionFactor = canvasDpi / getInchToUserUnitsFactor();
}

function getInchToUserUnitsFactor() {
  if (userUnits === 'inch') {
    return 1.0;
  }

  var f = 1.0;
  switch (userUnits) {
    case 'pt':
      f = 72;
      break;
    case 'mm':
      f = 25.4;
      break;
  }

  return f;
}

export default {
  ALIGNMENTS_CLOCKWISE: ALIGNMENTS_CLOCKWISE,

  sendGenericRequest: function (params) {
    return new Promise((resolve) => {
      sandbox.request.sendGenericRequest(params, resolve);
    });
  },

  stringToFloat: function (str) {
    var value = parseFloat(str);
    return isNaN(value) ? 0 : value;
  },

  extractFileName: function (filePath) {
    var path = filePath ? filePath.replace(/\\/g, '/') : '';
    return path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.'));
  },

  makeUniqueName: function (existingNames, proposedName) {
    var uniqueName = proposedName;
    var namesLower = existingNames.map(function (name) {
      return name.toLowerCase();
    });
    var count = 1;
    do {
      uniqueName = proposedName + ' ' + count++;
    } while (namesLower.indexOf(uniqueName.toLowerCase()) >= 0);

    return uniqueName;
  },

  getUserUnits: function () {
    return userUnits;
  },

  setUserUnits: function (units) {
    userUnits = units;
    resetConversionFactor();
  },

  getUserUnitsSymbol: function () {
    return SYMBOL_BY_UNIT[userUnits];
  },

  getCanvasDpi: function () {
    return canvasDpi;
  },

  canvasToSystemUnits: function (value) {
    return isNaN(value) ? value : value / canvasDpi;
  },

  systemToCanvasUnits: function (value) {
    return isNaN(value) ? value : value * canvasDpi;
  },

  systemToCanvasFontSize: function (fontSize) {
    return isNaN(fontSize) ? fontSize : fontSize / 72 * canvasDpi;
  },

  composeCanvasFont: function (fontStyle, fontWeight, fontSize, fontFamily) {
    return fontStyle + ' ' + fontWeight + ' ' + this.systemToCanvasFontSize(fontSize) + 'px "' + fontFamily + '"';
  },

  /**
   * Convert canvas units (pixels) to user units
   */
  canvasToUserUnits: function (value) {
    return isNaN(value) ? value : this.roundUserUnits(value / conversionFactor);
  },

  /**
   * Convert user units to canvas units (pixels)
   */
  userToCanvasUnits: function (value) {
    return isNaN(value) ? value : value * conversionFactor;
  },

  roundUserUnits: function (value, extraDecimalPlaces) {
    return this.roundUnits(value, userUnits, extraDecimalPlaces);
  },

  roundUnits: function (value, units, extraDecimalPlaces) {
    var dec = 7;
    switch (units) {
      case 'inch':
        dec = 4;
        break;
      case 'pt':
        dec = 3;
        break;
      case 'mm':
        dec = 3;
        break;
    }

    if (!isNaN(extraDecimalPlaces)) {
      dec += extraDecimalPlaces;
    }

    return this.roundTo(value, dec);
  },

  roundTo: function (value, decimalPlaces) {
    return isNaN(value) ? value : +Number(value).toFixed(decimalPlaces);
  },

  /**
   * Convert points to inches
   */
  ptToInch: function (value) {
    return this.roundTo(value / 72, 4);
  },

  /**
   * Convert user units to system units (inch)
   */
  userToSystemUnits: function (value) {
    return isNaN(value) ? value : value / getInchToUserUnitsFactor();
  },

  /**
   * Convert system units (inch) to user units
   */
  systemToUserUnits: function (value, extraDecimalPlaces) {
    return isNaN(value) ? value : this.roundUserUnits(getInchToUserUnitsFactor() * value, extraDecimalPlaces);
  },

  toCanvasLeftTop: function (plateRectangle, x, y, cornerX, cornerY) {
    var left = plateRectangle.left + this.systemToCanvasUnits(x);
    var top = plateRectangle.top + this.systemToCanvasUnits(y);
    if (cornerX && cornerY) {
      var offset = this.calcOriginOffset(plateRectangle.width, plateRectangle.height, cornerX, cornerY);
      left += offset.x;
      top += offset.y;
    }

    return {
      left: left,
      top: top
    };
  },

  toSystemXY: function (plateRectangle, left, top, cornerX, cornerY) {
    left -= plateRectangle.left;
    top -= plateRectangle.top;
    if (cornerX && cornerY) {
      var offset = this.calcOriginOffset(plateRectangle.width, plateRectangle.height, cornerX, cornerY);
      left -= offset.x;
      top -= offset.y;
    }

    return {
      x: this.canvasToSystemUnits(left),
      y: this.canvasToSystemUnits(top)
    };
  },

  toCanvasDimensions: function (width, height) {
    return {
      width: this.systemToCanvasUnits(width),
      height: this.systemToCanvasUnits(height)
    };
  },

  toCanvasRectangle: function (plateRectangle, x, y, width, height, cornerX, cornerY) {
    var p = this.toCanvasLeftTop(plateRectangle, x, y, cornerX, cornerY);
    return {
      left: p.left,
      top: p.top,
      width: this.systemToCanvasUnits(width),
      height: this.systemToCanvasUnits(height)
    };
  },

  toSystemRectangle: function (plateRectangle, left, top, width, height, cornerX, cornerY) {
    var p = this.toSystemXY(plateRectangle, left, top, cornerX, cornerY);
    return {
      x: p.x,
      y: p.y,
      width: this.canvasToSystemUnits(width),
      height: this.canvasToSystemUnits(height)
    };
  },

  plateRectangleToSystem: function (plateRectangle) {
    return {
      x: 0,
      y: 0,
      width: this.canvasToSystemUnits(plateRectangle.width),
      height: this.canvasToSystemUnits(plateRectangle.height)
    };
  },

  containsLineBreak: function (text) {
    return (text || '').indexOf('\n') >= 0;
  },

  replaceLineBreaks: function (text, replaceText) {
    return (text || '').replace(/(\r?\n)/g, replaceText || '');
  },

  removeLineBreaks: function (text) {
    return this.replaceLineBreaks(text, '');
  },

  composePosition: function (positionX, positionY, defaultPosition) {
    var defPos = defaultPosition;
    return positionX && positionY ? positionY + '-' + positionX : defPos;
  },

  getPositionX: function (position, defaultPositionX) {
    var positionX = defaultPositionX;
    var idx = (typeof position == 'string') ? position.indexOf('-') : -1;
    if (idx >= 0) {
      positionX = position.substring(idx + 1, position.length);
    }

    return positionX;
  },

  calcElementNewXY: function (plateRectangle, element, newReferenceX, newReferenceY) {
    const o = this.toCanvasLeftTop(plateRectangle, element.x, element.y, element.referenceX, element.referenceY);
    const p = this.toSystemXY(plateRectangle, o.left, o.top, newReferenceX, newReferenceY);

    return p;
  },

  getPositionY: function (position, defaultPositionY) {
    var positionY = defaultPositionY;
    var idx = (typeof position == 'string') ? position.indexOf('-') : -1;
    if (idx > 0) {
      positionY = position.substring(0, idx);
    }

    return positionY;
  },

  rotateVector: function (left, top, angle) {
    var v = {left: left, top: top};
    switch (angle) {
      case 90:
      case -270:
        v.left = -top;
        v.top = left;
        break;
      case 180:
      case -180:
        v.left = -left;
        v.top = -top;
        break;
      case 270:
      case -90:
        v.left = top;
        v.top = -left;
        break;
    }

    return v;
  },

  rotateDimensions: function (width, height, angle) {
    var d = {width: width, height: height};
    switch (angle) {
      case 90:
      case -270:
      case 270:
      case -90:
        d.width = height;
        d.height = width;
        break;
    }

    return d;
  },

  calcMargins: function (innerRect, outerRect) {
    const margins = {
      left: innerRect.left - outerRect.left,
      right: outerRect.left + outerRect.width - innerRect.left - innerRect.width,
      top: innerRect.top - outerRect.top,
      bottom: outerRect.top + outerRect.height - innerRect.top - innerRect.height
    };

    return margins;
  },

  rotateMargins: function (margins, angle) {
    const ma = [margins.left, margins.top, margins.right, margins.bottom];
    const count = ma.length;

    let step = 0;
    switch (angle) {
      case 90:
      case -270:
        step = 1;
        break;
      case 180:
      case -180:
        step = 2;
        break;
      case 270:
      case -90:
        step = 3;
        break;
    }

    return {
      left: ma[(0 - step + count) % count],
      top: ma[(1 - step + count) % count],
      right: ma[(2 - step + count) % count],
      bottom: ma[(3 - step + count) % count],
    };
  },

  calcAlignmentBeforeRotation: function (alignmentX, alignmentY, angle) {
    if (angle === 0) {
      return {
        alignmentX: alignmentX,
        alignmentY: alignmentY
      };
    }

    var count = ALIGNMENTS_CLOCKWISE.length;
    var alignment = this.composePosition(alignmentX, alignmentY);
    var idx = ALIGNMENTS_CLOCKWISE.indexOf(alignment);

    // rotate rectangle counterclockwise (in opposite direction)
    var step = 0;
    switch (angle) {
      case 90:
      case -270:
        step = 2;
        break;
      case 180:
      case -180:
        step = 4;
        break;
      case 270:
      case -90:
        step = 6;
        break;
    }

    var align = 'center-center';
    if (idx >= 0) {
      align = ALIGNMENTS_CLOCKWISE[(idx - step + count) % count];
    }

    return {
      alignmentX: this.getPositionX(align),
      alignmentY: this.getPositionY(align)
    };
  },

  /**
   * Calculate vector from the given point (left, top) to the alignment point on the given rectangle
   */
  calcVectorToAlignmentPoint: function (left, top, rect, alignmentX, alignmentY) {
    var rx, ry;
    switch (alignmentX) {
      case 'left':
        rx = rect.left;
        break;
      case 'center':
        rx = rect.left + rect.width / 2;
        break;
      case 'right':
        rx = rect.left + rect.width;
        break;
    }

    switch (alignmentY) {
      case 'top':
        ry = rect.top;
        break;
      case 'center':
        ry = rect.top + rect.height / 2;
        break;
      case 'bottom':
        ry = rect.top + rect.height;
        break;
    }

    return {left: rx - left, top: ry - top};
  },

  calcOriginOffset: function (width, height, originX, originY) {
    var x = 0;
    var y = 0;
    switch (originX) {
      case 'center':
        x = width / 2;
        break;
      case 'right':
        x = width;
        break;
    }

    switch (originY) {
      case 'center':
        y = height / 2;
        break;
      case 'bottom':
        y = height;
        break;
    }

    return {x: x, y: y};
  },

  toNormalizedRectangle: function (rect, originX, originY, angle) {
    if (originX === 'left' && originY === 'top' && angle === 0) {
      return {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height
      };
    }

    var align = this.calcAlignmentBeforeRotation(originX, originY, -angle);
    var dims = this.rotateDimensions(rect.width, rect.height, angle);
    var offset = this.calcOriginOffset(dims.width, dims.height, align.alignmentX, align.alignmentY);

    return {
      left: rect.left - offset.x,
      top: rect.top - offset.y,
      width: dims.width,
      height: dims.height
    };
  },

  fromNormalizedRectangle: function (rect, originX, originY, angle) {
    if (originX === 'left' && originY === 'top' && angle === 0) {
      return {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height
      };
    }

    var align = this.calcAlignmentBeforeRotation(originX, originY, -angle);
    var dims = this.rotateDimensions(rect.width, rect.height, angle);
    var offset = this.calcOriginOffset(rect.width, rect.height, align.alignmentX, align.alignmentY);

    return {
      left: rect.left + offset.x,
      top: rect.top + offset.y,
      width: dims.width,
      height: dims.height
    };
  },

  rectanglesIntersection: function (r1, r2) {
    const left = Math.max(r1.left, r2.left);
    const top = Math.max(r1.top, r2.top);
    const right = Math.min(r1.left + r1.width, r2.left + r2.width);
    const bottom = Math.min(r1.top + r1.height, r2.top + r2.height);

    return {
      left,
      top,
      width: Math.max(0, right - left),
      height: Math.max(0, bottom - top)
    };
  },

  areRectanglesEqual: function (r1, r2, eps = EPS) {
    return Math.abs(r1.x - r2.x) <= eps && Math.abs(r1.y - r2.y) <= eps &&
      Math.abs(r1.width - r2.width) <= eps && Math.abs(r1.height - r2.height) <= eps;
  },

  checkRectanglesOverlap: function (r1, r2, margin = 0, eps = EPS) {
    const d = margin - eps;
    return r1.x + r1.width > r2.x - d && r1.x < r2.x + r2.width + d &&
      r1.y + r1.height > r2.y - d && r1.y < r2.y + r2.height + d;
  },

  areValuesEqual: function (obj1, obj2, keys) {
    return !keys.some(key => obj1[key] !== obj2[key]);
  },

  range: function (size, startAt = 1) {
    return Array.from({length: size}, (v, k) => k + startAt);
  },

  isScaledShape: function (shape) {
    return shape.scaleX !== 1 || shape.scaleY !== 1;
  },

  isScaledXShape: function (shape) {
    return shape.scaleX !== 1;
  },

  isScaledYShape: function (shape) {
    return shape.scaleY !== 1;
  },

  unscaleShape: function (shape) {
    var width = shape.width * shape.scaleX;
    var height = shape.height * shape.scaleY;
    shape.set({
      width: width,
      height: height,
      scaleX: 1,
      scaleY: 1,
      flipX: false,
      flipY: false
    });

    return shape;
  },

  calcRectangleArea: function (rectangle) {
    return rectangle && rectangle.width > 0 && rectangle.height > 0 ? rectangle.width * rectangle.height : 0;
  },

  constrainValue: function (value, min, max) {
    return Math.min(Math.max(min, value), max);
  },

  constrainPoint: function (point, bounds) {
    point.left = this.constrainValue(point.left, bounds.left, bounds.left + bounds.width);
    point.top = this.constrainValue(point.top, bounds.top, bounds.top + bounds.height);

    return point;
  },

  applyMoveConstraints: function (rectangle, bounds) {
    if (rectangle.left + rectangle.width > bounds.left + bounds.width) {
      rectangle.left = bounds.left + bounds.width - rectangle.width;
    }
    if (rectangle.left < bounds.left) {
      rectangle.left = bounds.left;
    }
    if (rectangle.top + rectangle.height > bounds.top + bounds.height) {
      rectangle.top = bounds.top + bounds.height - rectangle.height;
    }
    if (rectangle.top < bounds.top) {
      rectangle.top = bounds.top;
    }

    return rectangle;
  },

  applyScaleConstraints: function (rectangle, bounds, uniformScale) {
    if (!uniformScale) {
      if (rectangle.width > bounds.width) {
        rectangle.width = bounds.width;
      }
      if (rectangle.height > bounds.height) {
        rectangle.height = bounds.height;
      }
    } else {
      var fx = rectangle.width <= bounds.width ? 1 : bounds.width / rectangle.width;
      var fy = rectangle.height <= bounds.height ? 1 : bounds.height / rectangle.height;
      if (fx !== 1 || fy !== 1) {
        var f = Math.min(fx, fy);
        rectangle.width *= f;
        rectangle.height *= f;
      }
    }

    return rectangle;
  },

  applyConstraints: function (rectShape, bounds) {
    var scaled = this.isScaledShape(rectShape);
    if (scaled) {
      this.unscaleShape(rectShape);
    }

    if (this.calcRectangleArea(bounds) > 0) {
      var originX = rectShape.originX;
      var originY = rectShape.originY;
      var angle = rectShape.angle;
      var rectangle = this.toNormalizedRectangle(rectShape, originX, originY, angle);

      if (scaled) {
        this.applyScaleConstraints(rectangle, bounds, rectShape.lockUniScaling);
      } else {
        this.applyMoveConstraints(rectangle, bounds);
      }

      rectangle = this.fromNormalizedRectangle(rectangle, originX, originY, angle);
      rectShape.set({
        left: rectangle.left,
        top: rectangle.top,
        width: rectangle.width,
        height: rectangle.height
      });

      rectShape.setCoords();
    }

    return rectShape;
  },

  createPseudoArray: function () {
    return {otype: 'array'};
  },

  /**
   * Shallow clone all pseudo array items
   */
  clonePseudoArrayItems: function (pseudoArray) {
    const result = this.createPseudoArray();
    for (let key in pseudoArray) {
      const item = pseudoArray[key];
      result[key] = item !== null && typeof item === 'object' ? {...item} : item;
    }

    return result;
  },

  /**
   * Convert regular array to pseudo array object
   */
  toPseudoArray: function (arr) {
    if (!Array.isArray(arr)) {
      return arr;
    }

    var obj = this.createPseudoArray();
    for (var i = 0; i < arr.length; i++) {
      obj[i] = arr[i];
    }

    return obj;
  },

  /**
   * Convert all regular arrays to pseudo array objects recursively
   */
  toPseudoArrayDeep: function (obj) {
    var result;
    if (Array.isArray(obj)) {
      result = this.toPseudoArray(obj);
      result = this.toPseudoArrayDeep(result);
    } else if (typeof obj === 'object' && obj !== null) {
      result = {};
      for (var key in obj) {
        result[key] = this.toPseudoArrayDeep(obj[key]);
      }
    } else {
      result = obj;
    }

    return result;
  },

  /**
   * Convert pseudo array object to regular array
   */
  fromPseudoArray: function (obj) {
    if (!obj || typeof obj !== 'object' || obj.otype != 'array') {
      return obj;
    }

    var arr = [];
    for (var key in obj) {
      if (key !== 'otype') {
        arr.push(obj[key]);
      }
    }

    return arr;
  },

  /**
   * Convert all pseudo arrays inside an object to regular arrays
   */
  fromPseudoArrayDeep: function (obj) {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }

    const replacer = (key, value) => {
      if (typeof value === 'object' && value.otype === 'array') {
        return this.fromPseudoArray(value);
      }

      return value;
    };

    return JSON.parse(JSON.stringify(obj, replacer));
  },

  maxKey: function (obj) {
    if (!obj) {
      return -1;
    }

    var result = -1;
    for (var key in obj) {
      var num = Number(key);
      if (!isNaN(num) && num > result) {
        result = num;
      }
    }

    return result;
  },

  cloneDeep: function (obj, replacer) {
    return JSON.parse(JSON.stringify(obj, replacer));
  },

  cloneElement: function (element) {
    var replacer = function (key, value) {
      if (key === 'shape') {
        return;
      }

      return value;
    };

    return this.cloneDeep(element, replacer);
  },

  calcNudge: function (direction) {
    var x = 0;
    var y = 0;
    var step = this.canvasToSystemUnits(1);
    switch (direction) {
      case 'left':
        x = -step;
        break;
      case 'right':
        x = step;
        break;
      case 'up':
        y = -step;
        break;
      case 'down':
        y = step;
        break;
    }

    return {x, y};
  },

  getAlignmentPoints: function (rectangle) {
    if (!rectangle) {
      return;
    }

    const {left, top, width, height} = rectangle;
    return {
      ht: top,
      hm: top + height / 2,
      hb: top + height,
      vl: left,
      vm: left + width / 2,
      vr: left + width
    };
  },

  getAlignmentInfo: function (canvasShape) {
    var boundingBox = this.toNormalizedRectangle(canvasShape, canvasShape.originX, canvasShape.originY, canvasShape.angle);
    var alignments = this.getAlignmentPoints(boundingBox);

    return {
      boundingBox,
      alignments
    };
  },

  getFurnitureIcons: function (fileType) {
    fileType = (fileType || '').toLowerCase();

    return ICONS_BY_FILE_TYPE[fileType] || [];
  },

  getFurnitureImageUrl: function (folderNwid, imageName, timestamp) {
    const params = {
      action: 'getFurnitureImage',
      command: 'getLayouManagerActions',
      rootId: folderNwid,
      imageName: encodeURIComponent(imageName),
      timestamp
    };

    return sandbox.request.getImageUrl(params, true);
  },

  findFurnitureByImageName: function(furnitures, imageName) {
    if (!Array.isArray(furnitures) || !imageName) {
      return;
    }

    var imageNameLower = imageName.toLowerCase();
    return furnitures.find(f => (f.name || '').toLowerCase() === imageNameLower);
  }

};
