/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['sandbox', 'AbstractModule', 'ember', './views/TitleView', './views/ResourcesView', './views/StatusView', './controllers/FlowStepOverviewController'],
  function (sandbox, AbstractModule, Ember, TitleView, ResourceView, StatusView, Controller) {
  'use strict';

  function createResorceEventsInfo(eventsInfo, r, queProcess) {
    var maxEvents = r.maxInProgressEvents;
    if (queProcess) {
      queProcess.events.forEach(function(process){
        var eventInfo = {};
        Ember.set(eventInfo, "progress", "height: 100%");
        Ember.set(eventInfo, 'processingEventId', process.id);
        Ember.set(eventInfo, "inProcess", "100");
        eventsInfo.pushObject(eventInfo);
        maxEvents--;
      });
    }
    for (var j = 0; j < maxEvents; j++) {
      var eventInfo = {};
      Ember.set(eventInfo, "progress", "height: 100%");
      Ember.set(eventInfo, 'processingEventId', 0);
      Ember.set(eventInfo, "inProcess", "0");
      eventsInfo.pushObject(eventInfo);
    }
    Ember.set( r, "eventsInfo", eventsInfo);
  }

  function getEventInfo(res, id) {
    var eventsInfo = res.eventsInfo;
    if (typeof  eventsInfo === "undefined") {
      return eventsInfo;
    }
    for (var i = 0; i < eventsInfo.length; i++) {
      if (eventsInfo[i].processingEventId === id) {
        return eventsInfo[i];
      }
    }
    for (i = 0; i < eventsInfo.length; i++) {
      if (typeof eventsInfo[i].processingEventId === "undefined" || eventsInfo[i].processingEventId === 0) {
        return eventsInfo[i];
      }
    }
    ////if from some reason a tick message to remove the event was not received
    for (i = 0; i < eventsInfo.length; i++) {
      Ember.set(eventsInfo[i], 'processingEventId', 0);
      Ember.set(eventsInfo[i], 'processingEventName', "");
      Ember.set(eventsInfo[i], "processingEventDetails", "");
      Ember.set(eventsInfo[i], "inProcess", "0");
    }
    return eventsInfo[0];
  }

  function handleAddObject(model) {
    var id = model["id"];
    var parent = this.objectsMap[model["parentId"]];
    sandbox.appUtils.flattenModel(model, this.objectsMap);
    var childProperty = model["childPropertyId"];
    var objToUpdate = parent[childProperty];
    var updateArray = Array.isArray(objToUpdate);
    if (updateArray) {
      var addedProperties = this.addedObjects[parent["id"]];
      if (!addedProperties) {
        addedProperties = {};
        this.addedObjects[parent["id"]] = addedProperties;
      }
      var objectToSplice = addedProperties[childProperty];
      if (!objectToSplice) {
        objectToSplice = [];
        addedProperties[childProperty] = objectToSplice;
      }
      objectToSplice.push(model);
    } else {
      Ember.set(parent, childProperty, model);
    }
    var eventInfo = getEventInfo.apply(this, [parent, model.id]);
    if (model.childPropertyId === "inProcessEvents" ) {
      Ember.set(eventInfo, 'processingEventId', model.id);
      Ember.set(eventInfo, "progress", "height: 100%");
      Ember.set(eventInfo, "inProcess", "100");
    } else if (typeof eventInfo !== "undefined") {
      Ember.set(eventInfo, 'processingEventId', 0);
      Ember.set(eventInfo, 'processingEventName', "");
      Ember.set(eventInfo, "processingEventDetails", "");
      Ember.set(eventInfo, "inProcess", "0");
    }
    if (model.childPropertyId === "resources" ) { //new resource is assigned
      var eventsInfo = [];
      createResorceEventsInfo.apply(this, [eventsInfo, model]);
    }
  }

  function handleUpdateObject(model) {
    var existing = this.objectsMap[model["id"]];
    if (existing) {
      for (var key in model) {
        Ember.set(existing, key, model[key]);
      }
      Ember.set(this.ctrl, "existingChanged", !this.ctrl.existingChanged);
      //this.ctrl.toggleAddProperty('existingChanged');
      if (existing.childPropertyId === "inProcessEvents" ) {
        var parent = this.objectsMap[existing["parentId"]];
        var eventInfo = getEventInfo.apply(this, [parent, existing.id]);
        Ember.set(eventInfo, "progress", "height: " + (100 - model.progress) + '%');
      }
    }
  }

  function handleDeleteObjects(model) {
    var childProperty = model["childPropertyId"];
    if (childProperty === "status") {
      return; //statuses can only be replaced not removed
    }
    var parent = this.objectsMap[model["parentId"]];
    var idsToDelete = model["ids"];

    var objToUpdate = parent[childProperty];
    var updateArray = Array.isArray(objToUpdate);
    if (idsToDelete) {
      for (var i = 0; i < idsToDelete.length; i++) {
        var idToDelete =  idsToDelete[i];
        var o =  this.objectsMap[idToDelete];
        if (updateArray) {
          objToUpdate.removeObject(o);
          delete this.objectsMap[idToDelete];
        } else {
          this.objectsMap[idToDelete] = null;
          var existingObj = Ember.get(parent, childProperty);
          if (existingObj) {
            var existingId = existingObj["id"];
            if (existingId === idToDelete) {
              Ember.set(parent, childProperty, null);
            }
          }
        }
        if (childProperty === "inProcessEvents" ) {
          var eventInfo = getEventInfo.apply(this, [parent, idToDelete]);
          Ember.set(eventInfo, "progress", "height: 100%");
          Ember.set(eventInfo, 'processingEventId', 0);
          Ember.set(eventInfo, "inProcess", "0");
        }
      }
    }
  }


  function tickUpdateHandler(model) {
    var action = model["action"];
    if (!action) { //this is not part of the model
      return;
    }
    switch (action.toLowerCase()) {
    case "remove":
      handleDeleteObjects.call(this, model);
      break;
    case "add":
      handleAddObject.apply(this, [model]);
      break;
    case "update":
      handleUpdateObject.call(this, model);
      break;
    }
  }

  function mergeToModel(parent, properyName, toAdd) {
    var model = parent[properyName];
    var sortingDescription = parent[properyName + ":sorting"];
    if (sortingDescription) {
      var sortingTokens = sortingDescription.split(":");
      if (sortingTokens.length === 2) {
        sandbox.sorting.sort(toAdd, sortingTokens[1], sortingTokens[0]);
        var comparator = sandbox.sorting.getComparator(sortingTokens[1], sortingTokens[0]);
        var modelPosition = 0;
        for (var i = 0; i < toAdd.length; i++) {
          var found = false;
          for (var j = modelPosition; j < model.length; j++) {
            if (comparator(toAdd[i], model[j]) < 1) {
              model.insertAt(j, toAdd[i]);
              found = true;
              modelPosition = j;
              break;
            }
          }
          if (!found) {
            model.pushObject(toAdd[i]);
          }
        }

      } else {
        model.pushObject(toAdd[i]);
      }
    } else {
      model.pushObjects(toAdd);
    }
  }
  return AbstractModule.extend({
    initDone: function () {
      var ctrl = Controller.create({
        module: this
      });
      this.ctrl = ctrl;
      this.views = {
        title: TitleView.create({
          controller: ctrl
        }),
        resources: ResourceView.create({
          controller: ctrl
        }),
        status: StatusView.create({
          controller: ctrl
        })
      };
    },

    firstTickReceived: function (data) {

      // build map between id and model for updates
      this.objectsMap = sandbox.appUtils.flattenModel(data.model, {});
      this.viewLinks = data.model.viewLinks;
      sandbox.dom.addClass(this.element, 'pro-modules-flowstep-overview');

      // sort resources
      var sortingTokens = data.model['resources:sorting'].split(":");
      sandbox.sorting.sort(data.model.resources, sortingTokens[1], sortingTokens[0]);

      ///add progress object for each of  the possible in process events
      var resources = data.model.resources;
      for (var i = 0; i < resources.length; i++) {
        var r = resources[i];
        var eventsInfo = [];
        var queProcess = data.model.queues.find(function(q){
          return q.queueType === "queProcess";
        });
        createResorceEventsInfo.apply(this, [eventsInfo, r, queProcess]);
      }
      this.ctrl.set('model', data.model);
      this.views.title.replaceIn(this.element + ' .item-title');
      this.views.resources.replaceIn(this.element + ' .item-content');
      this.views.status.replaceIn(this.element + ' .item-statusbar');

      // var successQueue = data.model.queues.find(function(queue){
      //   return queue.queueType === "queProcess";
      // });
      // successQueue.events.forEach(function(event){
      //   handleUpdateObject.call(this, event);
      //
      // }.bind(this));
    },

    tickUpdate: function (data) {


      this.addedObjects = {};
      for (var m in data.model) {
        if (sandbox.jsUtils.isObject(data.model[m])) {
          tickUpdateHandler.apply(this, [data.model[m]]);
        }
      }
      for (var parentId in this.addedObjects) {
        var parent = this.objectsMap[parentId];
        var propertiesToAddTo = this.addedObjects[parentId];
        for (var propertyName in propertiesToAddTo) {
          mergeToModel.apply(this, [parent, propertyName, propertiesToAddTo[propertyName]]);
        }
      }
    }
  });
});