import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

export default (DecoratedCell) => {

  class ObserverCell extends Component {

    static propTypes = {
      model: PropTypes.any,
      propName: PropTypes.string,
      columnData: PropTypes.any,
      getColumnData: PropTypes.func,
      options: PropTypes.any,
      separatorIndexes: PropTypes.any,
      getOptionsData: PropTypes.func,
      style: PropTypes.any,
      getStyle: PropTypes.func,
      title: PropTypes.any,
      getTitle: PropTypes.func,

    };

    render() {
      const {model, propName, columnData, getColumnData, options, getOptionsData, style, getStyle, title, getTitle, ...props} = this.props;

      return (
        <DecoratedCell
          {...props}
          columnData={model && propName ? model[propName] : typeof getColumnData === 'function' ? getColumnData() : columnData}
          options={typeof getOptionsData === 'function' ? getOptionsData().options : options}
          separatorIndexes={typeof getOptionsData === 'function' ? getOptionsData().separatorIndexes : []}
          style={typeof getStyle === 'function' ? getStyle() : style}
          title={typeof getTitle === 'function' ? getTitle() : title}
        />
      );
    }
  }

  return observer(ObserverCell);
};
