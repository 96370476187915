/**
 * @name
 * @fileOverview
 * @author sergey
 */

import sandbox from 'sandbox';
import Wizard from 'widgets/Wizard/Wizard';
import AbstractModule from 'AbstractModule';
import WizardUtils from './utils/wizardUtils';
import { restPost } from 'core/managers/rest2';

const { translate } = sandbox.localization;
const defaultSteps = [ // Todo - ???
  {
    "title": translate("Structure"),
    "stepName": "Structure"    
  },
  {
    "title": translate("Sections"),
    "stepName": "Sections"
  },
  {
    "title": translate("Pages"),
    "stepName": "Pages"    
  },
  {
    "title": translate("Books"),
    "stepName": "Books"    
  },
  {
    "title": translate("Formes"),
    "stepName": "Forms"    
  },
  {
    "title": translate("Production Runs"),
    "stepName": "ProductionRuns"    
  },
  {
    "title": translate("Plan Activation"),
    "stepName": "PlanActivation"    
  }];


var stepsMap = { // Todo - ???
  "StructureLegacy": require("modules/PlanningWizard/steps/StructureLegacy/StructureLegacy"),
  "Structure": require("modules/PlanningWizard/steps/Structure/Structure"),
  "Sections": require("modules/PlanningWizard/steps/Sections/Sections"),
  "Pages": require("modules/PlanningWizard/steps/Pages/Pages"),
  "Books": require("modules/PlanningWizard/steps/Books/Books"),
  "BooksLegasy": require("modules/PlanningWizard/steps/BooksLegacy/Books"),
  "Forms": require("modules/PlanningWizard/steps/Forms/Forms"),
  "ProductionRuns": require("modules/PlanningWizard/steps/productionRuns/productionRuns"),
  "PlanActivation": require("modules/PlanningWizard/steps/PlanActivation/PlanActivation"),
  "SanomaPreOrderSelection": require("modules/PlanningWizard/steps/SanomaPreOrderSelection/SanomaPreOrderSelection"),
  "SanomaSharing": require('modules/PlanningWizard/steps/SanomaSharing/SanomaSharing'),
  "SanomaSummary": require('modules/PlanningWizard/steps/SanomaSummary/SanomaSummary')
  //"BooksReact": require("./steps/BooksReact/Books")
}

function createExistsPlansInfo(model){
  let ret = [];
  const info = model.newPublicationsInfo || [];
  model.planSetup.getPublications();

  for (let i=0;i<model.newPublicationsInfo.length;i++){    
    const publication = model.newPublicationsInfo[i];
    publication.editions.forEach(function (edition) {
      const {code, name, nwid, index} = edition;
      const editionData = {code, name, nwid, index};
      editionData.publicationName = publication.name;
      editionData.publicationDate = publication.date;
      let planSetupPublication = model.planSetup.getPublication(editionData.publicationName);
      if (planSetupPublication){
      	editionData.standalone = planSetupPublication.standAloneEditions === "true";
      }
      
      ret.push(editionData);
    });
  }

  

  model.editions = ret;
}


function loadWizardData(res, responseLocking = {}) {
  var that = this;
  var rest = sandbox.request.rest(this.nwid); // Todo - remove after change rest.sendBeacon
  var initObj = {
    startParameters: this.startParameters,
    projectorId: that.id,
    //stepsRoot: "modules/PlanningWizard/steps", //require.toUrl("./steps", null, "relative/url"),
    element: that.element,
    onFinished: clearModel,
    stepsProperties: res.clientSettings ? res.clientSettings.stepsProperties : null,
    steps: res.clientSettings ? res.clientSettings.steps : null,
    model: {}
  };


  var serverSettings = res.serverSettings;
  //if (serverSettings){
  WizardUtils.initData(initObj.model, res, responseLocking);


  if (initObj.model.lockedError) {
    sandbox.dialog.alert(initObj.model.lockedError).then(() => window.close());
    return;
  }

  //}

  createExistsPlansInfo(initObj.model);
  
  


  initObj.steps = resolveSteps(initObj, initObj.steps || defaultSteps);
  

  initObj.win = this.win;
  initObj.enableCancelButton = true;
  initObj.labels = {
    next: translate("Next"),
    previous: translate("Previous"),
    cancel: translate("New Plan"),
    finish: translate("Close")
  };
  initObj.onCanceled = function () {
    wizard.destroy();
    initObj.model.publication = null;

    sandbox.request.getPlan(that, that.rootId, null, that.id,
      {
        rootId: that.startParameters.rootId,
        rootType: that.startParameters.rootType,
        instanceNwId: that.startParameters.nwid,
        firstStep: true
      }
    )
      .then(function (res) {
        //loadWizardData.call(that, res.data);
        var planContainersIds = res.data.planContainersIds;
        if (planContainersIds) {
          var params = { planContainersIds: planContainersIds };
          restPost(this.nwid, `global/locking/containers/lock/PlanningWizard`, params)
            .then(responseLocking => {
              if (responseLocking.LockError) {
                sandbox.dialog.alert(responseLocking.LockError).then(() => window.close());
                return;
              }
              loadWizardData.call(this, res.data, responseLocking);
            });
        } else {
          loadWizardData.call(this, res.data, {});
        }
      }.bind(that));

  };
  initObj.onUnload = function () {
    var lockingGroupId = initObj.model.publication.LockingGroupId;
    if (lockingGroupId) {
      const data = { data: { shouldLock: false } };
      rest.sendBeacon(`global/locking/containers/groups/${initObj.model.publication.LockingGroupId}/unlock/PlanningWizard`, data);

    }

  };
  console.log(initObj, Wizard.AbstractStep);
  initObj.startParameters = that.startParameters;


  var wizard = new Wizard(initObj);
}


function clearModel() {
  this.model.publication = null;
}

function findStepOverrides(overrideProps, name){
  for (let key in overrideProps){
    if (key.toLowerCase() == name){
      return overrideProps[key];
    }
  }
  return {};
}
function mergeSettings(properties, overrideProps, step){
  
  // const optionalStep = step.replace(step.substring(0,1), step.substring(0,1).toLowerCase());
  const stepOverrideProps = findStepOverrides(overrideProps, step.toLowerCase()); //overrideProps ? overrideProps[step] || overrideProps[optionalStep] : {};  
  const settings = {...properties, ...stepOverrideProps};
  return settings;
}

function resolveSteps(initObj, steps) {
  steps.forEach(function (step) {
    step.properties = mergeSettings (step.properties || {}, initObj.stepsProperties ? initObj.stepsProperties.steps : {},
      step.stepName || step.title);
    let className = stepsMap[step.properties.stepName ? step.properties.stepName : step.stepName];
    step.stepClass = (className);
    
  });
  return steps;
}

module.exports = AbstractModule.extend({

  init: function () {
    this._super();
  },

  clearModel: clearModel,
  initDone: function () {
    console.log("planningWizard: initDone");

    var that = this;

    sandbox.request.getPlan(this, that.rootId, null, this.id,
      {
        rootId: this.startParameters.rootId,
        rootType: this.startParameters.rootType,
        instanceNwId: this.startParameters.nwid,
        firstStep: true
      })
      .then(function (res) {

        var planContainersIds = res.data.planContainersIds;
        if (planContainersIds) {
          var params = { planContainersIds: planContainersIds };
          restPost(this.nwid, `global/locking/containers/lock/PlanningWizard`, params)
            .then(responseLocking => {

              if (responseLocking.LockError) {
                sandbox.dialog.alert(responseLocking.LockError).then(() => window.close());
                return;
              }
              loadWizardData.call(this, res.data, responseLocking);
            });
        } else {
          loadWizardData.call(this, res.data, {});
        }
      }.bind(this));
  },
 
  destroy: function () {
    this._super();
  },

  firstTickReceived: function (data) {
    console.log("planningWizard: firstTickReceived");
  },

  tickUpdate: function (data) { }

});