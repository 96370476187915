import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sections from './sections';
import Books from './books';

const isUndefined = o => typeof o === 'undefined';

export default class BirdeyeMain extends Component {
  static childContextTypes = {
    controller: PropTypes.object,
    module: PropTypes.object,
  };

  static defaultProps = {
    module: undefined,
    model: undefined,
    pageDefinition: undefined,
    formDefinition: undefined,
    alignmentPoints: undefined
  };

  setInitialState = () => {
    return {
      time: Date.now()
    };
  };

  getChildContext() {
    const { ctrl } = this.props.module;
    return {
      controller: ctrl,
      module: this.props.module
    };
  }

  getModel = () => {
    return this.props.model;
  };

  getModelType = (model) => {
    var type = typeof model === 'undefined' || typeof model.type !== 'string' ?
      undefined :
      model.type.toLowerCase();

    if (typeof type === 'undefined') {
      return undefined;
    }

    return type;
  };

  getModelElements = (model, elementsType, elementsName, type) => {
    var elements = [];

    if (type === 'productionrun') {
      elements = isUndefined(model.zone) ? model[elementsName] || [] : model.zone[elementsName] || [];
    }
    if (type === 'zone' || type === 'edition') {
      elements = model[elementsName] || [];
    }
    if (type === elementsType) {
      elements.push(model);
    }

    return elements;
  };

  seprator = (sections, books, filterZoneViewType) => {
    if (sections !== undefined && books !== undefined && sections.length > 0 && books.length > 0 && filterZoneViewType === undefined) {
      return <div>
        <br /><br />
        <hr />
        <br /><br />
      </div>;
    }
    return null;
  };

  onClick = (e) => {
    this.props.module.ctrl.onClick(undefined, undefined, e);
  };

  handleClose = () => this.props.module.handleClose();

  render() {
    var model = this.getModel(),
      controller = this.props.module.ctrl,
      type = this.getModelType(model),
      pageDefinition = this.props.pageDefinition,
      formDefinition = this.props.formDefinition,
      alignmentPoints = this.props.alignmentPoints,
      sections = this.getModelElements(model, 'section', 'sections', type)
        .map(function (section) {
          return <Sections key={section.id}
            model={section}
            pageDefinition={pageDefinition}
            alignmentPoints={alignmentPoints}>
          </Sections>;
        }),
      books = this.getModelElements(model, 'book', 'books', type)
        .map(function (book) {
          return <Books key={book.id}
            model={book}
            formDefinition={formDefinition}
            alignmentPoints={alignmentPoints}>
          </Books>;
        });

    return <div className="BirdEyeLayersMain" onClick={(e) => this.onClick(e)}>
      {sections}
      {this.seprator(sections, books, controller.filterZoneViewType)}
      {books}
    </div>;
  }
}

BirdeyeMain.propTypes = {
  module: PropTypes.object,
  pageDefinition: PropTypes.object,
  formDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  model: PropTypes.object,
};