export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
};

export const COMPARE_TYPE = {
  CASE_SENSITIVE: 'CASE_SENSITIVE',
  CASE_INSENSITIVE: 'CASE_INSENSITIVE',
  NUMBERS: 'NUMBERS',
  DATES: 'DATES',
  VERSIONS: 'VERSIONS',
};

/**
 * Compares two dot-separated version numbers.
 * This function works by splitting the version numbers into an array of numbers.
 * It then compares the numbers in the arrays, one by one.
 * If a number in the first array is greater than a number in the second array, then the function returns 1.
 * If a number in the first array is less than a number in the second array, then the function returns -1.
 * If all of the numbers are equal, then the function returns 0.
 *
 * @param v1 {string} - first dot-separated version number
 * @param v2 {string} - second dot-separated version number
 * @returns {number} - 1 (v1 > v2), -1 (v1 < v2), 0 (v1 === v2)
 */

function compareVersions(v1 = '', v2 = '') {
  let result = 0;

  const a1 = v1.split('.');
  const a2 = v2.split('.');
  const maxLength = Math.max(a1.length, a2.length);
  for (let i = 0; i < maxLength; i++) {
    const n1 = Number(a1[i] || 0);
    const n2 = Number(a2[i] || 0);
    if (n1 > n2) {
      result = 1;
      break;
    } else if (n1 < n2) {
      result = -1;
      break;
    }
  }

  return result;
}

function compareValues(a, b, compareType) {
  let result = 0;

  if (typeof a === 'undefined') {
    result = typeof b !== 'undefined' ? -1 : 0;
  } else if (typeof b === 'undefined') {
    result = typeof a !== 'undefined' ? 1 : 0;
  } else {
    switch (compareType) {
      case COMPARE_TYPE.CASE_SENSITIVE:
        result = a < b ? -1 : a > b ? 1 : 0;
        break;
      case COMPARE_TYPE.NUMBERS:
        result = Number(a) < Number(b) ? -1 : Number(a) > Number(b) ? 1 : 0;
        break;
      case COMPARE_TYPE.DATES: {
        const t1 = a ? a.getTime() : a;
        const t2 = b ? b.getTime() : b;
        result = t1 < t2 ? -1 : t1 > t2 ? 1 : 0;
        break;
      }
      case COMPARE_TYPE.VERSIONS:
        result = compareVersions(a, b);
        break;
      default:
        result = 0;
        break;
    }
  }

  return result;
}

export function createValueComparator(compareType = COMPARE_TYPE.CASE_INSENSITIVE, ascending = true) {
  let collator;
  if (compareType === COMPARE_TYPE.CASE_INSENSITIVE) {
    collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
  }

  const compare = (a, b) => {
    let result = collator ? collator.compare(a, b) : compareValues(a, b, compareType);
    if (!ascending) {
      result = -result;
    }

    return result;
  };

  return compare;
}

export function createObjectComparator(sortProperty, compareType = COMPARE_TYPE.CASE_INSENSITIVE, ascending = true) {
  let collator;
  if (compareType === COMPARE_TYPE.CASE_INSENSITIVE) {
    collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
  }

  const compare = (a, b) => {
    let v1, v2;
    if (typeof sortProperty === 'function') {
      v1 = sortProperty(a);
      v2 = sortProperty(b);
    } else {
      v1 = a[sortProperty];
      v2 = b[sortProperty];
    }

    let result = collator ? collator.compare(v1, v2) : compareValues(v1, v2, compareType);
    if (!ascending) {
      result = -result;
    }

    return result;
  };

  return compare;
}

export function composeComparators(comparators) {
  const compare = (a, b) => {
    let result = 0;

    for (let comparator of comparators) {
      const res = comparator(a, b);
      if (res < 0 || res > 0) {
        result = res;
        break;
      }
    }

    return result;
  };

  return compare;
}
