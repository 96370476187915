import React from 'react';
import Popup from 'components/common/popup/Popup';
import KEYS from 'core/keys';
import FlatButton from 'components/common/buttons/FlatButton';
import PropTypes from 'prop-types';
import SvgIcon from 'components/common/buttons/SvgIcon';
import { SearchInput } from 'components/common/inputs/SearchInput';

const FILTER_LIST_ICON = 'filter_list';
const emptyFunction = () => { };

export default class TextInputFilter extends React.Component {

  static propTypes = {
    textValue: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    textValue: '',
    onChange: emptyFunction
  };

  state = {
    visible: false,
  };

  togglePopup = () => {
    const visible = !this.state.visible;
    this.setState({
      visible
    })
  };

  closePopup = () => {
    this.setState({
      visible: false
    });
  };

  handleKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode === KEYS.RETURN) {
      this.closePopup()
    }
  }

  handleClearFilter = (event) => {
    const { onChange } = this.props;
    onChange(event, '');
    event.stopPropagation();
  }

  render() {
    const { visible } = this.state;
    const { textValue, onChange } = this.props;
    const active = visible ? 'active' : '';

    return (
      <div ref='textinputfilter' onClick={this.togglePopup} className={`crtx-filter-text-input ${active}`}
        title={textValue}>
        <span className='crtx-filter-text-input-content'>{textValue}</span>
        {!textValue ? <SvgIcon
          key={FILTER_LIST_ICON}
          name={FILTER_LIST_ICON}
          className='crtx-filter-text-input-filter-list-icon'
        /> :
          <FlatButton className='crtx-filter-multiselect-clear-filter-button'
            onClick={this.handleClearFilter}>×</FlatButton>}
        <Popup topOffset={-25} opener={this.refs.textinputfilter} visible={visible} onClickedOutside={this.closePopup}>
          <SearchInput onChange={onChange} value={textValue} onKeyPress={this.handleKeyPress} autoFocus={true} />
        </Popup>
      </div>
    )
  }
}