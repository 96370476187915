
define(['react', 'react-dom', 'sandbox', 'AbstractAction', 'core/services/dialogService'], 
  function(React, ReactDOM, sandbox, AbstractAction, dialogService) {

  'use strict';

  let dialogUID = 0;
      
  return AbstractAction.extend({

    isApplicable: function (objs) {
      if (!objs){
          return false;
      }
      var flowStepNwids = [];
      if (this.config && this.config.flowStepNwids && this.config.flowStepNwids.length > 0){
        flowStepNwids = this.config.flowStepNwids;
      } else {
        return true;
      }
      return true;
      // return !objs.some(function(obj){
      //                     var denyLockFlowstep = obj.denyLockFlowstep ? obj.denyLockFlowstep : [];
      //                     return denyLockFlowstep.some(function(deny){
      //                       return flowStepNwids.some(function(flowStepNwid){
      //                         return deny === flowStepNwid;
      //                       });
      //                     });
      //                   });
    },

    execute: function (objs) {
      var that = this;
      var viewInstanceNwId = this.module.nwid;
      var projectorId = this.module.projectorId;

      //for (var index = 0; index < objs.length ; index++) {
        //var obj = objs[index];
        //if (typeof obj !== 'undefined') {
          if (Array.isArray(objs)) {
            this.selectedItem = objs[0];
            this.parameters.objects = objs.map(function(item) {
              return item.nwid;
            });

          } else {
            this.selectedItem = objs;
          }
           var obj = this.selectedItem;

          var request = sandbox.request.getLockAllowedResources(viewInstanceNwId, this.nwid, this.selectedItem.nwid, this.selectedItem.type, projectorId,
            {
              config: this.config,
              type: obj["type"],
              nwid: obj["nwid"],
              parameters: this.parameters
            }
          );
        //}
      //}
      // Open Dialog
      request.then(res => {
        var code = res.statusCode;
        if (code === 200){

          // Init
          var lockedResources = res.data.lockedResources;
          var availableResources = res.data.availableResources;
          availableResources.forEach(function(resource){
            var stat = res.data.resourcesState[resource.name];
            resource.selected = (stat === "true");
            resource.grayed = (stat === "partialy");
          });

          var oldLockedResources =  lockedResources.filter(function (item) {
            return !availableResources.find(function (resource) {
              return item.nwid === resource.nwid;
            }, item);
          });
          oldLockedResources.forEach(function(resource) {
            resource.selected = true;
            availableResources.pushObject(resource);
          });

          var lockedGroupResourceNames = res.data.lockedGroupResourceNames || [];
          var availableLockedGroupResourceNames = sandbox.sorting.sort(res.data.availableGroupResourceNames, "alpha");
          var availableGroupsModel = [];
          availableLockedGroupResourceNames.forEach(function (item) {
             var groupModel = {};
             groupModel.name = item;
            var stat = res.data.groupsState[item];
            groupModel.selected = (stat === "true");
            groupModel.grayed = (stat === "partialy");
            availableGroupsModel.push(groupModel);
          });
          res.data.availableGroupResourceNames = availableGroupsModel;

          const LockSetup = require('setups/LockSetup/LockSetup').default;
          let lockSetup = new LockSetup(function(buttonName, state) {
            that.handleSetupClose(state.model);
          });
          res.data.selectedItem = that.selectedItem;
          res.data.objects = that.selectedItem;
          res.data.availableResourcesStart = sandbox.jsUtils.cloneDeep(res.data.availableResources);
          res.data.availableGroupsStart = sandbox.jsUtils.cloneDeep(res.data.availableGroupResourceNames);
          sandbox.dialog.show({
            title: sandbox.localization.translate(that.label) + " - " + that.selectedItem.label,
            modal: true,
            width: 500,
            height: 300,
            win: this.module.win,
            init: function (content, container) {
              let elementId = 'LockSetupForm-' + (dialogUID++);
              sandbox.dom.attr(content, 'id', elementId);
              sandbox.dom.css(content, 'height', '100%');
              lockSetup.domElement = content[0];
              lockSetup.container = container;
              lockSetup.initDone();
              lockSetup.firstTickReceived({
                model: res.data
            });
            }
          });
        }
      });
    },

    handleSetupClose: function (content) {
      var viewInstanceNwId = this.module.nwid;
      var projectorId = this.module.projectorId;

      var type = content.selectedItem["type"];
      var nwid = content.selectedItem["nwid"];

      // Get Selected Resources's nwid
      var selectedResources = content.availableResources.filter(function(resource){
        return (typeof(resource.selected) !== 'undefined' && resource.selected === true);
      });

      var selectedResourcesNwids = selectedResources.map(function(resource) {
        return resource.nwid;
      });

      var partialResources = content.availableResources.filter(function(resource){
        return (typeof(resource.grayed) !== 'undefined' && resource.grayed === true);
      });

      var partialResourcesNwids = partialResources.map(function(resource) {
        return resource.nwid;
      });

      var selectedGroups = content.availableGroupResourceNames.filter(function (group) {
         return group.selected;
      });

      var selectedGroupNames = selectedGroups.map(function(group) {
         return group.name;
      });

      var partialGroups =  content.availableGroupResourceNames.filter(function (group) {
        return group.grayed;
      });

      var partialGroupsNames = partialGroups.map(function(group) {
        return group.name;
      });

      var resourcesStartSate = content.availableResourcesStart;
      var groupsStartSate = content.availableGroupsStart;

      // Lock
      var request = sandbox.request.lock(viewInstanceNwId, this.nwid, nwid, type, projectorId,
        {
          config: this.config,
          type: type,
          nwid: nwid,
          allowedResources: selectedResourcesNwids,
          allowedAssignedGroupNames: selectedGroupNames,
          partialResources: partialResourcesNwids,
          partialGroups: partialGroupsNames,
          resourcesStartSate: resourcesStartSate,
          groupsStartSate: groupsStartSate,
          parameters: this.parameters
        }
      );
      request.then(function (res) {
        var code = res.statusCode;
        if (code !== 200){
          var message = res.errorMessage;
          if (res !== null){
            sandbox.dialog.alert(message);
          }
        }
      });
    }
  });
});
