/**
 * @name ToolbarComponent
 * @file Main panel toolbar component
 *
 * @author Boris
 * @since: 2017-09-04
 */

import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'components/common/buttons/FlatButton';
import ToggleFlatButton from 'components/common/buttons/ToggleFlatButton';
import Menu from 'components/common/menu/Menu';
import { getActionIcon, getGeneralSvgIconHref } from 'core/services/iconService';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';

const getIconUrl = (iconName, iconSprite = '') => {
  let iconUrl = '';
  if (iconSprite) {
    if (iconSprite === 'general') {
      iconUrl = getGeneralSvgIconHref(iconName);
    }
  } else {
    iconUrl = getActionIcon(iconName);
  }

  return iconUrl;
};

const insertSeparatorItems = (items) => {
  const result = [];
  items.forEach((item, index) => {
    if (index > 0 && index < items.length && item.groupName !== items[index - 1].groupName) {
      result.push({ itemType: 'separator' });
    }

    result.push(item);
  });

  return result;
};

const arrangeItems = (items, right) => {
  const result = items.filter(item => {
    if (right && item.alignRight || !right && !item.alignRight) {
      return true;
    }
  });

  return insertSeparatorItems(result);
};

class ToolbarComponent extends React.Component {
  static propTypes = {
    toolbarItems: PropTypes.array,
    onButtonClick: PropTypes.func,
    onPushButtonClick: PropTypes.func,
    onToggleButtonClick: PropTypes.func,
    onMenuTriggerClick: PropTypes.func,
    onMenuItemSelect: PropTypes.func
  };

  handleButtonClick(item) {
    const { onButtonClick } = this.props;
    if (typeof onButtonClick === 'function') {
      onButtonClick(item);
    }
  }

  handleToggleButtonClick(item) {
    const { onToggleButtonClick } = this.props;
    if (typeof onToggleButtonClick === 'function') {
      onToggleButtonClick(item);
    }
  }

  handlePushButtonClick(item) {
    const { onPushButtonClick } = this.props;
    if (typeof onPushButtonClick === 'function') {
      onPushButtonClick(item);
    }
  }

  handleMenuTriggerClick(item, visible) {
    const { onMenuTriggerClick } = this.props;
    if (typeof onMenuTriggerClick === 'function') {
      onMenuTriggerClick(item, visible);
    }
  }

  handleMenuItemSelect(item, menuItem, index) {
    const { onMenuItemSelect } = this.props;
    if (typeof onMenuItemSelect === 'function') {
      onMenuItemSelect(item, menuItem, index);
    }
  }

  getTooltip = item => {
    if (item.tooltipOn) {
      return item.checked ? translate(item.tooltipOn) : translate(item.tooltipOff);
    } else {
      return translate(item.tooltip);
    }
  };

  renderIcon = (iconName, iconSprite, iconStyle) => {
    const iconUrl = getIconUrl(iconName, iconSprite);

    if (iconSprite) {
      return <svg className='svg-icon-24' style={iconStyle}>
        <use xlinkHref={iconUrl} />
      </svg>;
    }

    return <img style={iconStyle} src={iconUrl} />;
  };

  renderToolbarItem = (item, index) => {
    const { itemType, iconSprite, iconStyle } = item;

    if (itemType === 'separator') {
      return <div key={'sep' + index} className='crtx-mt-separator' />;
    }

    const className = classNames('crtx-mt-button', item.className);
    const key = `${item.name}_${index}`;
    const disabled = item.disabled || !item._isApplicable;
    const tooltip = this.getTooltip(item);

    if (itemType === 'toggle') {
      const iconName = item.checked ? item.iconOn : item.iconOff;

      return (
        <FlatButton
          key={key}
          className={className}
          disabled={disabled}
          tooltip={tooltip}
          onClick={() => this.handleToggleButtonClick(item)}
        >
          {this.renderIcon(iconName, iconSprite, iconStyle)}
        </FlatButton>
      );
    } else if (itemType === 'push') {
      return (
        <ToggleFlatButton
          key={key}
          className={className}
          checked={item.checked}
          disabled={disabled}
          tooltip={tooltip}
          onClick={() => this.handlePushButtonClick(item)}
        >
          {this.renderIcon(item.icon, iconSprite, iconStyle)}
        </ToggleFlatButton>
      );
    } else if (itemType === 'menu') {
      return (
        <Menu
          key={key}
          className='crtx-mt-menu'
          items={item.menuItems}
          disabled={disabled}
          tooltip={tooltip}
          onTriggerClick={(e, visible) => this.handleMenuTriggerClick(item, visible)}
          onSelect={(e, menuItem, index) => this.handleMenuItemSelect(item, menuItem, index)}
        >
          <i className='material-icons'>{item.icon}</i>
        </Menu>
      );
    } else {
      return (
        <FlatButton
          key={key}
          className={className}
          tooltip={tooltip}
          disabled={disabled}
          onClick={() => this.handleButtonClick(item)}
        >
          {this.renderIcon(item.icon, iconSprite, iconStyle)}
        </FlatButton>
      );
    }
  };

  render() {
    const { toolbarItems } = this.props;
    const leftItems = arrangeItems(toolbarItems, false);
    const rightItems = arrangeItems(toolbarItems, true);

    return (
      <div className='crtx-mt-content'>
        <div className='crtx-mt-left'>
          {leftItems.map(this.renderToolbarItem)}
        </div>
        <div className='crtx-mt-right'>
          {rightItems.map(this.renderToolbarItem)}
        </div>
      </div>
    );
  }

}

module.exports = ToolbarComponent;