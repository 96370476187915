import React, { Component } from 'react';
import PropTypes from 'prop-types';

import sandbox from 'sandbox';

const isUndefined = o => typeof o === 'undefined';

const holdTypesImageName = {
  content: 'hold_content',
  structure: 'hold_structure',
  scheduled: 'hold_scheduled',
  partial: 'hold_partial'
};

export default class HoldIndication extends Component {
  static propTypes = {
    holdType: PropTypes.string,
    title: PropTypes.string
  };

  static defaultProps = {
    holdType: undefined,
    title: ''
  };

  getSrcByHoldType = (holdType) => {
    if (isUndefined(holdTypesImageName[holdType])) {
      return undefined;
    }

    return sandbox.icons.getGeneralIcon('', holdTypesImageName[holdType], '.svg');
  };

  render() {
    const holdType = this.props.holdType,
      src = this.getSrcByHoldType(holdType),
      title = this.props.title;

    return isUndefined(src) ? <span></span> : <img src={src} title={title} />;
  }
}