import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Canvas, { utilities, Stage, CanvasImage, Line, Rect } from 'components/common/canvas';
import AccordionItem from './AccordionItem';
import Checkbox from "components/common/inputs/Checkbox";
import MeasurementsUnit from './MeasurementsUnit';
import Header from "components/common/headers/Header";
import Radio from "components/common/inputs/Radio";
import sandbox, { localization } from 'sandbox';
import { fromServerDate } from 'core/dates';

const translate = localization.translate;
const labels = {
  navigator: translate("NAVIGATOR"),
  measurement: translate("MEASUREMENT"),
  legend: translate("LEGEND"),
  versions: translate("VERSIONS"),
  input: translate('Input'),
  referencePoint: translate('Reference point:'),
  referenceSide: translate('Reference side:'),
};

const activeVersionIcon = sandbox.icons.getModuleIcon('PageView', 'activeVersion');

const CANVAS_NAV_HEIGHT = 270;
const CANVAS_NAV_WIDTH = 250;
const VIEW_TYPE_COMPARE = 'Compare';
const VIEW_TYPE_HIRES_VIEW = 'HiresView';

const isUndefined = o => typeof o === 'undefined';
const isArray = o => Array.isArray(o);

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

const deg2rad = deg => deg * Math.PI / 180;
const rad2deg = rad => rad * 180 / Math.PI;
const compare = (a, b) => {
  return a < b ? -1 :
    a > b ? 1 :
      0;
};
const getTopLeftPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[0];
};
const getBottomRightPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[points.length - 1];
};

export default class NavigationPanel extends Component {
  static propTypes = {
    image: PropTypes.object,
    mainCanvasHeight: PropTypes.number,
    mainCanvasWidth: PropTypes.number,
    versions: PropTypes.object,
    rotation: PropTypes.number,
    zoom: PropTypes.number,
    flipHorizontal: PropTypes.bool,
    flipVertical: PropTypes.bool,
    isDistanceTool: PropTypes.bool,
    isMoveTool: PropTypes.bool,
    isDensityTool: PropTypes.bool,
    densityData: PropTypes.object,
    showMediaBoxes: PropTypes.bool,
    showPDFBoxes: PropTypes.bool,
    showVersions: PropTypes.bool,
    offsetPoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    imagePoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    mouseLastPosition: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    distanceToolPoints: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      measurementData: PropTypes.shape({
        fromX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fromY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        toX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        toY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        w: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        d: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        pw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ph: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    }),
    selectedVersionNumber: PropTypes.number,
    activeVersionNumber: PropTypes.number,
    compareVersionsNumbersSelected: PropTypes.array,
    viewType: PropTypes.string,
    onCanvasResize: PropTypes.func,
    onVersionClick: PropTypes.func,
    onCanvasMouseDown: PropTypes.func,
    onNavCanvasMouseDown: PropTypes.func,
    onDocumentMouseMove: PropTypes.func,
    onDocumentMouseUp: PropTypes.func,
    onMeasurementUnitChange: PropTypes.func,
    onMainStageLoad: PropTypes.func,
    compareData: PropTypes.object,
    onLoad: PropTypes.func,
    onChangeBoxesSelected: PropTypes.func,
    hiresNavigatorImage: PropTypes.object,
    onChangeGuidelineSelected: PropTypes.func,
    guidelines: PropTypes.array,
    showGuidelines: PropTypes.bool
  };

  static defaultProps = {
    onLoad: () => {
    }
  };

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad(this);
  }

  handleNavCanvasMouseDown = (event) => {
    this.props.onNavCanvasMouseDown(event);
  };

  handleVersionClick = (versionKey) => {

    return () => {
      const { versions } = this.props;
      const selectedVersion = versions[versionKey];

      if (!isUndefined(selectedVersion)) {
        this.props.onVersionClick(selectedVersion);//handle the action
      }
    };
  };

  handleMeasurementUnitChange = (event) => {
    this.props.onMeasurementUnitChange(event);
  };

  renderImage = () => {
    const { image, imagePoint, hiresNavigatorImage, viewType } = this.props;

    if (viewType === VIEW_TYPE_HIRES_VIEW) {
      if (hiresNavigatorImage) {
        return <CanvasImage image={hiresNavigatorImage} point={imagePoint} />;
      }
    } else if (image) {
      return <CanvasImage image={image} point={imagePoint} />;
    }
  };

  getNavZoom = () => {
    const { image, rotation, mainCanvasInstance } = this.props;
    const navZoom = this.getNavFitToContentZoom2(image);
    if (isUndefined(navZoom)) return undefined;//sometimes on reset this happens
    return navZoom;
  };

  getViewportPointsRelativeToNavImage = () => {
    let result;

    const { hiresNavigatorImage, viewportPoints, viewType } = this.props;

    if (viewType === VIEW_TYPE_HIRES_VIEW && hiresNavigatorImage) {

      const percentageViewportPoints = Object.keys(viewportPoints).reduce((acc, viewportPointKey) => {
        const p = viewportPoints[viewportPointKey];
        const { imageWidth, imageHeight } = viewportPoints;
        if (!isNaN(p?.x) && !isNaN(p?.y) && !isNaN(imageWidth) && !isNaN(imageHeight)) {
          acc[viewportPointKey] = {
            x: (p.x * 100) / imageWidth,
            y: (p.y * 100) / imageHeight
          };
        } else {
          acc[viewportPointKey] = p;
        }

        return acc;

      }, {});

      const viewportPointsRelativeToNavImage = Object.keys(percentageViewportPoints).reduce((acc, viewportPointKey) => {
        const p = percentageViewportPoints[viewportPointKey];
        const { width, height } = hiresNavigatorImage;

        if (!isNaN(p?.x) && !isNaN(p?.y) && !isNaN(width) && !isNaN(height)) {
          acc[viewportPointKey] = {
            x: (p.x * width) / 100,
            y: (p.y * height) / 100
          };
        } else {
          acc[viewportPointKey] = p;
        }

        return acc;
      }, {});

      viewportPointsRelativeToNavImage.viewportWidth = viewportPointsRelativeToNavImage.bottomRight?.x - viewportPointsRelativeToNavImage.topLeft?.x;
      viewportPointsRelativeToNavImage.viewportHeight = viewportPointsRelativeToNavImage.bottomRight?.y - viewportPointsRelativeToNavImage.topLeft?.y;

      result = viewportPointsRelativeToNavImage;

    } else {
      result = viewportPoints;
    }

    return result;
  };

  renderViewport2 = () => {
    const { image, mainCanvasInstance, hiresNavigatorImage, viewType } = this.props;
    if (!image || !mainCanvasInstance?.current.getBoundingClientRect() ||
      viewType === VIEW_TYPE_HIRES_VIEW && !hiresNavigatorImage) {
      return;
    }

    const navZoom = viewType === VIEW_TYPE_HIRES_VIEW ? this.getNavFitToContentZoom2(hiresNavigatorImage) : this.getNavFitToContentZoom2(image);
    const key = isArray(image.imageTiles) ? image.imageTiles.length : undefined;
    const { topLeft, viewportWidth, viewportHeight } = this.getViewportPointsRelativeToNavImage();

    if (topLeft && viewportWidth > 0 && viewportHeight > 0) {
      return (
        <Rect
          key={key}
          strokeStyle='red'
          lineWidth={1 / navZoom}
          point={topLeft}
          width={viewportWidth}
          height={viewportHeight}
        />
      );
    }
  };

  getNavFitToContentZoom2 = (image) => {
    const { rotation, mainCanvasInstance, hiresNavigatorImage, viewType } = this.props;

    if (isUndefined(image) || isUndefined(mainCanvasInstance)) return 1;

    const canvasNavHalfWidth = CANVAS_NAV_WIDTH / 2;
    const canvasNavHalfHeight = CANVAS_NAV_HEIGHT / 2;
    const imageHalfWidth = image.width / 2;
    const imageHalfHeight = image.height / 2;
    const canvasNavCenterPoint = {
      x: canvasNavHalfWidth,
      y: canvasNavHalfHeight
    };

    const pointA = utilities.getPointByRotation({
      x: canvasNavHalfWidth - imageHalfWidth,
      y: canvasNavHalfHeight - imageHalfHeight
    }, canvasNavCenterPoint, rotation);
    const pointB = utilities.getPointByRotation({
      x: canvasNavHalfWidth + imageHalfWidth,
      y: canvasNavHalfHeight - imageHalfHeight
    }, canvasNavCenterPoint, rotation);
    const pointC = utilities.getPointByRotation({
      x: canvasNavHalfWidth + imageHalfWidth,
      y: canvasNavHalfHeight + imageHalfHeight
    }, canvasNavCenterPoint, rotation);
    const pointD = utilities.getPointByRotation({
      x: canvasNavHalfWidth - imageHalfWidth,
      y: canvasNavHalfHeight + imageHalfHeight
    }, canvasNavCenterPoint, rotation);

    return Math.min(
      CANVAS_NAV_WIDTH / (Math.max(pointA.x, pointB.x, pointC.x, pointD.x) - Math.min(pointA.x, pointB.x, pointC.x, pointD.x)),
      CANVAS_NAV_HEIGHT / (Math.max(pointA.y, pointB.y, pointC.y, pointD.y) - Math.min(pointA.y, pointB.y, pointC.y, pointD.y))
    );
  };

  handleToggleCollapse = (bind, id) => {
    var { store } = this.props;
    store.dispatch(actions.toggleCollapse(id));
  };

  handleChangeBoxSelected = (boxIndex, overlayBoxesName) => (event, checked) => {
    const { onChangeBoxesSelected } = this.props;
    onChangeBoxesSelected(boxIndex, overlayBoxesName, checked);
  };

  renderOverlay = (box, index, overlayBoxesName) => {
    return <div key={`${box.label} ${index}`} className='crtx-overlay'>
      <div className='crtx-overlay-checkBox-container'>
        <Checkbox checked={box.isSelected} title={box.label}
          onChange={this.handleChangeBoxSelected(index, overlayBoxesName)} />
      </div>
      <div className='crtx-overlay-data-container'>

        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{box.label}</div>
          <div className='crtx-overlay-data-input'>
            <hr className='crtx-overlay-data-input-hr-line' style={{ border: `1px solid ${box.color}` }} />
          </div>
        </div>

        <div className='crtx-overlay-data-label'>X:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.x}</div>

        <div className='crtx-overlay-data-label'>Y:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.y}</div>

        <div className='crtx-overlay-data-label'>W:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.width}</div>

        <div className='crtx-overlay-data-label'>H:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.height}</div>

        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{labels.referencePoint}</div>
          <div className='crtx-overlay-data-input'>{translate(box.referencePoint)}</div>
        </div>
      </div>

    </div>;


  };

  //this renders the data to go into the navigator box
  renderOverlays = () => {
    const { overlayBoxes, showMediaBoxes, showPDFBoxes } = this.props;
    const my_key_table = this.refs.pgLegend;
    let mediaOverlayBoxes = [];
    let pdfOverlayBoxes = [];

    if (!isUndefined(my_key_table) && !isUndefined(overlayBoxes)) {
      if (!isUndefined(overlayBoxes.mediaOverlayBoxes) && showMediaBoxes) {
        mediaOverlayBoxes = overlayBoxes.mediaOverlayBoxes.map((box, index) => this.renderOverlay(box, index, 'mediaOverlayBoxes'));
      }
      if (!isUndefined(overlayBoxes.pdfOverlayBoxes) && showPDFBoxes) {
        pdfOverlayBoxes = overlayBoxes.pdfOverlayBoxes.map((box, index) => this.renderOverlay(box, index, 'pdfOverlayBoxes'));
      }
    }
    return mediaOverlayBoxes.concat(pdfOverlayBoxes);
  };

  handleChangeGuidelineSelected = guidelineName => (event, checked) => {
    const { onChangeGuidelineSelected } = this.props;
    onChangeGuidelineSelected(guidelineName, checked);
  };

  renderGuidelineData = (guideline, index) => {
    const guidelineDataObj = {};
    if (guideline.orientation === 'vertical') {
      guidelineDataObj.positionLabel = translate('X:');
      guidelineDataObj.position = guideline.displayPosition;
      guidelineDataObj.referenceSide = guideline.referenceX;
    } else {
      guidelineDataObj.positionLabel = translate('Y:');
      guidelineDataObj.position = guideline.displayPosition;
      guidelineDataObj.referenceSide = guideline.referenceY;
    }
    return <div key={`${guideline.name} ${index}`} className='crtx-overlay'>
      <div className='crtx-overlay-checkBox-container'>
        <Checkbox checked={guideline.isSelected} title={guideline.name}
          onChange={this.handleChangeGuidelineSelected(guideline.name)} />
      </div>
      <div className='crtx-overlay-data-container'>
        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{guideline.name}</div>
          <div className='crtx-overlay-data-input'>
            <hr className='crtx-overlay-data-input-hr-line' style={{ border: `1px solid ${guideline.color}` }} />
          </div>
        </div>

        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{guidelineDataObj.positionLabel}</div>
          <div className='crtx-overlay-data-input'>{guidelineDataObj.position}</div>
        </div>

        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{labels.referenceSide}</div>
          <div className='crtx-overlay-data-input'>{translate('{1}', guidelineDataObj.referenceSide)}</div>
        </div>
      </div>
    </div>;
  };

  renderGuidelinesData = () => {
    const { guidelines, showGuidelines } = this.props;
    const my_key_table = this.refs.pgLegend;
    let guidelinesDataArr = [];

    if (my_key_table && guidelines && showGuidelines) {
      guidelinesDataArr = guidelines.map((guideline, index) => this.renderGuidelineData(guideline, index));
    }
    return guidelinesDataArr;
  };

  render() {
    const {
      image,
      images,
      mainCanvasHeight,
      mainCanvasWidth,
      offsetPoint,
      rotation,
      zoom,
      flipHorizontal,
      flipVertical,
      isDistanceTool,
      isMoveTool,
      isDensityTool,
      imagePoint,
      guidelines,
      showGuidelines,
      mouseLastPosition,
      distanceToolPoints,
      measurementUnit,
      windowRef,
      mainCanvasInstance,
      versions,
      onCanvasMouseDown,
      onNavCanvasMouseDown,
      onVersionClick,
      showMediaBoxes,
      showPDFBoxes,
      showVersions,
      overlayBoxes,
      densityData,
      selectedVersionNumber,
      activeVersionNumber,
      compareVersionsNumbersSelected,
      viewType,
      hiresNavigatorImage,
      loading
    } = this.props;

    if (isUndefined(mainCanvasInstance)) return <div>
      Loading...
    </div>;

    return <div className="crtx-new-page-view-navigation-panel">
      <div className="crtx-content-accordion">
        <div className='accordion'>
          <MeasurementsUnit measurementUnit={measurementUnit}
            onChangeMeasurementUnit={this.handleMeasurementUnitChange} />
          {/* {React.Children.map(this.props.children, this.renderChild)} */}
          <AccordionItem title={labels.navigator} className='show'>
            <div ref='navigatorTab'>
              <article ref="article1">
                <Canvas ref="navCanvas"
                  width={CANVAS_NAV_WIDTH}
                  height={CANVAS_NAV_HEIGHT}
                  style={{ width: CANVAS_NAV_WIDTH, height: CANVAS_NAV_HEIGHT, marginTop: '2px' }}
                  onMouseDown={this.handleNavCanvasMouseDown}
                >
                  <Stage ref="navStage"
                    point={{ x: 0, y: 0 }}
                    rotation={rotation}
                    flipHorizontal={flipHorizontal}
                    flipVertical={flipVertical}
                    zoom={viewType === VIEW_TYPE_HIRES_VIEW ? this.getNavFitToContentZoom2(hiresNavigatorImage) : this.getNavFitToContentZoom2(image)}
                  >
                    {!loading && this.renderImage()}
                    {!loading && this.renderViewport2()}
                  </Stage>
                </Canvas>
              </article>
            </div>
          </AccordionItem>
          <AccordionItem title={labels.measurement} className='show'>
            <div ref="measurementTab">
              <article ref="article2">
                <table ref="pgDensityTable" className={isDensityTool ? 'pgDensityTable' : 'hideMe'} cellSpacing="1"
                  cellPadding="3">
                  <tbody>
                    <tr>
                      <td ref="cmyk" width="100%">
                        C: {this.props.densityData.cyan}%<br />
                        M: {this.props.densityData.magenta}%<br />
                        Y: {this.props.densityData.yellow}%<br />
                        K: {this.props.densityData.black}%<br /><br />
                        Total Ink Coverage: {this.props.densityData.totalInkCoverage}%<br />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className={(isDistanceTool || isMoveTool) ? 'pgMsTable' : 'hideMe'}>
                  <div>
                    x: {isUndefined(distanceToolPoints.measurementData.fromX) ? '' : distanceToolPoints.measurementData.fromX}
                  </div>
                  <div>
                    x: {isUndefined(distanceToolPoints.measurementData.toX) ? '' : distanceToolPoints.measurementData.toX}
                  </div>
                  <div>
                    y: {isUndefined(distanceToolPoints.measurementData.fromY) ? '' : distanceToolPoints.measurementData.fromY}
                  </div>
                  <div>
                    y: {isUndefined(distanceToolPoints.measurementData.toY) ? '' : distanceToolPoints.measurementData.toY}
                  </div>
                  <div className='row-3'>
                    w: {isUndefined(distanceToolPoints.measurementData.w) ? '' : distanceToolPoints.measurementData.w}
                  </div>
                  <div className='row-3'>
                    pw: {isUndefined(distanceToolPoints.measurementData.pw) ? '' : distanceToolPoints.measurementData.pw}
                  </div>
                  <div>
                    h: {isUndefined(distanceToolPoints.measurementData.h) ? '' : distanceToolPoints.measurementData.h}
                  </div>
                  <div>
                    ph: {isUndefined(distanceToolPoints.measurementData.ph) ? '' : distanceToolPoints.measurementData.ph}
                  </div>
                  <div>
                    d: {isUndefined(distanceToolPoints.measurementData.d) ? '' : distanceToolPoints.measurementData.d}
                  </div>
                </div>
              </article>
            </div>
          </AccordionItem>
          <AccordionItem title={labels.legend}
            className={(showMediaBoxes || showPDFBoxes || showGuidelines) ? 'show' : 'hide'}>
            <div className='crtx-legend' ref="pgLegend" width="216" cellSpacing="1" cellPadding="3"
              style={{ height: '200px' }}>
              {
                (showMediaBoxes || showPDFBoxes) ? this.renderOverlays() : showGuidelines ? this.renderGuidelinesData() : undefined
              }
            </div>
          </AccordionItem>
          <AccordionItem title={labels.versions}
            className={`accordion-item-versions-container ${(showVersions) ? 'show' : 'hide'}`}>
            <div className='crtx-versions' ref='versionsTab'>
              {
                Object.keys(versions).map((versionKey, index) => {
                  const isSelected = viewType === VIEW_TYPE_COMPARE ? compareVersionsNumbersSelected.findIndex(compareVersion => compareVersion === versions[versionKey].key) >= 0 : versions[versionKey].key === selectedVersionNumber;
                  const isActive = versions[versionKey].key === activeVersionNumber;
                  const formattedDate = localization.toLocaleShortDateTime(fromServerDate(versions[versionKey].versionData.time), true);

                  return <div className={`crtx-version-row ${isSelected ? 'selected' : ''}`} key={index}
                    onClick={this.handleVersionClick(versionKey)}>
                    <div
                      className='crtx-version-row-external-version'>{versions[versionKey].versionData.externalVersion}</div>
                    <div className='crtx-version-row-page-image-container'><img
                      className='crtx-version-row-page-image-tag' src={versions[versionKey].iconURL} /></div>
                    <div className='crtx-version-row-verdion-data'>
                      <span title={`${labels.input}:`}>{`${labels.input}:`}</span>
                      <span title={formattedDate}>{formattedDate}</span>
                      <span
                        title={versions[versionKey].versionData.inputFileName}>{versions[versionKey].versionData.inputFileName}</span>
                    </div>
                    {
                      isActive ?
                        <div className='crtx-version-row-active-icon'><img src={activeVersionIcon} /></div> : undefined
                    }
                  </div>;
                })
              }
            </div>
          </AccordionItem>
        </div>
      </div>
    </div>;
  }
}