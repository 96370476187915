import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Duration from "components/common/dropdown/Duration";

export default class extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => { }
  };

  handleChange = (event, value, prop) => {
    var { rowIndex, columnKey, onChange } = this.props;

    if (typeof this.props.onChange === 'function')
      onChange(rowIndex, columnKey, value, prop);
  };

  render() {
    var { columnData, onChange, ...props } = this.props;

    return <div className="cell">
      <div className="cell-content">
        <Duration className="Duration"
          {...props}
          value={columnData}
          onChange={this.handleChange}
        />
      </div>
    </div>;
  }
}
