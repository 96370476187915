define(['react', 'prop-types', 'widgets/ReactDataGrid/src/ReactDataGrid', 'sandbox'], function (React, PropTypes, ReactDataGrid, sandbox) {

  const { Component } = React;
  var { Table, headers, Column } = ReactDataGrid;
  var { SortableHeader } = ReactDataGrid.headers;
  var selectorNames = ["Forms", "Separations", "Presses", "Towers", "Cylinders", "Locations", "Sectors"];


  class Cell extends Component {

    constructor(props) {
      super(props);
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.columnData !== this.props.columnData;
    }

    render() {

      var { columnData } = this.props;

      return <div className="cell">
        <div className="cell-content">
          <div className="cell-content-padding">
            {columnData}
          </div>
        </div>
      </div>;

    }
  }

  class CheckBoxCell extends Component {

    static defaultProps = {
      disabled: false
    }

    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.rowIndex !== this.props.rowIndex || nextProps.columnData !== this.props.columnData;
    }

    handleChange() {
      var { rowIndex, onChange } = this.props;

      onChange(rowIndex);
    }

    render() {
      var { columnData, disabled } = this.props;
      var cellStyle = {
        width: '100%',
        height: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      };
      var checked = columnData
      // if (disabled) {
      //   checked = false;
      // }
      return <div style={cellStyle}>
        <div className="cell-content">
          <input type="checkbox" checked={checked} onChange={this.handleChange} disabled={disabled} />
        </div>
      </div>;
    }

  }

  return class OutputPlatesSetup extends Component {

    constructor(props) {
      super(props);
      this.state = {
        selectorsState: {}
      };
      this.onSelectChange = this.onSelectChange.bind(this);
      this.onSelectorsChanges = this.onSelectorsChanges.bind(this);
      this.onManualRowSelection = this.onManualRowSelection.bind(this);
      this.renderSelectorBox = this.renderSelectorBox.bind(this);
      this.renderSelectors = this.renderSelectors.bind(this);
      this.renderSelect = this.renderSelect.bind(this);
    }

    onSelectChange(ev) {
      var select = ev.target;
      var name = select.name;
      switch (name) {
        case "reason":
          this.props.actions.changeReason.call(this.props.module, select.value);
          break;
        case "resource":
          this.props.actions.changeResource.call(this.props.module, select.value);
          break;
      }
    }

    onSelectorsChanges(ev) {
      var select = ev.target;
      var name = select.name.toLowerCase();
      var selectedOptions = [].slice.call(select.selectedOptions);
      var selected = selectedOptions.map(function (option) {
        return option.value;
      });
      var selectorsState = this.state.selectorsState;
      selectorsState[name] = selected;
      this.setState({
        selectorsState: selectorsState
      });
      this.props.actions.applySelectors.call(this.props.module, selectorsState);
      select.focus();
    }

    onManualRowSelection(index) {
      this.props.actions.rowSelected.call(this.props.module, index);
    }

    renderSelectorBox(name, options) {
      return <table width="100%" className="selector-wrapper">
        <thead>
          <tr>
            <th>{sandbox.localization.translate(name)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <select name={name} size={8} className="selector" multiple onChange={this.onSelectorsChanges}>
                {options.map((opt, index) => <option key={index} className='selector-option' value={opt}>{opt === 'null' ? 'undefined' : opt}</option>)}
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    }

    renderSelectors(selectors) {
      var that = this;
      return <table className='selectors-wrapper'>
        <tbody>
          <tr>
            {selectorNames.map((selector, index) => <td key={index}>
              {that.renderSelectorBox(selector, selectors[selector.toLowerCase()])}
            </td>)}
          </tr>
        </tbody>
      </table>
    }

    renderSelect(name, options) {
      return <select name={name} className="selection-box" onChange={this.onSelectChange}>
        {options.map((opt, index) => <option key={index} value={opt['value']}>{opt['text']}</option>)}
      </select>
    }

    render() {
      var { model, actions, module } = this.props;
      var headerHeight = 35;
      var rowHeight = 29;
      let tableClass = !model.showOnlyPlates ? "plates-table" : "plates-table-full";
      return <div className='output-plates-setup'>
        {!model.showOnlyPlates ? this.renderSelectors(model.selectors) : undefined}


        <div className={tableClass}>
          <Table headerHeight={headerHeight}
            rowHeight={rowHeight}
            rows={model.rows}
            selectableRows={false}
            onRowClick={function (rowIndex, rowContent, ev) { }}
            onRowContextMenu={function (rowIndex, rowContent, ev) { }}
            columnKey='plateNwid'
            virtualScroll={false}>

            <Column columnKey="selected"
              width={50}
              align="center"
              autoWidth={true}
              header={<SortableHeader>

              </SortableHeader>}
              cell={<CheckBoxCell onChange={this.onManualRowSelection} />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey],
                  disabled: model.rows[rowIndex]["disabled"] || model.rows[rowIndex].mustBe
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />

            <Column columnKey="formName"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Form")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="separationName"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Separation")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="pressName"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Press")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="towerName"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Tower")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="cylinderName"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Cylinder")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="locationOnCylinder"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Location")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey] === 'null' ? 'undefined' : model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="cylinderSector"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Sector")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="resource"
              width={150}
              align="left"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Output Resource")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                return {
                  columnData: model.rows[rowIndex][columnKey]
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
            <Column columnKey="status"
              width={150}
              align="center"
              autoWidth={true}
              header={<SortableHeader>
                {sandbox.localization.translate("Status")}
              </SortableHeader>}
              cell={<Cell />}
              cellDataGetter={(rowIndex, columnKey) => {
                var plateState = model.rows[rowIndex][columnKey];
                var src = "";
                if (model.rows[rowIndex][columnKey] !== "NotAssigned") {
                  src = sandbox.icons.getTemplateIcon(model.rows[rowIndex][columnKey], "tiny", model.rows[rowIndex]["flowStepIconName"]);
                }
                return {
                  columnData: <img title={model.rows[rowIndex].flowStepName} src={src} />
                };
              }}
              shouldCellUpdate={(nextProps, props) => {
                return nextProps.columnData !== props.columnData;
              }}
            />
          </Table>
        </div>

        <div className="plates-table-footer">
          <span>{sandbox.localization.translate("Reasons")}: {this.renderSelect("reason", model.reasons)}</span>
          {(!model.showOnlyPlates) && (
            <span>{sandbox.localization.translate("Resources")}: {this.renderSelect("resource", model.resources)}</span>
          )}
        </div>
      </div>;
    }
  }
});