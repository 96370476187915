/**
 * @name ProgressBar Component
 * @fileOverview ProgressBar Ember Component using Kendo UI Core
 * @author Guy Behar
 */

define( ['ember', 'sandbox', 'kendo.progressbar'],
    function ( Ember, sandbox ) {
      'use strict';

      var DEFUALT_VALUES = {
            ANIMATION: false,
            MAX: 100,
            MIN: 0,
            VALUE: 0,
            ORIENTATION: 'horizontal', // can be wither 'horizontal' or 'vertical'
            REVERSE: false,
            SHOWSTATUS: false,
            TYPE: 'value'
          },
          Component;

      function createKendoProgressBar ( ) {
        var $element = this.$();

        this._progressbar = $element.kendoProgressBar({
          animation: this.get('duration') != undefined ? { duration: this.get('duration') } : ( this.get('animation') || DEFUALT_VALUES.ANIMATION ),
          max: this.get('max') || DEFUALT_VALUES.MAX,
          min: this.get('min') || DEFUALT_VALUES.MIN,
          value: this.get('value') || DEFUALT_VALUES.VALUE,
          orientation: this.get('orientation') || DEFUALT_VALUES.ORIENTATION,
          reverse: this.get('reverse') || DEFUALT_VALUES.REVERSE,
          showStatus: this.get('showStatus') || DEFUALT_VALUES.SHOWSTATUS,
          type: this.get('type') || DEFUALT_VALUES.TYPE
        }).data('kendoProgressBar');
      }
      function onValueChanged ( ) {
        if ( this._progressbar == undefined ) return;
        this._progressbar.value(this.get('value'));
      }

      Component = Ember.Component.extend({
        attributeBindings: ['style', 'class'],
        init: function(){
          this._super();
        },
        createKendoProgressBar: createKendoProgressBar.on('didInsertElement'),
        valueChanged: onValueChanged.observes('value')
      });

      Ember.Handlebars.helper('progress-bar', Component);

      return Component;
} );