/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 *
 */

define(['ember', 'text!../templates/status.hbs'], function (Ember, StatusTemplate) {
  'use strict';
  return Ember.View.extend({
    template: Ember.Handlebars.compile(StatusTemplate)
  });
});
