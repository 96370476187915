/**
 * @name ThumbnailLayers
 * @fileOverview
 * @author  guyb
 * @date    23/08/2015
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import AbstractModule from 'AbstractModule';
import BirdeyeMain from './src/birdeyemain';
// import pageDefinition from './models/pagedefinition';
// import formDefinition from './models/formdefinition';
import Controller, { DEFAULT_SCALE, MAX_SCALE, MIN_SCALE } from './controllers/controller';
import { restGet } from 'core/managers/rest2';
import { translate } from 'core/services/localization';
import { getThumbnailLayersScalePreferences } from 'core/managers/preferences';
import TickableModel from '../TickableModel';
import jsutils from 'base/jsUtils';
import { transformViewModel } from './viewModelTransforms/viewModelTranforms';
import { arrayToObject } from 'utilities/array';

const THROTTLE_WAIT = 1000;

function birdeyeProps(module, model, alignmentPoints, thumbnailsPagesDefinition, thumbnailsFormsDefinition) {
  return {
    module: module,
    model: model,
    thumbnailFormsDefinition: thumbnailsFormsDefinition,
    thumbnailPagesDefinition: thumbnailsPagesDefinition,
    alignmentPoints: alignmentPoints,
    scale: module.scale
  };
}

const getUpdatedSelectedItems = (model, selectedItems) => {
  let updatedSelected = [];
  if (model.type === 'section') {
    model.pages.forEach(page => {
      if (selectedItems[page.id]) {
        updatedSelected.push(page);
      }
    });
  } else if (model.type === 'book') {
    model.forms.forEach(form => {
      if (selectedItems[form.id]) {
        updatedSelected.push(form);
      }
    });
  } else {
    model.sections?.forEach(section => {
      section.pages.forEach(page => {
        if (selectedItems[page.id]) {
          updatedSelected.push(page);
        }
      });
    });
    model.books?.forEach(book => {
      book.forms.forEach(form => {
        if (selectedItems[form.id]) {
          updatedSelected.push(form);
        }
      });
    });
  }

  return updatedSelected;
};

function showToolbar(data) {
  const pushedButton = this.preferences.pushedFilterButton;

  this.toolbar = this.createToolbar();
  this.toolbar.addItem({
    label: 'Expand All',
    name: 'Expand All',
    _isApplicable: true,
    icon: 'expand',
    tooltip: translate('Expand All'),
    execute: this.ctrl.expandAllViews.bind(this.ctrl)
  });
  this.toolbar.addItem({
    label: 'Collapse All',
    name: 'Collapse All',
    _isApplicable: true,
    icon: 'collapse',
    tooltip: translate('Collapse All'),
    execute: this.ctrl.collapseAllViews.bind(this.ctrl)
  });
  this.toolbar.addItem({
    label: 'Missing Pages',
    name: 'missing',
    checked: pushedButton === 'missing',
    _isApplicable: true,
    icon: 'missing_pages',
    tooltip: this.ctrl.getMissingTooltip('pages'),
    itemType: 'push',
    groupName: 'pageState',
    execute: this.filterButtonClick.bind(this)
  });
  this.toolbar.addItem({
    label: 'Error Pages',
    name: 'error',
    checked: pushedButton === 'error',
    _isApplicable: true,
    icon: 'error_pages',
    tooltip: this.ctrl.getErrorTooltip('pages'),
    itemType: 'push',
    groupName: 'pageState',
    execute: this.filterButtonClick.bind(this)
  });
  this.toolbar.addItem({
    label: 'Waiting For Approval',
    name: 'waiting_for_approval',
    checked: pushedButton === 'waiting_for_approval',
    _isApplicable: true,
    icon: 'waiting_for_approval',
    tooltip: translate('Show Waiting For Approval'),
    itemType: 'push',
    groupName: 'pageState',
    execute: this.filterButtonClick.bind(this)
  });
  if (this.ctrl.hasBooks(data.model) || this.ctrl.isBook(data.model)) {
    this.toolbar.addItem({
      label: 'Expected',
      name: 'actual',
      checked: pushedButton === 'actual',
      _isApplicable: true,
      icon: 'actual_plates',
      tooltip: translate('Show Formes With Expected Plates Only'),
      itemType: 'push',
      groupName: 'pageState',
      execute: this.filterButtonClick.bind(this)
    });
  }
  if (data.model.type !== 'edition') {
    this.toolbar.addItem({
      label: 'Local Pages',
      name: 'local_pages',
      checked: pushedButton === 'local_pages',
      _isApplicable: true,
      icon: 'local_pages',
      tooltip: translate('Show Local Pages Only'),
      itemType: 'push',
      groupName: 'pageState',
      execute: this.filterButtonClick.bind(this)
    });
  }
}

function showToolbarGroupZoneViewType(model) {
  if (!this.ctrl.hasBooks(model) || !this.ctrl.hasSections(model)) {
    return;
  }

  const pushedButton = this.preferences.pushedViewButton;

  this.toolbar.addItem({
    label: 'Show sections',
    name: 'show_sections',
    checked: pushedButton === 'show_sections',
    _isApplicable: true,
    icon: 'sections',
    tooltip: translate('Show sections'),
    itemType: 'push',
    groupName: 'showZoneViewType',
    execute: this.viewButtonClick.bind(this)
  });
  this.toolbar.addItem({
    label: 'Show books',
    name: 'show_books',
    checked: pushedButton === 'show_books',
    _isApplicable: true,
    icon: 'books',
    tooltip: translate('Show books'),
    itemType: 'push',
    groupName: 'showZoneViewType',
    execute: this.viewButtonClick.bind(this)
  });
}

export default AbstractModule.extend({
  allowStandbyMode: true,
  init: function () {
    var that = this;

    this._super();
    this.scale = getThumbnailLayersScalePreferences() || DEFAULT_SCALE;
    this.selectedItems = {};
    this.ctrl = new Controller(this);
    this.pagesWaitingForPairedUpdate = {};
    this.shouldShowRelatedItemsDialog = false;
    this.itemsToRenderInDialog = [];
    this.aggregatedItem = {};
    this.viewModel = {};
    this.handleClose = this.handleClose.bind(this);
    this.tickableModel = new TickableModel({
      markDirty: true,
      onBeforeUpdate: function (model, newModel) {
        if (typeof newModel.virtual !== 'undefined') {
          return that.ctrl.handleVirtualUpdate(model, newModel);
        }
      },
      onAction: function (action, parent, model) {

        if (action === 'update') {
          var viewConfiguration = that.tickViewConfiguration;
          if (model && model.type === 'page') {
            if (typeof viewConfiguration !== 'undefined') {
              let pageNumber = viewConfiguration.numberMode === 'zone' ? model.numberInZone : viewConfiguration.numberMode === 'section' ? model.numberInSection : '';
              let pageName = model.label;
              let label = pageNumber;
              if (viewConfiguration.showName) {
                label = label + '.' + pageName;
              }
              model.label = label;
            }
          }
        }
      },
      onObjectParsed: function (model) {
        var viewConfiguration = that.tickViewConfiguration;
        if (model && model.type === 'page') {
          if (typeof viewConfiguration !== 'undefined') {
            let pageNumber = viewConfiguration.numberMode === 'zone' ? model.numberInZone : viewConfiguration.numberMode === 'section' ? model.numberInSection : '';
            let pageName = model.label;
            let label = pageNumber;
            if (viewConfiguration.showName) {
              label = label + '.' + pageName;
            }
            model.label = label;
          }
        }
      }
    });
    this.alignmentPoints = {};
    this.thumbnailsPagesDefinition = {};
    this.thumbnailsFormsDefinition = {};
    this.registerToThumbnailLayersScaleUpdate();
  },
  initDone: function () {
    restGet(this.nwid, 'conversion-tables/Approvers/data')
      .then(approversData => this.approvers = approversData);

    this.ctrl.keyDownHandler(this.element);
    this.reactRoot = createRoot(this.domElement);
    this.tab && this.tab.registerDeselectHandler(this.handleClose);
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },
  destroy: function () {
    this._super();
    this.handleClose();
    this.ctrl.removeKeyDownHandler(this.element);
    this.reactRoot.unmount();
  },
  registerToThumbnailLayersScaleUpdate: function () {
    sandbox.pubsub.subscribe('thumbnailLayersScale', (scale) => {
      this.ctrl.setScale(scale, false);
    });
  },

  updateZoomButtonsDisabledState: function () {
    this.toolbar.setItemDisabled('zoomout', this.scale <= MIN_SCALE);
    this.toolbar.setItemDisabled('zoomin', this.scale >= MAX_SCALE);
  },

  showFooter: function (model) {
    const { planImportFileName, planImportTime } = model;
    if (planImportFileName && planImportTime) {
      const footer = this.createFooter();

      footer.addPlanImportInfo(planImportFileName, planImportTime);
    }
  },

  firstTickReceived: function (data) {
    this.alignmentPoints = data.config.alignmentPoints;
    this.thumbnailsPagesDefinition = data.config.pageDefinition;
    this.thumbnailsFormsDefinition = data.config.formDefinition;
    this.tickViewConfiguration = data.model.viewConfiguration;
    this.tickableModel.firstTickHandler(data.model);
    if (data.model.type === 'edition') {
      this.aggregateMode = data.preferences.aggregateMode || false;
    }
    const transformedModel = transformViewModel(this.tickableModel.model(), this.ctrl, this.aggregateMode, this.aggregatedItem, this.thumbnailsPagesDefinition, this.thumbnailsFormsDefinition);
    this.viewModel = transformedModel;
    if (typeof this.viewModel.showLinksOnlyForChase === 'undefined') {
      this.viewModel.showLinksOnlyForChase = this.viewModel.sections?.[0]?.showLinksOnlyForChase;
    }
    this.itemsToRenderInDialog = transformedModel.itemsToRenderInDialog || [];

    this.preferences = data.preferences || {};

    showToolbar.call(this, data);
    this.showFooter(data.model);
    showToolbarGroupZoneViewType.call(this, this.viewModel);

    this.toolbar.addItem({
      label: 'Hide Thumbnails',
      name: 'hide_thumbnails',
      checked: data.preferences.hideThumbnails === true,
      _isApplicable: true,
      icon: 'hide_thumbnails',
      tooltip: translate('Hide Thumbnails'),
      itemType: 'push',
      execute: this.hideThumbnailsButtonClick.bind(this)
    });
    this.toolbar.addItem({
      label: 'zoomout',
      name: 'zoomout',
      action: 'zoomout',
      _isApplicable: true,
      icon: 'zoom_out',
      iconSprite: 'general',
      tooltip: translate('Zoom Out'),
      groupName: 'zoom',
      execute: () => {
        this.ctrl.zoomOut();
        this.updateZoomButtonsDisabledState();
      }
    });

    this.toolbar.addItem({
      label: 'zoomin',
      name: 'zoomin',
      action: 'zoomin',
      _isApplicable: true,
      icon: 'zoom_in',
      iconSprite: 'general',
      tooltip: translate('Zoom In'),
      groupName: 'zoom',
      execute: () => {
        this.ctrl.zoomIn();
        this.updateZoomButtonsDisabledState();
      }
    });

    if (data.model.type === 'edition') {
      this.toolbar.addItem({
        label: translate('Aggregate Mode'),
        name: 'aggregateMode',
        _isApplicable: true,
        icon: 'aggregate',
        iconSprite: 'general',
        itemType: 'push',
        checked: data.preferences.aggregateMode,
        execute: this.toggleAggregateMode.bind(this)
      });
    }

    this.updateZoomButtonsDisabledState();

    if (this.preferences.pushedFilterButton) {
      this.ctrl.applyFilter.call(this.ctrl, true, this.preferences.pushedFilterButton);
    }

    if (this.preferences.pushedViewButton) {
      this.ctrl.applyFilterZoneViewType.call(this.ctrl, true, this.preferences.pushedViewButton);
    }

    if (this.preferences.hideThumbnails) {
      this.ctrl.applyShowOnlyPagesInfo.call(this.ctrl, true);
    }
    this.render();
  },

  toggleAggregateMode: function (pushed) {

    this.aggregateMode = pushed;

    this.toolbar.setItemChecked('aggregateMode', pushed);

    const transformedModel = transformViewModel(this.tickableModel.model(), this.ctrl, this.aggregateMode, this.aggregatedItem, this.thumbnailsPagesDefinition, this.thumbnailsFormsDefinition);
    this.viewModel = transformedModel;
    this.itemsToRenderInDialog = transformedModel.itemsToRenderInDialog || [];

    if (!pushed) {
      this.shouldShowRelatedItemsDialog = false;
      this.aggregatedItem = {};
      this.itemsToRenderInDialog = [];
    }
    this.render();

    this.savePreferences({ aggregateMode: pushed });
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
    this.showFooter(data.model[0]);
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    const transformedModel = transformViewModel(this.tickableModel.model(), this.ctrl, this.aggregateMode, this.aggregatedItem, this.thumbnailsPagesDefinition, this.thumbnailsFormsDefinition);
    this.viewModel = transformedModel;
    const updatedSelected = getUpdatedSelectedItems(this.viewModel, this.selectedItems);
    this.selectedItems = arrayToObject(updatedSelected, 'id');
    this.updateSelected(updatedSelected);
    this.itemsToRenderInDialog = transformedModel.itemsToRenderInDialog || [];
    this.render();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  handleAggregatedItemClick: function (aggregatedItem) {
    this.shouldShowRelatedItemsDialog = this.aggregatedItem.relatedPages?.[0].nwid === aggregatedItem.relatedPages?.[0].nwid ? !this.shouldShowRelatedItemsDialog : true;
    this.aggregatedItem = aggregatedItem;
    this.itemsToRenderInDialog = aggregatedItem.relatedPages;
    this.render();
  },

  handleClose: function () {
    this.shouldShowRelatedItemsDialog = false;
    this.aggregatedItem = {};
    this.itemsToRenderInDialog = [];
    this.render();
  },

  filterButtonClick: function (pushed, buttonName) {
    this.ctrl.applyFilter.call(this.ctrl, pushed, buttonName);
    this.render();

    this.savePreferences({ pushedFilterButton: pushed ? buttonName : '' });
  },

  viewButtonClick: function (pushed, buttonName) {
    this.ctrl.applyFilterZoneViewType.call(this.ctrl, pushed, buttonName);
    this.render();

    this.savePreferences({ pushedViewButton: pushed ? buttonName : '' });
  },

  hideThumbnailsButtonClick: function (pushed) {
    this.ctrl.applyShowOnlyPagesInfo.call(this.ctrl, pushed);
    this.render();

    this.savePreferences({ hideThumbnails: pushed });
  },

  render: function () {
    var birdeyeMain = React.createElement(BirdeyeMain, birdeyeProps(this, this.viewModel,
      this.alignmentPoints,
      this.thumbnailsPagesDefinition,
      this.thumbnailsFormsDefinition));

    this.reactRoot.render(birdeyeMain);
    setTimeout(() => this.tickableModel.clean(this.tickableModel.model()), 0);
  }
});