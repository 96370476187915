import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Canvas, { Stage, Line, Rect, Cross, Text } from 'components/common/canvas';
import NavigationPanel from './navigation';
import { AutoSizer } from 'react-virtualized';
import localization, { translate } from 'core/services/localization';
import Loader from "components/common/loader/Loader";
import { selectCursorStyle } from '../reducers/selectors';
import { fromServerDate } from 'core/dates';

const labels = {
  version: translate('Version'),
  input: translate('Input'),
  active: translate('Active'),
  notActive: translate('Not Active'),
  versionInformation: translate('Version Information'),
  pleaseSelect2VersionsToCompare: translate('Please select 2 versions to compare'),
  comparingVersions: translate('Comparing Versions')
};
const CANVAS_NAV_WIDTH = 250;
const VIEW_TYPE_COMPARE = 'Compare';
const VIEW_TYPE_PAGE_VIEW = 'PageView';
const VIEW_TYPE_FORM_VIEW = 'FormView';
const VIEW_TYPE_HIRES_VIEW = 'HiresView';

const deg2rad = deg => deg * Math.PI / 180;
const rad2deg = rad => rad * 180 / Math.PI;
const compare = (a, b) => {
  return a < b ? -1 :
    a > b ? 1 :
      0;
};
const getTopLeftPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[0];
};
const getBottomRightPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[points.length - 1];
};

const isUndefined = o => typeof o === 'undefined';

export default class PageView extends Component {

  static propTypes = {
    image: PropTypes.object,
    mainCanvasHeight: PropTypes.number,
    mainCanvasWidth: PropTypes.number,
    showNavigator: PropTypes.bool,
    versions: PropTypes.object,
    rotation: PropTypes.number,
    zoom: PropTypes.number,
    flipHorizontal: PropTypes.bool,
    flipVertical: PropTypes.bool,
    isDistanceTool: PropTypes.bool,
    isMoveTool: PropTypes.bool,
    isDensityTool: PropTypes.bool,
    densityData: PropTypes.object,
    showMediaBoxes: PropTypes.bool,
    showPDFBoxes: PropTypes.bool,
    offsetPoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    imagePoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    mouseLastPosition: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    distanceToolPoints: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      measurementData: PropTypes.shape({
        fromX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        fromY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        toX: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        toY: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        w: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        d: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        pw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        ph: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    }),
    onCanvasResize: PropTypes.func,
    onVersionClick: PropTypes.func,
    onCanvasMouseDown: PropTypes.func,
    onNavCanvasMouseDown: PropTypes.func,
    onDocumentMouseMove: PropTypes.func,
    onDocumentMouseUp: PropTypes.func,
    onMeasurementUnitChange: PropTypes.func,
    onMainStageLoad: PropTypes.func,
    compareData: PropTypes.object,

    activeVersionNumber: PropTypes.number,
    selectedVersionNumber: PropTypes.number,
    isHold: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    compareVersionsNumbersSelected: PropTypes.array,
    viewType: PropTypes.string,
    loading: PropTypes.bool,
    onChangeBoxesSelected: PropTypes.func,
    renderCanvasImage: PropTypes.func,
    hiresNavigatorImage: PropTypes.object,
    onChangeGuidelineSelected: PropTypes.func,
    guidelines: PropTypes.array,
    showGuidelines: PropTypes.bool,
    showPageLabels: PropTypes.bool,
  };

  canvasRef = React.createRef();

  setVersionBorderColor = (versionClassNameObj = {}, colorName) => {
    versionClassNameObj.topPageLabel = `topPageLabel ${colorName}Background`;
    versionClassNameObj.topPageDiv = `topPageDiv ${colorName}Background`;
    versionClassNameObj.canvas = `pgViewCanvas ${colorName}Border`;
  };

  getVersionBorderClassName = () => {
    const {
      versions,
      activeVersionNumber,
      selectedVersionNumber,
      isHold,
      viewType,
      compareVersionsNumbersSelected,
      togglingBetweenCompareVersionsIndex
    } = this.props;

    let versionClassName = {
      topPageLabel: 'topPageLabel grayBackground',
      topPageDiv: 'topPageDiv grayBackground',
      canvas: 'pgViewCanvas grayBorder',
      labelText: `${labels.versionInformation}.`,
      key: undefined
    };

    if (viewType === VIEW_TYPE_PAGE_VIEW || viewType === VIEW_TYPE_FORM_VIEW) {
      let selectedVersion = versions[selectedVersionNumber];
      versionClassName.key = !isUndefined(selectedVersion) ? selectedVersion.key : undefined;

      if (!isUndefined(selectedVersion) && !isUndefined(selectedVersion.versionData)) {
        const isActive = activeVersionNumber === selectedVersion.key;

        versionClassName.labelText = `${labels.version}: ${selectedVersion.versionData.versionNumber}
        ${labels.input}: ${localization.toLocaleShortDateTime(fromServerDate(selectedVersion.versionData.time), true)} 
        ${selectedVersion.versionData.inputFileName}
         **${isActive ? labels.active : labels.notActive}**`;

        if (isActive) {
          if (isHold) this.setVersionBorderColor(versionClassName, 'yellow');
          else this.setVersionBorderColor(versionClassName, 'gray');
        } else this.setVersionBorderColor(versionClassName, 'red');
      }
    } else if (viewType === VIEW_TYPE_COMPARE) {
      if (togglingBetweenCompareVersionsIndex === -1) versionClassName.labelText = compareVersionsNumbersSelected.length === 0 ? labels.pleaseSelect2VersionsToCompare : `${labels.comparingVersions}: ${compareVersionsNumbersSelected.join(' : ')}`;
      else {
        const versionToShow = versions[compareVersionsNumbersSelected[togglingBetweenCompareVersionsIndex]];
        versionClassName.labelText = !isUndefined(versionToShow) ? `${labels.version}: ${versionToShow.key}` : labels.pleaseSelect2VersionsToCompare;
      }
      this.setVersionBorderColor(versionClassName, 'blue');
    } else if (viewType === VIEW_TYPE_HIRES_VIEW) {
      versionClassName.labelText = '';
    }
    return versionClassName;
  };

  handleCanvasResize = ({ width, height }) => {
    // const topDivHeight = this.refs.topPageDiv.height;
    this.props.onCanvasResize({ width, height });
  };

  handleCanvasMouseDown = (event) => {
    this.props.onCanvasMouseDown(event);
  };

  handleCanvasMouseWheel = (event) => {
    this.props.onMouseWheel(event);
  };

  handleMainStageLoad = (instance) => {
    this.props.onMainStageLoad(instance);
    this.mainStageInstance = instance;
  };

  handleMainCanvasLoad = () => {
    this.props.onMainCanvasLoad(this.canvasRef);
    this.mainCanvasInstance = this.canvasRef;
  };

  handleNavCanvasLoad = (instance) => {
    this.props.onNavCanvasLoad(instance);
    this.navCanvasInstance = instance;
  };

  renderImage = () => {
    const { renderCanvasImage } = this.props;
    return renderCanvasImage();
  };

  renderDistanceLine = () => {
    const {
      windowRef, distanceToolPoints, mouseLastPosition, isDistanceTool, isMoveTool,
      isDensityTool, zoom, images, rotation, mainCanvasHeight, mainCanvasWidth,
      flipHorizontal, flipVertical, offsetPoint, mouseDensityPosition
    } = this.props;

    if (isMoveTool) {
      return undefined;
    } else if (isDistanceTool) {
      if (isUndefined(distanceToolPoints.point)) return undefined;
      return <Line point={distanceToolPoints.point} pointTo={distanceToolPoints.pointTo} strokeStyle="red"
        lineWidth={1 / zoom} />;
    } else if (isDensityTool) {
      return <Cross point={{ x: (mouseDensityPosition.x), y: (mouseDensityPosition.y) }}
        pointTo={{ x: (mouseDensityPosition.x), y: (mouseDensityPosition.y) }} strokeStyle="red"
        lineWidth={1 / zoom} />;
    }

  };

  drawOverlay = (box, boxType, index) => {
    if (!box.isSelected) {
      return;
    }

    return <Rect
      key={boxType + index}
      point={{ x: box.calcLeft, y: box.calcTop }}
      width={box.calWidth} height={box.calcHeight}
      label={boxType === 'pdfOverlayBoxes' ? box.label : undefined}
      strokeStyle={box.color} fillStyle={box.fill ? box.color : undefined}
      fillAlpha={0.5} strokeAlpha={1} lineWidth={2}
    />;
  };

  //This renders the rectangle(s) on the stage
  renderOverlays = () => {
    const { overlayBoxes, showMediaBoxes, showPDFBoxes } = this.props;
    let mediaOverlayBoxes = [];
    let pdfOverlayBoxes = [];

    if (!isUndefined(overlayBoxes)) {
      if (!isUndefined(overlayBoxes.mediaOverlayBoxes) && showMediaBoxes) {
        mediaOverlayBoxes = overlayBoxes.mediaOverlayBoxes.map((box, index) => this.drawOverlay(box, 'mediaOverlayBoxes', index));
      }
      if (!isUndefined(overlayBoxes.pdfOverlayBoxes) && showPDFBoxes) {
        pdfOverlayBoxes = overlayBoxes.pdfOverlayBoxes.map((box, index) => this.drawOverlay(box, 'pdfOverlayBoxes', index));
      }
    }
    return mediaOverlayBoxes.concat(pdfOverlayBoxes);
  };

  drawGuideline = (guideline, zoom, index) => {
    const { point, pointTo, isSelected } = guideline;
    if (!isSelected) {
      return;
    }

    return <Line key={index} point={point} pointTo={pointTo} strokeStyle={guideline.color} lineWidth={1 / zoom} />;
  };

  renderGuidelines = () => {
    const { guidelines, showGuidelines, zoom } = this.props;
    let drawGuidelineArr = [];

    if (guidelines && showGuidelines) {
      drawGuidelineArr = guidelines.map((guideline, index) => this.drawGuideline(guideline, zoom, index));
    }
    return drawGuidelineArr;
  };

  drawPageLabel = (cell, index) => {
    return <Text key={index} {...cell} />;
  };

  renderPageLabels = () => {
    const { gridCells, showPageLabels } = this.props;
    if (!showPageLabels) {
      return null;
    }

    return gridCells.map(this.drawPageLabel);
  };

  componentDidUpdate(prevProps) {
    const { viewportPoints, onUpdateTilesInViewport } = this.props;
    if (Object.keys(viewportPoints).length > 0 && JSON.stringify(prevProps.viewportPoints) !== JSON.stringify(viewportPoints)) {
      onUpdateTilesInViewport(viewportPoints);
    }
  }

  render() {
    const {
      image,
      images,
      mainCanvasHeight,
      mainCanvasWidth,
      offsetPoint,
      rotation,
      zoom,
      flipHorizontal,
      flipVertical,
      showNavigator,
      isDistanceTool,
      isMoveTool,
      isDensityTool,
      imagePoint,
      showMediaBoxes,
      showPDFBoxes,
      showVersions,
      overlayBoxes,
      mouseLastPosition,
      distanceToolPoints,
      measurementUnit,
      windowRef,
      compareData,
      mainCanvasInstance,
      densityData,
      onChangeGuidelineSelected,
      onCanvasMouseDown,
      onNavCanvasMouseDown,
      onMouseWheel,
      showGuidelines,
      guidelines,
      gridCells,
      onVersionClick,
      onMeasurementUnitChange,
      versions,
      selectedVersionNumber,
      activeVersionNumber,
      compareVersionsNumbersSelected,
      viewType,
      loading,
      onChangeBoxesSelected,
      viewportPoints,
      hiresNavigatorImage,
      missingPage
    } = this.props;

    const versionStyleData = this.getVersionBorderClassName();

    return <div className="crtx-new-page-view">
      <div className='new-page-view-top-div' style={selectCursorStyle(this.props)}>
        <AutoSizer ref='autoSizerMainCanvas' onResize={this.handleCanvasResize}>
          {({ width, height }) => ([
            <div key='label' ref='topPageDiv' className={versionStyleData.topPageDiv}
              style={{ width: mainCanvasWidth }}>
              <Loader loading={loading} />
              <div>
                <label ref='topPageLabel' htmlFor='topPageDiv' id={versionStyleData.key}
                  className={versionStyleData.topPageLabel}>
                  {versionStyleData.labelText}
                </label>
              </div>
            </div>
            ,
            <Canvas
              key='canvas'
              ref={this.canvasRef}
              width={mainCanvasWidth}
              height={mainCanvasHeight}
              style={{ width: mainCanvasWidth, height: mainCanvasHeight }}
              className={versionStyleData.canvas}
              onMouseDown={this.handleCanvasMouseDown}
              onLoad={this.handleMainCanvasLoad}
              onMouseWheel={this.handleCanvasMouseWheel}
            >
              <Stage
                ref="stage"
                point={offsetPoint}
                rotation={rotation}
                zoom={zoom}
                flipHorizontal={flipHorizontal}
                flipVertical={flipVertical}
                onLoad={this.handleMainStageLoad}
              >
                {!loading && this.renderImage()}
                {!loading && this.renderDistanceLine()}
                {!loading && this.renderOverlays()}
                {!loading && this.renderGuidelines()}
                {!loading && this.renderPageLabels()}
              </Stage>
            </Canvas>
          ]
          )}
        </AutoSizer>
      </div>

      {showNavigator && <div style={{ position: "relative", width: CANVAS_NAV_WIDTH }}>
        <NavigationPanel
          windowRef={windowRef}
          image={image}
          images={images}
          imagePoint={imagePoint}
          mouseLastPosition={mouseLastPosition}
          distanceToolPoints={distanceToolPoints}
          isDistanceTool={isDistanceTool}
          isMoveTool={isMoveTool}
          isDensityTool={isDensityTool}
          densityData={densityData}
          mainCanvasHeight={mainCanvasHeight}
          mainCanvasWidth={mainCanvasWidth}
          offsetPoint={offsetPoint}
          rotation={rotation}
          zoom={zoom}
          flipHorizontal={flipHorizontal}
          flipVertical={flipVertical}
          measurementUnit={measurementUnit}
          mainCanvasInstance={this.mainCanvasInstance}
          onCanvasMouseDown={onCanvasMouseDown}
          onNavCanvasMouseDown={onNavCanvasMouseDown}
          onMeasurementUnitChange={onMeasurementUnitChange}
          onVersionClick={onVersionClick}
          versions={versions}
          selectedVersionNumber={selectedVersionNumber}
          activeVersionNumber={activeVersionNumber}
          compareVersionsNumbersSelected={compareVersionsNumbersSelected}
          viewType={viewType}
          showMediaBoxes={showMediaBoxes}
          showGuidelines={showGuidelines}
          showPDFBoxes={showPDFBoxes}
          showVersions={showVersions}
          overlayBoxes={overlayBoxes}
          guidelines={guidelines}
          onLoad={this.handleNavCanvasLoad}
          onChangeBoxesSelected={onChangeBoxesSelected}
          onChangeGuidelineSelected={onChangeGuidelineSelected}
          viewportPoints={viewportPoints}
          hiresNavigatorImage={hiresNavigatorImage}
          loading={loading}
        />
      </div>
      }

    </div>;
  }
}