define([], function () {
  const COLORS = {
    COMPLETED: '#85ec85',
    WAITING_FOR_APPROVAL: '#5eced5',
    ERROR: '#ff4437',
    WARNING_IN_PREFLIGHT: '#ff7f50', // coral
  };

  return {
    "background-progress": function (value, model) {
      if (this.isCompleted(model)) {
        return COLORS.COMPLETED;
      }
      if (this.isError(model)) {
        return COLORS.ERROR;
      }
      if (this.isWarningInPreflight(model)) {
        return COLORS.WARNING_IN_PREFLIGHT;
      }

      if (this.isWaitingForApproval(model)) {
        return COLORS.WAITING_FOR_APPROVAL;
      }

      return undefined;
    }
  };
});