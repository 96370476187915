/**
 * @name NavigatorComponent
 * @file Main panel navigation bar component
 *
 * @author Boris
 * @since: 2017-09-12
 */

import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'components/common/buttons/FlatButton';
import localization from 'core/services/localization';
import iconService from 'core/services/iconService';
import Breadcrumbs from './Breadcrumbs';
import Menu from 'components/common/menu/Menu';
import Dropdown from 'components/common/dropdown/Dropdown';
import { measureMaxTextWidth } from '../../utilities/measurement';
import Checkbox from 'components/common/inputs/Checkbox';
import ToggleFlatButton from 'components/common/buttons/ToggleFlatButton';
import SvgIcon from 'components/common/buttons/SvgIcon';

const { translate } = localization;

const DROPDOWN_EMPTY_TEXT_WIDTH = 90;

const isFilterActive = filters => filters.some(filter => filter.active);

class NavigatorComponent extends React.Component {
  static propTypes = {
    buttons: PropTypes.array
  };

  handleHelpClick = () => {
    const { onHelpClick } = this.props;
    if (typeof onHelpClick === 'function') {
      onHelpClick();
    }
  };

  handleViewLinkClick = (viewLink, event) => {
    const { onViewLinkClick } = this.props;
    if (typeof onViewLinkClick === 'function') {
      onViewLinkClick(viewLink, event);
    }
  };

  handlePreviousClick = (event) => {
    const { onPreviousClick } = this.props;
    if (typeof onPreviousClick === 'function') {
      onPreviousClick(event);
    }
  };

  handleNextClick = (event) => {
    const { onNextClick } = this.props;
    if (typeof onNextClick === 'function') {
      onNextClick(event);
    }
  };

  handleMenuItemSelect = (group, itemIndex, event) => {
    this.handleViewLinkClick(group.links[itemIndex], event);
  };

  renderViewLinksGroup = (group, index) => {
    const { navigationDisabled } = this.props;

    if (group.links.length > 1) {
      const label = group.label;
      const icon = group.icon.toLowerCase();

      return (
        <Menu
          key={index}
          className='crtx-mn-menu'
          disabled={navigationDisabled}
          items={group.links.map(item => ({
            text: translate(item.label),
            iconUrl: iconService.getGeneralIcon('navigator', item.icon)
          }))}
          tooltip={translate(label)}
          onSelect={(e, item, itemIndex) => this.handleMenuItemSelect(group, itemIndex, e)}
        >
          <img src={iconService.getGeneralIcon('navigator', icon)} />
          <div className='crtx-menu-arrow-right' />
        </Menu>
      );
    } else {
      const viewLink = group.links[0];
      const iconName = viewLink.icon;// || viewLink.label;

      return (
        <FlatButton
          key={index}
          className={'crtx-mn-view-link-button'}
          disabled={navigationDisabled}
          tooltip={translate(viewLink.label)}
          onClick={(e) => this.handleViewLinkClick(viewLink, e)}
        >
          <img src={iconService.getGeneralIcon('navigator', iconName)} />
        </FlatButton>
      );
    }
  };

  renderViewLinksGroups() {
    const { viewLinksGroups } = this.props;

    return (
      <div className={'crtx-mn-view-links'}>
        {viewLinksGroups.map(this.renderViewLinksGroup)}
      </div>
    );
  }

  renderNavigationOption(option) {
    return (
      <option key={option.id} value={option.id}>{option.label}</option>
    );
  }

  handleFilterClick = (e, checked, name) => {
    const { onFilterClick } = this.props;
    if (typeof onFilterClick === 'function') {
      onFilterClick(e, checked, name);
    }
  }

  handleNavigationOptionSelect = (e, value, index) => {
    const { onNavigationOptionSelect } = this.props;
    if (typeof onNavigationOptionSelect === 'function') {
      onNavigationOptionSelect(index);
    }
  };

  getNavigatorOptionContentGetter = (option, index) => {
    if (typeof option === 'undefined') {
      return;
    }

    const colorIcon = option.filterData && typeof option.filterData.colored !== 'undefined' ? option.filterData.colored ? 'cmyk' : 'bw' : undefined;
    const contentIcon = option.filterData && typeof option.filterData.content !== 'undefined' ? option.filterData.content ? 'image' : 'no_image' : undefined;

    return <div className='crtx-mn-dropdown-option'>
      <label>{option.label}</label>
      {colorIcon && <SvgIcon name={colorIcon} tooltip={translate('Colored')} className='crtx-size-18' />}
      {contentIcon && <SvgIcon name={contentIcon} tooltip={translate('Low Res')} className='crtx-size-18' />}
    </div>;
  };

  menuItemGetter = (item, index) => {
    return (
      <div className='crtx-menu-item' title={item.displayName}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox checked={item.active} />
          <label className='crtx-menu-item-text'>{translate(item.displayName)}</label>
        </div>
      </div>
    )
  }

  renderNavigationControls() {
    const { navigationOptions, selectedNavigationIndex, navigationDisabled, filters } = this.props;
    if (!navigationOptions || navigationOptions.length <= 0) {
      return null;
    }

    const selectedOption = navigationOptions[selectedNavigationIndex];
    const selectedValue = selectedOption ? selectedOption.label : '';
    const labels = navigationOptions.map(op => op.label);
    const dropdownWidth = measureMaxTextWidth(labels) + DROPDOWN_EMPTY_TEXT_WIDTH;
    // const active = isFilterActive(filters) ? 'active' : '';

    return (
      <div className='crtx-mn-controls'>
        <div className='crtx-mn-separator' />
        {filters.length > 0 &&
          <Menu
            className='crtx-mn-navigation-filters-menu'
            items={filters}
            closeOnSelect={false}
            menuItemGetter={this.menuItemGetter}
            onSelect={(e, item) => this.handleFilterClick(e, item.active, item.name)}
          >
            <ToggleFlatButton
              className='crtx-size-24'
              checked={isFilterActive(filters)}
              tooltip={translate('Navigation Filters')}
            >
              <SvgIcon name='filter_list' className='crtx-size-24' />
            </ToggleFlatButton>
          </Menu>}
        <FlatButton
          className='crtx-mn-previous-button'
          disabled={navigationDisabled}
          tooltip={translate('previous')}
          onClick={this.handlePreviousClick}
        >
          ◄
        </FlatButton>
        <Dropdown
          options={navigationOptions}
          value={selectedValue}
          className='navigator-dropdown'
          valueProp='label'
          style={{ width: dropdownWidth }}
          disabled={navigationDisabled}
          onSelect={this.handleNavigationOptionSelect}
          itemGetter={this.getNavigatorOptionContentGetter}
        />
        <FlatButton
          className='crtx-mn-next-button'
          disabled={navigationDisabled}
          tooltip={translate('next')}
          onClick={this.handleNextClick}
        >
          ►
        </FlatButton>
      </div>
    );
  }

  renderHelp() {
    const { helpClick } = this.props;

    return (
      <FlatButton
        className={'crtx-mn-help-button k-icon k-i-help'}
        tooltip={translate('Help')}
        onClick={this.handleHelpClick}
      >
      </FlatButton>
    );
  }

  componentWillUnmount() {
    // TODO: unmount component
    console.log('###componentWillUnmount()');
  }

  render() {
    const { breadcrumbs } = this.props;

    return (
      <div className={'crtx-mn-content'}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        {this.renderViewLinksGroups()}
        {this.renderNavigationControls()}
        {this.renderHelp()}
      </div>
    );
  }

}

export default NavigatorComponent;
