import React from 'react';
import localization from 'core/services/localization';
import pubsub from 'core/managers/pubsub';
import Dropdown from 'components/common/dropdown/Dropdown';


export default class extends React.Component {

  constructor(props) {
    super(props);

    const languages = localization.getAvailableLanguages().map(lang => ({ value: lang.code, text: lang.name }));
    const languageCode = localization.getLanguageCode();
    this.state = {
      languages,
      languageCode
    };

    this.unsubscribeLanguageChanged = pubsub.subscribe('language-changed', () => {
      this.setState({
        languageCode: localization.getLanguageCode()
      });
    });
  }

  handleLanguagesSelect = (event, value) => {
    this.setState({
      languageCode: value
    });

    localization.loadLocalization(value);
  };

  componentWillUnmount() {
    this.unsubscribeLanguageChanged();
  }

  render() {
    const { languages, languageCode } = this.state;
    const { style, color, withGlobeIcon } = this.props;

    return (

      <div className='crtx-login-languages'>
        {withGlobeIcon &&
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path
              fill={color}
              d="m12 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12zm-0.8 1.7v4.9h-3.3a11.3 11.3 0 0 1 0.8-2.2 5.54 5.54 0 0 1 2.5-2.7zm1.6 0c0.9 0.3 1.7 1.2 2.5 2.7a14.3 14.3 0 0 1 0.8 2.2h-3.3zm-4.7 0.6a6.38 6.38 0 0 0-0.8 1.4 24.9 24.9 0 0 0-1.1 2.9h-3.1a10.5 10.5 0 0 1 5-4.3zm7.8 0a11.3 11.3 0 0 1 5.1 4.3h-3.2a11 11 0 0 0-1.1-2.9 6.38 6.38 0 0 0-0.8-1.4zm-13.6 5.9h3.6a25.8 25.8 0 0 0-0.3 2.9h-4a9.47 9.47 0 0 1 0.7-2.9zm5.2 0h3.7v2.9h-4c0.1-1 0.2-2 0.3-2.9zm5.3 0h3.7a13.6 13.6 0 0 1 0.3 2.9h-4zm5.3 0h3.6a9.47 9.47 0 0 1 0.7 2.9h-4a28.5 28.5 0 0 0-0.3-2.9zm-16.5 4.6h4a13.6 13.6 0 0 0 0.3 2.9h-3.6a9.47 9.47 0 0 1-0.7-2.9zm5.6 0h4v2.9h-3.7c-0.1-0.9-0.2-1.9-0.3-2.9zm5.6 0h4a13.6 13.6 0 0 1-0.3 2.9h-3.7zm5.6 0h4a9.47 9.47 0 0 1-0.7 2.9h-3.6a13.6 13.6 0 0 0 0.3-2.9zm-15.3 4.5h3.2a11 11 0 0 0 1.1 2.9 6.38 6.38 0 0 0 0.8 1.4 9.86 9.86 0 0 1-5.1-4.3zm4.8 0h3.3v4.9c-0.9-0.3-1.7-1.2-2.5-2.7a11.3 11.3 0 0 1-0.8-2.2zm4.9 0h3.3a11.3 11.3 0 0 1-0.8 2.2 5.13 5.13 0 0 1-2.5 2.7zm5 0h3.2a10.3 10.3 0 0 1-5.1 4.3 6.38 6.38 0 0 0 0.8-1.4 16 16 0 0 0 1.1-2.9z"
            />
          </svg>}

        <Dropdown
          options={languages}
          style={style}
          value={languageCode}
          onSelect={this.handleLanguagesSelect} />
      </div>
    );
  }
}
