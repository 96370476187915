/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

import { startModule } from 'core/managers/module';
import sandbox from 'sandbox';
import Ember from 'ember';
import AbstractModule from 'AbstractModule';
import TitleComponent from './components/TitleComponent';
import ZoneOverviewComponent from './components/ZoneOverviewComponent';
import FragmentsListComponent from './components/FragmentsListComponent';
import PlatesProgressBar from './components/PlatesProgressBar';
import viewManager from 'core/managers/views';
import BookModel from './models/BookModel';
import FormModel from './models/FormModel';
import SectionModel from './models/SectionModel';
import PageModel from './models/PageModel';
import StatusModel from './models/StatusModel';
import ProductionRunModel from './models/ProductionRunModel';
import AggregateModel from './models/AggregateModel';
import ContextMenuWidget from 'ContextMenuWidget';
import KendoEmber from 'kendo-ember';
import {fetchBreadcrumbs, formatBreadcrumbsCodes} from 'utilities/breadcrumbs';
import { getMainStatus, ALL_LANES } from 'utilities/statuses';

const DIFF_OVERVIEW_BASESIZE = 199;
const DIFF_PROGRESS_SIZE = 48;

function createDom() {
  this.titleComponent.replaceIn(this.title);

  sandbox.dom.empty(this.content);
  sandbox.dom.addClass(this.content, 'item-content-empty zone-overview');
  this.zoneOverviewComponent.appendTo(this.content);
}

function sortModelArrays(model) {
  var excludedKeys = ["viewLinks", "approvalFlowSteps", "selected"];
  for (var i in model) {
    if (Array.isArray(model[i])) {
      if (excludedKeys.indexOf(i) !== -1) {
        continue;
      }
      var sortingDescription = model[i + ":sorting"];
      if (sortingDescription) {
        var sortingTokens = sortingDescription.split(":");
        if (sortingTokens.length === 2) {
          sandbox.sorting.sort(model[i], sortingTokens[1], sortingTokens[0]);
        }
      }
      sortModelArrays.apply(this, [model[i]]);
    } else if (sandbox.jsUtils.isObject(model[i])) {
      sortModelArrays.apply(this, [model[i]]);
    }
  }
}

function getIndexInArrayToInsert(arr, model, sortingDescription) {
  var sortingTokens = sortingDescription != undefined ? sortingDescription.split(':') : [],
    comparator,
    index = -1;

  if (sortingTokens.length === 2) {
    comparator = sandbox.sorting.getComparator(sortingTokens[1], sortingTokens[0]);
    arr.some(function (item, itemIndex) {
      if (comparator(model, item) < 1) {
        index = itemIndex;
        return true;
      }
    });
  }
  return index;
}

function addFormToBook(formModel, bookObject) {
  var that = this,
    status = getMainStatus(formModel),
    statusObject = new StatusModel(status, formModel.id),
    newFormObject = new FormModel(formModel, statusObject, bookObject.get('id')),
    forms = bookObject.get('forms'),
    index = getIndexInArrayToInsert(forms, newFormObject, bookObject.get('forms:sorting'));

  if (index >= 0) {
    bookObject.get('forms').insertAt(index, newFormObject);
  }
  else {
    bookObject.get('forms').pushObject(newFormObject);
  }

  that.objectsMap[formModel.id] = newFormObject;
  if (statusObject !== undefined && typeof status !== 'undefined' && status !== null) {
    that.objectsMap[status.id] = statusObject;
  }

  return newFormObject;
}

function addBookToModel(bookModel) {
  var that = this,
    bookObject = new BookModel(bookModel),
    index = getIndexInArrayToInsert(that.model.get('books'), bookObject, that.model['books:sorting']);

  if (index >= 0) {
    that.model.get('books').insertAt(index, bookObject);
  }
  else {
    that.model.get('books').pushObject(bookObject);
  }

  that.objectsMap[bookModel.id] = bookObject;

  if (bookModel.forms === undefined) return;
  bookModel.forms.forEach(function (formModel) {
    addFormToBook.call(that, formModel, bookObject);
  });

  sortModelArrays(bookModel.forms);
}

function addPageToSection(pageModel, sectionObject) {
  var that = this,
    status = getMainStatus(pageModel),
    statusObject = new StatusModel(status, pageModel.id),
    newPageObject = new PageModel(pageModel, statusObject, sectionObject.get('id')),
    pages = sectionObject.get('pages'),
    index = getIndexInArrayToInsert(pages, newPageObject, sectionObject.get('pages:sorting'));

  if (index >= 0) {
    pages.insertAt(index, newPageObject);
  }
  else {
    pages.pushObject(newPageObject);
  }

  that.objectsMap[pageModel.id] = newPageObject;
  if (statusObject !== undefined && typeof status !== 'undefined' && status !== null) {
    that.objectsMap[status.id] = statusObject;
  }
  return newPageObject;
}

function addSectionToModel(sectionModel) {
  var that = this,
    sectionObject = new SectionModel(sectionModel),
    index = getIndexInArrayToInsert(that.model.get('sections'), sectionObject, that.model.get('sections:sorting'));

  if (index >= 0) {
    that.model.get('sections').insertAt(index, sectionObject);
  }
  else {
    that.model.get('sections').pushObject(sectionObject);
  }
  that.objectsMap[sectionModel.id] = sectionObject;

  if (sectionModel.pages === undefined) return;
  sectionModel.pages.forEach(function (pageModel) {
    addPageToSection.call(that, pageModel, sectionObject);
  });
  sortModelArrays(sectionModel.pages);
}

function addProductionRun(productionRunModel) {
  var that = this,
    productionRunObject = new ProductionRunModel(productionRunModel),
    aggregateObject;

  that.model.get('productionRuns').pushObject(productionRunObject);
  that.objectsMap[productionRunObject.id] = productionRunObject;

  if (productionRunModel.aggregate === undefined || productionRunModel.aggregate === null) return;

  aggregateObject = new AggregateModel(productionRunModel.aggregate);
  productionRunObject.set('aggregate', aggregateObject);
  that.objectsMap[aggregateObject.id] = aggregateObject;
}

function addAggregateToProductionRun(aggregateModel, productionRunObject) {
  var aggregateObject;

  if (productionRunObject === undefined) return;

  aggregateObject = new AggregateModel(aggregateModel);
  productionRunObject.set('aggregate', aggregateObject);
  this.objectsMap[aggregateObject.id] = aggregateObject;
}

function initBooksModel(model) {
  var that = this;
  if (model.books === undefined) return;

  that.model.set('books:sorting', model['books:sorting']);
  model.books.forEach(function (bookModel) {
    addBookToModel.call(that, bookModel);
  });
}

function initSectionsModel(model) {
  var that = this;

  if (model.sections === undefined) return;

  that.model.set('sections:sorting', model['sections:sorting']);
  model.sections.forEach(function (sectionModel) {
    addSectionToModel.call(that, sectionModel);
  });
}

function initProductionRuns(model) {
  var that = this;

  if (model.productionRuns === undefined) return;// && (model.type.toLowerCase() != 'aggregate') ) return;

  model.productionRuns.forEach(function (productionRun) {
    var productionRunModel = new ProductionRunModel(productionRun),
      aggregateModel = new AggregateModel(productionRun.aggregate);

    productionRunModel.set('aggregate', aggregateModel);
    that.model.get('productionRuns').pushObject(productionRunModel);
    that.objectsMap[productionRun.id] = productionRunModel;
    that.objectsMap[productionRun.aggregate.id] = aggregateModel;
  });
}

function initModel(model) {
  var that = this;
  that.model.set('name', model.name);
  that.model.set('id', model.id);
  that.model.set('nwid', model.nwid);
  that.model.set('type', model.type);
  that.model.set('deadline', model && model.productionRuns && model.productionRuns.length > 0 ? model.productionRuns[0].deadline : null)
  that.model.set('deadlinePassed', model.deadlinePassed);
  that.objectsMap[model.id] = that.model;
  initBooksModel.call(that, model);
  initSectionsModel.call(that, model);
  initProductionRuns.call(that, model);
  sortModelArrays.call(that, model);
}

function handleAddAction(model) {
  var that = this,
    statusObject,
    parentId = model.parentId,
    id = model.id,
    childPropertyId = model.childPropertyId != undefined ? model.childPropertyId.toLowerCase() : undefined;
  
  if (childPropertyId != 'sections' && childPropertyId != 'books' && (that.objectsMap[parentId] === undefined || that.objectsMap[id] != undefined)) return;

  if (childPropertyId === 'books') {
    addBookToModel.call(that, model);
  }
  if (childPropertyId === 'sections') {
    addSectionToModel.call(that, model);
  }
  if (childPropertyId === 'pages') {
    addPageToSection.call(that, model, that.objectsMap[parentId]);
    this.model.get('updateFlags').set('pages', Date.now());
  }
  if (childPropertyId === 'forms') {
    addFormToBook.call(that, model, that.objectsMap[parentId]);
    this.model.get('updateFlags').set('forms', Date.now());
  }
  if (ALL_LANES.some(lane => lane === childPropertyId)) {
    statusObject = new StatusModel(model, parentId);
    that.objectsMap[parentId].set(childPropertyId, statusObject);
    that.objectsMap[id] = statusObject;
  }
  if (childPropertyId === 'productionruns') {
    addProductionRun.call(that, model, that.objectsMap[parentId]);
  }
  if (childPropertyId === 'aggregate') {
    addAggregateToProductionRun.call(that, model, that.objectsMap[parentId]);
  }
}

function handleRemoveAction(model) {
  var that = this,
    statusObject,
    parentId = model.parentId,
    parent = that.objectsMap[parentId],
    ids = model.ids,
    childPropertyId = model.childPropertyId != undefined ? model.childPropertyId.toLowerCase() : undefined,
    objToRemove;

  if (ids === undefined) return;
  if (childPropertyId === undefined) return;
  if (parent === undefined) return;
  if (Array.isArray(ids) === false) ids = [ids];

  ids.forEach(function (id) {
    if (that.objectsMap[id] === undefined) return;

    objToRemove = parent.get(childPropertyId);
    if (Array.isArray(objToRemove)) {
      objToRemove.removeObject(that.objectsMap[id]);
      that.objectsMap[id] = undefined;
      delete that.objectsMap[id];
    } else {
      if (parent.get(childPropertyId)?.get('id') === id) {
        parent.set(childPropertyId, undefined);
      }
    }
  });

  if (childPropertyId === 'forms') {
    this.model.get('updateFlags').set('forms', Date.now());
  }
  else if (childPropertyId === 'pages') {
    this.model.get('updateFlags').set('pages', Date.now());
  }
}

function handleUpdateAction(model) {
  if (this.objectsMap[model.id] === undefined) {
    return;
  }

  Object.keys(model).forEach(key => {
    this.objectsMap[model.id].set(key, model[key]);
  });
}

function initViewLinks() {
  Object.keys(this.linksByTypes).forEach(linkName => {
    if (this.linksByTypes[linkName] === undefined) {
      const linkParams = linkName.split(':');
      requestViewLink.apply(this, linkParams);
    }
  });
}

function firstTickHandler(model, preferences) {
  initModel.call(this, model);
  fetchBreadcrumbs(this.model.get('nwid'), this.model.get('type')).then(breadcrumbs => {
    updateMainTabTitle.call(this, breadcrumbs);
  });

  initViewLinks.call(this);
}

function tickUpdateHandler(model) {
  var action = model.action.toLowerCase();

  switch (action) {
    case 'add':
      handleAddAction.call(this, model);
      break;
    case 'remove':
      handleRemoveAction.call(this, model);
      break;
    case 'update':
      handleUpdateAction.call(this, model);
      break;
  }
}

function resolveViewLink(viewLinks, viewClass, icon) {
  for (var i = 0; i < viewLinks.length; i++) {
    const params = viewManager.getViewInfo(viewLinks[i]);
    if (params.viewClass === viewClass) {
      if (icon) {
        if (params.icon.indexOf(icon) >= 0) {
          return viewLinks[i];
        }
      } else {
        return viewLinks[i];
      }
    }
  }
  return undefined;
}

function composeLinkName(rootType, rootClass, icon) {
  return rootType + ':' + rootClass + (icon ? ':' + icon : '');
}

function requestViewLink(rootType, rootClass, icon) {
  const nwid = this.model.get('nwid');
  const linkName = composeLinkName(rootType, rootClass, icon);

  return sandbox.request.retrieveViewLinks(nwid, rootType, this.nwid).then(res => {
    this.linksByTypes[linkName] = resolveViewLink(res.data.viewLinks, rootClass, icon);
    return this.linksByTypes[linkName];
  });
}

function openView(rootType, viewClass, icon) {
  const rootId = this.model.get('nwid');
  const linkName = composeLinkName(rootType, viewClass, icon);
  const viewNwid = this.linksByTypes[linkName];
  if (viewNwid) {
    startModule(viewNwid, null, {
      nwid: viewNwid,
      rootId,
      rootType,
      viewClass,
      target: 'main',
      default: true,
      isSecondary: false,
      showCloseButton: true
    });
  }
}

function openContextMenu(ev, rootType, rootClass, icon) {
  const nwid = this.model.get('nwid');
  const linkName = composeLinkName(rootType, rootClass, icon);
  const viewNwid = this.linksByTypes[linkName];

  if (viewNwid) {
    const contextMenuModel = {
      type: rootType,
      nwid: nwid,
      viewLinks: [viewNwid]
    };

    this.showContextMenu(contextMenuModel, [contextMenuModel], ev);
  }
}

function updateMainTabTitle(breadcrumbs) {
  const title = formatBreadcrumbsCodes(breadcrumbs);
  if (title) {
    this.model.set('name', title);
  }
}

function overviewDomSize(width) {
  sandbox.dom.css(this.element, 'width', width);
}

function productionRunsChanged(productionRunsLenght) {
  overviewDomSize.call(this, DIFF_OVERVIEW_BASESIZE + (DIFF_PROGRESS_SIZE * productionRunsLenght) + 'px');
}

module.exports = AbstractModule.extend({
  objectsMap: {},
  model: undefined,
  linksByTypes: {
    //'sections:client.myway.views.birdeye.MyBirdeye': undefined,
    //'books:client.myway.views.birdeye.MyBirdeye': undefined,
    'sections:BirdEyeLayers': undefined,
    'books:BirdEyeLayers': undefined,
    'books:StructuresTableView:PlatesTable': undefined
  },
  init: function () {
    this._super();
    this.objectsMap = {};
    this.model = Ember.Object.create({
      id: -1,
      nwid: -1,
      name: 'Zone Overview',
      books: [],
      'books:sorting': undefined,
      sections: [],
      'sections:sorting': undefined,
      type: undefined,
      productionRuns: [],
      aggregate: new AggregateModel({
        id: -1,
        nwid: -1,
        totalFinishedPlates: 0,
        totalPlates: 0,
        type: 'aggregate'
      }),
      updateFlags: Ember.Object.create({
        forms: Date.now(),
        pages: Date.now()
      }),
      viewlinks: []
    });
  },
  initDone: function () {
    this.titleComponent = TitleComponent.create({model: this.model});
    this.zoneOverviewComponent = ZoneOverviewComponent.create({
      books: this.model.get('books'),
      sections: this.model.get('sections'),
      productionRuns: this.model.get('productionRuns'),
      plates: this.model.get('aggregate'),
      updateFlags: this.model.get('updateFlags'),
      openView: this.openView,
      openPages: this.openPages,
      openForms: this.openForms,
      openPlates: this.openPlates,
      contextMenuPages: this.contextMenuPages,
      contextMenuForms: this.contextMenuForms,
      contextMenuPlates: this.contextMenuPlates,
      productionRunsChanged: productionRunsChanged,
      module: this
    });

    this.title = sandbox.dom.find('.item-title', this.element)[0];
    this.content = sandbox.dom.find('.item-content', this.element)[0];
    createDom.call(this);
  },
  firstTickReceived: function (data) {
    let model = data.model;
    this.viewLinks = model.viewLinks;
    if (model.type === 'productionrun') {
      model = model.zone;
    }

    firstTickHandler.call(this, model, data.preferences);
    sortModelArrays.call(this, model);
  },
  tickUpdate: function (data) {
    var that = this;
    for (var model in data.model) {
      if (sandbox.jsUtils.isObject(data.model[model])) {
        tickUpdateHandler.call(this, data.model[model]);
      }
    }

    sortModelArrays.call(this, data.model);
  },
  openPages: function () {
    openView.call(this, 'sections', 'BirdEyeLayers');
  },
  openForms: function () {
    openView.call(this, 'books', 'BirdEyeLayers');
  },
  openPlates: function () {
    openView.call(this, 'books', 'StructuresTableView', 'PlatesTable');
  },
  contextMenuPages: function (ev) {
    openContextMenu.call(this, ev, 'sections', 'BirdEyeLayers');
  },
  contextMenuForms: function (ev) {
    openContextMenu.call(this, ev, 'books', 'BirdEyeLayers');
  },
  contextMenuPlates: function (ev) {
    openContextMenu.call(this, ev, 'books', 'StructuresTableView', 'PlatesTable');
  },
  destroy: function () {
    this._super();

  }
});
