import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Cell extends Component {
  static propTypes = {
    cell: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func,
    refCallback: PropTypes.func,
    onDragOver: PropTypes.func,
    onDrop: PropTypes.func
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {
      dragOver: false
    };
  }

  handleRefCallback = (node) => {
    const { refCallback, cell } = this.props;
    if (refCallback) {
      refCallback(node, cell);
    }
  };

  handleClick = (e) => {
    const { onClick, cell } = this.props;
    if (onClick) {
      onClick(e, cell);
    }
  };

  handleDragEnter = (e) => {
    if (this.props.onDrop) {
      this.setState({ dragOver: true });
    }
  };

  handleDragLeave = (e) => {
    //console.log('###handleDragLeave()')
    if (this.props.onDrop) {
      this.setState({ dragOver: false });
    }
  };

  handleDragOver = (e) => {
    if (this.props.onDrop) {
      e.preventDefault();
    }
  };

  handleDrop = (e) => {
    const { onDrop, cell } = this.props;

    if (onDrop) {
      e.preventDefault();
      this.setState({ dragOver: false });
      onDrop(e, cell);
    }
  };

  render() {
    const { children, style } = this.props;
    const className = `cell ${this.state.dragOver ? 'drag-over' : ''}`;

    return (
      <div
        className={className}
        style={style}
        onClick={this.handleClick}
        ref={this.handleRefCallback}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      >
        {children}
      </div>
    );
  }
}

export default Cell;
