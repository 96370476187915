import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { translate, toLocaleShortDate } from 'core/services/localization';
import { composeComparators, createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import Table, { Column, cells, headers, filters } from 'widgets/ReactDataGrid';
import {
  FILTER,
  FILTER_TYPE,
  FILTER_DATA_TYPE,
  reduceColumnsToSortBy,
  reduceColumnsToFilterBy,
  checkDateCondition,
  checkTextCondition,
  ROW_HEIGHT
} from 'widgets/ReactDataGrid/utils';

const { Text, CheckboxCell } = cells;
const { GenericHeader, HeaderCaption } = headers;
const { MultiselectFilter, TextInputFilter } = filters;

const defaultColumnsToSortBy = [
  {
    key: 'publicationDate',
    sortType: COMPARE_TYPE.DATES,
    ascending: true
  },
  {
    key: 'publicationName',
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    ascending: true
  },
  {
    key: 'name',
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    ascending: true
  }
];

const getColumns = (rows, selectedNwid, onSelect) => {
  const columns = [
    {
      caption: '',
      key: 'nwid',
      width: 35,
      resizable: false,
      align: 'center',
      cell: CheckboxCell,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = rows[rowIndex];

        return {
          columnData: row.nwid === selectedNwid,
          onChange: () => onSelect(row.nwid)
        };
      },
    },
    {
      caption: translate('Date'),
      key: 'publicationDate',
      width: 110,
      resizable: true,
      cell: Text,
      sortType: COMPARE_TYPE.DATES,
      filter: FILTER.DATE,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = rows[rowIndex];

        return {
          columnData: toLocaleShortDate(row.publicationDate)
        };
      },
    },
    {
      caption: translate('Publication'),
      key: 'publicationName',
      width: 300,
      resizable: true,
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = rows[rowIndex];

        return {
          columnData: row.publicationName
        };
      },
    },
    {
      caption: translate('Edition'),
      key: 'name',
      width: 200,
      resizable: true,
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = rows[rowIndex];

        return {
          columnData: row.name
        };
      },
    },
  ];

  return columns;
};

const sortRows = (rows, columnsToSortBy) => {
  let sortedRows = [];
  if (columnsToSortBy.length <= 0) {
    sortedRows = [...rows];
  } else {
    const comparator = composeComparators(columnsToSortBy.map(col =>
      createObjectComparator(col.key, col.sortType, col.ascending)
    ));

    sortedRows = [...rows].sort(comparator);
  }

  return sortedRows;
};

const filterRows = (rows, filtersByKey) => {
  const columns = reduceColumnsToFilterBy(Object.entries(filtersByKey).map(([key, value]) => ({
    key,
    filter: filtersByKey[key]
  })));

  const filteredRows = rows.filter(row => {
    let match = true;

    for (const col of columns) {
      const filter = col.filter;
      if (filter.type === FILTER_TYPE.MULTISELECT) {
        if (filter.selected && filter.selected.length > 0) {
          const filterValue = col.filterValueGetter(row);
          match = filter.selected.some(s => s === filterValue);
        }
      } else if (filter.type === FILTER_TYPE.DATE) {
        match = checkDateCondition(row[col.key], filter);
      } else if (filter.type === FILTER_TYPE.TEXT) {
        if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
          match = checkTextCondition(row[col.key], filter);
        }
      }

      if (!match) {
        break;
      }
    }

    return match;
  });

  return filteredRows;
};

function EditionsTable({ editions, duplicatedNwid, selectedNwid, onSelect }) {
  const [columnsToSortBy, setColumnsToSortBy] = useState(defaultColumnsToSortBy);
  const [filtersByKey, setFiltersByKey] = useState({});

  const tableRef = useRef(null);

  const sortedRows = useMemo(() => {
    return sortRows(editions, columnsToSortBy);
  }, [editions, columnsToSortBy]);

  const filteredRows = useMemo(() => {
    return filterRows(sortedRows, filtersByKey);
  }, [sortedRows, filtersByKey]);

  useEffect(() => {
    if (duplicatedNwid) {
      const rowIndex = filteredRows.findIndex(row => row.nwid === duplicatedNwid);
      if (rowIndex >= 0) {
        tableRef.current?.scrollToIndex(rowIndex);
      }
    }
  }, [duplicatedNwid]);

  const getRowClassName = rowIndex => {
    const duplicated = duplicatedNwid && filteredRows[rowIndex]?.nwid === duplicatedNwid;

    return duplicated ? ['duplicated'] : [];
  };

  const handleColumnFilterChange = (column, columnFilter) => {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    const { key, filter } = column;
    const newFiltersByKey = { ...filtersByKey, [key]: { ...filter, ...columnFilter } };
    setFiltersByKey(newFiltersByKey);
  };

  const handleTableSort = (columnKey, multiSort) => {
    const newColumnsToSortBy = reduceColumnsToSortBy(getColumns(), columnsToSortBy, columnKey, multiSort);
    setColumnsToSortBy(newColumnsToSortBy);
  };

  const renderColumnFilter = column => {
    let { filter } = column;
    if (!filter) {
      return;
    }

    filter = { ...filter, ...filtersByKey[column.key] };

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:
        return (
          <MultiselectFilter
            selectedValues={filter.selected}
            options={filter.options}
            dataType={filter.dataType}
            onSelect={(event, values, index) => handleColumnFilterChange(column, { selected: values })}
          />
        );
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            textValue={filter.textValue || ''}
            onChange={(e, textValue) => handleColumnFilterChange(column, { textValue })}
          />
        );
    }
  };

  const renderColumnHeaderFilter = (column) => {

    return (
      <div className='column-header-filter'>
        {renderColumnFilter(column)}
      </div>
    );
  };

  const renderColumnHeaderCaption = (column) => {
    let sortable = typeof column.sortType !== 'undefined';
    let sortDirection = '', index = -1, handleClick;
    if (sortable) {
      index = columnsToSortBy.findIndex(col => col.key === column.key);
      sortDirection = index < 0 ? '' : columnsToSortBy[index].ascending ? 'asc' : 'desc';
      handleClick = multiSort => handleTableSort(column.key, multiSort);
    }

    return (
      <HeaderCaption
        sortDirection={sortDirection}
        sortOrder={index + 1}
        tooltip={column.caption}
        onSort={handleClick}
        sortable={sortable}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  const renderColumnHeader = (column) => {

    return (
      <GenericHeader
        captionRenderer={renderColumnHeaderCaption(column)}
        filterRenderer={renderColumnHeaderFilter(column)}
      />
    );
  };


  return (
    <Table
      ref={tableRef}
      rows={filteredRows}
      columnKey='nwid'
      virtualScroll={true}
      minColumnWidth={20}
      headerHeight={61}
      rowHeight={ROW_HEIGHT}
      selectableRows={false}
      getRowClassName={getRowClassName}
    >
      {getColumns(filteredRows, selectedNwid, onSelect).map((col, index) => {
        return <Column
          key={col.key}
          title={col.caption}
          columnKey={col.key}
          width={col.width}
          align={col.align}
          resizable={col.resizable}
          header={renderColumnHeader(col)}
          cell={col.cell}
          cellDataGetter={col.cellDataGetter}
          shouldCellUpdate={() => true}
        />;
      })}
    </Table>
  );
}

EditionsTable.propTypes = {
  editions: PropTypes.array,
  onSelect: PropTypes.func,
};

export default EditionsTable;
