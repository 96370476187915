import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import View from './View';
import { fromServerDate } from 'core/dates';
import { createNavigatorFilter } from '../NewPageView/navigatorControls';
import { getLaneNameToDisplayNameMap } from 'utilities/statuses';
import { makeHistoryTableColumns } from './columnsCreator';
import { savePreferences } from 'core/managers/preferences';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { DEFAULT_SORT } from './constants';
import { moveItem } from 'utilities/array';
import { translate } from 'core/services/localization';
import {
  checkDateCondition,
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

const lanesMap = getLaneNameToDisplayNameMap();

function createRowsModel(model) {
  let rows = [], i = 0, leni = model.history.length;

  const parseContent = (fragment) => {
    const getCommonProps = (item, itemHistory) => {
      let info = itemHistory.info;
      info.rowId = itemHistory.id;
      if (info.externalVersions && typeof info.externalVersions !== 'undefined') {
        info.externalVersions = info.externalVersions.toString();
      }
      info.type = item.type;
      info.lane = lanesMap[info.lane];
      info.time = fromServerDate(info.time);

      return info;
    };

    fragment.history.forEach(fragmentHistory => {
      let info = { ...getCommonProps(fragment, fragmentHistory) };

      info.contentName = fragment.contentName;
      info.contentType = fragment.contentType;
      rows.push(info);

    });

    fragment.separations.forEach(separation => {
      separation.history.forEach(sepHistory => {
        let info = { ...getCommonProps(separation, sepHistory) };

        info.contentName = fragment.contentName + '/' + separation.contentName;
        info.contentType = fragment.contentType;
        info.color = sandbox.colors.getColorByName(separation.colorName);
        rows.push(info);
      });
    });

    return rows;
  };

  if (model.type === 'fragment/content') {
    parseContent(model);
  } else {
    for (i; i < leni; i++) {
      var info = model.history[i].info;
      info.rowId = model.history[i].id;
      info.contentName = model.contentName;
      if (info.externalVersions !== null && typeof info.externalVersions !== 'undefined') {
        info.externalVersions = info.externalVersions.toString();
      }
      //TODO: remove this when the server will not send demons anymore
      if (info.flowStepTemplate !== null && typeof info.flowStepTemplate !== 'undefined') {
        if (info.flowStepTemplate.search("demon") !== -1) {
          continue;
        }
      }
      info.type = model.type;
      info.lane = lanesMap[info.lane];
      info.time = fromServerDate(info.time);
      rows.push(info);
    }

    var j, lenSep = model.separations.length;
    for (var sep = 0; sep < lenSep; sep++) {
      var separation = model.separations[sep];
      if (separation.history.length > 0) {
        var lenSepHis = separation.history.length;
        for (j = 0; j < lenSepHis; j++) {
          let info = separation.history[j].info;
          info.rowId = separation.history[j].id;
          info.contentName = model.contentName + '/' + separation.contentName;
          if (info.externalVersions !== null && typeof info.externalVersions !== 'undefined') {
            info.externalVersions = info.externalVersions.toString();
          }

          //TODO: remove this when the server will not send demons anymore
          if (info.flowStepTemplate !== null && typeof info.flowStepTemplate !== 'undefined') {
            if (info.flowStepTemplate.search("demon") !== -1) {
              continue;
            }
          }
          info.type = separation.type;
          info.color = sandbox.colors.getColorByName(separation.colorName);
          info.lane = lanesMap[info.lane];
          info.time = fromServerDate(info.time);
          rows.push(info);
        }

        var lenPlates = 0;
        if (typeof separation.plates !== 'undefined') {
          lenPlates = separation.plates.length;
        }
        for (var iPlate = 0; iPlate < lenPlates; iPlate++) {
          var plate = separation.plates[iPlate];
          var lenPlateHis = plate.history.length;
          for (var iPlateHis = 0; iPlateHis < lenPlateHis; iPlateHis++) {
            var plateInfo = plate.history[iPlateHis].info;
            plateInfo.rowId = plate.history[iPlateHis].id;
            plateInfo.contentName = model.contentName + '/' + separation.contentName + '/' + plate.colorName;
            plateInfo.externalVersions = plateInfo.externalVersions.toString();
            plateInfo.type = plate.type;
            plateInfo.lane = lanesMap[plateInfo.lane];
            plateInfo.color = sandbox.colors.getColorByName(plate.colorName);
            plateInfo.time = fromServerDate(plateInfo.time);
            //TODO: remove this when the server will not send demons anymore
            rows.push(plateInfo);

          }
        }
      }
    }

    if (model.fragments) {
      model.fragments.forEach(fragment => {
        parseContent(fragment);
      });
    }
  }
  return rows;
}

function setDesktopNavSelected(data) {
  if (typeof data.model.neighborItems === 'undefined' || data.model.neighborItems === null || !this.navigator) {
    return;
  }

  var items = data.model.neighborItems.sort(function (a, b) {
    return a.index - b.index;
  });

  if (items === undefined || items === null) { return; }

  this.preferences = data.preferences || {};
  this.preferences.filters = data.preferences.filters || [];

  const filters = createNavigatorFilter(this.preferences.filters, items[0]);

  const navigatorData = {
    items,
    filters
  };

  this.navigator.setControls(navigatorData);

  items.forEach((item, index) => {
    item.viewNwid = this.nwid; //get the id of the view, in Navigator use this to find the viewLink
    if (data.model.nwid === item.nwid) {
      this.navigator.setSelectedIndex(index);
    }
  });

}

export default AbstractModule.extend({

  items: [],

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.onTableRowContextMenu = this.onTableRowContextMenu.bind(this);
    this.render = this.render.bind(this);
  },

  firstTickReceived: function (data) {
    var model = data.model;
    this.preferences = data.preferences || {};
    this.columnsToSortBy = DEFAULT_SORT;
    this.filtersEnabled = this.preferences.table?.filtersEnabled || false;
    setDesktopNavSelected.call(this, data);
    this.showBinAndSorterColumns = !!(model.type === 'form');
    this.toolbar = this.createToolbar();
    this.initToolbar();
    this.buildViewModel(model);

    this.win.document.addEventListener('keydown', this.handleDocumentKeyDown);
  },

  tickUpdate: function () {
  },

  tickCommit: function () {
    this.render();
  },

  initToolbar: function () {
    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list',
      iconSprite: 'general',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  buildViewModel: function (model) {
    this.viewModel = {
      sortColumnKey: '',
      sortOrder: ''
    };
    this.viewModel.rows = createRowsModel(model);

    this.viewModel.sortedHistoryRows = [...this.viewModel.rows];
    this.viewModel.filteredHistoryRows = [...this.viewModel.sortedHistoryRows];

    this.historyTableColumns = makeHistoryTableColumns(this.viewModel, this);

    this.sortHistoryRows();
    this.filterHistoryRows();
    this.render();
  },

  sortHistoryRows: function () {
    if (this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.historyTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));

      this.viewModel.sortedHistoryRows = this.viewModel.sortedHistoryRows.sort(comparator);
    }
  },

  filterHistoryRows: function () {
    if (!this.filtersEnabled) {
      this.viewModel.filteredHistoryRows = this.viewModel.sortedHistoryRows;
    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.historyTableColumns);
      this.viewModel.filteredHistoryRows = this.viewModel.sortedHistoryRows.filter(row => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(row) : row[col.key];
              match = filter.selected.some(s => s === '' ? filterValue === '' : filterValue === s);
            }
          } else if (filter.type === FILTER_TYPE.DATE) {
            const time = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(row) : row[col.key];
            if (time) {
              match = checkDateCondition(time, filter);
            }

          } else if (FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(row) : row[col.key];
              match = checkTextCondition(text, filter);
            }
          }
          if (!match) {
            break;
          }
        }
        return match;
      });
    }
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.filterHistoryRows();

    this.saveHistoryPageColumnPreferences();
    this.render();
  },

  savePreferences: function (preferences) {
    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  onTableRowContextMenu: function (rowIndex, rowContent, ev) {
    this.showContextMenu(rowContent, [rowContent], ev);
  },

  handleDocumentKeyDown: function (e) {
    switch (e.code) {
      case 'F5':
        e.preventDefault();
        break;
    }
  },

  handleHistoryPageColumnClick: function () {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.historyTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortHistoryRows();
      this.filterHistoryRows();
      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
      this.render();
    };
  },

  saveHistoryPageColumnPreferences: function () {
    const historyColumns = extractColumnPreferences(this.historyTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        historyColumns,
      }
    });
  },

  handleHistoryPageColumnsFilter: function (columns) {
    this.historyTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveHistoryPageColumnPreferences();
  },

  handleHistoryPageColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.historyTableColumns, oldIndex, newIndex);
    this.saveHistoryPageColumnPreferences();
    this.render();
  },

  handleHistoryPageTableColumnResize: function (columns) {
    this.historyTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveHistoryPageColumnPreferences();
  },

  handleHistoryPageColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.saveHistoryPageColumnPreferences();
    this.filterHistoryRows();
    this.render();
  },

  destroy: function () {
    this._super();
    this.win.document.removeEventListener('keydown', this.handleDocumentKeyDown);
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(<View module={this}
      viewModel={this.viewModel}
      historyTableColumns={this.historyTableColumns}
      win={this.win}
      onTableRowContextMenu={this.onTableRowContextMenu}
    />);
  },
});
