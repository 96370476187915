/**
 * @name Abstract Action
 * @fileOverview Abstract action class
 * @constructor
 * @author sergey
 */

import Class from 'class';
import sandbox from 'sandbox';
import toastServiceModule from 'core/services/toastService';
import { fetchBreadcrumbs, formatBreadcrumbs } from 'utilities/breadcrumbs';
import logger from 'logger';
const { translate } = sandbox.localization;

var log = logger.getDefaultLogger();

var SINGLE_SELECTION = 'Single',
  MULTIPLE_SELECTION = 'Multiple',
  SELECTED_CONTEXT = 'selected',
  ROOT_CONTEXT = 'root';

function isPromise(value) {
  if (typeof value === 'object' && typeof value.then === 'function') return true;
  return false;
}

function isApplicableSingleSelection() {
  return typeof this.module.selected !== 'undefined' && this.module.selected.length === 1;
}

function isApplicableMultipleSelection() {
  return typeof this.module.selected !== 'undefined' && this.module.selected.length >= 1;
}

function isApplicableAlways() {
  return true;
}

function getContext() {
  switch (this.actionContext) {
    case SELECTED_CONTEXT:
      return this.module.selected;
    case ROOT_CONTEXT:
      return [this.module.model || {
        nwid: this.module.startParameters.rootId,
        type: this.module.startParameters.rootType
      }];
    default:
      return void 0;
  }
}

/**
 * This function calls isApplicable function
 * and sets the _isApplicable value of the action
 * for observing
 * @param fn
 * @returns {function(this:Abstract.decorateIsApplicable)|*}
 */
function decorateIsApplicable(fn) {
  return function () {
    var applicable;
    if (arguments.length > 0) {
      applicable = fn.apply(this, arguments);
    } else {
      applicable = fn.call(this, getContext.call(this));
    }
    if (window.Ember !== void 0) {
      window.Ember.set(this, '_isApplicable', applicable);
    }
    else {
      this._isApplicable = applicable;
    }
    return applicable;
  }.bind(this);
}

function getBreadcrumbsPath(items, clbk) {
  if (typeof items === 'undefined') {
    clbk('');
    return;
  }
  let item = Array.isArray(items) ? items[0] : items;
  let breadcrumbs;

  fetchBreadcrumbs(item.nwid, item.type).then(breadcrumbs => {
    clbk(formatBreadcrumbs(breadcrumbs));
  });
}

/**
 * Decorates the execute method to pass the
 * correct context that was specified in
 * the action definition
 *
 * @param execute
 * @returns {function(this:Abstract.decorateExecute)|*}
 */
function decorateExecute(execute) {
  return function () {
    const toastService = toastServiceModule.createToastService(this.win);
    let toast;
    let promise;
    let items = arguments.length > 0 ? arguments[0] : getContext.call(this);

    if (this.toastMessage !== undefined && this.toastMessage !== '') {
      toast = toastService.createToast('bottom-right', this.toastMessage, '', 'regular', undefined, undefined, false);
    }
    if (typeof items !== 'undefined' && typeof toast !== 'undefined') {
      getBreadcrumbsPath(items, (breadcrumbs) => {
        toast.message(breadcrumbs);
      });
    }
    if (!this.isApplicable.apply(this, arguments)) {
      return;
    }
    if (arguments.length > 0) {
      promise = execute.apply(this, arguments);
    } else {
      var ctx = getContext.call(this);
      if (ctx !== void 0) {
        promise = execute.call(this, getContext.call(this));
      }
      else {
        promise = execute.call(this);
      }
    }

    if (isPromise(promise)) {
      promise.done(function (res) {
        if (typeof toast !== 'undefined') {
          toast.delay(true).type('ok');
          if (typeof this.toastMessageSuccess !== 'undefined') {
            toast.title(this.toastMessageSuccess);
          }
        }
        if (res && res.data && res.data.error) {
          toast = toast || toastService.createToast('bottom-right', '', '', 'error', undefined, undefined, false);
          toast.delay(false).type('error');
          toast.title(res.data.error.title || this.toastMessageError);
          toast.message(res.data.error.message);
        }
        if (res && res.errorMessage) {
          let title, message;
          try {
            const error = JSON.parse(res.errorMessage);
            title = error.ExceptionTitle;
            message = error.ExceptionDescription;
          } catch (err) {
            message = res.errorMessage;
          }
          toast = toast || toastService.createToast('bottom-right', '', '', 'error', undefined, undefined, false);
          toast.delay(false).type('error');
          toast.title(translate(title));
          toast.message(translate(message));
        }
        if (res && res.data && res.data.warning) {
          toast = toast || toastService.createToast('bottom-right', '', '', 'regular', undefined, undefined, false);
          toast.delay(false).type('regular');
          toast.title(res.data.warning.title || '');
          toast.message(res.data.warning.message);
        }
      }.bind(this))
        .fail(function () {
          if (typeof toast !== 'undefined') {
            toast.delay(true).type('error');
            if (typeof this.toastMessageError !== 'undefined') {
              toast.title(this.toastMessageError);
            }
          }
        }.bind(this));
    }
    return promise;
  }.bind(this);
}

module.exports = Class.extend({
  initDone: function () {
    if (sandbox.jsUtils.isFunction(this.isApplicable)) {
      if (this.isApplicableType !== void 0) {
        log.warn('isApplicable function and isApplicableType cannot be defined together in action ' + this.actionClass + '. isApplicable will override the isApplicableType.');
      }
      this.isApplicable = decorateIsApplicable.call(this, this.isApplicable.bind(this));
    }
    else {
      switch (this.isApplicableType) {
        case SINGLE_SELECTION:
          this.isApplicable = decorateIsApplicable.call(this, isApplicableSingleSelection.bind(this));
          break;
        case MULTIPLE_SELECTION:
          this.isApplicable = decorateIsApplicable.call(this, isApplicableMultipleSelection.bind(this));
          break;
        default:
          this.isApplicable = decorateIsApplicable.call(this, isApplicableAlways.bind(this));
          break;
      }
    }
    this.execute = decorateExecute.call(this, this.execute);
  },
  /**
   * Checks whether all parameters are passed into
   * the function and whether all of them are
   * defined
   * @param {Object} args Passed parameters
   * @param {Number} expected The number of expected parameters
   * @returns {boolean} Returns true if assert is valid
   */
  assertArguments: function (args, expected) {
    if (args.length !== expected) {
      return false;
    }
    for (var i in args) {
      if (args.hasOwnProperty(i)) {
        if (typeof args[i] === 'undefined') {
          return false;
        }
      }
    }
    return true;
  },
  execute: function () {
    log.warn('Abstract action execution has been invoked. Override the execute method');
    return true;
  },
  destroy: function () {
  }
}, "AbstractAction");
