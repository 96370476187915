import { DATA_TYPE_BY_COLUMN_KEY, FILTER_TYPE_BY_COLUMN_KEY, } from './constants';
import { FILTER_TYPE, FILTER_DATA_TYPE } from 'widgets/ReactDataGrid/utils';

export function getBaseObjectType(objectType) {
  let result = objectType;
  if (objectType && objectType.includes('/content')) {
    result = objectType.replace('/content', '');
  }

  return result;
}

export function mapValues(values) {
  if (!Array.isArray(values)) {
    return;
  }

  return values.reduce((acc, value) => {
    acc[value] = true;
    return acc;
  }, {});
}

export function getColumnFilter(columnKey) {
  const type = FILTER_TYPE_BY_COLUMN_KEY[columnKey] || FILTER_TYPE.MULTISELECT;
  const dataType = DATA_TYPE_BY_COLUMN_KEY[columnKey] || FILTER_DATA_TYPE.TEXT;
  let shouldSortOptions;
  if (type === FILTER_TYPE.MULTISELECT) {
    shouldSortOptions = columnKey !== 'state';
  }

  return {
    type,
    dataType,
    shouldSortOptions,
  };
}

export function hasCurrentDateCondition(columns) {
  let result = false;
  for (const col of columns) {
    const filter = col.filter;
    if (filter && filter.type === 'date' && filter.option && filter.option !== 'nofilter') {
      result = true;
    }
  }

  return result;
}
