import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useFloating,
  autoUpdate,
  offset,
  size,
  flip,
  shift,
  useClick,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions
} from '@floating-ui/react';
import { PopoverContextProvider } from './PopoverContext';

const SHIFT_PADDING = 5;

export function usePopover(
  {
    initialOpen = false,
    placement,
    clientX,
    clientY,
    matchWidth = false,
    modal = false,
    delay = 300,
    open: controlledOpen,
    onOpenChange: setControlledOpen
  }) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const [labelId, setLabelId] = useState();
  const [descriptionId, setDescriptionId] = useState();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(0),
      flip(),
      shift({ padding: SHIFT_PADDING }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: matchWidth ? `${rects.reference.width}px` : undefined,
            maxHeight: `${availableHeight}px`
          });
        }
      }),
    ]
  });

  const context = data.context;

  const isUncontrolled = open => typeof open === 'undefined';

  const click = useClick(context, {
    enabled: isUncontrolled(controlledOpen)
  });

  const hover = useHover(context, {
    move: false,
    enabled: isUncontrolled(controlledOpen),
    delay: {
      open: delay,
      close: 0
    }
  });

  const focus = useFocus(context, {
    enabled: isUncontrolled(controlledOpen)
  });

  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([click, hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      clientX,
      clientY,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId
    }),
    [open, setOpen, interactions, data, clientX, clientY, modal, labelId, descriptionId]
  );
}

export function Popover(
  {
    children,
    ...props
  }) {
  const data = usePopover(props);
  const { refs, clientX, clientY } = data;

  useEffect(() => {
    if (!isNaN(clientX) && !isNaN(clientY)) {
      refs.setPositionReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: clientX,
            y: clientY,
            top: clientY,
            right: clientX,
            bottom: clientY,
            left: clientX
          };
        }
      });
    }
  }, [refs, clientX, clientY]);

  return (
    <PopoverContextProvider value={data}>
      {children}
    </PopoverContextProvider>
  );
}

Popover.propTypes = {
  children: PropTypes.node,
  modal: PropTypes.bool,
  delay: PropTypes.number,
  placement: PropTypes.oneOf(['top', 'top-start', 'top-end', 'right', 'right-start', 'right-end',
    'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end']),
  clientX: PropTypes.number,
  clientY: PropTypes.number,
  matchWidth: PropTypes.bool,
  initialOpen: PropTypes.bool,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
};

