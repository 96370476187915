define(['sandbox', 'jquery', 'class', './AbstractStep', 'jquery.steps'],
  function(sandbox, $, Class, AbstractStep){
    'use strict';

    /**
     * init ({
   *   container:   "",
   *   model:       {},
   *   properties:  {}
   * })
     * render
     * back
     * next
     * destroy
     *
     */

    var counter = 0;
    var LOADING_URL = "../kernel/assets/img/sys/ajax-loader-big.gif";

    function insertLoadingImg(element){
      $(element).empty();
      $(element).append($('<div class="wizard-loading-img"><img src="' + LOADING_URL + '"></div>'));
    }

    function checkInitObj(initObj){
      if (!initObj){
        return false;
      }
      if (!initObj.model){
        initObj.model = {};
      }
      return true;
    }


    function forward(steps, currIdx, nextIdx){
      var res = true, step, i;
      for (i = currIdx; i < nextIdx; i++){
        step = steps[i].instance;
        res = (typeof step.next === 'function') && !steps[i].isHidden ? step.next() : true;
        if (!res){
          break;
        }
      }
      return res;
    }

    function backward(steps, currIdx, nextIdx){
      var res = true, step, i;
      for (i = currIdx; i > nextIdx; i--){
        step = steps[i].instance;
        res = typeof step.back === 'function' && !steps[i].isHidden ? step.back() : true;
        if (!res){
          break;
        }
      }
      return res;
    }

    function createOnStepChanging(wizard) {
      function onStepChanging(event, currIdx, nextIdx){
        var steps = wizard._steps;
        var res = true;

        if (currIdx < nextIdx){
          res = forward(steps, currIdx, nextIdx);
        } else {
          res = backward(steps, currIdx, nextIdx);
        }

        if (res && steps[nextIdx] && steps[nextIdx].instance.render && !steps[nextIdx].isHidden){
          steps[nextIdx].instance.render();
        }
        return res;
      }
      return onStepChanging;
    }

    function createOnStepChanged(wizard) {
      function onStepChanged(event, currIdx, prevIdx){
        var steps = wizard._steps;
        if (!steps[currIdx].isHidden){
          return true;
        }
        if (currIdx > prevIdx){
          wizard._wizard.steps("next");
        } else {
          wizard._wizard.steps("previous");
        }
        return true;
      }
      return onStepChanged;
    }

    function createOnFinishing(wizard) {
      function onFinishing(event, currIdx) {
        var step = wizard._steps[currIdx].instance;
        return typeof step.next === 'function' ? step.next() : true;
      }
      return onFinishing;
    }

    function renderFinishedMessage(wizard, message){
      $(wizard.element).empty();
      $(wizard.element).html("<h1>finished</h1><div>" + message + "</div" );
    }

    function createOnFinished(wizard) {
      function onFinished(event, currIdx) {
        console.log("onFinished");
        insertLoadingImg(wizard.element);
        $(wizard.element).append($("<h1>please wait</h1>"));
        if (typeof wizard.onFinished === 'function'){
          var promise = wizard.onFinished();
          promise.always(function(data){
            renderFinishedMessage(wizard, data);
          });
          return true;
        }
        renderFinishedMessage(wizard, "");
        return true;
      }
      return onFinished;
    }


    function loadAndCreateSteps(wizard, initObj){
      var steps 			= wizard._steps;
      initObj.steps.forEach(function(step, idx){
        //require(['../Wizard/steps/' + step.stepClass + '/' + step.stepClass + '.js'], function(stepClass){
        var stepClass = step.stepClass;
        var stepInstance = new stepClass({
          model:      initObj.model,
          properties: step,
          container:  steps[idx].container,
          wizard:     wizard,
          startParameters: initObj.startParameters,
          projectorId: initObj.projectorId,
          stepProperties: step.properties
        });
        steps[idx].stepClass 		= stepClass;
        steps[idx].instance			= stepInstance;
        if (idx === 0 && stepInstance.render){
          stepInstance.render();
        }
      });
    }


    function createWizardSkeleton(wizard, initObj){
      const translate = sandbox.localization.translate;
      
      var wizardId		= wizard.id = "wizard-" + counter++;
      var	wizardHtml	= $('<div id="' + wizardId + '"></div>');
      var	steps       = wizard._steps = [];

      var configurations = {
        //enableAllSteps: true,
        titleTemplate:        "#title#",
        stepsOrientation: 		"vertical",
        enableCancelButton:   initObj.enableCancelButton,
        onCanceled:           initObj.onCanceled,
        onStepChanging: 			createOnStepChanging(wizard),
        onStepChanged:        createOnStepChanged(wizard),
        onFinishing:  				createOnFinishing(wizard),
        onFinished: 					createOnFinished(wizard),
      }
      if (initObj.labels){
        configurations.labels = initObj.labels
      }

      wizard._wizard 		= wizardHtml.steps(configurations);
      wizard.onFinished = initObj.onFinished;
      $(initObj.win).on('beforeunload', initObj.onUnload || function () {});
      initObj.steps.forEach(function(step, idx){
        var container = $('<div class="wizard-step-container"></div>');
        insertLoadingImg(container);
        wizardHtml.steps("add", {
          title: 	translate(step.title) ,
          content:	container
        });
        steps[idx] = {
          container: container[0]
        }
      });
      $(initObj.element).html(wizardHtml);
    }
    /**
     *
     * @param initObj
     */
    function init(initObj) {
      if (!checkInitObj){
        return;
      }
      this.model 			= initObj.model;
      this.element 		= initObj.element;
      this.win        = initObj.win || window;
      this.initObj    = initObj;
      createWizardSkeleton(this, initObj);
      loadAndCreateSteps(this, initObj);
    }
    /**k
     *
     */
    function destroy() {
      var steps 	= this._steps;
      steps.forEach(function(step){
        if (step.instance && step.instance.destroy){
          step.instance.destroy();
        }
      });
      this._wizard.steps("destroy");
      $(this.element).empty();
    }

    function getButtonHtmlElem (wizard, name) {
      return wizard.find('a[href="#' + name + '"]').parent();
    }

    function disableButton (name) {
      var li = getButtonHtmlElem(this._wizard, name);
      li._enableAria(false);
      var menuElems = this._wizard.find('.steps li');
      var currentIndex = this._wizard.steps('getCurrentIndex');
      if (name === 'previous'){
        menuElems.slice(0, currentIndex)._enableAria(false);
      }

    }

    function enableButton (name) {
      var li = getButtonHtmlElem(this._wizard, name);
      li._enableAria();
      var menuElems = this._wizard.find('.steps li');
      var currentIndex = this._wizard.steps('getCurrentIndex');
      if (name === 'previous'){
        menuElems.slice(0, currentIndex)._enableAria(true);
      }
    }

    function hideSteps (steps, isHidden) {
      if (!isNaN(steps)){
        steps = [steps];
      }
      if (isHidden !== false){
        isHidden = true;
      }
      var stepsTabs = this._wizard.find(".steps li[role=tab]");
      var wizard = this;
      steps.forEach(function(idx){
        if (isNaN(idx)){
          return;
        }
        var tab = $(stepsTabs[idx]);
        tab.css("display", isHidden ? "none" : "block");
        wizard._steps[idx].isHidden = isHidden;
      });
    }

    function showSteps (steps) {
      this.hideSteps(steps, false);
    }

    function getCurrentStep(){
      return this._wizard.steps('getCurrentIndex');
    }

    function getInternalObject(){
      return this._wizard;
    }

    var Wizard = Class.extend({
      init:           init,
      destroy:        destroy,
      disableButton:  disableButton,
      enableButton:   enableButton,
      hideSteps:      hideSteps,
      showSteps:      showSteps,
      getCurrentStep: getCurrentStep,
      getInternalObject: getInternalObject
    }, "Wizard");
    Wizard.AbstractStep = AbstractStep;

    return Wizard;
  });