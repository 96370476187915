import React, {Component} from 'react';
import Popup from '../popup/Popup';
import TextInput from '../inputs/TextInput';
import PropTypes from 'prop-types';


class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      filter: false
    };
  }

  static propTypes = {
    value: PropTypes.string,
    options: PropTypes.array,
    separatorIndexes: PropTypes.array,
    addText: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    onAdd: PropTypes.func,
    onRemove: PropTypes.func
  };

  static defaultProps = {
    value: '',
    addText: 'Add Value',
    disabled: false,
    options: [],
    separatorIndexes: [],
    onChange: () => {
    }
  };


  handleChangeTextInput = (event, value) => {
    const {onChange} = this.props;

    this.setState({
      visible: true,
      filter: true
    });

    if (typeof onChange === 'function') {
      onChange(event, value);
    }
  };

  handleBlurTextInput = (event) => {
    const {onBlur} = this.props;

    if (typeof onBlur === 'function') {
      onBlur();
    }
  };

  filterOptions = (options, value) => {
    return options.filter(item => item && value && item.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  };

  handleAddClick = (event) => {
    const {value, onAdd} = this.props;

    onAdd(event, value.trim());
  };

  selectedItem = (event, item, index) => {
    const {onSelect} = this.props;

    this.setState({
      visible: false,
      filter: false
    });

    if (typeof onSelect === 'function') {
      onSelect(event, item, index);
    }
  };

  removeItem = (event, value, index) => {
    const {onRemove} = this.props;

    onRemove(event, value, index);

    event.stopPropagation();
  };

  renderOptions = () => {
    const {options, value, onAdd, onRemove, addText, separatorIndexes} = this.props;
    const {filter} = this.state;
    const trimedValue = value.trim();
    const filteredOptions = !filter || value === '' ? options : this.filterOptions(options, trimedValue);
    let valueInOptions = false;


    const optionsRender = filteredOptions.map((item, index) => {
      const sepClassName = filter ? '' : ((separatorIndexes.length > 0 &&
        separatorIndexes.indexOf(index) !== -1) &&
        separatorIndexes.length < options.length ? 'separator' : '');
      valueInOptions = (item && item.trim() === trimedValue) || (value === '') ? true : valueInOptions;
      const selectedClassName = item === value ? 'selected' : '';
      const removeIcon = typeof onRemove === 'function' ?
        <div
          className='crtx-removeIcon'
          title={`Remove ${item}`}
          onClick={(e) => this.removeItem(e, item, index)}
        >
          <img className='crtx-removeIcon-img' />
        </div> : '';
      return (
        <li
          key={index}
          className={`crtx-autocomplete-item ${selectedClassName} ${sepClassName}`}
          title={item}
          onClick={(e) => this.selectedItem(e, item, index)}
        >
          {item}{removeIcon}
        </li>
      );
    });

    const addButton = (typeof onAdd === 'function') && (!valueInOptions) ?
      <li
        className='crtx-autocomplete-add'
        onClick={this.handleAddClick}>
        <div className='crtx-autocomplete-add-value'>{value}</div>
        <div className='crtx-autocomplete-add-text'>{addText}</div>
      </li> : undefined;

    return (
      <ul className='crtx-autocomplete-items'>
        {optionsRender}
        {addButton}
      </ul>
    );
  };

  togglePopup = () => {
    if (!this.props.disabled) {
      this.setState({
        visible: !this.state.visible,
        filter: false
      });
    }
  };

  closePopup = () => {
    this.setState({
      visible: false
    });
  };

  render() {
    const {value, className, style, disabled, title} = this.props;
    const {visible} = this.state;
    const disabledClassName = disabled ? 'disabled' : '';


    return <div ref='autocomplete' className={`crtx-autocomplete ${className} ${disabledClassName}`} style={style}>
      <div className='crtx-autocomplete-content' onDoubleClick={this.togglePopup}>
        <TextInput
          className='crtx-autocomplete-input'
          disabled={disabled}
          value={value}
          title={title}
          onChange={this.handleChangeTextInput}
          onBlur={this.handleBlurTextInput}
        />
      </div>
      <div className={`crtx-autocomplete-handler ${disabledClassName}`} onClick={this.togglePopup}>▼</div>
      <Popup opener={this.refs.autocomplete} visible={visible} onClickedOutside={this.closePopup}>
        {this.renderOptions()}
      </Popup>
    </div>
  }
}

module.exports = Autocomplete;