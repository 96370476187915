import PropTypes from 'prop-types';
import React from 'react';
import CanvasComponent from './canvasComponent';

export default class Cross extends CanvasComponent {
  static propTypes = {
    strokeStyle: PropTypes.string,
    lineWidth: PropTypes.number,
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }).isRequired,
  };

  static defaultProps = {
    lineWidth: 1
  };

  

  draw = (ctx, canvas) => {
    const { strokeStyle, lineWidth, point, pointTo } = this.props;
    const n1 = 4;
    const n2 = 10;

    const isUndefined = o => typeof o === 'undefined';


    if (isUndefined(point.x) || isUndefined(point.y)) return;

    ctx.beginPath();
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();

    ctx.moveTo((pointTo.x - point.x) - n2, (pointTo.y - point.y));
    ctx.lineTo((pointTo.x - point.x) - n1, (pointTo.y - point.y));
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo((pointTo.x - point.x) + n1, (pointTo.y - point.y));
    ctx.lineTo((pointTo.x - point.x) + n2, (pointTo.y - point.y));
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo((pointTo.x - point.x), (pointTo.y - point.y) - n2);
    ctx.lineTo((pointTo.x - point.x), (pointTo.y - point.y) - n1);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo((pointTo.x - point.x), (pointTo.y - point.y) + n1);
    ctx.lineTo((pointTo.x - point.x), (pointTo.y - point.y) + n2);
    ctx.stroke();
    ctx.closePath();
  };
}