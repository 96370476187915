import React, { Component, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import resemblePlugin from 'resemble';
import sandbox from 'sandbox';
import PageView, { getContent, getSepContent } from '../NewPageView/NewPageView';
import { loadImage } from '../NewPageView/utilities';

const { translate } = sandbox.localization;

const missingPageFull = '../kernel/assets/img/module/PageView/missing_page.png';
const isUndefined = o => typeof o === 'undefined';

export default PageView.extend({

  initDone: function () {
    this._super();
  },

  firstTickReceived: function (data) {
    this._super(data);

    let sepNwids = { composite: getContent(data.model).nwid };
    data.model.separations.forEach(separation => {
      sepNwids = { ...sepNwids, [separation.colorType]: getSepContent(data.model, separation).nwid };
    });

    this.store.dispatch({
      type: 'COMPARE_DATA',
      compareData: {
        compareType: 'composite',
        separationNwids: sepNwids,
        type: data.model.type,
        isColorMgt: false
      }
    });
    this.handleLoadImages();
    this.disableAllToolbarButtons(true);

  },

  createMyToolbar: function (...args) {
    this._super(...args);
    this.toolbar.addItem({
      name: 'togglingBetweenVersions',
      _isApplicable: true,
      icon: 'change_circle',
      iconSprite: 'general',
      tooltip: translate('Toggling between versions'),
      execute: () => {
        this.handleTogglingBetweenVersions();
      }
    });
  },

  enableToolbarButtons: function () {
    this.toolbar.setItemChecked('singleSep', true);//compare is only single sep
    const ITEMS_TO_DISABLE = ['composite', 'cyan', 'magenta', 'yellow', 'black'];
    ITEMS_TO_DISABLE.forEach(item => this.toolbar.setItemDisabled(item, false));
  },

  disableAllToolbarButtons: function (bDisable) {
    console.log('SAll buttons: ' + bDisable);
    let items = [];
    if (bDisable) {
      items = ['composite', 'cyan', 'magenta', 'yellow', 'black', 'singleSep'];
    }
    this.toolbar.setItemChecked('singleSep', true);//compare is only single sep

    //enable/disable all buttons
    items.forEach(item => {
      this.toolbar.setItemDisabled(item, bDisable);
    });
  },

  toggleSep: function (sepKey, checked) {
    const { compareData, versions } = this.store.getState();

    if (sepKey === 'singleSep') return;
    let items = {
      checked: [],
      unchecked: []
    };

    let colorType = 'composite';//should really use colorType for the keys 
    switch (sepKey) {
      case 'composite':
        items.checked = ['cyan', 'magenta', 'yellow', 'black', 'composite'];
        items.unchecked = ['blackAsGray', 'singleSep'];
        colorType = 'composite';
        console.log('Set composite now...');
        break;
      case 'black':
        items.checked = ['black'];
        items.unchecked = ['cyan', 'magenta', 'yellow', 'blackAsGray', 'singleSep', 'composite'];
        console.log('Set Black separation now...');
        colorType = 'Black';
        break;

      case 'cyan':
        items.checked = ['cyan'];
        items.unchecked = ['black', 'magenta', 'yellow', 'blackAsGray', 'singleSep', 'composite'];
        console.log('Set Cyan separation now...');
        colorType = 'Cyan';
        break;

      case 'magenta':
        items.checked = ['magenta'];
        items.unchecked = ['cyan', 'black', 'yellow', 'blackAsGray', 'singleSep', 'composite'];
        console.log('Set Magenta separation now...');
        colorType = 'Magenta';
        break;

      case 'yellow':
        items.checked = ['yellow'];
        items.unchecked = ['cyan', 'magenta', 'black', 'blackAsGray', 'singleSep', 'composite'];
        console.log('Set Yellow separation now...');
        colorType = 'Yellow';
        break;
    }
    //set the items now
    items.checked.forEach(s => {
      this.toolbar.setItemChecked(s, true);
    });
    items.unchecked.forEach(s => {
      this.toolbar.setItemChecked(s, false);
    });

    //get the composite for the current image displayed.

    //this dispatch a new event to compare just seps
    this.store.dispatch({
      type: 'COMPARE_DATA',
      compareData: {
        compareType: colorType,
        separationNwids: compareData.separationNwids,
        type: compareData.type,
        isColorMgt: compareData.isColorMgt
      }
    });

    this.compareSelectedVersions();

  },

  //Load image from Resemble.js now
  onComparePageLoaded: function (data) {
    loadImage(data.getImageDataUrl())
      .then(image => {
        console.log('Resemble image loaded successfully' + this);
        this.enableToolbarButtons();
        this.store.dispatch({
          type: 'ADD_IMAGE',
          imageData: {
            key: 'composite',
            imageUrl: data.getImageDataUrl,
            image: image
          }
        });
        this.handleUpdateViewportPoints({ width: image.width, height: image.height });
        this.store.dispatch({
          type: 'LOAD_IMAGE',
          selectedImageKey: 'composite'
        });
      })
      .catch(() => {
        console.log('Resemble Image failed to load');
      });
  },


  handleVersionClick: function (selectedVersion) {
    const { versions, compareVersionsNumbersSelected } = this.store.getState();
    const newCompareVersionsNumbersSelected = compareVersionsNumbersSelected.map(compareVersion => compareVersion);
    if (!isUndefined(selectedVersion)) {
      if (compareVersionsNumbersSelected.includes(selectedVersion.key)) {
        const indexOfversion = compareVersionsNumbersSelected.findIndex(compareVersionNum => compareVersionNum === selectedVersion.key);
        newCompareVersionsNumbersSelected.splice(indexOfversion, 1);
      }
      else {
        if (compareVersionsNumbersSelected.length >= 2) newCompareVersionsNumbersSelected.splice(0, 1);
        newCompareVersionsNumbersSelected.push(selectedVersion.key);
      }
      this.store.dispatch({
        type: 'UPDATE_COMPARE_VERSIONS_NUMBERS_SELECTED',
        compareVersionsNumbersSelected: newCompareVersionsNumbersSelected
      });
      this.handleLoadImages();
    }
  },

  setSepUrl: function (compareURL) {
    const { compareData, content } = this.store.getState();

    //only change url for separations
    if (isUndefined(compareData.compareType)) return compareURL;

    let sepNwid = compareData.separationNwids[compareData.compareType];

    if (!isUndefined(sepNwid)) {
      const regNwidUrl = /(nwid=[^&]+&)/i;
      const regTemplateUrl = /(template=[^&]+&)/i;
      const templateStr = 'template=';
      compareURL = compareURL.replace('action=icon', 'action=full');//action to full from icon in Versions table
      compareURL = compareURL.replace(regNwidUrl, 'nwid=' + sepNwid + '&');//put in the NWID
      if (compareData.compareType === 'composite') {
        const templateStrValue = content.type === 'fragment/content' ? compareData.type : compareData.type + '/content';

        compareURL = compareURL.replace(regTemplateUrl, templateStr + templateStrValue + '&');
      } else {
        //get page/forme separation not page content
        const templateStrValue = content.type === 'fragment/content' ? 'fragment/separation/content' : compareData.type + '/separation/content';

        compareURL = compareURL.replace(regTemplateUrl, templateStr + templateStrValue + '&');
      }

      return compareURL;
    }
    return compareURL;
  },

  onCompareSelectSeparation: function () {

    if (!isUndefined(this.compareVers1) && !isUndefined(this.compareVers2)) return;

    this.compareSelectedVersions();
  },

  loadSeparations: function (data) {
    //for now don't load any seps becuase compare does not use them.
  },

  compareSelectedVersions: function () {
    const { compareVersionsNumbersSelected, versions } = this.store.getState();
    //find the selected version
    const compareVers1 = Object.values(versions).find(version => version.key === compareVersionsNumbersSelected[0]);
    const compareVers2 = Object.values(versions).find(version => version.key === compareVersionsNumbersSelected[1]);

    if (isUndefined(compareVers1) || isUndefined(compareVers2)) return;

    this.foundFirstRow = true;

    const compareVersionUrl1 = this.setSepUrl(compareVers1.blobURL);
    const compareVersionUrl2 = this.setSepUrl(compareVers2.blobURL);

    this.store.dispatch({
      type: 'UPDATE_LOADING',
      loading: true
    });
    Promise.all([
      loadImage(compareVersionUrl1),
      loadImage(compareVersionUrl2)
    ])
      .then(([imageA, imageB]) => {
        resemble.outputSettings({
          errorColor: {
            red: 255,
            green: 0,
            blue: 255
          },
          errorType: 'flat',
          transparency: 0.3
        });
        resemble(imageA.src).compareTo(imageB.src).onComplete(data => {
          this.onComparePageLoaded(data);
          this.store.dispatch({
            type: 'UPDATE_LOADING',
            loading: false
          });
        });
      })
      .catch(err => {
        this.store.dispatch({
          type: 'UPDATE_LOADING',
          loading: false
        });
      });
  },

  loadOneImage: function (imageToLoad) {
    if (!isUndefined(imageToLoad)) {
      var key = 'version' + imageToLoad.key;
      this.store.dispatch({
        type: 'UPDATE_LOADING',
        loading: true
      });
      loadImage(imageToLoad.blobURL)
        .then(image => {
          this.store.dispatch({
            type: 'ADD_IMAGE',
            imageData: {
              key: key,
              imageUrl: imageToLoad.blobURL,
              image: image
            }
          });
          this.handleUpdateViewportPoints({ width: image.width, height: image.height });
          this.store.dispatch({
            type: 'LOAD_IMAGE',
            selectedImageKey: key
          });
          this.store.dispatch({
            type: 'UPDATE_LOADING',
            loading: false
          });
        })
        .catch(() => {
          this.loadMissingPage(missingPageFull, key, true);
          this.disableAllToolbarButtons(false);
          this.store.dispatch({
            type: 'UPDATE_LOADING',
            loading: false
          });
        });
    }
  },

  handleTogglingBetweenVersions: function () {
    const { togglingBetweenCompareVersionsIndex, versions, compareVersionsNumbersSelected } = this.store.getState();
    if (compareVersionsNumbersSelected.length === 2) {
      const newTogglingBetweenCompareVersionsIndex = togglingBetweenCompareVersionsIndex === compareVersionsNumbersSelected.length - 1 ? -1 : togglingBetweenCompareVersionsIndex + 1;
      this.store.dispatch({
        type: 'UPDATE_TOOGLING_BETWEEN_COMPARE_VERSIONS_INDEX',
        togglingBetweenCompareVersionsIndex: newTogglingBetweenCompareVersionsIndex
      });

      if (newTogglingBetweenCompareVersionsIndex === -1) this.compareSelectedVersions();
      else {
        const versionToLoad = Object.values(versions).find(version => version.key === compareVersionsNumbersSelected[newTogglingBetweenCompareVersionsIndex]);
        if (!isUndefined(versionToLoad)) this.loadOneImage(versionToLoad);
      }
    }
  },

  handleLoadImages: function () {
    const { versions, compareVersionsNumbersSelected } = this.store.getState();
    if (compareVersionsNumbersSelected.length === 2) {
      this.store.dispatch({
        type: 'UPDATE_TOOGLING_BETWEEN_COMPARE_VERSIONS_INDEX',
        togglingBetweenCompareVersionsIndex: -1
      });
      this.toolbar.setItemDisabled('togglingBetweenVersions', false);
      this.compareSelectedVersions();
    }
    else if (compareVersionsNumbersSelected.length === 1) {
      this.toolbar.setItemDisabled('togglingBetweenVersions', true);
      const versionToLoad = Object.values(versions).find(version => version.key === compareVersionsNumbersSelected[0]);
      if (!isUndefined(versionToLoad)) this.loadOneImage(versionToLoad);
      this.store.dispatch({
        type: 'UPDATE_TOOGLING_BETWEEN_COMPARE_VERSIONS_INDEX',
        togglingBetweenCompareVersionsIndex: 0
      });
    }
    else {
      this.toolbar.setItemDisabled('togglingBetweenVersions', true);
      this.loadMissingPage(missingPageFull, 'composite', true);
    }
  },


});