/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define(['ember', 'sandbox'], function ( Ember, sandbox ) {
  'use strict';

  var DiffItemClasses = [ 'empty', 'inwork', 'ok', 'error', 'sep', 'waiting'],
    Component = Ember.Component.extend({
      tagName: 'li',
      fragment: {},//{id: 0, status: 0, title: ''},
      size: null,
      attributeBindings: ['title', 'style'],
      classNameBindings: ['itemClass'],
      init: function(){
        this._super();

        this.initDomElementAttributes();
      },
      initDomElementAttributes: function(){
        var fragment = this.get('fragment');
        this.set('title', fragment.name);
      },
      calcSize: function(){
        return this.get('fragment').get('type') === 'sepration' ? parseInt(this.get('size')) + 2 : this.get('size');
      }.property('size'),
      itemClass: function(){
        return DiffItemClasses[this.get('fragment').get('color')];
      }.property('fragment', 'fragment.status', 'fragment.color'),
      sizeChanged: function(){
        var style = 'width: ' + this.get('calcSize') + 'px;' +
                    ' height: ' + this.get('calcSize') + 'px;';
        if (this.get('fragment').get('type') === 'sepration') {
          style = style + ' background-image: url(' + sandbox.icons.getModuleIcon("ZoneOverview", "sepbg") +')';
        }
        this.set('style', style);
      }.observes('size').on('init'),
      itemChanged: function() {
        this.initDomElementAttributes();
      }.observes('fragment', 'fragment.status', 'fragment.title').on('init')
    });

  Ember.Handlebars.helper('fragments-list-item', Component);

  return Component;
});