import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import iconService from 'sandbox/icons';
import CollapsiblePanelContainer from './containers/collapsiblePanelContainer';
import Forms from './forms';
import ProgressBar from './progressbar';
import IndicationCounter from './indicationCounter';
import LockIndicator from './elements/indicator/lockIndicator';

export default class Books extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static defaultProps = {
    model: {},
    formDefinition: undefined,
    alignmentPoints: undefined,
    selectedItems: {}
  };

  state = {
    selectedItems: {}
  };

  getBookStyle = (controller) => {
    var display = controller.filterZoneViewType === undefined || controller.filterZoneViewType === controller.zoneViewTypes.books ?
      '' : 'none';
    return {
      display: display
    };
  };

  books = () => {
    var books = [],
      props = this.props,
      model = typeof props.model === 'undefined' ? 'undefined' : props.model,
      type = typeof model === 'undefined' || typeof model.type !== 'string' ?
        undefined :
        model.type.toLowerCase();

    if (typeof model === 'undefined') {
      return books;
    }
    if (typeof type === 'undefined') {
      return books;
    }

    if (type === 'zone') {
      books = model.books || [];
    } else if (type === 'book') {
      books.push(model);
    } else {
      books = model;
    }
    return books;
  };

  forms = (book) => {
    var forms = typeof book === 'undefined' ? undefined : book.forms;

    if (!Array.isArray(forms)) {
      return [];
    }

    return forms.filter(function (form) {
      return form.virtual === false;
    });
  };

  isBookLocked = (book) => {
    return book.locks && book.locks.length > 0;
  };

  bookLockTitle = (book) => {
    var locked = this.isBookLocked(book);
    var locks = book.locks || [];
    var distinctLocksMap = {};

    if (!locked) {
      return '';
    }

    locks.forEach(function (lock) {
      let resources = lock.resources || [];
      let assignedGroups = lock.assignedGroups || [];
      resources.forEach(function (resource) {
        distinctLocksMap[resource] = true;
      });
      assignedGroups.forEach(function (assignedGroup) {
        distinctLocksMap[assignedGroup] = true;
      });
    });

    return Object.keys(distinctLocksMap).join(', ');
  };

  renderBookLockIconIndicator = (book) => {
    var locked = this.isBookLocked(book);
    var title = this.bookLockTitle(book);

    return <LockIndicator locked={locked} title={title} />;
  };

  formDefinition = () => {
    return this.props.formDefinition;
  };

  alignmentPoints = () => {
    return this.props.alignmentPoints;
  };

  renderProgressBar = (controller, forms) => {
    const formDefinition = this.formDefinition();
    const generalProperties = formDefinition.generalProperties || {};
    const progressCount = controller.getProgressCount(forms);

    if (generalProperties.showProgressBar === false) {
      return undefined;
    }

    return <ProgressBar min={0} max={progressCount.total} current={progressCount.completed} style={{ position: 'absolute', left: 10, right: 10, marginTop: 6 }}>
      {progressCount.completed} / {progressCount.total}
    </ProgressBar>;
  };

  render() {
    var component = this,
      controller = this.context.module.ctrl,
      bookStyle = this.getBookStyle(controller),
      books = this.books().map(function (book) {
        var forms = component.forms(book),
          lockIndicatorStyle = {
            display: component.isBookLocked(book) ? '' : 'none'
          },
          progressCount = controller.getProgressCount(forms),
          title = (<div className="Book-Title">
            <div className="Book-Title-Locked-Indicator" style={lockIndicatorStyle}>
              {component.renderBookLockIconIndicator(book)}
            </div>
            <div className="Book-Title-Content" title={book.name}>
              {book.name} | {sandbox.localization.translate('Formes')}
            </div>
            <div className="Book-Title-Slider">
              {component.renderProgressBar(controller, forms)}
            </div>
            <div className="Book-Title-Icons">
              <IndicationCounter icon="reject" count={progressCount.rejected} />
              <IndicationCounter icon="error" count={progressCount.error} />
              <IndicationCounter icon="waitingForApproval" count={progressCount.waitingForApproval} />
              <IndicationCounter module="Thumbnail" icon="holdCounter" count={progressCount.onHold} />
            </div>
          </div>);


        return <div key={book.id}>
          <div className="Book" tabIndex="0" style={bookStyle}>
            <CollapsiblePanelContainer title={title} handlerIcon={iconService.getSystemIcon('arrow')}>
              <Forms bookId={book.id}
                forms={forms}
                formDefinition={component.formDefinition()}
                alignmentPoints={component.alignmentPoints()}
                selectedItems={component.state.selectedItems}>
              </Forms>
            </CollapsiblePanelContainer>
          </div>
        </div>;
      });

    return <div>
      {books}
    </div>;
  }
}

Books.propTypes = {
  formDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  model: PropTypes.object,
  forms: PropTypes.array,
};