/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define(['sandbox', 'ember'],
  function (sandbox, Ember) {
    'use strict';

    const DIFF_STATUS = {
      'empty': 0,
      'inwork': 1,
      'ok': 2,
      'error': 3,
      'sep': 4,
      'waiting': 5
    };

    function PageModel(model, status, parentId) {
      if (model === undefined || model.id === undefined || model.nwid === undefined) return undefined;
      return Ember.Object.extend({
        id: undefined,
        nwid: undefined,
        type: undefined,
        sortingDescription: undefined,
        color: function () {
          if (this.get('type') === 'sepration') return DIFF_STATUS['sep'];
          const completionState = this.get('completionState');
          let ret = -1;

          if (completionState === undefined || completionState === 'none') {
            ret = DIFF_STATUS['empty'];
          } else if (completionState === 'error' || completionState === 'reject') {
            ret = DIFF_STATUS['error'];
          } else if (completionState === 'waiting') {
            ret = DIFF_STATUS['waiting'];
          } else if (completionState === 'inProgress') {
            ret = DIFF_STATUS['inwork'];
          } else if (completionState === 'success') {
            ret = DIFF_STATUS['ok'];
          };

          return ret;
        }.property('completionState'),
        finished: function () {
          const completionState = this.get('completionState');
          return completionState === 'success';
        }.property('completionState')
      }).create({
        id: model.id,
        nwid: model.nwid,
        type: model.type || 'page',
        name: model.name || '',
        virtual: model.virtual,
        numberInSection: model.numberInSection,
        status: status,
        parentId: parentId,
        completionState: model.completionState,
        '_model': model
      });
    }

    return PageModel;
  });