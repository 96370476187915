import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getGeneralSvgIconHref } from 'core/services/iconService';
import FlatButton from 'components/common/buttons/FlatButton';
import ToggleFlatButton from 'components/common/buttons/ToggleFlatButton';
import Filter from 'components/private/filter/ProductionRunsFilter';
import Run from './Run';

const MIN_ZOOM = 0.5;
const MAX_ZOOM = 1;

function View({ module, viewModel }) {

  const { scale, plannedOnly } = viewModel;

  return (
    <div className='crtx-bins-view'>
      <div className='toolbar'>
        <Filter
          filterOptions={module.filterOptions}
          onChange={module.handleFilterChange}
          onRefresh={module.handleFilterRefresh}
        />
        <ToggleFlatButton
          className='button'
          checked={plannedOnly}
          tooltip={translate('Show planned Bins only')}
          onClick={() => module.handleClickPlannedOnly()}
        >
          <svg className='svg-icon'>
            <use xlinkHref={getGeneralSvgIconHref('remove_unused')} />
          </svg>
        </ToggleFlatButton>
        <FlatButton
          className='button'
          tooltip={translate('Zoom out')}
          disabled={scale <= MIN_ZOOM}
          onClick={module.handleClickZoomOut}
        >
          <svg className='svg-icon'>
            <use xlinkHref={getGeneralSvgIconHref('zoom_out')} />
          </svg>
        </FlatButton>
        <FlatButton
          className='button'
          tooltip={translate('Zoom in')}
          disabled={scale >= MAX_ZOOM}
          onClick={module.handleClickZoomIn}
        >
          <svg className='svg-icon'>
            <use xlinkHref={getGeneralSvgIconHref('zoom_in')} />
          </svg>
        </FlatButton>
      </div>
      <div className='runs-container'>
        {viewModel.runs.map(run => {
          return <Run key={run.nwid} module={module} scale={scale} {...run} />
        })}
      </div>
    </div>
  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
};

export default View;