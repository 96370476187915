/**
 * Created by moshemal on 9/4/14.
 */

define(['class', './../../../utils/wizardUtils', "./pairing"],function(Class,  wizardUtils, pairing){
  'use strict';
	
	function getTitle (page){
    
    var continueNumbers = this.continueNumbers;
    //var title = continueNumbers ? page.paginationInZone :
    //(page.sectionCode !== null && typeof page.sectionCode !== 'undefined') ? page.sectionCode + page.pagination : page.pagination;
    var title = continueNumbers ? page.number : page.sectionCode + page.number;
    title = continueNumbers ? page.pagination : page.sectionCode + page.pagination;
    var insertChar = page.insertChar;
    if (insertChar !== null && typeof insertChar !== "undefined"){
      title = continueNumbers ? page.pagination : page.sectionCode + page.pagination;
      title += insertChar;
    }
    var splitChar = page.split;
    if (splitChar !== null && typeof splitChar !== "undefined"){
      title += splitChar;
    }
    if (!page.fakePanorama){
      var virtualTitle = page.virtualTitle;
      if (virtualTitle !== null && typeof virtualTitle !== "undefined"){
        title = title + "-" + virtualTitle
      }
    }
    
    return title;
  }
  
    function translatePages(mappedModel, pages) {
    var ret = [];
    for (var i=0;i<pages.length;i++) {
      var items = [];
      for (var j = 0; j < pages[i].length; j++) {
        var modelPage = mappedModel[pages[i][j]];
        items.addObject(
          {
            numberInSection: modelPage.numberInSection,
            numberInBook: modelPage.numberInBook,
            numberInZone: modelPage.numberInZone,
            folioNumber: modelPage.folioNumber,
            paginationInZone: modelPage.paginationInZone,
            displayName: modelPage.displayName,
            title:getTitle.call(this, modelPage),
            sectionCode:modelPage.sectionName,
            sourceId: modelPage.sourceId,
            virtual: modelPage.virtual && !modelPage.fakeVirtual,
            virtualPage: modelPage.virtualPage,
            isDinky: modelPage.isDinky,
            halfWeb: modelPage.halfWeb,
            eightUpDinky: modelPage.eightUpDinky,
            panorama: modelPage.panorama && !modelPage.fakePanorama,
            insertChar: modelPage.insertChar,
            split: modelPage.split,
            panoramaChanged: modelPage.panoramaChanged
          }
        );
      }
      ret.addObject(items);
    }
    return ret;
  }

  function findPage(list, sourceId){
    for (var i=0;i<list.length;i++) {
      for (var j = 0; j < list[i].length; j++) {
        if (list[i][j].sourceId === sourceId){
          return list[i][j];
        }
      }
    }
    return null;
  }

  function clone(model){
    var ret = {};
    for(var propertyName in model) {
      ret[propertyName] = model[propertyName];
    }
    return ret;
  }

  function getNumberOnBook(pages, sourceId){
    for (var i=0;i<pages.length;i++){
      for (var j=0;j<pages[i].length;j++){
        if (pages[i][j].hasOwnProperty("sourceId")){
          if (pages[i][j].sourceId === sourceId){
            return i+1;
          }
        } else if (pages[i][j] === sourceId){
          return i+1;
        }
      }
    }
    return -1;
  }

  function resolveGroups(groups){
    var ret = {};
    for (var i=0;i<groups.length;i++){
      var groupName = groups[i].name;
      ret[groupName] = groups[i];
    }
    return ret;
  }

  function resolveImpositions(groups, impositions){
    var ret = {};
    var impositionNames = [];
    for (var i=0;i<groups.length;i++){
      var groupName = groups[i].name;
      var layoutLinks = groups[i].layoutLinks;
      for (var j=0;j<layoutLinks.length;j++){
        var imposition = impositions[layoutLinks[j].nwid];
        if (!imposition){
          continue;
        }
        var impositionName = layoutLinks[j].name;
        var impositionNwid = layoutLinks[j].nwid;
        // TODO need to valid layout
        if (!ret.hasOwnProperty(groupName)){
          ret[groupName] = [];
        }
        ret[groupName].addObject({value:impositionNwid, text:impositionName, cellGrid:imposition.cellGrid});
      }
    }
    return ret;
  }

  function sortPmtByType(pmtInfo){
    var ret = {};
    Object.keys(pmtInfo).forEach(function (pmt) {
      var type = pmtInfo[pmt];
      if (!ret.hasOwnProperty(type)){
        ret[type] = [];
      }
      ret[type].addObject({value:pmt, text:pmt});
    });
    return ret;
  }

  function resolveContentKey(pages){
    var ret = ""
    var that = this;
    pages.forEach(function(pageSourceId){
      var page = that.mappedModel[pageSourceId];
      if (typeof page !== 'undefined'){
        var isLocal = false;
        for (var i=0;i<page.separations.length;i++){
          var separation = page.separations[i];
          var masterSeparation = separation.masterSeparation;
          if (masterSeparation === null || typeof masterSeparation === 'undefind') {
            isLocal = true;
            break;
          }
        }
        ret += isLocal ? page.sourceId : page.masterPage;
      }
    });
    return ret;
  }

  function validModel(zones){

    return true;
  }

  function createForm(mappedModel, bookModel, stepForm){
    var form = {layoutType:stepForm.layoutType, displayName:stepForm.displayName, sourceId:stepForm.sourceId, layoutGroup:stepForm.layoutGroup};
    form.layout = stepForm.defaultLayout;
    form.layoutNwId = stepForm.layoutNwId;
    var pagesSourceIds = [];
    var modelPages = [];
    stepForm.pages.forEach(function (page){
      var modelPage = mappedModel[page.sourceId];
      modelPages.push(modelPage);
      if (typeof modelPage !== 'undefined' && modelPage !== null){
        modelPage.isDinky = page.isDinky ? true : false;
        modelPage.halfWeb = page.halfWeb ? true : false;
        modelPage.eightUpDinky = page.eightUpDinky ? true : false;
        pagesSourceIds.push(page.sourceId);
      } else {
        pagesSourceIds.push("$empty$");
      }
    });
    form.pages = pagesSourceIds;
    pairing.updateFormSeparations(form, modelPages);
    return form;
    //  updateFormSeparations(form, formPages);
  }

  function canIBeEightUpDinky(mappedModel, page, pages){
    if (page === null || typeof page === 'undefined'){
      return false;
    }
    var min = 9999, minPage;
    var pageNumber = page.numberInBook;
    pages.forEach(function (sourceId){
      if (sourceId === null || typeof sourceId === 'undefined' || sourceId === '$empty$'){
        return;
      }
      var n = mappedModel[sourceId].numberInBook;
      if (n < min && n % 2 > 0){
        min = n;
        minPage = mappedModel[sourceId];
      }
    });
    return minPage.sourceId === page.sourceId && min % 2 > 0;
  }

  function canIBeDinky(dinkyIdx, numberOnBook, n){
    if (dinkyIdx === -2 && numberOnBook > n/2){
      return true;
    } else if (dinkyIdx === -4 && numberOnBook < n/2){
      return true;
    } else if (dinkyIdx === -5){
      return true;
    } else if (numberOnBook % 2 > 0){
      return true;
    }
    return false;
  }

  return Class.extend({

    //selectedZone: null,
    //
    //selectedEdition : null,

    mappedModel: {},
    //copyDinky.call(this.modelHandler, this.selectedPageToCut, formModel);
    copyDinky: function (page, form){
      var stepZone = this.formsToBooks[form.sourceId];
      var zoneSourceId  = stepZone.sourceId;
      var bookName = stepZone.bookName;
      var formStructID = form.structID; 
      var originalForm = form;

      var extraDinkyPages = stepZone.extraDinkyPages || [];
      for (var i=0;i<form.pages.length;i++){
        if (form.pages[i].sourceId === '$empty$'){
          continue;
        }
        page.pageOnFormsourceId = form.pages[i].sourceId;
        break;
      }
      extraDinkyPages.addObject (page);   
      stepZone.extraDinkyPages = extraDinkyPages;   
    },

    rebuildForms: function (form, search = true){
      var stepZone = this.formsToBooks[form.sourceId];
      var zoneSourceId  = stepZone.sourceId;
      var bookName = stepZone.bookName;
      var formStructID = form.structID; 
      var originalForm = form;
      var layoutInfo = this.model.pmtMode ? {pmtDescription: this.model.pmtDescription, pmtMode:true} :
      {pmtMode:false, impositions:this.model.impositions, groups:resolveGroups(this.model.groups)};

      var oldForms = stepZone.forms.reduce(function ( total, current ) {
        total[ current.sourceId ] = current;
        return total;
      }, {}); 

      if (this.model.clientSettings && this.model.clientSettings.formNameMode){
        layoutInfo.formNameMode = this.model.clientSettings.formNameMode;
      } else {
        layoutInfo.formNameMode = "number";
      }
      pairing.defaultForms(layoutInfo, stepZone, stepZone.pages, false);
      var dinkyIdx = pairing.validDinkies(stepZone.pages);
      var newStepForms = [];
      var that = this;
      stepZone.forms.forEach(function (form) {

        var oldForm = oldForms[form.sourceId];
        if (oldForm){
          that.customFields.forEach( function (field){
            form[field.name] = oldForm[field.name];
          });
        }

        var structID = bookName + ":";
        var myPages = form.pages.slice(0);
        var pages = [];
        for (var i=0;i<myPages.length;i++){
          var page = findPage(stepZone.pages, myPages[i]);//that.mappedModel[myPages[i]];
          if (typeof page === 'undefined' || page === null){
            pages.addObject({sourceId:"$empty$"});

          } else {
            var numberOnBook = getNumberOnBook(stepZone.pages, page.sourceId);
            var index = [i];
            if (typeof form.indexes !== 'undefined'){
              index = form.indexes[i];
            }
            var masterPageZone = that.mappedModel[myPages[i]].masterPageZone || stepZone.code;
            structID += masterPageZone + numberOnBook + ":"                
            pages.addObject(
              {
                title:page.title,
                sectionCode:page.sectionCode,
                sourceId:page.sourceId,
                index:index,
                isDinky: page.isDinky,
                halfWeb: page.halfWeb,
                eightUpDinky: page.eightUpDinky,
                panorama: page.panorama && !page.fakePanorama,
                virtualPage: page.virtualPage,
                numberOnBook: numberOnBook,
                paginationInZone: page.paginationInZone,
                numberInZone: page.numberInZone,
                numberInBook: page.numberInBook,
                folioNumber: page.folioNumber,
                insertChar: page.insertChar,
                panoramaChanged: page.panoramaChanged,
                split: page.split,
                isValidForDinky: form.layoutType === '8ups' ?  canIBeEightUpDinky(that.mappedModel, page, form.pages) :
                  canIBeDinky(dinkyIdx, numberOnBook, stepZone.pages.length)
                //isValidForDinky: canIBeDinky(dinkyIdx, numberOnBook, stepZone.pages.length)
              }
            );
          }

        }

        var stepForm = {
          displayName:form.displayName,
          sourceId:form.sourceId,
          pages:pages,
          layout: form.layout,
          layoutNwId: form.layoutNwId,
          layoutGroup: form.layoutGroup,
          layoutType: form.layoutType,
          rows: form.rows,
          columns: form.columns,
          contentKey: resolveContentKey.call(that, form.pages),
          structID: form.layoutGroup + ":" + structID
        };
        that.formsToBooks[stepForm.sourceId] = stepZone;
        newStepForms.addObject(stepForm);
      });
      Ember.set(stepZone, "forms", newStepForms);

      if (!search){
        return;
      }
      var that = this;
      Object.keys(this.stepModel).forEach(function(bookName){
        var stepBook = that.stepModel[bookName];
        stepBook.zones.forEach(function(stepZone){
          if (stepZone.sourceId !== zoneSourceId){            
            stepZone.forms.forEach(function(form){
              if (form.structID === formStructID){
                form.originalForm = originalForm.layout;
                form.layoutNwId = originalForm.layoutNwId;
                form.layoutGroup = originalForm.layoutGroup;
                form.layoutType = originalForm.layoutType;
                that.rebuildForms(form, false);
              }
            });  
          }
        });
        
      });

    },

    updateDinkey: function (page, form){      
      var that = this;
      var stepZone = this.formsToBooks[form.sourceId];
      var prevDinky = null;
      for (var i=0;i<stepZone.pages.length;i++){
        for (var j=0;j<stepZone.pages[i].length;j++){
          if (stepZone.pages[i][j].sourceId === page.sourceId){
            stepZone.pages[i][j].isDinky = page.isDinky;
            stepZone.pages[i][j].halfWeb = page.halfWeb;
            stepZone.pages[i][j].eightUpDinky = page.eightUpDinky;
          } else {
            if (stepZone.pages[i][j].isDinky && prevDinky === null){
              prevDinky = {sourceId:stepZone.pages[i][j].sourceId, revert:true, isDinky:true};
            }
            //stepZone.pages[i][j].isDinky = false;
          }
        }
      }

      var layoutInfo = this.model.pmtMode ? {pmtDescription: this.model.pmtDescription, pmtMode:true} :
        {pmtMode:false, impositions:this.model.impositions, groups:resolveGroups(this.model.groups)};
      if (this.model.clientSettings && this.model.clientSettings.formNameMode){
        layoutInfo.formNameMode = this.model.clientSettings.formNameMode;
      } else {
        layoutInfo.formNameMode = "number";
      }
      pairing.defaultForms(layoutInfo, stepZone, stepZone.pages, false);
      var dinkyIdx = pairing.validDinkies(stepZone.pages);
      //if (dinkyIdx !== 0 && prevDinky !== null){
      //  this.updateDinkey(prevDinky, form);
      //  return;
      //}


      var newStepForms = [];

      stepZone.forms.forEach(function (form) {
        var myPages = form.pages.slice(0);
        var bookName = stepZone.bookName;
        var pages = [];
        for (var i=0;i<myPages.length;i++){
          var page = findPage(stepZone.pages, myPages[i]);//that.mappedModel[myPages[i]];
          if (typeof page === 'undefined' || page === null){
            pages.addObject({sourceId:"$empty$"});
          } else {
            var structID = bookName + ":";
            var numberOnBook = getNumberOnBook(stepZone.pages, page.sourceId);
            var index = [i];
            if (typeof form.indexes !== 'undefined'){
              index = form.indexes[i];
            }
            var masterPageZone = that.mappedModel[myPages[i]].masterPageZone || stepZone.code;
            structID += masterPageZone + numberOnBook + ":"
            pages.addObject(
              {
                title:page.title,
                sectionCode:page.sectionCode,
                sourceId:page.sourceId,
                index:index,
                isDinky: page.isDinky,
                halfWeb: page.halfWeb,
                eightUpDinky: page.eightUpDinky,
                panorama: page.panorama && !page.fakePanorama,
                virtualPage: page.virtualPage,
                numberOnBook: numberOnBook,
                paginationInZone: page.paginationInZone,
                numberInZone: page.numberInZone,
                insertChar: page.insertChar,
                split: page.split,
                panoramaChanged: page.panoramaChanged,
                folioNumber: page.folioNumber,
                numberInBook: page.numberInBook,
                isValidForDinky: form.layoutType === '8ups' ?  canIBeEightUpDinky(that.mappedModel, page, form.pages) :
                  canIBeDinky(dinkyIdx, numberOnBook, stepZone.pages.length)
                //isValidForDinky: canIBeDinky(dinkyIdx, numberOnBook, stepZone.pages.length)
              }
            );
          }

        }

        var stepForm = {
          displayName:form.displayName,
          sourceId:form.sourceId,
          pages:pages,
          layout: form.layout,
          layoutNwId: form.layoutNwId,
          layoutGroup: form.layoutGroup,
          layoutType: form.layoutType,
          contentKey: resolveContentKey.call(that, form.pages),
          structID: form.layoutGroup + ":" + structID
        };
        that.formsToBooks[stepForm.sourceId] = stepZone;
        newStepForms.addObject(stepForm);
      });
      var dinkyState = pairing.isValid(stepZone.forms, stepZone.layoutType);
      Ember.set(stepZone, "forms", newStepForms);
      Ember.set(stepZone, "dinkyState", dinkyState);
      var stepBook = this.stepModel[stepZone.bookName];
      Ember.set(stepBook, "dinkyState", !(Ember.get(stepBook, "dinkyState")));
    },

    convertToStepModel: function (model){      
      this.mappedModel = wizardUtils.mapSourceIdToObject(model);
      this.formsToBooks = {};
      var that = this;
      var stepModel = {}, contents = {};
      var publication = Ember.get(model, "publication");
      var editions = Ember.get(publication, "editions");
      var counter = 0;
      editions.forEach ( function (edition){
        edition.zones.forEach (function (zone) {
          zone.books.forEach (function (book){
            var bookModel;
            if (stepModel.hasOwnProperty(book.displayName)){
              bookModel = stepModel[book.displayName];
            } else {
              bookModel = {displayName:book.displayName, zones:[], order:counter, hidden:book.hidden};
              stepModel[book.displayName] = bookModel;
              Ember.set(bookModel, "dinkyState", true);
              counter++;
            }
            var stepZone = {displayName:zone.displayName, sourceId:book.sourceId, forms:[], zoneSourceId:zone.sourceId, defaultLayout:book.defaultLayout, code:zone.code, hidden:zone.hidden};
            stepZone.pages = translatePages.call(that, that.mappedModel, book.pages);
            stepZone.bookName = book.displayName;
            var dinkyIdx = pairing.validDinkies(stepZone.pages);
            stepZone.layoutType = book.layoutType;
            stepZone.pmiTemplate = book.pmiTemplate;
            stepZone.pmiTemplateDescription = book.pmiTemplateDescription;
            
            book.forms = book.forms.filter(function(f) { return !f.virtual })

            book.forms.forEach(function (form){

              if ((typeof form.indexes === 'undefined' || form.indexes === null) &&
                form.layoutType === '4ups' || form.layoutType === '8ups'){
                var formPages = [];
                for (var i=0;i<form.pages.length;i++) {
                  var page = that.mappedModel[form.pages[i]];
                  formPages.push(page);
                }
                form.indexes = pairing.buildIndexes(formPages);
              }

              var structID = book.displayName + ":";
              var pages = [];
              for (var i=0;i<form.pages.length;i++){
                var page = that.mappedModel[form.pages[i]];
                if (typeof page === 'undefined'){
                  page = {sourceId:"$empty$"};
                }
                var numberOnBook = getNumberOnBook(book.pages, page.sourceId);
                var masterPageZone = page.masterPageZone || stepZone.code;
                structID += masterPageZone + numberOnBook + ":"                
                var index = [i];
                if (typeof form.indexes !== 'undefined'){
                  index = form.indexes[i];
                }
                pages.addObject(
                  {
                    title:getTitle.call(that, page),
                    sectionCode:page.sectionName,
                    sourceId:page.sourceId,
                    index:index,
                    numberOnBook:numberOnBook,
                    isDinky: page.isDinky,
                    halfWeb: page.halfWeb,
                    eightUpDinky: page.eightUpDinky,
                    panorama: page.panorama && !page.fakePanorama,
                    virtualPage: page.virtualPage,
                    paginationInZone: page.paginationInZone,
                    numberInZone: page.numberInZone,
                    insertChar: page.insertChar,
                    split: page.split,
                    panoramaChanged: page.panoramaChanged,
                    folioNumber: page.folioNumber,
                    numberInBook: page.numberInBook ? page.numberInBook : numberOnBook,
                    dinkyMode: dinkyIdx,
                    isValidForDinky: form.layoutType === '8ups' ?  canIBeEightUpDinky(that.mappedModel, page, form.pages) :
                        canIBeDinky(dinkyIdx, numberOnBook, stepZone.pages.length)
                  });
              }
              var stepForm = {
                displayName:form.displayName,
                sourceId:form.sourceId,
                pages:pages,
                layout: form.layout,
                layoutType: form.layoutType,
                layoutGroup: form.layoutGroup,
                layoutNwId: form.layoutNwId,
                rows: form.rows,
                columns: form.columns,
                contentKey: resolveContentKey.call(that, form.pages),
                structID: form.layoutGroup + ":" + structID
              };

              that.customFields.forEach( function (field){
                let formProp = form[field.name];
                if (field.defaultFromBook){
                  let bookProp = book[field.name];
                  let oldBookProp = book["_" + field.name + "_oldValu"];
                  if (oldBookProp && oldBookProp != bookProp){
                    formProp = formProp && formProp == oldBookProp ? bookProp : 
                      formProp = formProp && formProp != bookProp ? formProp : bookProp;
                  } else if (bookProp){
                    formProp = formProp && formProp != bookProp ? formProp : bookProp;
                  }
                }                
                stepForm[field.name] = formProp ? formProp : "";
              });
              that.formsToBooks[stepForm.sourceId] = stepZone;
              if (!contents.hasOwnProperty(stepForm.contentKey)){
                stepForm.contentKey = [];
              }
              stepForm.contentKey.addObject(stepForm);
              stepZone.forms.addObject(stepForm);
            });
            var dinkyState = pairing.isValid(stepZone.forms, stepZone.layoutType);
            Ember.set(stepZone, "dinkyState", dinkyState);
            bookModel.zones.addObject(stepZone);
          });
        });
      });

      var books = [];
      Object.keys (stepModel).forEach(function (key){
        books.addObject(stepModel[key]);
      });
      books.sort(function (b1, b2){
        return parseInt(b1.order, 10) - parseInt(b2.order, 10);
      });
      this.stepModel = stepModel;
      var pmtMode = model.pmtMode;
      if (pmtMode){
        return {pmtMode: pmtMode, books: books, pmtInfo:model.pmtInfo, pmtByType:sortPmtByType(model.pmtInfo)};
      }
      var ret = {books: books, optionalImpositions:resolveImpositions(model.groups, model.impositions)};
      ret.pmtMode = pmtMode;
      if (ret.clientSettings && ret.clientSettings.formNameMode){
        ret.formNameMode = ret.clientSettings.formNameMode;
      } else {
        ret.formNameMode = "number";
      }

      ret.impositions = model.impositions;
      ret.groups = resolveGroups(model.groups);
      return ret;
    },

    convertFromStepModel: function (model){
      var stepModel = this.stepModel;
      var j;
      if (!validModel(stepModel)){
        return false;
      }

      var that = this;
      Object.keys(stepModel).forEach(function(bookName){
        var genBook = stepModel[bookName];
        genBook.zones.forEach(function(stepZone){
          var zoneBookSourceId = stepZone.sourceId;
          var zoneSourceId = stepZone.zoneSourceId;

          var zone = that.mappedModel[zoneSourceId];
          var book = that.mappedModel[zoneBookSourceId];
          var isBroadsheet = "broadsheet" === book.layoutType;
          var virtualPages = {};
          if (isBroadsheet){
            zone.sections.forEach(function(section){
              section.pages.forEach(function(page){
                if (page.virtual){
                  virtualPages[page.panoramaPage] = page;
                }
              });
            });
          }

          var fields = [];
          that.customFields.forEach(function (h){
            fields.push(h.name);
          });
          book.formCustomFields = fields;
          var existForms = {};
          book.forms.forEach(function (form) {
            existForms[form.sourceId] = 0;
          });
          var stepForms = stepZone.forms;
          var virtualForms = [];
          stepForms.forEach(function(stepForm){
            
            var form = that.mappedModel[stepForm.sourceId];

            if (form === null || typeof form ==='undefined'){
              form = createForm(that.mappedModel, book, stepForm);
              book.forms.addObject(form);
            } else {
              existForms[form.sourceId] = 1;
              var sourceIds = [];
              stepForm.pages.forEach(function(page){
                sourceIds.push(page.sourceId);
              });
              form.pages = sourceIds;

              form.rows = stepForm.rows;
              form.columns = stepForm.columns;
            }

            if (form.pages.length > 0 && virtualPages[form.pages[0]]){
              var virtualPage = virtualPages[form.pages[0]];
              var virtualForm = {displayName: virtualPage.displayName, virtual: true};
              virtualForm.minNumberInBook = virtualPage.numberInBook;
              virtualForms.push(virtualForm);
            }
            that.customFields.forEach( function (field){
              form[field.name] = stepForm[field.name] ? stepForm[field.name] : "";
            });
            form.layoutNwId = stepForm.layoutNwId;
            if (that.model.impositions){
              var imposition = that.model.impositions[form.layoutNwId];
              form.layout = imposition.name;
              //form.layoutNwId = imposition.nwid;
            }

            var format = "Broadsheet";
            if (form.layoutType === "tabs" || form.layoutType === "4ups"){
              format = "Tabloid";
            } else if (book.pmiTemplate && book.pmiTemplate.length > 0){
              var y = parseInt(form.rows, 10);
              var x = parseInt(form.columns, 10);
              if (y == 1 && x <= 2){
                format = "Broadsheet";
              } else {
                format = "Tabloid";
              }
            }
            form.pages.forEach( function (page){
              var modelPage = that.mappedModel[page];
              if (modelPage !== null && typeof modelPage !=='undefined') {
                modelPage.format = format;
              }
            });
          });
          Object.keys(existForms).forEach(function(key){
            if (existForms[key] === 0){
              var form = wizardUtils.matchBySourceId(key, book.forms);
              if (form !== null){
                book.forms.removeObject(form);
              }
            }
          });                        
          if (virtualForms.length > 0){
            book.forms = book.forms.concat(virtualForms);
            book.forms.sort (function(a, b){return a - b});
          }
        });        
      });


      var publication = Ember.get(model, "publication");
      var editions = Ember.get(publication, "editions");
      var counter = 0;
      var formNameMode = (this.model.clientSettings && this.model.clientSettings.formNameMode) ?
        this.model.clientSettings.formNameMode : "";
      editions.forEach ( function (edition){
        edition.zones.forEach (function (zone) {
          var numInZone = 0;
          zone.books.forEach(function(book){
            for (var i=0;i<book.forms.length;i++){
              book.forms[i].numberInBook = i+1;
              book.forms[i].numberInZone = ++numInZone;

              if (formNameMode === "numberInZone"){
                book.forms[i].displayName = book.forms[i].numberInZone;
              } else if (formNameMode === "runningNumber"){
                book.forms[i].displayName = book.forms[i].numberInBook;
              }
            }
          });
        });
      });


      return true;

    },

    setModel: function (model){
      Ember.set(this, 'model', model);
      this.continueNumbers = this.model.continueNumbers;
      this.sourceIdBreadcrumb = this.model.clientSettings && this.model.clientSettings.sourceIdBreadcrumb ? this.model.clientSettings.sourceIdBreadcrumb : false; 
      this.namingPattern = this.model.clientSettings && this.model.clientSettings.pageNamingPattern ? this.model.clientSettings.pageNamingPattern :
        [];
	
    },

    customFields: [],

    isValid: function(){

      return true;
    },
    //
    //zones: function (){
    //  var ret = [];
    //  var editions = Ember.get(this, 'model.editions');
    //  for (var i=0;i<editions.length;i++){
    //    var zones = editions[i].zones;
    //    ret.pushObjects(zones);
    //  }
    //  return ret;
    //},
    //

    notifyPropertyChange: function (section, property, value){

    },



    applyAll: function (){


    }


  });
});
