import React from 'react';
import Ember from 'ember';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import { fetchAndSetContentBreadcrumbs } from 'utilities/breadcrumbs';
import colorService from 'core/services/colorService';
import settingsManager from 'core/managers/settings';
import request from 'core/managers/request';
import { getPageViewPreferences, savePageViewPreferences } from 'core/managers/preferences';
import { createToastService } from 'core/services/toastService';
import sandbox from 'sandbox';
import AbstractModule from 'AbstractModule';
import { arrayToObject } from 'utilities/array';
import reducer from './reducers/softproof';
import { CanvasImage } from 'components/common/canvas';
import PageView from './components/PageView';
import { loadImage, getGraySource, mergeImages, rgb2cmyk, centerPoint } from './utilities';
import TickableModel from '../TickableModel';
import { updateLoading } from './actionsCreator';
import { retriveAndSetEditionNavigatorItems, retriveAndSetSectionNavigatorItems } from './navigatorControls';
import { IntervalWorker } from 'utilities/IntervalWorker';
import dialogService from 'core/services/dialogService';
import { translate } from 'core/services/localization';
import { getParentViewRoot } from 'utilities/view';

const isFunction = o => typeof o === 'function';
const isUndefined = o => typeof o === 'undefined';
const isHold = holdType => !(isUndefined(holdType) || holdType === '' || holdType === 'none');
const propExists = (obj, path) => {
  return !!path.split('.').reduce((obj, prop) => {
    return obj && obj[prop] ? obj[prop] : undefined;
  }, obj);
};
const missingPageFull = '../kernel/assets/img/module/PageView/missing_page.png';
const MEDIA_BOX_COLOR = 'purple';
const CROP_BOX_COLOR = 'black';
const BLEED_BOX_COLOR = 'blue';
const TRIM_BOX_COLOR = 'green';
const ART_BOX_COLOR = 'red';
const DEFAULT_COLOR = '#FE0056';
const MAX_ZOOM = 15;
const MIN_ZOOM = 0.07;
const VIEW_TYPE_COMPARE = 'Compare';
const VIEW_TYPE_PAGE_VIEW = 'PageView';
const VIEW_TYPE_FORM_VIEW = 'FormView';
const VIEW_TYPE_FRAGMENT_VIEW = 'FragmentView';
const VIEW_TYPE_HIRES_VIEW = 'HiresView';
const WIDTH = 'width';
const HEIGHT = 'height';
const MILLIMETER = 'mm';
const INCH = 'inch';
const POINT = 'pt';
const MILLIMETER_PRECISION = 2;
const INCH_PRECISION = 3;
const POINT_PRECISION = 0;
const BOTTOM_LEFT_REFERENCE_POINT = 'bottom-left';
const CMYK_SEPARATIONS = ['cyan', 'magenta', 'yellow', 'black'];
const PDF_BOXES_ORDER = ['mediabox', 'artbox', 'cropbox', 'trimbox', 'bleedbox'];
const LOADING_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  LOADING: 'LOADING',
  LOADED: 'LOADED'
};
const CELL_LABEL_PROPS = {
  font: '256px serif',
  fillStyle: '#ffff00',
  strokeStyle: '#002456',
  lineWidth: 6,
  centered: true,
};

const ROTATION_SPEED = 15;

export const getContent = (model) => {
  return model.type === 'fragment/content' ? model : model.content;
};

export const getSepContent = (model, separation) => {
  return model.type === 'fragment/content' ? separation : separation.separationContent;
};

function arrangePdfBoxes(pdfBoxes) {
  const boxesByName = arrayToObject(pdfBoxes, 'label');
  const arrangedBoxes = PDF_BOXES_ORDER.reduce((acc, boxName) => {
    if (boxesByName[boxName]) {
      acc.push(boxesByName[boxName]);
      delete boxesByName[boxName];
    }

    return acc;
  }, []);

  for (let name in boxesByName) {
    arrangedBoxes.push(boxesByName[name]);
  }

  return arrangedBoxes;
}

function getBoxColor(box) {
  if (box.color) {
    return box.color;
  }

  switch (box.label) {
    case 'mediabox':
      return MEDIA_BOX_COLOR;
    case 'cropbox':
      return CROP_BOX_COLOR;
    case 'bleedbox':
      return BLEED_BOX_COLOR;
    case 'trimbox':
      return TRIM_BOX_COLOR;
    case 'artbox':
      return ART_BOX_COLOR;
    default:
      return DEFAULT_COLOR;
  }
}

function composeColorManagementKey(versionNumber) {
  return 'CMversion' + (versionNumber || '');
}

const colorTableMap = colorService.getAllColors().reduce((acc, color) => {
  const name = color.name.toLowerCase();
  if (!acc[name]) {
    acc[name] = color;
  }

  return acc;
}, {});

const isSepApplicable = (cmykNameToTypeMap, sep) => Object.values(cmykNameToTypeMap).includes(sep);

const stripPrefix = str => str && str.startsWith('spot_') ? str.substring(5) : str;

export default AbstractModule.extend({

  allowReplaceProjector: true,

  init: function () {
    this._super();

    //this binds every function so we don't need to bind anywhere else
    for (var key in this) {
      if (key !== '_super' && isFunction(this[key])) {
        this[key] = this[key].bind(this);
      }
    }
  },

  initDone: function () {
    this.toastService = createToastService(this.win);
    this.store = createStore(reducer, {
      windowNwid: this.nwid,
      viewId: this.id,
      windowRef: this.win,
      firstTickData: undefined,
      mainCanvasWidth: 0,
      mainCanvasHeight: 0,
      showNavigator: false,
      versions: {},
      rotation: 0,
      rotationDegree: 0,
      zoom: 1,
      isDistanceTool: false,
      isMoveTool: true,
      isDensityTool: false,
      densityData: {
        cyan: 0,
        magenta: 0,
        yellow: 0,
        black: 0,
        totalInkCoverage: 0
      },
      isMouseOnNavigator: false,
      flipHorizontal: false,
      flipVertical: false,
      measurementUnit: settingsManager.getLengthUnit(),
      images: {},
      showMediaBoxes: false,
      showPDFBoxes: false,
      showGuidelines: false,
      showPageLabels: false,
      showVersions: true,
      compareData: {},
      overlayBoxes: {},
      guidelines: [],
      gridCells: [],
      resolution: {
        xResolution: 100,
        yResolution: 100
      },
      offsetPoint: {
        x: 0,
        y: 0
      },
      mouseLastPosition: {
        x: undefined,
        y: undefined
      },
      mouseDensityPosition: {
        x: undefined,
        y: undefined
      },
      distanceToolPoints: {
        point: undefined,
        pointTo: undefined,
        measurementData: {
          fromX: undefined,
          fromY: undefined,
          toX: undefined,
          toY: undefined,
          w: undefined,
          h: undefined,
          d: undefined,
          pw: undefined,
          ph: undefined
        }
      },
      activeVersionNumber: undefined,
      selectedVersionNumber: undefined,
      content: undefined,
      isHold: 'none',
      viewType: '',
      compareVersionsNumbersSelected: [],
      togglingBetweenCompareVersionsIndex: -1,
      loading: false,
      colorManagement: false,
      hiresData: {},
      imageType: undefined,
      pageAction: undefined,
      separations: [],
      viewportPoints: {},
      loadingTiles: {},
      hiresNavigatorImage: undefined,
      loadingStatus: LOADING_STATUS.NOT_STARTED,
    });

    this.win.navigationState = this.win.navigationState || {};
    this.rotateWorker = new IntervalWorker();
    this.tickableModel = new TickableModel();
    this.tickableModel.register(['page', 'page/content'], this.handlePageContentTick);
    this.tickableModel.register(['page'], this.handlePageTick);
    this.tickableModel.register(['form'], this.handlePageTick);
    this.tickableModel.register(['form/content'], this.handlePageContentTick);
    this.tickableModel.register(['fragment/content'], this.handlePageTick);
    this.tickableModel.register(['fragment/separation/content'], this.handlePageSeparationContent);
    // this.tickableModel.register(['mainversion'], this.handleMainVersionTick);
    this.tickableModel.register(['page/separation'], this.handlePageSeparation);
    this.tickableModel.register(['page/separation', 'page/separation/content'], this.handlePageSeparationContent);
    this.store.subscribe(this.render);
    this.reactRoot = createRoot(this.domElement);

    this.render();
  },

  updateSpotColorsInToolbar: function (separations, cmykNameToTypeMap) {
    if (!separations) {
      return;
    }
    this.spotColors = separations.filter(sep => !cmykNameToTypeMap[sep.name]);
    if (this.spotColors.length > 0) {
      this.spotColors.forEach(spot => {
        const rgbObj = colorTableMap[spot.name.toLowerCase()]?.rgb || {};
        const iconStyle = {
          color: `rgb(${rgbObj.red}, ${rgbObj.green}, ${rgbObj.blue})`,
        };
        const spotName = `spot_${spot.name}`;
        this.toolbar.addItem({
          name: spotName,
          itemType: 'push',
          _isApplicable: true,
          icon: 'color_separation',
          iconSprite: 'general',
          iconStyle,
          tooltip: translate("'{1}' separation", spot.name),
          groupName: 'tool',
          checked: true,
          execute: (checked) => {
            this.toggleSep(spotName, checked);
          }
        });
      });
    }
  },

  setBreadcrumbs: function (model) {
    fetchAndSetContentBreadcrumbs(this, getContent(model));
  },

  firstTickReceived: function (data) {
    this.rootMismatch = data.model.nwid !== this.viewSettings.rootId;
    if (this.rootMismatch) {
      return;
    }

    this.setBreadcrumbs(data.model);

    this.store.dispatch(updateLoading(false));

    this.store.dispatch({
      type: 'LOADING_STATUS',
      loadingStatus: LOADING_STATUS.NOT_STARTED
    });

    this.missingPage = false;

    const { windowRef } = this.store.getState();
    // this.passedFirstTick = false;
    this.iconUrlCounter = 0;
    //save this value for updates
    getContent(data.model).versionNwid ? this.lastContentVersionNwid_Saved = '000' : this.lastContentVersionNwid_Saved = getContent(data.model).versionNwid;

    this.tickableModel.firstTickHandler(data.model);

    const { model: { separations } } = data;

    const cmykNameToTypeMap = separations.reduce((acc, sep) => {
      if (!acc[sep.name] && sep.colorType !== 'Spot' && colorTableMap[sep.name.toLowerCase()]?.default) {
        acc[sep.name] = sep.colorType?.toLowerCase();
      }

      return acc;
    }, {});
    const separationToolbarNames = separations.map(sep => cmykNameToTypeMap[sep.name] || `spot_${sep.name}`);

    const { viewType } = data.model;

    this.store.dispatch({
      type: 'LOAD_INITIAL_DATA',
      isHold: isHold(data.model.holdType),
      content: getContent(data.model),
      viewType,
      imageType: data.model.imageType,
      pageAction: data.model.pageAction,
      cmykNameToTypeMap,
      separations,
      separationToolbarNames,
      images: {},
      versions: {},
      activeVersionNumber: undefined,
      selectedVersionNumber: undefined
    });

    //set the saved preferences
    this.setStartupState(data);

    if (!this.toolbar) {
      this.createMyToolbar(separations, cmykNameToTypeMap);
    } else {
      CMYK_SEPARATIONS.forEach(sep => {
        this.toolbar.setItemChecked(sep, true);
        const item = this.toolbar.items.findBy('name', sep);
        const isApplicable = isSepApplicable(cmykNameToTypeMap, sep);
        item._isApplicable = isApplicable;
        item.action._isApplicable = isApplicable;
      });
      this.toolbar.setItemChecked('singleSep', false);
      if (this.spotColors) {
        this.toolbar.items = this.toolbar.items.filter(item => !(item.name || '').startsWith('spot_'));
      }
      viewType !== VIEW_TYPE_HIRES_VIEW && this.updateSpotColorsInToolbar(separations, cmykNameToTypeMap); // spot colors are not supported in HiRes
    }

    const { showMediaBoxes, showPDFBoxes } = this.store.getState();

    this.toolbar.setItemChecked('mediaBoxes', showMediaBoxes);
    this.toolbar.setItemChecked('pdfOverlayBoxes', showPDFBoxes);


    this.toolbar.setItemChecked('density_tool', false);
    this.toolbar.setItemChecked('distance_tool', false);
    this.toolbar.setItemChecked('move_image', true);

    this.store.dispatch({
      type: 'MOVE_TOOL',
      isDistanceTool: false,
      isMoveTool: true,
      isDensityTool: false
    });

    //set the navigator controls
    let bShowAllSections = settingsManager.get('softproofShowSections');
    if (propExists(data.model, 'section.zone.sections')) {
      const { navigatorFilters } = this.store.getState();
      const parentRoot = getParentViewRoot(this);
      if (parentRoot.type === 'edition') {
        retriveAndSetEditionNavigatorItems(this, data.model, parentRoot);
      } else {
        retriveAndSetSectionNavigatorItems(this, navigatorFilters, data.model, bShowAllSections, data.model.section.currentSectionName);
      }
    }

    this.loadVersions.call(this, getContent(data.model));

    //now load all the images
    if (viewType === VIEW_TYPE_PAGE_VIEW || viewType === VIEW_TYPE_FORM_VIEW || viewType === VIEW_TYPE_FRAGMENT_VIEW) {
      this.store.dispatch({
        type: 'UPDATE_LOADING',
        loading: true
      });

      const nwid = getContent(data.model).nwid;
      const path = 'content.nwid';
      let params = {
        defaultIcon: sandbox.icons.getGeneralIcon(null, 'empty'),
        template: getContent(data.model).type,
        action: 'full',
        viewId: this.id,
        nwid,
        iconUrlCounter: this.iconUrlCounter++,
        projectorId: this.projectorId
      };
      const url = request.getImageUrl(params, true);
      this.disableAllToolbarButtons(false);
      loadImage(url)
        .then(image => {
          if (!this.doesImageMatchContent(nwid, path)) {
            //console.log('### PageView 1 => IMAGE NO MATCH');
            return;
          }

          this.store.dispatch({
            type: 'ADD_IMAGE',
            imageData: {
              key: 'composite',
              imageUrl: url,
              image: image
            },
            resolution: {
              xResolution: data.model.xRes,
              yResolution: data.model.yRes
            }
          });

          this.handleUpdateViewportPoints({ width: image.width, height: image.height });
          this.loadFormLayout(data);

          if (this.isColorManagementEnabled()) {
            const { versions, selectedVersionNumber } = this.store.getState();
            const selectedVersion = versions[selectedVersionNumber];
            const url = this.getColorManageURL(selectedVersion.blobURL);
            loadImage(url)
              .then(image => {
                //add the image to the store now
                const cmKey = composeColorManagementKey(selectedVersionNumber);
                this.store.dispatch({
                  type: 'ADD_IMAGE',
                  imageData: {
                    key: cmKey,
                    imageUrl: url,
                    image: image
                  }
                });
                this.store.dispatch({
                  type: 'LOAD_IMAGE',
                  selectedImageKey: cmKey
                });

                this.disableAllToolbarButtons(false);
                this.loadRectangles.call(this, data);
                this.store.dispatch({
                  type: 'DISTANCE_POINT_CHANGE',
                  distanceToolPoints: {
                    point: undefined,
                    pointTo: undefined,
                    measurementData: this.getUpdatedMeasurementData()
                  }
                });
                this.handleUpdateViewportPoints({ width: image.width, height: image.height });
                this.store.dispatch({
                  type: 'UPDATE_LOADING',
                  loading: false
                });
              })
              .catch(() => {
                console.warn(`The image with the following url was not found: ${url}`);
                this.loadMissingPage(missingPageFull, 'composite', true);
              });
          } else {
            this.store.dispatch({
              type: 'LOAD_IMAGE',
              selectedImageKey: 'composite'
            });
            this.disableAllToolbarButtons(false);
            this.loadRectangles.call(this, data);
            this.store.dispatch({
              type: 'DISTANCE_POINT_CHANGE',
              distanceToolPoints: {
                point: undefined,
                pointTo: undefined,
                measurementData: this.getUpdatedMeasurementData()
              }
            });

            this.store.dispatch({
              type: 'UPDATE_LOADING',
              loading: false
            });
          }
        })
        .catch(() => {
          console.warn(`The image with the following url was not found: ${url}`);
          if (!this.doesImageMatchContent(nwid, path)) {
            //console.log('### PageView 2 => IMAGE NO MATCH');
            return;
          }

          this.loadMissingPage(missingPageFull, 'composite', true);
          //disable ALL the buttons here.
          this.disableAllToolbarButtons(true);

          this.store.dispatch({
            type: 'LOAD_INITIAL_DATA',
            overlayBoxes: {
              mediaOverlayBoxes: [],
              pdfOverlayBoxes: []
            },
            guidelines: [],
            gridCells: []
          });
        });
    }

    const content = getContent(data.model);
    if (data.model.viewType === VIEW_TYPE_COMPARE) {
      let compareVersionsNumbersSelected = [];

      if (content.mainVersions.length >= 2) {
        const versionNumberToCompare1 = content.activeMainVersion === content.mainVersions.length ? content.activeMainVersion - 1 : content.activeMainVersion + 1;
        const versionNumberToCompare2 = content.activeMainVersion;
        compareVersionsNumbersSelected = [versionNumberToCompare1, versionNumberToCompare2];
        this.store.dispatch({
          type: 'UPDATE_COMPARE_VERSIONS_NUMBERS_SELECTED',
          compareVersionsNumbersSelected
        });
      } else if (content.mainVersions.length === 1) {
        compareVersionsNumbersSelected = [content.activeMainVersion];
        this.store.dispatch({
          type: 'UPDATE_COMPARE_VERSIONS_NUMBERS_SELECTED',
          compareVersionsNumbersSelected
        });
      } else {
        this.loadMissingPage(missingPageFull, 'composite', true);
      }
    }

    windowRef.document.addEventListener('keydown', this.handleDocumentKeyDown);

  },

  tickUpdate: function (data) {
    if (this.rootMismatch) {
      return;
    }

    const { model } = data;

    if (model.length > 0 && typeof model[0].killed !== 'undefined') {
      this.missingPage = model[0].killed;
    }

    const { sections, versions, firstTickData } = this.store.getState();

    let neighborsUpdated = false;

    for (let i = 0; i < model.length; i++) {
      var item = model[i];
      var action = item['actions'];

      if (item.hasOwnProperty('approvalFlowSteps')) {//this will control the toolbar approve/reject buttons
        var me = this.selected[0].separations.findBy('nwid', item.nwid);
        if (me != undefined) {
          Ember.set(me, 'approvalFlowSteps', item.approvalFlowSteps);//update this and the toolbar will magically update
        } else {
          var me = this.selected[0];
          if (me != undefined) {
            if (me.nwid === item.nwid) {
              Ember.set(me, 'approvalFlowSteps', item.approvalFlowSteps);
            }
          }
        }
      }
      if (item.hasOwnProperty('isApprovalEnabled')) {
        var me = this.selected[0];
        if (me != undefined) {
          Ember.set(me, 'isApprovalEnabled', item.isApprovalEnabled);//update this and the toolbar will magically update
        }
      }
      if (sandbox.jsUtils.isObject(item) && item.isVirtualPage !== undefined && !isUndefined(action) && !isUndefined(sections)) {
        if (action.toLowerCase() === 'update') {
          const section = Object.values(sections).findBy('nwid', item.nwid);
          if (section.isVirtualPage != item.isVirtualPage) {
            section.isVirtualPage = item.isVirtualPage;
            neighborsUpdated = true;
          }
        }
      }
    }

    if (neighborsUpdated) {
      const { navigatorFilters } = this.store.getState();
      const parentRoot = getParentViewRoot(this);
      if (parentRoot.type !== 'edition') {
        retriveAndSetSectionNavigatorItems(this, navigatorFilters, data.model);
      }
    }
    this.tickableModel.tickUpdateHandler(model);
  },

  doesImageMatchContent(nwid, path) {
    const state = this.store.getState();
    return nwid === sandbox.jsUtils.get(state, path);
  },

  rootChanged(prevRootId, nextRootId) {
    //console.log('### PageView.rootChanged() => prevRootId, nextRootId:', prevRootId, nextRootId);
    this.store.dispatch(updateLoading(true));
  },

  navigatorFilterChanged: function (navigatorFilters) {
    this.win.navigationState.navigatorFilters = navigatorFilters;
    this.store.dispatch({
      type: 'UPDATE_NAVIGATOR_FILTER',
      navigatorFilters
    });
  },

  handleUpdateViewportPoints: function (imageWidthHeight) {
    this.store.dispatch({
      type: 'UPDATE_VIEWPORT_POINTS',
      imageWidthHeight
    });
  },

  flip: function (flipType) {
    const { rotationDegree } = this.store.getState();
    const rotateDependsOnFlip = rotationDegree / 90 % 2 !== 0;

    const horizontalFlip = () => {
      const { flipHorizontal } = this.store.getState();
      let newFlipHorizontal = !flipHorizontal;
      this.store.dispatch({
        type: 'FLIP_HORIZONTAL',
        flipHorizontal: newFlipHorizontal
      });
      this.win.navigationState.flipHorizontal = newFlipHorizontal;
      this.handleUpdateViewportPoints(this.getImageWidthHeight());
    };

    const verticalFlip = () => {
      const { flipVertical } = this.store.getState();
      let newFlipVertical = !flipVertical;
      this.store.dispatch({
        type: 'FLIP_VERTICAL',
        flipVertical: newFlipVertical
      });
      this.win.navigationState.flipVertical = newFlipVertical;
      this.handleUpdateViewportPoints(this.getImageWidthHeight());
    };

    if (flipType === 'horizontal') {
      if (rotateDependsOnFlip) {
        verticalFlip();
      } else {
        horizontalFlip();
      }
    }
    if (flipType === 'vertical') {
      if (rotateDependsOnFlip) {
        horizontalFlip();
      } else {
        verticalFlip();
      }
    }
  },

  handlePageTick: function (action, item) {
    if (item !== item.getRoot()) return;
    this.store.dispatch({
      type: 'UPDATE_HOLD',
      isHold: isHold(item.holdType)
    });

    this.updateSelected([item]);
  },

  handlePageSeparation: function (action, item) {
    // console.log('handlePageSeparation called.');

  },

  handlePageSeparationContent: function (action, item) {
    // console.log('handlePageSeparationContent called.');

  },

  handleMainVersionTick: function (action, item) {
    // const { firstTickData } = this.store.getState();
    // console.log('handleMainVersionTick called.');
    // console.log(action, item);
    // if (isUndefined(item) || isUndefined(firstTickData) || (action === 'remove')) return;

    // if (this.passedFirstTick) {
    //   if (action === 'add') {
    //     if (item.type === 'mainversion') {
    //       // firstTickData.model.content.mainVersions.push(item);
    //       // this.updateMainVersion(item, firstTickData);//add the new version
    //     }
    //   }
    //   else if (action === 'update') {
    //     // this.addVersion(item, firstTickData);//update this version
    //   }
    // }
  },

  handlePageContentTick: function (action, item) {
    const { content, activeVersionNumber } = this.store.getState();
    if (isUndefined(item) || isUndefined(content) || (action === 'remove')) return;

    const page = item.getParent();
    if (page.getRoot() !== page) return;

    if (page.content.activeMainVersion !== activeVersionNumber) {
      this.store.dispatch({
        type: 'UPDATE_ACTIVE_VERSION_INDEX',
        activeVersionNumber: page.content.activeMainVersion
      });
      this.setVersionToolbarButtons(false);
    }

    item.mainVersions.forEach(mainVersion => {
      if (!isUndefined(mainVersion.action) && mainVersion.action === 'Add' || mainVersion.action === 'Update') {
        this.updateMainVersion(mainVersion, content);
      }
    });

  },

  getSelectedImage: function () {
    const { images = {}, selectedImageKey } = this.store.getState();

    return images[selectedImageKey]?.image;
  },

  createMyToolbar: function (separations, cmykNameToTypeMap) {
    this.createToolbar();
    let module = this;
    const {
      content,
      showNavigator,
      showPDFBoxes,
      showMediaBoxes,
      showGuidelines,
      showPageLabels,
      colorManagement,
      viewType,
      isDistanceTool,
      isMoveTool,
      isDensityTool,
      fitMode
    } = this.store.getState();

    this.toolbar.addItem({
      name: 'save',
      _isApplicable: true,
      icon: 'save_settings',
      iconSprite: 'general',
      tooltip: translate('Save preferences'),
      execute: () => {
        savePageViewPreferences(this.collectPreferences())
          .then(res => {
            this.toastService.successToast('', translate('Preferences saved successfully.'));
          });
      }
    });
    this.toolbar.addItem({
      name: 'print',
      _isApplicable: true,
      icon: 'print',
      iconSprite: 'general',
      tooltip: translate('Print image'),
      execute: () => {
        const { windowRef } = this.store.getState();
        const image = this.getSelectedImage();
        var pwin = windowRef.open('', 'Print', 'toolbar=0,location=0,menubar=0');
        pwin.document.write('<img src="' + image.src + '"/>');
        pwin.document.close();
        pwin.focus();
        pwin.onload = function () {
          setTimeout(function () {
            pwin.print();
            pwin.close();
          }, 500);//wait a bit for page to load
        };
      }
    });
    this.toolbar.addItem({
      name: 'rotate_left',
      _isApplicable: true,
      icon: 'rotate_left',
      iconSprite: 'general',
      tooltip: translate('Rotate image counterclockwise'),
      groupName: 'image',
      execute: () => {
        //rotate the image -90 degrees
        this.handleRotate(-90);
      }
    });
    this.toolbar.addItem({
      name: 'rotate_right',
      _isApplicable: true,
      icon: 'rotate_right',
      iconSprite: 'general',
      tooltip: translate('Rotate image clockwise'),
      groupName: 'image',
      execute: () => {
        //rotate the image +90 degrees
        this.handleRotate(90);
      }
    });
    this.toolbar.addItem({
      name: 'flip_horizontal',
      _isApplicable: true,
      icon: 'flip_horizontal',
      iconSprite: 'general',
      tooltip: translate('Flip image horizontally'),
      groupName: 'image',
      execute: () => {
        this.flip('horizontal');
      }
    });
    this.toolbar.addItem({
      name: 'flip_vertical',
      _isApplicable: true,
      icon: 'flip_vertical',
      iconSprite: 'general',
      tooltip: translate('Flip image vertically'),
      groupName: 'image',
      execute: () => {
        this.flip('vertical');
      }
    }
    );

    this.toolbar.addItem({
      name: 'move_image',
      _isApplicable: true,
      icon: 'move',
      iconSprite: 'general',
      checked: isMoveTool,
      itemType: 'push',
      tooltip: translate('Move tool'),
      groupName: 'tool',
      execute: (checked) => {
        if (!checked) {
          this.toolbar.setItemChecked('move_image', true);
        } else {
          //clear out the measurement data     

          this.store.dispatch({
            type: 'DISTANCE_POINT_CHANGE',
            distanceToolPoints: {
              point: undefined,
              pointTo: undefined,
              measurementData: {
                fromX: undefined,
                fromY: undefined,
                toX: undefined,
                toY: undefined,
                w: undefined,
                h: undefined,
                d: undefined,
                pw: undefined,
                ph: undefined
              }
            }
          });
          this.store.dispatch({
            type: 'MOVE_TOOL',
            isDistanceTool: !checked,
            isMoveTool: checked,
            isDensityTool: !checked
          });
        }
      }
    });

    this.toolbar.addItem({
      name: 'distance_tool',
      _isApplicable: true,
      icon: 'ruler',
      iconSprite: 'general',
      itemType: 'push',
      tooltip: translate('Distance tool'),
      groupName: 'tool',
      checked: isDistanceTool,
      execute: (checked) => {
        if (!checked) {
          this.toolbar.setItemChecked('distance_tool', true);
        } else {
          //clear out the measurement data         

          this.store.dispatch({
            type: 'DISTANCE_POINT_CHANGE',
            distanceToolPoints: {
              point: undefined,
              pointTo: undefined,
              measurementData: {
                fromX: undefined,
                fromY: undefined,
                toX: undefined,
                toY: undefined,
                w: undefined,
                h: undefined,
                d: undefined,
                pw: undefined,
                ph: undefined
              }
            }
          });
          this.store.dispatch({
            type: 'DISTANCE_TOOL',
            isDistanceTool: checked,
            isMoveTool: !checked,
            isDensityTool: !checked
          });
        }
      }
    });

    this.toolbar.addItem({
      name: 'density_tool',
      _isApplicable: true,
      iconSprite: 'general',
      icon: 'ink_density',
      tooltip: translate('Density tool'),
      itemType: 'push',
      groupName: 'tool',
      checked: isDensityTool,
      execute: async (checked) => {
        if (!checked) {
          this.toolbar.setItemChecked('density_tool', true);
        } else {
          const { separations, loadingStatus } = this.store.getState();

          if (loadingStatus === LOADING_STATUS.NOT_STARTED) {
            this.store.dispatch({
              type: 'LOADING_STATUS',
              loadingStatus: LOADING_STATUS.LOADING
            });
            await this.loadSeparations(separations);
            this.store.dispatch({
              type: 'LOADING_STATUS',
              loadingStatus: LOADING_STATUS.LOADED
            });
          }

          this.store.dispatch({
            type: 'DENSITY_TOOL',
            isDistanceTool: !checked,
            isMoveTool: !checked,
            isDensityTool: checked
          });
        }
      }
    });

    //only show media and overlay boxes for pages
    if (content.type.indexOf('page') >= 0 || content.type.indexOf('fragment') >= 0) {
      this.toolbar.addItem({
        name: 'mediaBoxes',
        itemType: 'push',
        _isApplicable: true,
        iconSprite: 'general',
        icon: 'pdf_boxes',
        tooltip: translate('PDF boxes'),
        checked: showMediaBoxes,
        execute: (checked) => {
          this.win.navigationState.showMediaBoxes = checked;
          this.store.dispatch({
            type: 'MEDIA_BOXES_CHANGED',
            showMediaBoxes: checked
          });

        }
      });

      this.toolbar.addItem({
        name: 'pdfOverlayBoxes',
        itemType: 'push',
        _isApplicable: true,
        iconSprite: 'general',
        icon: 'overlay_boxes',
        tooltip: translate('Overlay boxes'),
        checked: showPDFBoxes,
        execute: (checked) => {
          this.win.navigationState.showPDFBoxes = checked;
          this.store.dispatch({
            type: 'PDF_BOXES_CHANGED',
            showPDFBoxes: checked
          });

        }
      });
    } else {
      this.toolbar.addItem({
        name: 'guidelines',
        itemType: 'push',
        _isApplicable: true,
        iconSprite: 'general',
        icon: 'guidelines',
        tooltip: translate('Guidelines'),
        checked: showGuidelines,
        execute: (checked) => {
          this.win.navigationState.showGuidelines = checked;
          this.store.dispatch({
            type: 'GUIDELINES_CHANGED',
            showGuidelines: checked
          });

        }
      });

      this.toolbar.addItem({
        name: 'pagelabels',
        itemType: 'push',
        _isApplicable: true,
        iconSprite: 'general',
        icon: 'page_number',
        tooltip: translate('Page labels'),
        checked: showPageLabels,
        execute: (checked) => {
          this.win.navigationState.showPageLabels = checked;
          this.store.dispatch({
            type: 'PAGE_LABELS_CHANGED',
            showPageLabels: checked
          });
        }
      });
    }

    if (viewType === VIEW_TYPE_PAGE_VIEW || viewType === VIEW_TYPE_FORM_VIEW) {
      const selectedMonitorProfile = window.localStorage.getItem('monitorProfile') || '';
      const selectedPressProfile = window.localStorage.getItem('pressProfile') || '';

      this.toolbar.addItem({
        name: 'colorManagement',
        itemType: 'push',
        _isApplicable: true,
        checked: (!selectedMonitorProfile || !selectedPressProfile) ? false : colorManagement,
        tooltip: translate('Color management'),
        icon: 'color_management',
        iconSprite: 'general',
        execute: (checked) => {
          if (!selectedMonitorProfile || !selectedPressProfile) {
            dialogService.openAlertDialog(translate('Please choose ICC profiles in the user preferences dialog'), translate('Missing ICC Profile'), this);
            this.toolbar.setItemChecked('colorManagement', false);
            this.win.navigationState.colorManagement = false;
          } else {
            this.win.navigationState.colorManagement = checked;
            this.store.dispatch({
              type: 'COLOR_MANAGEMENT_CHANGED',
              colorManagement: checked
            });

            const { versions, selectedVersionNumber } = this.store.getState();

            const lastSelectedVersion = versions[selectedVersionNumber];
            this.handleVersionClick(lastSelectedVersion);
          }
        }

      });
    }

    this.toolbar.addItem({
      name: 'Toggle Properties',
      _isApplicable: true,
      iconSprite: 'general',
      icon: 'navigation',
      itemType: 'push',
      checked: showNavigator,
      tooltip: translate('Navigator'),
      execute: () => {
        //dispatch an action to the store so it will toggle the properties panel.
        const { showNavigator } = this.store.getState();
        let newShowNavigator = !showNavigator;
        this.store.dispatch({
          type: 'TOGGLE_NAVIGATOR',
          showNavigator: newShowNavigator
        });
        this.win.navigationState.showNavigator = newShowNavigator;
      }
    });

    this.toolbar.addItem({
      name: 'zoom_out',
      _isApplicable: true,
      icon: 'zoom_out',
      iconSprite: 'general',
      tooltip: translate('Zoom out'),
      groupName: 'zoom',
      execute: () => {
        this.handleZoom(-0.25);
      }
    });

    this.toolbar.addItem({
      name: 'zoom_in',
      _isApplicable: true,
      icon: 'zoom_in',
      iconSprite: 'general',
      tooltip: translate('Zoom in'),
      groupName: 'zoom',
      execute: () => {
        this.handleZoom(0.25);
      }
    });

    this.toolbar.addItem({
      name: 'fit_to_width',
      _isApplicable: true,
      itemType: 'push',
      icon: 'fit_to_width',
      iconSprite: 'general',
      tooltip: translate('Fit to width'),
      groupName: 'zoom',
      checked: fitMode === 'fit_to_width',
      execute: checked => this.handleFitToWidth(checked)
    });

    this.toolbar.addItem({
      name: 'fit_to_height',
      _isApplicable: true,
      itemType: 'push',
      icon: 'fit_to_height',
      iconSprite: 'general',
      tooltip: translate('Fit to height'),
      groupName: 'zoom',
      checked: fitMode === 'fit_to_height',
      execute: checked => this.handleFitToHeight(checked)
    });

    this.toolbar.addItem({
      name: 'fit_to_view',
      _isApplicable: true,
      itemType: 'push',
      icon: 'fit_to_page',
      iconSprite: 'general',
      tooltip: translate('Fit to view'),
      groupName: 'zoom',
      checked: fitMode === 'fit_to_view',
      execute: checked => this.handleFitToView(checked)
    });

    this.toolbar.addItem({
      name: 'actual_size',
      _isApplicable: true,
      itemType: 'push',
      icon: 'actual_size',
      iconSprite: 'general',
      tooltip: translate('Actual size'),
      groupName: 'zoom',
      checked: fitMode === 'actual_size',
      execute: checked => this.handleActualSize(checked)
    });

    this.toolbar.addItem({
      name: 'composite',
      _isApplicable: true,
      icon: 'composite_color',
      iconSprite: 'general',
      tooltip: translate('Turn on all colors'),
      execute: () => {
        module.toggleSep('composite', true);
      }
    });

    this.toolbar.addItem({
      name: 'singleSep',
      id: 'singleSep',
      _isApplicable: true,
      itemType: 'push',
      icon: 'single_separation',
      iconSprite: 'general',
      tooltip: translate('Single separation selection'),
      checked: false,
      execute: (checked) => {
        module.toggleSep('singleSep', checked);
        if (checked) {
          module.toggleSep('black', 'k', checked);
        } else {
          module.toggleSep('composite', 'composite', checked);
        }
      }
    });

    this.toolbar.addItem({
      name: 'cyan',
      itemType: 'push',
      _isApplicable: isSepApplicable(cmykNameToTypeMap, 'cyan'),
      icon: 'cyan_separation',
      iconSprite: 'general',
      tooltip: translate('Cyan separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSep('cyan', checked);
      }
    });

    this.toolbar.addItem({
      name: 'magenta',
      itemType: 'push',
      _isApplicable: isSepApplicable(cmykNameToTypeMap, 'magenta'),
      icon: 'magenta_separation',
      iconSprite: 'general',
      tooltip: translate('Magenta separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSep('magenta', checked);
      }
    });

    this.toolbar.addItem({
      name: 'yellow',
      itemType: 'push',
      _isApplicable: isSepApplicable(cmykNameToTypeMap, 'yellow'),
      icon: 'yellow_separation',
      iconSprite: 'general',
      tooltip: translate('Yellow separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSep('yellow', checked);
      }
    });

    this.toolbar.addItem({
      name: 'black',
      itemType: 'push',
      _isApplicable: isSepApplicable(cmykNameToTypeMap, 'black'),
      icon: 'black_separation',
      iconSprite: 'general',
      tooltip: translate('Black separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSep('black', checked);
      }
    });

    this.toolbar.addItem({
      name: 'blackAsGray',
      itemType: 'push',
      _isApplicable: true,
      icon: 'black_as_gray',
      iconSprite: 'general',
      tooltip: translate('Show black as gray'),
      checked: false,
      execute: (checked) => {
        module.toggleSep('blackAsGray', checked);
      }
    });

    viewType !== VIEW_TYPE_HIRES_VIEW && this.updateSpotColorsInToolbar(separations, cmykNameToTypeMap);

    this.toolbar.addItem({
      itemType: 'menu',
      icon: 'more_vert',
      menuItems: [],
      unshift: true,
      _isApplicable: true,
      tooltip: translate('More actions'),
      execute: () => {
        return this.getMenuItems();
      },
    });

    this.toolbarLocalButtons = [];
    const toolbarGlobalButtons = ['ApproveAllActionCR', 'RejectAllActionCR', 'OpenCustomApprovalViewCR'];
    this.toolbar.items.forEach(item => (toolbarGlobalButtons.indexOf(item.name) < 0) && this.toolbarLocalButtons.push(item.name));//save local buttons

    this.toolbar.setItemClassName('RejectBothActionCR', 'crtx-mt-reject-button'); // make space between approve and reject buttons (NUK only)
  },

  //load the missing page
  //Parms:
  // url: url string of the page
  // key: key to images that you want to save in the store
  // alsoLoadPage: Boolean if you want to load the page to the main canvas
  loadMissingPage: function (url, key, alsoLoadPage) {

    this.store.dispatch({
      type: 'UPDATE_LOADING',
      loading: true
    });

    this.missingPage = true;
    loadImage(url)
      .then(image => {
        this.store.dispatch({
          type: 'ADD_IMAGE',
          imageData: {
            key: key,
            imageUrl: image.src,
            image: image
          }
        });

        this.handleUpdateViewportPoints({ width: image.width, height: image.height });

        if (alsoLoadPage) {
          //call load page for the clicked version
          this.store.dispatch({
            type: 'LOAD_IMAGE',
            selectedImageKey: key
          });
          //clear any measurement data
          this.store.dispatch({
            type: 'DISTANCE_POINT_CHANGE',
            distanceToolPoints: {
              point: undefined,
              pointTo: undefined,
              measurementData: this.getUpdatedMeasurementData()
            }
          });
        }

        this.store.dispatch({
          type: 'UPDATE_LOADING',
          loading: false
        });

      })
      .catch(() => {
        // console.log('Failed to load the missing Page...');
      });
  },

  setVersionToolbarButtons: function (bDisable) {
    const { separationToolbarNames } = this.store.getState();
    const toolbarButtons = ['composite', 'singleSep', 'cyan', 'magenta', 'yellow',
      'black', 'blackAsGray', 'menu_more_actions', 'ApproveAllActionCR', 'RejectAllActionCR', 'OpenCustomApprovalViewCR'];
    toolbarButtons.forEach(item => this.toolbar.setItemDisabled(item, bDisable));//enable all buttons

    separationToolbarNames.forEach(sep => this.toolbar.setItemChecked(sep, true));
    ['singleSep', 'blackAsGray'].forEach(item => this.toolbar.setItemChecked(item, false));
  },

  //enable/disable all buttons
  disableAllToolbarButtons: function (disabled) {
    this.toolbarLocalButtons.forEach(item => {
      this.toolbar.setItemDisabled(item, disabled);
    });
  },

  getMenuItems: function () {
    const EXCLUDE_MENU_ACTIONS = ['ApproveAllActionCR', 'RejectAllActionCR', 'SetActiveVersionActionCR', 'OpenCustomApprovalViewCR',
      'ShowRightPanelInfoActionCR', 'ObjectInfoActionCR'];
    const possibleActions = this.getRelevantActions(this.selected[0], 'popup');
    const selectedItems = this.selected;

    return possibleActions.reduce((acc, possibleAction) => {
      if (EXCLUDE_MENU_ACTIONS.indexOf(possibleAction.actionDefinitionName) < 0) {
        acc.push({
          text: translate(possibleAction.label),
          disabled: !possibleAction.isApplicable.call(possibleAction, selectedItems),
          execute: this.decorateActionExecute.call(possibleAction, () => possibleAction.execute.call(possibleAction, selectedItems))
        });
      }
      return acc;
    }, []);
  },


  /**
   * Mimics the current action execution behavior: close the window after execution of some actions
   * This function should be removed when this strange behavior will be fixed
   * @param fn - action execute function
   * @returns {Function} - decorated action execute function
   */
  decorateActionExecute: function (fn) {
    return function () {
      const { windowRef } = this.store.getState();
      const result = fn.apply(this, arguments);
      const CLOSE_WINDOW_ACTIONS = ['HoldContentElementActionCR', 'ReleaseContentElementActionCR', 'SkipErrorActionCR',
        'HoldStructureElementActionCR', 'ReleaseStructureElementActionCR', 'ClearActionCR', 'SetToIgnoreActionCR', 'RemoveIgnoreActionCR'];

      //TODO: Remove window close code
      if (CLOSE_WINDOW_ACTIONS.indexOf(this.actionDefinitionName) >= 0) {
        setTimeout(() => {
          windowRef.close();
        }, 1500);
      }

      return result;
    }.bind(this);
  },

  collectPreferences: function () {
    const {
      showNavigator,
      rotation,
      zoom,
      measurementUnit,
      flipHorizontal,
      flipVertical,
      showMediaBoxes,
      showPDFBoxes,
      showGuidelines,
      showPageLabels,
      colorManagement,
      fitMode,
      navigatorFilters
    } = this.store.getState();

    const general = {
      measurementUnit,
      zoom,
      flipHorizontal,
      flipVertical,
      rotation,
      colorManagement,
      showNavigator,
      showMediaBoxes,
      showPDFBoxes,
      showGuidelines,
      showPageLabels,
      fitMode,
    };

    const windowRect = {
      left: this.win.screenX,
      top: this.win.screenY,
      width: this.win.innerWidth,
      height: this.win.innerHeight
    };

    return { general, navigatorFilters, windowRect };
  },

  isColorManagementEnabled: function () {
    const { colorManagement = false } = this.store.getState();

    return colorManagement;
  },

  getColorManageURL: function (blobUrl) {
    const { windowRef } = this.store.getState();

    var newUrl = blobUrl;
    const monitorName = windowRef.localStorage.getItem('monitorProfile'); //TODO: change localstorage name from monitorProfile to monitorName, same with press...;
    const pressName = windowRef.localStorage.getItem('pressProfile');

    if (this.isColorManagementEnabled() && monitorName !== '' && pressName !== '') {
      newUrl = newUrl.replace('action=full', 'action=full-cmyk');
      newUrl = newUrl.replace('action=icon', 'action=full-cmyk');
      newUrl = newUrl + '&colorManage=true';
      newUrl = newUrl + '&monitorName=' + monitorName;
      newUrl = newUrl + '&pressName=' + pressName + '&';
    } else {
      //get the full image always
      newUrl = newUrl.replace('action=full-cmyk', 'action=full');
      newUrl = newUrl.replace('action=icon', 'action=full');
      newUrl = newUrl.slice(0, newUrl.search('&colorManage'));
    }
    return newUrl;
  },

  getImageMeasurements: function (measureKey) {
    const { measurementUnit, resolution } = this.store.getState();
    const image = this.getSelectedImage();
    const resolutionMeasurements = measureKey === WIDTH ? resolution.xResolution : measureKey === HEIGHT ? resolution.yResolution : 0;
    let measure = image[measureKey];

    return measurementUnit === INCH ? (measure / resolutionMeasurements).toFixed(INCH_PRECISION) : measurementUnit === MILLIMETER ? (measure * 25.4 / resolutionMeasurements).toFixed(MILLIMETER_PRECISION) : measurementUnit === POINT ? (measure * 72 / resolutionMeasurements).toFixed(POINT_PRECISION) : 0;
  },

  convertFromInchToMeasurementsUnits: function (measure, resolution) {
    const { measurementUnit } = this.store.getState();

    return measurementUnit === INCH ? measure.toFixed(INCH_PRECISION) : measurementUnit === MILLIMETER ? (measure * 25.4).toFixed(MILLIMETER_PRECISION) : measurementUnit === POINT ? (measure * 72).toFixed(POINT_PRECISION) : 0;
  },

  convertFromInchToPixel: function (measure, resolution) {
    return measure * resolution;
  },

  loadRectangles: function (data) {
    const { images, selectedImageKey, measurementUnit, resolution, viewType } = this.store.getState();
    const mainVersion = getContent(data.model).mainVersions.findBy('versionNumber', getContent(data.model).activeMainVersion);

    if (isUndefined(mainVersion)) return;
    request.getRectangles(mainVersion.nwid, mainVersion.type, 'pdfOverlayBoxes')
      .then(boxdata => {
        if (!isUndefined(boxdata)) {
          let pdfBoxes = [], pdfOverlays = [];
          const xResolution = data.model.hasOwnProperty('xRes') ? data.model.xRes : 100;
          const yResolution = data.model.hasOwnProperty('yRes') ? data.model.yRes : 100;
          const selectedImageWidth = this.getSelectedImage().width;
          const selectedImageHeight = this.getSelectedImage().height;

          if ((boxdata.hasOwnProperty('Rectangles'))) {
            pdfBoxes = boxdata.Rectangles.filter(box => !box.hasOwnProperty('type') || box.type === 'GENERAL');
            pdfBoxes = arrangePdfBoxes(pdfBoxes);
            pdfBoxes.forEach((box, index) => {
              box.referencePoint = box.referencePoint || BOTTOM_LEFT_REFERENCE_POINT;
              box.calWidth = this.convertFromInchToPixel(box.width, xResolution);
              box.calcHeight = this.convertFromInchToPixel(box.height, yResolution);
              box.calcLeft = -selectedImageWidth / 2 + this.convertFromInchToPixel(box.left, xResolution);
              box.calcTop = box.referencePoint === BOTTOM_LEFT_REFERENCE_POINT ? selectedImageHeight / 2 - this.convertFromInchToPixel(box.top, yResolution) - box.calcHeight : -selectedImageHeight / 2 + this.convertFromInchToPixel(box.top, yResolution);
              box.color = getBoxColor(box);
              box.fill = false;
              box.legendData = {
                x: this.convertFromInchToMeasurementsUnits(box.left, xResolution),
                y: this.convertFromInchToMeasurementsUnits(box.top, yResolution),
                width: this.convertFromInchToMeasurementsUnits(box.width, xResolution),
                height: this.convertFromInchToMeasurementsUnits(box.height, yResolution),
                xResolution,
                yResolution
              };
              box.isSelected = true;
            });

            pdfOverlays = boxdata.Rectangles.filter(box => box.type === 'OVERLAY');
            pdfOverlays.forEach((box) => {
              box.referencePoint = box.referencePoint || BOTTOM_LEFT_REFERENCE_POINT;
              box.calcLeft = -selectedImageWidth / 2 + this.convertFromInchToPixel(box.left, xResolution);
              box.calcTop = box.referencePoint === BOTTOM_LEFT_REFERENCE_POINT ? selectedImageHeight / 2 - this.convertFromInchToPixel(box.top, yResolution) - box.calcHeight : -selectedImageHeight / 2 + this.convertFromInchToPixel(box.top, yResolution);
              box.calWidth = this.convertFromInchToPixel(box.width, xResolution);
              box.calcHeight = this.convertFromInchToPixel(box.height, yResolution);
              box.color = getBoxColor(box);
              box.fill = true;
              box.legendData = {
                x: this.convertFromInchToMeasurementsUnits(box.left, xResolution),
                y: this.convertFromInchToMeasurementsUnits(box.top, yResolution),
                width: this.convertFromInchToMeasurementsUnits(box.width, xResolution),
                height: this.convertFromInchToMeasurementsUnits(box.height, yResolution),
                xResolution,
                yResolution
              };
              box.isSelected = true;
            });
          }

          if (viewType === VIEW_TYPE_FORM_VIEW) {
            const guidelineHorizontalStart = -selectedImageWidth / 2 + this.convertFromInchToPixel(0, xResolution);
            const guidelineHorizontalEnd = selectedImageWidth / 2 + this.convertFromInchToPixel(0, xResolution);
            const guidelineVerticalStart = -selectedImageHeight / 2 + this.convertFromInchToPixel(0, yResolution);
            const guidelineVerticalEnd = selectedImageHeight / 2 + this.convertFromInchToPixel(0, yResolution);
            boxdata.guidelines && boxdata.guidelines.length > 0 && boxdata.guidelines.forEach(guideline => {
              guideline.isSelected = true;
              if (guideline.orientation === 'vertical') {
                const imageWidth = guideline.referenceX === 'right' ? selectedImageWidth : -selectedImageWidth;
                const guidelineXInPixel = (imageWidth / 2) + this.convertFromInchToPixel(guideline.x, yResolution);
                guideline.point = { x: guidelineXInPixel, y: guidelineVerticalStart };
                guideline.pointTo = { x: guidelineXInPixel, y: guidelineVerticalEnd };
                guideline.displayPosition = this.convertFromInchToMeasurementsUnits(guideline.x, yResolution);
              } else {
                const imageHeight = guideline.referenceY === 'bottom' ? selectedImageHeight : -selectedImageHeight;
                const guidelineYInPixel = (imageHeight / 2) + this.convertFromInchToPixel(guideline.y, xResolution);
                guideline.point = { x: guidelineHorizontalStart, y: guidelineYInPixel };
                guideline.pointTo = { x: guidelineHorizontalEnd, y: guidelineYInPixel };
                guideline.displayPosition = this.convertFromInchToMeasurementsUnits(guideline.y, xResolution);
              }
            });

            this.store.dispatch({
              type: 'LOAD_GUIDELINES',
              guidelines: boxdata.guidelines
            });
          }

          this.store.dispatch({
            type: 'LOAD_OVERLAY_BOXES',
            overlayBoxes: {
              mediaOverlayBoxes: pdfBoxes,
              pdfOverlayBoxes: pdfOverlays
            }
          });
        }
      });
  },

  toStagePoint: function (x, y) {
    const { width, height } = this.getSelectedImage() || {};

    return { x: x - width / 2, y: y - height / 2 };
  },

  getPageLabelsByIndexes: function (model) {
    const { PageInfoOnForm: { indexes }, pages } = model;

    return pages.reduce((acc, p) => {
      const pageIndexes = indexes[p.pageContent?.nwid] || [];
      const index = pageIndexes[0];
      if (index >= 0) {
        acc[index] = p.name;
      }

      return acc;
    }, {});
  },

  loadFormLayout: async function (data) {
    const { model, model: { nwid, type, xRes = 100, yRes = 100 } } = data;
    if (type !== 'form') {
      return;
    }

    const pageLabels = this.getPageLabelsByIndexes(model);

    const formLayout = await request.loadFormLayout(nwid, 'form');
    const { cells, columns } = formLayout?.cellGrid || {};
    const gridCells = cells.reduce((acc, cell) => {
      if (cell.isSkipped) {
        return acc;
      }

      const x = this.convertFromInchToPixel(cell.x, xRes);
      const y = this.convertFromInchToPixel(cell.y, yRes);
      const width = this.convertFromInchToPixel(cell.width, xRes);
      const height = this.convertFromInchToPixel(cell.height, yRes);
      const cp = centerPoint(x, y, width, height);
      const point = this.toStagePoint(cp.x, cp.y);
      const index = cell.row * columns + cell.column;
      const text = pageLabels[index] || '';

      acc.push({
        ...CELL_LABEL_PROPS,
        point,
        text,
        rotation: cell.rotation,
      });

      return acc;
    }, []);

    this.store.dispatch({
      type: 'LOAD_GRID_CELLS',
      gridCells
    });
  },

  getVersionData: function (version, content) {
    const blobURL = request.getImageUrl({
      defaultIcon: sandbox.icons.getGeneralIcon(null, 'empty'),
      template: content.type,
      action: 'full',
      viewId: this.id,
      nwid: content.nwid,
      version: version.versionNumber,
      externalVersion: version.externalVersion,
      iconUrlCounter: content.versionNwid,
      activeMainVersion: content.activeMainVersion,
      projectorId: this.projectorId
    }, true);

    //then add icon list to store
    const iconURL = request.getImageUrl({
      defaultIcon: sandbox.icons.getModuleIcon('PageView', 'missing_page_small'),
      template: content.type,
      action: 'icon',
      viewId: this.id,
      nwid: content.nwid,
      version: version.versionNumber,
      externalVersion: version.externalVersion,
      activeMainVersion: content.activeMainVersion,
      iconUrlCounter: content.versionNwid,
      projectorId: this.projectorId
    }, true);

    return {
      key: version.versionNumber,
      blobURL: blobURL,
      iconURL: iconURL,
      versionData: version
    };
  },

  updateMainVersion: function (version, content) {
    const versionData = this.getVersionData(version, content);
    this.store.dispatch({
      type: 'UPDATE_VERSIONS',
      versionData: versionData
    });
  },

  loadVersions: function (content) {
    const { viewType, selectedVersionNumber } = this.store.getState();
    let versions = [];
    if (content.hasOwnProperty('mainVersions')) {
      versions = sandbox.sorting.sort(content.mainVersions, 'numeric', 'versionNumber');
    }
    // versions.sort(function (a, b) {
    //   return (b.versionNumber - a.versionNumber);
    // });
    versions.forEach(function (version) {
      this.updateMainVersion(version, content);
    }, this);

    this.store.dispatch({
      type: 'UPDATE_ACTIVE_AND_SELECTED_INDEX',
      activeVersionNumber: content.activeMainVersion,
      selectedVersionNumber: !isUndefined(selectedVersionNumber) ? selectedVersionNumber : content.activeMainVersion
    });

    //TODO Enable/Disable buttons here
  },

  loadSeparation: function (color, url) {
    return loadImage(url)
      .then(img => {
        this.store.dispatch({
          type: 'ADD_IMAGE',
          imageData: {
            key: color,
            imageUrl: url,
            image: img
          }
        });
        //now get the graysource of the C,M,Y separation and save it in the store.
        if (color !== 'black') {
          let grayImage = new Image();
          grayImage.src = getGraySource(color, img, this.win.document);
          this.store.dispatch({
            type: 'ADD_IMAGE',
            imageData: {
              key: 'gray_' + color,
              imageUrl: url,
              image: grayImage
            }
          });
        }
      })
      .catch(() => {
        this.loadMissingPage(missingPageFull, color, false);
        this.toolbar.setItemDisabled(color, true);//disable button
      });
  },

  loadSeparations: async function (separations) {

    const { content } = this.store.getState();

    const itemsToDisable = ['composite', 'singleSep', 'blackAsGray', 'cyan', 'magenta', 'yellow', 'black'];
    itemsToDisable.forEach(item => this.toolbar.setItemDisabled(item, true));//disable button
    const sepPromisesArray = [];

    for (let separation of separations) {

      let params = {
        defaultIcon: sandbox.icons.getGeneralIcon(null, 'empty'),
        template: getSepContent(content, separation).type,
        action: 'full',
        viewId: this.id,
        nwid: getSepContent(content, separation).nwid,
        iconUrlCounter: this.iconUrlCounter++,
        //version: separation.activeMainVersion,
      };
      sepPromisesArray.push(this.loadSeparation(separation.colorType.toLowerCase(), request.getImageUrl(params, true)));
      switch (separation.colorType.toLowerCase()) {
        case 'k':
        case 'black':
          this.toolbar.setItemDisabled('black', false);//enable the button
          this.toolbar.setItemDisabled('blackAsGray', false);//enable the button
          break;
        default:
          this.toolbar.setItemDisabled(separation.colorType.toLowerCase(), false);//enable the button
          this.toolbar.setItemDisabled('composite', false);//enable the button
          this.toolbar.setItemDisabled('singleSep', false);//enable the button
          break;
      }
    }
    await Promise.all(sepPromisesArray);
  },

  //this will divide the checked/unchecked colors and put them into an object
  colorSeps: function (sepKey, checked) {
    const { separationToolbarNames } = this.store.getState();
    let items = {
      checked: [],
      unchecked: []
    };

    //preserve the blackAsGray checkbox ALWAYS
    this.toolbar.items.findBy('name', 'blackAsGray').checked ? items.checked.push('blackAsGray') : items.unchecked.push('blackAsGray');

    if (sepKey === 'blackAsGray') {
      //preserve all keys
      separationToolbarNames.forEach(color => {
        if (this.toolbar.items.findBy('name', color)) { // to ignore spot colors that are currently not supported in Hires
          this.toolbar.items.findBy('name', color).checked ? items.checked.push(color) : items.unchecked.push(color);
        }
      });
    } else {
      const isSingleSep = this.toolbar.items.findBy('name', 'singleSep').checked;
      if (isSingleSep) checked = true;//always keep color on if in singleSep mode
      separationToolbarNames.forEach(color => {
        const item = this.toolbar.items.findBy('name', color);
        if (item) { // to ignore spot colors that are currently not supported in Hires
          if (sepKey === color) {
            if (checked) {
              items.checked.push(color);
            } else {
              items.unchecked.push(color);
            }
          } else {
            if (!isSingleSep && item.checked) {
              items.checked.push(color);
            } else {
              items.unchecked.push(color);
            }
          }
        }
      });
    }
    return items;
  },

  toggleSep: async function (sepKey, checked) {
    const { separations, loadingStatus } = this.store.getState();

    if (loadingStatus === LOADING_STATUS.NOT_STARTED) {
      this.store.dispatch({
        type: 'LOADING_STATUS',
        loadingStatus: LOADING_STATUS.LOADING
      });
      await this.loadSeparations(separations);
      this.store.dispatch({
        type: 'LOADING_STATUS',
        loadingStatus: LOADING_STATUS.LOADED
      });
    }
    const { images, activeVersionNumber, separationToolbarNames } = this.store.getState();
    let items = {
      checked: [],
      unchecked: []
    };

    if (images) {
      this.toolbar.setItemChecked(sepKey, checked);//set the clicked item now

      switch (sepKey) {
        case 'composite':
          items.checked = [...separationToolbarNames];
          items.unchecked = ['singleSep', 'blackAsGray'];
          this.toolbar.setItemDisabled('blackAsGray', false);
          break;
        case 'singleSep':
          if (checked) {
            items.checked = ['black', 'singleSep'];
            items.unchecked = separationToolbarNames.filter(sep => !items.checked.includes(sep));
          } else {
            items.checked = [...separationToolbarNames];
            items.unchecked = ['singleSep'];
          }
          break;
        default:
          items = this.colorSeps(sepKey, checked);
          break;
      }
      items.checked.forEach(s => {
        this.toolbar.setItemChecked(s, true);
      });
      items.unchecked.forEach(s => {
        this.toolbar.setItemChecked(s, false);
      });

      if (!items.checked.includes('blackAsGray') && separationToolbarNames.every(sep => items.checked.includes(sep))) {
        if (this.isColorManagementEnabled()) {
          this.store.dispatch({
            type: 'LOAD_IMAGE',
            selectedImageKey: composeColorManagementKey(activeVersionNumber)
          });
        } else {
          this.store.dispatch({
            type: 'LOAD_IMAGE',
            selectedImageKey: 'composite'
          });
        }

        return;//dont merge anything
      }
      const isSingleSep = this.toolbar.items.findBy('name', 'singleSep').checked;
      let imagesToMerge = [];
      this.toolbar.setItemDisabled('blackAsGray', false);
      if (isSingleSep) {
        const singleSep = stripPrefix(items.checked.find(item => item !== 'blackAsGray'));
        if (singleSep === 'black') {
          images[singleSep] && imagesToMerge.push(images[singleSep]);
        } else {
          this.toolbar.setItemChecked('blackAsGray', false);
          this.toolbar.setItemDisabled('blackAsGray', true);
          items.checked = items.checked.filter(item => item !== 'blackAsGray');
          images[`gray_${singleSep}`] && imagesToMerge.push(images[`gray_${singleSep}`]);
        }
      } else {
        imagesToMerge = items.checked.reduce((acc, item) => {
          const itemName = stripPrefix(item);
          images[itemName] && acc.push(images[itemName]);
          return acc;
        }, []);
      }

      //now do we really need to merge, only if there is more than 1 imagesToMerge
      if (imagesToMerge.length === 0 || !imagesToMerge[0] && !items.checked.includes('blackAsGray')) {
        this.store.dispatch({
          type: 'LOAD_IMAGE',
          selectedImageKey: undefined
        });
      } else if (imagesToMerge.length === 1 && !items.checked.includes('blackAsGray')) {
        this.store.dispatch({
          type: 'LOAD_IMAGE',
          selectedImageKey: imagesToMerge[0].key
        });
      } else {
        const url = mergeImages(imagesToMerge, this.win.document, this.toolbar.items.findBy('name', 'blackAsGray').checked);
        loadImage(url)
          .then(image => {
            this.store.dispatch({
              type: 'ADD_IMAGE',
              imageData: {
                key: 'mergedImage',
                imageUrl: url,
                image: image
              }
            });
            this.store.dispatch({
              type: 'LOAD_IMAGE',
              selectedImageKey: 'mergedImage'
            });
          })
          .catch(() => {
            // console.log('image failed to load');
          });
      }
    }
  },

  setStartupState: function (data = {}) {
    const { windowRef } = this.store.getState();
    const localStorageMonitorName = windowRef.localStorage.getItem('monitorProfile');
    const localStoragePressName = windowRef.localStorage.getItem('pressProfile');

    const { general = {}, navigatorFilters = [] } = getPageViewPreferences();
    const navState = this.win.navigationState;

    const measurementUnit = navState.measurementUnit ?? general.measurementUnit ?? settingsManager.getLengthUnit();
    const zoom = navState.zoomState ?? general.zoom ?? 1;
    const fitMode = navState.fitMode ?? general.fitMode ?? '';
    const flipHorizontal = navState.flipHorizontal ?? general.flipHorizontal ?? false;
    const flipVertical = navState.flipVertical ?? general.flipVertical ?? false;
    const showNavigator = navState.showNavigator ?? general.showNavigator ?? true;
    let rotation = navState.rotationState ?? general.rotation ?? 0;
    const colorManagement = localStorageMonitorName && localStoragePressName ? (navState.colorManagement || general.colorManagement || false) : false;
    const showMediaBoxes = navState.showMediaBoxes ?? general.showMediaBoxes ?? false;
    const showPDFBoxes = navState.showPDFBoxes ?? general.showPDFBoxes ?? false;
    const showGuidelines = navState.showGuidelines ?? general.showGuidelines ?? false;
    const showPageLabels = navState.showPageLabels ?? general.showPageLabels ?? false;
    const updatedNavigatorFilters = navState.navigatorFilters ?? navigatorFilters ?? [];

    // now override any that are defined in the plan
    const { viewRotationOverride } = data.model;
    if (typeof viewRotationOverride !== 'undefined' && viewRotationOverride !== -1) {
      rotation = viewRotationOverride - 360;
    }

    this.store.dispatch({
      type: 'STARTUP_STATE',
      measurementUnit,
      zoom,
      fitMode,
      flipHorizontal,
      flipVertical,
      rotation,
      rotationDegree: rotation,
      showNavigator,
      showMediaBoxes,
      showPDFBoxes,
      showGuidelines,
      showPageLabels,
      colorManagement,
      navigatorFilters: updatedNavigatorFilters
    });
  },

  handleVersionClick: function (selectedVersion) {
    const { images, activeVersionNumber } = this.store.getState();

    if (!isUndefined(selectedVersion)) {
      var key = 'version' + selectedVersion.key;
      if (this.isColorManagementEnabled()) {
        key = composeColorManagementKey(selectedVersion.key);
      }
      if (isUndefined(images[key])) {//is this version already loaded

        this.store.dispatch({
          type: 'UPDATE_LOADING',
          loading: true
        });

        const url = this.getColorManageURL(selectedVersion.blobURL);
        loadImage(url)
          .then(image => {
            //add the image to the store now
            this.store.dispatch({
              type: 'ADD_IMAGE',
              imageData: {
                key: key,
                imageUrl: url,
                image: image
              }
            });
            //call load page for the clicked version
            this.store.dispatch({
              type: 'LOAD_IMAGE',
              selectedImageKey: key
            });
            //clear any measurement data
            this.store.dispatch({
              type: 'DISTANCE_POINT_CHANGE',
              distanceToolPoints: {
                point: undefined,
                pointTo: undefined,
                measurementData: this.getUpdatedMeasurementData(true)
              }
            });

            this.store.dispatch({
              type: 'UPDATE_LOADING',
              loading: false
            });
          })
          .catch(() => {
            this.loadMissingPage(missingPageFull, key, true);
            this.disableAllToolbarButtons(false);

            this.store.dispatch({
              type: 'UPDATE_LOADING',
              loading: false
            });
          });
      } else {
        //call load page for the clicked version
        this.store.dispatch({
          type: 'LOAD_IMAGE',
          selectedImageKey: key
        });
        this.store.dispatch({
          type: 'DISTANCE_POINT_CHANGE',
          distanceToolPoints: {
            point: undefined,
            pointTo: undefined,
            measurementData: this.getUpdatedMeasurementData(true)
          }
        });
      }

      this.store.dispatch({
        type: 'UPDATE_SELECTED_VERSION_INDEX',
        selectedVersionNumber: selectedVersion.key
      });
      this.toolbar.refreshIsApplicableProperty();
    }
    this.disableAllToolbarButtons(false);//enable all the buttons
    this.setVersionToolbarButtons(activeVersionNumber !== selectedVersion.key);
  },

  handleCanvasResize: function ({ width, height }) {
    //TODO this -16 below is height of topPageDiv
    this.store.dispatch({
      type: 'CANVAS_RESIZE',
      mainCanvasWidth: width,
      mainCanvasHeight: (height - 16)
    });
  },

  handleMainStageLoad: function (instance) {
    this.mainStageInstance = instance;
  },

  handleMainCanvasLoad: function (instance) {
    this.mainCanvasInstance = instance.current;

    this.store.dispatch({
      type: 'SET_MAIN_CANVAS_INSTANCE',
      mainCanvasInstance: this.mainCanvasInstance
    });
  },

  handleNavCanvasLoad: function (instance) {
    this.navCanvasInstance = instance;
  },

  handleZoom: function (zoomValue) {
    if (this.missingPage) {
      return;
    }
    const { zoom } = this.store.getState();
    const newZoomValue = (zoom + zoomValue) >= MAX_ZOOM ? MAX_ZOOM : (zoom + zoomValue) <= MIN_ZOOM ? MIN_ZOOM : (zoom + zoomValue);

    this.toolbar.setItemDisabled('zoom_out', false);
    this.toolbar.setItemDisabled('zoom_in', false);
    if (newZoomValue <= MIN_ZOOM) {
      this.toolbar.setItemDisabled('zoom_out', true);
    } else if (newZoomValue >= MAX_ZOOM) {
      this.toolbar.setItemDisabled('zoom_in', true);
    }

    this.toolbar.items.forEach(item => {
      if (item.groupName === 'zoom') {
        this.toolbar.setItemChecked(item.name, false);
      }
    });

    this.store.dispatch({
      type: 'ZOOM',
      zoom: newZoomValue
    });
    this.win.navigationState.fitMode = '';
    this.win.navigationState.zoomState = newZoomValue;
    this.handleUpdateViewportPoints(this.getImageWidthHeight());
  },

  handleRotate: function (rotateBy) {
    if (this.missingPage) {
      return;
    }
    const { rotation, rotationDegree } = this.store.getState();

    const workerCallback = e => {
      const { rotation, rotationDegree } = this.store.getState();
      this.store.dispatch({
        type: 'ROTATE',
        rotation: rotation !== rotationDegree ? rotation + Math.sign(rotateBy) * ROTATION_SPEED : rotationDegree,
      });
      this.handleUpdateViewportPoints(this.getImageWidthHeight());

      if (rotation === rotationDegree) {
        this.rotateWorker.stop();
      }
    };

    this.rotateWorker.start(workerCallback, 1000 / 60);

    if (rotation !== rotationDegree) {
      this.store.dispatch({
        type: 'ROTATE',
        rotation: rotationDegree,
      });
    }

    this.store.dispatch({
      type: 'UPDATE_ROTATION_DEGREE',
      rotationDegree: rotationDegree + rotateBy
    });

    this.win.navigationState.rotationState = rotationDegree + rotateBy;

  },

  // set the image to fit to the width of the canvas
  handleFitToWidth: function (checked) {
    this.win.navigationState.fitMode = 'fit_to_width';
    this.store.dispatch({
      type: 'FIT_TO_WIDTH'
    });
  },

  // set the image to fit to the width of the canvas
  handleFitToHeight: function (checked) {
    this.win.navigationState.fitMode = 'fit_to_height';
    this.store.dispatch({
      type: 'FIT_TO_HEIGHT'
    });
  },

  // set the image to fit the canvas
  handleFitToView: function (checked) {
    this.win.navigationState.fitMode = 'fit_to_view';
    this.store.dispatch({
      type: 'FIT_TO_VIEW'
    });
  },

  // zoom image to actual size (100% zoom)
  handleActualSize: function (checked) {
    this.win.navigationState.fitMode = 'actual_size';
    this.store.dispatch({
      type: 'ACTUAL_SIZE'
    });
  },

  handleCanvasMouseWheel: function (event) {
    if (this.missingPage) {
      return;
    }
    const { zoom } = this.store.getState();
    const delta = Math.max(-1, Math.min(1, (-event.deltaY || -event.detail)));
    const newZoom = delta * zoom * 0.2;
    this.handleZoom(newZoom);
  },

  handleNavCanvasMouseDown: function (event) {
    if (this.missingPage) {
      return;
    }
    const { offsetPoint, windowRef } = this.store.getState();

    //user is moving the navCanvas not the mainCanvas
    this.store.dispatch({
      type: 'MOVE_POINT_CHANGE',
      isMouseOnNavigator: true,
      offsetPoint,
      mouseLastPosition: {
        x: event.pageX,
        y: event.pageY
      }
    });

    windowRef.document.addEventListener('mousemove', this.handleDocumentMouseMove);
    windowRef.document.addEventListener('mouseup', this.handleDocumentMouseUp);
    event.preventDefault();
  },

  handleCanvasMouseDown: function (event) {

    this.store.dispatch({
      type: 'MOUSE_ON_NAVIGATOR',
      isMouseOnNavigator: false
    });

    if (this.missingPage) {
      return;
    }
    const {
      isDistanceTool, isMoveTool,
      isDensityTool, offsetPoint, windowRef
    } = this.store.getState();

    const pointOnCanvas = this.mainCanvasInstance.canvasUtilities.getCanvasByScreenPoint(event.pageX, event.pageY);
    const image = this.getSelectedImage();
    const pointOnStage = this.mainStageInstance.stageUtilities.getStageByCanvasPoint_Limited(pointOnCanvas.x, pointOnCanvas.y, image.width, image.height);

    if (isDistanceTool) {
      this.store.dispatch({
        type: 'DISTANCE_POINT_CHANGE',
        distanceToolPoints: {
          point: pointOnStage,
          pointTo: pointOnStage,
          measurementData: this.getUpdatedMeasurementData()
        }
      });
    } else if (isMoveTool) {
      // const pointOnCanvas2 = this.mainCanvasInstance.canvasUtilities.getCanvasByScreenPoint(event.pageX, event.pageY);
      // const pointOnStage2 = this.mainStageInstance.stageUtilities.getStageByCanvasPoint(pointOnCanvas2.x, pointOnCanvas2.y);

      // console.log('Stage: ' + pointOnStage2.x + ' y: ' + pointOnStage2.y);
      // console.log('Event pageX: ' + event.pageX + ' event pagey: ' + event.pageY);
      // console.log('Offset Point: ' + offsetPoint.x + ' y: ' + offsetPoint.y);
      // const image = this.getSelectedImage();
      // const halfImageWidth = image.width / 2;
      // const halfImageHeight = image.height / 2;

      this.store.dispatch({
        type: 'MOVE_POINT_CHANGE',
        isMouseOnNavigator: false,
        offsetPoint,
        mouseLastPosition: {
          x: event.pageX,
          y: event.pageY
        }
      });
    } else if (isDensityTool) {

      this.store.dispatch({
        type: 'DENSITY_POINT_CHANGE',
        mouseLastPosition: {
          x: pointOnStage.x,
          y: pointOnStage.y
        },
        mouseDensityPosition: {
          x: pointOnStage.x,
          y: pointOnStage.y
        },
        densityData: this.getUpdatedDensityData({
          x: pointOnStage.x,
          y: pointOnStage.y
        })
      });
    }

    windowRef.document.addEventListener('mousemove', this.handleDocumentMouseMove);
    windowRef.document.addEventListener('mouseup', this.handleDocumentMouseUp);
    event.preventDefault();
  },

  handleDocumentMouseMove: function (event) {
    if (this.missingPage) {
      return;
    }
    const {
      isDistanceTool, isMoveTool, offsetPoint, distanceToolPoints,
      mouseLastPosition, isMouseOnNavigator, zoom,
      mainCanvasHeight, mainCanvasWidth, rotation, flipHorizontal, flipVertical, images, selectedImageKey
    } = this.store.getState();

    const image = (!isUndefined(images) && !isUndefined(selectedImageKey) && !isUndefined(images[selectedImageKey])) ? this.getSelectedImage() : undefined;

    if (isMouseOnNavigator) {
      //do navigator related things here
      const maxHeight = ((rotation % 180) === 0) ? this.getSelectedImage().height * zoom / 2 : this.getSelectedImage().width * zoom / 2;
      const maxWidth = ((rotation % 180) === 0) ? this.getSelectedImage().width * zoom / 2 : this.getSelectedImage().height * zoom / 2;
      const flipHorizontalValue = !!flipHorizontal ? -1 : 1;
      const flipVerticalValue = !!flipVertical ? -1 : 1;
      const navZoomValue = isUndefined(this.navCanvasInstance.getNavZoom()) ? 1 : this.navCanvasInstance.getNavZoom();
      const movePosX = ((mouseLastPosition.x - event.pageX) * zoom) / navZoomValue;
      const movePosY = ((mouseLastPosition.y - event.pageY) * zoom) / navZoomValue;
      const oPoint = {
        x: (Math.abs((movePosX + offsetPoint.x)) > maxWidth) ? offsetPoint.x : ((movePosX + offsetPoint.x)),
        y: (Math.abs((movePosY + offsetPoint.y)) > maxHeight) ? offsetPoint.y : ((movePosY + offsetPoint.y))
      };

      this.store.dispatch({
        type: 'MOVE_POINT_CHANGE',
        isMouseOnNavigator: true,
        offsetPoint: {
          x: oPoint.x,
          y: oPoint.y,
        },
        mouseLastPosition: {
          x: event.pageX,
          y: event.pageY
        }
      });
      this.handleUpdateViewportPoints(this.getImageWidthHeight());
      return;
    }
    if (isDistanceTool) {
      const pointOnCanvas = this.mainCanvasInstance.canvasUtilities.getCanvasByScreenPoint(event.pageX, event.pageY);
      // const image = this.getSelectedImage();
      const pointOnStage = this.mainStageInstance.stageUtilities.getStageByCanvasPoint_Limited(pointOnCanvas.x, pointOnCanvas.y, image.width, image.height);

      this.store.dispatch({
        type: 'DISTANCE_POINT_CHANGE',
        distanceToolPoints: {
          point: distanceToolPoints.point,
          pointTo: pointOnStage,
          measurementData: this.getUpdatedMeasurementData()
        }
      });
    } else if (isMoveTool) {
      const maxHeight = ((rotation % 180) === 0) ? this.getSelectedImage().height * zoom / 2 : this.getSelectedImage().width * zoom / 2;
      const maxWidth = ((rotation % 180) === 0) ? this.getSelectedImage().width * zoom / 2 : this.getSelectedImage().height * zoom / 2;
      const xMax = (offsetPoint.x > 0) ? maxWidth : -maxWidth;//center of the canvas is xMax
      const yMax = (offsetPoint.y > 0) ? maxHeight : -maxHeight;//center of canvas y
      const oPoint = {
        x: (Math.abs(event.pageX - mouseLastPosition.x + offsetPoint.x) > maxWidth) ? xMax : (event.pageX - mouseLastPosition.x + offsetPoint.x),
        y: (Math.abs(event.pageY - mouseLastPosition.y + offsetPoint.y) > maxHeight) ? yMax : (event.pageY - mouseLastPosition.y + offsetPoint.y)
      };
      // console.log('totation: ' + rotation);
      // console.log('offsetpoint: x: ' + offsetPoint.x + " y: " + offsetPoint.y);
      // console.log('oPoint: x: ' + oPoint.x + " y: " + oPoint.y);
      // console.log('mouseLastPosition: x:' + event.pageX + " y: " + event.pageY);

      this.store.dispatch({
        type: 'MOVE_POINT_CHANGE',
        offsetPoint: {
          x: oPoint.x,
          y: oPoint.y,
        },
        mouseLastPosition: {
          x: event.pageX,
          y: event.pageY
        }
      });
    }
    this.handleUpdateViewportPoints(this.getImageWidthHeight());
    event.preventDefault();
  },

  handleDocumentMouseUp: function (event) {
    if (this.missingPage) {
      return;
    }
    const {
      images, selectedImageKey, isDistanceTool, isMoveTool, offsetPoint,
      distanceToolPoints, mouseLastPosition, zoom, windowRef, isMouseOnNavigator, isDensityTool
    } = this.store.getState();

    if (!isMouseOnNavigator) {
      const pointOnCanvas = this.mainCanvasInstance.canvasUtilities.getCanvasByScreenPoint(event.pageX, event.pageY);
      const image = this.getSelectedImage();
      const pointOnStage = this.mainStageInstance.stageUtilities.getStageByCanvasPoint_Limited(pointOnCanvas.x, pointOnCanvas.y, image.width, image.height);

      if (isDistanceTool) {
        this.store.dispatch({
          type: 'DISTANCE_POINT_CHANGE',
          distanceToolPoints: {
            point: distanceToolPoints.point,
            pointTo: pointOnStage,
            measurementData: this.getUpdatedMeasurementData()
          }
        });
      } else if (isMoveTool) {
        const oPoint = {
          x: (event.pageX - mouseLastPosition.x + offsetPoint.x),
          y: (event.pageY - mouseLastPosition.y + offsetPoint.y)
        };
        // console.log('md offsetpoint: x: ' + offsetPoint.x + " y: " + offsetPoint.y);
        // console.log('md mouseLastPosition: x:' + event.pageX + " y: " + event.pageY);

        if (Math.abs(oPoint.x) < this.getSelectedImage().width * zoom / 2 &&
          Math.abs(oPoint.y) < this.getSelectedImage().height * zoom / 2) {
          this.store.dispatch({
            type: 'MOVE_POINT_CHANGE',
            offsetPoint: {
              x: oPoint.x,
              y: oPoint.y,
            },
            mouseLastPosition: {
              x: undefined,
              y: undefined
            }
          });
        }
      } else if (isDensityTool) {
        this.store.dispatch({
          type: 'DENSITY_POINT_CHANGE',
          mouseLastPosition: {
            x: pointOnStage.x,
            y: pointOnStage.y
          },
          mouseDensityPosition: {
            x: pointOnStage.x,
            y: pointOnStage.y
          },
          densityData: this.getUpdatedDensityData({
            x: pointOnStage.x,
            y: pointOnStage.y
          })
        });
      }
    }
    windowRef.document.removeEventListener('mousemove', this.handleDocumentMouseMove);
    windowRef.document.removeEventListener('mouseup', this.handleDocumentMouseUp);
    event.preventDefault();
  },

  handleDocumentKeyDown: function (e) {
    //dont allow default browser actions for these key events
    if (e.ctrlKey) {
      switch (e.code) {
        case 'NumpadAdd':
        case 'Equal':
          e.preventDefault();
          this.toolbar.clickItem('zoom_in');
          break;
        case 'Minus':
        case 'NumpadSubtract':
          e.preventDefault();
          this.toolbar.clickItem('zoom_out');
          break;
        case 'KeyV':
          e.preventDefault();
          this.toolbar.clickItem('ApproveAllActionCR');
          break;
        case 'KeyX':
          e.preventDefault();
          this.toolbar.clickItem('RejectAllActionCR');
          break;
        case 'Digit0':
        case 'Numpad0':
          e.preventDefault();
          this.toolbar.clickItem('fit_to_height');
          break;
        case 'Numpad1':
        case 'Digit1':
          e.preventDefault();
          this.toolbar.clickItem('actual_size');
          break;
        case 'Digit2':
        case 'Numpad2':
          e.preventDefault();
          this.toolbar.clickItem('fit_to_width');
          break;
        case 'Digit3':
        case 'Numpad3':
          e.preventDefault();
          this.toolbar.clickItem('fit_to_height');
          break;
        case 'PageDown':
        case 'ArrowRight':
        case 'PageUp':
        case 'ArrowLeft':
          e.preventDefault();
          this.nextSep(e.code); //always keep color on if in singleSep mode
          break;
        case 'KeyR':
          e.preventDefault();
          break;
        case 'F5':
          e.preventDefault();
          break;
      }
    } else {
      switch (e.code) {
        case 'PageDown':
        case 'ArrowRight':
        case 'ArrowDown':
          e.preventDefault();
          this.navigator.handleNextClick(e);
          break;
        case 'PageUp':
        case 'ArrowLeft':
        case 'ArrowUp':
          e.preventDefault();
          this.navigator.handlePreviousClick(e);
          break;
        case 'F5':
          e.preventDefault();
          break;
      }
    }
  },

  nextSep: function (key) {
    //if up arrow is pressed and is single sep mode iterate through seps
    //Note: Enable this to allow 37=rt, 39=lt on single sep to iterate separations instead of pages
    const isSingleSep = this.toolbar.items.findBy('name', 'singleSep').checked;
    if ((key === 'ArrowRight' || key === 'ArrowLeft') && isSingleSep) {
      var sepName = '';
      if (this.toolbar.items.findBy('name', 'black').checked) {
        if (key === 'ArrowRight') {
          sepName = 'cyan';
        } else {
          sepName = 'singleSep';
        }
      } else if (this.toolbar.items.findBy('name', 'cyan').checked) {
        if (key === 'ArrowRight') {
          sepName = 'magenta';
        } else {
          sepName = 'black';
        }
      } else if (this.toolbar.items.findBy('name', 'magenta').checked) {
        if (key === 'ArrowRight') {
          sepName = 'yellow';
        } else {
          sepName = 'cyan';
        }
      } else if (this.toolbar.items.findBy('name', 'yellow').checked) {
        if (key === 'ArrowRight') {
          sepName = 'singleSep';
        } else {
          sepName = 'magenta';
        }
      }
      if (sepName != '') {
        this.toolbar.clickItem(sepName);
      }
    } else {
      if (key === 'ArrowRight') {
        this.toolbar.clickItem('singleSep');
      } else {
        this.toolbar.clickItem('singleSep');
        this.toolbar.clickItem('yellow');
      }
    }
  },

  getUpdateOverlayBoxes: function (OverlayBoxesProperty) {
    const { overlayBoxes } = this.store.getState();
    return !isUndefined(overlayBoxes[OverlayBoxesProperty]) ? overlayBoxes[OverlayBoxesProperty].map(box => {
      return {
        ...box,
        legendData: {
          ...box.legendData,
          x: this.convertFromInchToMeasurementsUnits(box.left, box.legendData.xResolution),
          y: this.convertFromInchToMeasurementsUnits(box.top, box.legendData.yResolution),
          width: this.convertFromInchToMeasurementsUnits(box.width, box.legendData.xResolution),
          height: this.convertFromInchToMeasurementsUnits(box.height, box.legendData.yResolution),
        }
      };
    }) : [];

  },

  handleMeasurementUnitChange: function (event) {
    const { distanceToolPoints } = this.store.getState();
    let unit = event.target.value;
    this.store.dispatch({
      type: 'MEASUREMENT_UNIT_CHANGE',
      measurementUnit: unit
    });
    this.win.navigationState.measurementUnit = unit;
    this.store.dispatch({
      type: 'DISTANCE_POINT_CHANGE',
      distanceToolPoints: {
        point: distanceToolPoints.point,
        pointTo: distanceToolPoints.pointTo,
        measurementData: this.getUpdatedMeasurementData()
      }
    });

    this.store.dispatch({
      type: 'LOAD_OVERLAY_BOXES',
      overlayBoxes: {
        mediaOverlayBoxes: this.getUpdateOverlayBoxes('mediaOverlayBoxes'),
        pdfOverlayBoxes: this.getUpdateOverlayBoxes('pdfOverlayBoxes')
      }
    });
  },

  getUpdatedDensityData: function (mouseLastPosition) {

    const { images, windowRef, mainCanvasHeight, mainCanvasWidth } = this.store.getState();

    if (isUndefined(mouseLastPosition) || isUndefined(mouseLastPosition.x)) return {
      cyan: 0,
      magenta: 0,
      yellow: 0,
      black: 0,
      totalInkCoverage: 0
    };//just return default data if problem with mouse position

    const tempCanvas = windowRef.document.createElement('canvas');
    const ctx = tempCanvas.getContext('2d');

    tempCanvas.width = mainCanvasWidth;
    tempCanvas.height = mainCanvasHeight;

    //cyan layer
    let cyanData = [0, 0, 0];
    if (images['cyan']) {
      ctx.drawImage(images['cyan'].image, Math.round(mouseLastPosition.x + images['cyan'].image.width / 2), Math.round(mouseLastPosition.y + images['cyan'].image.height / 2), 5, 5, 0, 0, 5, 5);//just draw the 5X5 pixel where the mouse went down.
      cyanData = ctx.getImageData(0, 0, 5, 5).data;
    }
    const aCyan = rgb2cmyk(cyanData[0], cyanData[1], cyanData[2]);

    //magenta layer
    let magentaData = [0, 0, 0];
    if (images['magenta']) {
      ctx.drawImage(images['magenta'].image, Math.round(mouseLastPosition.x + images['magenta'].image.width / 2), Math.round(mouseLastPosition.y + images['magenta'].image.height / 2), 5, 5, 0, 0, 5, 5);//just draw the 5X5 pixel where the mouse went down.
      magentaData = ctx.getImageData(0, 0, 5, 5).data;
    }
    const aMagenta = rgb2cmyk(magentaData[0], magentaData[1], magentaData[2]);

    //yellow layer
    let yellowData = [0, 0, 0];
    if (images['yellow']) {
      ctx.drawImage(images['yellow'].image, Math.round(mouseLastPosition.x + images['yellow'].image.width / 2), Math.round(mouseLastPosition.y + images['yellow'].image.height / 2), 5, 5, 0, 0, 5, 5);//just draw the 5X5 pixel where the mouse went down.
      yellowData = ctx.getImageData(0, 0, 5, 5).data;
    }
    const aYellow = rgb2cmyk(yellowData[0], yellowData[1], yellowData[2]);

    //black layer
    let blackData = [0, 0, 0];
    if (images['black']) {
      ctx.drawImage(images['black'].image, Math.round(mouseLastPosition.x + images['black'].image.width / 2), Math.round(mouseLastPosition.y + images['black'].image.height / 2), 5, 5, 0, 0, 5, 5);//just draw the 5X5 pixel where the mouse went down.
      blackData = ctx.getImageData(0, 0, 5, 5).data;
    }
    const aBlack = rgb2cmyk(blackData[0], blackData[1], blackData[2]);

    return {
      cyan: Math.round(aCyan[0] * 100),
      magenta: Math.round(aMagenta[1] * 100),
      yellow: Math.round(aYellow[2] * 100),
      black: Math.round(aBlack[3] * 100),
      totalInkCoverage: (Math.round(aCyan[0] * 100) + Math.round(aMagenta[1] * 100) + Math.round(aYellow[2] * 100) + Math.round(aBlack[3] * 100))
    };
  },

  getUpdatedMeasurementData: function (bReset = false) {
    const { distanceToolPoints, measurementUnit, resolution } = this.store.getState();
    const pw = this.getImageMeasurements(WIDTH);
    const ph = this.getImageMeasurements(HEIGHT);
    let wt, ht, dt;

    if (bReset) {
      return {
        fromX: undefined,
        fromY: undefined,
        toX: undefined,
        toY: undefined,
        w: undefined,
        h: undefined,
        d: undefined,
        pw: pw,
        ph: ph
      };
    }

    let xt1 = isUndefined(distanceToolPoints.point) ? undefined : Math.round(distanceToolPoints.point.x + pw / 2);
    let xt2 = isUndefined(distanceToolPoints.point) ? undefined : Math.round(distanceToolPoints.pointTo.x + pw / 2);
    let yt1 = isUndefined(distanceToolPoints.point) ? undefined : Math.round(distanceToolPoints.point.y + ph / 2);
    let yt2 = isUndefined(distanceToolPoints.point) ? undefined : Math.round(distanceToolPoints.pointTo.y + ph / 2);

    if (!isUndefined(xt1) || !isUndefined(xt2)) {

      if (measurementUnit === POINT) {
        xt1 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.point.x * 72) / resolution.xResolution + pw / 2).toFixed(POINT_PRECISION);
        xt2 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.pointTo.x * 72) / resolution.xResolution + pw / 2).toFixed(POINT_PRECISION);
        yt1 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.point.y * 72) / resolution.yResolution + ph / 2).toFixed(POINT_PRECISION);
        yt2 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.pointTo.y * 72) / resolution.yResolution + ph / 2).toFixed(POINT_PRECISION);
        wt = (xt2 - xt1).toFixed(POINT_PRECISION);
        ht = (yt2 - yt1).toFixed(POINT_PRECISION);
        dt = (Math.round(Math.sqrt((wt * wt) + (ht * ht)))).toFixed(POINT_PRECISION);
      } else if (measurementUnit === INCH) {
        xt1 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.point.x) / resolution.xResolution + pw / 2).toFixed(INCH_PRECISION);
        xt2 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.pointTo.x) / resolution.xResolution + pw / 2).toFixed(INCH_PRECISION);
        yt1 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.point.y) / resolution.yResolution + ph / 2).toFixed(INCH_PRECISION);
        yt2 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.pointTo.y) / resolution.yResolution + ph / 2).toFixed(INCH_PRECISION);
        wt = (xt2 - xt1).toFixed(INCH_PRECISION);
        ht = (yt2 - yt1).toFixed(INCH_PRECISION);
        dt = (Math.sqrt((wt * wt) + (ht * ht))).toFixed(INCH_PRECISION);
      } else if (measurementUnit === MILLIMETER) {
        xt1 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.point.x * 25.4) / resolution.xResolution + pw / 2).toFixed(MILLIMETER_PRECISION);
        xt2 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.pointTo.x * 25.4) / resolution.xResolution + pw / 2).toFixed(MILLIMETER_PRECISION);
        yt1 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.point.y * 25.4) / resolution.yResolution + ph / 2).toFixed(MILLIMETER_PRECISION);
        yt2 = isUndefined(distanceToolPoints.point) ? undefined : ((distanceToolPoints.pointTo.y * 25.4) / resolution.yResolution + ph / 2).toFixed(MILLIMETER_PRECISION);
        wt = (xt2 - xt1).toFixed(MILLIMETER_PRECISION);
        ht = (yt2 - yt1).toFixed(MILLIMETER_PRECISION);
        dt = (Math.sqrt((wt * wt) + (ht * ht))).toFixed(MILLIMETER_PRECISION);
      }
    }

    return {
      fromX: xt1,
      fromY: yt1,
      toX: xt2,
      toY: yt2,
      w: wt,
      h: ht,
      d: dt,
      pw: pw,
      ph: ph
    };
  },

  getSelectedVersion: function () {
    const { versions, selectedVersionNumber } = this.store.getState();
    return versions[selectedVersionNumber] || -1;
  },

  getActiveVersion: function () {
    const { versions, activeVersionNumber } = this.store.getState();
    return versions[activeVersionNumber] || -1;
  },

  handleChangeBoxesSelected: function (boxIndex, overlayBoxesName, checked) {
    this.store.dispatch({
      type: 'UPDATE_SELECTED_BOX',
      overlayBoxesName,
      boxIndex,
      checked
    });
  },

  handleChangeGuidelineSelected: function (guidelineName, checked) {
    this.store.dispatch({
      type: 'UPDATE_SELECTED_GUIDELINE',
      guidelineName,
      checked
    });
  },

  renderCanvasImage: function (image) {
    return () => {
      if (!image) {
        return null;
      }

      const { imagePoint } = this.store.getState();

      return <CanvasImage key='CanvasImage' image={image} point={image.imagePoint || imagePoint} />;
    };
  },

  getImageWidthHeight: function () {
    const { width, height } = this.getSelectedImage() || {};

    return { width, height };
  },

  handleUpdateTilesInViewport: function () {
  },//for hires view

  destroy: function () {
    this._super();
    this.win.document.removeEventListener('keydown', this.handleDocumentKeyDown);
    if (this.reactRoot !== null) {
      this.reactRoot.unmount();
      this.reactRoot = null;
    }

  },

  render: function () {

    if (isUndefined(this)) return;
    const {
      images,
      selectedImageKey,
      mainCanvasWidth,
      mainCanvasHeight,
      offsetPoint,
      rotation,
      zoom,
      flipHorizontal,
      flipVertical,
      showNavigator,
      isDistanceTool,
      isMoveTool,
      isDensityTool,
      imagePoint,
      guidelines,
      gridCells,
      showMediaBoxes,
      showPDFBoxes,
      showGuidelines,
      showPageLabels,
      showVersions,
      overlayBoxes,
      measurementUnit,
      isMouseOnNavigator,
      mouseLastPosition,
      mouseDensityPosition,
      distanceToolPoints,
      versions,
      windowRef,
      compareData,
      densityData,
      activeVersionNumber,
      selectedVersionNumber,
      isHold,
      compareVersionsNumbersSelected,
      viewType,
      loading,
      togglingBetweenCompareVersionsIndex,
      viewportPoints,
      hiresNavigatorImage
    } = this.store.getState();

    const image = (!isUndefined(images) && !isUndefined(selectedImageKey) && !isUndefined(images[selectedImageKey])) ? this.getSelectedImage() : undefined;
    const hiresNavigatorImageToRender = !isUndefined(hiresNavigatorImage) && viewType === VIEW_TYPE_HIRES_VIEW ? hiresNavigatorImage : undefined;

    this.reactRoot !== null && this.reactRoot.render(
      <PageView image={image}
        hiresNavigatorImage={hiresNavigatorImageToRender}
        images={images}
        windowRef={windowRef}
        mainCanvasHeight={mainCanvasHeight}
        mainCanvasWidth={mainCanvasWidth}
        offsetPoint={offsetPoint}
        showNavigator={showNavigator}
        versions={versions}
        rotation={rotation}
        zoom={zoom}
        flipHorizontal={flipHorizontal}
        flipVertical={flipVertical}
        isDistanceTool={isDistanceTool}
        isMoveTool={isMoveTool}
        isDensityTool={isDensityTool}
        measurementUnit={measurementUnit}
        imagePoint={imagePoint}
        showMediaBoxes={showMediaBoxes}
        showGuidelines={showGuidelines}
        showPageLabels={showPageLabels}
        showPDFBoxes={showPDFBoxes}
        showVersions={showVersions}
        overlayBoxes={overlayBoxes}
        guidelines={guidelines}
        gridCells={gridCells}
        mouseLastPosition={mouseLastPosition}
        mouseDensityPosition={mouseDensityPosition}
        distanceToolPoints={distanceToolPoints}
        onCanvasResize={this.handleCanvasResize}
        onVersionClick={this.handleVersionClick}
        onMainStageLoad={this.handleMainStageLoad}
        onMainCanvasLoad={this.handleMainCanvasLoad}
        onNavCanvasLoad={this.handleNavCanvasLoad}
        onCanvasMouseDown={this.handleCanvasMouseDown}
        onNavCanvasMouseDown={this.handleNavCanvasMouseDown}
        onDocumentMouseMove={this.handleDocumentMouseMove}
        onDocumentMouseUp={this.handleDocumentMouseUp}
        onMouseWheel={this.handleCanvasMouseWheel}
        onMeasurementUnitChange={this.handleMeasurementUnitChange}
        compareData={compareData}
        densityData={densityData}
        activeVersionNumber={activeVersionNumber}
        selectedVersionNumber={selectedVersionNumber}
        isHold={isHold}
        compareVersionsNumbersSelected={compareVersionsNumbersSelected}
        viewType={viewType}
        loading={loading}
        togglingBetweenCompareVersionsIndex={togglingBetweenCompareVersionsIndex}
        onChangeBoxesSelected={this.handleChangeBoxesSelected}
        onChangeGuidelineSelected={this.handleChangeGuidelineSelected}
        renderCanvasImage={this.renderCanvasImage(image)}
        viewportPoints={viewportPoints}
        onUpdateTilesInViewport={this.handleUpdateTilesInViewport}
        missingPage={this.missingPage}
      />);
  }
});