/**
 * @name rulers
 * @file Creates and draws rules on Fabric canvas
 *
 * @author Boris
 * @since: 2016-09-27
 */

import utils from '../utils/utils';
import Fabric from 'fabric';

const MIN_PIXELS_BETWEEN_MARKERS = 96; // pixels
const STEPS_BETWEEN_MARKERS = 10;
const LARGE_TICK_LENGTH = 18; // pixels
const SMALL_TICK_LENGTH = 5; // pixels
const RULER_COLOR = 'white';
const TICK_COLOR = '#888888';
const FONT_SIZE = 10; // pixels
const FONT_FAMILY = 'Verdana';
const FONT_COLOR = '#888888';

export default (hrulerCanvas, vrulerCanvas) => {

  const createRulerRect = (width, height) => {
    return new Fabric.Rect({
      width: width,
      height: height,
      fill: RULER_COLOR,
      selectable: false,
      evented: false
    })
  };

  const createHRulerRect = (length, thickness) => {
    return createRulerRect(length, thickness);
  };

  const createVRulerRect = (length, thickness) => {
    return createRulerRect(thickness, length);
  };

  const createLine = (coords) => {
    return new Fabric.Line(coords, {
      stroke: TICK_COLOR,
      selectable: false,
      evented: false
    });
  };

  const createHRulerTick = (distance, from, to) => {
    return createLine([distance, from, distance, to]);
  };

  const createVRulerTick = (distance, from, to) => {
    return createLine([from, distance, to, distance]);
  };

  const createMarker = (text, left, top, extraOptions) => {
    var options = {
      left: left,
      top: top,
      fontFamily: FONT_FAMILY,
      fontSize: FONT_SIZE,
      fill: FONT_COLOR,
      centeredRotation: false,
      selectable: false,
      evented: false
    };

    if (extraOptions) {
      for (var key in extraOptions) {
        options[key] = extraOptions[key];
      }
    }

    return new Fabric.Text(text, options);
  };

  const createHRulerMarker = (markerValue, distance) => {
    return createMarker(markerValue.toString(), distance + 2, 1);
  };

  const createVRulerMarker = (markerValue, distance) => {
    var extraOptions = {
      angle: -90,
      originX: 'right',
      originY: 'top'
    };

    return createMarker(markerValue.toString(), 1, distance + 2, extraOptions);
  };

  const roundUnitsBetweenMarkers = (units) => {
    var result = 1;
    if (units > 1 && units <= 10) {
      result = Math.floor(units);
    } else if (units > 10 && units <= 100) {
      result = Math.floor(units / 10) * 10;
    } else if (units > 100) {
      result = Math.floor(units / 100) * 100;
    }

    return result;
  };

  const calcRulerOptions = (length, thickness, start, zoom) => {
    var unitsBetweenMarkers = roundUnitsBetweenMarkers(utils.canvasToUserUnits(MIN_PIXELS_BETWEEN_MARKERS) / zoom);
    var stepsBetweenMarkers = STEPS_BETWEEN_MARKERS;
    var step = utils.userToCanvasUnits(unitsBetweenMarkers / stepsBetweenMarkers) * zoom;
    var l1 = thickness - LARGE_TICK_LENGTH;
    var l2 = thickness;
    var s1 = thickness - SMALL_TICK_LENGTH;
    var s2 = thickness;

    return {
      length,
      thickness,
      start,
      zoom,
      unitsBetweenMarkers,
      stepsBetweenMarkers,
      step,
      l1,
      l2,
      s1,
      s2
    };
  };

  const drawRuler = (rulerOptions) => {
    var markers = [];
    var ticks = [];
    var o = rulerOptions;

    var stepIndex = 0;
    for (var d = o.start; d < o.length; d += o.step) {
      if (d >= o.thickness) {
        if (stepIndex % o.stepsBetweenMarkers === 0) {
          ticks.push(o.createTick(d, o.l1, o.l2));
          var markerValue = o.unitsBetweenMarkers * Math.round(stepIndex / o.stepsBetweenMarkers);
          markers.push(o.createMarker(markerValue, d));
        } else {
          ticks.push(o.createTick(d, o.s1, o.s2));
        }
      }
      stepIndex++;
    }

    o.canvas.add.apply(o.canvas, [o.createRulerRect(o.length, o.thickness)].concat(ticks, markers));

    o.canvas.renderAll();
  };

  const updateHRuler = (length, thickness, start, zoom) => {
    hrulerCanvas.clear();

    hrulerCanvas.setDimensions({width: length, height: thickness});

    var o = calcRulerOptions(length, thickness, start, zoom);
    o.createTick = createHRulerTick;
    o.createMarker = createHRulerMarker;
    o.createRulerRect = createHRulerRect;
    o.canvas = hrulerCanvas;
    drawRuler(o);
  };

  const updateVRuler = (length, thickness, start, zoom) => {
    vrulerCanvas.clear();

    vrulerCanvas.setDimensions({width: thickness, height: length});

    var o = calcRulerOptions(length, thickness, start, zoom);
    o.createTick = createVRulerTick;
    o.createMarker = createVRulerMarker;
    o.createRulerRect = createVRulerRect;
    o.canvas = vrulerCanvas;
    drawRuler(o);
  };

  return {
    updateHRuler,
    updateVRuler
  }

};
