/**
 * Created by tzvikas on 4/30/2015 .
 */

define(['sandbox', 'sandbox/planSetup', './../../../utils/wizardUtils'],
  function(sandbox, PlanSetup, wizardUtils){
    'use strict';

    function resolvePmiTemplate(templates, pmiTemplate){
      for (var i=0;i<templates.length;i++){
        if (templates[i].name === pmiTemplate){
          return templates[i];
        }
      }
    }

    function trimLastDash(s){
      if (s.charAt(s.length-1) === "-"){
        return s.substring(0, s.length - 1);
      }
      return s;
    }

    function findRole(role, layoutLinks){
      for (var i=0;i<layoutLinks.length;i++){
        if (layoutLinks[i].role === role){
          return layoutLinks[i].nwid;
        }
      }
      return null;
    }

    function findImpositionRole(layoutLinks, nwid){
      for (var i=0;i<layoutLinks.length;i++){
        if (layoutLinks[i].nwid === nwid){
          return layoutLinks[i].role;
        }
      }
      return null;
    }
	
	function isVirtual (page){
      return page.virtual && !page.fakeVirtual;
    }

    function isPanorama (page){
      return page.panorama && !page.fakePanorama;
    }
    
    function resolveFormLayouts(forms){
      var ret = {};
      if ( typeof forms === 'undefined' || forms === null){
        return ret;
      }

      forms.forEach(function (form){
        if (form.layoutNwId)
          ret[form.sourceId] = form.layoutNwId;
      });
      return ret;
    }

    function isPanoramaForm(pages){
      for (var i=0;i<pages.length;i++){
        if (!pages[i]){
          continue;
        }
        if (pages[i].constructor === Array){
          for (var j=0;j<pages[i].length;j++){
            if (pages[i][j] && isPanorama(pages[i][j])){
              return true;
            }
          }
        } else {
          if (pages[i] && isPanorama(pages[i])){
            return true;
          }
        }

      }
      return false;
    }

    function matchRole(index,numForms, layoutLinks, pages){      
      var ret = "";
      var isPanorama = isPanoramaForm(pages);
      if (isPanorama){
        ret = findRole("panorama", layoutLinks);
        if (ret !== null){
          return {role:"panorama", imposition:ret};
        }
      }
      if (index === 0){
        ret = findRole("first", layoutLinks);
        if (ret !== null){
          return {role:"first", imposition:ret};
        }
      } else if (index + 1 === numForms){
        ret = findRole("last", layoutLinks);
        if (ret !== null){
          return {role:"last", imposition:ret};
        }
      }

      if ((index+1) % 2 === 0){
        ret = findRole("even", layoutLinks);
      } else {
        ret = findRole("odd", layoutLinks);
      }

      if (ret === null){
        ret = findRole("general", layoutLinks);
      } else {
        return {role:(index+1) % 2 === 0 ? "even" : "odd", imposition:ret};
      }

      if (ret === null){
        return {role:"custom", imposition:layoutLinks[0].nwid};   
      }
      return {role:"general", imposition:ret};
    }

    function isHalfWeb(pages){
      for (var i=0;i<pages.length ;i++ )
      {
        for (var j=0;j<pages[i].length ;j++ )
        {
          if (pages[i][j].halfWeb)
          {
            return true;
          }
        }
      }
      return false;
    }

    function getMatrixPosition(node){
      var row = parseInt(node.row, 10);
      var column = parseInt(node.column, 10);
      switch (row)
      {
        case 1:
          return column === 1 ? 0 : 1;
        default:
          return column === 1 ? 2 : 3;
      }
    }

    function getMatrixPositionNew(node){
      var row = parseInt(node.row, 10);
      var column = parseInt(node.column, 10);
      switch (row)
      {
        case 0:
          return column === 0 ? 0 : 1;
        default:
          return column === 0 ? 2 : 3;
      }
    }

    function get8upsMatrixPosition(node){
      var row = parseInt(node.row, 10);
      var column = parseInt(node.column, 10);
      switch (row)
      {
        case 1:
          return column - 1;
        default:
          return 3 + column;
      }
    }

    function getPairPosition(node){
      var row = parseInt(node.row, 10) + 1;
      var column = parseInt(node.column, 10) + 1;
      return row * column - 1;
    }

    function getEvenPlatePage(node, cells, columns){
      var row = parseInt(node.row, 10) + 1;
      var column = parseInt(node.column, 10);
      var opositeC = (columns - 1 - column) + 1;

      //for (var i=0;i<cells.length-1;i++){
      //  var r = parseInt(cells[i].row, 10);
      //  var c = parseInt(cells[i], 10);
      //  if (r === row && c === opositeC){
      //
      //  }
      //}
      var ret = (row-1)*columns + opositeC;

      return ret - 1;
    }

    function get8upsMatrixPositionNew(node, columns){
      var row = parseInt(node.row, 10) + 1;
      var column = parseInt(node.column, 10) + 1;
      //var r = row % 2;
      //switch (r)
      //{
      //  case 0:
      //    return column - 1;
      //  default:
      //    return 3 + column;
      //}
      var ret = (row-1)*columns + column;
      return ret - 1;
    }

    function getDinkyNumber(allPages){
      for (var i=0;i<allPages.length ; i++){
        var pages = allPages[i];
        if (pages === null || typeof pages === 'undefined'){
          continue;
        }
        for (var j=0;j<pages.length ;j++ )
        {
          if (pages[j].eightUpDinky){
            return i+1;
          }
        }
      }
      return -1;
    }

    function isDinky8UpsPlate(dinkyNumber, allPages){
      for (var i=0;i<allPages.length ; i++){
        var pages = allPages[i];
        if (pages === null || typeof pages === 'undefined'){
          continue;
        }
        for (var j=0;j<pages.length ;j++ )
        {
          if (pages[j].numberInBook === dinkyNumber || pages[j].numberInBook === dinkyNumber + 1){
            return true;
          }
        }
      }
      return false;
    }

    function isDinkyInThisPlate (platePages, dinky){
      for (var i=0;i<platePages.length ; i++)
      {
        var pages = platePages[i];
        if (pages === null || typeof pages === 'undefined'){
          continue;
        }

        for (var j=0;j<platePages[i].length ;j++ )
        {
          if (platePages[i][j].numberInBook === dinky){
            return platePages[i][j].numberInBook;
          }
        }


      }
      return -1;
    }

    function matchLocations(locations) {
      var ret = [];
      for (var i=0;i<locations.length ;i++ ){
        ret[i] = (locations[i] % 2 === 0) ? locations[i] + 1 : locations[i] - 1;
      }
      return ret;
    }

    function getPairs1(pages){
      var ret = [];
      for (var i=0,j=pages.length-1;j>i;i++,j--){
        ret[i] = [pages[i], pages[j]];
      }
      return ret;
    }
    function getPairsTabs(tabs){
      var ret = [];
      for (var i=0,j=tabs.length-1;j>i;i++,j--){
        var lowHalfWeb = isHalfWeb(tabs[i]);
        var highHalfWeb = isHalfWeb(tabs[j]);

        if (lowHalfWeb)
        {
          ret[i] = [tabs[i], []];
          ret[i+1] = [[], tabs[i+1]];
          i++;
          j++;
        } else if (highHalfWeb){
          ret[j] = [[], tabs[j]];
          ret[j-1] = [tabs[j-1], []];
          i--;
          j--;
        } else {
          ret[i] = [tabs[i], tabs[j]];
        }

      }
      for (i=ret.length-1;i>=0 ;i-- )
      {
        if (typeof ret[i] === 'undefined' || ret[i] === null)
        {
          ret.splice(i, 1);
        }
      }
      return ret;
    }

    

    function getEightUpsPairs(pages){
      var numPages = pages.length;
      var ret = [];
      var numPlates = numPages/4;
      if (numPlates  < 1){
        numPlates = 1;
      }

     
      var dinky = getDinkyNumber(pages);
      let pairs = [];
      for (let i=0, j=numPages-1; i<numPlates; i++, j--) {
        let pair = [];
        pair.push(pages[i]);
        pair.push(pages[numPages/2-i - 1]);
        
        pair.push(pages[numPages/2+i]);
        pair.push(pages[j]);
  //			System.out.println(pair);
        pairs.push(pair);
      }

      let _8ups = [];
      for (let i=0; i<pairs.length; i++) {
        let pair = pairs[i];
        if (isDinky8UpsPlate(dinky, pair)){
          const platePages = [pair[0], null, null, pair[1], pair[2], null, null, pair[3]];
          _8ups.push(platePages);          
          pairs.splice(i, 1);
          i--;
        }
      }
      
      for (let i=0;i<pairs.length/2;i++) {	
			
        let l1 = pairs[i];
        let l2 = pairs[pairs.length - 1 - i];
        l1 = l1.concat(l2);
        l1.sort(function(a, b){          
          return parseInt(a[0].numberInBook) - parseInt(b[0].numberInBook)
        });
        _8ups.push(l1);
        
        
      }

      
      return _8ups;
    }

    
    function getPairs(pages){
      var ret = [];
      //pages.sort(function (a,b){
      //  return a[0].numberInZone - b[0].numberInZone;
      //});
      //for (var i=0;i<pages.length;i++){
      //  for (var j=0;j<pages[i].length;j++){
      //    pages[i][j].numberInBook = i + 1;
      //    console.log (pages[i][j].numberInBook + ":" + pages[i][j].displayName)
      //  }
      //}
      for (var i=0,j=pages.length-1;j>i;i++,j--){
        var dinky = -1;
        if (pages[i][0].isDinky){
          dinky = pages[i][0].numberInBook;
        } else if (pages[j-1][0].isDinky){
          dinky = pages[j-1][0].numberInBook;
        }
        var lowDinkyNum = i + 1;
        var highDinkyNum = j;

        if (lowDinkyNum === dinky)
        {
          ret[i] = [pages[i]];
          ret[i+1] = [pages[i+1]];
          i++;
          j++;
        } else if (highDinkyNum ===  dinky){
          ret[j] = [pages[j]];
          ret[j-1] = [pages[j-1]];
          i--;
          j--;
        } else {
          ret[i] = [pages[i], pages[j]];
        }
      }

      for (i=ret.length-1;i>=0 ;i-- )
      {
        if (typeof ret[i] === 'undefined' || ret[i] === null)
        {
          ret.splice(i, 1);
        }
      }
      return ret;
    }

    //function getPairsWithDinkies(pages, )
    function updateFormSeparations(form, pages){
      var seps = {};
      var existsSeps = {};
      if (form.separations === null || typeof form.separations === 'undefined'){
        form.separations = [];
      }
      form.separations.forEach(function (separation){
        existsSeps[separation.displayName] = 0;
      });
      pages.forEach(function(page){
        if (typeof page !== 'undefined' && page !== null && page.hasOwnProperty("separations")){
          page.separations.forEach(function (separation){
            seps[separation.displayName] = 1;
          });
        }
      });

      Object.keys(seps).forEach(function (key){
        if (existsSeps.hasOwnProperty(key)){
          existsSeps[key] = 1;
        } else {
          var sepSourceId = form.sourceId + "/" + key;
          var separation = {displayName:key, sourceId:sepSourceId.toLowerCase(), parent:form.sourceId};
          form.separations.push(separation);
        }
      });
    }



    function createBroadsheetFormsNew(layoutInfo, book, pages){
      var forms = [];

      var defaultLayout = book.defaultLayout;
      var layoutGroup = layoutInfo.groups[defaultLayout];
      var prevFormsLayouts = resolveFormLayouts(book.forms);
      var formNameMode = layoutInfo.formNameMode;
      var bookSrcId = book.sourceId;
      pages.forEach( function (pagesPerNumber) {
        pagesPerNumber.forEach( function (page) {
          if (isVirtual(page)){
            return;
          }          
          var role = matchRole(page.numberInSection-1,pages.length, layoutGroup.layoutLinks, [[page]]);
          var imposition = layoutInfo.impositions[role.imposition];
          var layoutType = role.role;
          //var displayName = page.title;
          var displayName = getFormdisplayNameNew([page], formNameMode)
          var uniqueName =  getFormUniqueNameNew([page]) ;

          var formSourceId = (bookSrcId + "/" + uniqueName).toLowerCase();

          var form = {parent:bookSrcId, layoutType:'broadsheet', displayName:displayName, sourceId:formSourceId};
          if (prevFormsLayouts.hasOwnProperty(formSourceId)){
            var prevRole = layoutInfo.impositions[prevFormsLayouts[form.sourceId]].role;
            if (page.panorama && prevRole == "general"){
              delete prevFormsLayouts[formSourceId];
            } else if (!page.panorama && prevRole == "panorama"){
              delete prevFormsLayouts[formSourceId];
            }            
          }

          form.layoutNwId = getPrevImposition(prevFormsLayouts, formSourceId, [page], layoutInfo) ? prevFormsLayouts[form.sourceId] : imposition.nwid;
         
          //form.layoutNwId = prevFormsLayouts.hasOwnProperty(form.sourceId) ? prevFormsLayouts[form.sourceId] : imposition.nwid;
          form.layout = imposition.name;
          form.layoutGroup = defaultLayout;
          form.minNumberInBook = page.numberInBook;
          form.pages = [page.sourceId];
          updateFormSeparations(form, [page]);
          forms.push(form);
        });
      });
      return forms;
    }

    function createBroadsheetForms(layoutInfo, book, pages){
      var pmtMode = layoutInfo.pmtMode;
      if (!pmtMode){
        return createBroadsheetFormsNew(layoutInfo, book, pages);
      }
      var forms = [];
      var bookSrcId = book.sourceId;
      pages.forEach( function (pagesPerNumber) {
        pagesPerNumber.forEach( function (page) {
          if (isVirtual(page)){
            return;
          }
          var displayName = page.title;
          var uniqueName =  displayName + page.paginationInZone;
          var form = {parent:bookSrcId, layoutType:'broadsheet', displayName:displayName, sourceId:bookSrcId + uniqueName};
          form.layout = book.defaultLayout;
          form.pages = [page.sourceId];
          updateFormSeparations(form, [page]);
          forms.push(form);
        });
      });
      return forms;
    }

    function getFormdisplayName(formNameMode, low, high, high1, low1){      
      if (formNameMode === "number")
        return getMin([low, high]);
      if (typeof high ===  'undefined' || isVirtual(high)){
        return low.title;
      } else if (typeof low ===  'undefined' || isVirtual(low)){
        return high.title;
      }
      if (high1 === null || typeof high1 == 'undefined')
        return low.title + "-" + high.title;

      return low.title + "-" + high.title + "-" +  high1.title + "-" + low1.title;
    }

    function getFormdisplayNameNew(items, formNameMode){      
      if (formNameMode === "number")
        return getMin(items);
      if (formNameMode === "numberInZone")
        return getMinInZone(items);
      var ret = "";
      for (var i=0;i<items.length;i++){
        if (typeof items[i] ===  'undefined' || isVirtual(items[i])){
          continue;
        } else if (typeof items[i].displayName ===  'undefined' || isVirtual(items[i])){
          continue;
        }
        var title = (formNameMode === "pagesPhysicalNumber" ? items[i].numberInZone : items[i].title);
        if (items[i].fakePanorama && formNameMode !== "pagesPhysicalNumber"){
          title = title.substring(0, title.length - items[i].virtualTitle.length - 1)          
        }
        ret += title + "-";
      }
      return trimLastDash(ret);
    }

    function getMinInZone(pages) {
      var min = 99999;
      for (var i = 0; i < pages.length; i++) {
        if (!pages[i]) {
          continue;
        }
        var n = pages[i].numberInZone;
        if (n < min) {
          min = n;
        }
      }
      return min;
    }

    function getMin(pages){
      var min = 99999;
      for (var i=0;i<pages.length;i++){
        if (!pages[i]){
          continue;
        }
        var n = pages[i].numberInBook;
        if (n < min){
          min = n;
        }
      }
      return min;
    }

    function getMax(pages){
      var max = -1;
      for (var i=0;i<pages.length;i++){
        if (!pages[i]){
          continue;
        }
        var n = pages[i].numberInBook;
        if (n > max){
          max = n;
        }
      }
      return max;
    }

    function getFormUniqueName(low, high, high1, low1){
      if (typeof high ===  'undefined' || isVirtual(high)){
        var lowSplit = typeof low.split !== 'undefined' && low.split !== null ? low.split : "";
        return low.paginationInZone + lowSplit;
      } else if (typeof low ===  'undefined' || isVirtual(low)){
        var highSplit = typeof high.split !== 'undefined' && high.split !== null ? high.split : "";
        return high.paginationInZone + highSplit;
      }
      var lowSplit = typeof low.split !== 'undefined' && low.split !== null ? low.split : "";
      var highSplit = typeof low.split !== 'undefined' && low.split !== null ? high.split : "";
      if (high1 === null || typeof high1 == 'undefined')
        return low.paginationInZone + lowSplit + "-" + high.paginationInZone + highSplit;
      return low.paginationInZone + lowSplit + "-" + high.paginationInZone + highSplit + "-" +
        high1.paginationInZone + lowSplit + "-" + low1.paginationInZone + highSplit;
    }

    function getFormUniqueNameNew(items){
      var ret = "";
      for (var i=0;i<items.length;i++){
        if (typeof items[i] ===  'undefined' || Object.keys(items[i]).length === 0 || isVirtual(items[i])){
          continue;
        }
        var split = typeof items[i].split !== 'undefined' && items[i].split !== null ? items[i].split : "";
        var insert = typeof items[i].insertChar !== 'undefined' && items[i].insertChar !== null ? items[i].insertChar : "";
        ret += items[i].numberInBook + insert + split + "-";
      }
      return trimLastDash(ret.toLowerCase());
    }

    function getFormPages(low, high, high1, low1){
      if (typeof high !==  'undefined' && isVirtual(high)){
        return [low];
      } else if (typeof low !==  'undefined' && isVirtual(low)){
        return [high];
      } else if (typeof high ===  'undefined'){
        return [low, {}];
      } else if (typeof low ===  'undefined') {
        return [{}, high];
      }
      return [low, high];
    }

    function getFormPagesNew(items){
      var ret = [];
      for (var i=0;i<items.length;i++){
        if (typeof items[i] !==  'undefined' && isVirtual(items[i])){
          continue;
        } else if (typeof items[i] ===  'undefined'){
          ret.push({});
        } else {
          ret.push(items[i]);
        }
      }
      return ret;
    }

    function getFormPagessourceId(low, high, high1, low1){
      if (typeof high !==  'undefined' && isVirtual(high)){
        return [low.sourceId];
      } else if (typeof low !==  'undefined' && isVirtual(low)){
        return [high.sourceId];
      } else if (typeof high ===  'undefined'){
        return [low.sourceId, {sourceId:"$empty$"}];
      } else if (typeof low ===  'undefined') {
        return [{sourceId:"$empty$"}, high.sourceId];
      }
      if (high1 === null || typeof high1 == 'undefined')
        return [low.sourceId, high.sourceId];

      return [low.sourceId, high.sourceId, high1.sourceId, low1.sourceId];
    }

    function getFormPagessourceIdNew(items){
      var ret = [];
      for (var i=0;i<items.length;i++){
        if (typeof items[i] !==  'undefined' && isVirtual(items[i])){
          continue;
        } else if (typeof items[i] ===  'undefined'){
          ret.push({});
        } else {
          ret.push(items[i].sourceId);
        }
      }
      return ret;
    }

    function createTabsForms(layoutInfo, book, pairs, updateSeps){
      return createPairForms(layoutInfo, book, pairs, updateSeps, "tabs");
    }

    function createTowUpsForms(layoutInfo, book, pairs, updateSeps){
      return createPairForms(layoutInfo, book, pairs, updateSeps, "2ups");
    }

    function createPmiForms(layoutInfo, book, pages, updateSeps){

      var defaultLayout = book.defaultLayout;
      var layoutGroup = layoutInfo.groups[defaultLayout];
      var prevFormsLayouts = resolveFormLayouts(book.forms);
      var pmiForms = book.pmiForms;
      delete book.pmiForms;

      var formNameMode = layoutInfo.formNameMode;
      var forms = [];
      var bookSrcId = book.sourceId;
      var map = [];
      for (var i=0;i<=pages.length-1;i++) {
        map["" + pages[i][0].numberInBook] = pages[i];
        //console.log ("---" + "" + pages[i][0].numberInBook);
      }

      var pmiTemplate = book.pmiTemplateDescription;
      //var numSheets = parseInt(pmiTemplate.sheets, 10);
      var sheets = pmiTemplate.sheets;
      var numSheets = sheets.length;
      for (var i=0;i<sheets.length;i++){
        var sheet = sheets[i];
        var rows = parseInt (sheet.rows, 10);
        var columns = parseInt (sheet.columns, 10);
        var index = parseInt (sheet.number, 10);
        var pagesOnSheet = sheet.pages;
        var maxNumber = resloveMaxNumber(pagesOnSheet, map);



        for (var j=0;j<maxNumber;j++){
          var pagesOnForm = [], unSortedPages = [];
          for (var k=0;k<sheet.pages.length;k++){
            var pageOnSheet = sheet.pages[k];
            var number = pageOnSheet.number;
            var row = parseInt(pageOnSheet.row, 10);
            var column = parseInt(pageOnSheet.column, 10);
            var flowOrder = (row-1)*columns + column;
            var pagesList = map["" + number];
            if (pagesList.length < maxNumber){
              pagesList[j] = pagesList[0];
            }
            pagesOnForm[flowOrder-1] = pagesList[j];
            unSortedPages.push(pagesList[j]);

          }
          unSortedPages.sort(function(a, b){return a.numberInBook - b.numberInBook});
          var displayName = getFormdisplayNameNew(unSortedPages, formNameMode);
          var uniqueName =  i + "_" + getFormUniqueNameNew(unSortedPages);
          
          var prevImpositionNwId = prevFormsLayouts.hasOwnProperty(bookSrcId + "/" + uniqueName) ? prevFormsLayouts[bookSrcId + "/" + uniqueName] : "0";
          if (prevImpositionNwId == "0" && pmiForms.length > i){
            prevImpositionNwId = pmiForms[i] || "0";
          }
          var imposition = null, tempImposition = null;
          var optionalImpositions = [];
          for (var l=0;l<layoutGroup.layoutLinks.length;l++){
            var impositionNwId = layoutGroup.layoutLinks[l].nwid;
            var impositionToTest = layoutInfo.impositions[impositionNwId];
            if (!impositionToTest){
              continue;
            }
            var x = impositionToTest.cellGrid.columns;
            var y = impositionToTest.cellGrid.rows;
            if (x == columns && rows == y){
              tempImposition = impositionToTest;
              if (imposition === null){
                // imposition = impositionToTest;
                optionalImpositions.push(impositionToTest);
              }
              if (prevImpositionNwId === tempImposition.nwid){
                imposition = tempImposition;
                break;
              }
            }
          }
          if (imposition == null && optionalImpositions.length > 0){
            var role = matchRole(index-1, numSheets, optionalImpositions, pagesOnForm);
            if (role.role === "even" || role.role === "odd"){
              for (var imp=0;imp<optionalImpositions.length;imp++){
                if (optionalImpositions[imp].role === role.role){
                  imposition = optionalImpositions[imp];
                }
              }
            }
            if (imposition === null){
              imposition = optionalImpositions[0];
            }
          }
          
          //var role = matchRole(index, numSheets, layoutGroup.layoutLinks, pagesOnForm);
          //var imposition = layoutInfo.impositions[role.imposition];

          var form = {parent:bookSrcId, layoutType:"pmi", displayName:displayName, sourceId:bookSrcId + "/" + uniqueName};
          if (imposition !== null){
            form.layoutNwId = imposition.nwid;
            form.layout = imposition.name;
          } else {
            sandbox.dialog.alert ("Layout group's impositions mismatch form structure");
            return [];
          }

          form.rows = rows;
          form.columns = columns;
          var formPages = pagesOnForm;//highPages[f].virtual ? [lowPages[f]] : [lowPages[f], highPages[f]];
          form.layoutGroup = defaultLayout;
          
          var pagesOnFormsWithIndexes = reorderPagesOnForms(pagesOnForm);
          pagesOnForm = pagesOnFormsWithIndexes.filter(function(n){ return n !== undefined });
          pagesOnForm = pagesOnForm.filter(function(n){ return !isVirtual(n)});
          
          
          form.pages = getFormPagessourceIdNew(pagesOnForm);//highPages[f].virtual ? [lowPages[f].sourceId] : [lowPages[f].sourceId, highPages[f].sourceId];
          // var pagesOnFormsWithIndexes = reorderPagesOnForms(pagesOnForm);//tzvika
          form.indexes = buildIndexes(pagesOnFormsWithIndexes);
          if (updateSeps)
            updateFormSeparations(form, formPages);
          forms.push(form);




        }

      }

      return forms;
    }

    function resloveMaxNumber(pagesOnSheet, map){
      var max = -1;
      for (var i=0;i<pagesOnSheet.length;i++){
        var number = pagesOnSheet[i].number + "";
        if (map[number].length > -1){
          max = map[number].length;
        }
      }
      return max;
    }

    function buildIndexes(pages){
      var indexes = Array(pages.length), virtualIndexes = [], panoramaIndexes = [];
      for (var i=0,k=0;i<pages.length;i++){
        var page = pages[i];
        if (typeof page ===  'undefined'){
          k++;
          continue;
        } if (isVirtual(page)) {
          continue;
        } else if (isPanorama(page)){
          panoramaIndexes.push(i);
          for (var j=0;j<pages.length;j++){
            if (typeof pages[j] ===  'undefined'){
              continue;
            }
            if (pages[j].sourceId === page.virtualPage){
              const exists = virtualIndexes.some((element) => element === j);
              if (!exists){
                virtualIndexes.push(j);
              }
              
            }
          }
        }
      }
      for (var i=0;i<pages.length;i++){
        var page = pages[i];
        if (typeof page ===  'undefined' || isVirtual(page)) {
          continue;
        } else if (isPanorama(page)){
          // var panoIndexs = [];
          const pIndex = panoramaIndexes.findIndex((element) => element === i);
          const vIndex = virtualIndexes[pIndex];
          const panoIndexs = vIndex > i ? [i, vIndex] : [vIndex, i];
          // const myIndex = vIndex > i ? i : vIndex;
          // var virtualPage = null;
          // var myIndex = i;
          // for (var j=0;j<pages.length;j++){
          //   if (typeof pages[j] ===  'undefined'){
          //     continue;
          //   }
          //   if (pages[j].sourceId === page.virtualPage){
          //     virtualIndexes.push(j);             
          //   }            
          // }
          // console.log (virtualIndexes);
          // for (var j=0;j<pages.length;j++){
          //   if (typeof pages[j] ===  'undefined'){
          //     continue;
          //   }
          //   if (pages[j].sourceId === page.virtualPage){              
          //     panoIndexs = j > i ? [i, j] : [j, i];
          //     myIndex = j > i ? i : j;
          //     break;
          //   }        
          // }
          
          // // indexes.push(panoIndexs);
          indexes[k] = panoIndexs;    
          k++;      
        } else {
          indexes[k] = [i];
          k++;
          // indexes.push([i]);
        }
      }
      
      return indexes;
    }


    function createEightUpsFormsNew (layoutInfo, book, pairs, updateSeps) {


      var bookSrcId = book.sourceId;
      var formType = "8ups";
      var formNameMode = layoutInfo.formNameMode;
      var defaultLayout = book.defaultLayout;
      var layoutGroup = layoutInfo.groups[defaultLayout];
      var prevFormsLayouts = resolveFormLayouts(book.forms);


      var forms = [];


      for (var i=0; i<pairs.length; i++){
        var keepLocation = false;
        var platePages = pairs[i];
        var minNumber = -1;
        var minNumberInSection = -1;
        for (var p=0;p<platePages.length ;p++ ){
          if ( typeof platePages[p] !== 'undefined' && platePages[p] !== null){
            minNumber = platePages[p][0].paginationInZone;
            minNumberInSection = platePages[p][0].numberInSection;
            break;
          }
        }

        //for (var p=0;p<platePages.length ;p++ ) {
        //  if (typeof platePages[p] === 'undefined' || platePages[p] === null) {
        //    if (minNumber % 2 === 0){
        //      minNumber--;
        //      minNumberInSection--;
        //    }
        //  }
        //}
        var maxLength = 0;
        platePages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          maxLength = list.length > maxLength ? list.length : maxLength;
        });
        platePages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          if (list.length < maxLength) {
            list[1] = list[0];
          }
        });


        for (var counter = 0; counter < maxLength; counter++) {


          var unSortedPages = [];
          for (p=0;p<platePages.length ;p++ ){
            if ( typeof platePages[p] === 'undefined' || platePages[p] === null){
              unSortedPages[p] = undefined;
            } else {
              unSortedPages[p] = platePages[p][counter];
            }
            //var displayName = platePages[p] !== null && typeof pdelatePages[p][counter] !== 'undefined' ? platePages[p][counter].displayName : "null";
          }
          var displayName = getFormdisplayNameNew(unSortedPages, formNameMode);// highPages[f].virtual ? lowPages[f].title : lowPages[f].title + "-" + highPages[f].title;
          var uniqueName =  getFormUniqueNameNew(unSortedPages);// displayName + lowPages[f].numberInZone + "-" + highPages[f].numberInZone;
          var formSourceId = bookSrcId + "/" + uniqueName;

          var locations = [], locationsForIndexes = [], locationsEven = [];

          var role = matchRole(minNumberInSection-1,pairs.length, layoutGroup.layoutLinks, platePages);
          var impositionNwId = prevFormsLayouts.hasOwnProperty(formSourceId) ? prevFormsLayouts[formSourceId] : role.imposition;
          var imposition = layoutInfo.impositions[impositionNwId];
          var layoutType = findImpositionRole(layoutGroup.layoutLinks, impositionNwId);
          //var imposition = layoutInfo.impositions[role.imposition];
          //var layoutType = role.role;
          for (var index=0;index<imposition.cellGrid.cells.length;index++ ) {
            var frontFlowOrder = parseInt(imposition.cellGrid.cells[index].flowOrder, 10) - 1;
            frontFlowOrder = frontFlowOrder < 0 ? 0 : frontFlowOrder;
            //locations[frontFlowOrder] = get8upsMatrixPosition(pmtDescription.pages[i]);
            locationsForIndexes[frontFlowOrder] = getMatrixPositionNew(imposition.cellGrid.cells[index]);
            if (imposition.cellGrid.cells[index].isSkipped){
              continue;
            }
            locations[frontFlowOrder] = get8upsMatrixPositionNew(imposition.cellGrid.cells[index], imposition.cellGrid.columns);
            locationsEven[frontFlowOrder] = getEvenPlatePage(imposition.cellGrid.cells[index], imposition.cellGrid.cells, imposition.cellGrid.columns);
          }
          var isEvenOrOdd = (layoutType === "even" || layoutType === "odd");
          locationsEven = isEvenOrOdd ? locations : locationsEven;
          //var locationsEven = matchLocations(locations);
          var locationsForIndexesEven = matchLocations(locationsForIndexes);
          var locationsToUse, locationsForIndexesToUse;
          if (keepLocation) {
            keepLocation = false;
            locationsToUse = (minNumber % 2 === 0) ? locations : locationsEven;
            locationsForIndexesToUse =  (minNumber % 2 === 0) ? locationsForIndexes : locationsForIndexesEven;
          } else {
            locationsToUse = (minNumber % 2 === 0) ? locationsEven : locations;
            locationsForIndexesToUse =  (minNumber % 2 === 0) ? locationsForIndexesEven : locationsForIndexes;
          }

          var pagesOnForms = [], pagesOnFormsWithIndexes = [];

          for (p=0;p<platePages.length ;p++ ){
            if ( typeof platePages[p] === 'undefined' || platePages[p] === null){
              pagesOnForms[locationsToUse[p]] = 'undefined';
              pagesOnFormsWithIndexes[locationsForIndexesToUse[p]] = undefined;
            } else {
              pagesOnForms[locationsToUse[p]] = platePages[p][counter];
              pagesOnFormsWithIndexes[locationsForIndexesToUse[p]] = platePages[p][counter];
            }
            let pageDisplayName = platePages[p] !== null && typeof platePages[p][counter] !== 'undefined' ? platePages[p][counter].displayName : "null";
          }


          var form = {parent:bookSrcId, layoutType:formType, displayName:displayName, sourceId:formSourceId};
          form.layoutNwId = prevFormsLayouts.hasOwnProperty(form.sourceId) ? prevFormsLayouts[form.sourceId] : imposition.nwid;
          form.layout = imposition.name;
          var formPages = pagesOnForms;//highPages[f].virtual ? [lowPages[f]] : [lowPages[f], highPages[f]];
          form.layoutGroup = defaultLayout;
          form.minNumberInBook= getMin(pagesOnForms);
          form.pages = getFormPagessourceIdNew(pagesOnForms);//highPages[f].virtual ? [lowPages[f].sourceId] : [lowPages[f].sourceId, highPages[f].sourceId];
          form.indexes = buildIndexes(pagesOnFormsWithIndexes);
          if (updateSeps)
            updateFormSeparations(form, formPages);
          forms.push(form);

        }


      }

      return forms;
    }

    function createEightUpsForms (layoutInfo, book, pairs, updateSeps) {

      var pmtMode = layoutInfo.pmtMode;
      var formNameMode = layoutInfo.formNameMode;
      if (!pmtMode){
        return createEightUpsFormsNew(layoutInfo, book, pairs, updateSeps);
      }
      var bookSrcId = book.sourceId;
      var formType = "8ups";

      var defaultLayout = book.defaultLayout;
      var pmtDescription = pmtDescriptionInfo[defaultLayout];
      var locations = [];
      var forms = [];
      for (var i=0;i<pmtDescription.pages.length;i++ ) {
        var frontFlowOrder = parseInt(pmtDescription.pages[i].frontFlowOrder, 10) - 1;
        frontFlowOrder = frontFlowOrder < 0 ? 0 : frontFlowOrder;
        locations[frontFlowOrder] = get8upsMatrixPosition(pmtDescription.pages[i]);
      }
      var locationsEven = matchLocations(locations);

      for (var i=0; i<pairs.length; i++){
        var keepLocation = false;
        var platePages = pairs[i];
        var minNumber = -1;
        for (var p=0;p<platePages.length ;p++ ){
          if ( typeof platePages[p] !== 'undefined' && platePages[p] !== null){
            minNumber = platePages[p][0].paginationInZone;
            break;
          }
        }

        for (var p=0;p<platePages.length ;p++ ) {
          if (typeof platePages[p] === 'undefined' || platePages[p] === null) {
            if (minNumber % 2 === 0){
              minNumber--;
            }
          }
        }

        var locationsToUse;
        if (keepLocation) {
          keepLocation = false;
          locationsToUse = (minNumber % 2 === 0) ? locations : locationsEven;
        } else {
          locationsToUse = (minNumber % 2 === 0) ? locationsEven : locations;
        }

        var maxLength = 0;
        platePages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          maxLength = list.length > maxLength ? list.length : maxLength;
        });
        platePages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          if (list.length < maxLength) {
            list[1] = list[0];
          }
        });


        for (var counter = 0; counter < maxLength; counter++) {
          var pagesOnForms = [];

          for (var p=0;p<platePages.length ;p++ ){
            if ( typeof platePages[p] === 'undefined' || platePages[p] === null){
              pagesOnForms[locationsToUse[p]] = 'undefined';
            } else {
              pagesOnForms[locationsToUse[p]] = platePages[p][counter];
            }
            var displayName = platePages[p] !== null && typeof platePages[p][counter] !== 'undefined' ? platePages[p][counter].displayName : "null";
          }

          var displayName = getFormdisplayNameNew(pagesOnForms, formNameMode);// highPages[f].virtual ? lowPages[f].title : lowPages[f].title + "-" + highPages[f].title;
          var uniqueName =  getFormUniqueNameNew(pagesOnForms);// displayName + lowPages[f].numberInZone + "-" + highPages[f].numberInZone;
          var form = {parent:bookSrcId, layoutType:formType, displayName:displayName, sourceId:bookSrcId + uniqueName};
          form.layout = book.defaultLayout;
          var formPages = pagesOnForms;//highPages[f].virtual ? [lowPages[f]] : [lowPages[f], highPages[f]];
          form.pages = getFormPagessourceIdNew(pagesOnForms);//highPages[f].virtual ? [lowPages[f].sourceId] : [lowPages[f].sourceId, highPages[f].sourceId];
          form.indexes = buildIndexes(pagesOnForms);
          if (updateSeps)
            updateFormSeparations(form, formPages);
          forms.push(form);

        }


      }

      return forms;
    }

    function createFourUpsFormsNew(layoutInfo, book, pairs, updateSeps){
      var bookSrcId = book.sourceId;
      var formType = "4ups";
      var fourUps = getPairsTabs(pairs);
      var defaultLayout = book.defaultLayout;
      var formNameMode = layoutInfo.formNameMode;
      var layoutGroup = layoutInfo.groups[defaultLayout];

      var prevFormsLayouts = resolveFormLayouts(book.forms);

      //var matrix =
      var forms = [];
      var inDinkyMode = false, keepLocation = false;
      for (var i=0;i<fourUps.length;i++) {



        var lowPair = fourUps[i][0];
        var highPair = fourUps[i][1];
        if (inDinkyMode){
          keepLocation = true;
        }
        if (lowPair.length === 0){
          lowPair = [[{}], [{}]];
          inDinkyMode = !inDinkyMode;
        } else if (highPair.length === 0){
          highPair = [[{}], [{}]];
          inDinkyMode = !inDinkyMode;
        }
        var pages = [lowPair[0], highPair[0], highPair[1], lowPair[1]];
        var maxLength = 0;
        pages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          maxLength = list.length > maxLength ? list.length : maxLength;
        });
        pages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          if (list.length < maxLength) {
            list[1] = list[0];
          }
        });

        var minNumber = -1;

        for (var counter = 0; counter < maxLength; counter++) {
          for (var p=0;p<pages.length ;p++ ){
            if ( typeof pages[p] !== 'undefined' && pages[p] !== null && !isNaN(pages[p][counter].paginationInZone)){
              minNumber = pages[p][counter].paginationInZone;
              break;
            }
          }
          break;
        }


        for (var counter = 0; counter < maxLength; counter++) {

          var unSortedPages = [];
          for (var p=0;p<pages.length ;p++ ){
            if ( typeof pages[p] === 'undefined' || pages[p] === null){
              unSortedPages[p] = undefined;
            } else {
              unSortedPages[p] = pages[p][counter];
            }
          }

          var displayName = getFormdisplayNameNew(unSortedPages, formNameMode);// highPages[f].virtual ? lowPages[f].title : lowPages[f].title + "-" + highPages[f].title;
          var uniqueName =  getFormUniqueNameNew(unSortedPages);// displayName + lowPages[f].numberInZone + "-" + highPages[f].numberInZone;
          var formSourceId = bookSrcId + "/" + uniqueName;

          var locations = [], locationsForIndexes = [];
          var role = matchRole(i,fourUps.length, layoutGroup.layoutLinks, pages);
          var impositionNwId = prevFormsLayouts.hasOwnProperty(formSourceId) ? prevFormsLayouts[formSourceId] : role.imposition;
          var imposition = layoutInfo.impositions[impositionNwId];
          //var imposition = layoutInfo.impositions[role.imposition];
          //var layoutType = role.role;
          var layoutType = findImpositionRole(layoutGroup.layoutLinks, impositionNwId);
          for (var index=0;index<imposition.cellGrid.cells.length;index++ ) {
            var frontFlowOrder = parseInt(imposition.cellGrid.cells[index].flowOrder, 10) - 1;
            frontFlowOrder = frontFlowOrder < 0 ? 0 : frontFlowOrder;
            locationsForIndexes[frontFlowOrder] = getMatrixPositionNew(imposition.cellGrid.cells[index]);
            if (imposition.cellGrid.cells[index].isSkipped){
              continue;
            }
            locations[frontFlowOrder] = getMatrixPositionNew(imposition.cellGrid.cells[index]);
          }
          var isPanorama = isPanoramaForm(pages);
          var isEvenOrOdd = (layoutType === "even" || layoutType === "odd");
          var locationsEven = isEvenOrOdd ? locations : matchLocations(locations);
          var locationsForIndexesEven = isEvenOrOdd ? locationsForIndexes : matchLocations(locationsForIndexes);
          var locationsToUse, locationsForIndexesToUse;
          if (keepLocation) {
            keepLocation = false;
            locationsToUse = locationsToUse;//(minNumber % 2 === 0) ? locations : locationsEven;
            locationsForIndexesToUse =  locationsForIndexesToUse;
          } else {
            locationsToUse = (minNumber % 2 === 0) ? locationsEven : locations;
            locationsForIndexesToUse = (minNumber % 2 === 0) ? locationsForIndexesEven : locationsForIndexes;
          }


          var pagesOnForms = [], pagesOnFormsWithIndexes = [];
          for (p=0;p<pages.length ;p++ ){
            if ( typeof pages[p] === 'undefined' || pages[p] === null){
              pagesOnForms[locationsToUse[p]] = undefined;
              pagesOnFormsWithIndexes[locationsForIndexesToUse[p]] = undefined;
            } else {
              pagesOnForms[locationsToUse[p]] = pages[p][counter];
              pagesOnFormsWithIndexes[locationsForIndexesToUse[p]] = pages[p][counter];
            }

          }
          var pagesOnFormsWithIndexes = reorderPagesOnForms(pagesOnFormsWithIndexes);
          pagesOnForms = pagesOnFormsWithIndexes.filter(function(n){ return n !== undefined });
          pagesOnForms = pagesOnForms.filter(function(n){ return !isVirtual(n)});
          //var displayName = getFormdisplayNameNew(pagesOnForms);// highPages[f].virtual ? lowPages[f].title : lowPages[f].title + "-" + highPages[f].title;
          //var uniqueName =  getFormUniqueNameNew(pagesOnForms);// displayName + lowPages[f].numberInZone + "-" + highPages[f].numberInZone;
          var form = {parent:bookSrcId, layoutType:formType, displayName:displayName, sourceId:formSourceId};
          form.layoutNwId = prevFormsLayouts.hasOwnProperty(form.sourceId) ? prevFormsLayouts[form.sourceId] : imposition.nwid;
          form.layout = imposition.name;
          form.layoutGroup = defaultLayout;
          form.minNumberInBook = getMin(pagesOnForms);
          var formPages = pagesOnForms;//highPages[f].virtual ? [lowPages[f]] : [lowPages[f], highPages[f]];
          form.pages = getFormPagessourceIdNew(pagesOnForms);//highPages[f].virtual ? [lowPages[f].sourceId] : [lowPages[f].sourceId, highPages[f].sourceId];
          form.indexes = buildIndexes(pagesOnFormsWithIndexes);
          form.stam = "1";
          if (updateSeps)
            updateFormSeparations(form, formPages);
          forms.push(form);
        }

      }
      return forms;
    }

    function reorderPagesOnForms(pages){
      var replaced = [];
      for (var i=0;i<pages.length;i++){
        var page = pages[i];
        if (page && page.sourceId && isVirtual(page)){
          for (var j=i;j<pages.length;j++){
            var panorama = pages[j];
            if (panorama && panorama.sourceId && panorama.virtualPage == page.sourceId){
              replaced.push([i,j]);
              break;
            }
          }
        }
      }

      for (var k=0;k<replaced.length;k++){
        var indexes = replaced[k];
        var temp = pages[indexes[0]];
        pages[indexes[0]] = pages[indexes[1]];
        pages[indexes[1]] = temp;
      }
      return pages;
    }

    function createFourUpsForms(layoutInfo, book, pairs, updateSeps){
      var formNameMode = layoutInfo.formNameMode;
      var bookSrcId = book.sourceId;
      var formType = "4ups";
      var fourUps = getPairsTabs(pairs);
      var defaultLayout = book.defaultLayout;

      var pmtMode = layoutInfo.pmtMode;
      if (!pmtMode){
        return createFourUpsFormsNew(layoutInfo, book, pairs, updateSeps);
      }

      var pmtDescription = layoutInfo.pmtDescription[defaultLayout];


      var locations = [];
      for (var i=0;i<pmtDescription.pages.length;i++ ) {
        var frontFlowOrder = parseInt(pmtDescription.pages[i].frontFlowOrder, 10) - 1
        frontFlowOrder = frontFlowOrder < 0 ? 0 : frontFlowOrder;
        locations[frontFlowOrder] = getMatrixPosition(pmtDescription.pages[i]);
      }
      var locationsEven = matchLocations(locations);
      //var matrix =
      var forms = [];
      var inDinkyMode = false, keepLocation = false;
      for (var i=0;i<fourUps.length;i++) {
        var lowPair = fourUps[i][0];
        var highPair = fourUps[i][1];
        if (inDinkyMode){
          keepLocation = true;
        }
        if (lowPair.length === 0){
          lowPair = [[{}], [{}]];
          inDinkyMode = !inDinkyMode;
        } else if (highPair.length === 0){
          highPair = [[{}], [{}]];
          inDinkyMode = !inDinkyMode;
        }
        var pages = [lowPair[0], highPair[0], highPair[1], lowPair[1]];
        var maxLength = 0;
        pages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          maxLength = list.length > maxLength ? list.length : maxLength;
        });
        pages.forEach(function (list) {
          if ( typeof list === 'undefined' || list === null){
            return;
          }
          if (list.length < maxLength) {
            list[1] = list[0];
          }
        });

        var minNumber = -1;
        for (var counter = 0; counter < maxLength; counter++) {
          for (var p=0;p<pages.length ;p++ ){
            if ( typeof pages[p] !== 'undefined' && pages[p] !== null && !isNaN(pages[p][counter].paginationInZone)){
              minNumber = pages[p][counter].paginationInZone;
              break;
            }
          }
          break;
        }
        var locationsToUse;
        if (keepLocation) {
          keepLocation = false;
          locationsToUse = (minNumber % 2 === 0) ? locations : locationsEven;
        } else {
          locationsToUse = (minNumber % 2 === 0) ? locationsEven : locations;
        }

        for (var counter = 0; counter < maxLength; counter++) {
          var pagesOnForms = [];
          for (var p=0;p<pages.length ;p++ ){
            if ( typeof pages[p] === 'undefined' || pages[p] === null){
              pagesOnForms[locationsToUse[p]] = undefined;
            } else {
              pagesOnForms[locationsToUse[p]] = pages[p][counter];
            }

          }

          var displayName = getFormdisplayNameNew(pagesOnForms, formNameMode);// highPages[f].virtual ? lowPages[f].title : lowPages[f].title + "-" + highPages[f].title;
          var uniqueName =  getFormUniqueNameNew(pagesOnForms);// displayName + lowPages[f].numberInZone + "-" + highPages[f].numberInZone;
          var form = {parent:bookSrcId, layoutType:formType, displayName:displayName, sourceId:bookSrcId + uniqueName};
          form.layout = book.defaultLayout;
          var formPages = pagesOnForms;//highPages[f].virtual ? [lowPages[f]] : [lowPages[f], highPages[f]];
          form.pages = getFormPagessourceIdNew(pagesOnForms);//highPages[f].virtual ? [lowPages[f].sourceId] : [lowPages[f].sourceId, highPages[f].sourceId];
          form.indexes = buildIndexes(pagesOnForms);
          if (updateSeps)
            updateFormSeparations(form, formPages);
          forms.push(form);
        }

      }
      return forms;
    }
	
	function getPrevImposition(prevFormsLayouts, formSourceId, allPages, layoutInfo = {impositions:[]}){
      if (!prevFormsLayouts.hasOwnProperty(formSourceId)){
        return null;
      }
      var panoChanged = false;
      for (var i=0, j=0;i<allPages.length;i++){
        if (!allPages[i]){
          continue;
        }
        if (allPages[i].panoramaChanged){
          panoChanged = true;
          allPages[i].panoramaChanged = false;          
        }
      }

      if (!panoChanged){
        return prevFormsLayouts[formSourceId];
      } else {
        var imposition = layoutInfo.impositions[prevFormsLayouts[formSourceId]];
        if (imposition && imposition.role && imposition.role == "custom"){
          return prevFormsLayouts[formSourceId];
        }
      }
      return null;

    }
	
    function createPairFormsNew(layoutInfo, book, pairs, updateSeps, formType){
      var formNameMode = layoutInfo.formNameMode;
      var forms = [];
      var bookSrcId = book.sourceId;

      var defaultLayout = book.defaultLayout;
      var layoutGroup = layoutInfo.groups[defaultLayout];
      var prevFormsLayouts = resolveFormLayouts(book.forms);

      for (var i=0, j=0;i<pairs.length;i++){

        var lowPages = pairs[i][0];
        var highPages = pairs[i][1];
        


        //var pages = lowPages;
        if (typeof lowPages !== 'undefined' && typeof highPages !== 'undefined'){
          if (lowPages.length > highPages.length)
          {
            highPages[1] = highPages[0];
          } else if (lowPages.length < highPages.length){
            lowPages[1] = lowPages[0];
          }
        } else if (typeof lowPages === 'undefined'){
          lowPages = [];
          lowPages[highPages.length-1] = undefined;
        } else if (typeof highPages === 'undefined') {
          highPages = [];
          highPages[lowPages.length-1] = undefined;
        }

        var pagesForLoop = lowPages;

        for (var f=0;f< pagesForLoop.length;f++ ) {
          var allPages = [lowPages[f], highPages[f]];
          j++;
          var displayName = getFormdisplayNameNew(allPages, formNameMode);// highPages[f].virtual ? lowPages[f].title : lowPages[f].title + "-" + highPages[f].title;
          var uniqueName =  getFormUniqueNameNew(allPages);// displayName + lowPages[f].numberInZone + "-" + highPages[f].numberInZone;
          var formSourceId = bookSrcId + "/" + uniqueName;

          var role = matchRole(i,pairs.length, layoutGroup.layoutLinks, allPages);
          var impositionNwId = prevFormsLayouts.hasOwnProperty(formSourceId) ? prevFormsLayouts[formSourceId] : role.imposition;
          var imposition = layoutInfo.impositions[impositionNwId];
          var layoutType = findImpositionRole(layoutGroup.layoutLinks, impositionNwId);

          var locations = [];
          for (var index=0;index<imposition.cellGrid.cells.length;index++ ) {
            if (imposition.cellGrid.cells[index].isSkipped){
              continue;
            }
            var flowOrderValue = imposition.cellGrid.cells[index].flowOrder;
            if (isNaN(flowOrderValue)){
              flowOrderValue = index + 1;
            }

            var frontFlowOrder = parseInt(flowOrderValue, 10) - 1;
            frontFlowOrder = frontFlowOrder < 0 ? 0 : frontFlowOrder;
            //locations[frontFlowOrder] = get8upsMatrixPosition(pmtDescription.pages[i]);
            locations[frontFlowOrder] = getPairPosition(imposition.cellGrid.cells[index]);

          }

          var isEvenOrOdd = (layoutType === "even" || layoutType === "odd" || layoutType === "panorama");
          if (formType === "2ups" && i % 2 > 0 && !isEvenOrOdd){
            lowPages = pairs[i][locations[1]];
            highPages = pairs[i][locations[0]];
          } else {
            lowPages = pairs[i][locations[0]];
            highPages = pairs[i][locations[1]];            
          }

          if (typeof lowPages !== 'undefined' && typeof highPages !== 'undefined'){
            if (lowPages.length > highPages.length)
            {
              highPages[1] = highPages[0];
            } else if (lowPages.length < highPages.length){
              lowPages[1] = lowPages[0];
            }
          } else if (typeof lowPages === 'undefined'){
            lowPages = [];
            lowPages[highPages.length-1] = undefined;            
          } else if (typeof highPages === 'undefined') {
            highPages = [];
            highPages[lowPages.length-1] = undefined;
          }


          //var displayName = lowPages[f].displayName + "-" + highPages[f].displayName;


          var form = {parent:bookSrcId, layoutType:formType, displayName:displayName, sourceId:formSourceId};
          form.layoutNwId = impositionNwId;
          form.layout = imposition.name;
          form.layoutGroup = defaultLayout;
          form.minNumberInBook = getMin(allPages);
          form.maxNumberInBook = getMax(allPages);
          var formPages = getFormPages(lowPages[f], highPages[f]);//highPages[f].virtual ? [lowPages[f]] : [lowPages[f], highPages[f]];          
          form.pages = getFormPagessourceId(lowPages[f], highPages[f]);//highPages[f].virtual ? [lowPages[f].sourceId] : [lowPages[f].sourceId, highPages[f].sourceId];

          var numCells = layoutInfo.impositions[role.imposition].cellGrid.cells.length;
          if (form.pages.length !== numCells){

          }

          if (updateSeps)
            updateFormSeparations(form, formPages);
          forms.push(form);
        }



      }

      return forms;
    }



    function createPairForms(layoutInfo, book, pairs, updateSeps, formType){
      var pmtMode = layoutInfo.pmtMode;
      var formNameMode = layoutInfo.formNameMode;
      if (!pmtMode){
        return createPairFormsNew(layoutInfo, book, pairs, updateSeps, formType);
      }
      var forms = [];
      var bookSrcId = book.sourceId;
      for (var i=0, j=0;i<pairs.length;i++){
        var lowPages = pairs[i][0];
        var highPages = pairs[i][1];
        if (formType === "2ups" && i % 2 === 0) {
          lowPages = pairs[i][1];
          highPages = pairs[i][0];
        }

        //var pages = lowPages;
        if (typeof lowPages !== 'undefined' && typeof highPages !== 'undefined'){
          if (lowPages.length > highPages.length)
          {
            highPages[1] = highPages[0];
          } else if (lowPages.length < highPages.length){
            lowPages[1] = lowPages[0];
          }
        } else if (typeof lowPages === 'undefined'){
          lowPages = [];
          lowPages[highPages.length-1] = undefined;
        } else if (typeof highPages === 'undefined') {
          highPages = [];
          highPages[lowPages.length-1] = undefined;
        }

        for (var f=0;f< lowPages.length;f++ ) {
          j++;
          //var displayName = lowPages[f].displayName + "-" + highPages[f].displayName;
          var displayName = getFormdisplayName(formNameMode, lowPages[f], highPages[f]);// highPages[f].virtual ? lowPages[f].title : lowPages[f].title + "-" + highPages[f].title;
          var uniqueName =  getFormUniqueName(lowPages[f], highPages[f]);// displayName + lowPages[f].numberInZone + "-" + highPages[f].numberInZone;
          var form = {parent:bookSrcId, layoutType:formType, displayName:displayName, sourceId:bookSrcId + uniqueName};
          form.layout = book.defaultLayout;
          var formPages = getFormPages(lowPages[f], highPages[f]);//highPages[f].virtual ? [lowPages[f]] : [lowPages[f], highPages[f]];
          form.pages = getFormPagessourceId(lowPages[f], highPages[f]);//highPages[f].virtual ? [lowPages[f].sourceId] : [lowPages[f].sourceId, highPages[f].sourceId];
          if (updateSeps)
            updateFormSeparations(form, formPages);
          forms.push(form);
        }



      }

      return forms;
    }


    function buildDefaultForms(layoutInfo, book, pages, updateSeps){      
      var formNameMode = layoutInfo.formNameMode;
      if (book.pmiTemplate){
        Ember.set(book, "forms", createPmiForms(layoutInfo, book, pages, updateSeps));
        if (formNameMode === "runningNumber"){
          for (var i=0;i<book.forms.length;i++){
            book.forms[i].displayName = (i + 1) + "";
          }
        }
        return;
      } else if(book.layoutType === 'custom'){
        return;
      }
      switch (book.layoutType){
        case "tabs":
          Ember.set(book, "forms", createTabsForms(layoutInfo, book, getPairs(pages), updateSeps));
          break;
        case "2ups":
          Ember.set(book, "forms", createTowUpsForms(layoutInfo, book, getPairs(pages), updateSeps));
          //book.forms = createTowUpsForms(book, getPairs(pages));
          break;
        case "4ups":
          Ember.set(book, "forms", createFourUpsForms(layoutInfo, book, getPairs(pages), updateSeps));
          //book.forms = createTowUpsForms(book, getPairs(pages));
          break;
        case "8ups":          
          Ember.set(book, "forms", createEightUpsForms(layoutInfo, book, getEightUpsPairs(pages), updateSeps));
          //book.forms = createTowUpsForms(book, getPairs(pages));
          break;
        default:
          Ember.set(book, "forms", createBroadsheetForms(layoutInfo, book, pages, updateSeps));
        //book.forms = createBroadsheetForms(book, pages);
      }
      const layoutType = book.layoutType;
      const numForms = book.forms.length;
      book.forms.sort(function(a, b){
        var min1 = a.minNumberInBook;
        var min2 = b.minNumberInBook;
        if (!min1 || !min2){
          return 0;
        }
        let numPages1 = numPagesInForm(a);
        let numPages2 = numPagesInForm(b);
        let isAscending = true;
        let m1 = 0, m2 = 0;
        if (layoutType == "2ups" && (numPages1 === 1 || numPages2 === 1)){
          m1 = a.maxNumberInBook;
					m2 = b.maxNumberInBook;
					if (numPages1 == 1 && min1 > numForms/2) {
						isAscending = false;									
					} else if (numPages2 == 1 && min2 > numForms/2) {
						isAscending = false;									
					}
        }
        console.log ("tavika");
        return isAscending ? min1 - min2 : m2 - m1;
      });

    }

    function numPagesInForm(form){
      let n = 0;
      for (let i=0;i<form.pages.length;i++){
        if (form.pages[i] && form.pages[i].sourceId !== "$empty$"){
          n++;
        }
      }
      return n;
    }

    function isValid(forms, layoutType){      
      var n = forms.length;    
      var isPmi = layoutType === 'custom';
      var isBroadsheet = layoutType === 'broadsheet';
      if (isPmi || isBroadsheet){
        return true;
      }
      return (n % 2 === 0);
    }

    function validDinkies (pages){

      var n = pages.length;
      if (n % 4 === 0){
        return 0;
      }
      var dinky = 0, minPano = 0, maxPano = 0, minDinky = 0, maxDinky = 0;
      var currentDinky = 0;
      for (var i=0;i<pages.length;i++){
        if (pages[i][0].isDinky) {
          dinky++;
          currentDinky = i;
        } else if (isPanorama(pages[i][0])) {
          (i + 1) <= n/2 ? minPano++ : maxPano++;
        } else if ((i+1) % 2 > 0 ){
          if (minDinky === 0){
            minDinky = i;
          } else if (i < n-2){
            maxDinky = i;
          }

        }
      }

      if (dinky % 2 > 0){
        if (minPano > maxPano && maxDinky > 0){
          if (currentDinky < n/2){
            // wrong dinky on first half
            return -1;
          } else {
            // right dinky on first half
            return -2;
          }
        } else if (minPano < maxPano && minDinky > 0) {
          if (currentDinky > n/2){
            return -3;
          } else {
            return -4;
          }
        } else if (minPano === maxPano) {
          return -5;
        }
      }

      if (minPano >= maxPano && maxDinky > 0){
        //for (var i=0;i<pages[maxDinky].length;i++){
        //  pages[maxDinky][i].isDinky = true;
        //}
        return maxDinky;
      } else if (minDinky > 0) {
        //for (var i = 0; i < pages[minDinky].length; i++) {
        //  pages[minDinky][i].isDinky = true;
        //}
        return minDinky;
      }



    }


    function createDinky(idx, pages){
      for (var i=0;i<pages[idx].length;i++){
        pages[idx][i].isDinky = true;
      }
    }

    return {

      defaultForms: buildDefaultForms,
      updateFormSeparations: updateFormSeparations,
      validDinkies: validDinkies,
      isValid: isValid,
      createDinky: createDinky,
      buildIndexes: buildIndexes
    };

  })
;
