import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactComponents from 'widgets/ReactComponents/src/index';

var { MultiSelectDropdown } = ReactComponents;

export default class extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => { }
  };

  handleChange = (val, text) => {
    var { rowIndex, columnKey, onChange } = this.props;

    if (typeof this.props.onChange === 'function')
      onChange(rowIndex, columnKey, val);
  };

  render() {
    var { columnData, columnKey, options, onChange, ...props } = this.props;

    return <div className="cell">
      <div className="cell-content">
        <MultiSelectDropdown className="Dropdown" options={options} {...props} values={columnData} onChange={this.handleChange} />
      </div>
    </div>;
  }
}
