/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */
import { fromServerDate } from 'core/dates';
import Ember from 'ember';
import ZoneOverviewTemplate from 'text!../templates/ZoneOverview.hbs';
import PageModel from '../models/PageModel';
import FormModel from '../models/FormModel';

function isNotVirtual(model) {
  return model.get('virtual') !== true;
}

module.exports = Ember.Component.extend({
  template: Ember.Handlebars.compile(ZoneOverviewTemplate),
  sections: [],
  books: [],
  plates: {
    totalFinishedPlates: 0,
    totalPlates: 100
  },
  forms: function () {
    var arr = [],
      books = this.get('books');
    books.filter(isNotVirtual).forEach(function (book, index) {
      arr = arr.concat(book.get('forms').filter(isNotVirtual));
      if (index + 1 < books.length)
        arr.push(new FormModel({ id: -1, nwid: -1, type: 'sepration' }, {}, -1));
    });
    return arr;
  }.property('books', 'books.@each', 'books.@each.forms', 'updateFlags.forms'),
  pages: function () {
    var arr = [],
      sections = this.get('sections');
    sections.filter(isNotVirtual).forEach(function (section, index) {
      arr = arr.concat(section.get('pages').filter(isNotVirtual));
      if (index + 1 < sections.length)
        arr.push(new PageModel({ id: -1, nwid: -1, type: 'sepration' }, {}, -1));
    });
    return arr;
  }.property('sections', 'sections.@each', 'sections.@each.pages', 'updateFlags.pages'),
  onlyPages: function () {
    var arr = [];
    this.get('sections').forEach(function (section) {
      arr = arr.concat(section.get('pages').filter(isNotVirtual));
    });
    return arr;
  }.property('pages', 'pages.@each'),
  onlyForms: function () {
    var arr = [];
    this.get('books').forEach(function (book) {
      arr = arr.concat(book.get('forms').filter(isNotVirtual));
    });
    return arr;
  }.property('forms', 'forms.@each'),
  finishedPages: function () {
    return this.get('pages').filter(function (page) {
      return page.get('virtual') !== true && page.get('finished') === true;
    }).length;
  }.property('pages', 'pages.@each', 'pages.@each.finished'),
  finishedForms: function () {
    return this.get('forms').filter(function (form) {
      return form.get('virtual') !== true && form.get('finished') === true;
    }).length;
  }.property('forms', 'forms.@each', 'forms.@each.finished'),
  pagesStatus: function () {
    return 'Pages: ' + this.get('finishedPages') + ' / ' + this.get('onlyPages').length;
  }.property('onlyPages', 'finishedPages'),
  formsStatus: function () {
    return 'Formes: ' + this.get('finishedForms') + ' / ' + this.get('onlyForms').length;
  }.property('onlyForms', 'finishedForms'),
  productionRunsHandler: function () {
    var fnc = this.get('productionRunsChanged') || function () { };
    fnc.call(this.get('module'), this.get('productionRuns').length);
  }.observes('productionRuns', 'productionRuns.@each').on('init'),
  orderedProductionRuns: function () {
    return this.productionRuns.map(function (p) {
      return p;
    }).sort(function (a, b) {
      return fromServerDate(a.deadline).getTime() - fromServerDate(b.deadline).getTime();
    })
  }.property('productionRuns', 'productionRuns.@each', 'productionRuns.@each.deadline')
});
