import PropTypes from 'prop-types';
define(['react'], function (React) {
  'use strict'

  const { Component } = React;

  return class DropdownOptionItem extends Component {

    static propTypes = {

      text: PropTypes.string,

      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),

      index: PropTypes.number,

      selected: PropTypes.bool,

      onClick: PropTypes.func,

      onMouseHover: PropTypes.func

    };

    static defaultProps = {

      onClick: function () { }

    };

    constructor(props) {
      super(props);
    }


    handleClick = (event) => {

      var { value, index, onClick } = this.props;

      onClick(value, index, true, event);

    };

    handleMouseOver = (event) => {

      var { value, index, onMouseHover } = this.props;

      onMouseHover(value, index, event);

    };

    render() {

      var { text, selected } = this.props;

      var className = selected ? 'dropdown-list-item selected' : 'dropdown-list-item';

      return <li className={className} onClick={this.handleClick} onMouseOver={this.handleMouseOver} title={text}>
        {text}
      </li>;

    }

  }

});