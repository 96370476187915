define(['sandbox', 'ember', 'logger'], function (sandbox, Ember, logger) {
    'use strict';
    var log = logger.getDefaultLogger();

    return Ember.ObjectController.extend({

        deletePressProfile: function (pressprofile) {
            var deletePressProfileAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'DeletePressProfileActionCR');
            if (typeof deletePressProfileAction === 'undefined') {
                log.error('DeletePressProfileAction instance is not found');
                return;
            }

            if (deletePressProfileAction.isApplicable(pressprofile)) {
                deletePressProfileAction.execute(pressprofile);
            }
        }
    });

});
