define(['react', 'sandbox'], function (React, sandbox) {
  'use strict';

  var Component = React.Component;
  var FULL_RECT = 0;
  var CENTER_SINGLE_RECT = 1;
  var OBLIQUE_RECTS = 2;
  var VERTICAL_CENTER = 3;
  var HORIZONTAL_CENTER = 4;

  var LOCAL = 0;
  var COMMON = 1;
  var COLOR_SHARE = 2;
  var MONO_SHARE = 3;


  var FALSE_PANORAMA = 0;
  var REAL_PANORAMA = 1;
  var REVERT_PANORAMA = 2;
  var translate = sandbox.localization.translate;

  function getColorPaintMode(separations, colorObjects) {
    var numColors = Object.keys(separations).length;
    if (numColors >= 3) {
      return FULL_RECT;
    }
    if (numColors === 1) {
      return CENTER_SINGLE_RECT;
    }
    var isCyan = separations.hasOwnProperty(colorObjects.cyan);
    var isMagenta = separations.hasOwnProperty(colorObjects.magenta);
    var isYellow = separations.hasOwnProperty(colorObjects.yellow);
    var isBlack = separations.hasOwnProperty(colorObjects.black);

    if (isCyan && isMagenta) {
      return HORIZONTAL_CENTER;
    }
    if (isYellow && isBlack) {
      return HORIZONTAL_CENTER;
    }
    if (isCyan && isYellow) {
      return VERTICAL_CENTER;
    }
    if (isMagenta && isBlack) {
      return VERTICAL_CENTER;
    }
    return OBLIQUE_RECTS;
  }

  function getSingleColor(separations, colorObjects) {
    var list = Object.keys(separations);
    if (list.length > 0) {
      for (var key in colorObjects) {
        var value = colorObjects[key];
        if (value === list[0]) {
          return key;
        }
      }
    }
    return "";
  }



  class Main extends Component {
    state = {};

    hideDialog = () => {
      this.props.actionController({}, "hideAllDialogs", []);
      //this.props.actionController({}, "hideCustomColorDialog", []);
      //this.props.actionController({}, "hideAdvanceSharingDialog", []);
    };

    render() {
      var style = {};

      return (
        <div style={style} className='pwpsContainer' onClick={this.hideDialog}>
          <ColorDialog main={this} actionController={this.props.actionController} colorObjects={this.props.colorObjects} />
          <AdvanceSharingDialog main={this} actionController={this.props.actionController} mapPages={this.props.mapPages}
            chaseMode={this.props.chaseMode} />
          <PropertiesDialog actionController={this.props.actionController} main={this} />

          <Sections main={this} sections={this.props.sections} chaseMode={this.props.chaseMode}
            actionController={this.props.actionController} colorObjects={this.props.colorObjects} />
        </div>
      );
    };
  }

  class AdvanceSharingDialog extends Component {

    selectedZone = null;

    selectedPage = null;

    state = {
      display: 'none',
      x: '0px',
      y: '0px',
      mode: LOCAL
    };

    constructor(props) {
      super(props);
      props.actionController({}, "AdvanceSharingDialogInit", [this]);
    };

    hideDialog = (event) => {
      this.selectedZone = null;
      this.selectedPage = null;
      this.props.actionController(event, "hideAllDialogs", []);
    };

    clickHandler = (event) => {
      event.stopPropagation();

    };

    colorShareAction = (event) => {
      var mode = event.target.value;
      console.log(this)
      var hasDefMaster = true;
      if (mode !== LOCAL) {
        if (!this.selectedPage || !this.selectedZone) {
          hasDefMaster = this.props.actionController(event, "hasDefaultMaster", []);
        }
      }
      if (!hasDefMaster) {
        // TODO add error
        this.setState({ mode: event.target.value });
        this.props.main.setState({ renderFlag: !this.state.renderFlag });
        return;
      }
      var actionResult = this.props.actionController(event, "colorShareAction", [this.props.page, mode]);
      for (var i = 0; i < actionResult.cells.length; i++) {
        if (actionResult.cells[i] && actionResult.cells[i].props) {
          actionResult.cells[i].props.stateChangeListener(actionResult);
        }
      }
      //this.hideDialog(event);
      this.setState({ mode: event.target.value });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };

    shareZoneChanged = (event) => {
      this.selectedZone = event.target.value;
      this.selectedPage = this.props.actionController(event, "getSelectedPage", [this.selectedZone, this.props.mapPages]);
      if (this.selectedPage !== null) {
        var actionResult = this.props.actionController(event, "jumpShareAction", [this.state.mode, this.selectedZone, this.selectedPage]);

      }

      this.render();
      this.props.main.setState({ renderFlag: !this.state.renderFlag });

    };

    sharePageChanged = (event) => {
      this.selectedPage = event.target.value;
      var actionResult = this.props.actionController(event, "jumpShareAction", [this.state.mode, this.selectedZone, this.selectedPage]);
      this.render();
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };

    render() {
      var mode = this.state.mode;
      //var zoneMissing = false, pageMissing = false;
      //if (mode !== LOCAL){
      //  zoneMissing = this.selectedZone == null;
      //  pageMissing = this.selectedPage == null;
      //} else {
      //  this.selectedZone = null;
      //  this.selectedPage = null;
      //}
      //
      //console.log (zoneMissing + "::::::::" + pageMissing);
      var style = {
        height: '120px', display: this.state.display, backgroundColor: 'white',
        position: 'absolute', top: this.state.y + "px", left: this.state.x + "px", zIndex: 2, outline: '1px ridge gray'
      };

      var buttonStyle = {
        width: '10px', height: '10px', position: 'absolute', top: '0px', right: '0px',
        margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', fontSize: '6px'
      };


      var tableStyle = { marginTop: '15px', marginLeft: '5px' };
      var disabled = this.state.mode === LOCAL;

      var zoneOptionsList = this.state.display === 'none' ? [] : this.props.actionController(null, "getZonesOptionsForSelectedPage", [this.selectedZone]);


      //var zoneOptionsList = Object.keys(this.props.mapPages);
      //if (zoneOptionsList.length > 0 && zoneOptionsList[0] !== '---'){
      //  zoneOptionsList.unshift("---");
      //}


      var zoneOptions = zoneOptionsList.map(function (zone, index) { // Todo -
        if (this.selectedZone === null && zone.selected) {
          this.selectedZone = zone.title;
        }
        return <option key={index} value={zone.title} selected={zone.selected}>{zone.title}</option>
        //return <option value={zone} selected={zone === this.state.selectedZone}>{zone}</option>
      }, this);

      var pageOptionsList = [];
      if (this.selectedZone !== null) {
        pageOptionsList = this.props.actionController(null, "getSectionOptionsForSelectedPage", [this.selectedZone, this.props.mapPages]);
        pageOptionsList.unshift({ title: "---", sourceId: "" });
        //if (pageOptionsList.length > 0 && pageOptionsList[0] !== '---'){
        //  pageOptionsList.unshift({title:"---", sourceId:""});
        //}
      } else {
        pageOptionsList = [{ title: "---", sourceId: "" }];
      }

      var pageOptions = pageOptionsList.map(function (page, index) {
        return <option key={index} value={page.sourceId} selected={page.selected}>{page.title}</option>
      }, this);

      function jumpShareZoneUI(obj) {
        if (obj.props.chaseMode) {
          return "";
        }

        return (
          // <div>
          <tr>
            <td>
              Zone:
        </td>

            <td>
              <select onChange={obj.shareZoneChanged} onClick={obj.clickHandler}
                value={obj.state.selectedZone} disabled={disabled}>
                {zoneOptions}
              </select>
            </td>
          </tr>
          // </div>
        )
      }

      function jumpSharePageUI(obj) {
        if (obj.props.chaseMode) {
          return "";
        }

        return (
          <tr>
            <td>Page:</td>

            <td>
              <select onChange={obj.sharePageChanged} onClick={obj.clickHandler}
                value={obj.state.selectedPage} disabled={disabled}>
                {pageOptions}
              </select>
            </td>
          </tr>
        )
      }

      return (
        <div style={style} className="myAdvanceSharingDialog">
          <input type='button' style={buttonStyle} value='x' onClick={this.hideDialog} />
          <table style={tableStyle} cellPadding="5" cellSpacing="8">
            <tr>
              <td>Type:</td><td>
                <select onChange={this.colorShareAction} onClick={this.clickHandler}
                  value={parseInt(this.state.mode, 10)}>
                  <option value={COLOR_SHARE}>Color Share</option>
                  <option value={MONO_SHARE}>Text Share</option>
                  <option value={COMMON}>Common</option>
                  <option value={LOCAL}>Local</option>
                </select>
              </td>
            </tr>
            {jumpShareZoneUI(this)}
            {jumpSharePageUI(this)}

          </table>
        </div>
      )
    };
  }

  class ColorDialog extends Component {

    state = {
      display: 'none',
      x: '0px',
      y: '0px'
    };

    constructor(props) {
      super(props);
      props.actionController({}, "CustomColorDialogInit", [this]);
    }

    hideDialog = (event) => {
      this.props.actionController(event, "hideAllDialogs", []);
    };

    colorChange = (event) => {
      event.stopPropagation();
      var color = event.target.dataset.color;
      var state = {}
      state[color] = !this.state[color];

      var actionResult = this.props.actionController(event, "customColorChange", [color, state[color]]);
      for (var i = 0; i < actionResult.cells.length; i++) {
        actionResult.cells[i].props.stateChangeListener(actionResult);
      }
      this.setState(state);
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };

    render() {

      function getColorName(colorObjects, color) {
        for (var key in colorObjects) {
          var value = colorObjects[key];
          if (value === color) {
            return key;
          }
        }
        return color;
      }
      var colorObjects = this.props.colorObjects;
      var style = {
        width: '110px', height: '30px', display: this.state.display, backgroundColor: 'white',
        position: 'absolute', top: this.state.y + "px", left: this.state.x + "px", zIndex: 2, outline: '1px ridge gray'
      };

      var buttonStyle = {
        width: '10px', height: '10px', position: 'absolute', top: '0px', right: '0px',
        margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', fontSize: '6px'
      };

      var colors = [colorObjects.cyan, colorObjects.magenta, colorObjects.yellow, colorObjects.black].map(function (color, index) {
        var exists = this.state.hasOwnProperty(color) && this.state[color];
        var style = { backgroundColor: getColorName(colorObjects, color), width: '20px', height: '20px', flow: 'left' };
        var imgStyle = { visibility: exists ? 'visible' : 'hidden' }
        var imgSrc = sandbox.icons.getModuleIcon("PlanningWizard", "checkedProcessColorSmall");
        return (
          <td key={index}>
            <div style={style} onClick={this.colorChange} data-color={color}>
              <img src={imgSrc} style={imgStyle} data-color={color} />
            </div>
          </td>
        )
      }, this);

      return (
        <div style={style} className="myColorDialog">
          <input type='button' style={buttonStyle} value='x' onClick={this.hideDialog} />
          <table>
            <tr>
              {colors}
            </tr>
          </table>
        </div>
      )
    };
  }

  class PropertiesDialog extends Component {

    state = {
      display: 'none',
      x: '0px',
      y: '0px',
      customProps: []
    };

    constructor(props) {
      super(props);
      props.actionController({}, "PropertiesDialogInit", [this]);
    };

    handlePanorama = (event) => {
      this.props.actionController(event, "panoramaAction", [this, event.target.checked ? REAL_PANORAMA : REVERT_PANORAMA]);
      this.setState({ isPanorama: event.target.checked });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };

    insertAction = (event) => {
      var numInserts = parseInt(event.target.value);
      this.props.actionController(event, "insertAction", [this, numInserts]);
      this.setState({ insertsApplicable: event.target.value });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };

    splitAction = (event) => {
      this.props.actionController(event, "splitPage", [event.target.checked]);
      this.setState({ isSplit: event.target.checked });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
      this.hideDialog();
    };

    dinkyAction = (event) => {
      this.props.actionController(event, "dinkyPage", [event.target.checked]);
      this.setState({ isDinky: event.target.checked });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
      this.hideDialog();
    };

    propChangeAction = (event) => {
      var actionResult = this.props.actionController(event, "customPropertyChange", [event.target.dataset.value, event.target.checked]);
      //for (var i=0;i<actionResult.cells.length;i++){
      //  actionResult.cells[i].props.stateChangeListener(actionResult);
      //}
      var state = {};
      state[event.target.dataset.value] = event.target.checked;
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
      this.setState(state);
    };

    clickHandler = (event) => {
      event.stopPropagation();
    };

    hideDialog = (event) => {
      this.props.actionController(event, "hideAllDialogs", []);
    };

    render() {
      /*
  
       {display : 'inline', x: x, y: y, props: props,
       panoramaState:panoramaState, singleSelection:this.selectedPages.length === 1});
       */

      function panoramaFieldStyle(obj) {
        if (obj.state.panoramaApplicable) {
          //return {display:'inline'};

        } else {
          //return {display:'none'};
        }
      }

      var options = [0, 2, 4, 6, 8, 10, 12].map(function (numInserts, index) {
        return <option key={index} value={numInserts}>{numInserts}</option>
      }, this);


      function insertsSelectValue(obj) {
        var state = obj.state.insertsApplicable;
        return parseInt(state, 10);
      }

      function dinkyFieldStyle(obj) {
        if (!obj.state.showDinky) {
          return { display: 'none' };
        }
      }

      function insertsFieldStyle(obj) {
        //  var state = obj.state.insertsApplicable;
        //  return state > 1 ? {opacity : '.50', filter  : 'alpha(opacity=50)', cursor  : 'default'} : {};
        if (obj.state.hideInserts) {
          return { display: 'none' };
        }
        var state = obj.state.insertsApplicable;
        return state > -1 ? { display: 'table-row' } : { display: 'none' };
      }


      var style = {
        width: '150px', height: '270px', display: this.state.display, outline: '1px ridge gray', backgroundColor: 'white',
        position: 'absolute', top: this.state.y + "px", left: this.state.x + "px", zIndex: 1
      };

      var buttonStyle = {
        width: '10px', height: '10px', position: 'absolute', top: '0px', right: '0px',
        margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', fontSize: '6px'
      };
      var tableStyle = { width: '100%', paddingTop: '12px' };
      var tdStyle = { width: '70%' };

      var hideInserts = this.state.hideInserts;
      var showDinky = this.state.showDinky;
      var customProps = this.state.customProps.map(function (prop, index) {

        return (
          <tr key={index}>
            <td>{prop.title}:</td>
            <td style={tableStyle}><input data-value={prop.name} type='checkbox' checked={this.state[prop.name]} onChange={this.propChangeAction} /></td>
          </tr>
        )


      }, this);
      return (
        <div style={style} onClick={this.clickHandler} className="myDialog">

          <input type='button' style={buttonStyle} value='x' onClick={this.hideDialog} />

          <table style={tableStyle} cellPadding="5" cellSpacing="5">
            <tbody>
              <tr disabled={!this.state.panoramaApplicable}>
                <td style={tdStyle}>{translate('Panorama')}</td><td  ><input type='checkbox' checked={this.state.isPanorama} onChange={this.handlePanorama}
                  disabled={!this.state.panoramaApplicable} /></td>
              </tr>
              <tr disabled={!this.state.splitApplicable}>
                <td style={tdStyle}>{translate('Split')}</td><td  ><input type='checkbox' checked={this.state.isSplit} onChange={this.splitAction}
                  disabled={!this.state.splitApplicable} /></td>
              </tr>
              <tr style={dinkyFieldStyle(this)}>
                <td style={tdStyle}>{translate('Fly Sheet')}</td><td  ><input type='checkbox' checked={this.state.isDinky} onChange={this.dinkyAction}
                  disabled={false} /></td>
              </tr>

              <tr style={insertsFieldStyle(this)}>
                <td style={tdStyle}>{translate('Inserts')}</td><td>
                  <select onChange={this.insertAction} value={this.state.insertsApplicable} onClick={this.clickHandler}>
                    {options}
                  </select>
                </td>
              </tr>
              {customProps}
            </tbody>
          </table>
        </div>
      )
    };
  }

  class ColorIcon extends Component {

    state = {
      cellHOver: false
    };

    getSepVisibilityStyle = (colorObjects, sepValue) => {
      var sepDisplayName = colorObjects[sepValue];
      if (this.props.page.virtual)
        return { visibility: 'hidden' };
      return this.props.page.separations.hasOwnProperty(sepDisplayName) ? { visibility: 'visible' } : { visibility: 'hidden' };
    };

    getSingleSepStyle = () => {
      return { position: 'relative', top: '6px', left: '6px', width: '12px', height: '12px' };
    };

    getColorRectStyle = (colorObjects, sepValue) => {
      var sepDisplayName = colorObjects[sepValue];
      if (this.props.page.separations.hasOwnProperty(sepDisplayName)) {
        var sep = this.props.page.separations[sepDisplayName];
        if (sep.masterSeparation === null || typeof sep.masterSeparation === "undefined") {
          if (sep.baseSeparation === null || typeof sep.baseSeparation === "undefined") {
            return sepValue;
          }
          return sepValue + "Share";
        } else {
          return sepValue + "Share";
        }
      } else if (typeof this.props.page.masterPage !== 'undefined' && this.props.page.masterPage !== null) {
        return sepValue + "Share";
      }
      return ""
    };

    mouseOverEvent = () => {
      if (this.props.chaseMode) {
        return;
      }
      this.setState({ cellHOver: true });
      this.props.cellStateHandle({ advanceColorVisibility: true });
    };

    mouseOutEvent = () => {
      if (this.props.chaseMode) {
        return;
      }
      this.setState({ cellHOver: false });
      this.props.cellStateHandle({ advanceColorVisibility: true });
    };

    render() {
      var singleSepContainerStyle = { width: '24px !important', height: '24px !important' };
      var containerStyle = this.state.cellHOver ? { outline: "1px outset white" } : {};
      var isLink = (typeof this.props.page.masterPage !== 'undefined' && this.props.page.masterPage !== null)
        || (this.props.page.basePage !== null || typeof this.props.page.basePage === "undefined");
      var colorObjects = this.props.colorObjects;
      containerStyle.cursor = isLink ? "default" : "pointer";
      switch (getColorPaintMode(this.props.page.separations, colorObjects)) {
        case OBLIQUE_RECTS:
        case FULL_RECT:
          return (

            <div style={containerStyle} className='colorIcon' onClick={this.handleColorIconClick} onMouseOver={this.mouseOverEvent} onMouseOut={this.mouseOutEvent}>

              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'cyan')} style={this.getSepVisibilityStyle(colorObjects, 'cyan')}>&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'magenta')} style={this.getSepVisibilityStyle(colorObjects, 'magenta')} >&nbsp;</div>
              </div>
              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'yellow')} style={this.getSepVisibilityStyle(colorObjects, 'yellow')} >&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'black')} style={this.getSepVisibilityStyle(colorObjects, 'black')} >&nbsp;</div>
              </div>
            </div>

          );
          break;
        case CENTER_SINGLE_RECT:
          return (
            <div className='colorIcon' style={containerStyle} onClick={this.handleColorIconClick} onMouseOver={this.mouseOverEvent} onMouseOut={this.mouseOutEvent} >
              <div className='colorIconRow'>
                <div className='singleSepContainerStyle'>
                  <div className={this.getColorRectStyle(colorObjects, getSingleColor(this.props.page.separations, colorObjects))} style={this.getSingleSepStyle()}>&nbsp;</div>
                </div>
              </div>
            </div>

          );
          break;
        default:
          return (

            <div className='colorIcon' style={containerStyle} onClick={this.handleColorIconClick} onMouseOver={this.mouseOverEvent} onMouseOut={this.mouseOutEvent}>
              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'cyan')} style={this.getSepVisibilityStyle(colorObjects, 'cyan')}>&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'magenta')} style={this.getSepVisibilityStyle(colorObjects, 'magenta')} >&nbsp;</div>
              </div>
              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'yellow')} style={this.getSepVisibilityStyle(colorObjects, 'yellow')} >&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'black')} style={this.getSepVisibilityStyle(colorObjects, 'black')} >&nbsp;</div>
              </div>
            </div>

          );

      }

    };

    handleColorIconClick = (event) => {
      if (this.props.chaseMode) {
        var page = this.props.page;
        if (page.isChase)
          return;
      }
      this.props.actionController(event, "hideAllDialogs", []);
      this.props.selectionHandler(event);
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }
      var actionResult = this.props.actionController(event, "colorChangeAction", [this.props.page]);
      if (typeof actionResult.cells === 'undefined') {
        return;
      }
      //for (var i=0;i<actionResult.cells.length;i++){
      //  actionResult.cells[i].props.stateChangeListener(actionResult);
      //}
      this.props.cell.fireSectionSizeChange({});
    };

  }

  class Cell extends Component {

    state = {
      spHOver: false,
      customHOver: false,
      cellHOver: false,
      hover: false,
      selected: false,
      advanceColorVisibility: false
    };

    mouseOver = () => {
      this.setState({ cellHOver: true });
    };

    mouseOut = () => {
      this.setState({ cellHOver: false });
    };

    selectCell = (event) => {
      var actionResult = this.props.actionController(event, "selectionDone", [this.props.page, this]);
      this.props.actionController(event, "hideAllDialogs", []);
      this.fireSectionSizeChange({});
    };

    fireSectionSizeChange = (changeEvent) => {
      this.props.sectionChangeListener(changeEvent);
    };

    imgMouseOverEvent = (event) => {
      this.handleMouseEvent(event, true);
    };

    imgMouseOutEvent = (event) => {
      this.handleMouseEvent(event, false);
    };

    stateHandle = (stateObject) => {
      this.setState(stateObject);
    }

    handleMouseEvent = (event, isOver) => {
      var className = event.currentTarget.className;
      if (className === "customPropsRightSection") {
        this.setState({ customHOver: isOver })
      } else if (className === "sharingSection") {
        this.setState({ hover: isOver });
      } else if (className === "customShareIcon") {
        this.setState({ ccHOver: isOver });
      } else if (className === "customColorIcon") {
        this.setState({ spHOver: isOver });
      }
    };

    render() {
      var customProps = Object.keys(this.props.page.customProperties).map(function (prop) {

        if (this.props.page.customProperties[prop]) {
          var ret = prop;
          if (ret === "ED") {
            ret = "E";
          } else if (ret === "AD") {
            ret = "A";
          }
          return ret + " ";
        }
        return "";


      }, this);

      function getDinkyProp(obj) {
        var isDinky = obj.props.page.dinky;
        if (!isDinky) {
          return "";
        }
        return <div>
          F
            </div>
      }

      function getCustomPropertiesSection(obj) {
        var src = obj.state.customHOver ? sandbox.icons.getModuleIcon("PlanningWizard", "menu_hover") :
          sandbox.icons.getModuleIcon("PlanningWizard", "menu");
        var rightStyle = { visibility: obj.state.cellHOver ? 'visible' : 'hidden' };
        var chasePage = typeof obj.props.page.basePage !== "undefined" && obj.props.page.basePage !== null;
        if (chasePage) {
          return (
            <div className='customPropsContainer'>

            </div>);
        }
        return (
          <div className='customPropsContainer'>
            <div className='customPropsLeftSide'>{customProps}</div>
            <div style={rightStyle} className='customPropsRightSection' onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent}>
              <div><img src={src} onClick={obj.showPropertiesDialog} /></div>
            </div>
          </div>);
      }

      function getSharingSection(obj) {
        //var jumpShareWidth = obj.props.page.jumpShare ? {width:'50px', minWidth:'50'} : {};
        var cursor = { cursor: 'pointer' };
        var masterTitle = obj.props.page.jumpShare && obj.props.page.masterPageName !== obj.props.page.title ?
          "(" + obj.props.page.masterPageName + ")" : "";
        if (obj.props.page.masterPageZone !== obj.props.page.masterZone) {
          masterTitle = "";
        }
        if (obj.props.chaseMode) {
          /*
           options are :
            page is master but not local
            page is master and local
          */
          var localSrc = sandbox.icons.getModuleIcon("PlanningWizard", "local")
          var masterButNotLocal = sandbox.icons.getModuleIcon("PlanningWizard", "localGrey")
          var hasBase = typeof obj.props.page.basePage !== "undefined" && obj.props.page.basePage !== null
          var hasMaster = typeof obj.props.page.masterPage !== "undefined" && obj.props.page.masterPage !== null
          if (!hasBase && !hasMaster) {
            // i'm local
            return (<div className='sharingSection' onClick={obj.handleShareIconClick} >
              <span style={cursor}><img src={localSrc} style={cursor} /></span>
            </div>);
          } else if (!hasMaster) {
            return (<div className='sharingSection' onClick={obj.handleShareIconClick} >
              <span style={cursor}><img src={masterButNotLocal} style={cursor} /></span>
            </div>);
          } else {
            return (<div className='sharingSection' onClick={obj.handleShareIconClick} >
              <span style={cursor}>{obj.props.page.masterZone}</span>
            </div>);
          }

        } else {
          if (!obj.props.page.isMasterOptional && obj.props.chaseMode) {
            if (typeof obj.props.page.basePage === "undefined" || obj.props.page.basePage === null) {
              var style = { visibility: obj.state.cellHOver ? 'visible' : 'hidden' };

              //var style = {visibility:obj.state.cellHOver ? 'visible' : 'hidden', float:'right'};
              var src = obj.state.hover ? sandbox.icons.getModuleIcon("PlanningWizard", "local_hover") :
                sandbox.icons.getModuleIcon("PlanningWizard", "local");
              return (<div className='sharingSection' style={style} onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent}>
                <div><img src={src} onClick={obj.handleShareIconClick} style={cursor} /></div>
              </div>);

            } else {
              return (<div className='sharingSection' onClick={obj.handleShareIconClick}>
                <span style={cursor}></span>
              </div>);
            }
          } else if (!obj.props.page.isMasterOptional) {
            var src = obj.state.hover ? sandbox.icons.getModuleIcon("PlanningWizard", "local_hover") :
              sandbox.icons.getModuleIcon("PlanningWizard", "local");
            return (<div className='sharingSection'><img src={src} /></div>);
          } else if ((typeof obj.props.page.masterZone === "undefined" || obj.props.page.masterZone === null) && !obj.props.page.virtual) {
            var style = { visibility: obj.state.cellHOver ? 'visible' : 'visible' };

            //var style = {visibility:obj.state.cellHOver ? 'visible' : 'hidden', float:'right'};
            var src = obj.state.hover ? sandbox.icons.getModuleIcon("PlanningWizard", "local_hover") :
              sandbox.icons.getModuleIcon("PlanningWizard", "local");
            return (<div className='sharingSection' style={style} onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent}>
              <div><img src={src} onClick={obj.handleShareIconClick} style={cursor} /></div>
            </div>);

          } else {
            //var jumpShareWidth = obj.props.page.jumpShare ? {width:'50px', minWidth:'50'} : {};
            return (<div className='sharingSection' onClick={obj.handleShareIconClick} >
              <span style={cursor}>{obj.props.page.masterZone + masterTitle}</span>
            </div>);
          }
        }

      }

      function getCustomColorIcon(obj) {
        var enabled = obj.props.page.masterPageZone === null || typeof obj.props.page.masterPageZone === "undefined";
        if (obj.props.chaseMode) {
          enabled = false;
        }
        var style = { visibility: enabled && obj.state.cellHOver && obj.state.advanceColorVisibility ? 'visible' : 'hidden' };
        var src = obj.state.ccHOver ? sandbox.icons.getModuleIcon("PlanningWizard", "dropdown_arrow_hover") :
          sandbox.icons.getModuleIcon("PlanningWizard", "dropdown_arrow");
        return <div className='customShareIconContainer'><img className='customShareIcon' style={style} src={src} onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent} onClick={obj.showCustomColorDialog} /></div>
      }

      function getCustomShareIcon(obj) {
        var singleSelection = obj.props.actionController({}, "isSingleSelection", [])
        if (!obj.props.page.isMasterOptional) {
          return (<div className='customColorIcon'></div>);
        }
        var style = { visibility: obj.state.cellHOver && singleSelection ? 'visible' : 'hidden' };
        var src = obj.state.spHOver ? sandbox.icons.getModuleIcon("PlanningWizard", "dropdown_arrow_hover") :
          sandbox.icons.getModuleIcon("PlanningWizard", "dropdown_arrow");
        return <div><img className='customColorIcon' style={style} src={src} onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent} onClick={obj.showCustomColor} /></div>
      }

      function getSelectionStyle(obj) {
        var style = {};
        //if (obj.state.selected) {
        if (obj.props.page.selectedState) {
          style.border = 'solid 3px #83b5ff';
        } else if (obj.state.cellHOver) {
          style.border = 'solid 3px #bdd8ff';
        } else {
          //style = {border: ''};
        }
        var key = obj.props.page.masterPageZone !== null && typeof obj.props.page.masterPageZone !== "undefined" ?
          obj.props.page.masterPageZone : obj.props.actionController({}, "findZone", [obj.props.page]);

        var value = obj.props.page.isChase ? "white" : obj.props.zonesColorMap[key];// zonesBgColors[key];
        style.backgroundColor = value;

        style.visibility = obj.props.page.virtual ? 'hidden' : 'visible';

        return style;
      }
      
      function getTitleWithoutCode (page){
          var ret = "";
          var names = page.title.split("-");
          for (var i=0;i<names.length;i++){
            name = names[i].replace(page.sectionCode, "");
            if (i < names.length - 1){
              name += "-";
            }
            ret += name;
          }
          
          return ret;
        }

	
      return (
        <div className='recordCell' onClick={this.selectCell} ref={'cell' + this.props.page.sourceId}
          style={getSelectionStyle(this)} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>
          <div className='cellContentContainer'>
            <div className='cellContentContainerLabel' >{this.props.actionController(null, "useCode") ? this.props.page.title : getTitleWithoutCode(this.props.page)}</div>

            <ColorIcon page={this.props.page} actionController={this.props.actionController}
              stateChangeListener={this.props.stateChangeListener}
              selectionHandler={this.selectCellController}
              cellStateHandle={this.stateHandle}
              chaseMode={this.props.chaseMode}
              colorObjects={this.props.colorObjects}
              cell={this} />

            {getCustomColorIcon(this)}

            {getSharingSection(this)}
            {getCustomShareIcon(this)}
            {getDinkyProp(this)}
            {getCustomPropertiesSection(this)}
          </div>


        </div>
      );
    };

    selectCellController = (event) => {
      event.stopPropagation();
      if (!this.props.page.selectedState)
        this.selectCell(event);
    };

    handleShareIconClick = (event) => {
      this.selectCellController(event);
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }
      var actionResult = this.props.actionController(event, "shareAction", [this.props.page]);
      this.props.stateChangeListener(actionResult);
      this.fireSectionSizeChange({});
    };

    showPropertiesDialog = (event) => {
      this.selectCellController(event);
      var actionResult = this.props.actionController(event, "ShowPropertiesDialog", [this.props.page]);
      this.props.stateChangeListener(actionResult);
    };

    showCustomColorDialog = (event) => {
      this.selectCellController(event);
      var actionResult = this.props.actionController(event, "ShowCustomColorDialog", [this.props.page]);
      this.props.stateChangeListener(actionResult);
    };

    showCustomColor = (event) => {
      this.selectCellController(event);
      var actionResult = this.props.actionController(event, "ShowAdvanceSharingDialog", [this.props.page]);
      this.props.stateChangeListener(actionResult);
    };

  }

  class Row extends Component {

    state = {
      rowChangeFlag: false,
      rowTitleHOver: false
    };

    stateChangeListener = (actionResult) => {
      this.setState({ rowChangeFlag: !this.state.rowChangeFlag });
    };

    imgMouseOverEvent = (event) => {
      this.setState({ rowTitleHOver: true })
    };

    imgMouseOutEvent = (event) => {
      this.setState({ rowTitleHOver: false })
    };

    getStyle = () => {
      for (var i = 0; i < this.props.zones.length; i++) {
        var page = this.props.zonedPage.zoneMap[this.props.zones[i].displayName];
        if (page !== null && typeof page !== "undefined") {
          if (!page.virtual) {
            return { display: 'table-row' };
          }
        }
      }
      return { display: 'none' };
    };

    render() {

      var cells = this.props.zones.map(function (zone, index) {
        var zoneName = zone.displayName;
        if (!this.props.zonedPage.zoneMap.hasOwnProperty(zoneName)) {
          return (<div className='recordCell'></div>);
        }
        var page = this.props.zonedPage.zoneMap[zoneName];
        var sourceId = page.sourceId;
        return (<Cell key={index} rowIndex={this.props.index} page={page} sourceId={sourceId}
          stateChangeListener={this.stateChangeListener}
          actionController={this.props.actionController}
          sectionChangeListener={this.props.sectionChangeListener} zonesColorMap={this.props.zonesColorMap}
          ref={"cell" + page.sourceId}
          colorObjects={this.props.colorObjects}
          chaseMode={this.props.chaseMode}
          zoneName={zoneName}
          sectionsChangeListener={this.props.sectionsChangeListener} />);


      }, this);

      var src = sandbox.icons.getModuleIcon("PlanningWizard", "row_arrow");
      var style = { visibility: this.state.rowTitleHOver ? 'visible' : 'hidden' };
      //var src = this.state.rowTitleHOver ? sandbox.icons.getModuleIcon("PlanningWizard", "row_arrow") :
      //  sandbox.icons.getModuleIcon("PlanningWizard", "row_arrow");
      return (
        <div className='recordRow' style={this.getStyle()}>
          <div className='recordCellTitle' onClick={this.handleClick} onMouseOver={this.imgMouseOverEvent} onMouseOut={this.imgMouseOutEvent}>
            <img className='recordCellTitleImg' src={src} style={style} /></div>
          {cells}
        </div>
      );
    };

    handleClick = (event) => {
      var that = this;
      if (event.ctrlKey || event.metaKey) {
        this.props.actionController(event, "rowAction", [true]);
      }

      for (var ref in this.refs) {
        var obj = React.findDOMNode(that.refs[ref]);
        obj.click();
        this.props.actionController(event, "rowAction", [true]);
      }
      this.props.actionController(event, "rowAction", [false]);
    };
  }

  class HeaderRow extends Component {
    render() {

      var cols = this.props.zones.map(function (zone, index) {
        var zoneName = zone.displayName;
        var zoneFullName = zone.title;
        var style = { backgroundColor: zone.color };//{position: '-webkit-sticky', position: 'sticky', top: '0px'};
        // var backgroundColor = zone.color;//zonesBgColors[zoneName];

        //style.backgroundColor = backgroundColor;
        return (<div className='headerCell' key={index} style={style}>{zoneFullName} ({zoneName})</div>);
      });
      // var style1 = { position: '-webkit-sticky', position: 'sticky', top: '0px' };
      // var style = { backgroundColor: '#a9a9a9', minWidth: '60px', position: '-webkit-sticky', position: 'sticky', top: '0px'};

      return (
        <div className='headerRow' >
          <div className='headerCell'>{this.props.sectionName}</div>
          {cols}
        </div>
      );
    };
  }

  class Section extends Component {

    state = {
      sectionChangeFlag: false
    };

    sectionChangeListener = (changeEvent) => {
      this.setState({ sectionChangeFlag: !this.state.sectionChangeFlag });
    };

    render() {
      var zonesColorMap = {};
      this.props.section.zones.map(function (zone) {
        zonesColorMap[zone.displayName] = zone.color;
      });
      var rows = this.props.section.zonedPages.map(function (zonedPage, index) {
        return (<Row sectionsChangeListener={this.props.sectionsChangeListener} key={index} index={index} colorObjects={this.props.colorObjects} zonedPage={zonedPage} zones={this.props.section.zones} actionController={this.props.actionController}
          sectionChangeListener={this.sectionChangeListener} zonesColorMap={zonesColorMap}
          chaseMode={this.props.chaseMode} />);
      }, this);

      return (
        <div className='pagesTable'>
          <HeaderRow colorObjects={this.props.colorObjects} zones={this.props.section.zones}
            actionController={this.props.actionController}
            sectionName={this.props.section.displayName} />
          {rows}
        </div>

      );
    };
  }

  class Sections extends Component {

    state = {
      sectionsChangeFlag: false
    };

    sectionsChangeListener = () => {
      this.setState({ sectionsChangeFlag: !this.state.sectionsChangeFlag });
    };

    render() {
      var that = this;
      var sectionTables = this.props.sections.map(function (section, index) {
        return (<Section key={index} sectionsChangeListener={that.sectionsChangeListener} colorObjects={that.props.colorObjects} section={section} actionController={that.props.actionController} chaseMode={that.props.chaseMode}></Section>);
      });
      return (
        <div>
          {sectionTables}
        </div>
      );
      //return (<h1> Hello, world! I am Ilan.</h1>);
    }
  }

  //var zonesBgColors = {};

  class PagesTable extends Component {
    render() {
      return (
        //<h1> Hello, world! I am Ilan.</h1>
        <Main sections={this.props.sections} actionController={this.props.actionController}
          colorMode={this.props.colorMode} chaseMode={this.props.chaseMode} mapPages={this.props.mapPages}
          colorObjects={this.props.colorObjects} />
      );
    };
  }


  return {
    PagesTable: PagesTable,
    contents: null
  };
});
