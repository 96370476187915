import base from 'base';
import icons from 'core/services/iconService';
import pubsub from 'core/managers/pubsub';
import { getModuleId, getModuleContainerId } from 'utilities/desktop';
import { formatBreadcrumbsCodes, formatBreadcrumbs } from 'utilities/breadcrumbs';
import BaseTabStrip from './BaseTabStrip';

function activateTabHandler(event, tabStripInstance) {
  const moduleId = getModuleId(event.contentElement);
  if (moduleId > 0) {
    pubsub.publish('selected-main-module-changed', moduleId);

    const module = require('core/managers/module').getModuleById(moduleId);
    pubsub.publish('active-selection-changed', { selected: !module ? [] : module.selected, moduleTarget: 'main' });
  }
}

function getSortableOptions(sortableTabGroupId) {
  return {
    filter: `ul.${sortableTabGroupId} li.k-item`,
    axis: "x",
    container: "ul.k-tabstrip-items",
    hint: function (element) {
      const ulAttr = 'class="k-tabstrip-items k-reset" style="margin: 0; padding: 0;"';
      const liAttr = 'class="k-item k-state-default k-tab-on-top k-state-active"';
      return base.dom.createNode(`<ul ${ulAttr}><li ${liAttr}>${element.html()}</li></ul>`);
    },
    start: function (e) {
      const tabStrip = e.sender.element.data('kendoTabStrip');
      tabStrip.activateTab(e.item);
    },
    change: function (e) {
      const tabStrip = e.sender.element.data('kendoTabStrip');
      const reference = tabStrip.tabGroup.children().eq(e.newIndex);

      if (e.oldIndex < e.newIndex) {
        tabStrip.insertAfter(e.item, reference);
      } else {
        tabStrip.insertBefore(e.item, reference);
      }

      require('core/managers/module').saveModulesPreferences();
    }
  };
}

function registerCloseTabHandler(tab) {
  const tabElement = tab.getTabElement();
  base.events.listen(tabElement, 'click', event => {
    if (event.target && event.target.className === 'close') {
      event.preventDefault();
      event.stopImmediatePropagation();
      base.events.unlisten(tabElement, event);

      closeTabHandler.call(this, tab);
    }
  });
}

/**
 * Remove the given tab from the KendoUI tabStrip widget and from the given array of tab instances.
 * After removing the tab stop containing module and publish 'active-selection-changed' message.
 * @param {object} tab - instance of the Tab class
 */
function closeTabHandler(tab) {
  const moduleId = getModuleId(tab.getContentElement());
  require('core/managers/module').stopModule(moduleId);

  this.removeTab(tab);

  if (this.getTabCount() <= 0) {
    pubsub.publish('active-selection-changed', { selected: [], moduleTarget: 'main' });
  }
}

function composeTabTitle(state) {
  let titleHtml = '';
  let text = state.text;
  let tooltip = text;

  if (state.rootType) {
    titleHtml = `<img src="${icons.getTemplateIcon(state.rootType)}"/>`;
  }

  const codesStr = formatBreadcrumbsCodes(state.breadcrumbs);
  if (codesStr) {
    text = codesStr;
    tooltip = formatBreadcrumbs(state.breadcrumbs);
  }

  // Title with text and close button
  titleHtml += `<span class="title">${text}</span><a class="close">&times</a>`;

  return {
    titleHtml,
    tooltip
  };
}

class MainTabStrip extends BaseTabStrip {
  constructor(containerId) {
    super(containerId, { activate: activateTabHandler });

    const sortableTabGroupId = `${containerId}-sortable-tab-group`;
    base.dom.addClass(this.tabStrip.tabGroup[0], sortableTabGroupId);

    const tabStripElement = this.getTabStripElement();
    $(tabStripElement).kendoSortable(getSortableOptions(sortableTabGroupId));
  }

  appendTab(tabState) {
    const tab = super.appendTab(tabState, composeTabTitle);

    registerCloseTabHandler.call(this, tab);

    return tab;
  }

  getActiveModuleContainerId() {
    let containerId = '';

    const activeTab = this.getActiveTab();
    if (activeTab) {
      containerId = getModuleContainerId(activeTab.getContentElement());
    }

    return containerId;
  }

  getModuleIds() {
    return this.getTabs().map(tab => getModuleId(tab.getContentElement()));
  }
}

module.exports = MainTabStrip;