import { translate } from 'core/services/localization';
import requestManager from 'core/managers/request';
import iconsService from 'core/services/iconService';
import toastService from 'core/services/toastService';
import dialogService from 'core/services/dialogService';
import utils from './utils/utils';
import elementBase from './elements/elementBase';
import {createReduxStore} from './redux/storeCreator';

const PLATE_MARGIN_LEFT = 100;
const PLATE_MARGIN_TOP = 100;
const PLATE_MARGIN_RIGHT = 40;
const PLATE_MARGIN_BOTTOM = 40;

const fixDataElementType = (data, layoutType) => {
  if (layoutType === 'plate') {
    data.elementType = 'plate';
  } else if (layoutType === 'layout') {
    data.elementType = data.elementType || 'layout';
  }

  if (data.plate) {
    data.plate.elementType = 'plate';
  }
};

const jsonReplacer = (key, value) => {
  // Exclude the following objects and properties from save:
  // shape: created only for drawing on canvas
  // all temporary properties that are started from '_'
  if (key === 'shape' || key.charAt(0) === '_') {
    return;
  }

  if (typeof value === 'object' && value.otype === 'array') {
    return utils.fromPseudoArray(value);
  }

  return value;
};

export default class Editor {
  constructor(data, settings, canvas) {
    console.log("Editor.constructor() settings.layoutType=", settings.layoutType);

    this.initialData = data;

    this.layoutNwid = data.nwid;
    this.layoutType = settings.layoutType;
    this.module = settings.module;
    this.layoutChanged = settings.layoutChanged;
    this.userUnits = settings.userUnits;
    this.folderNwid = settings.folderNwid;
    this.fonts = settings.fonts;
    this.regmarksOptions = settings.regmarksOptions;
    this.furnitures = settings.furnitures;

    this.canvas = canvas;

    this.init();
  }

  init = () => {
    utils.setUserUnits(this.userUnits);

    fixDataElementType(this.initialData, this.getLayoutType());

    const plateData = this.initialData.plate || this.initialData;
    this.initCanvas(plateData.width, plateData.height);

    this.reduxStore = createReduxStore(this);

    this.savedModelJson = this.layoutChanged ? '' : this.modelToJson(this.buildModel());
  };

  getLayoutType = () => {
    // Original layout types: 'form', 'layout', 'plate', 'ink'
    return this.layoutType === 'form' ? 'layout' : this.layoutType || 'layout';
  };

  /**
   * State history for undo/redo
   */
  history = [];

  /**
   * Current state index in the history array
   */
  historyCurrent = -1;

  savedModelJson = '';

  initCanvas = (plateWidth, plateHeight) => {
    //console.log("=== initCanvas() ===");

    this.canvas.setStartLeft(PLATE_MARGIN_LEFT);
    this.canvas.setStartTop(PLATE_MARGIN_TOP);

    const canvasWidth = utils.systemToCanvasUnits(plateWidth) + PLATE_MARGIN_LEFT + PLATE_MARGIN_RIGHT;
    const canvasHeight = utils.systemToCanvasUnits(plateHeight) + PLATE_MARGIN_TOP + PLATE_MARGIN_BOTTOM;
    this.canvas.setCanvasActualSize(canvasWidth, canvasHeight);
    this.canvas.zoomToFitPlate();
  };

  getStartLeft = () => {
    return this.canvas.getStartLeft();
  };

  getStartTop = () => {
    return this.canvas.getStartTop();
  };

  getMainCanvas = () => {
    return this.canvas.getMainCanvas();
  };

  canvasZoomOut = () => {
    return this.canvas.zoomOut();
  };

  canvasZoomIn = () => {
    return this.canvas.zoomIn();
  };

  scrollToSelectedObject = () => {
    return this.canvas.scrollToSelectedObject();
  };

  getRelativeImageSource = name => {
    return iconsService.getModuleIcon('LayoutEditor', name);
  };

  getInitialData = () => {
    return this.initialData;
  };

  getRootId = () => {
    return this.module.viewSettings.rootId
  };

  getRootType = () => {
    return this.module.viewSettings.rootType
  };

  getFolderNwid = () => {
    return this.folderNwid;
  };

  getViewInstanceNwid = () => {
    return this.module.nwid;
  };

  getLayoutNwid = () => {
    return this.layoutNwid;
  };

  // Note: In redux V4 when the save() method is called from reducer the following exception is thrown
  // 'You may not call store.getState() while the reducer is executing.'
  // when we try to obtain the current state from the our 'reduxStore'.
  // In this case we use the state received as argument
  save = state => {
    let msg = this.validateModel(state);
    if (msg) {
      msg += '\n\n' + translate('Do you still want to save this layout?');
      dialogService.openConfirmDialog(msg, translate('Validation Failed')).then(() => {
        this.saveModel(state);
      });
    } else {
      this.saveModel(state);
    }
  };

  saveModel = state => {
    let result;

    const rootId = this.getRootId();
    const rootType = this.getRootType();
    const layoutType = this.getLayoutType();

    const model = this.buildModel(state);
    model.version = '1.0';
    const modelJson = this.modelToJson(model);
    if (layoutType === 'layout') {
      if (rootType === 'form') {
        result = this.saveFormLayout(rootId, modelJson);
      } else {
        result = this.saveLayout(rootId, modelJson);
      }
    } else if (layoutType === 'plate') {
      result = this.savePlate(rootId, modelJson);
    } else if (layoutType === 'ink') {
      result = this.saveInk(rootId, modelJson);
    }

    this.savedModelJson = modelJson;

    if (result) {
      result.then((resp) => {
          if (resp) {
            toastService.successToast(translate('The data has been successfully saved'));
          } else {
            toastService.errorToast(translate('Save failed'));
          }
        }
      );
    }
  };

  saveFormLayout = (rootId, modelJson) => {
    let params = {
      command: 'saveLayout',
      nwid: rootId,
      type: 'form',
      layout: modelJson
    };

    return requestManager.genericRequestPromise(params);
  };

  saveLayout = (rootId, modelJson) => {
    let params = {
      action: 'saveLayout',
      command: 'getLayouManagerActions',
      rootId: rootId,
      layout: modelJson
    };

    return requestManager.genericRequestPromise(params);
  };

  savePlate = (rootId, modelJson) => {
    let params = {
      action: 'savePlate',
      command: 'getLayouManagerActions',
      rootId: rootId,
      plate: modelJson
    };

    return requestManager.genericRequestPromise(params);
  };

  saveInk = (rootId, modelJson) => {
    let params = {
      action: 'saveInk',
      command: 'getLayouManagerActions',
      rootId: rootId,
      ink: modelJson
    };

    return requestManager.genericRequestPromise(params);
  };

  modelToJson = model => {
    return JSON.stringify(model, jsonReplacer);
  };

  isModelChanged = () => {
    const currentModel = this.buildModel();
    return !currentModel ? false : this.savedModelJson !== this.modelToJson(currentModel);
  };

  getAvailableFonts = () => {
    return this.fonts || [];
  };

  findFontByName = fontName => {
    const fonts = this.getAvailableFonts();
    const font = fonts.filter(item => item.name === fontName)[0];

    return font;
  };

  canUndo = () => {
    return this.historyCurrent > 0;
  };

  canRedo = () => {
    return this.historyCurrent < this.history.length - 1;
  };

  canZoomIn = () => {
    return this.canvas.canZoomIn();
  };

  canZoomOut = () => {
    return this.canvas.canZoomOut();
  };

  getReduxStore = () => {
    return this.reduxStore;
  };

  buildModel = state => {
    if (!state) {
      state = this.reduxStore.getState();
    }

    return state.layout;
  };

  validateModel = (state) => {
    if (!state) {
      state = this.reduxStore.getState();
    }

    if (!state.layout || !state.layout.cellGrid) {
      return '';
    }

    const msg = elementBase(this).validate(state, state.layout.cellGrid);

    return msg;
  };

  reloadFurnitures = () => {
    return this.module.reloadFurnitures()
      .then(furnitures => {
        if (Array.isArray(furnitures)) {
          this.furnitures = furnitures;
        }

        return this.furnitures;
      });
  };
}
