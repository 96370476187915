/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define(['sandbox', 'ember'],
  function (sandbox, Ember) {
    'use strict';

    function ProductionRunModel(model) {
      if (model === undefined || model.id === undefined || model.nwid === undefined) return undefined;
      return Ember.Object.create({
        id: model.id,
        nwid: model.nwid,
        type: model.type || 'productionrun',
        deadline: model.deadline,
        deadlinePassed: model.deadlinePassed || false,
        aggregate: undefined,
        '_model': model
      });
    }

    return ProductionRunModel;
  });
