/**
 * @name UploadView
 * @file Upload View entry point
 *
 * @author Boris
 * @since: 2017-02-19
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import jsutils from 'base/jsUtils';
import Upload from './components/Upload';
import { convertUploadDate } from './utils';
import TickableModel from '../TickableModel';
import AbstractModule from 'AbstractModule';
import { createObjectComparator } from 'core/comparators';

const THROTTLE_WAIT = 1000;

const getFilesToRemove = (updates, model) => {
  const updatesToRemove = updates.filter(update => update.action === 'Remove' && update.childPropertyId === 'uploadedfile');
  return updatesToRemove.reduce((acc, update) => {
    update.ids.forEach(id => {
      const file = model.unplannedFiles.find(file => file.id === id);
      if (file) {
        acc.push({ ...file, status: 'DroppedPage' });
      }
    });
    return acc;
  }, []);
};

module.exports = AbstractModule.extend({

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.updates = [];
    this.filesToRemove = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  firstTickReceived: function (data) {
    //console.log('###firstTickReceived=>', data);

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    const folder = model.children[0];
    if (!folder) {
      return;
    }

    const publications = (folder.planSetup.publications || []).sort(createObjectComparator('name'));

    const unplannedFiles = folder.uploadedfile || [];
    convertUploadDate(unplannedFiles);
    unplannedFiles.forEach(f => {
      f.uploadedSize = parseInt(f.size);
      f.timestamp = f.nwid;
      if (f.targetObject) {
        f.expectedName = f.targetObject.name;
      }
    });
    const actionConfig = this.getActionConfig() || {};

    this.initialModel = model;
    this.viewModel = {
      folderNwid: folder.nwid,
      viewNwid: this.nwid,
      viewLinks: folder.viewLinks || [],
      publications,
      unplannedFiles: [...unplannedFiles, ...this.filesToRemove],
      hotFolder: actionConfig.hotFolder,
      autoTurnOn: actionConfig.autoTurnOn,
      filesFromTickUpdate: [],
      module: this
    };

    this.render();
  },

  tickUpdate: function (data) {
    //console.log('###tickUpdate=> data.model=', data.model);

    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.filesToRemove = getFilesToRemove(this.updates, this.viewModel);
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  getActionConfig: function () {
    const action = this.viewActions.find((a) => a.actionDefinitionName === 'UploadActionCR' && a.nwid);
    if (!action || !action.config) {
      console.warn('UploadView.getActionConfig() => Action config section was not found');
      return;
    }

    return action.config;
  },

  fileToPage: function (item) {
    if (item.targetObject && item.targetObject.nwid) {
      return {
        nwid: item.targetObject.nwid,
        type: item.targetObject.type,
        viewLinks: item.targetObject.viewLinks,
        actionLinks: item.actionLinks
      };
    } else {
      return {
        nwid: item.nwid,
        type: item.type,
        actionLinks: item.actionLinks
      };
    }
  },

  updateUploadTabTitle: function (fileCount) {
    this.tab.setState({
      itemCount: fileCount
    });
  },

  filterViewLinks: function (viewClass) {
    return this.viewModel.viewLinks.filter((link) => link.indexOf(`viewClass=${viewClass}`) > 0);
  },

  extractViewNwid: function (viewClass) {
    const link = this.viewModel.viewLinks.find((link) => link.indexOf(`viewClass=${viewClass}`) > 0);
    if (!link) {
      return;
    }

    return link.match(/view:\/\/(\d+)\?/)[1];
  },

  handleContextMenu: function (e, clickedFile, selectedFiles) {
    //console.log('###UploadView.handleContextMenu() ===', e, clickedFile, selectedFiles);
    if (clickedFile && selectedFiles && selectedFiles.length > 0) {
      const clickedPage = this.fileToPage(clickedFile);
      const selectedPages = selectedFiles.map(f => this.fileToPage(f));
      this.showContextMenu(clickedPage, selectedPages, e);
    }
  },

  handleDeleteFiles: function (e, files) {
    //console.log('###UploadView.handleDeleteFiles() ===', e, files);
    const action = this.viewActions.find((a) => a.actionDefinitionName === 'DeleteActionCR');
    if (!action || !files || files.length <= 0) {
      return;
    }

    const pages = files.map(f => this.fileToPage(f));
    action.execute(pages);
  },

  handleFileCountChange: function (fileCount) {
    //console.log('###UploadView.handleFileCountChange() => fileCount=', fileCount);
    this.updateUploadTabTitle(fileCount);
  },

  handleHistoryButtonClick: function () {
    const action = this.viewActions.find((a) => a.actionDefinitionName === 'OpenUploadHistoryCR' && a.nwid);
    if (!action) {
      return;
    }

    action.execute([this.initialModel]);
  },

  handleUploadPageDoubleClick(page) {
    this.navigateByViewLink(this.fileToPage(page));
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    //console.log('###UploadView.render() ===');
    this.reactRoot.render(
      <Upload
        model={this.viewModel}
        onContextMenu={(e, file, files) => this.handleContextMenu(e, file, files)}
        onFileCountChange={fileCount => this.handleFileCountChange(fileCount)}
        onHistoryButtonClick={() => this.handleHistoryButtonClick()}
        onDeleteFiles={(e, files) => this.handleDeleteFiles(e, files)}
        onDoubleClick={(file) => this.handleUploadPageDoubleClick(file)}
      />);
  }

});
