import sandbox from 'sandbox';
import { fromServerDate, getMaxDate } from 'core/dates';
import { getMainStatus } from 'utilities/statuses';

const date_max_value = getMaxDate();
const status_types_order = [
  'NotAssigned', 'Regular', 'Waiting', 'Success', 'Error'
];

function getPropertyRecursive(obj, propertyName) {
  if (typeof obj[propertyName] !== "undefined") {
    return obj[propertyName];
  }
  for (var key in obj) {
    if (typeof obj[key] === 'object') {
      var ret = getPropertyRecursive(obj[key], propertyName);
      if (typeof ret !== "undefined") {
        return ret;
      }
    }
  }
}
function getObjToCompare(obj, propertyName) {
  if (!propertyName) {
    return obj;
  } else {
    return getPropertyRecursive(obj, propertyName);
  }
}

function compareObjects(obj1, obj2, ascending) {
  if (obj1 === obj2) {
    return 0;
  }
  if (ascending) {
    if (obj1 < obj2) {
      return -1;
    } else {
      return 1;
    }

  } else {
    if (obj1 < obj2) {
      return 1;
    } else {
      return -1;
    }
  }
}

function getStatusTimeSortingValue(status) {
  const statusType = status.statusType;
  const progress = status.progress;

  if (statusType === 'Success' && progress === '100') return status_types_order.indexOf('Success');
  if (statusType === 'Error') return status_types_order.indexOf('Error');
  if (statusType === 'NotAssigned') return status_types_order.indexOf('NotAssigned');
  return status_types_order.indexOf('Regular');
}

function getFinishedTimeSortingValue(status) {
  const statusType = status.statusType;

  if (statusType === 'Success' || statusType === 'Error') return 1;
  return 0;
}

function comparator(sortType, propertyName) {
  return function (viewtype, order) {
    if (order === 'desc') return sandbox.sorting.getComparator(sortType + 'decending', propertyName);
    return sandbox.sorting.getComparator(sortType, propertyName);
  };
}

function separationComparator() {
  return function (viewType, order) {
    return function (obj1, obj2) {
      const separation1 = Array.isArray(obj1.separations) ? obj1.separations.length : obj1.separation;
      const separation2 = Array.isArray(obj2.separations) ? obj2.separations.length : obj2.separation;

      return compareObjects(separation1, separation2, order === 'desc' ? false : true);
    };
  };
}

function flowstpeComparator() {
  function isValidFlowstep(status) {
    return typeof status.statusType !== 'undefined' && status.statusType !== 'NotAssigned' && typeof status.time !== 'undefined';
  }
  function getFlowstepValue(obj = {}) {
    const status = getMainStatus(obj) || {};
    const step = isValidFlowstep(status) ? status.flowStepType : undefined;
    const iconName = typeof step !== 'undefined' ? status.flowStepIconName : undefined;

    return typeof step === 'undefined' ? '' : [step, iconName].join(',');
  }
  return function (viewType, order) {
    return function (obj1, obj2) {
      const flowStep1 = getFlowstepValue(obj1);
      const flowStep2 = getFlowstepValue(obj2);

      return compareObjects(flowStep1, flowStep2, order === 'desc' ? false : true);
    };
  };
}

function statusComparator() {
  function isValidStatus(status) {
    return typeof status.time !== 'undefined' && status.statusType !== "NotAssigned";
  }
  function getStatusType(obj = {}) {
    const status = getMainStatus(obj) || {};
    const statusType = isValidStatus(status) ? status_types_order.indexOf(status.statusType) : -1;

    return statusType;
  }
  return function (viewType, order) {
    return function (obj1, obj2) {
      const statusType1 = getStatusType(obj1);
      const statusType2 = getStatusType(obj2);

      return compareObjects(statusType1, statusType2, order === 'desc' ? false : true);
    };
  };
}

function startTimeComparator() {
  return function (viewType, order) {
    const empty_date = date_max_value;
    return function (obj1, obj2) {
      const status1 = getMainStatus(obj1);
      const status2 = getMainStatus(obj2);
      const startTime1 = status1?.startTime === '' ? empty_date : fromServerDate(getObjToCompare(status1, 'startTime'));
      const startTime2 = status2?.startTime === '' ? empty_date : fromServerDate(getObjToCompare(status2, 'startTime'));

      return compareObjects(startTime1, startTime2, order === 'desc' ? false : true);
    }
  };
}

function statusTimeComparator() {
  return function (viewType, order) {
    const empty_date = date_max_value;
    return function (obj1, obj2) {
      const status1 = getMainStatus(obj1);
      const status2 = getMainStatus(obj2);
      const time1 = status1?.time === '' ? empty_date : fromServerDate(getObjToCompare(status1, 'time'));
      const time2 = status2?.time === '' ? empty_date : fromServerDate(getObjToCompare(status2, 'time'));
      const statusTime1 = getStatusTimeSortingValue(status1) && time1;
      const statusTime2 = getStatusTimeSortingValue(status2) && time2;

      return compareObjects(statusTime1, statusTime2, order === 'desc' ? false : true);
    }
  };
}

function finishedTimeComparator() {
  return function (viewType, order) {
    const empty_date = date_max_value;
    return function (obj1, obj2) {
      const status1 = getMainStatus(obj1);
      const status2 = getMainStatus(obj2);
      const time1 = status1?.time === '' ? empty_date : fromServerDate(getObjToCompare(status1, 'time'));
      const time2 = status2?.time === '' ? empty_date : fromServerDate(getObjToCompare(status2, 'time'));
      const finishedTime1 = getFinishedTimeSortingValue(status1) && time1;
      const finishedTime2 = getFinishedTimeSortingValue(status2) && time2;

      return compareObjects(finishedTime1, finishedTime2, order === 'desc' ? false : true);
    };
  };
}

function durationComparator() {
  return function (viewType, order) {
    const empty_date = date_max_value;
    return function (obj1, obj2) {
      const status1 = getMainStatus(obj1);
      const status2 = getMainStatus(obj2);
      const time1 = status1?.time === '' ? empty_date : fromServerDate(getObjToCompare(status1, 'duration'));
      const time2 = status2?.time === '' ? empty_date : fromServerDate(getObjToCompare(status2, 'duration'));
      const finishedTime1 = getStatusTimeSortingValue(status1) && time1;
      const finishedTime2 = getStatusTimeSortingValue(status2) && time2;

      return compareObjects(finishedTime1, finishedTime2, order === 'desc' ? false : true);
    };
  };
}

function resourceComparator() {
  return function (viewType, order) {
    return function (obj1, obj2) {
      const obj1Status = getMainStatus(obj1);
      const obj2Status = getMainStatus(obj2);
      const obj1ParentStatus = getMainStatus(obj1.parent);
      const obj2ParentStatus = getMainStatus(obj2.parent);
      const status1 = typeof obj1Status !== 'undefined' ? obj1Status : typeof obj1.parent !== 'undefined' && typeof obj1ParentStatus !== 'undefined' ? obj1ParentStatus : {};
      const status2 = typeof obj2Status !== 'undefined' ? obj2Status : typeof obj2.parent !== 'undefined' && typeof obj2ParentStatus !== 'undefined' ? obj2ParentStatus : {};
      const resource1 = status1.resource || '';
      const resource2 = status2.resource || '';

      return compareObjects(resource1, resource2, order === 'desc' ? false : true);
    };
  };
}

function isActualComparator() {
  return function (viewType, order) {
    return function (obj1, obj2) {
      const isActual1 = obj1.isActual;
      const isActual2 = obj2.isActual;

      return compareObjects(isActual1, isActual2, order === 'desc' ? false : true);
    };
  };
}


export default {
  date: comparator('date', 'date'),
  publication: comparator('alpha', 'publication'),
  edition: comparator('alpha', 'edition'),
  zone: comparator('alpha', 'zone'),
  section: comparator('alpha', 'sectionName'),
  book: comparator('alpha', 'bookName'),
  layout: comparator('alpha', 'impositionName'),
  layoutgroup: comparator('alpha', 'layoutGroupName'),
  number: comparator('numeric', 'number'),
  physicalNumber: comparator('numeric', 'physicalNumber'),
  filename: comparator('alpha', 'expectedName'),
  inputFileName: comparator('alpha', 'mainVersionInputFileName'),
  separation: separationComparator(),
  flowstep: flowstpeComparator(),//  comparator('alpha', 'flowStepIconName'),
  status: statusComparator(),
  holdType: comparator('alpha', 'holdType'),
  starttime: startTimeComparator(),
  statustime: statusTimeComparator(),
  finishedtime: finishedTimeComparator(),
  duration: durationComparator(),
  resource: resourceComparator(),
  name: comparator('alpha', 'name'),
  pagename: comparator('alpha', 'pageName'),
  form: comparator('alpha', 'form'),
  cylinderName: comparator('alpha', 'cylinderName'),
  cylinderSector: comparator('alpha', 'cylinderSector'),
  locationOnCylinder: comparator('alpha', 'locationOnCylinder'),
  towerName: comparator('alpha', 'towerName'),
  pressName: comparator('alpha', 'pressName'),
  isActual: isActualComparator(),
  groupName: comparator('alpha', 'groupName'),
  groupOrder: comparator('numeric', 'groupOrder'),
  bin: comparator('alpha', 'bin'),
  fanout: comparator('alpha', 'fanout')
};