/**
 * @name Canvas
 * @file React component that encapsulates Fabric canvas functionality
 *
 * @author Boris
 * @since: 2022-02-08
 */

import React, { useEffect, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Fabric from 'fabric';
import iconService from 'core/services/iconService';
import rulers from './rulers';
import utils from '../utils/utils';
import actions from "../redux/actions";

const ZOOM_VALUES = [0.25, 0.5, 0.75, 1, 1.5, 2];
const SCROLLBAR_THICKNESS = 20;
const RULER_THICKNESS = 18; // pixels

const createRulerCanvas = (canvasNode) => {
  return new Fabric.StaticCanvas(canvasNode, {
    width: 1,
    height: 1,
    renderOnAddRemove: false, // do not render canvas after add/remove object
    stateful: false, // do not save object state
    preserveObjectStacking: true, // do not bring the selected object to the front
    selection: false // disable group selection
  });
};

const createMainCanvas = (canvasNode) => {
  return new Fabric.Canvas(canvasNode, {
    backgroundColor: { source: iconService.getModuleIcon('LayoutEditor', 'transp_bg') },
    width: 1,
    height: 1,
    renderOnAddRemove: false, // do not render canvas after add/remove object
    //stateful: false, // do not save object state
    preserveObjectStacking: true, // do not bring the selected object to the front
    selection: false, // disable group selection
    controlsAboveOverlay: true // draw controls on an active object always on top
  });
};

const Canvas = forwardRef(({store}, ref) => {

  const canvasContainerNodeRef = useRef(null);
  const hrulerCanvasNodeRef = useRef(null);
  const vrulerCanvasNodeRef = useRef(null);
  const mainCanvasContainerNodeRef = useRef(null);
  const canvasAreaScrollerNodeRef = useRef(null);
  const canvasAreaNodeRef = useRef(null);
  const mainCanvasNodeRef = useRef(null);

  const mainCanvasRef = useRef(null);
  const rulersRef = useRef(null);
  const mouseDownTargetRef = useRef('');
  const canvasShapeToMoveRef = useRef(null);

  const storeRef = useRef(null);
  storeRef.current = store;

  // Left starting point of the top ruler in pixels
  const startLeftRef = useRef(0);

  // Top starting point of the left ruler in pixels
  const startTopRef = useRef(0);

  // Canvas actual (100% zoom) width in pixels
  const actualWidthRef = useRef(0);

  // Canvas actual (100% zoom) height in pixels
  const actualHeightRef = useRef(0);

  useImperativeHandle(ref, () => ({
    getMainCanvas,
    getStartLeft,
    setStartLeft,
    getStartTop,
    setStartTop,
    setCanvasActualSize,
    zoomToFitPlate,
    zoomIn,
    zoomOut,
    canZoomIn,
    canZoomOut,
    updateCanvasSize,
    scrollToSelectedObject,
  }));

  useEffect(() => {
    console.log('Canvas.useEffect() == 1 == ref=>', ref);

    const hrulerCanvasNode = hrulerCanvasNodeRef.current;
    const vrulerCanvasNode = vrulerCanvasNodeRef.current;
    rulersRef.current = rulers(createRulerCanvas(hrulerCanvasNode), createRulerCanvas(vrulerCanvasNode));
    mainCanvasRef.current = createMainCanvas(mainCanvasNodeRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Canvas.useEffect() == 2 == ref=>', ref);

    let unregisterListeners;
    if (store) {
      unregisterListeners = registerListeners();
    }

    return unregisterListeners;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  useEffect(() => {
    console.log('Canvas.useEffect() == 3 == ref=>', ref);

    mainCanvasRef.current?.renderAll();
  });

  const registerListeners = () => {
    console.log('Canvas.registerListeners() == 0 ==');

    const hrulerCanvasNode = hrulerCanvasNodeRef.current;
    const vrulerCanvasNode = vrulerCanvasNodeRef.current;
    const scrollerNode = getCanvasAreaScrollerNode();
    const canvasNode = getUpperCanvasNode();

    scrollerNode.addEventListener('scroll', handleCanvasScroll);
    canvasNode.addEventListener('wheel', handleMouseWheel);
    canvasEventsOn(mainCanvasRef.current);

    const canvasContainerNode = canvasContainerNodeRef.current;
    const doc = canvasContainerNode.ownerDocument;
    doc.addEventListener('mouseup', handleMouseUp);
    doc.addEventListener('keydown', handleUndoRedoKeyDown);
    canvasContainerNode.addEventListener('keydown', handleKeyboardEventsOnCanvas);
    hrulerCanvasNode.addEventListener('mousedown', handleHRulerMouseDown);
    vrulerCanvasNode.addEventListener('mousedown', handleVRulerMouseDown);

    return () => {
      scrollerNode.removeEventListener('scroll', handleCanvasScroll);
      canvasNode.removeEventListener('wheel', handleMouseWheel);
      canvasEventsOff(mainCanvasRef.current);
      doc.removeEventListener('mouseup', handleMouseUp);
      doc.removeEventListener('keydown', handleUndoRedoKeyDown);
      canvasContainerNode.removeEventListener('keydown', handleKeyboardEventsOnCanvas);
      hrulerCanvasNode.removeEventListener('mousedown', handleHRulerMouseDown);
      vrulerCanvasNode.removeEventListener('mousedown', handleVRulerMouseDown);
    };
  };

  const getStartLeft = () => {
    return startLeftRef.current;
  };

  const setStartLeft = (value) => {
    startLeftRef.current = value;
  };

  const getStartTop = () => {
    return startTopRef.current;
  };

  const setStartTop = (value) => {
    startTopRef.current = value;
  };

  const getActualWidth = () => {
    return actualWidthRef.current;
  };

  const getActualHeight = () => {
    return actualHeightRef.current;
  };

  const setCanvasActualSize = (width, height) => {
    actualWidthRef.current = width > 0 ? width : 0;
    actualHeightRef.current = height > 0 ? height : 0;
  };

  const getCanvasAreaScrollerNode = () => {
    return canvasAreaScrollerNodeRef.current;
  };

  const getUpperCanvasNode = () => {
    return mainCanvasContainerNodeRef.current.querySelector('.upper-canvas');
  };

  const getCanvasArea = () => {
    return canvasAreaNodeRef.current;
  };

  const scrollToSelectedObject = () => {
    const activeObject = mainCanvasRef.current.getActiveObject();
    if (!activeObject) {
      return;
    }

    const scroller = getCanvasAreaScrollerNode();
    const hasVScrollBar = scroller.offsetWidth > scroller.clientWidth;
    const hasHScrollBar = scroller.offsetHeight > scroller.clientHeight;
    if (hasVScrollBar || hasHScrollBar) {
      const zoom = getCanvasZoom();
      const boundingBox = utils.toNormalizedRectangle(activeObject, activeObject.originX, activeObject.originY, activeObject.angle);
      if (hasHScrollBar) {
        scroller.scrollLeft = zoom * (boundingBox.left - getStartLeft());
      }
      if (hasVScrollBar) {
        scroller.scrollTop = zoom * (boundingBox.top - getStartTop());
      }
    }
  };

  const handleCanvasScroll = useCallback(event => {
    mainCanvasRef.current.absolutePan({
      x: event.target.scrollLeft,
      y: event.target.scrollTop
    });

    updateRulers();
  }, [store]);

  const handleMouseWheel = useCallback(event => {
    const scroller = getCanvasAreaScrollerNode();
    scroller.scrollLeft += event.deltaX;
    scroller.scrollTop += event.deltaY;
  }, [store]);

  const canvasEventsOn = useCallback(canvas => {
    canvas.on('selection:cleared', selectionCleared);
    canvas.on('object:selected', shapeSelected);
    canvas.on('object:moving', shapeTransforming);
    canvas.on('object:scaling', shapeTransforming);
    canvas.on('object:modified', shapeModified);
    canvas.on('mouse:down', shapeMouseDown);
    canvas.on('mouse:up', shapeMouseUp);
    canvas.on('mouse:move', mouseMove);
    canvas.on('text:changed', textChanged);
    canvas.on('text:editing:exited', textEditingExited);
  }, [store]);

  const canvasEventsOff = useCallback(canvas => {
    canvas.off('selection:cleared', selectionCleared);
    canvas.off('object:selected', shapeSelected);
    canvas.off('object:moving', shapeTransforming);
    canvas.off('object:scaling', shapeTransforming);
    canvas.off('object:modified', shapeModified);
    canvas.off('mouse:down', shapeMouseDown);
    canvas.off('mouse:up', shapeMouseUp);
    canvas.off('mouse:move', mouseMove);
    canvas.off('text:changed', textChanged);
    canvas.off('text:editing:exited', textEditingExited);
  }, [store]);

  const dispatchShapeSelected = useCallback(shape => {
    // handle onBlur event before selecting/unselecting canvas shape
    setTimeout(function () {
      store.dispatch(actions.shapeSelected(shape));
    }, 0);
  }, [store]);


  const selectionCleared = useCallback(() => {
    //console.log("selectionCleared() => options=", options);
    dispatchShapeSelected();
  }, [store]);

  const shapeSelected = useCallback(options => {
    //console.log("shapeSelected() => shape=", options.target.shape);
    if (options.target?.shape) {
      dispatchShapeSelected(options.target.shape);
    }
  }, [store]);

  const shapeTransforming = useCallback(options => {
    if (options.target?.shape) {
      //console.log("shapeTransforming() => shape=", options.target.shape);
      store.dispatch(actions.shapeTransforming(options.target, options.e));
    }
  }, [store]);

  const shapeModified = useCallback(options => {
    //console.log("shapeModified() => options=", options);
    if (options.target?.shape) {
      //console.log("shapeModified() => shape=", options.target.shape);
      store.dispatch(actions.shapeModified(options.target));
    }
  }, [store]);

  const shapeMouseDown = useCallback(options => {
    if (options.target?.shape) {
      // handle onBlur event before mouse down event
      setTimeout(function () {
        //console.log("shapeMouseDown() => shape=", options.target.shape);
        store.dispatch(actions.shapeMouseDown(options.target, options.e));
      }, 0);
    }
  }, [store]);

  const shapeMouseUp = useCallback(options => {
    //console.log("shapeMouseUp() => options=", options);
    if (options.target?.shape) {
      setTimeout(function () {
        //console.log("shapeMouseUp() => shape=", options.target.shape);
        store.dispatch(actions.shapeMouseUp(options.target, options.e));
      }, 0);
    }
  }, [store]);

  const mouseMove = useCallback(options => {
    //console.log("mouseMove() => options.e =", options.e);
    const event = options.e;
    if (event.buttons === 1 && mouseDownTargetRef.current) {
      const orientation = mouseDownTargetRef.current === 'hruler' ? 'horizontal' :
        mouseDownTargetRef.current === 'vruler' ? 'vertical' : '';
      if (orientation) {
        if (canvasShapeToMoveRef.current) {
          store.dispatch(actions.moveShape(canvasShapeToMoveRef.current, event));
        } else {
          store.dispatch(actions.createGuideline(orientation, event));
          canvasShapeToMoveRef.current = getMainCanvas().getActiveObject();
        }
      }
    }

    // TODO: uncomment the next line after implementing full support for ruler guides
    //store.dispatch(actions.mouseMove(event));
  }, [store]);

  const textChanged = useCallback(options => {
    if (options.target?.shape) {
      //console.log("textChanged() => shape=", options.target.shape);
      store.dispatch(actions.shapeTextEvent(options.target, 'text:changed'));
    }
  }, [store]);

  const textEditingExited = useCallback(options => {
    if (options.target?.shape) {
      //console.log("textEditingExited() => shape=", options.target.shape);

      // Set timeout to avoid this error: 'Reducers may not dispatch actions'
      setTimeout(function () {
        store.dispatch(actions.shapeTextEvent(options.target, 'text:editing:exited'));
      }, 0);
    }
  }, [store]);

  const handleHRulerMouseDown = useCallback(() => {
    mouseDownTargetRef.current = 'hruler';
  }, [store]);

  const handleVRulerMouseDown = useCallback(() => {
    mouseDownTargetRef.current = 'vruler';
  }, [store]);

  const handleMouseUp = useCallback(event => {
    //console.log("###handleMouseUp(): event=", event);
    if (canvasShapeToMoveRef.current) {
      store.dispatch(actions.shapeMouseUp(canvasShapeToMoveRef.current, event, true));
    }

    mouseDownTargetRef.current = '';
    canvasShapeToMoveRef.current = null;
  }, [store]);

  const handleUndoRedoKeyDown = useCallback(event => {
    //console.log("###handleUndoRedoKeyDown(): event.code=" + event.code + ", event.target.tagName=" + event.target.tagName);
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      return;
    }

    switch (event.code) {
      case 'KeyZ':
        if (event.ctrlKey || event.metaKey) {
          event.shiftKey ? store.dispatch(actions.redo()) : store.dispatch(actions.undo());
        }
        break;
      case 'KeyY':
        if (event.ctrlKey || event.metaKey) {
          store.dispatch(actions.redo());
        }
        break;
    }
  }, [store]);

  const handleKeyboardEventsOnCanvas = useCallback(event => {
    //console.log("###handleKeyboardEventsOnCanvas(): event.code=" + event.code);
    switch (event.code) {
      case 'ArrowLeft':
        store.dispatch(actions.nudgeShape('left'));
        event.preventDefault();
        break;
      case 'ArrowUp':
        store.dispatch(actions.nudgeShape('up'));
        event.preventDefault();
        break;
      case 'ArrowRight':
        store.dispatch(actions.nudgeShape('right'));
        event.preventDefault();
        break;
      case 'ArrowDown':
        store.dispatch(actions.nudgeShape('down'));
        event.preventDefault();
        break;
      case 'Delete':
        store.dispatch(actions.deleteSelectedElement());
        break;
    }

  }, [store]);

  const getCanvasZoom = () => {
    return mainCanvasRef.current?.getZoom() || 0;
  };

  const setCanvasZoom = zoom => {
    const actualWidth = getActualWidth();
    const actualHeight = getActualHeight();
    if (zoom <= 0 || actualWidth <= 0 || actualHeight <= 0) {
      return;
    }

    const width = zoom * actualWidth;
    const height = zoom * actualHeight;

    const canvasArea = getCanvasArea();
    canvasArea.style.width = width + 'px';
    canvasArea.style.height = height + 'px';

    if (mainCanvasRef.current && width > 0 && height > 0) {
      mainCanvasRef.current.setZoom(zoom);
    }

    updateCanvasSize();
  };

  const updateCanvasSize = () => {
    if (getActualWidth() <= 0 || getActualHeight() <= 0) {
      return;
    }

    const scroller = getCanvasAreaScrollerNode();
    const canvasArea = getCanvasArea();

    // workaround for Chrome browser bug: client width or height is not recalculated when scrollbar is added
    let scrollX = canvasArea.offsetWidth > scroller.offsetWidth;
    let scrollY = canvasArea.offsetHeight > scroller.offsetHeight;
    if (!scrollX && scrollY) {
      scrollX = canvasArea.offsetWidth > scroller.offsetWidth - SCROLLBAR_THICKNESS;
    } else if (!scrollY && scrollX) {
      scrollY = canvasArea.offsetHeight > scroller.offsetHeight - SCROLLBAR_THICKNESS;
    }
    scroller.style.overflowX = scrollX ? 'scroll' : 'hidden';
    scroller.style.overflowY = scrollY ? 'scroll' : 'hidden';

    doUpdateCanvasSize();
  };


  const doUpdateCanvasSize = () => {
    const scroller = getCanvasAreaScrollerNode();
    const width = scroller.clientWidth;
    const height = scroller.clientHeight;

    if (width > 0 && height > 0) {
      mainCanvasRef.current.setDimensions({ width, height });
      updateRulers();
    }
  };

  const updateRulers = () => {
    const scroller = getCanvasAreaScrollerNode();
    const width = scroller.clientWidth;
    const height = scroller.clientHeight;

    if (width > 0 && height > 0) {
      const zoom = getCanvasZoom();
      const left = RULER_THICKNESS + zoom * getStartLeft() - scroller.scrollLeft;
      rulersRef.current.updateHRuler(RULER_THICKNESS + width, RULER_THICKNESS, left, zoom);

      const top = RULER_THICKNESS + zoom * getStartTop() - scroller.scrollTop;
      rulersRef.current.updateVRuler(RULER_THICKNESS + height, RULER_THICKNESS, top, zoom);
    }
  };

  const zoomIn = () => {
    const zoom = calcNextZoomIn();

    setCanvasZoom(zoom);

    return getCanvasZoom();
  };

  const calcNextZoomIn = () => {
    const currentZoom = getCanvasZoom();
    let zoom = 0;
    for (let i = 0; i < ZOOM_VALUES.length; i++) {
      if (currentZoom < ZOOM_VALUES[i]) {
        zoom = ZOOM_VALUES[i];
        break;
      }
    }

    const zoomToFit = calcZoomToFitPlate();
    if (currentZoom < zoomToFit && zoomToFit < zoom) {
      zoom = zoomToFit;
    }

    return zoom;
  };

  const canZoomIn = () => {
    const zoom = calcNextZoomIn();

    return zoom > getCanvasZoom();
  };

  const zoomOut = () => {
    const zoom = calcNextZoomOut();

    setCanvasZoom(zoom);

    return getCanvasZoom();
  };

  const calcNextZoomOut = () => {
    const currentZoom = getCanvasZoom();
    let zoom = 0;
    for (let i = ZOOM_VALUES.length - 1; i >= 0; i--) {
      if (currentZoom > ZOOM_VALUES[i]) {
        zoom = ZOOM_VALUES[i];
        break;
      }
    }

    const zoomToFit = calcZoomToFitPlate();
    if (currentZoom > zoomToFit && zoomToFit > zoom) {
      zoom = zoomToFit;
    } else if (zoom < zoomToFit) {
      zoom = 0;
    }

    return zoom;
  };

  const canZoomOut = () => {
    const zoom = calcNextZoomOut();

    return zoom > 0 && zoom < getCanvasZoom();
  };

  const zoomToFitPlate = () => {
    const zoom = calcZoomToFitPlate();

    setCanvasZoom(zoom);
  };

  const calcZoomToFitPlate = () => {
    let zoom = 0;
    const scroller = getCanvasAreaScrollerNode();
    const actualWidth = getActualWidth();
    const actualHeight = getActualHeight();
    if (actualWidth > 0 && actualHeight > 0 && scroller) {
      zoom = Math.min(scroller.offsetWidth / actualWidth, scroller.offsetHeight / actualHeight);
    }

    return zoom;
  };

  const getMainCanvas = () => {
    return mainCanvasRef.current;
  };

  return (
    <div ref={canvasContainerNodeRef} tabIndex='0' className='editor-canvas-view'>
      <div className='hruler'>
        <canvas ref={hrulerCanvasNodeRef} />
      </div>
      <div className='vruler'>
        <canvas ref={vrulerCanvasNodeRef} />
      </div>
      <div ref={mainCanvasContainerNodeRef} className='main-canvas-container'>
        <div ref={canvasAreaScrollerNodeRef} className='canvas-area-scroller'>
          <div ref={canvasAreaNodeRef} className='canvas-area'>
          </div>
        </div>
        <div>
          <canvas ref={mainCanvasNodeRef} id='mainCanvas' />
        </div>
      </div>
    </div>
  );
});

Canvas.propTypes = {
  store: PropTypes.any,
};

export default Canvas;