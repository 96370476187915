import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers, filters } from 'widgets/ReactDataGrid';
import { FILTER_TYPE, ROW_HEIGHT, HEADER_HEIGHT, HEADER_HEIGHT_WITH_FILTER, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';
import { defaultShouldCellUpdate } from './columnsCreator';
const { GenericHeader, HeaderCaption } = headers;
const { TextInputFilter, DateFilter, MultiselectFilter } = filters;

const MIN_COLUMN_WIDTH = 20;
const getRowHeight = unplanTableColumns => {
  const thumbnailColumn = unplanTableColumns.find(col => col.key === 'thumbnail');
  return thumbnailColumn.visible ? ROW_HEIGHT_INCLUDING_THUMBNAIL : ROW_HEIGHT;
};


export default function View({ module, viewModel, fragmentsTableColumns = [] }) {
  const [rowHeight, setRowHeight] = useState(getRowHeight(fragmentsTableColumns));
  const renderFragmentColumnFilter = column => {

    const { filter } = column;

    if (!filter) {
      return;
    }

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:

        const selectedValues = Array.isArray(filter.selected) && filter.selected || [];

        return (
          <MultiselectFilter
            selectedValues={selectedValues}
            options={filter.options}
            dataType={filter.dataType}
            onSelect={(event, values, index) => module.handleFragmentColumnFilterChange(column, { selected: values })}
          />
        );
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            textValue={filter.textValue}
            onChange={(e, textValue) => module.handleFragmentColumnFilterChange(column, { textValue })}
          />
        );
      case FILTER_TYPE.DATE:
        return (
          <DateFilter
            filter={filter}
            onApply={filter => module.handleFragmentColumnFilterChange(column, filter)}
          />
        );
    }
  };

  const handleFragmentColumnHeaderFilter = column => {

    if (!module.filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {renderFragmentColumnFilter(column)}
      </div>
    );
  };
  const handleFragmentColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={multiSort => handleClick(column.key, column.sortValueGetter, multiSort)} sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  const handleFragmentColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={handleFragmentColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={handleFragmentColumnHeaderFilter(column)}
      />
    );
  };
  const handleFragmentsTableColumnsFilter = (columnKey, visible, columns) => {

    module.handleFragmentsTableColumnsFilter(columns);
    const currentRowHeight = getRowHeight(fragmentsTableColumns);
    setRowHeight(currentRowHeight);
  };

  const renderFragmentsTable = () => {

    const { columnsToSortBy, filtersEnabled } = module;

    const headerHeight = filtersEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;

    return (
      <Table
        rows={viewModel.filteredFragments}
        columnKey='index'
        virtualScroll={true}
        selectableRows={true}
        fixed={false}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        onColumnsFilter={handleFragmentsTableColumnsFilter}
        onSelect={selectedRows => module.handleTableSelect(selectedRows)}
        onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleRowContextMenu(rowContent, selectedRows, e)}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleFragmentsTableColumnOrder(columns, oldIndex, newIndex)}
        onColumnResize={(columns, columnKey) => module.handleFragmentsTableColumnResize(columns)}
        showFilterOptionInHeaderMenu={true}
        filtersEnabled={filtersEnabled}
        onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
        onDoubleClickRow={(rowIndex, rowContent, e) => module.handleDoubleClickRow(rowIndex, rowContent, e)}
      >
        {fragmentsTableColumns.map(col => {
          const index = columnsToSortBy.findIndex(column => column.key === col.key);
          const sortDirection =
            col.sortType && index !== -1
              ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
              : undefined;
          const handleClick = col.sortType
            ? module.handleFragmentColumnClick(col.sortType)
            : undefined;
          return <Column
            key={col.key}
            title={col.caption}
            columnKey={col.key}
            width={col.width}
            visible={col.visible}
            header={handleFragmentColumnHeader(col, handleClick, sortDirection, index)}
            cell={col.cell}
            cellDataGetter={col.cellDataGetter}
            shouldCellUpdate={col.shouldCellUpdate || defaultShouldCellUpdate}
          />;
        })}
      </Table>
    );
  };

  return (
    <div className='fragments-view crtx-form-element-full-height' onKeyDown={e => module.handleTableViewKeyDown(e)}>
      {renderFragmentsTable()}
    </div>
  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  fragmentsTableColumns: PropTypes.array,
};
