/**
 * @fileOverview
 * @author elad
 */

import {translate} from 'core/services/localization';
import sandbox from 'sandbox';
import AbstractAction from 'AbstractAction';
import {isInApprovalFlowStep, isSeparationWaitingForUserApprovalIndications, isItemWaitingForApproval, getItemApprovalIndications, getItemWaitingIndicationsPerUser, getSeparationWaitingIndicationsPerUser} from '../../utilities/approvals';
import dialogService from '../../core/services/dialogService';

const isUndefined = o => typeof o === 'undefined';
const APPROVAL_FLOW_STEP_TYPE = 'workflow/step/flow/approval';

function getApprovalFlowStepNwid(module) {
  if (module.startParameters && module.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) 
    return module.startParameters.rootId;
  
  if (module.parentModule && module.parentModule.startParameters && module.parentModule.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) 
    return module.parentModule.startParameters.rootId;

  return undefined;
}

function getIsViewDescendentOfApprovalView(actionInstance) {
  if (actionInstance.module.startParameters && actionInstance.module.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) return true;
  if (actionInstance.module.parentModule && actionInstance.module.parentModule.startParameters && actionInstance.module.parentModule.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) return true;

  return false;
}

export default AbstractAction.extend({
  isApplicable: function (objs) {
    if (objs.some(obj => obj.aggregated)) {
      return false;
    };
    const config = this.config || {};
    const allowedFlowstepIds = config.flowStepIds || [];
    const isViewDescendentOfApprovalView = getIsViewDescendentOfApprovalView(this);
    const approvalFlowStepNwid = getApprovalFlowStepNwid(this.module);

    if (isViewDescendentOfApprovalView && allowedFlowstepIds.length > 0 && !allowedFlowstepIds.includes(approvalFlowStepNwid)) return false;

    return objs.some(selectedItem => {
      if (selectedItem.type === 'page/separation') {
        return isInApprovalFlowStep(selectedItem, allowedFlowstepIds) && isSeparationWaitingForUserApprovalIndications(selectedItem.parent, selectedItem);
      }
      return isItemWaitingForApproval(selectedItem, allowedFlowstepIds)
    });
  },

  execute: function (objs) {
    let operation = this.parameters.operation;
    let rejectOption = "reject";
    if (typeof this.config !== "undefined") {
      rejectOption = this.config.rejectOperation;
    }
    if (operation === "reject" && rejectOption === "prompt") {
      const buttons = [
        {
          id: 'reject',
          title: translate('Reject'),
        },
        {
          id: 'reject_and_kill',
          title: translate('Reject And Kill'),
        },
        {
          id: 'calcel',
          title: translate('Cancel'),
          reject: true
        }
      ];

      dialogService.openConfirmDialog(translate('Please select action'), translate('Reject options'), {buttons})
        .then(id => {
          if (id === 'reject') {
            this.perforSubmit(objs, false);
          } else if (id === 'reject_and_kill') {
            this.perforSubmit(objs, true);
          }
        });
    } else if (operation === "reject" && rejectOption === "kill") {
      this.perforSubmit(objs, true);
    } else {
      return this.perforSubmit(objs, false);
    }

  },

  perforSubmit: function (objs, isKill) {
    var nwid, type;
    var operation = this.parameters.operation;
    var result = false;
    let rejectOption = "reject";
    const config = this.config || {};
    const allowedFlowstepIds = config.flowStepIds;

    if (typeof this.config !== "undefined") {
      rejectOption = this.config.rejectOperation;
    }
    var approvedObjects = [];
    const flowStepNwid = getApprovalFlowStepNwid(this.module);
    const isFromApproveStep = (typeof(flowStepNwid) !== "undefined");
    for (var index = 0; index < objs.length; index++) {
      var obj = objs[index];
      if (typeof obj !== 'undefined') {
        if (isInApprovalFlowStep(obj, allowedFlowstepIds)) {
          var flowSteps = obj["approvalFlowSteps"];
          var flowStepNWIds = [];
          if (typeof flowStepNwid === "undefined") {
            for (var i = 0; i < flowSteps.length; i++) {
              flowStepNWIds.push(flowSteps[i]);
            }
          } else {
            flowStepNWIds.push(flowStepNwid);
          }

          var content = null;
          type = obj["type"];
          if (type === "page") {
            content = obj["pageContent"];
          } else if (type === "page/separation") {
            content = obj["separationContent"];
          } else if (type === "form/separation") {
            content = obj["separationContent"];
          } else {
            content = obj["formContent"];
          }
          nwid = content === null ? content["nwid"] : obj["nwid"];
          type = content === null ? content["type"] : obj["type"];
          let contentTypes = typeof getItemApprovalIndications(obj) === 'undefined' ? undefined : getItemWaitingIndicationsPerUser(obj);
          //sandbox.request.approveRejectObject(operation, nwid, type, flowStepNWIds, {});
          approvedObjects.push({ operation: operation, nwid: nwid, type: type, flowStepNWIds: flowStepNWIds, contentTypes: contentTypes });
        }
        var separations = obj["separations"];
        if (typeof separations === 'undefined') {
          separations = [];
        }
        for (var j = 0; j < separations.length; j++) {
          var sep = separations[j];
          if (isInApprovalFlowStep(sep, allowedFlowstepIds)) {
            flowSteps = sep["approvalFlowSteps"];
            flowStepNWIds = [];
            if (typeof flowStepNwid === "undefined") {
              for (var k = 0; k < flowSteps.length; k++) {
                flowStepNWIds.push(flowSteps[k]);
              }
            } else {
              flowStepNWIds.push(flowStepNwid);
            }
            content = sep["separationContent"];
            nwid = content === null ? content["nwid"] : sep["nwid"];
            type = content === null ? content["type"] : sep["type"];
            let contentTypes = typeof getItemApprovalIndications(obj) === 'undefined' ? undefined : getSeparationWaitingIndicationsPerUser(obj, sep);
            //sandbox.request.approveRejectObject(operation, nwid, type, flowStepNWIds, {});
            approvedObjects.push({ operation: operation, nwid: nwid, type: type, flowStepNWIds: flowStepNWIds, contentTypes: contentTypes });
          }
        }

      }
    }
    var cfg = this.config;
    if (typeof cfg === "undefined") {
      cfg = {};
    }
    return sandbox.request.approveRejectObjects({ operation: operation, approvedObjects: approvedObjects, kill: isKill, isFromApproveStep: isFromApproveStep }, this.module.projectorId, this.nwid, cfg);
  }
});