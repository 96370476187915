import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CollapsiblePanelContainer from './containers/collapsiblePanelContainer';
import Thumbnails from './thumbnails';
import iconService from 'core/services/iconService';
import ProgressBar from './progressbar';
import IndicationCounter from './indicationCounter';
import { translate } from 'core/services/localization';

export default class ThumbnailSections extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static defaultProps = {
    model: {},
    scale: undefined,
    thumbnailDefinition: undefined,
    alignmentPoints: undefined,
    selectedItems: {}
  };

  state = {
    selectedItems: {}
  };

  getSectionStyle = (controller) => {
    var display = controller.filterZoneViewType === undefined || controller.filterZoneViewType === controller.zoneViewTypes.sections ?
      '' : 'none';

    return {
      display: display
    };
  };

  sections = () => {
    var sections = [],
      props = this.props,
      model = typeof props.model === 'undefined' ? 'undefined' : props.model,
      type = typeof model === 'undefined' || typeof model.type !== 'string' ?
        undefined :
        model.type.toLowerCase();

    if (typeof model === 'undefined') {
      return sections;
    }
    if (typeof type === 'undefined') {
      return sections;
    }

    if (type === 'zone') {
      sections = model.sections || [];
    } else if (type === 'section') {
      sections.push(model);
    } else {
      sections = model;
    }
    return sections;
  };

  pages = (section) => {
    var pages = typeof section === 'undefined' ? undefined : section.pages;

    if (!Array.isArray(pages)) {
      return [];
    }

    return pages.filter(function (page) {
      return page.virtual === false;
    });
  };

  thumbnailDefinition = () => {
    return this.props.thumbnailDefinition;
  };

  alignmentPoints = () => {
    return this.props.alignmentPoints;
  };

  renderProgressBar = (controller, pages) => {
    const thumbnailDefinition = this.thumbnailDefinition();
    const generalProperties = thumbnailDefinition.generalProperties || {};
    const progressCount = controller.getProgressCount(pages);

    if (generalProperties.showProgressBar === false) {
      return undefined;
    }

    return <ProgressBar min={0} max={progressCount.total} current={progressCount.completed} >
      {progressCount.completed} / {progressCount.total}
    </ProgressBar>;
  };

  render() {
    var component = this,
      controller = this.context.module.ctrl,
      scale = this.props.scale,
      viewConfiguration = this.props.viewConfiguration,
      sectionStyle = this.getSectionStyle(controller),
      sections = this.sections().map(function (section) {
        var pages = component.pages(section),
          progressCount = controller.getProgressCount(pages),
          title = (<div className="Section-Title">
            <div className="Section-Title-Content" title={section.name}>
              {section.name} | {translate('Pages')}
            </div>

            <div className="Section-Title-Slider">
              {component.renderProgressBar(controller, pages)}
            </div>
            <div className="Section-Title-Icons">
              <IndicationCounter icon="reject" count={progressCount.rejected} />
              <IndicationCounter icon="error" count={progressCount.error} />
              <IndicationCounter icon="waitingForApproval" count={progressCount.waitingForApproval} />
              <IndicationCounter module="Thumbnail" icon="holdCounter" count={progressCount.onHold} />
            </div>
          </div>);

        return <div key={section.id}>
          <div className="Section" tabIndex="0" style={sectionStyle}>
            <CollapsiblePanelContainer title={title} handlerIcon={iconService.getSystemIcon('arrow')}>
              <Thumbnails parentId={section.id}
                thumbnails={pages}
                scale={scale}
                viewConfiguration={viewConfiguration}
                thumbnailDefinition={component.thumbnailDefinition()}
                alignmentPoints={component.alignmentPoints()}
                selectedItems={component.state.selectedItems}>
              </Thumbnails>
            </CollapsiblePanelContainer>
          </div>
        </div>;
      });
    return <div className="Sections">
      {sections}
    </div>;
  }
}

ThumbnailSections.propTypes = {
  scale: PropTypes.number,
  thumbnailDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  viewConfiguration: PropTypes.object,
  model: PropTypes.object
};