/**
 * Create object from the given array using value of the specified item key.
 * In more generic case the second parameter can be a function which takes item and returns string value
 * that will be used as key in the target object.
 *
 * @param items - array of objects
 * @param keyName - item key name or function that will be used to build the target object properties
 * @returns {object}
 *
 * Example:
 * arrayToObject([
 *   { id: '111', name: 'Larry Page' },
 *   { id: '112', name: 'Sergey Brin' }
 * ], 'id')
 * returns
 * {
 *   111: { id: '111', name: 'Larry Page' },
 *   112: { id: '112', name: 'Sergey Brin' }
 * }
 * In this example same output will be produced when the following function is used
 * instead of keyName: item => item.id
 */
export function arrayToObject(items = [], keyName = 'key') {
  let getValue;
  if (typeof keyName === 'function') {
    getValue = keyName;
  }

  return items.reduce((acc, item) => {
    const objectKey = getValue ? getValue(item) : item[keyName];
    if (!acc[objectKey]) {
      acc[objectKey] = item;
    }

    return acc;
  }, {});
}

export function arrayToDistinctEntries(items = [], keyName = 'key') {
  return Object.entries(arrayToObject(items, keyName));
}

export function arrangeItemsByKeys(items = [], keys = [], keyName = 'key') {
  const itemsByKeys = arrayToObject(items, keyName);
  let arrangedItems = keys.reduce((acc, key) => {
    const item = itemsByKeys[key];
    if (item) {
      acc.push(item);
      itemsByKeys[key] = null;
    }
    return acc;
  }, []);

  const newItems = items.filter(item => itemsByKeys[item[keyName]] !== null);

  return arrangedItems.concat(newItems);
}

export function swapItems(items = [], index1, index2) {
  let temp = items[index2];
  items[index2] = items[index1];
  items[index1] = temp;

  return items;
}

export function moveItem(array, from, to) {
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to;

    const [item] = array.splice(from, 1);
    array.splice(endIndex, 0, item);
  }
  return array;
}

function toValue(obj, key) {
  let value;
  if (!key) {
    value = obj;
  } else if (typeof key === 'function') {
    value = key(obj);
  } else {
    value = obj[key];
  }

  return value;
}

export function sum(array = [], key) {
  return array.reduce((acc, item) => {
    let value = toValue(item, key);
    acc += Number(value) || 0;

    return acc;
  }, 0);
}

export function max(array = [], key) {
  const result = array.reduce((acc, item) => {
    let value = toValue(item, key);
    acc = value > acc ? Number(value) : acc;

    return acc;
  }, Number.NEGATIVE_INFINITY);

  return result === Number.NEGATIVE_INFINITY ? NaN : result;
}

export function min(array = [], key) {
  const result = array.reduce((acc, item) => {
    let value = toValue(item, key);
    acc = value < acc ? Number(value) : acc;

    return acc;
  }, Number.POSITIVE_INFINITY);

  return result === Number.POSITIVE_INFINITY ? NaN : result;
}

export function hasDuplicates(items = [], key) {
  let found = false;

  const valuesDict = {};
  for (const item of items) {
    let value = toValue(item, key);
    if (valuesDict[value]) {
      found = true;
      break;
    }

    valuesDict[value] = true;
  }

  return found;
}

export function findDuplicates(items = [], key) {
  const itemsByKey = items.reduce((acc, item) => {
    let value = toValue(item, key);
    if (!acc[value]) {
      acc[value] = [];
    }

    acc[value].push(item);

    return acc;
  }, {});

  const duplicates = Object.entries(itemsByKey).filter(([key, value]) => value.length > 1);

  return duplicates;
}

export function hasEmptyValues(items = [], key) {
  return items.some(item => !toValue(item, key));
}

export function areArraysEqual(arr1 = [], arr2 = []) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const countItems = (arr) => {
    return arr.reduce((acc, item) => {
      acc[item] = typeof acc[item] === 'undefined' ? 1 : acc[item] + 1;

      return acc;
    }, {});
  };

  let equal = true;
  const obj1 = countItems(arr1);
  const obj2 = countItems(arr2);
  for (const [key, value] of Object.entries(obj1)) {
    if (value !== obj2[key]) {
      equal = false;
      break;
    }
  }

  return equal;
}
