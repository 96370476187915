import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager
} from '@floating-ui/react';
import { DEFAULT_MAX_ZINDEX, findMaxZIndex,adjustFakeKendoWindowZIndex, setFakeKendoWindowZIndex } from 'utilities/zindex';
import { classNames } from 'utilities/classNames';
import { usePopoverContext } from './PopoverContext';

export const PopoverContent = forwardRef(
  function PopoverContent({ children, className, style, ...props }, propRef) {
    const { context: floatingContext, ...context } = usePopoverContext();
    const setRef = useMergeRefs([context.refs.setFloating, propRef]);
    const [zIndex, setZIndex] = useState(DEFAULT_MAX_ZINDEX);

    // Important: Implementation dependent code
    // This two lines of code are vital for creating portals in the Light Window.
    // Obtain document of the window where the reference (anchor) element is located
    const ownerDocument = floatingContext.elements.domReference?.ownerDocument;
    const portalNode = ownerDocument?.body;

    useEffect(() => {
      if (!portalNode) {
        return;
      }

      if (context.open) {
        const maxZIndex=  findMaxZIndex(ownerDocument);
        setMaxZIndex(maxZIndex + 1);
      } else {
        setZIndex(DEFAULT_MAX_ZINDEX);
        adjustFakeKendoWindowZIndex(ownerDocument);
      }
    }, [context.open]);

    const setMaxZIndex = (zIndex) => {
      setFakeKendoWindowZIndex(ownerDocument, zIndex);
      setZIndex(zIndex);
    };

    return (
      <FloatingPortal root={portalNode}>
        {context.open && (
          <FloatingFocusManager context={floatingContext} modal={context.modal}>
            <div
              ref={setRef}
              className={classNames('crtx-popover', className)}
              style={{
                position: context.strategy,
                top: context.y ?? 0,
                left: context.x ?? 0,
                zIndex: zIndex,
                ...style
              }}
              aria-labelledby={context.labelId}
              aria-describedby={context.descriptionId}
              {...context.getFloatingProps(props)}
            >
              {children}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    );
  });

PopoverContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};
