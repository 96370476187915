import Class from 'class';
import sandbox from 'sandbox';
import styleHelperFunctions from '../helpers/styleHelperFunctions';
import BatchedRenderer from '../batchedRenderer';
import { translate } from 'core/services/localization';
import { getMainStatus } from 'utilities/statuses';

var DEFAULT_SINGALE_THUMBNAIL_SIZE = [160, 130],
  DEFAULT_DOUBLE_HORIZONTAL_THUMBNAIL_SIZE = [200, 130],
  DEFAULT_DOUBLE_VERTICAL_THUMBNAIL_SIZE = [200, 160],
  DEFAULT_DOUBLE_THUMBNAIL_SIZE = [200, 160],
  PAGE_STATUS_DESCRIPTION = ['empty', 'inwork', 'ok', 'error', 'sep', 'waiting'];


function isPages(model) {
  if (model.type === 'section' || typeof model.sections !== 'undefined') {
    return true;
  }
  return false;
}

function isVirtual(item) {
  return item !== undefined && item.virtual === true;
}

function areThereMissingSeparationsForCompletion(item) {
  var separations = item['separations'];
  if (typeof separations === 'undefined') {
    return false;
  }
  var foundCompleteSep = false;
  var foundIncompleteSep = false;
  for (var i = 0; i < separations.length; i++) {
    var status = separations[i].defaultStatus;
    if (status) {
      var statusType = status['statusType'];
      if (statusType === 'Success') {
        var percentage = status['progress'];
        if (percentage === '100') {
          foundCompleteSep = true;
        } else {
          foundIncompleteSep = true;
        }
      } else {
        foundIncompleteSep = true;
      }
    } else {
      foundIncompleteSep = true;
    }
  }
  if (foundCompleteSep && foundIncompleteSep) {
    return true;
  } else {
    return false;
  }
}

export default Class.extend({
  init: function (module) {
    this.module = module;
    this.selectedItemParentArray = undefined;
    this.lastSelctedItem = undefined;
    this.selectionHandlers = [];
    this.collapsiblePanelsHandlers = [];
    this.filterArgument = undefined;
    this.filterZoneViewType = undefined;
    this.tickHandlers = [];
    this.batchedRenderer = new BatchedRenderer();
  },

  triggerTick: function () {
    //var that = this;
    //that.batchedRenderer.render(function () {
    //  that.tickHandlers.forEach(function (tickHandler, index) {
    //    tickHandler.fnc.call(tickHandler.context);
    //  });
    //});
  },

  onTick: function (fnc, context) {
    if (this.tickHandlers.filter(function (handler) {
      return handler.fnc === fnc;
    }).length > 0) {
      return;
    }

    this.tickHandlers.push({ fnc: fnc, context: context });
  },

  offTick: function (fnc) {
    this.tickHandlers = this.tickHandlers.filter(function (handler) {
      return handler.fnc !== fnc;
    });
  },

  getItem: function (id) {
    if (typeof id === 'undefined') {
      return this.module.tickableModel.model();
    }

    return this.module.tickableModel.get(id);
  },

  isItemSelected: function (item) {
    return typeof this.module.selectedItems[item.id] !== 'undefined' && this.areContainersSame(this.selectedItemParentArray[0], item);
  },

  getSelectedItems: function (asArray) {
    var selectedItems = this.module.selectedItems;
    if (!asArray) {
      return selectedItems;
    }

    return Object.keys(selectedItems).map(function (selectedItemId) {
      return selectedItems[selectedItemId];
    });
  },

  emptySelectedItems: function () {
    this.module.selectedItems = {};
  },

  getSelectedItemParent: function (item) {
    const { viewModel, itemsToRenderInDialog } = this.module;
    let parent = [];
    if (viewModel.type !== 'edition') {
      parent = item.__parent;
    } else if (itemsToRenderInDialog.some(it => it.id === item.id)) {
      parent = itemsToRenderInDialog;
    } else if (item.type === 'page') {
      parent = viewModel.sections.find(s => s.name === item.section.sectionName).pages;
    } else {
      parent = viewModel.books.find(b => b.name === item.book.name).forms;
    }
    return parent;
  },

  addToSelectedItems: function (item, lastItem) {
    var itemsArr = this.getSelectedItemParent(item),
      itemIndex,
      lastItemIndex;

    if (arguments.length === 1) {
      this.module.selectedItems[item.id] = item;
      return;
    }

    itemIndex = itemsArr.findIndex(it => it.id === item.id);
    lastItemIndex = itemsArr.findIndex(it => it.id === lastItem.id);

    for (var i = Math.min(itemIndex, lastItemIndex); i <= Math.max(itemIndex, lastItemIndex); i++) {
      if (
        !isVirtual(itemsArr[i]) &&
        (this.filterArgument === undefined || this.filterArgument(itemsArr[i]))
      ) {
        this.module.selectedItems[itemsArr[i].id] = itemsArr[i];
      }
    }
  },

  removeFromSelectedItems: function (item) {
    delete this.module.selectedItems[item.id];
  },

  unSelectItems: function () {
    this.module.selectedItems = {};
    this.lastSelctedItem = undefined;
    this.selectedItemParentArray = undefined;

    this.updateModuleSelected();

    this.triggerSelectionChanged({});
  },

  selectedItemsToArray: function () {
    const result = [];
    const selectedItems = this.module.selectedItems;

    Object.keys(selectedItems).forEach(function (id) {
      if (typeof selectedItems[id] !== 'undefined') {
        result.push(selectedItems[id]);
      }
    });

    return result;
  },

  areContainersSame: function (item1, item2) {
    if (!item1 || !item2) {
      return false;
    }

    let ret;

    if (this.module.viewModel.type === 'edition') {
      ret = this.getContainerId(item1) === this.getContainerId(item2);
    } else {
      ret = item1.__parent.__parent.nwid === item2.__parent.__parent.nwid;
    }

    return ret;
  },

  getContainerId: function (item) {
    let containerId;
    if (item.type === 'page') {
      containerId = item.section.nwid;
    } else {
      containerId = item.book.nwid;
    }

    return containerId;
  },

  updateModuleSelected: function () {
    this.module.updateSelected(this.selectedItemsToArray());
  },

  //onKeyDown: function(sections, e) {
  //  if ( (e.metaKey === true || e.ctrlKey === true) && e.key === 'a') {
  //  }
  //},
  setSelected: function (model, operator) {
    var module = this.module,
      //selectedItems = module.selectedItems,
      lastSelctedItem = this.lastSelctedItem,
      itemNumber = model.numberInSection || model.numberInBook;

    const modelParent = this.getSelectedItemParent(model);
    const sameContainers = this.areContainersSame(this.selectedItemParentArray?.[0], model);
    if (operator === undefined || !sameContainers) {
      this.emptySelectedItems();
    }

    if (operator === 'shift' && sameContainers) {
      this.addToSelectedItems(lastSelctedItem, model);
    } else if (!this.isItemSelected(model)) {
      this.addToSelectedItems(model);
    } else {
      this.removeFromSelectedItems(model);
    }

    this.selectedItemParentArray = modelParent;
    this.lastSelctedItem = model;

    this.triggerSelectionChanged();
    this.updateModuleSelected();
  },

  onClick: function (models, clickedModel, e) {
    var operator = e.metaKey === true || e.ctrlKey === true ?
      'ctrl' :
      e.shiftKey === true ?
        'shift' :
        undefined;

    if (this.shouldClosePopup && !this.module.itemsToRenderInDialog.some(item => item.id === clickedModel?.id)) {
      this.handleClose();
    }
    if (clickedModel === undefined) {
      this.unSelectItems();

      e.stopPropagation();
      return;
    }

    this.setSelected(clickedModel, operator);

    e.stopPropagation();
  },

  onOpenChange: function () {
    this.shouldClosePopup = true;
  },

  onContextMenu: function (models, clickedModel, e) {
    if (!this.isItemSelected(clickedModel)) {
      this.setSelected(clickedModel, undefined);
    }

    this.module.showContextMenu.apply(this.module, [clickedModel, this.getSelectedItems(true), e]);

    e.stopPropagation();
  },

  onDoubleClick: function (clickedModel, e) {
    e.stopPropagation();

    if (!clickedModel.aggregated) {
      this.module.navigateByViewLink(clickedModel, undefined, e);
    }
  },

  handleClose: function () {
    this.module.handleClose();
  },

  handleAggregatedItemClick: function (clickedModel, e) {
    this.module.handleAggregatedItemClick(clickedModel);
    this.shouldClosePopup = false;
  },

  onKeyDown: function (ev) {
    var itemsArr = this.selectedItemParentArray;
    //CTRL + A
    if (ev.keyCode === 65 && (ev.metaKey || ev.ctrlKey)) {
      ev.stopPropagation();
      ev.preventDefault();
      if (itemsArr === undefined) {
        return;
      }

      this.lastSelctedItem = itemsArr[0];

      this.setSelected(itemsArr[itemsArr.length - 1], 'shift');
    }
    //CTRL + C
    if (ev.keyCode === 67 && (ev.metaKey || ev.ctrlKey)) {
      let copyAction = this.module.viewActions.find(viewAction => viewAction.actionClass === 'CopyAction');
      if (copyAction && copyAction.isApplicable(this.module.selected) && !this.module.selected[0].aggregated) {
        copyAction.execute(this.module.selected);
      }
    }
    //CTRL + V
    if (ev.keyCode === 86 && (ev.metaKey || ev.ctrlKey)) {
      let pasteAction = this.module.viewActions.find(viewAction => viewAction.actionClass === 'PasteAction');
      if (pasteAction && pasteAction.isApplicable(this.module.selected) && !this.module.selected[0].aggregated) {
        pasteAction.execute(this.module.selected);
      }
    }
  },

  keyDownHandler: function (element) {
    sandbox.dom.attr(element, 'tabindex', '2');
    sandbox.events.listen(element, 'keydown', this, this.onKeyDown.bind(this));
  },

  removeKeyDownHandler: function (element) {
    sandbox.events.unlisten(element, 'keydown', this.onKeyDown);
  },

  registerSelectionChangedHandler: function (id, handler, context) {
    this.selectionHandlers.push({ id: id, handler: handler, context: context });
  },

  removeSelectionChangedHandler: function (id) {
    this.selectionHandlers = this.selectionHandlers.filter(function (selectionHandler) {
      return selectionHandler.id !== id;
    });
  },

  triggerSelectionChanged: function () {
    var that = this;
    this.selectionHandlers.forEach(function (selectionHandler) {
      selectionHandler.handler.apply(selectionHandler.context, [that.module.selectedItems, that.selectedItemParentArray]);
    });
  },

  registerCollapsiblePanelsHandlers: function (handler, context) {
    this.collapsiblePanelsHandlers.push({ handler: handler, context: context });
  },

  removeCollapsiblePanelsHandler: function (handler) {
    this.collapsiblePanelsHandlers = this.collapsiblePanelsHandlers.filter(function (collapsiblePanelHandler) {
      return collapsiblePanelHandler !== handler;
    });
  },

  triggerCollapsibelPanelChanged: function (isOpen) {
    var that = this;
    this.collapsiblePanelsHandlers.forEach(function (collapsiblePanelHandler) {
      collapsiblePanelHandler.handler.apply(collapsiblePanelHandler.context, [isOpen]);
    });
  },

  getFormSize: function (form) {
    var DEFAULT_SINGALE_FORM_SIZE = [80, 80],
      DEFAULT_DOUBLE_PAGE_SIZE = [160, 80],
      DEFAULT_DOUBLE_VERTICAL_PAGE_SIZE = [80, 160];

    var pageInfoOnForm = form.PageInfoOnForm,
      matrix = form.PageInfoOnForm.matrix,
      horizontalImageCount = matrix.horizontalImageCount,
      verticalImageCount = matrix.verticalImageCount;
    /**
     * TODO: HACK solution for now. Should be changed to get size value from server
     */

    if (horizontalImageCount === 1 && (verticalImageCount === 1 || verticalImageCount === 2)) {
      return DEFAULT_SINGALE_FORM_SIZE;
    }

    return DEFAULT_DOUBLE_PAGE_SIZE;
  },

  getThumbnailPageSize: function (page) {
    var isPanorama = page.pageContent.isPanorama;

    if (isPanorama) {
      if (page.pageContent.format === 'Tabloid') {
        return DEFAULT_DOUBLE_VERTICAL_THUMBNAIL_SIZE;
      }

      return DEFAULT_DOUBLE_THUMBNAIL_SIZE;
    }

    return DEFAULT_SINGALE_THUMBNAIL_SIZE;
  },

  getThumbnailFormSize: function (form) {
    var pageInfoOnForm = form.PageInfoOnForm,
      matrix = form.PageInfoOnForm.matrix,
      horizontalImageCount = matrix.horizontalImageCount,
      verticalImageCount = matrix.verticalImageCount;

    if (horizontalImageCount === 1 && verticalImageCount === 1) {
      return DEFAULT_SINGALE_THUMBNAIL_SIZE;
    }
    return DEFAULT_DOUBLE_HORIZONTAL_THUMBNAIL_SIZE;
    //if (horizontalImageCount === 1 && verticalImageCount === 1) return DEFAULT_SINGALE_THUMBNAIL_SIZE;
    //if (horizontalImageCount > 1 && verticalImageCount === 1) return DEFAULT_DOUBLE_HORIZONTAL_THUMBNAIL_SIZE;
    //if (horizontalImageCount === 1 && verticalImageCount > 1) return DEFAULT_DOUBLE_VERTICAL_THUMBNAIL_SIZE;

    //return DEFAULT_DOUBLE_THUMBNAIL_SIZE;
  },

  getThumbnailSize: function (thumbnail) {
    if (thumbnail.type === 'page') {
      return this.getThumbnailPageSize(thumbnail);
    }

    return this.getThumbnailFormSize(thumbnail);
  },

  getMinThumbnailSize: function (thumbnails) {
    var that = this,
      minSize = undefined;

    if (!Array.isArray(thumbnails)) {
      return this.getThumbnailSize(thumbnails);
    }

    minSize = thumbnails.reduce(function (accMinSize, thumbnail) {
      var thumbnailSize = that.getThumbnailSize(thumbnail);

      return accMinSize[0] < thumbnailSize[0] && accMinSize[1] < thumbnailSize[1] ? accMinSize : thumbnailSize;
    }, [Number.MAX_VALUE, Number.MAX_VALUE]);

    return minSize;
  },

  statusDescriptions: function (statusDescription) {
    return PAGE_STATUS_DESCRIPTION.indexOf(statusDescription);
  },

  getPageStatusDescription: function (page) {
    const pageStatus = page?.completionState;

    if (page.type === 'sepration') {
      return 'sep';
    }

    if (pageStatus === undefined) {
      return 'empty';
    }
    if (pageStatus === 'error') {
      return 'error';
    }
    if (pageStatus === 'waiting') {
      return 'waiting';
    }
    if (pageStatus === 'inProgress') {
      return 'inwork';
    }
    if (pageStatus === 'success') {
      return 'ok';
    }

    return 'empty';
  },

  getMissingTooltip: function (type) {
    if (type === 'pages') {
      return translate('Show Missing Pages');
    }
    return translate('Show Missing Formes');
  },

  getErrorTooltip: function (type) {
    if ('pages') {
      return translate('Show Error Pages');
    }
    return translate('Show Error Formes');
  },

  isCompleted: function (item) {
    return item.completionState === 'success';
  },

  isMissing: function (item) {
    return item.completionState === 'none';
  },

  isWaitingForApproval: function (item) {
    return item.completionState === 'waiting';
  },

  isWarningInPreflight: function (item) {
    const reportType = item.pageContent && item.pageContent.preflightReportInfo ?
      item.pageContent.preflightReportInfo.preflightReportType :
      undefined;
    return reportType === 1;
  },

  isError: function (item) {
    return item.completionState === 'error';
  },

  isRejectedItem: function (item) {
    return item.completionState === 'reject';
  },

  isOnHoldItem: function (item) {
    var holdType = item.holdType;
    if (typeof holdType !== 'undefined' && holdType !== 'none') {
      return true;
    } else {
      return false;
    }
  },

  isLocalItem: function (item) {
    var separations = item.separations;
    var localSeparations = separations.filter(function (sep) {
      return sep.local && (typeof sep.baseSeparation === 'undefined' || sep.baseSeparation === null);
    });
    return (localSeparations.length > 0);
  },

  isItemWithActualPlates: function (item) {
    if (typeof item === 'undefined') {
      return false;
    }

    if (item.type === 'page') {
      return true;
    }

    if (typeof item.separations === 'undefined') {
      return false;
    }

    return item.separations.some(sep => {
      return (sep.plates || []).some(plate => plate.isActual === true);
    });
  },

  isBook: function (item) {
    return item && item.type === 'book';
  },

  hasSections: function (item) {
    return !!item?.sections;
  },

  hasBooks: function (item) {
    return !!item?.books;
  },

  expandAllViews: function () {
    this.triggerCollapsibelPanelChanged(true);
  },

  collapseAllViews: function () {
    this.triggerCollapsibelPanelChanged(false);
  },

  applyFilter: function (isPushed, filterName, group) {
    if (!isPushed) {
      this.filterArgument = undefined;
    } else {
      switch (filterName) {
        case 'missing':
          this.filterArgument = this.isMissing;
          break;
        case 'error':
          this.filterArgument = this.isError;
          break;
        case 'waiting_for_approval':
          this.filterArgument = this.isWaitingForApproval;
          break;
        case 'local_pages':
          this.filterArgument = this.isLocalItem;
          break;
        case 'actual':
          this.filterArgument = this.isItemWithActualPlates;
          break;
        default:
          this.filterArgument = undefined;
      }
      this.unSelectItems();
    }
  },

  updateCount: function (model, pairModel, count) {
    var that = this;
    if (that.isCompleted(model) && (typeof pairModel === 'undefined' || that.isCompleted(pairModel))) {
      count.completed++;
    }
    if (that.isRejectedItem(model) && (typeof pairModel === 'undefined' || that.isRejectedItem(pairModel))) {
      count.rejected++;
    }
    if (that.isError(model) && (typeof pairModel === 'undefined' || that.isError(pairModel))) {
      count.error++;
    }
    if (that.isWaitingForApproval(model) && (typeof pairModel === 'undefined' || that.isWaitingForApproval(pairModel))) {
      count.waitingForApproval++;
    }
    if (that.isOnHoldItem(model) && (typeof pairModel === 'undefined' || that.isOnHoldItem(pairModel))) {
      count.onHold++;
    }
    count.total++;
  },
  getProgressCount: function (models) {
    var that = this,
      count = {
        completed: 0,
        rejected: 0,
        error: 0,
        waitingForApproval: 0,
        onHold: 0,
        total: 0
      };

    if (!Array.isArray(models)) {
      return count;
    }

    models.forEach(function (model) {
      var pairNwId = typeof model !== 'undefined' ? model.pairNwId : undefined;
      var pairModel = typeof pairNwId !== 'undefined' ? that.getByNwid(pairNwId) : undefined;
      if (model.virtual === true || model.ignore === true && !model.aggregated) {
        return;
      }

      if (model.aggregated) {
        model.relatedPages.forEach(rp => {
          const relatedPagePairNwId = typeof model !== 'undefined' ? model.pairNwId : undefined;
          const relatedPagePairModel = typeof relatedPagePairNwId !== 'undefined' ? that.getByNwid(relatedPagePairNwId) : undefined;
          if (rp.virtual === true || rp.ignore === true) {
            return;
          }
          that.updateCount(rp, relatedPagePairModel, count);
        });
      } else {
        that.updateCount(model, pairModel, count);
      }
    });

    return count;
  },

  hasPair: function (model) {
    return !(typeof model.pairSourceId === 'undefined');
  },

  hasPairWaiting: function (model) {
    return this.module.pagesWaitingForPairedUpdate[model.pairSourceId] !== undefined;
  },

  waitForPair: function (model, newModel) {
    this.module.pagesWaitingForPairedUpdate[model.sourceId] = { model: model, newModel: newModel };
  },

  getWaitingPair: function (model) {
    return this.module.pagesWaitingForPairedUpdate[model.pairSourceId];
  },

  removeWaitingPair: function (model) {
    delete this.module.pagesWaitingForPairedUpdate[model.pairSourceId];
  },

  isPairIsSelected: function (model, waitingPairModel) {
    return this.isItemSelected(model) || this.isItemSelected(waitingPairModel);
  },

  switchSelectionBetweenPairs: function (model, waitingPairModel) {
    var selected,
      goingToBeSelected;

    if (this.isItemSelected(model)) {
      selected = model;
      goingToBeSelected = waitingPairModel;
    } else {
      selected = waitingPairModel;
      goingToBeSelected = model;
    }

    if (this.lastSelctedItem === selected) {
      this.lastSelctedItem = goingToBeSelected;
    }
    this.removeFromSelectedItems(selected);
    this.addToSelectedItems(goingToBeSelected);
    this.triggerSelectionChanged();
    this.updateModuleSelected();

  },

  handleVirtualUpdate: function (model, newModel) {
    var that = this,
      waitingPair;

    if (!that.hasPair(model)) {
      return true;
    }

    if (!that.hasPairWaiting(model)) {
      that.waitForPair(model, newModel);
      return false;
    }

    waitingPair = that.getWaitingPair(model);
    that.module.tickableModel.updateWithoutHandlers(waitingPair.newModel);

    if (that.isPairIsSelected(model, waitingPair.model)) {
      that.switchSelectionBetweenPairs(model, waitingPair.model);
    }

    that.removeWaitingPair(model);

    return true;
  },

  mapUnplannedPage: function (model, data) {
    var action = this.module.viewActions.find(a => a.actionDefinitionName === 'MapUnplannedPageActionCR');

    if (!action) {
      return Promise.resolve(
        {
          data: {
            message: translate('You do not have permission to drop unplanned pages'),
          }
        }
      );
    }

    return action.execute(model, data);
  },

  zoneViewTypes: {
    sections: 'SECTIONS',
    books: 'BOOKS'
  },

  applyFilterZoneViewType: function (isPushed, filterName, group) {
    if (!isPushed) {
      this.filterZoneViewType = undefined;
    } else {
      switch (filterName) {
        case 'show_sections':
          this.filterZoneViewType = this.zoneViewTypes.sections;
          break;
        case 'show_books':
          this.filterZoneViewType = this.zoneViewTypes.books;
          break;
        default:
          this.filterZoneViewType = undefined;
      }
      this.unSelectItems();
    }
  },


  //var params = {
  //  defaultImage: sandbox.icons.getGeneralIcon(null, "empty"),
  //  template: (typeof this.selectedSeparation.content.type === 'undefined') || this.selectedSeparation.content.type === "form" ?
  //    this.get("content.formContent.type") : this.selectedSeparation.get("content.separationContent.type"),
  //  // this.get("content.formContent.type"),
  //  //version: this.get("content.pageContent.contentVersionMajorIndex"),
  //  action: "icon",
  //  //imageType: imageType,
  //  viewId: this.get("content.id"),
  //  // pageAction: 3,
  //  nwid: contentObject.nwid,
  //  //iconUrlCounter is not required by the server, we use it to have different URL so the browser refresh the image
  //  iconUrlCounter: iconUrlCounter++,
  //  projectorId: this.get('controller.module.projectorId')
  //  //rootId: this.selectedSeparation.get("content.id")
  //};
  //return sandbox.request.getImageUrl(params, true);

  getThumbnailContent: function (thumbnail) {
    if (thumbnail === undefined) {
      return undefined;
    }
    if (thumbnail.type === 'page') {
      return thumbnail.pageContent;
    }
    if (thumbnail.type === 'form') {
      return thumbnail.formContent;
    }
    if (thumbnail.type === 'form/separation') {
      return thumbnail.separationContent;
    }
    if (thumbnail.type === 'page/separation') {
      return thumbnail.separationContent;
    }
  },

  // this function does not get called in birdeye view
  showThumbnailImage: function (thumbnail) {
    if (thumbnail && getMainStatus(thumbnail)?.flowStepType) {
      return true;
    }
    return false;
  },

  getThumbnailUrl: function (thumbnailContent) {
    if (thumbnailContent === undefined) {
      return undefined;
    }

    var params = {
      defaultImage: sandbox.icons.getGeneralIcon(null, 'empty'),
      template: thumbnailContent.type,
      action: 'icon',
      viewId: this.module.id,
      nwid: thumbnailContent.nwid,
      //iconUrlCounter: Math.random(),
      version: thumbnailContent.contentVersionMajorIndex,
      projectorId: this.module.projectorId
    };

    return sandbox.request.getImageUrl(params, true);
  },

  getMatrix: function (thumbnail) {
    if (thumbnail.type === 'form') {
      return thumbnail.PageInfoOnForm.matrix;
    }

    return {
      horizontalImageCount: thumbnail.pageContent.isPanorama ? 2 : 1,
      verticalImageCount: 1
    };
  },

  getFormIndexes: function (thumbnail) {
    var ret = {};
    if (thumbnail.type === 'form') {
      return thumbnail.PageInfoOnForm.indexes;
    }

    ret[thumbnail.nwid] = 0;
    return ret;
  },

  getOrietations: function (thumbnail) {
    var ret = {};
    if (thumbnail.type === 'form') {
      return thumbnail.PageInfoOnForm.orietations;
    }

    ret[thumbnail.nwid] = 'horizontal';
    return ret;
  },

  getByNwid: function (nwid) {
    var module = this.module;
    return module.tickableModel.getByNwid(nwid);
  },

  lockedTitle: function (model) {
    let title = '';

    const resources = this.getResources(model);
    if (resources.length > 0) {
      title = translate('Resources: ') + resources.join(', ');
    }

    const groups = this.getGroups(model);
    if (groups.length > 0) {
      title = (title ? title + '; ' : '') + translate('Groups: ') + groups.join(', ');
    }

    return title;
  },

  getResources: function (model) {
    return (model.allowedResourcesAndGroups || {}).resources || [];
  },

  getGroups: function (model) {
    return (model.allowedResourcesAndGroups || {}).groups || [];
  },

  isLocked: function (model) {
    return this.getResources(model).length > 0 || this.getGroups(model).length > 0;
  },

  isPreApproved: function (model) {
    let content = model.type === 'page' ? model.pageContent :
      model.type === 'form' ? model.formContent : undefined;
    return typeof content === 'undefined' || typeof content.isPreApproved === 'undefined' ? false : content.isPreApproved;
  },

  styleHelperFunctions: styleHelperFunctions


});
