define(
  [
    './Dropdown/Dropdown',
    './Dropdown/MultiSelectDropdown',
    './Button/Icon',
    './Button/Button',
    './Button/SplitButton',
    './Button/ToggleButton',
    './List/List',
    './Panel/CollapsiblePanel',
    './Accordion/Accordion',
    './Accordion/AccordionPanel'
  ],
  function (Dropdown,
            MultiSelectDropdown,
            Icon,
            Button,
            SplitButton,
            ToggleButton,
            List,
            CollapsiblePanel,
            Accordion,
            AccordionPanel) {

    return {
      Dropdown,
      MultiSelectDropdown,
      Icon,
      Button,
      SplitButton,
      ToggleButton,
      List,
      CollapsiblePanel,
      Accordion,
      AccordionPanel
    };

  });
