import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useReducer,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import dom from 'base/dom';
import { Store } from 'widgets/SimpleForm/src/index';
import EditorMediator from './EditorMediator';
import Toolbar from './components/Toolbar';
import Sidebar from './components/Sidebar';
import Canvas from './canvas/Canvas';

const LayoutEditor = forwardRef(({ data, settings, onChangeLayout }, ref) => {

  const [editor, setEditor] = useState(null);
  const [reduxStore, setReduxStore] = useState(null);
  const [formStore, setFormStore] = useState(null);

  const splitterRef = useRef(null);
  const canvasRef = useRef(null);

  const [, forceUpdate] = useReducer(count => count + 1, 0);

  useImperativeHandle(ref, () => ({
    isModelChanged: () => editor.isModelChanged(),
    save: () => editor.save(),
  }));

  useLayoutEffect(() => {
    console.log('LayoutEditor.useLayoutEffect()');

    const $splitter = dom.find(splitterRef.current);
    $splitter.kendoSplitter({
      panes: [{}, { size: '400px', collapsible: true }],
      resize: (e) => canvasRef.current?.updateCanvasSize()
    });

    return () => $splitter.data('kendoSplitter')?.destroy();
  }, []);

  useEffect(() => {
    console.log('LayoutEditor.useEffect() canvasRef=>', canvasRef.current);

    const canvas = canvasRef.current;
    const editor = new EditorMediator(data, settings, canvas);
    setEditor(editor);
    const reduxStore = editor.getReduxStore();
    setReduxStore(reduxStore);
    const formStore = new Store(reduxStore);
    setFormStore(formStore);

    reduxStore.subscribe(forceUpdate);
  }, []);

  return (
    <div className='layout-editor'>
      <div ref={splitterRef} className='fit-height'>
        <div className='editor-center'>
          <Toolbar store={formStore} editor={editor} />
          <Canvas ref={canvasRef} store={reduxStore} />
        </div>
        <div className='editor-sidebar'>
          <div className='sidebar-top'>
            {formStore ? <Sidebar store={formStore} editor={editor} onChangeLayout={onChangeLayout} /> : null}
          </div>
        </div>
      </div>
    </div>
  );

});

LayoutEditor.propTypes = {
  data: PropTypes.object,
  settings: PropTypes.object,
};

export default LayoutEditor;