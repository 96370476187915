import React from 'react';
import SeparationsComposite from './separationsComposite';
import SeparationVersion from './separationVersion';
import SeparationStatusIcon from './separationStatusIcon';
import { getMainStatus } from 'utilities/statuses';

const isUndefined = o => typeof o === 'undefined';

export default class extends React.Component {
  static defaultProps = {
    separations: undefined,
    statuses: [],
    definition: undefined,
    approvalIndicationComplete: undefined
  };

  renderApprovalIndication = (approvalIndication) => {
    return <span style={{ width: 13, display: 'inline-block', textAlign: 'center' }}>
      {approvalIndication}
    </span>;
  };

  renderVersion = (definition) => {
    return <SeparationVersion definition={definition} version={0} />;
  };

  renderStatus = (definition) => {
    return <SeparationStatusIcon definition={definition} separation={{}} />;
  };

  renderPlatesStatusType = (elementDefinition, approvalIndication, separations) => {
    const approvalIndications = isUndefined(approvalIndication) ? [] : approvalIndication.split(',');
    if (separations.some(sep => getMainStatus(sep)?.flowStepType === 'workflow/step/flow/approval') && approvalIndications.length > 1) {
      return <div>
        {approvalIndication.split(',').sort().map(this.renderApprovalIndication)}
      </div>;
    }
    return undefined;
  };

  renderElement = (definition, elementDefinition, separations) => {
    const type = elementDefinition.type;

    switch (type) {
      case 'separation':
        if (!definition.showComposite) return undefined;
        return <SeparationsComposite separations={separations} key={0} onSeparationClick={this.props.onSeparationClick} />
      case 'separation-version':
        return this.renderVersion(elementDefinition);
        break;
      case 'separation-status':
        return this.renderStatus(elementDefinition);
        break;
      case 'separation-status-type':
        return this.renderPlatesStatusType(elementDefinition, this.props.approvalIndication, separations);
      default:
        return <div></div>;
    }
  };

  renderElements = (separations, definition) => {
    const elements = definition.elements || [];

    return elements.map((elementDefinition, index) => {
      return <div className="SeparationProgressItemElement" key={index}>
        {this.renderElement(definition, elementDefinition, separations)}
      </div>;
    });
  };

  render() {
    var {
      separations,
      statuses,
      pairStatus,
      definition,
      onSeparationClick
    } = this.props;

    return <li className="separation-progress-header">
      {this.renderElements(separations, definition)}
    </li>;
  }
}