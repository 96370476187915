/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 3/9/14
 * Time: 10:07 AM
 * To change this template use File | Settings | File Templates.
 */
define(['sandbox', './OpenModuleAction', 'core/services/dialogService', '../../setups/OutputPlatesSetup/OutputPlatesSetup'],
  function (sandbox, OpenModuleAction, dialogService, Setup) {
    'use strict';

    return OpenModuleAction.extend({

      isApplicable: function () {
        var objects = this.module.selected;
        if (typeof objects === 'undefined' || objects.length !== 1) {
          return false;
        }
        //return (objects[0].isManualPlanChangeEnabled);
        return true;
      }

    });

  });