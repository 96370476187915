import React from 'react';
import { createRoot } from 'react-dom/client';
import dom from 'base/dom';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import ModelHandler from './model/ModelHandler';
import ModeSelector from './views/ModeSelector';
import { restPost } from 'core/managers/rest2';
import { DEADLINE_MODE } from "./model/constants";

function init(initObj) {
  console.log('StructureNew.init()');

  this._super(initObj);

  const { wizard, projectorId, startParameters: { rootType, rootId, nwid: viewNwid } } = initObj;
  this.settings = { wizard, projectorId, rootType, rootId, viewNwid, ...this.model.clientSettings };
  // this.settings ={...this.settings, zoneDeadlineMode: DEADLINE_MODE.MANDATORY}; // ***TEST DEADLINE

  this.modelHandler = new ModelHandler(this.model, this.settings);
}

function next() {

  const validationResult = this.modelHandler.convertFromStepModel();
  var chaseSteps = this.model.clientSettings.hideChaseSteps || [1, 3, 4];
  try {
    if (validationResult.isValid && this.model["chaseMode"]) {
      this.settings.wizard.hideSteps(chaseSteps);
    }
  } catch (e) {
    console.log("failed to hide/show steps");
  }


  return validationResult.isValid;

}

function destroy() {
  console.log('StructureNew.destroy()');

  this.reactRoot.unmount();
}

function render() {
  this.settings.wizard.disableButton('next');
  var lockingGroupId = this.model.publication?.LockingGroupId;

  if (lockingGroupId) {
    const data = { data: { shouldLock: false } };
    restPost(this.settings.viewNwid, `global/locking/containers/groups/${this.model.publication.LockingGroupId}/unlock/PlanningWizard`, data).then((responseLocking) => {
      internalRender.call(this);
    });

  } else {
    internalRender.call(this);
  }
}

function internalRender() {
  this.settings.wizard.enableButton('next');
  this.settings.wizard.disableButton('cancel');

  this.containerElement = dom.find(this.container)[0];
  if (!this.reactRoot) {
    this.reactRoot = createRoot(this.containerElement);
  }

  this.reactRoot.render(<ModeSelector modelHandler={this.modelHandler} />);
}


module.exports = AbstractStep.extend({
  init,
  render,
  next,
  destroy,
}, 'Structure');