import React, { Component } from 'react';
import Progressbar from '../../progressbar';
import PropTypes from 'prop-types';

export default class extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    plates: undefined,
    definition: undefined,
    color: undefined
  };

  platesCount = (plates) => {
    return Array.isArray(plates) ? plates.length : 0;
  };

  finishedPlates = (plates, controller) => {
    if (!Array.isArray(plates)) return 0;

    return plates.filter(controller.isCompleted).length;
  };

  render() {
    var {
            plates,
      definition,
      color
          } = this.props,
      controller = this.context.controller,
      platesCount = this.platesCount(plates),
      finishedPlates = this.finishedPlates(plates, controller),
      progressStyle = {
        width: 33,
        height: 3,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'solid 1px ' + color
      };

    return <div className="separation-plates-progress">
      <div className="separation-plates-progress-text">
        {finishedPlates} / {platesCount}
      </div>
      <div className="separation-plates-progress-progress">
        <Progressbar max={platesCount} current={finishedPlates} style={progressStyle} color={color} />
      </div>
    </div>;
  }
}