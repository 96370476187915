import React, { Component } from 'react';
import Button from '../Button';
import State from './State';
import PropTypes from 'prop-types';

class SwitchButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    static propTypes = {
        state: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        style: PropTypes.object,
        onClick: PropTypes.func
    }

    static defaultProps = {
        disabled: false,
        children: [<State name='' />]
    }

    handleClick(event) {
        this.props.onClick(event, event.target.innerHTML);
    }

    render() {
        const { state, className, disabled, style, onClick, children } = this.props;

        const child = children.find(function (childElenent) {
            return state === childElenent.props.name;
        });

        return <Button className={`crtx-switch ${className}`} style={style} disabled={disabled} onClick={this.handleClick}>
            {typeof child === 'undefined' ? child : child.props.children}
        </Button>
    }
};

module.exports = SwitchButton;