import { COMPARE_TYPE } from 'core/comparators';
import { cells } from 'widgets/ReactDataGrid';
import sandbox from 'sandbox';
import localization from 'sandbox/localization';
import React from 'react';
import IndeterminateProgressBar from 'components/common/progress/IndeterminateProgressBar';
import { toLocaleShortDateTime } from 'core/services/localization';
import { restPost } from 'core/managers/rest2';
import toastService from 'core/services/toastService';

const emptyImageSrc = '//:0';
const OBJECT_TYPE = {
  manager: 'controlpanel/site/manager',
  machine: 'controlpanel/site/machine',
};
const { translate } = localization;

const installStatusLabel = {
  'InstallationPending': translate('Pending'),
  'InstallerDownloading': translate('Downloading'),
  'InstallerDownloaded': translate('Downloaded'),
  'InstallerDownloadFailed': translate('Download failed'),
  'TriggerInstallationFailed': translate('Trigger installation failed'),
  'InstallationStarted': translate('Installing'),
  'InstallationFailed': translate('Installation failed'),
  'ResourceServiceStarted': translate('Resource service started'),
  '': translate('Not Available')
};

const labels = {
  active: translate('Active'),
  type: translate('Type'),
  name: translate('Name'),
  manager: translate('Manager'),
  machine: translate('Machine'),
  address: translate('Address'),
  connected: translate('Connected'),
  version: translate('Version'),
  availableVersion: translate('Available Version'),
  resources: translate('Resources'),
  install: translate('Install'),
  installStatus: translate('Install Status'),
  installProgress: translate('Install Progress'),
  errorDescription: translate('Error Description'),
  resourceServiceIsConnected: translate('Resource Service is connected'),
  resourceServiceIsNotConnected: translate('Resource Service is not connected'),
  resourceServiceVersion: translate('Resource Service Version'),
  resourceServiceInstallerVersion: translate('Resource Service Installer Version'),
  installAvailableVersion: translate('Install Available Version'),
  installationIsNotAvailable: translate('Installation Is Not Available'),
  statusTime: translate('Status Time'),
  notAvailable: translate('Not Available'),
  status: translate('Status'),

};

const getInstallationStatus = (row) => {
  let status = '';

  if (installStatusLabel[row.installationStatus] !== '' &&
    installStatusLabel[row.installationStatus] !== 'Not Available') {
    status = installStatusLabel[row.installationStatus];
  } else {
    status = row.remoteInstallSupportedMessage || '';
  }
  return status;
};

const getStatusClassName = (status) => {
  return (status === 'InstallationFailed' || status === 'InstallerDownloadFailed') ? 'crtx-cluster-error-color' : '';
};

const { IconCell, Text } = cells;

export const getClusterColumns = (preferences, clusterTableRows) => {


  const handleInstallAvailableVersionClick = (name) => {
    const tokenApi = '85b99f24-7336-483f-8fb0-f7af15a65994';

    restPost(tokenApi, `resource-service-update/${name}/trigger-installation`)
      .catch(err => {
        toastService.errorToast(translate('Install request failed'), err.statusText);
      });
  };
  const columnPrefs = preferences.table && preferences.table.columns ? preferences.table.columns : [];
  const columnWidth = (propertyName, defaultValue) => columnPrefs[propertyName] && columnPrefs[propertyName].width ? columnPrefs[propertyName].width : (defaultValue ? defaultValue : 40);


  return [
    {

      displayName: labels.active,
      key: 'current',
      align: 'center',
      width: columnWidth('current', 60),
      cell: IconCell,
      sortType: COMPARE_TYPE.NUMBERS,
      sortable: true,
      valueGetter: (obj) => {
        return obj.current;
      },

      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: rowIndex,
          icon: clusterTableRows[rowIndex].current ? sandbox.icons.getModuleIcon("ClusterView", "active") : emptyImageSrc,
          title: clusterTableRows[rowIndex].current ? labels.active : ''

        };
      }
    },
    {
      displayName: labels.type,
      key: 'type',
      cell: IconCell,
      width: columnWidth('type', 60),
      align: 'center',
      sortType: COMPARE_TYPE.CASE_SENSITIVE,
      sortable: true,

      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: rowIndex,
          icon: clusterTableRows[rowIndex].type === OBJECT_TYPE.manager ? sandbox.icons.getModuleIcon("ClusterView", "server") :
            clusterTableRows[rowIndex].type === OBJECT_TYPE.machine ? sandbox.icons.getModuleIcon("ClusterView", "machine") : emptyImageSrc,
          title: clusterTableRows[rowIndex].type === OBJECT_TYPE.manager ? labels.manager :
            clusterTableRows[rowIndex].type === OBJECT_TYPE.machine ? labels.machine : ''


        };
      },
    },
    {
      displayName: labels.name,
      key: 'name',
      cell: Text,
      width: columnWidth('name', 100),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortable: true,
      valueGetter: (obj) => {
        return obj.name;
      },
      cellDataGetter: (rowIndex, columnKey) => {
        const item = clusterTableRows[rowIndex];
        return item.name;
      },
    },
    {
      displayName: labels.address,
      key: 'ip',
      cell: Text,
      width: columnWidth('ip', 130),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortable: true,
      cellDataGetter: (rowIndex, columnKey) => {
        const item = clusterTableRows[rowIndex];
        return item.address;
      },
    },
    {

      displayName: labels.connected,
      key: 'connected',
      align: 'center',
      width: columnWidth('connected', 85),
      cell: Text,
      sortType: COMPARE_TYPE.NUMBERS,
      sortable: true,
      valueGetter: (obj) => {
        return obj.connected;
      },
      cellDataGetter: (rowIndex, columnKey) => {
        const isConnected = clusterTableRows[rowIndex].connected;
        const color = isConnected ? '#0ab006' : '#d2232a';

        return {
          columnData: <i className='material-icons crtx-cluster-cursor-default' style={{ color }}>power</i>,
          title: isConnected ? labels.resourceServiceIsConnected : labels.resourceServiceIsNotConnected
        };
      }

    },
    {

      displayName: labels.version,
      key: 'resourceServiceVersion',
      width: columnWidth('resourceServiceVersion', 100),
      cell: Text,
      sortType: COMPARE_TYPE.VERSIONS,
      cellDataGetter: (rowIndex, columnKey) => {
        const item = clusterTableRows[rowIndex];

        return {
          columnData: <span>{item.resourceServiceVersion === '' ? labels.notAvailable : item.resourceServiceVersion}</span>,
          title: labels.resourceServiceVersion
        };

      }
    },
    {

      displayName: labels.availableVersion,
      key: 'resourceServiceInstallerVersion',
      width: columnWidth('resourceServiceInstallerVersion', 130),
      cell: Text,
      sortType: COMPARE_TYPE.NUMBERS,
      sortable: true,
      valueGetter: (obj) => {
        return obj.resourceServiceVersion;
      },
      cellDataGetter: (rowIndex, columnKey) => {
        const item = clusterTableRows[rowIndex];

        return {
          columnData: <span>{item.resourceServiceInstallerVersion === '' ? labels.notAvailable : item.resourceServiceInstallerVersion}</span>,
          title: labels.resourceServiceInstallerVersion
        };

      }
    },
    {
      displayName: labels.install,
      key: 'enableInstall',
      width: columnWidth('enableInstall', 60),
      cell: Text,
      align: 'center',
      cellDataGetter: (rowIndex, columnKey) => {
        const color = clusterTableRows[rowIndex].enableInstall ? '#0ab006' : '#afafaf';

        let installButton = clusterTableRows[rowIndex].type === OBJECT_TYPE.manager ? '' :
          clusterTableRows[rowIndex].enableInstall ?
            <i className='material-icons crtx-cluster-cursor-pointer'
              style={{ color: color }}
              onClick={(e) => handleInstallAvailableVersionClick(clusterTableRows[rowIndex].name)}>
              system_update_alt
            </i> :
            <i className='material-icons' style={{ color }}>system_update_alt</i>;

        return {
          columnData: installButton,
          title: clusterTableRows[rowIndex].type === OBJECT_TYPE.manager ? '' : clusterTableRows[rowIndex].enableInstall ?
            labels.installAvailableVersion : labels.installationIsNotAvailable
        };
      }
    },
    {
      displayName: labels.status,
      key: 'installationStatus',
      width: columnWidth('installationStatus', 140),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortable: true,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = clusterTableRows[rowIndex];
        const status = getInstallationStatus(row);

        return {
          columnData: status === '' ? '' : <span className={getStatusClassName(status)}>{status}</span>,
          title: status !== '' ? status : (row.remoteInstallSupportedMessage || '')
        };
      }
    },
    {
      displayName: labels.statusTime,
      key: 'installationStatusTime',
      width: columnWidth('installationStatusTime', 140),
      cell: Text,
      sortType: COMPARE_TYPE.DATES,
      sortable: true,
      valueGetter: (obj) => {
        return obj.installationStatusTime;
      },
      cellDataGetter: (rowIndex, columnKey) => {
        const row = clusterTableRows[rowIndex];
        const status = getInstallationStatus(row);

        return { columnData: status === '' ? '' : toLocaleShortDateTime(row.installationStatusTime) };
      }
    },
    {
      displayName: labels.installProgress,
      key: 'enableInstallProgress',
      width: columnWidth('enableInstallProgress', 120),
      cell: Text,
      sortType: COMPARE_TYPE.NUMBERS,
      sortable: true,
      align: 'center',
      cellDataGetter: (rowIndex, columnKey) => {
        let progressBar = clusterTableRows[rowIndex].enableInstallProgress ?
          <IndeterminateProgressBar className='crtx-cluster-progress-bar' vertical={false} /> : '';

        return { columnData: progressBar };

      }
    },
    {
      displayName: labels.errorDescription,
      key: 'installationStatusErrorDetails',
      width: columnWidth('installationStatusErrorDetails', 130),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortable: true,
      valueGetter: (obj) => {
        return obj.installationStatusErrorDetails !== '' ? obj.installationStatusErrorDetails :
          (obj.resourceServiceRemoteInstallSupportedMessage !== '' ? obj.resourceServiceRemoteInstallSupportedMessage : '');
      },
      cellDataGetter: (rowIndex, columnKey) => {
        const message = clusterTableRows[rowIndex].installationStatusErrorDetails !== '' ?
          clusterTableRows[rowIndex].installationStatusErrorDetails :
          (clusterTableRows[rowIndex].resourceServiceRemoteInstallSupportedMessage &&
            clusterTableRows[rowIndex].resourceServiceRemoteInstallSupportedMessage !== '' ?
            clusterTableRows[rowIndex].resourceServiceRemoteInstallSupportedMessage : '');

        return {
          columnData: message
        };
      }
    },
    {
      displayName: labels.resources,
      key: 'resourcesString',
      cell: Text,
      width: columnWidth('resourcesString', 300),
      flex: 1,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, columnKey) => {

        return clusterTableRows[rowIndex].resourcesString;
      },
    },
  ];
};
