import React from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';
import Pages from './pages';
import Forms from './forms';

const DEFAULT_SIZE = [100, 100];

export default class GridItem extends React.Component {
  static contextTypes = {
    module: PropTypes.object
  };

  popoverRef = React.createRef();
  hasKeyDownHandlerRef = React.createRef();

  static defaultProps = {
    gridSize: undefined,
    gridMargin: undefined,
    gridAlign: undefined,
    size: DEFAULT_SIZE,
    aggregated: false,
    numOfRelatedPages: 0
  };

  componentDidUpdate() {
    if (this.popoverRef.current && !this.hasKeyDownHandlerRef.current) {
      this.hasKeyDownHandlerRef.current = true;
      this.context.module.ctrl.keyDownHandler(this.popoverRef.current);
    }
  }

  handleOpenChange = () => {
    this.hasKeyDownHandlerRef.current = false;
    if (typeof this.props.onOpenChange === 'function') {
      this.props.onOpenChange();
    }
  };

  getGridSize = () => {
    return this.props.gridSize;
  };

  getSize = () => {
    return this.props.size;
  };

  getGridMargin = () => {
    return this.props.gridMargin;
  };

  getGridAlign = () => {
    return this.props.gridAlign;
  };

  getGridItemSize = (grid, size, margin) => {
    if (!Array.isArray(grid) || !Array.isArray(size) || grid.length === size.length === 2) {
      return DEFAULT_SIZE;
    }


    return grid.map(function (gridProp, index) {

      var gridLength = Math.ceil(size[index] / gridProp),
        extraMargins = gridLength <= 1 ? 0 : (gridLength - 1) * 2;

      return (gridLength * gridProp) + (extraMargins * margin);
    });
  };

  getChildren = () => {
    return this.props.children;
  };

  getIsVisible = () => {
    return this.props.isVisible;
  };

  getGridItemStyle = (gridItemSize, align, margin, isVisible) => {
    const style = {
      width: gridItemSize[0] + 3,
      height: gridItemSize[1] + 3,
      float: align,
      margin: margin,
      display: isVisible === true ? '' : 'none',
      overflow: 'hidden',
    };
    const style2 = {
      width: gridItemSize[0],
      height: gridItemSize[1],
      margin: 0,
      float: align,
      display: isVisible === true ? '' : 'none',
      position: 'absolute',
    };
    return { gridItemStyle: style, relatedPagesLayersStyle: style2 };
  };

  render() {
    const { shouldShowRelatedItemsDialog, itemsToRenderInDialog, aggregatedItem } = this.context.module;
    const { itemDefinition, alignmentPoints, aggregated, itemNwid, numOfRelatedPages, onAggregatedItemClick, onClick } = this.props,
      children = this.getChildren(),
      gridSize = this.getGridSize(),
      gridMargin = this.getGridMargin(),
      gridAlign = this.getGridAlign(),
      size = this.getSize(),
      gridItemSize = this.getGridItemSize(gridSize, size, gridMargin),
      isVisible = this.getIsVisible(),
      style = this.getGridItemStyle(gridItemSize, gridAlign, gridMargin, isVisible);

    return <div className="GridItem" style={style.gridItemStyle}>
      {aggregated ? <div onClick={onClick} style={style.relatedPagesLayersStyle}>
        <Popover
          placement={'bottom'}
          open={shouldShowRelatedItemsDialog && aggregatedItem?.relatedPages.some(page => `aggregated-${page.nwid}` === itemNwid)}
          onOpenChange={this.handleOpenChange}
        >

          <PopoverTrigger>
            <div onClick={onAggregatedItemClick} className='birdeye-layers-number-of-related-items'>{numOfRelatedPages}</div>
          </PopoverTrigger>

          <PopoverContent className='thumbnail-layers-related-items-popover-content'>
            {shouldShowRelatedItemsDialog ? <div className='birdeye-layers-related-items-popover crtx-text-unselectable' ref={this.popoverRef}>
              {aggregatedItem.type === 'page' ?
                <Pages
                  pages={itemsToRenderInDialog}
                  pageDefinition={itemDefinition}
                  alignmentPoints={alignmentPoints}
                >
                </Pages>
                :
                <Forms
                  forms={itemsToRenderInDialog}
                  formDefinition={itemDefinition}
                  alignmentPoints={alignmentPoints}
                >
                </Forms>}
            </div> : null}
          </PopoverContent>
        </Popover>
      </div> : undefined}
      {children}
    </div>;
  }
}

GridItem.propTypes = {
  itemDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  aggregated: PropTypes.bool,
  itemNwid: PropTypes.string,
  numOfRelatedPages: PropTypes.number,
  onAggregatedItemClick: PropTypes.func,
  onClick: PropTypes.func,
  onOpenChange: PropTypes.func,
  children: PropTypes.object,
  isVisible: PropTypes.bool,
  gridMargin: PropTypes.number,
  gridAlign: PropTypes.string,
  gridSize: PropTypes.array,
  size: PropTypes.array,
};