import BaseTreeView from '../BaseTreeView/BaseTreeView';
import childrenTransforms from './childrenTransforms';
import FolderMenu from "../BaseTreeView/FolderMenu";
import Filter from "./Filter";
import TreeDataAdapter from "./TreeDataAdapter";

export default BaseTreeView.extend({
  initDone: function () {
    this._super();

    this.dataAdapter.setChildrenTransforms(childrenTransforms);
  },
  createTreeDataAdapter: function () {
    return new TreeDataAdapter({
      module: this
    });
  },
  createFolderMenu: function (dataAdapter) {
    return new FolderMenu(this, dataAdapter);
  },
  getFilterComponent: function () {
    return Filter;
  },
}, "PlanTree");
