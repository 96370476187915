import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from "components/common/headers/Header";
import Radio from "components/common/inputs/Radio";
import sandbox from 'sandbox';

const translate = sandbox.localization.translate;
const labels = {
  measurementsUnit: translate('MEASUREMENT UNITS'),
}
const MILLIMETER = 'mm';
const INCH = 'inch';
const PIXEL = 'px';
const POINT = 'pt';

export default class MeasurementsUnit extends Component {
  static propTypes = {
    measurementUnit: PropTypes.string,
    onChangeMeasurementUnit: PropTypes.func
  };

  render() {
    const { measurementUnit, onChangeMeasurementUnit } = this.props;
    return <div className='crtx-measurements-unit'>
      <div className='crtx-measurements-unit-header'>{labels.measurementsUnit}</div>
      <div className='crtx-measurements-unit-content'>
        <label className='crtx-measurements-unit-content-label'><Radio name='measurementsUnit' value={MILLIMETER} checked={measurementUnit === MILLIMETER} onChange={onChangeMeasurementUnit} /> mm</label>
        <label className='crtx-measurements-unit-content-label'><Radio name='measurementsUnit' value={INCH} checked={measurementUnit === INCH} onChange={onChangeMeasurementUnit} /> in</label>
        <label className='crtx-measurements-unit-content-label'><Radio name='measurementsUnit' value={POINT} checked={measurementUnit === POINT} onChange={onChangeMeasurementUnit} /> pt</label>
      </div>
    </div>;
  };

}