export const selectCursorStyle = (state) => {
  let name = 'default';

  if (state.loading) {
    name = 'wait';
  } else {
    if (!state.missingPage) {
      if (state.isDistanceTool || state.isDensityTool) {
        name = 'crosshair';
      } else if (state.isMoveTool) {
        name = 'all-scroll';
      }
    }
    return { cursor: name };
  }
};