import baseData from 'base/data';
import jsUtils from 'base/jsUtils';
import settingsManager from 'core/managers/settings';
import viewManager from 'core/managers/views';

const THROTTLE_WAIT = 1000;

let genericPreferences = {};
let savedGenericPreferences = {};

function cloneDeep(object = {}) {
  // clones and removes undefined values
  return JSON.parse(JSON.stringify(object));
}

export function initGenericPreferences(preferences = {}) {
  const keys = ['defaultViews', 'openedViews', 'windowsSizes'];

  const prefs = { ...preferences };
  keys.forEach(key => {
    const views = prefs[key] || [];
    prefs[key] = views.filter(v => !!viewManager.getViewInfo(v.nwid));
  });

  genericPreferences = prefs;
  savedGenericPreferences = preferences;
}

export function getDefaultViewsPreferences() {
  const { defaultViews = [] } = genericPreferences;

  return cloneDeep(defaultViews);
}

export async function saveDefaultViewsPreferences(defaultViews = []) {
  saveGenericPreferences({ defaultViews }, true);
}

export function getOpenedViewsPreferences() {
  const { openedViews = [] } = genericPreferences;

  return cloneDeep(openedViews);
}

export async function saveOpenedViewsPreferences(openedViews = []) {
  saveGenericPreferences({ openedViews }, true);
}

export function getLayoutPanesPreferences() {
  const { panes = {} } = genericPreferences;

  return cloneDeep(panes);
}

export function saveLayoutPanesPreferences(panes = {}) {
  saveGenericPreferences({ panes });
}

export function getWindowRectanglesPreferences() {
  const { windowsSizes: windowRectangles = [] } = genericPreferences;

  return cloneDeep(windowRectangles);
}

export async function saveWindowRectanglesPreferences(windowRectangles = []) {
  saveGenericPreferences({ windowsSizes: windowRectangles }, true);
}

export function getThumbnailLayersScalePreferences() {
  const { thumbnailLayersScale = 1 } = genericPreferences;

  return thumbnailLayersScale;
}

export function saveThumbnailLayersScalePreferences(thumbnailLayersScale) {
  saveGenericPreferences({ thumbnailLayersScale });
}

export function getPageViewPreferences() {
  const { pageView  = {} } = genericPreferences;

  return cloneDeep(pageView);
}

export async function savePageViewPreferences(pageView) {
  saveGenericPreferences({ pageView }, true);
}

async function saveGenericPreferences(preferences, immediately = false) {
  genericPreferences = {
    ...genericPreferences,
    ...cloneDeep(preferences)
  };

  if (immediately) {
    await postGenericPreferences();
  } else {
    postGenericPreferencesThrottled();
  }
}

async function postGenericPreferences() {
  if (jsUtils.isEqual(genericPreferences, savedGenericPreferences)) {
    return;
  }

  console.log('=== postGenericPreferences() ===');
  savedGenericPreferences = cloneDeep(genericPreferences);

  const requestParams = {
    command: 'saveGenericPreferences',
    clientId: settingsManager.get('id'),
    folderNwid: settingsManager.get('currentFolderNwid'),
    requestVersion: 1,
    preferences: JSON.stringify(genericPreferences)
  };

  return baseData.ajax({
    url: settingsManager.get('webAppPath') + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    error: function () {
      console.error('Saving generic preferences failed');
    }
  });
}

const postGenericPreferencesThrottled = jsUtils.throttle(postGenericPreferences, THROTTLE_WAIT, {
  leading: false,
  trailing: true
});

export async function savePreferences({ nwid, projectorId, rootId, rootType }, preferences) {
  const requestParams = {
    command: 'saveViewPreferences',
    preferences: JSON.stringify(preferences),
    clientId: settingsManager.get('id'),
    requestVersion: 1
  };

  return baseData.ajax({
    url: settingsManager.get('webAppPath') + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: { ...requestParams, nwid, projectorId, rootId, rootType },
    success: function (res) {
      console.log('Server response on Save Preferences', res);
    },
    error: function () {
      console.error('Saving preferences failed for module');
    }
  });
}

export default {
  _name: 'preferences',
  _type: 'manager',
  savePreferences
};