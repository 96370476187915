/**
 * Created by elad on 3/21/2016.
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import SessionView from './src/SessionView';


function getUIModel() {
  return this.model.sessions.map(function (item) {
    return {
      ip: item.ip,
      loginTime: item.loginTime,
      sessionId: item.sessionId,
      userName: item.user.name,
      userEmail: item.user.email
    };

  });
}

function mergeToModel(data) {
  var objects = data.model;
  var that = this;
  objects.forEach(function (item) {
    var action = item.action.toLowerCase();
    if (action === "add") {
      that.model.sessions.push(item);
    } else if (action === "remove") {
      var idsToRemove = item.ids;
      for (var i = 0; i < idsToRemove.length; i++) {
        var indToRemove = that.model.sessions.findIndex(function (session) {
          return (idsToRemove[i] === session.id);
        });
        if (indToRemove > -1) {
          that.model.sessions.splice(indToRemove, 1);
        }
      }

    }
  });
}

function updateTable() {
  this.uiModel = getUIModel.call(this);
  sandbox.sorting.sort(this.uiModel, "date", "loginTime");
  this.render();
}

export default AbstractModule.extend({
  classNames: ['pro-modules-sessionview'],

  actions: {
    disconnectSession: function (sessionId) {
      var msg = sandbox.localization.translate("You are about to disconnect a session");
      sandbox.dialog.confirm(msg, sandbox.localization.translate('Confirmation')).then(function () {
        var params = {
          command: 'logoutSession',
          rootId: this.viewSettings.nwid,
          nwid: this.viewSettings.nwid,
          template: this.viewSettings.rootType,
          sessionId: sessionId
        };
        sandbox.request.sendGenericRequest(params, null);
      }.bind(this));
    }
  },

  firstTickReceived: function (data) {
    this.model = data.model;
    this.reactRoot = createRoot(this.domElement);
    updateTable.call(this, data);
  },

  tickUpdate: function (data) {
    mergeToModel.call(this, data);
    updateTable.call(this, data);
  },

  render: function () {
    const sessionView = React.createElement(SessionView, { model: this.uiModel, actions: this.actions, module: this });

    this.reactRoot.render(sessionView);
  }

});
