import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate, toLocaleDate } from 'core/services/localization';
import { MSEC_IN_HOUR, getFolderCurrentDate } from 'core/dates';
import TextInput from 'components/common/inputs/TextInput';
import NumericInput from 'components/common/inputs/NumericInput';

function Filter(
  {
    filterOptions: { name = '', daysBefore = '', daysAfter = '', daysBeforeReadOnly = false, daysAfterReadOnly = false } = {},
    onChange,
    onRefresh
  }) {

  const [lastRefreshTime, setLastRefreshTime] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLastRefreshTime(Date.now()); // re-render filter
      if (daysBefore || daysAfter) {
        onRefresh && onRefresh();
      }
    }, MSEC_IN_HOUR);

    return () => {
      clearTimeout(timeoutId);
    };
  });

  const handleNameChange = (event, name) => {
    onChange && onChange(event, { name });
  };

  const handleDaysBeforeChange = (event, daysBefore) => {
    onChange && onChange(event, { daysBefore }, true);
  };

  const handleDaysAfterChange = (event, daysAfter) => {
    onChange && onChange(event, { daysAfter }, true);
  };

  return (
    <div className='crtx-tree-filter'>
      <TextInput
        className='crtx-tree-filter-name'
        inputType='search'
        value={name}
        placeholder={translate('Search')}
        title={translate('Publication name')}
        onChange={handleNameChange}
      />
      <NumericInput
        value={daysBefore}
        readOnly={daysBeforeReadOnly}
        allowEmptyValue={true}
        notNegative={true}
        decimalPlaces={0}
        size={2}
        placeholder={'-d'}
        title={daysBeforeReadOnly ? translate('Days before (read-only)') : translate('Days before')}
        onChange={handleDaysBeforeChange}
      />
      <div className='crtx-tree-filter-current-time'>
        {toLocaleDate(getFolderCurrentDate(), 'd MMM')}
      </div>
      <NumericInput
        value={daysAfter}
        readOnly={daysAfterReadOnly}
        allowEmptyValue={true}
        notNegative={true}
        decimalPlaces={0}
        size={2}
        placeholder={'+d'}
        title={daysAfterReadOnly ? translate('Days after (read-only)') : translate('Days after')}
        onChange={handleDaysAfterChange}
      />
    </div>
  );
}

Filter.propTypes = {
  filterOptions: PropTypes.shape({
    name: PropTypes.string, // node name (label)
    daysBefore: PropTypes.oneOfType([ // days before the current folder time
      PropTypes.number,
      PropTypes.string
    ]),
    daysAfter: PropTypes.oneOfType([ // days after the current folder time
      PropTypes.number,
      PropTypes.string
    ]),
    daysBeforeReadOnly: PropTypes.bool,
    daysAfterReadOnly: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default Filter;
