/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define(['ember', 'text!../templates/FragmentsList.hbs', '../components/FragmentsListItemComponent'], function ( Ember, FragmentsListTemplate, FragmentsListItemComponent ) {
  'use strict';

  var DiffFragmentClasses = [ 'empty', 'inwork', 'ok', 'error', 'sep'],
    DiffFragmentSizeCases = [
      //{fragments: 6, size: 20},
      {fragments: 39, size: 8},
      {fragments: 100, size: 4},
      {fragments: 156, size: 2}
    ],
    defaultFragmentDomSize = DiffFragmentSizeCases[0].size,
    Component = Ember.Component.extend({
      tagName: 'div',
      //classNames: ['fragment-list'],
      classNames: ['fragment-list-container'],
      template: Ember.Handlebars.compile(FragmentsListTemplate),
      fragments: [ ],
      fragmentDomSize: defaultFragmentDomSize,
      init: function ( ) {
        this._super();
      },
      initFragmentDomSize: function ( ) {
        this.set('fragmentDomSize', this.getFragmentDomNeededSize());
      }.observes('fragments.@each').on('init'),
      getFragmentDomNeededSize: function ( ) {
        var fragmentsLength = this.get('fragments').length,
          acceptableFragmentSizes = DiffFragmentSizeCases.filter(function ( diffSize ) {
            return fragmentsLength <= diffSize.fragments;
          });

        if ( acceptableFragmentSizes.length === 0 ) {
          return DiffFragmentSizeCases[DiffFragmentSizeCases.length - 1].size;
        }
        return acceptableFragmentSizes[0].size;
      },
      doubleClick: function (ev) {
        var fnc = this.get('onclick') || function(){};
        fnc.call(this.get('module') || {}, ev);
      },
      contextMenu: function (ev) {
        var fnc = this.get('onContextMenu') || function(){};
        fnc.call(this.get('module') || {}, ev);
        //var module = this.get('module');
        //module.showContextMenu(nodeModel, [nodeModel], e);
      }
    });

  Ember.Handlebars.helper('fragments-list', Component);

  return Component;
});