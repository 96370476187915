/**
 * @name ClusterView
 * @file ClusterView module
 *
 * @author Tanya
 * @since: 2020-06-08
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import jsutils from 'base/jsUtils';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import Cluster from './Cluster';
import { getClusterColumns } from './columnsCreator';
import sandbox from 'sandbox';
import { composeComparators, createObjectComparator, COMPARE_TYPE } from 'core/comparators';

import { fromServerDate } from 'core/dates';
import {
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

const THROTTLE_WAIT = 1000;

const DEFAULT_COLUMNS_TO_SORT = [
  {
    key: 'name',
    ascending: true,
    compareType: COMPARE_TYPE.CASE_INSENSITIVE
  }
];

const composeResourcesString = (obj) => {
  let resourcesStr = "";

  for (var propertyName in obj) {
    resourcesStr += propertyName + "(" + obj[propertyName] + "),";
  }
  if (resourcesStr.charAt(resourcesStr.length - 1) === ",") {
    resourcesStr = resourcesStr.substring(0, resourcesStr.length - 1);
  }
  return resourcesStr;
};

const resolveResourceType = (type) => {
  var a = type.split("/");
  if (a.length === 0) {
    return type;
  }
  return a[a.length - 1];
};

const groupResources = (clusterObject) => {
  var resources = clusterObject.resources;
  if (resources.length > 0) {
    var obj = {};
    for (var i = 0; i < resources.length; i++) {
      if (typeof resources[i].type !== "undefined") {
        var type = resolveResourceType(resources[i].type);
        if (typeof obj[type] !== 'undefined' && obj[type] !== null) {
          obj[type] = obj[type] + 1;
        } else {
          obj[type] = 1;
        }
      }
    }
    clusterObject["groupedResources"] = obj;
  }
  return clusterObject;
};

export default AbstractModule.extend({
  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.toolbar = this.createToolbar();
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = this.preferences.columnsToSortBy || [...DEFAULT_COLUMNS_TO_SORT];
    this.tickableModel = new TickableModel();
    this.tickableModel.firstTickHandler(data.model);
    this.buildViewModel();
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  buildViewModel: function () {

    const model = this.tickableModel.model();
    let clusterTableRows = model.managers.concat(model.machines);

    clusterTableRows = clusterTableRows.map(obj => groupResources(obj)).map(obj => {
      const isValidResourceServiceVersion = obj.resourceServiceVersion ?
        obj.resourceServiceVersion.split('.').every(str => !isNaN(parseInt(str))) : false;

      return {
        ...obj,
        resourcesString: obj.groupedResources ? composeResourcesString(obj.groupedResources) : '',
        enableInstall: obj.resourceServiceVersion !== obj.resourceServiceInstallerVersion &&
          isValidResourceServiceVersion &&
          obj.connected &&
          (obj.installationStatus === '' || obj.installationStatus === 'InstallerDownloadFailed') &&
          obj.resourceServiceRemoteInstallSupported,
        enableInstallProgress: obj.installationStatus !== '' && obj.installationStatus !== 'InstallerDownloadFailed' && obj.installationStatus !== 'InstallationFailed' && obj.installationStatus !== 'TriggerInstallationFailed',
        installationStatusTime: fromServerDate(obj.installationStatusTime)
      };
    }
    );

    let clusterTableColumns = getClusterColumns(this.preferences, clusterTableRows);

    this.viewModel = {
      managers: model.managers,
      machines: model.machines,
      clusterTableRows: clusterTableRows,
      clusterTableColumns: clusterTableColumns
    };

    this.sortClusterTableRows();
    this.render();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }
    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.preferences);
  },


  sortClusterTableRows: function () {
    if (this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.viewModel.clusterTableColumns, this.columnsToSortBy);
      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => createObjectComparator(col.valueGetter || col.key, col.compareType, col.ascending)));
      this.viewModel.clusterTableRows = this.viewModel.clusterTableRows.sort(comparator);
    }
    this.render();
  },


  handleClusterTableContextMenu: function (clickedRow, selectedRows, e) {
    this.showContextMenu(this.tickableModel.getByNwid(clickedRow.nwid), this.selected, e);
  },

  handleSort: function (columnKey, multiSort) {
    this.columnsToSortBy = reduceColumnsToSortBy(this.viewModel.clusterTableColumns, this.columnsToSortBy, columnKey, multiSort);

    this.sortClusterTableRows();

    this.savePreferences({
      ...this.preferences,
      columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
    });
  },

  handleClusterTableColumnResize: function (columns, columnKey) {
    this.savePreferences({
      table: {
        ...this.preferences.table,
        columns: columns

      }
    });
  },


  handleRowSelect: function (selectedRows) {
    this.updateSelected(selectedRows.map(row => this.tickableModel.getByNwid(row.nwid)));
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <Cluster
        module={this}
        viewModel={this.viewModel}
      />);
  }
});
