import { toServerDate, toServerDateOnly, formatDate, MSEC_IN_MINUTE } from 'core/dates';
import settingsManager from 'core/managers/settings';
import requestManager from 'core/managers/request';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';
import { restGet, restPost } from 'core/managers/rest2';
import { createObjectComparator } from 'core/comparators';
import { fromServerDate } from 'core/dates';
import { DEADLINE_MODE, MODE } from './constants';
import { appendParameters } from 'utilities/url';

function addZone(model, publication, edition, stepZone) {
  const sourceId = (edition.sourceId + '/' + stepZone.displayName).toLowerCase();
  const planSetupZone = model.planSetup.getZone({ displayName: publication.displayName }, stepZone.displayName);

  const zone = {
    type: 'zone',
    displayName: stepZone.displayName,
    order: stepZone.order,
    sharingOrder: stepZone.sharingOrder,
    sections: [{ type: 'section', parent: sourceId, startPage: 1, oldStartPage: 1, numPages: 0, sourceId: null }],
    books: [],
    sourceId: sourceId,
    parent: edition.sourceId,
    code: stepZone.shortName,
    share: planSetupZone.share === 'true',
    color: planSetupZone.color,
    deadline: toServerDate(stepZone.deadline)
  }


  if (typeof planSetupZone.parent !== 'undefined' && planSetupZone.parent !== null &&
    planSetupZone.parent !== stepZone.displayName) {
    zone.masterZoneName = planSetupZone.parent;
    zone.masterZone = (edition.sourceId + '/' + planSetupZone.parent).toLowerCase();
  }
  return zone;
}

function getDefaultDeadline(issueDate, interval = 0) {

  const deadline = new Date(issueDate.getTime() + interval * MSEC_IN_MINUTE);

  return deadline;
}

function updateChaseModel(stepModel) {
  const ret = { isValid: false, error: '' };
  const plannedPublication = stepModel.publication.plannedPublication;
  if (!plannedPublication) {
    return ret;
  }

  const prevEdition = plannedPublication.editions.find(e => e.index === stepModel.edition.order - 1);
  if (!prevEdition) {
    return ret;
  }
  this.model.editionToChase = prevEdition.nwid;
  this.model.chaseMode = true;
  if (this.model.editionToLoad) {
    delete this.model.editionToLoad;
  }
  ret.isValid = true;
  return ret;
}

function updateLoadModel(stepModel) {
  const ret = { isValid: false, error: '' };
  const plannedPublication = stepModel.publication.plannedPublication;
  if (!plannedPublication) {
    return ret;
  }

  const edition = plannedPublication.editions.find(e => {
    return e.name == stepModel.edition.displayName
  });

  this.model.editionWasLoaded = true;
  this.model.editionToLoad = edition.nwid;
  this.model.zones = stepModel.zones.map(z => z.selected ? z.displayName : '').filter(Boolean).join(";");
  ret.isValid = true;
  return ret;
}

function getEditions(planSetup, publicationDescriptor) {
  var optionalEditions = [];
  var order = 0;
  var myEditions = planSetup.getEditions(publicationDescriptor.displayName);
  var lastIndex = publicationDescriptor.plannedPublication ?
    publicationDescriptor.plannedPublication.lastIndex : 0;

  myEditions.forEach((editionDescriptor) => {
    var obj = {
      displayName: editionDescriptor.name,
      shortName: editionDescriptor.shortName,
      order: order
    };

    obj.disabled = publicationDescriptor.standAloneEditions ? false : order > lastIndex;


    obj.loadOption = false;
    let plannedEditions = publicationDescriptor.plannedPublication ? publicationDescriptor.plannedPublication.editions : [];
    for (let i = 0; i < plannedEditions.length; i++) {
      if (plannedEditions[i].name === editionDescriptor.name) {
        obj.loadOption = true;
        break;
      }
    }
    order++;
    optionalEditions.push(obj);
  });

  return optionalEditions;
}

function getZones(planSetup, publicationDescriptor) {
  let ret = [];
  var planSetupZonesDescriptors = planSetup.getZones(publicationDescriptor);
  var zonesDescriptors = sortZones(planSetupZonesDescriptors);
  for (let i = 0; i < planSetupZonesDescriptors.length; i++) {
    //console.log ("getOptionalZones " + planSetupZonesDescriptors[i].name + ":" + i);
    let z = {
      changeFlag: "0", displayName: planSetupZonesDescriptors[i].name, order: i,
      sharingOrder: zonesDescriptors.indexOf(planSetupZonesDescriptors[i])
    };
    let plannedInEditions = publicationDescriptor.plannedPublication ?
      getPlannedEditionsForZone(publicationDescriptor.plannedPublication, z.displayName) : [];

    z.plannedInEditions = plannedInEditions;
    ret.push(z);
  }
  return ret;
}

function getPlannedEditionsForZone(plannedPublication, zoneName) {
  let ret = [];
  for (let i = 0; i < plannedPublication.editions.length; i++) {
    const editionDescriptor = plannedPublication.editions[i];
    const editionName = editionDescriptor.name;
    for (let j = 0; j < editionDescriptor.zones.length; j++) {
      if (editionDescriptor.zones[j].name.toLowerCase() == zoneName.toLowerCase()) {
        ret.push(editionName);
        break;
      }
    }
  }
  return ret;
}

function sortZones(zones) {
  var root = { children: [], unAssignNodes: [] };
  zones.forEach((zone) => {
    if (zone.parent === null || zone.parent === zone.name) {
      var node = { children: [], unAssignNodes: [], data: zone };
      root.children.push(node);
    } else {
      var parent = searchParent(root, zone);
      var newNode = { children: [], unAssignNodes: [], data: zone };
      if (parent === null) {
        root.unAssignNodes.push(newNode);
      } else {
        parent.children.push(newNode);
        for (var i = root.unAssignNodes.length - 1; i >= 0; i--) {
          var node = root.unAssignNodes[i];
          if (node.data.parent === newNode.data.name) {
            newNode.children.push(node);
          }
        }
      }
    }
  });

  var ret = [];
  root.children.forEach((node) => {
    collectChildren(ret, node);
  });
  return ret;
}

function collectChildren(ret, node) {
  ret.push(node.data);
  node.children.forEach((child) => {
    collectChildren(ret, child);
  });
}

function searchParent(root, zone) {
  var parent = zone.parent;
  for (var i = 0; i < root.children.length; i++) {
    var node = root.children[i];
    if (node.data.name === parent) {
      return node;
    } else {
      var ret = searchParent(node, zone);
      if (ret !== null) {
        return ret;
      }
    }
  }
  return null;
}

export default class ModelHandler {
  #model;
  #settings;
  #mode = MODE.CREATE;
  #stepModelByMode = {};
  #publicationFromTemplate = null;

  constructor(model, settings) {
    this.#model = model;
    this.#settings = settings;
  }

  get model() {
    return this.#model
  }

  get settings() {
    return this.#settings
  }


  getMode(mode) {
    return this.#mode;
  }

  setMode(mode) {
    this.#mode = mode;

    if (mode === MODE.DUPLICATE) {
      this.settings.wizard.disableButton('next');
    } else {
      this.settings.wizard.enableButton('next');
    }
  }

  getStepModel(mode) {
    return this.#stepModelByMode[mode];
  }

  setStepModel(mode, stepModel) {
    this.#stepModelByMode = {
      ...this.#stepModelByMode,
      [mode]: stepModel,
    };
  }

  getZoneDeadline(edition) {
    const stepModel = this.getStepModel(this.getMode());
    const issueDate = formatDate(stepModel.date, 'yyyy-M-d', 'en-US');
    return restGet(this.settings.viewNwid, `/folders/${this.settings.rootId}/planning-actions/default-zones-deadline-info?publicationDate=${issueDate}&publication=${stepModel.publication.displayName}&edition=${edition.displayName}`)
      .then((result) => {
        return result;
      })
      .catch(args => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ERROR " + args);
        return {};
      });
  }

  getPublications(date) {
    const { newPublicationsInfo, planSetup } = this.model;
    const wizardName = this.model.wizardFolder;
    let pubInfoByName;
    if (date) {
      const serverDate = toServerDateOnly(date);
      pubInfoByName = newPublicationsInfo.reduce((acc, pubInfo) => {
        if (pubInfo.date === serverDate) {
          acc[pubInfo.name] = pubInfo;
        }
        return acc;
      }, {});
    }

    const publications = planSetup.getPublications().reduce((acc, pubName) => {
      const pub = { displayName: pubName, shortName: planSetup.getPublication(pubName).shortName };
      pub.standAloneEditions = planSetup.getPublication(pubName).standAloneEditions === 'true';
      if (pubInfoByName?.[pubName]) {
        pub.loadOption = true;
        pub.plannedPublication = pubInfoByName[pubName];
        pub.planned = !!pub.plannedPublication;
        pub.validLoad = !this.settings.disableCrossWizardsPlanning || (pubInfoByName[pubName] && pubInfoByName[pubName].OriginInterfaceName === wizardName);
      }
      pub.editions = getEditions(planSetup, pub);
      pub.zones = getZones(planSetup, pub);

      acc.push(pub);

      return acc;

    }, []);

    return publications.sort(createObjectComparator('displayName'));
  }

  getEditions(publication) {
    if (!publication || !Array.isArray(publication.editions)) {
      return [];
    }

    const standalone = publication.standAloneEditions || false;
    const plannedEditions = publication.plannedPublication?.editions || [];
    let prevPlanned = true;
    const editions = publication.editions.map(edition => {
      const planned = plannedEditions.some(e => e.name === edition.displayName);
      const plannable = standalone || planned || prevPlanned;
      prevPlanned = planned;

      return {
        ...edition,
        planned,
        plannable
      }
    });

    return editions;
  }

  async getZones(date, publication, edition) {
    if (!publication || !edition || !Array.isArray(publication.zones)) {
      return [];
    }

    const plannedEditions = publication.plannedPublication?.editions || [];
    const plannedZones = plannedEditions.find(e => e.name === edition.displayName)?.zones || [];

    const needToGotoServer = this.model.checkZoneDeadLines && this.settings.zoneDeadlineMode === DEADLINE_MODE.MANDATORY;
    let deadline;
    if (this.settings.zoneDeadlineMode === DEADLINE_MODE.MANDATORY && date) {
      deadline = getDefaultDeadline(date, this.settings.presetTimeOffset);
    }

    let deadLineFromTable = {};
    if (needToGotoServer) {
      deadLineFromTable = await this.getZoneDeadline(edition);
      Object.keys(deadLineFromTable).forEach(key => {
        if (deadLineFromTable[key] && deadLineFromTable[key] !== "")
          deadLineFromTable[key] = fromServerDate(deadLineFromTable[key]);
      });

    }

    const zones = publication.zones.map(zone => {
      const plannedZone = plannedZones.find(z => z.name.toLowerCase() === zone.displayName.toLowerCase());
      const planned = !!plannedZone;
      return {
        ...zone,
        planned,
        selected: planned,
        deadline: plannedZone?.deadline ? fromServerDate(plannedZone.deadline) : deadLineFromTable[zone.displayName] || deadline,
      }
    });

    return zones;
  }

  convertFromStepModel() {
    let ret = {};

    const stepModel = this.getStepModel(this.getMode()) || {};
    const selectedZones = (stepModel.zones || []).filter(z => z.selected);
    if (selectedZones.length <= 0) {
      ret.error = 'Please select at least one zone'; // TODO: add translation
      return ret;
    }

    if (this.#publicationFromTemplate != null) {
      this.model.publication = this.#publicationFromTemplate;
      // maybe need to remove/add zones
      this.model.publication.editions[0].zones = this.model.publication.editions[0].zones.filter(function (z) {
        return selectedZones.some(e => e.displayName === z.displayName);
      });

      const newZones = selectedZones.filter((z) => {
        return !this.model.publication.editions[0].zones.some(e => e.displayName === z.displayName);
      });

      newZones.forEach((stepZone) => {
        this.model.publication.editions[0].zones.push(addZone(this.model, stepModel.publication, this.model.publication.editions[0], stepZone));
      });

      this.model.publication.editions[0].zones.sort(function (a, b) {
        return parseInt(a.order, 10) - parseInt(b.order, 10);
      });
      
      const planSetupPublication = this.model.planSetup.getPublication(this.model.publication.displayName);
      var continueNumbers = planSetupPublication.continuousNumberingBetweenSections;
      //continueNumbers = "true";
      if (continueNumbers === "false") {
        continueNumbers = false;
      } else if (continueNumbers === "true") {
        continueNumbers = true;
      }
      //continueNumbers = true;
      this.model.continueNumbers = continueNumbers;

      return { isValid: true, error: '' };
    }

    ret = { isValid: false, error: '' };

    if (!stepModel || !stepModel.date || !stepModel.publication || !stepModel.edition) {
      ret.error = 'Please select date, publication and edition';
      return ret;
    }

    const issueDate = formatDate(stepModel.date, 'yyyy-M-d', 'en-US');
    let prevEdition = null;

    let publication = {
      displayName: stepModel.publication.displayName,
      type: 'publication',
      sourceId: (this.model.folderPreFix + this.model.folder + '_/' + issueDate + '/' + stepModel.publication.displayName).toLowerCase(),
      manualPlan: true,
      issueDate: issueDate,
      standAloneEditions: stepModel.publication.standAloneEditions,
      code: stepModel.publication.shortName
    };

    const planSetupPublication = this.model.planSetup.getPublication(publication.displayName);

    const edition = {
      displayName: stepModel.edition.displayName,
      type: 'edition',
      sourceId: (publication.sourceId + '/' + stepModel.edition.displayName).toLowerCase(),
      parent: publication.sourceId,
      code: stepModel.edition.shortName
    };

    let zones = [];
    selectedZones.forEach((stepZone) => {
      const sourceId = (edition.sourceId + '/' + stepZone.displayName).toLowerCase();
      zones.push(addZone(this.model, publication, edition, stepZone));

    });
    edition.zones = zones;
    publication.editions = [edition];

    let sections = planSetupPublication.Sections;
    if (!(planSetupPublication.sections instanceof Array)) {
      sections = planSetupPublication.Sections.Section;
      if (!(sections instanceof Array)) {
        sections = [sections];
      }

      if (sections instanceof Array) {
        const productSorting = this.sectionSorting = settingsManager.get('productSorting').section;
        if (productSorting === "alphanumeric") {
          // sections.sort(function (a, b) {
          //   return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
          // });
          sections.sort(createObjectComparator('name'));
        } else {
          sections.sort(function (s1, s2) {
            var n1 = parseInt(s1['@index'], 10);
            var n2 = parseInt(s2['@index'], 10);
            return n1 - n2;
          });
        }

        publication.sectionsOptions = sections;
      }
    }
    this.model.publication = publication;
    var continueNumbers = planSetupPublication.continuousNumberingBetweenSections;
    //continueNumbers = "true";
    if (continueNumbers === "false") {
      continueNumbers = false;
    } else if (continueNumbers === "true") {
      continueNumbers = true;
    }
    //continueNumbers = true;
    this.model.continueNumbers = continueNumbers;
    
    if (stepModel.edition.loadOption) {
      return updateLoadModel.call(this, stepModel);
    } else if (!stepModel.publication.standAloneEditions && stepModel.edition.order > 0) {
      return updateChaseModel.call(this, stepModel);
    }
    ret.isValid = true;
    return ret;
  }

  getTemplates(publicationName) {
    if (!publicationName) {
      return [];
    }

    const { globalTemplates = {}, templates: localTemplates = {} } = this.model;

    let templates = Object.entries(globalTemplates).reduce((acc, [key, value]) => {
      if (value.some(name => name === publicationName)) {
        acc.push({ name: key, global: true });
      }

      return acc;
    }, []);

    templates = templates.concat((localTemplates[publicationName] || []).map(name => ({ name, global: false })));

    return templates;
  }

  activateTemplate(template) {
    const stepModel = this.getStepModel(this.getMode());
    if (!stepModel || !stepModel.date || !stepModel.publication || !template) {
      return Promise.reject('Invalid arguments');
    }

    this.settings.wizard.disableButton('next');
    const issueDate = formatDate(stepModel.date, 'yyyy-MM-dd', 'en-US');

    const params = {
      publicationDate: issueDate,
      publication: encodeURIComponent(stepModel.publication.displayName),
      template: encodeURIComponent(template),
      wizardFolder: this.model.wizardFolder
    };

    const url = appendParameters(`/folders/${this.settings.rootId}/planning-actions/activate-template`, params);

    return restPost(this.settings.viewNwid, url) // Todo - need testing
      .then((result) => {
        if (result.error) {
          toastService.errorToast('', translate('Failed to activate template {1}', template));
        } else {
          this.appendEditions(result.editions, result.newPublicationsInfo);
          toastService.successToast('', translate('The selected template have been successfully activated and added to the Plan Tree.'));
        }
      })
      .catch(err => {
        console.error("activateTemplate() =>", err);
        toastService.errorToast('', translate('Failed to activate template {1}', template));
      }).finally(() => {
        this.settings.wizard.enableButton('next');
      });
  }

  clearTemplate() {
    this.#publicationFromTemplate = null;
  }

  loadTemplate(templateName) {
    const stepModel = this.getStepModel(this.getMode());
    if (!stepModel || !stepModel.date || !stepModel.publication || !templateName) {
      return Promise.reject('Invalid arguments');
    }

    const issueDate = formatDate(stepModel.date, 'yyyy-M-d', 'en-US');
    return new Promise((resolve, reject) => {
      requestManager.getPlan(null, null, null, this.settings.projectorId,
        {
          rootId: this.settings.rootId,
          rootType: this.settings.rootType,
          instanceNwId: this.settings.viewNwid,
          mode: 'template',
          publicationName: stepModel.publication.displayName,
          publicationDate: issueDate,
          templateName: templateName
        }
      )
        .then(async res => {
          const publication = res.data.publication;
          if (!publication) {
            reject('No Publication');
          }

          publication.inActivationMode = false;
          publication.activated = false;

          this.#publicationFromTemplate = publication;

          const firstEdition = publication.editions[0];
          const { displayName, code } = firstEdition;

          const edition = {
            displayName,
            shortName: code,
            order: 0,
            disabled: false,
            planned: false,
            plannable: true
          };

          const zones = await this.getZones(stepModel.date, stepModel.publication, edition);
          zones.forEach(zone => {
            zone.planned = false;
            zone.selected = firstEdition.zones.some(z => z.displayName === zone.displayName);
          });

          resolve({ ...stepModel, edition, zones });
        })
        .fail(res => {
          toastService.errorToast('', translate('Failed to load template {1}', templateName));

          reject(res);
        });
    });
  }

  appendEditions(editions = [], newPublicationsInfo = []) {
    this.model.editions = this.model.editions.concat(editions);
    this.model.newPublicationsInfo = this.model.newPublicationsInfo.concat(newPublicationsInfo);
  }
}


