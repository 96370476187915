import React, { Component } from 'react';
import ReactDom from 'react-dom';
import jQuery from 'jquery';
import PropTypes from 'prop-types';

class ScrollablePanels extends Component {

  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    selectedIndex: PropTypes.number,
    onSelectedChange: PropTypes.func
  }

  static defaultProps = {
    style: {},
    selectedIndex: 0,
    onSelectedChange: () => { },
    children: []
  }

  propagateIndexChanged = true;

  constructor(props) {
    super(props);
    const { selectedIndex, children } = this.props;
    const selectedPanelIndex = selectedIndex >= 0 && selectedIndex <= children.length ? selectedIndex : 0;
    this.state = { selectedPanelIndex };
  }

  scrollToContent = (index, shouldPropagate = true) => {
    return (event) => {
      const scrollElement = ReactDom.findDOMNode(this.refs[`scrollElement`]);
      const contentElement = ReactDom.findDOMNode(this.refs[`element${index}`]);

      if (scrollElement != null && contentElement != null) {
        const offsetTop = contentElement.offsetTop;

        jQuery(scrollElement).stop().animate({
          scrollTop: offsetTop
        }, {
            duration: 200,
            start: () => {
              this.propagateIndexChanged = false;
            },
            complete: () => {
              this.propagateIndexChanged = true;
            }
          });

      }
    };
  };

  renderleftScrollablle = () => {
    const { children } = this.props;
    const { selectedPanelIndex } = this.state;
    const leftScrollablle = children.map((child, index) => {
      const selectedIndexClassName = selectedPanelIndex === index ? 'selectedIndex' : '';
      const iconPanel = <div className='crtx-icon-panel'><img src={child.props.icon} /></div>;
      const titlePanel = <div className='crtx-title-panel'><span>{child.props.title}</span></div>;

      return <div className={'crtx-left-panel ' + selectedIndexClassName} key={index} onClick={() => {
        this.handleChangeIndex(index, true);
      }}>{iconPanel}{titlePanel}</div>;
    });
    return leftScrollablle;
  };

  renderContentScrollablle = () => {
    const { children } = this.props;
    const content = children.map(function (child, index) {
      return React.cloneElement(child, { ref: `element${index}`, key: index });
    });
    return content;
  };

  getCurrentIndex = () => {
    const { children } = this.props;
    const scrollElement = ReactDom.findDOMNode(this.refs[`scrollElement`]);
    const currentScrollTopPos = scrollElement.scrollTop;

    return children.findIndex((child, index) => {
      const contentElement = ReactDom.findDOMNode(this.refs[`element${index}`]);
      const offsetTopContent = contentElement.offsetTop;
      const offsetBottomContent = offsetTopContent + contentElement.clientHeight;

      if (currentScrollTopPos >= offsetTopContent && currentScrollTopPos < offsetBottomContent) {
        return true;
      }

      return false;
    });
  };


  handleScroll = () => {
    const currentIndex = this.getCurrentIndex();

    if (currentIndex >= 0) {
      this.handleChangeIndex(currentIndex);
    }
    else {
      this.handleChangeIndex(0);
    }
  };

  handleChangeIndex = (index, shouldPropagate = true) => {
    const { selectedIndex, onSelectedChange } = this.props;
    const { selectedPanelIndex } = this.state;

    if (this.propagateIndexChanged === false) return;
    if (selectedPanelIndex === index) return;

    this.setState({
      selectedPanelIndex: index
    });

    if (shouldPropagate) onSelectedChange(index);
  };

  componentDidMount() {
    this.scrollToContent(this.props.selectedIndex, false)();
  }

  componentDidUpdate(prevProps) {
    const currentIndex = this.getCurrentIndex();

    if (currentIndex !== this.state.selectedPanelIndex) {
      this.scrollToContent(this.state.selectedPanelIndex, false)();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { selectedIndex } = this.props;
    const { children } = nextProps;

    if (selectedIndex === nextProps.selectedIndex) return;

    const propsSelectedIndex = nextProps.selectedIndex >= 0 && nextProps.selectedIndex < children.length ? nextProps.selectedIndex : 0;

    if (propsSelectedIndex !== this.state.selectedPanelIndex) {
      this.setState({
        selectedPanelIndex: propsSelectedIndex
      });
    }
  }

  render() {
    const { className, style, children } = this.props;

    return <div className={`crtx-scrollablle-panels ${className}`} style={style}>
      <div className='crtx-left-scrollablle'>
        {this.renderleftScrollablle()}
      </div>
      <div ref='scrollElement' className='crtx-content-scrollablle' onScroll={this.handleScroll}>
        {this.renderContentScrollablle()}
      </div>
    </div>;
  }

};

export default ScrollablePanels;