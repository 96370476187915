import PropTypes from 'prop-types';
import React, { Component } from 'react';

const isFunction = o => typeof o === 'function';

export default class Canvas extends Component {
  static propTypes = {
    subscribe: PropTypes.func,
    canvasDraw: PropTypes.func,
    onLoad: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.unsubscribe = props.subscribe(this);
  }

  componentDidMount() {
    const { onLoad } = this.props;

    if (isFunction(onLoad)) onLoad(this);

    this.props.canvasDraw();
  }

  componentDidUpdate() {
    this.props.canvasDraw();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return null;
  }
}