import React from 'react';
import icons from 'core/services/iconService';
import localization from 'core/services/localization';
import { RESOURCE_STATE_OPTIONS, TOGGLE_RESOURCE_OPTIONS, GLOBAL_RESOURCE_OPTIONS, DEVICE_TYPE_DISPLAY_MAP } from './constants';
import { arrayToDistinctEntries } from 'utilities/array';
import { COMPARE_TYPE } from 'core/comparators';
import { cells } from 'widgets/ReactDataGrid/';
import FlatButton from 'components/common/buttons/FlatButton';
import { arrangeItemsByKeys } from 'utilities/array';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';
import { getAllDistinctFolders } from './utils';
import { applyColumnPreferences, FILTER, NO_VALUE } from 'widgets/ReactDataGrid/utils';

const { Text, IconCell } = cells;

const { translate } = localization;

export const makeResourcesTableColumns = (viewModel, module) => {
  const columnPrefs = module.preferences.table && module.preferences.table.resourceColumns || [];
  let columns = [
    {
      key: 'toggleResource',
      width: 90,
      align: 'center',
      caption: translate('On/Off'),
      cell: IconCell,
      filter: {
        options: TOGGLE_RESOURCE_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      filterValueGetter: getToggleResourceDistinctiveValue,
      sortType: COMPARE_TYPE.NUMBERS,
      sortValueGetter: resource => resource.on ? 0 : 1,
      cellDataGetter: (rowIndex, key) => {
        const resourceStatus = viewModel.resources[rowIndex] ? viewModel.resources[rowIndex].on ? 'on' : 'off' : '';
        if (!resourceStatus) {
          return;
        }
        const title = resourceStatus === 'on' ? translate('Turn Resource Off') : translate('Turn Resource On');

        return {
          icon: icons.getGeneralIcon('resource', resourceStatus),
          onClick: e => module.toggleResource(viewModel.resources[rowIndex]),
          title
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'abortResource',
      width: 50,
      align: 'center',
      caption: translate('Abort'),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          icon: icons.getGeneralIcon('resource', 'abort_resource'),
          onClick: e => module.abortResource(viewModel.resources[rowIndex]),
          title: translate('Abort Resource')
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'resourceState',
      width: 80,
      align: 'center',
      caption: translate('State'),
      cell: IconCell,
      filter: {
        options: RESOURCE_STATE_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      filterValueGetter: getResourceStateDistinctiveValue,
      sortType: COMPARE_TYPE.NUMBERS,
      sortValueGetter: resource => module.getResourceLedIcon(resource).priority,
      cellDataGetter: (rowIndex, key) => {
        const ledIcon = module.getResourceLedIcon(viewModel.resources[rowIndex]);
        if (!ledIcon) {
          return;
        }
        return {
          icon: (ledIcon && ledIcon.url) || '',
          title: (ledIcon && translate(ledIcon.title)) || ''
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'name',
      width: 150,
      align: 'left',
      caption: translate('Name'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.resources[rowIndex] && viewModel.resources[rowIndex].name || '',
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'type',
      width: 150,
      align: 'left',
      caption: translate('Device Type'),
      cell: Text,
      filter: {
        options: createMultiselectFilterOptions(viewModel.resources, getResourceType, getResourceDeviceTypeOptionData),
        ...FILTER.MULTISELECT_TEXT,
      },
      filterValueGetter: getResourceType,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: getResourceType,
      cellDataGetter: (rowIndex, key) => {
        const columnData = getResourceType(viewModel.resources[rowIndex]);
        return {
          columnData
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'machineName',
      width: 150,
      align: 'left',
      caption: translate('Machine Name'),
      cell: Text,
      filter: {
        options: createMultiselectFilterOptions(viewModel.resources, getResourceMachineName, getResourceMachineNameOptionData),
        ...FILTER.MULTISELECT_TEXT,
      },
      filterValueGetter: getResourceMachineName,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: getResourceMachineName(viewModel.resources[rowIndex]),
          title: viewModel.resources[rowIndex] && `${viewModel.resources[rowIndex].machineName}: ${viewModel.resources[rowIndex].machineAddress}` || ''
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'desc',
      width: 150,
      align: 'left',
      caption: translate('Path'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.resources[rowIndex] && viewModel.resources[rowIndex].desc || '',
          ellipsisStart: true
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'message',
      width: 150,
      align: 'left',
      caption: translate('Device Message'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.resources[rowIndex] && viewModel.resources[rowIndex].message || '',
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'locked',
      width: 90,
      align: 'center',
      caption: translate('Locked'),
      cell: Text,
      sortType: COMPARE_TYPE.NUMBERS,
      cellDataGetter: (rowIndex, key) => {
        const data = viewModel.resources[rowIndex] && getResourceLockedCellData(viewModel.resources[rowIndex]);
        return {
          columnData: data || '',
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'flowSteps',
      width: 150,
      align: 'left',
      caption: translate('Flow Steps'),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: resource => getResourceBreadcrumbsFromFlowSteps(resource).join(', '),
      cellDataGetter: (rowIndex, key) => {
        const breadcrumbsArr = getResourceBreadcrumbsFromFlowSteps(viewModel.resources[rowIndex]);
        const columnData = breadcrumbsArr && breadcrumbsArr.join(', ');
        const title = breadcrumbsArr && breadcrumbsArr.join('\n');
        return {
          columnData,
          title,
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'folders',
      width: 150,
      align: 'left',
      caption: translate('Folders'),
      cell: Text,
      filter: {
        options: createFoldersMultiselectFilterOptions(viewModel.resources, getResourceFolders),
        ...FILTER.MULTISELECT_TEXT,
      },
      filterValueGetter: getResourceFolders,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: getResourceFolderNames,
      cellDataGetter: (rowIndex, key) => {
        const columnData = getResourceFolderNames(viewModel.resources[rowIndex]);
        const title = getResourceFolderNames(viewModel.resources[rowIndex]);
        return {
          columnData,
          title
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'global',
      width: 85,
      align: 'left',
      caption: translate('Global'),
      cell: Text,
      filter: {
        options: GLOBAL_RESOURCE_OPTIONS,
        ...FILTER.MULTISELECT_TEXT,
      },
      filterValueGetter: getResourceGlobalDistinctiveValue,
      sortType: COMPARE_TYPE.NUMBERS,
      sortValueGetter: resource => resource.global ? 0 : 1,
      cellDataGetter: (rowIndex, key) => {
        const { global } = viewModel.resources[rowIndex];
        const columnData = typeof global !== 'undefined' ? global ? translate('Global') : translate('Local') : undefined;
        return {
          columnData,
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'edit',
      width: 70,
      align: 'center',
      caption: translate('Edit'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const currentResource = viewModel.resources[rowIndex];
        if (!currentResource) {
          return;
        }
        const resourceEditable = module.canEditResource(currentResource);
        const onEditClick = e => module.editResource(currentResource);
        return {
          columnData: getEditResourceCellData(resourceEditable, onEditClick),
          title: translate('Edit Resource')
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'delete',
      width: 85,
      align: 'center',
      caption: translate('Delete'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const currentResource = viewModel.resources[rowIndex];
        if (!currentResource) {
          return;
        }
        const resourceRemovable = module.canRemoveResource(currentResource);
        const onDeleteClick = e => module.removeResource(currentResource);
        return {
          columnData: getRemoveResourceCellData(resourceRemovable, onDeleteClick),
          title: translate('Remove Resource')
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    }
  ];

  const keys = columnPrefs.map(col => col.key);
  columns = arrangeItemsByKeys(columns, keys);

  columns = applyColumnPreferences(columns, columnPrefs);

  return module.viewType === 'folder' ? columns.filter(col => col.key !== 'delete') : columns;
};

const getResourceBreadcrumbsFromFlowSteps = resource => {
  const data = resource && resource.flowSteps || '';
  const breadcrumbsArr = data && data.map(d => formatBreadcrumbs(d.breadcrumbs)) || [];
  return breadcrumbsArr;
};

const getResourceType = resource => resource && DEVICE_TYPE_DISPLAY_MAP[resource.type] || '';

const getResourceFolderNames = resource => resource && (resource.folders || []).map(folder => folder.name).join(', ') || '';

const getResourceMachineName = resource => resource && resource.machineName || '';

const getResourceLockedCellData = resource => {
  return resource.locked === 0 ? '' : (
    <label>
      <img src={icons.getGeneralIcon('status', 'locked')} alt={translate('Locked')} style={{ marginRight: 5 }} />
      {resource.locked}
    </label>
  );
};

const getEditResourceCellData = (resourceEditable, onEditClick) => (
  <FlatButton
    style={{ display: 'inline-flex' }}
    className='flat-button'
    tooltip={translate('Edit Resource')}
    disabled={!resourceEditable}
    onClick={onEditClick}
  >
    <img src={icons.getGeneralIcon(null, 'edit')} />
  </FlatButton>
);

const getRemoveResourceCellData = (resourceRemovable, onDeleteClick) => (
  <FlatButton
    className='flat-button'
    style={{ display: 'inline-flex' }}
    tooltip={translate('Remove Resource')}
    disabled={!resourceRemovable}
    onClick={onDeleteClick}
  >
    <img src={icons.getGeneralIcon(null, 'delete')} />
  </FlatButton>
);

const getResourceStateDistinctiveValue = resource => {
  let value;

  if (!resource) {
    return;
  }

  if (resource.humanStateDescription === 'Up') {
    value = 'alive';
  } else if (resource.humanStateDescription === 'Down') {
    value = 'dead';
  } else {
    value = 'unknown';
  }

  return value;
};

const getToggleResourceDistinctiveValue = resource => resource.on ? 'on' : 'off';

const getResourceGlobalDistinctiveValue = resource => resource.global ? 'Global' : 'Local';

const getResourceFolders = resource => {
  if (!Array.isArray(resource.folders)) {
    return [];
  }
  return resource.folders.map(folder => folder.name);
};

const getResourceMachineNameOptionData = resource => ({ columnData: resource && resource.machineName || NO_VALUE });

const getResourceDeviceTypeOptionData = resource => ({ columnData: resource && DEVICE_TYPE_DISPLAY_MAP[resource.type] || NO_VALUE });

const createFoldersMultiselectFilterOptions = (resources, filterValueGetter) => {

  const folders = getAllDistinctFolders(resources, filterValueGetter);
  const filterOptions = folders.map(folder => ({
    value: folder,
    data: { columnData: folder }
  }));

  return filterOptions;
};

const createMultiselectFilterOptions = (resources, filterValueGetter, filterOptionDataGetter) => {

  const entries = arrayToDistinctEntries(resources, filterValueGetter);
  const filterOptions = entries.map(([value, resource]) => ({
    value,
    data: filterOptionDataGetter(resource)
  }));

  return filterOptions;
};
