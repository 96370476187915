import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import ConversionInput from './ConversionInput';
import {translate} from 'core/services/localization';

const SERVER_UNIT = 'inch';
const FACTOR_BY_UNIT = {
  mm: 25.4,
  inch: 1,
  pt: 72
};

const DECIMALS_BY_UNIT = {
  mm: 3,
  inch: 4,
  pt: 2
};

const SYMBOL_BY_UNIT = {
  inch: translate('in'),
  mm: translate('mm'),
  pt: translate('pt'),
};

function LengthInput({
                       unit = SERVER_UNIT,
                       ...props
                     }, ref) {

  return (
    <ConversionInput
      {...props}
      ref={ref}
      unitSymbol={SYMBOL_BY_UNIT[unit]}
      factor={FACTOR_BY_UNIT[unit]}
      decimalPlaces={DECIMALS_BY_UNIT[unit]}
    />
  );
}

const LengthInputWithRef = forwardRef(LengthInput);

LengthInputWithRef.propTypes = {
  ...ConversionInput.propTypes,
  unit: PropTypes.string,
};

export default LengthInputWithRef
