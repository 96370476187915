import ServerAction from './ServerAction';
import sandbox from 'sandbox';

const isUndefined = o => typeof o === 'undefined';

export default ServerAction.extend({

  isApplicable: function () {
    return this.module.selected.length === 1 && this.module.selected[0].apiToken !== "" ? true : false;
  },

  execute: function(args) {
    this._super(args).done(() => {
      sandbox.reloadModule(this.module.id);
    });
  }
});
