import PropTypes from 'prop-types';
import React from 'react';
import CanvasComponent from './canvasComponent';

const isUndefined = o => typeof o === 'undefined';

export default class Rect extends CanvasComponent {
  static propTypes = {
    strokeStyle: PropTypes.string.isRequired,
    fillStyle: PropTypes.string,
    labelStyle: PropTypes.string,
    lineWidth: PropTypes.number,
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    fillAlpha: PropTypes.number,
    strokeAlpha: PropTypes.number,
    label: PropTypes.string
  };
  static defaultProps = {
    lineWidth: 1,
    fillAlpha: 1,
    strokeAlpha: 1,
    labelStyle: "#000000"
  };

  draw = (ctx) => {
    const { strokeStyle, fillStyle, lineWidth, point, width, height, fillAlpha, strokeAlpha, label, labelStyle } = this.props;

    ctx.beginPath();

    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;
    ctx.globalAlpha = strokeAlpha;
    ctx.strokeRect(0, 0, width, height);
    ctx.stroke();

    if (!isUndefined(fillStyle)) {
      ctx.save();
      ctx.fillStyle = fillStyle;
      ctx.globalAlpha = fillAlpha;
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    }

    if (!isUndefined(label)) {
      ctx.save();
      ctx.fillStyle = strokeStyle;
      ctx.fillRect(0, 0, label.length * 15, 25);//small colored box for text
      ctx.stroke();
      ctx.fillStyle = labelStyle;
      ctx.font = "18pt sans-serif";
      ctx.fillText(label, 0, 20);
      ctx.restore();
    }

    ctx.closePath();
  };
}