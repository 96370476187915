/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 */

define(['sandbox', 'go'], function (sandbox, go) {
  'use strict';

  function GraphOverview(model, preferences, div) {
    var make = go.GraphObject.make,  // for conciseness in defining templates
      setSaveTimeout = false,
      typeToSvgOjs = {},
      SAVE_TIMEOUT = 1000,
      that = this,
      canvas, ctx,
      NODE_MIN_SIZE = new go.Size(54, 54),
      ICON_MARGIN = new go.Margin(3, 0, 0, 3),
      COLORS = {
        original:   'transparent',
        hover:      '#eff5ff',
        selected:   '#b4d2ff',
        line:       '#aec7d5',
        background: '#f0f0f0'
      };

    function createNodesPref() {
      var nodes = {};
      for (var i = 0; i < model.flowSteps.length; i += 1) {
        var flowStep = model.flowSteps[i];
        nodes[flowStep.nwid] = {
          "compId": flowStep.nwid,
          "percentageX": 0,
          "percentageY": 0
        };
      }
      return nodes;
    }

   /* function saveEvent(event) {
      if (setSaveTimeout) {
        return;
      }
      setSaveTimeout = true;
      setTimeout(function () {
        // TODO: send saved model to server
        setSaveTimeout = false;
      }, SAVE_TIMEOUT);
    }
*/
    function insertSvgToNodes(typeToSvg) {
      var nodeIterator = that.graph.nodes;
      while (nodeIterator.next()) {
        var node = nodeIterator.value,
          panel = node.findObject("Icon"),
          svg = typeToSvg[
          (node.data.iconName && node.data.iconName !== "void") ? node.data.type + "/" + node.data.iconName : node.data.type],
          gObj;
        if (typeof svg !== 'undefined') {
          gObj = go.GraphObject.fromSvg(svg);
          gObj.margin = ICON_MARGIN;
        } else {
          gObj = make(go.Shape,
            {
              width: 36,
              height: 40,
              figure: "RoundedRectangle",
              margin: new go.Margin(7, 0, 0, 8),
              fill: "gray"
            });
        }
        panel.add(gObj);
      }
    }

    this.refreshModel = function () {
      var types = model.flowSteps.map(function (e) {
        if (e.iconName && e.iconName !== "void"){
          return e.type + "/" + e.iconName;
        } else {
          return e.type;
        }
      });
      sandbox.icons.getSVGIcons(types, function () {
        var typeToSvg = {};
        for (var i = 0; i < types.length; i++) {
          if (typeof arguments[i] !== 'undefined') {
            typeToSvg[types[i]] = arguments[i];
          }
        }
        that.graph.model = make(go.GraphLinksModel,
          {
            nodeKeyProperty: "nwid",
            linkFromKeyProperty: "sourceStep",
            linkToKeyProperty: "targetStep",
            nodeDataArray: model.flowSteps,
            linkDataArray: model.transitions
          });
        insertSvgToNodes(typeToSvg);
        //that.graph.model.addChangedListener(saveEvent.bind(that));
      });
    };

    // When a Node is double clicked, open the resource view
    function nodeDoubleClick(event, node) {
      this.module.navigateByViewLink(node.data);
    }

    function nwid2Point(nwid) {
      return new go.Point(preferences.nodes[nwid].percentageX,
        preferences.nodes[nwid].percentageY);
    }

    function updatePoint(point, entry) {
      preferences.nodes[entry.nwid].percentageX = point.x;
      preferences.nodes[entry.nwid].percentageY = point.y;
      return entry.nwid;
    }

    this.defineGraphContextMenu = function () {
      var cxMenu = that.graph.toolManager.contextMenuTool;

      cxMenu.showContextMenu = function (contextmenu, object) {
        var selected = that.graph.selection.toArray().map(function (e) {return e.data; });
        that.module.showContextMenu(object.data, selected, that.graph.lastInput.event);

        // Remember
        this.currentContextMenu = contextmenu;
      };
      cxMenu.hideContextMenu = function () {
        if (this.currentContextMenu === null) {
          return;
        }
        this.currentContextMenu = null;

        // InputEvent object NOT js object
        var inputEvent = that.graph.lastInput;

        //the key is a String
        if (inputEvent.key !== "Esc") {
          return;
        }
        inputEvent.bubbles = true;
      };
    };

    this.defineGraphTemplates = function () {
      // define node template
      this.graph.nodeTemplate = make(go.Node, "Auto",
        new go.Binding("location", "nwid", nwid2Point).makeTwoWay(updatePoint),
        make(go.Panel, go.Panel.Vertical,
          make(go.Panel, {
            name: 'Icon',
            portId: "",
            toSpot: new go.Spot(0, 0.5, 0, 0),
            fromSpot: new go.Spot(1, 0.5, 0, 0),
            minSize: NODE_MIN_SIZE
          },
            new go.Binding("background", "isSelected", function (s) { return (s ? COLORS.selected : COLORS.original); }).ofObject("")
          )),
        {
          toolTip:  make(go.Adornment, go.Panel.Auto,// define a tooltip for each node that displays the name as text
              make(go.TextBlock,{areaBackground: COLORS.hover},
                  new go.Binding("text", "label"))
          ),  // end of Adornment
          contextMenu: make(go.Adornment, go.Panel.Horizontal),
          doubleClick: nodeDoubleClick.bind(this),
          selectionAdorned: false, // no Adornment: instead change panel background color by binding to Node.isSelected
          mouseEnter: function (input, node) {
            if (node.isSelected) {
              return;
            }
            node.findObject("Icon").background = COLORS.hover;
          },
          mouseLeave: function (input, node) {
            if (node.isSelected) {
              return;
            }
            node.findObject("Icon").background = COLORS.original;
          }
        }
      );

      // define link template
      this.graph.linkTemplate = make('Link', {
          routing: go.Link.AvoidsNodes,
          curve: go.Link.JumpOver,
          selectable: false
        },
        make(go.Shape, {
            strokeWidth: 2,
            stroke: COLORS.line
          }
        ),
        make(go.Shape, {
            toArrow: "OpenTriangle",
            strokeWidth: 2,
            stroke: COLORS.line
          }
        )
      );
    };

    this.render = function () {
      this.graph = make(go.Diagram, div, {
        initialContentAlignment:  go.Spot.Center,
        autoScale: go.Diagram.Uniform,
        hasHorizontalScrollbar:   false,
        hasVerticalScrollbar:     false,
        allowMove:                false
      });

      canvas = sandbox.dom.find('canvas', that.graph.div)[0];
      ctx = canvas.getContext('2d');
      this.defineGraphTemplates();
      // enable Ctrl-Z to undo and Ctrl-Y to redo
      // (should do this after assigning Diagram.model)
      this.graph.undoManager.isEnabled = true;

      //if preferences exists we would use saved layout
      //var initialLayout = new go.Layout();
      if (typeof preferences === 'undefined') {
        preferences = {};
      }
			preferences = {};

      this.defineGraphContextMenu();

      if (typeof preferences.nodes === 'undefined' || preferences.nodes === {}) {
        preferences.nodes = createNodesPref();
        this.graph.layout = new go.LayeredDigraphLayout();
      }

      this.refreshModel();

    };

    this.destroy = function () {
   //   this.graph.model.removeChangedListener(saveEvent);
      delete preferences.nodes;
      delete model.flowSteps;
      delete model.transitions;
      delete this.graph;
    };

  }
  return GraphOverview;
});