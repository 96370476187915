import React, { Component } from 'react';
import Popup from '../popup/Popup';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { SearchInput } from 'components/common/inputs/SearchInput';

const MIN_OPTIONS_TO_SHOW_FILTER = 5;

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      searchValue: '',
      filteredOptions: this.props.options
    };
  }

  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    textProp: PropTypes.string,
    valueProp: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.any
    ]),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onSelect: PropTypes.func,
    itemGetter: PropTypes.func,
    onPopupOpen: PropTypes.func,
    itemStyleGetter: PropTypes.func,
    filterEnabled: PropTypes.bool,
    optionsWidth: PropTypes.string
  };

  static defaultProps = {
    value: '',
    textProp: 'text',
    valueProp: 'value',
    options: [],
    onClick: function () { },
    onDoubleClick: function () { },
    onSelect: function () { },
    onPopupOpen: () => { },
  };

  getOptionValue(option) {
    return typeof option === 'string' || typeof option === 'number' ? option : option[this.props.valueProp];
  };

  getOptionText(option) {
    if (typeof option === 'undefined') {
      return '';
    }

    return typeof option === 'string' || typeof option === 'number' ? option : option[this.props.textProp];
  };

  getOptionContent(option, index, defaultContent) {
    const { itemGetter } = this.props;

    return typeof itemGetter === 'function' ? itemGetter(option, index) : defaultContent;
  };

  findOption(value) {
    const { options } = this.props;

    return options.find(opt => this.getOptionValue(opt) === value);
  };

  filterOptions = (options, filterValue) => {
    let filteredOptions;
    if (!filterValue) {
      filteredOptions = options;
    } else {
      filteredOptions = options.filter(option => {
        let filteredOption;
        if (typeof option === 'string') {
          filteredOption = option.toLowerCase().includes(filterValue.toLowerCase());
        } else if (typeof option === 'number') {
          filteredOption = String(option).includes(filterValue);
        } else {
          filteredOption = option[this.props.textProp].toLowerCase().includes(filterValue.toLowerCase());
        };

        return filteredOption;
      });
    };

    return filteredOptions;
  };

  handleSearchValueChange = (event, newValue) => {
    const filteredOptions = this.filterOptions(this.props.options, newValue);
    this.setState({
      searchValue: newValue,
      filteredOptions
    });
  };

  renderOptions() {
    const { options } = this.props;
    const { filteredOptions, searchValue } = this.state;

    const updatedOptions = !searchValue ? options : filteredOptions;
    const filterEnabled = this.isFilterVisible();

    return <div className={classNames({ 'crtx-dropdown-content-container': filterEnabled })}>
      {filterEnabled ?
        <SearchInput onChange={this.handleSearchValueChange} value={searchValue} />
        : null}
      <ul className={classNames('crtx-dropdown-options', { scroll: filterEnabled })}>
        {updatedOptions.map(this.renderOption)}
      </ul>
    </div>
  };

  renderOption = (option, index) => {
    const selectedValue = this.props.value;
    const text = this.getOptionText(option);
    const value = this.getOptionValue(option);
    const itemClassName = classNames('crtx-dropdown-option', { selected: value === selectedValue });
    const itemStyle = typeof this.props.itemStyleGetter === 'function' ? this.props.itemStyleGetter(option, index) : {};
    const content = this.getOptionContent(option, index, text);

    return <li value={value}
      className={itemClassName}
      style={itemStyle}
      key={index}
      onClick={event => this.handleOptionSelect(event, option, index)}
      title={text}>
      {content}
    </li>
  };

  handleOptionSelect(event, option, index) {
    const { onSelect } = this.props;
    const value = this.getOptionValue(option);

    this.closePopup();

    onSelect(event, value, index);
  };

  togglePopup = () => {
    const { disabled, onPopupOpen } = this.props
    if (!disabled) {
      this.setState({
        visible: !this.state.visible
      }, () => onPopupOpen(this.state.visible));
    }
  };

  closePopup = () => {
    const { onPopupOpen, options } = this.props;
    this.setState({
      visible: false,
      searchValue: '',
      filteredOptions: options
    }, () => onPopupOpen(this.state.visible));
  };

  handleDropdownDoubleClick = (event) => {
    this.props.onDoubleClick(event)
  };

  handleDropdownClick = (event) => {
    this.props.onClick(event)
    this.togglePopup();
  };

  handleTextInputClick = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  isFilterVisible = () => {
    const { visible } = this.state;
    const { options, filterEnabled } = this.props;
    let showFilter = false;
    if (visible) {
      if (filterEnabled || (typeof filterEnabled === 'undefined' && options.length > MIN_OPTIONS_TO_SHOW_FILTER)) {
        showFilter = true;
      };
    };

    return showFilter;
  };

  render() {
    const { id, value, className, style, disabled, optionsWidth } = this.props;
    const { visible } = this.state;
    const dropdownClassName = classNames('crtx-dropdown', className, { disabled });

    const tabIndex = !disabled ? '0' : undefined;
    const selectedOption = this.findOption(value);
    const text = this.getOptionText(selectedOption);
    const content = this.getOptionContent(selectedOption, -1, text);

    return <div ref='dropdown'
      id={id}
      tabIndex={tabIndex}
      className={dropdownClassName}
      style={style}
      title={text}
      onDoubleClick={this.handleDropdownDoubleClick}
      onClick={this.handleDropdownClick}
    >
      <div className='crtx-dropdown-content'>{content}</div>
      <div className={classNames('crtx-dropdown-handler', { disabled })}>▼</div>
      <Popup opener={this.refs.dropdown} visible={visible} width={optionsWidth} onClickedOutside={this.closePopup} className={className} filterEnabled={this.isFilterVisible()}>
        {visible ? this.renderOptions() : null}
      </Popup>
    </div>;
  }
}

module.exports = Dropdown;