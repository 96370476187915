import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'components/common/buttons/SvgIcon';
import TextInput from 'components/common/inputs/TextInput';
import { classNames } from 'utilities/classNames';

export function SearchInput({ className, ...props }) {

  return <div className={classNames('crtx-search-input', className)}>
    <SvgIcon
      key='search'
      name='search'
      className={classNames('crtx-search-input-icon')}
    />
    <TextInput className={classNames('crtx-search-input-filter')} autoFocus={true} {...props} />
  </div>;
}

SearchInput.propTypes = {
  className: PropTypes.string
};