import React from 'react';
import sandbox from 'sandbox';
import PageView, { getContent, getSepContent } from '../NewPageView/NewPageView';
import { CanvasImage } from 'components/common/canvas';
import { loadImage, getViewportPoints, mergeImagesHires } from '../NewPageView/utilities';

const isUndefined = o => typeof o === 'undefined';
const translate = sandbox.localization.translate;
const missingPageFull = "../kernel/assets/img/module/PageView/missing_page.png";

const TILE_WIDTH = 2048;
const TILE_HEIGHT = 2048;
const MAX_ZOOM = 15;
const MIN_ZOOM = 0.7;
const BASIC_SEPARATIONS = ['cyan', 'magenta', 'yellow', 'black'];

const SEP_BY_COLOR = {
  cyan: 'cyanSep',
  magenta: 'magentaSep',
  yellow: 'yellowSep',
  black: 'blackSep',
};

function toServerKey(key) {
  return SEP_BY_COLOR[key] || key;
}

export default PageView.extend({

  initDone: function () {
    this._super();

    if (!this.toolbar) {
      this.createMyToolbar();
    }
  },

  firstTickReceived: function (data, type) {
    const { windowRef } = this.store.getState();
    const isFragment = data.model.type === 'fragment/content';

    this._super(data);
    this.store.dispatch({
      type: 'TOGGLE_NAVIGATOR',
      showNavigator: true
    });
    this.store.dispatch({
      type: 'STARTUP_STATE',
      zoom: 1,
      fitMode: '',
    });

    this.store.dispatch({
      type: 'UPDATE_SHOW_VERSIONS',
      showVersions: false
    });

    const separations = data.model.separations.filter(sep => BASIC_SEPARATIONS.includes(sep.colorType.toLowerCase()));
    this.store.dispatch({
      type: 'SET_SEPARATIONS',
      separations
    });

    // High resolution view
    let tempContent = getContent(data.model);
    if (this.checkIfNoContent(tempContent)) {
      this.store.dispatch({
        type: 'ADD_NAVIGATOR_IMAGE',
        hiresNavigatorImageData: undefined
      });
      this.setSepToDisable(true);
      this.loadMissingPage(missingPageFull, 'composite', true);
      return;
    }


    // Generate the tiles
    var hr_params = {
      task: 'init',
      vid: isFragment ? data.model.versionNwid : data.model.content.version.nwid,
      rootType: data.model.type,
      imageType: 'txt',
      viewId: this.id,
      pageAction: '12',
      version: isFragment ? data.model.version : data.model.content.version.nwid,
      nwid: getContent(data.model).nwid,
      projectorId: this.projectorId
    };

    separations.forEach(sep => {

      switch (sep.colorType.toLowerCase()) {
        case 'c':
        case 'cyan':
          hr_params.c = getSepContent(data.model, sep).nwid;
          hr_params.c1 = getSepContent(data.model, sep).version;
          hr_params.c2 = getSepContent(data.model, sep).major;
          break;
        case 'm':
        case 'magenta':
          hr_params.m = getSepContent(data.model, sep).nwid;
          hr_params.m1 = getSepContent(data.model, sep).version;
          hr_params.m2 = getSepContent(data.model, sep).major;
          break;
        case 'y':
        case 'yellow':
          hr_params.y = getSepContent(data.model, sep).nwid;
          hr_params.y1 = getSepContent(data.model, sep).version;
          hr_params.y2 = getSepContent(data.model, sep).major;
          break;
        case 'k':
        case 'black':
          hr_params.k = getSepContent(data.model, sep).nwid;
          hr_params.k1 = getSepContent(data.model, sep).version;
          hr_params.k2 = getSepContent(data.model, sep).major;
          break;
      }
    });

    sandbox.request.getImageData(hr_params)
      .then((response) => {
        console.log('Composite image loaded successfully');
        this.parseData(response, 'composite');//parse the data for the composite image
        this.loadSeparations(separations);
      })
      .catch((reason) => {
        console.log('Composite image failed to load');
      });

    windowRef.document.addEventListener('keyup', this.handleDocumentKeyUp);
    windowRef.document.addEventListener('keydown', this.handleDocumentKeyDown);
  },

  checkIfNoContent: function (content) {

    return content.type === 'fragment/content' ? !content : typeof content === 'undefined' || content.version === null || content.version === undefined || content.version.versionNumber !== content.activeMainVersion;
  },

  tickUpdate: function (data) {
    this._super(data);
  },

  //HIRES NEW

  setSepToDisable: function (disable) {
    const itemsToDisable = ['composite', 'singleSep', 'blackAsGray', 'cyan', 'magenta', 'yellow', 'black'];
    itemsToDisable.forEach(item => this.toolbar.setItemDisabled(item, disable));//disable button 
  },

  createImageTiles: function (tile, key, tileIndex, tilesToLoadLength, nwid, cid, loopIndex = 0, loopLength = 1) {
    const { content, imageType, pageAction, selectedImageKey } = this.store.getState();
    this.store.dispatch({
      type: 'ADD_LOADING_TILE',
      loadingTile: tile,
      key
    });
    let hr_params = {
      task: 'get',
      vid: content.type === 'fragment/content' ? content.versionNwid : content.version.nwid,
      rootType: 12,
      imageType: imageType,
      viewId: this.id,
      pageAction: pageAction,
      nwid,
      cid,
      row: tile.row,
      column: tile.column,
      projectorId: this.projectorId,
      key: toServerKey(key)
    };

    return sandbox.request.getImageData(hr_params)
      .then((response) => {
        return this.parseImage(response, key, {
          row: tile.row,
          col: tile.column
        }, key === 'composite', tileIndex, tilesToLoadLength);
      })
      .catch((reason) => {
        this.store.dispatch({
          type: 'REMOVE_LOADING_TILE',
          loadingTile: tile,
          key
        });
      });
  },

  getTilesInViewportToLoad: function (viewportPoints, hrData, key) {
    const { images, loadingTiles } = this.store.getState();

    const viewportPointsRelativeToTopLeft = Object.keys(viewportPoints).reduce((acc, viewportPointKey) => {
      if (!isUndefined(viewportPoints[viewportPointKey].x) && !isUndefined(viewportPoints[viewportPointKey].y)) {
        acc[viewportPointKey] = {
          x: viewportPoints[viewportPointKey].x + hrData.width / 2,
          y: viewportPoints[viewportPointKey].y + hrData.height / 2
        };
      }
      return acc;
    }, {});

    const viewportEdgeTiles = Object.keys(viewportPointsRelativeToTopLeft).reduce((acc, viewportPointKey) => {
      if (!isUndefined(viewportPointsRelativeToTopLeft[viewportPointKey]) && !isUndefined(viewportPointsRelativeToTopLeft[viewportPointKey].x) && !isUndefined(viewportPointsRelativeToTopLeft[viewportPointKey].y)) {
        const column = Math.floor(viewportPointsRelativeToTopLeft[viewportPointKey].x / TILE_WIDTH);
        const row = Math.floor(viewportPointsRelativeToTopLeft[viewportPointKey].y / TILE_HEIGHT);
        acc.rows.push(row);
        acc.columns.push(column);
      }
      return acc;
    }, { rows: [], columns: [] });

    const maxRow = Math.max(...viewportEdgeTiles.rows);
    const maxColumn = Math.max(...viewportEdgeTiles.columns);
    const minRow = Math.min(...viewportEdgeTiles.rows);
    const minColumn = Math.min(...viewportEdgeTiles.columns);

    let viewportTiles = [];

    for (let row = minRow; row <= maxRow; row++) {
      for (let column = minColumn; column <= maxColumn; column++) {
        viewportTiles.push({ row, column });
      }
    }

    if (!isUndefined(images) && !isUndefined(key) && !isUndefined(images[key]) && !isUndefined(images[key].imageTiles)) {
      const loadedTiles = images[key].imageTiles.map(image => image.tilePositionRowCol);

      const deltaViewportTielsToLoad = viewportTiles.filter((tileToLoad) => {

        const isLoadedTile = !isUndefined(loadedTiles.find(loadedTile => {
          return loadedTile.row === tileToLoad.row && loadedTile.column === tileToLoad.column;
        }));
        const isLoadingTile = !isUndefined(loadingTiles[key].find(loadingTile => {
          return loadingTile.row === tileToLoad.row && loadingTile.column === tileToLoad.column;
        }));

        return !isLoadedTile && !isLoadingTile;
      });

      return deltaViewportTielsToLoad;
    } else {
      return viewportTiles;
    }
  },

  parseData: function (response, key) {
    const {
      content,
      imageType,
      pageAction,
      flipHorizontal,
      flipVertical,
      rotation,
      zoom,
      offsetPoint,
      mainCanvasWidth,
      mainCanvasHeight
    } = this.store.getState();

    // Get Height and Width
    var param = response.split(',');
    let hrData = {};
    if (param !== undefined && param.length > 1) {
      //Save the hires data for updates

      param.forEach(item => {
        const aData = item.split(':');
        if (aData.length > 1) {
          hrData[aData[0]] = aData[1];
        }
      });
      hrData.hr_columns = Math.floor(parseInt(hrData.width) / TILE_WIDTH) + 1;
      hrData.hr_rows = Math.floor(parseInt(hrData.height) / TILE_HEIGHT) + 1;
      if (isUndefined(hrData.xRes) && isUndefined(hrData.yRes)) {
        hrData.xRes = 1000.0;
        hrData.yRes = 1000.0;
      }
      // firstTickData.hiresData = hrData;
      this.store.dispatch({
        type: 'LOAD_HIRES_DATA',
        hiresData: hrData
      });

      this.store.dispatch({
        type: 'UPDATE_LOADING',
        loading: true
      });

      const viewportPoints = getViewportPoints({
        width: hrData.width,
        height: hrData.height
      }, flipHorizontal, flipVertical, rotation, zoom, offsetPoint, mainCanvasWidth, mainCanvasHeight, this.mainCanvasInstance);
      const viewportTielsToLoad = this.getTilesInViewportToLoad(viewportPoints, hrData, key);

      viewportTielsToLoad.forEach((tile, index) => {
        this.createImageTiles(tile, key, index, viewportTielsToLoad.length, content.nwid, '_')
          .then(response => {
            if (index === viewportTielsToLoad.length - 1) {
              this.store.dispatch({
                type: 'UPDATE_LOADING',
                loading: false
              });
            }
          });
      });
    }

    let navigator_image_params = {
      defaultIcon: sandbox.icons.getGeneralIcon(null, "empty"),
      template: content.type,
      action: "full",
      viewId: this.id,
      nwid: content.nwid,
      iconUrlCounter: this.iconUrlCounter++,
      projectorId: this.projectorId
    };

    const navigatorImageUrl = sandbox.request.getImageUrl(navigator_image_params, true);
    loadImage(navigatorImageUrl).then(hiresNavigatorImage => {
      this.store.dispatch({
        type: 'ADD_NAVIGATOR_IMAGE',
        hiresNavigatorImageData: hiresNavigatorImage
      });
    });
  },

  compositeTilesLoad: function (viewportPoints, key) {
    const { hiresData, content } = this.store.getState();
    const viewportTielsToLoad = this.getTilesInViewportToLoad(viewportPoints, hiresData, 'composite');
    if (viewportTielsToLoad.length > 0) {
      viewportTielsToLoad.forEach((tile, index) => this.createImageTiles(tile, key, index, viewportTielsToLoad.length, content.nwid, '_')
        .then(response => {
          if (index === viewportTielsToLoad.length - 1) {
            this.store.dispatch({
              type: 'UPDATE_LOADING',
              loading: false
            });
          }
        }));
    } else {

      this.store.dispatch({
        type: 'UPDATE_LOADING',
        loading: false
      });
    }

  },

  handleUpdateTilesInViewport: function (viewportPoints) {
    const { hiresData, selectedImageKey, content, separations } = this.store.getState();
    if (this.checkIfNoContent(content)) {
      return;
    }
    if (!isUndefined(selectedImageKey)) {
      switch (selectedImageKey) {
        case 'composite':
          this.compositeTilesLoad(viewportPoints, selectedImageKey);
          break;
        case 'black':
          this.loadSeparation(separations.findBy('colorType', 'Black'));
          break;
        case 'cyan':
          this.loadSeparation(separations.findBy('colorType', 'Cyan'));
          break;
        case 'magenta':
          this.loadSeparation(separations.findBy('colorType', 'Magenta'));
          break;
        case 'yellow':
          this.loadSeparation(separations.findBy('colorType', 'Yellow'));
          break;
        case 'mergedImage':
          this.loadSeparations(separations);
          break;
      }

    }
  },

  parseImage: function (response, key, tilePosition, bLoadImage = false, tileIndex, tilesToLoadLength) {
    const { windowRef, hiresData } = this.store.getState();
    if (response.slice(0, 4) !== 'data') {
      response = "data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7";
      this.toolbar.destroy();
    }

    var bString = atob(response.split(',')[1]);

    // separate out the mime component
    var mimeString = response.split(',')[0].split(':')[1].split(';')[0];

    var ab = new ArrayBuffer(bString.length);
    var ua = new Uint8Array(ab);
    for (var i = 0; i < bString.length; i++) {
      ua[i] = bString.charCodeAt(i);
    }

    var blob = new Blob([ab], {
      type: mimeString
    });

    // URL is not supported natively
    var blobURL = (windowRef.URL) ? windowRef.URL.createObjectURL(blob) : windowRef.webkitURL.createObjectURL(blob);
    this.store.dispatch({
      type: 'HIRES_DATA_INIT',
      hiresData: {
        blobURL,
      }
    });

    return loadImage(blobURL)
      .then(image => {
        if (bLoadImage) {
          this.store.dispatch({
            type: 'LOAD_IMAGE',
            selectedImageKey: key
          });
          this.store.dispatch({
            type: 'DISTANCE_POINT_CHANGE',
            distanceToolPoints: {
              point: undefined,
              pointTo: undefined,
              measurementData: this.getUpdatedMeasurementData()
            }
          });
        }
        console.log('Hires image loaded successfully:', key);
        const ipX = (TILE_WIDTH) * tilePosition.col + image.width / 2 - hiresData.width / 2;
        const ipY = (TILE_HEIGHT) * tilePosition.row + image.height / 2 - hiresData.height / 2;
        this.store.dispatch({
          type: 'ADD_IMAGE_HIRES',
          imageTiles: {
            key,
            imageUrl: blobURL,
            image,
            imagePoint: { x: ipX, y: ipY },
            tilePositionRowCol: { row: tilePosition.row, column: tilePosition.col }
          },
          resolution: {
            xResolution: hiresData.xRes,
            yResolution: hiresData.yRes
          },
          height: hiresData.height,
          width: hiresData.width
        });
        this.missingPage = false;
        this.handleUpdateViewportPoints({ width: hiresData.width, height: hiresData.height });
        this.toolbarLocalButtons.forEach(item => this.toolbar.setItemDisabled(item, false));//enable all buttons
      })
      .catch(() => {
        console.log('Hires image failed to load:', key);
        this.loadMissingPage(missingPageFull, 'composite', true);
        this.toolbarLocalButtons.forEach(item => this.toolbar.setItemDisabled(item, true));//disable all buttons
      });
  },

  createMyToolbar: function () {
    this.createToolbar();
    let module = this;

    const {
      isMoveTool,
      isDistanceTool,
    } = this.store.getState();

    this.toolbar.addItem({
      name: 'print',
      _isApplicable: true,
      icon: 'print',
      iconSprite: 'general',
      tooltip: translate('Print Image'),
      execute: () => {
        const { images, selectedImageKey, windowRef } = this.store.getState();
        const image = images[selectedImageKey].image;
        var pwin = windowRef.open('', 'Print', 'toolbar=0,location=0,menubar=0');
        pwin.document.write('<img src="' + image.src + '"/>');
        pwin.document.close();
        pwin.focus();
        pwin.onload = function () {
          setTimeout(function () {
            pwin.print();
            pwin.close();
          }, 500);//wait a bit for page to load
        };
      }
    });
    this.toolbar.addItem({
      name: 'rotate_left',
      _isApplicable: true,
      icon: 'rotate_left',
      iconSprite: 'general',
      tooltip: translate('Rotate Image Counter-Clockwise'),
      groupName: 'image',
      execute: () => {
        //rotate the image -90 degrees
        this.handleRotate(-90);
      }
    });
    this.toolbar.addItem({
      name: 'rotate_right',
      _isApplicable: true,
      icon: 'rotate_right',
      iconSprite: 'general',
      tooltip: translate('Rotate Image Clockwise'),
      groupName: 'image',
      execute: () => {
        //rotate the image +90 degrees
        this.handleRotate(90);
      }
    });
    this.toolbar.addItem({
      name: 'flip_horizontal',
      _isApplicable: true,
      icon: 'flip_horizontal',
      iconSprite: 'general',
      tooltip: translate('Flip Image Horizontally'),
      groupName: 'image',
      execute: () => {
        const { flipHorizontal } = this.store.getState();
        this.store.dispatch({
          type: 'FLIP_HORIZONTAL',
          flipHorizontal: !flipHorizontal
        });
      }
    });
    this.toolbar.addItem({
      name: 'flip_vertical',
      _isApplicable: true,
      icon: 'flip_vertical',
      iconSprite: 'general',
      tooltip: translate('Flip Image Vertically'),
      groupName: 'image',
      execute: () => {
        const { flipVertical } = this.store.getState();
        this.store.dispatch({
          type: 'FLIP_VERTICAL',
          flipVertical: !flipVertical
        });
      }
    });
    this.toolbar.addItem({
      name: 'move_image',
      _isApplicable: true,
      icon: 'move',
      iconSprite: 'general',
      checked: isMoveTool,
      itemType: 'push',
      tooltip: translate('Move Tool'),
      groupName: 'tool',
      execute: (checked) => {
        if (!checked) {
          this.toolbar.setItemChecked('move_image', true);
        } else {
          //clear out the measurement data     

          this.store.dispatch({
            type: 'DISTANCE_POINT_CHANGE',
            distanceToolPoints: {
              point: undefined,
              pointTo: undefined,
              measurementData: {
                fromX: undefined,
                fromY: undefined,
                toX: undefined,
                toY: undefined,
                w: undefined,
                h: undefined,
                d: undefined,
                pw: undefined,
                ph: undefined
              }
            }
          });
          this.store.dispatch({
            type: 'MOVE_TOOL',
            isDistanceTool: !checked,
            isMoveTool: checked,
            isDensityTool: !checked
          });
        }
      }
    });

    this.toolbar.addItem({
      name: 'distance_tool',
      _isApplicable: true,
      icon: 'ruler',
      iconSprite: 'general',
      itemType: 'push',
      tooltip: translate('Distance Tool'),
      groupName: 'tool',
      checked: isDistanceTool,
      execute: (checked) => {
        if (!checked) {
          this.toolbar.setItemChecked('distance_tool', true);
        } else {
          //clear out the measurement data

          this.store.dispatch({
            type: 'DISTANCE_POINT_CHANGE',
            distanceToolPoints: {
              point: undefined,
              pointTo: undefined,
              measurementData: {
                fromX: undefined,
                fromY: undefined,
                toX: undefined,
                toY: undefined,
                w: undefined,
                h: undefined,
                d: undefined,
                pw: undefined,
                ph: undefined
              }
            }
          });
          this.store.dispatch({
            type: 'DISTANCE_TOOL',
            isDistanceTool: checked,
            isMoveTool: !checked,
            isDensityTool: !checked
          });
        }
      }
    });

    this.toolbar.addItem({
      name: 'actual_size',
      _isApplicable: true,
      icon: 'actual_size',
      iconSprite: 'general',
      tooltip: translate('Actual Size'),
      groupName: 'zoom',
      execute: () => {
        this.store.dispatch({
          type: 'ZOOM',
          zoom: 1
        });
        this.handleUpdateViewportPoints(this.getImageWidthHeight());
      }
    });

    this.toolbar.addItem({
      label: 'composite',
      name: 'composite',
      _isApplicable: true,
      icon: 'composite_color',
      iconSprite: 'general',
      tooltip: translate('Turn on all colors'),
      execute: () => {
        module.toggleSepHiRes('composite', true);
      }
    });

    this.toolbar.addItem({
      name: 'singleSep',
      id: 'singleSep',
      _isApplicable: true,
      itemType: 'push',
      icon: 'single_separation',
      iconSprite: 'general',
      tooltip: translate('Single separation selection'),
      checked: false,
      execute: (checked) => {
        module.toggleSepHiRes('singleSep', checked);
        if (checked) {
          module.toggleSepHiRes('black', 'k', checked);
        } else {
          module.toggleSepHiRes('composite', 'composite', checked);
        }
      }
    });

    this.toolbar.addItem({
      name: 'cyan',
      itemType: 'push',
      _isApplicable: true,
      icon: 'cyan_separation',
      iconSprite: 'general',
      tooltip: translate('Cyan separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSepHiRes('cyan', checked);
      }
    });

    this.toolbar.addItem({
      name: 'magenta',
      itemType: 'push',
      _isApplicable: true,
      icon: 'magenta_separation',
      iconSprite: 'general',
      tooltip: translate('Magenta separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSepHiRes('magenta', checked);
      }
    });

    this.toolbar.addItem({
      name: 'yellow',
      itemType: 'push',
      _isApplicable: true,
      icon: 'yellow_separation',
      iconSprite: 'general',
      tooltip: translate('Yellow separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSepHiRes('yellow', checked);
      }
    });

    this.toolbar.addItem({
      name: 'black',
      itemType: 'push',
      _isApplicable: true,
      icon: 'black_separation',
      iconSprite: 'general',
      tooltip: translate('Black separation'),
      checked: true,
      execute: (checked) => {
        module.toggleSepHiRes('black', checked);
      }
    });

    this.toolbar.addItem({
      name: 'blackAsGray',
      itemType: 'push',
      _isApplicable: true,
      icon: 'black_as_gray',
      iconSprite: 'general',
      tooltip: translate('Show black as gray'),
      checked: false,
      execute: (checked) => {
        module.toggleSepHiRes('blackAsGray', checked);
      }
    });

    this.toolbar.addItem({
      itemType: 'menu',
      icon: 'more_vert',
      menuItems: [],
      unshift: true,
      _isApplicable: true,
      tooltip: translate('More Actions'),
      execute: () => {
        return this.getMenuItems();
      }
    });

    this.toolbarLocalButtons = [];
    const toolbarGlobalButtons = ['ApproveAllActionCR', 'RejectAllActionCR', 'OpenCustomApprovalViewCR'];
    this.toolbar.items.forEach(item => (toolbarGlobalButtons.indexOf(item.name) <= -1) && this.toolbarLocalButtons.push(item.name));//save local buttons

  },


  loadSeparation: function (separation, sepIndex = 0, sepLoopLength = 1) {
    if (isUndefined(separation)) return;

    const {
      hiresData,
      flipHorizontal,
      flipVertical,
      rotation,
      zoom,
      offsetPoint,
      mainCanvasWidth,
      mainCanvasHeight,
      selectedImageKey,
      content
    } = this.store.getState();
    let cid;
    let key;

    switch (separation.colorType.toLowerCase()) {
      case 'c':
      case 'cyan':
        cid = 'C';
        key = 'cyan';
        this.toolbar.setItemDisabled('cyan', false);//enable the button
        this.toolbar.setItemDisabled('composite', false);//enable the button
        break;
      case 'm':
      case 'magenta':
        cid = 'M';
        key = 'magenta';
        this.toolbar.setItemDisabled('magenta', false);//enable the button 
        this.toolbar.setItemDisabled('composite', false);//enable the button
        break;
      case 'y':
      case 'yellow':
        cid = 'Y';
        key = 'yellow';
        this.toolbar.setItemDisabled('yellow', false);//enable the button 
        this.toolbar.setItemDisabled('composite', false);//enable the button
        break;
      case 'k':
      case 'black':
        cid = 'K';
        key = 'black';
        this.toolbar.setItemDisabled('black', false);//enable the button
        this.toolbar.setItemDisabled('blackAsGray', false);//enable the button 
        break;
    }

    const viewportPoints = getViewportPoints({
      width: hiresData.width,
      height: hiresData.height
    }, flipHorizontal, flipVertical, rotation, zoom, offsetPoint, mainCanvasWidth, mainCanvasHeight, this.mainCanvasInstance);

    const viewportTielsToLoad = this.getTilesInViewportToLoad(viewportPoints, hiresData, key);
    viewportTielsToLoad.forEach((tile, index) => {
      this.setSepToDisable(true);
      this.createImageTiles(tile, key, index, viewportTielsToLoad.length,
        getSepContent(content, separation).nwid, cid, sepIndex, sepLoopLength)
        .then(response => {
          if (index === viewportTielsToLoad.length - 1 && sepIndex === sepLoopLength - 1) {

            this.store.dispatch({
              type: 'UPDATE_LOADING',
              loading: false
            });
            this.setSepToDisable(false);
            if (selectedImageKey === 'mergedImage') {
              const separationsList = ['blackAsGray', 'cyan', 'magenta', 'yellow', 'black'];
              let checkedList = separationsList.filter(sep => {
                return this.toolbar.items.findBy('name', sep).checked;
              });
              setTimeout(() => {
                this.loadMergeImages(checkedList);
              }, 0);
            }
          }
        });
    });
  },

  loadSeparations: function (separations) {
    const itemsToDisable = ['composite', 'blackAsGray', 'cyan', 'magenta', 'yellow', 'black'];
    itemsToDisable.forEach(item => this.toolbar.setItemDisabled(item, true));//disable button 

    separations.forEach((separation, index) => {
      this.loadSeparation(separation, index, separations.length);
    });
  },

  loadMergeImages: function (checkedItems, sepKey) {
    const { images, hiresData, viewportPoints } = this.store.getState();

    if (checkedItems.includes('composite')) {
      this.store.dispatch({
        type: 'LOAD_IMAGE',
        selectedImageKey: "composite"
      });

      this.store.dispatch({
        type: 'UPDATE_LOADING',
        loading: false
      });
      return;//dont merge anything
    }

    const isSingleSep = this.toolbar.items.findBy('name', 'singleSep').checked;
    let imagesToMerge = [];
    this.toolbar.setItemDisabled('blackAsGray', false);
    if (isSingleSep) {
      const singleSep = checkedItems.find(item => item !== 'blackAsGray');
      if (singleSep === 'black') {
        images[singleSep] && imagesToMerge.push(images[singleSep]);
      } else {
        this.toolbar.setItemChecked('blackAsGray', false);
        this.toolbar.setItemDisabled('blackAsGray', true);
        checkedItems = checkedItems.filter(item => item !== 'blackAsGray');
        images[singleSep] && imagesToMerge.push(images[singleSep]);
      }
    } else {
      imagesToMerge = checkedItems.reduce((acc, item) => {
        images[item] && acc.push(images[item]);
        return acc;
      }, []);
    }

    //now do we really need to merge, only if there is more than 1 imagesToMerge
    if (imagesToMerge.length === 0 || !imagesToMerge[0] && !checkedItems.includes('blackAsGray')) {
      this.store.dispatch({
        type: 'LOAD_IMAGE',
        selectedImageKey: undefined
      });

      this.store.dispatch({
        type: 'UPDATE_LOADING',
        loading: false
      });
    } else if ((imagesToMerge.length === 1 && !checkedItems.includes('blackAsGray') && isSingleSep) || (imagesToMerge.length === 1 && !isSingleSep && sepKey === 'singleSep')) {
      this.store.dispatch({
        type: 'LOAD_IMAGE',
        selectedImageKey: imagesToMerge[0].key
      });

      this.store.dispatch({
        type: 'UPDATE_LOADING',
        loading: false
      });

    } else if (imagesToMerge.length === 4 && !checkedItems.includes('blackAsGray')) {
      this.compositeTilesLoad(viewportPoints, 'composite');
      this.store.dispatch({
        type: 'LOAD_IMAGE',
        selectedImageKey: 'composite'
      });
    } else {
      let mergeImagesTilesLength = Math.max(...imagesToMerge.map(mergeImage => mergeImage.imageTiles.length));
      let mergeImagesTiles = [];
      for (let i = 0; i < mergeImagesTilesLength; i++) {
        const mergeImageTile = imagesToMerge.map(mergeImage => {
          return mergeImage.imageTiles[i];
        });
        mergeImagesTiles.push(mergeImageTile);
      }
      mergeImagesTiles = mergeImagesTiles.filter(mergeImageTile => mergeImageTile.every(tile => !isUndefined(tile)));

      if (!isUndefined(images) && !isUndefined(images.mergedImage) && !isUndefined(images.mergedImage.imageTiles)) {
        const loadedTiles = images.mergedImage.imageTiles.map(image => image.tilePositionRowCol);

        const deltaMergeImagesTiles = mergeImagesTiles.filter((tileToLoad) => {

          const isLoadedTile = !isUndefined(loadedTiles.find(loadedTile => {
            return loadedTile.row === tileToLoad[0].tilePositionRowCol.row && loadedTile.column === tileToLoad[0].tilePositionRowCol.column;
          }));

          return !isLoadedTile;
        });
        mergeImagesTiles = deltaMergeImagesTiles;
        mergeImagesTilesLength = deltaMergeImagesTiles.length;
      }

      let imagesList = [];
      mergeImagesTiles.forEach((mergeImageTile, index) => {
        const imagePoint = mergeImageTile[0].imagePoint;
        const tilePositionRowCol = mergeImageTile[0].tilePositionRowCol;
        this.store.dispatch({
          type: 'ADD_LOADING_TILE',
          loadingTile: tilePositionRowCol,
          key: "mergedImage"
        });
        let promiseMergeImage = new Promise((resolve, reject) => {
          // setTimeout(() => {
          resolve(mergeImagesHires(mergeImageTile, this.win.document, this.toolbar.items.findBy('name', 'blackAsGray').checked));
          // }, 3000);
        });
        // const url = mergeImage(mergeImageTile, this.win.document, this.toolbar.items.findBy('name', 'blackAsGray').checked);

        promiseMergeImage.then(url => {
          // setTimeout(() => {
          loadImage(url)
            .then(image => {
              imagesList.push({
                imageTiles: {
                  key: "mergedImage",
                  imageUrl: url,
                  image: image,
                  imagePoint,
                  tilePositionRowCol
                },
              });
              if (imagesList.length === mergeImagesTiles.length) {

                this.store.dispatch({
                  type: 'ADD_IMAGES_HIRES',
                  key: "mergedImage",
                  images: imagesList,
                  resolution: {
                    xResolution: hiresData.xRes,
                    yResolution: hiresData.yRes
                  },
                  height: hiresData.height,
                  width: hiresData.width
                });
                this.store.dispatch({
                  type: 'LOAD_IMAGE',
                  selectedImageKey: "mergedImage"
                });

                this.store.dispatch({
                  type: 'UPDATE_LOADING',
                  loading: false
                });
              }
            })
            .catch(() => {
            });
          // }, 0);
        });
      });
    }
  },

  toggleSepHiRes: function (sepKey, checked) {
    const { images, separations, hiresData } = this.store.getState();
    this.store.dispatch({
      type: 'UPDATE_LOADING',
      loading: true
    });
    this.store.dispatch({
      type: 'CLEAR_MERGE_IMAGES',
    });

    let items = {
      checked: [],
      unchecked: []
    };

    if (!isUndefined(images)) {
      this.toolbar.setItemChecked(sepKey, checked);//set the clicked item now

      switch (sepKey) {
        case 'composite':
          items.checked = ['cyan', 'magenta', 'yellow', 'black', 'composite'];
          items.unchecked = ['singleSep', 'blackAsGray'];
          break;
        case 'singleSep':
          if (checked) {
            items.checked = ['black', 'singleSep'];
            items.unchecked = ['cyan', 'magenta', 'yellow'];
          } else {
            items.checked = ['cyan', 'magenta', 'yellow', 'black', 'composite'];
            items.unchecked = ['singleSep', 'blackAsGray'];
          }
        default:
          items = this.colorSeps(sepKey, checked);
          break;
      }

      items.checked.forEach(sep => {
        this.toolbar.setItemChecked(sep, true);
        if (sep !== 'blackAsGray') {
          this.loadSeparation(separations.findBy('name', sep));
        }
      });
      items.unchecked.forEach(sep => {
        this.toolbar.setItemChecked(sep, false);
      });

      if (isUndefined(images[sepKey]) && sepKey !== 'blackAsGray') {
        switch (sepKey) {
          case 'black':
            this.loadSeparation(separations.findBy('colorType', 'Black'));
            break;
          case 'cyan':
            this.loadSeparation(separations.findBy('colorType', 'Cyan'));
            break;
          case 'magenta':
            this.loadSeparation(separations.findBy('colorType', 'Magenta'));
            break;
          case 'yellow':
            this.loadSeparation(separations.findBy('colorType', 'Yellow'));
            break;
        }
      }
      this.loadMergeImages(items.checked, sepKey);
    }
  },

  handleZoom: function (zoomValue) {
    const { zoom } = this.store.getState();
    const newZoomValue = (zoom + zoomValue) >= MAX_ZOOM ? MAX_ZOOM : (zoom + zoomValue) <= MIN_ZOOM ? MIN_ZOOM : (zoom + zoomValue);

    this.store.dispatch({
      type: 'ZOOM',
      zoom: newZoomValue
    });
    this.handleUpdateViewportPoints(this.getImageWidthHeight());
  },

  handleDocumentMouseUp: function (event) {
    this._super(event);
    const separationsList = ['blackAsGray', 'cyan', 'magenta', 'yellow', 'black'];
    let checkedList = separationsList.filter(sep => {
      return this.toolbar.items.findBy('name', sep).checked;
    });
    this.loadMergeImages(checkedList);
  },

  getSelectedImage: function () {
    const { images, selectedImageKey } = this.store.getState();
    return typeof selectedImageKey !== 'undefined' ? images[selectedImageKey] : images['composite'];
  },

  renderCanvasImage: function (image) {
    const { content } = this.store.getState();
    return () => {
      if (image) {
        if (this.checkIfNoContent(content)) {
          return <CanvasImage image={image.image} point={image.imagePoint} />;
        } else if (image.imageTiles) {
          return image.imageTiles.map((tile, index) => {
            const { row, column } = tile.tilePositionRowCol;
            const key = `${index}-${row}-${column}`;

            return <CanvasImage key={key} image={tile.image} point={tile.imagePoint} />;
          });
        }
      }
    };
  },

  getImageWidthHeight: function () {
    const { hiresData, content } = this.store.getState();
    let imageWidthHeight = {};
    if (this.checkIfNoContent(content)) {
      const image = this.getSelectedImage();
      imageWidthHeight = { width: image.image.width, height: image.image.height };
    } else {
      imageWidthHeight = !isUndefined(hiresData) ? { width: hiresData.width, height: hiresData.height } : {};
    }
    return imageWidthHeight;
  },
});