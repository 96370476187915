import { translate } from 'core/services/localization';

export const SUPPORT_OAUTH_OPTIONS = [
  { value: 'notSupported', text: translate('None') },
  { value: 'global', text: translate('Global') },
  { value: 'folder', text: translate('Per Folder') },
];


export const PROVIDERS_OPTIONS_GLOBAL_SETUP = [
  { value: 'AZURE', text: 'Azure AD' },
  { value: 'OKTA', text: 'Okta' },
];

export const PROVIDERS_OPTIONS = [
  { value: '', text: translate('None') },
  ...PROVIDERS_OPTIONS_GLOBAL_SETUP,
];

export const AUTH_SERVER_OPTIONS = [
  { text: translate('Default Authorization Server'), value: 'default' },
  { text: translate('Org Authorization Server'), value: 'org' },
  { text: translate('Custom Authorization Server'), value: 'custom' }
];

export const GROUP_MAPPING_OPTIONS = [
  { value: 'BuiltIn', text: translate('Built In') },
  { value: 'regex', text: translate('RegEx') }
];

export const AZURE_FIELDS = {
  // autenticationServerURL: true,
  // clientID: true,
  // clientSecret: true,
  clientSecretValue: true,
  azureADTenantID: true,
  useOpenIDManagedGroups: true,
};

export const OKTA_FIELDS = {
  // autenticationServerURL: true,
  // clientID: true,
  // clientSecret: true,
  authorizationServer: true,
  customAuthorizationServerID: true,
  customAuthorizationServerAudience: true,
  useOpenIDManagedGroups: true,
};