import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flipbookTurn from 'flipbookTurn';
import { translate } from 'core/services/localization';
import $ from 'jquery';
import { loadFlipbookImage, loadImage } from '../../NewPageView/utilities';
import Loader from 'components/common/loader/Loader';
import ToggleFlatButton from 'components/common/buttons/ToggleFlatButton';
import SvgIcon from 'components/common/buttons/SvgIcon';

const isUndefined = o => typeof o === 'undefined';
const labels = {
  colorManagement: translate('Color Management'),
  showColorManagement: translate('Show Color Management'),
  hideColorManagement: translate('Hide Color Management'),
  transparency: translate('Transparency')
}

export default class flipbook extends Component {

  static propTypes = {
    windowRef: PropTypes.any,
    imageUrls: PropTypes.object,
    isInColorManagementMode: PropTypes.bool,
    onColorManagementButtonClick: PropTypes.func
  };

  static defaultProps = {
    imageUrls: {},
    isInColorManagementMode: false,
    onColorManagementButtonClick: () => {
    }
  };

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.windowRef.addEventListener("resize", this.handleResize);
    this.props.windowRef.addEventListener("keyup", this.handleKeyup);
  }

  componentWillUnmount = () => {
    this.props.windowRef.removeEventListener('resize', this.handleResize);
    this.props.windowRef.removeEventListener('keyup', this.handleKeyup);
  }

  componentDidUpdate = () => {
    if (!isUndefined(this.props.imageUrls.urls)) {
      this.initFlipbook();
    }
  }

  initFlipbook = () => {
    const { imageUrls, windowRef, isInColorManagementMode } = this.props;

    const myTurn = flipbookTurn;//just put this in so build will load the library

    //clear out array of images used for flipbook
    this.aImages = [];

    for (var i = 0; i < imageUrls.urls.length; i++) {
      var loadedImagesCount = 0;
      const url = isInColorManagementMode ? imageUrls.urls[i].cmUrl : imageUrls.urls[i].url

      loadFlipbookImage(url, 'Image' + (i + 1), 'Image' + (i + 1), imageUrls.urls[i].isPano, i + 1)
        .done(image => {
          this.aImages.push(image);//save the good image
          if (loadedImagesCount >= imageUrls.urls.length - 1) {
            //all this.images have been loaded so now put them into flipbook
            this.handleImageLoadFlipbook(imageUrls.currentPageNumber);
          }
          loadedImagesCount++
        })
        .fail(image => {
          console.log('Image failed to load');
          this.aImages.push(image.target);//save a blank img
          if (loadedImagesCount >= imageUrls.urls.length - 1) {
            //all this.images have been loaded so now put them into flipbook
            this.handleImageLoadFlipbook(imageUrls.currentPageNumber);
          }
          loadedImagesCount++
        });
    }

    //handle the bleed through here
    this.refs.globalAlpha.oninput = () => {
      // const { imageUrls } = this.props;
      for (var i = 1; i < imageUrls.urls.length; i++) {
        var myCanvas = windowRef.document.getElementById("PageCanvas" + i);
        if (myCanvas !== null) {
          this.drawFlipbookImages(myCanvas, i);
        }
      }
    }
  }

  resize = (thisImg) => {
    if (isUndefined(thisImg)) {
      console.log('image undef');
      thisImg = this.my_flipImage;
    }
    const iW = thisImg.width;
    const iH = thisImg.height;
    const el = this.refs.flipbookTop.parentElement.parentElement;//extra div so I had to go up 2 levels

    //calculate the sizes of the image based on the parent size
    el.style.overflow = 'hidden';
    var ratio = Math.min(((el.clientWidth - 30) / 2) / iW, (el.clientHeight - 30) / iH);
    let obj = { width: iW * ratio, height: iH * ratio };
    obj.width = obj.width * 2;

    //offset the top to center on the page if image is smaller that page height
    // document.getElementById("flipbook").style.marginTop = (el.clientHeight - obj.height) / 2 + 'px';

    return {
      width: obj.width,
      height: obj.height
    };
  }

  //pageNumber MUST be 1 based not 0
  drawFlipbookImages = (myCanvas, pageNumber) => {
    var myCtx = myCanvas.getContext('2d');
    if (pageNumber > 0 && pageNumber !== this.aImages.length) {
      var backPageNumber = -1;
      if (pageNumber % 2) {
        //odd 
        if (this.aImages[pageNumber].width !== 0) {
          myCtx.save();
          myCtx.setTransform(-1, 0, 0, 1, myCanvas.width, 0);//mirror
          if (this.aImages[pageNumber].isPano) {
            //odd => right pano
            myCtx.drawImage(this.aImages[pageNumber], 0, 0, myCanvas.width, myCanvas.height, 0, 0, myCanvas.width, myCanvas.height);
          } else {
            //no pano
            myCtx.drawImage(this.aImages[pageNumber], 0, 0, myCanvas.width, myCanvas.height);
          }
          myCtx.restore();
        } else {
          myCtx.fillStyle = 'white';//white background but can put whatever here
          myCtx.fillRect(0, 0, myCanvas.width, myCanvas.height);
        }
      } else {
        //even
        if (this.aImages[pageNumber - 2].width != 0) {
          myCtx.save();
          myCtx.setTransform(-1, 0, 0, 1, myCanvas.width, 0);//mirror
          if (this.aImages[pageNumber - 2].isPano) {
            //even => left pano
            myCtx.drawImage(this.aImages[pageNumber - 2], myCanvas.width, 0, myCanvas.width, myCanvas.height, 0, 0, myCanvas.width, myCanvas.height);
          } else {
            //no pano
            myCtx.drawImage(this.aImages[pageNumber - 2], 0, 0, myCanvas.width, myCanvas.height);
          }
          myCtx.restore();
        } else {
          myCtx.fillStyle = 'white';//white background but can put whatever here
          myCtx.fillRect(0, 0, myCanvas.width, myCanvas.height);
        }
      }
    }
    myCtx.globalAlpha = (1 - this.refs.globalAlpha.value / 100);//bleed it through original
    if (this.aImages[pageNumber - 1].isPano) {
      if ((pageNumber - 1) % 2) {
        //odd => right pano
        myCtx.drawImage(this.aImages[pageNumber - 1], 0, 0, myCanvas.width / 2, myCanvas.height, 0, 0, myCanvas.width, myCanvas.height);
      } else {
        //even => left pano
        myCtx.drawImage(this.aImages[pageNumber - 1], myCanvas.width / 2, 0, myCanvas.width / 2, myCanvas.height, 0, 0, myCanvas.width, myCanvas.height);
      }
    } else {
      //no pano
      myCtx.drawImage(this.aImages[pageNumber - 1], 0, 0, myCanvas.width, myCanvas.height);
    }
  }

  handleImageLoadFlipbook = (pageNumber) => {
    const { windowRef } = this.props;
    var pages = this.refs.flipbook;
    if (pages.firstChild) {
      $(pages).turn("destroy", windowRef.document);
    }
    var goodImage = null;
    this.aImages.sort((a, b) => (a.id.toLowerCase().localeCompare(b.id.toLowerCase(), undefined, {
      numeric: true,
      sensitivity: 'base'
    })))
    var i = 1;
    this.aImages.forEach(img => {
      var myCanvas = windowRef.document.createElement('canvas');
      if (img.width !== 0) {
        goodImage = img;
        myCanvas.id = 'PageCanvas' + img.id.slice(5, 9);//need to get the page numbers after img loaded
        myCanvas.width = img.width;//set width or image will be blurry
        myCanvas.height = img.height;//set height or image will be blurry
        this.drawFlipbookImages(myCanvas, i);
      } else {
        myCanvas.width = 500;//random value here
        myCanvas.height = 500;
        var myCtx = myCanvas.getContext('2d');
        myCtx.fillStyle = 'white';//white background but can put whatever here
        myCtx.fillRect(0, 0, myCanvas.width, myCanvas.height);
      }
      pages.appendChild(myCanvas);
      i++;
    });

    //now setup the flipbook to turn.js
    this.my_flipImage = goodImage;
    if (goodImage !== null) {
      var size = this.resize(goodImage);
      $(this.refs.flipbook).turn({
        gradients: false,
        width: size.width + 5,
        height: size.height + 5,
        myDocumentRef: windowRef.document,
        autoCenter: false
      });

      if (pageNumber > 1) {//start at clicked page
        $(this.refs.flipbook).turn("page", pageNumber);
      }
    } else {
      //we have no images to display so just show some blank pages for each
      const url = "../kernel/assets/img/module/PageView/blank_page.png";
      loadImage(url)
        .then(image => {
          this.my_flipImage = image;
          var size = this.resize(image);
          $(this.refs.flipbook).turn({
            gradients: false,
            width: size.width + 5,
            height: size.height + 5,
            myDocumentRef: windowRef.document,
            autoCenter: false
          });
        })
        .catch(() => {
          console.log('Failed to load the missing Page...');
          $(this.refs.flipbook).turn({
            gradients: false,
            width: 505,
            height: 505,
            myDocumentRef: windowRef.document,
            autoCenter: false
          });
        });
    }
  }

  handleResize = (e) => {
    if (this.my_flipImage == null) {
      return;
    }
    var me = this;

    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function () {
      var size = me.resize(me.my_flipImage);
      $(me.refs.flipbook).turn('size', size.width, size.height);
    }, 250);
  }

  handleKeyup = (e) => {
    if (e.keyCode === 37 || e.keyCode === 33) {//right arrow or page up
      $(this.refs.flipbook).turn("next");
    } else if (e.keyCode === 39 || e.keyCode === 34) {//left arrow
      $(this.refs.flipbook).turn("previous");
    } else if (e.keyCode === 36) {//home key
      $(this.refs.flipbook).turn("page", 1);
    } else if (e.keyCode === 35) {//End key
      $(this.refs.flipbook).turn("page", $(this.refs.flipbook).turn("pages"));
    }
  }

  handleColorManagementButtonClick = () => {
    const { isInColorManagementMode, onColorManagementButtonClick } = this.props;
    const currentPage = $(this.refs.flipbook).turn('page');
    onColorManagementButtonClick(!isInColorManagementMode, currentPage);
  }

  render() {
    const { imageUrls, isInColorManagementMode } = this.props;

    // if (this.props.hasErrored) {
    //   return <p>Sorry! There was an error loading the items</p>;
    // }

    // if (isUndefined(imageUrls.urls)) {
    //   return <p>Loading…</p>;
    // }

    if (isUndefined(imageUrls.urls)) {
      return <div>
        Loading...
        <br />
        <div style={{ paddingTop: '10px' }}>
          <Loader loading={isUndefined(imageUrls.urls)} />
        </div>
      </div>;
    }

    return (
      <div className="crtx-new-page-view">
        <div className='crtx-new-flipbook'>
          <div className='flipbook-global-alpha'>
            <label htmlFor="globalAlpha" className='flipbook-global-alpha-label'>{labels.transparency} </label>
            <input ref="globalAlpha" type="range" min="0" max="100" defaultValue="0" />
            <ToggleFlatButton
              className="crtx-mt-button flipbook-global-alpha-flat-button"
              checked={isInColorManagementMode}
              tooltip={labels.colorManagement}
              onClick={this.handleColorManagementButtonClick}
            >
              <SvgIcon name='color_management' className='crtx-size-24' />
            </ToggleFlatButton>
          </div>

          <div ref="flipbookTop" className="flipbookTop">
            <div className="flipbookTop-t">
              <div className="flipbookTop-tc">
                <div ref="flipbook" className="book">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
