import React from 'react';
import { getFolderCurrentDate } from 'core/dates';
import { translate, toLocaleShortDate, toLocaleShortTime, toLocaleShortDateTime } from 'core/services/localization';
import Radio from 'components/common/inputs/Radio';
import NumericInput from 'components/common/inputs/NumericInput';
import Dropdown from 'components/common/dropdown/Dropdown';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';
import DatePicker from 'components/common/dropdown/DatePicker';
import Button from 'components/common/buttons/Button';
import FlatButton from 'components/common/buttons/FlatButton';
import SvgIcon from 'components/common/buttons/SvgIcon';

const FILTER_LIST_ICON = 'filter_list';
const NO_FILTER = 'nofilter';
const MINUTE = 'min';
const HOUR = 'hour';
const DAY = 'day';
const WITHIN = 'within';
const MORE = 'more';
const DATE = 'date';
const RANGE = 'range';
const LAST = 'last';
const BEFORE = 'before';
const BETWEEN = 'between';

const labels = {
  minutes: translate('Minutes'),
  hours: translate('Hours'),
  days: translate('Days'),
  within: translate('Within the last'),
  moreThan: translate('More than'),
  ago: translate('ago'),
  between: translate('Between'),
  and: translate('and'),
  close: translate('Close'),
  cancel: translate('Cancel'),
  noFilter: translate('No Filter'),
  last: translate('last'),
  more: translate('more'),
  interval: translate('interval'),
  m: translate('m'),
  h: translate('h'),
  d: translate('d'),
  inRange: translate('In range from'),
  to: translate('to'),
  range: translate('range'),
  positiveValue: translate('Value should be positive'),
  dateError: translate('The end date should be greater than from date'),
  the: translate('The'),
  rangeError: translate('value should be greater than'),
  from: translate('from'),
  value: translate('value'),
};

export default class DateFilter extends React.Component {
  state = {
    radioSelected: NO_FILTER,
    moreThanValue: 0,
    withinTheLastValue: 0,
    dropdownList: [{ value: MINUTE, text: labels.minutes }, { value: HOUR, text: labels.hours }, {
      value: DAY,
      text: labels.days
    }],
    dropdownWithinTheLastValue: HOUR,
    dropdownMoreThanValue: HOUR,
    visible: false,
    dateFrom: undefined,
    dateTo: undefined,
    datePickerPopupOpened: false,
    dropdownPopupOpened: false,
    rangeFromValue: 0,
    rangeToValue: 0,
    dropdownRangeValue: HOUR,
    errorMessage: ''
  };

  isDataValid = () => {
    const {
      radioSelected,
      moreThanValue,
      withinTheLastValue,
      dropdownMoreThanValue,
      dropdownWithinTheLastValue,
      dateFrom,
      dateTo,
      rangeFromValue,
      rangeToValue
    } = this.state;
    switch (radioSelected) {
      case NO_FILTER:
        return true;
      case WITHIN:
        return !!(withinTheLastValue >= 0 && dropdownWithinTheLastValue);
      case MORE:
        return !!(moreThanValue >= 0 && dropdownMoreThanValue);
      case DATE:
        if (dateFrom && dateTo) {
          return (dateTo > dateFrom);
        } else if (dateFrom || dateTo) {
          return true;
        }
        return false;
      case RANGE:
        return (rangeFromValue < rangeToValue);
      default:
        return false;
    }
  };

  handleDatePickerPopupOpen = value => {
    this.setState({
      datePickerPopupOpened: value
    });
  };

  handleDropdownPopupOpen = (value) => {
    this.setState({
      dropdownPopupOpened: value
    });
  };

  handleRadioChange = (event, newValue) => {
    this.setState({
      radioSelected: newValue
    }, () => this.handleDateFilterUpdate());
  };

  handleRangeFromNumericInputChange = (event, newValue) => {
    this.setState({
      rangeFromValue: newValue
    }, () => this.handleDateFilterUpdate());

  };

  handleRangeToNumericInputChange = (event, newValue) => {
    this.setState({
      rangeToValue: newValue
    }, () => this.handleDateFilterUpdate());

  };

  handleWithinTheLastNumericInputChange = (event, newValue) => {
    this.setState({
      withinTheLastValue: newValue
    }, () => this.handleDateFilterUpdate());

  };

  handleMoreThanNumericInputChange = (event, newValue) => {
    this.setState({
      moreThanValue: newValue
    }, () => this.handleDateFilterUpdate());
  };

  handleWithinTheLastDropdownSelect = (event, value, index) => {
    this.setState({
      dropdownWithinTheLastValue: value
    }, () => this.handleDateFilterUpdate());
  };

  handleMoreThanDropdownSelect = (event, value, index) => {
    this.setState({
      dropdownMoreThanValue: value
    }, () => this.handleDateFilterUpdate());
  };

  handleRangeDropdownSelect = (event, value, index) => {
    this.setState({
      dropdownRangeValue: value
    }, () => this.handleDateFilterUpdate());
  };

  loadLastFilter = () => {
    const { option, value, units, dateFrom, dateTo, fromValue, toValue } = this.props.filter;
    if (option === LAST) {
      this.setState({
        radioSelected: WITHIN,
        withinTheLastValue: value,
        dropdownWithinTheLastValue: units,
      });
    } else if (option === BEFORE) {
      this.setState({
        radioSelected: MORE,
        moreThanValue: value,
        dropdownMoreThanValue: units,
      });
    } else if (option === BETWEEN) {
      this.setState({
        radioSelected: DATE,
        dateFrom,
        dateTo,
      });
    } else if (option === RANGE) {
      this.setState({
        radioSelected: RANGE,
        dropdownRangeValue: units,
        rangeFromValue: fromValue,
        rangeToValue: toValue,
      });
    }
  };

  togglePopover = () => {
    this.loadLastFilter();
    this.setState(prevState => ({ visible: !prevState.visible }));
  };

  closePopover = () => {
    const { datePickerPopupOpened, dropdownPopupOpened } = this.state;
    if (datePickerPopupOpened || dropdownPopupOpened) {
      return;
    }

    this.setState({
      visible: false
    });
  };

  handleChangeDateFrom = (event, date) => {
    this.setState({
      dateFrom: date,
    }, () => this.handleDateFilterUpdate());
  };

  handleChangeDateTo = (event, date) => {
    this.setState({
      dateTo: date,
    }, () => this.handleDateFilterUpdate());
  };

  createNoFilterObj = () => {
    return {
      option: NO_FILTER,
      value: undefined,
      units: undefined,
      dateFrom: undefined,
      dateTo: undefined
    };
  };

  handleDateFilterUpdate = () => {
    const { onApply } = this.props;
    const {
      radioSelected,
      moreThanValue,
      withinTheLastValue,
      dropdownMoreThanValue,
      dropdownWithinTheLastValue,
      dateFrom,
      dateTo,
      rangeFromValue,
      rangeToValue,
      dropdownRangeValue
    } = this.state;
    let filter;
    if (radioSelected === WITHIN) {
      if (this.isDataValid()) {
        filter = {
          option: LAST,
          value: withinTheLastValue,
          units: dropdownWithinTheLastValue,
          dateFrom: undefined,
          dateTo: undefined
        };
        this.setState({
          errorMessage: ''
        });
      } else {
        filter = this.createNoFilterObj();
        this.setState({
          errorMessage: labels.positiveValue
        });
      }
    } else if (radioSelected === MORE) {
      if (this.isDataValid()) {
        filter = {
          option: BEFORE,
          value: moreThanValue,
          units: dropdownMoreThanValue,
          dateFrom: undefined,
          dateTo: undefined
        };
        this.setState({
          errorMessage: ''
        });
      } else {
        filter = this.createNoFilterObj();
        this.setState({
          errorMessage: labels.positiveValue
        });
      }
    } else if (radioSelected === DATE) {
      if (this.isDataValid()) {
        filter = {
          option: BETWEEN,
          value: undefined,
          units: undefined,
          dateFrom,
          dateTo
        };
        this.setState({
          errorMessage: ''
        });
      } else {
        filter = this.createNoFilterObj();
        this.setState({
          errorMessage: labels.dateError
        });
      }
    } else if (radioSelected === RANGE) {
      if (this.isDataValid()) {
        filter = {
          option: RANGE,
          fromValue: rangeFromValue,
          toValue: rangeToValue,
          units: dropdownRangeValue,
          value: undefined,
          dateFrom: undefined,
          dateTo: undefined
        };
        this.setState({
          errorMessage: ''
        });
      } else {
        filter = this.createNoFilterObj();
        this.setState({
          errorMessage: <label>{labels.the} {<b>{labels.to}</b>} {labels.rangeError} {
            <b>{labels.from}</b>} {labels.value}</label>
        });
      }
    } else {
      filter = this.createNoFilterObj();
      this.setState({
        errorMessage: ''
      });
    }

    onApply(filter);
  };

  createHoursOnlyLabel = (dateFrom, dateTo) => {
    const localizedFromDate = toLocaleShortTime(dateFrom);
    const localizedToDate = toLocaleShortTime(dateTo);
    return `${localizedFromDate} - ${localizedToDate}`;

  };
  createFullDateLabel = (dateFrom, dateTo) => {
    const localizedFromDate = toLocaleShortDateTime(dateFrom);
    const localizedToDate = toLocaleShortDateTime(dateTo);
    return `${localizedFromDate}${localizedFromDate && localizedToDate && ' - '}${localizedToDate}`;
  };

  creatBetweenOptionLabel = (dateFrom, dateTo) => {
    const localizedShortFromDate = toLocaleShortDate(dateFrom);
    const localizedShortToDate = toLocaleShortDate(dateTo);
    const datelabel = localizedShortFromDate === localizedShortToDate ? this.createHoursOnlyLabel(dateFrom, dateTo) : this.createFullDateLabel(dateFrom, dateTo);
    return datelabel;
  };

  creatFilterCaption = () => {
    const { option, value, units, fromValue, toValue, dateFrom, dateTo } = this.props.filter;
    if (option === LAST) {
      return `${labels.last}: ${value} ${units === MINUTE ? labels.m : units === HOUR ? labels.h : labels.d}`;
    } else if (option === BEFORE) {
      return `${labels.more}: ${value} ${units === MINUTE ? labels.m : units === HOUR ? labels.h : labels.d}`;
    } else if (option === BETWEEN) {
      return this.creatBetweenOptionLabel(dateFrom, dateTo);
    } else if (option === RANGE) {
      const unitsLabel = units === MINUTE ? labels.m : units === HOUR ? labels.h : labels.d;
      return `${fromValue} ${unitsLabel} < ${toLocaleShortTime(getFolderCurrentDate())} < ${toValue} ${unitsLabel}`;
    }
  };

  handleClearFilter = (event) => {
    this.handleRadioChange(event, NO_FILTER);
    event.stopPropagation();
  };

  render() {
    const {
      radioSelected,
      moreThanValue,
      withinTheLastValue,
      dropdownList,
      dropdownWithinTheLastValue,
      dropdownMoreThanValue,
      visible,
      dateFrom,
      dateTo,
      rangeFromValue,
      rangeToValue,
      dropdownRangeValue,
      errorMessage
    } = this.state;

    const filterCaption = this.creatFilterCaption();
    const active = visible ? 'active' : '';
    const errorVisible = errorMessage ? 'visible' : '';

    return (
      <Popover
        placement={'bottom-end'}
        open={visible}
        onOpenChange={this.closePopover}
      >
        <PopoverTrigger onClick={this.togglePopover}>
          <div className={`crtx-filter-date ${active}`} title={filterCaption}>
            <div className='crtx-filter-multiselect-content'>{filterCaption}</div>
            {!filterCaption ?
              <SvgIcon
                key={FILTER_LIST_ICON}
                name={FILTER_LIST_ICON}
                className='crtx-filter-date-filter-list-icon'
              /> :
              <FlatButton
                className='crtx-filter-multiselect-clear-filter-button'
                onClick={this.handleClearFilter}>
                ×
              </FlatButton>}
          </div>
        </PopoverTrigger>

        <PopoverContent>
          <div className='crtx-filter-date-content'>
            <div className='crtx-filter-date-row'>
              <label className='crtx-filter-date-label'>
                <Radio
                  name='radioGroup' value={NO_FILTER}
                  checked={radioSelected === NO_FILTER}
                  onChange={this.handleRadioChange}
                />
                <span className='crtx-filter-date-text'>{labels.noFilter}</span>
              </label>
            </div>
            <div className='crtx-filter-date-row'>
              <label className='crtx-filter-date-label'>
                <Radio
                  name='radioGroup'
                  value={WITHIN}
                  checked={radioSelected === WITHIN}
                  onChange={this.handleRadioChange}
                />
                <span className='crtx-filter-date-text'>{labels.within}</span>
                <NumericInput
                  className='crtx-filter-date-numeric-input'
                  disabled={radioSelected !== WITHIN}
                  value={withinTheLastValue}
                  notNegative={true}
                  onChange={this.handleWithinTheLastNumericInputChange}
                />
                <Dropdown
                  disabled={radioSelected !== WITHIN}
                  onPopupOpen={this.handleDropdownPopupOpen}
                  options={dropdownList}
                  value={dropdownWithinTheLastValue}
                  onSelect={this.handleWithinTheLastDropdownSelect}
                  className='crtx-filter-date-dropdown'
                />
              </label>
            </div>
            <div className='crtx-filter-date-row'>
              <label className='crtx-filter-date-label'>
                <Radio
                  name='radioGroup'
                  value={MORE}
                  checked={radioSelected === MORE}
                  onChange={this.handleRadioChange}
                />
                <span className='crtx-filter-date-text'>{labels.moreThan}</span>
                <NumericInput
                  className='crtx-filter-date-numeric-input'
                  disabled={radioSelected !== MORE}
                  value={moreThanValue}
                  notNegative={true}
                  onChange={this.handleMoreThanNumericInputChange}
                />
                <Dropdown
                  disabled={radioSelected !== MORE}
                  onPopupOpen={this.handleDropdownPopupOpen}
                  options={dropdownList}
                  value={dropdownMoreThanValue}
                  onSelect={this.handleMoreThanDropdownSelect}
                  className='crtx-filter-date-dropdown'
                />
                <span className='crtx-filter-date-text'>{labels.ago}</span>
              </label>
            </div>
            <div className='crtx-filter-date-row'>
              <label className='crtx-filter-date-label'>
                <Radio
                  name='radioGroup'
                  value={DATE}
                  checked={radioSelected === DATE}
                  onChange={this.handleRadioChange}
                />
                <span className='crtx-filter-date-text'>{labels.between}</span>
                <DatePicker
                  disabled={radioSelected !== DATE}
                  onPopupOpen={this.handleDatePickerPopupOpen}
                  showTimeSelect
                  className='crtx-filter-date-datetimepicker'
                  onChange={this.handleChangeDateFrom}
                  value={dateFrom}
                />
                <span className='crtx-filter-date-text'>{labels.and}</span>
                <DatePicker
                  disabled={radioSelected !== DATE}
                  onPopupOpen={this.handleDatePickerPopupOpen}
                  showTimeSelect
                  className='crtx-filter-date-datetimepicker'
                  onChange={this.handleChangeDateTo}
                  value={dateTo}
                />
              </label>
            </div>
            <div className='crtx-filter-date-row'>
              <label className='crtx-filter-date-label'>
                <Radio
                  name='radioGroup'
                  value={RANGE}
                  checked={radioSelected === RANGE}
                  onChange={this.handleRadioChange}
                />
                <span className='crtx-filter-date-text'>{labels.inRange}</span>
                <NumericInput
                  className='crtx-filter-date-numeric-input'
                  disabled={radioSelected !== RANGE}
                  value={rangeFromValue}
                  onChange={this.handleRangeFromNumericInputChange}
                />
                <span className='crtx-filter-date-text'>{labels.to}</span>
                <NumericInput
                  className='crtx-filter-date-numeric-input'
                  disabled={radioSelected !== RANGE}
                  value={rangeToValue}
                  onChange={this.handleRangeToNumericInputChange}
                />
                <Dropdown
                  disabled={radioSelected !== RANGE}
                  onPopupOpen={this.handleDropdownPopupOpen}
                  options={dropdownList}
                  value={dropdownRangeValue}
                  onSelect={this.handleRangeDropdownSelect}
                  className='crtx-filter-date-dropdown'
                />
              </label>
            </div>
            <label className={`crtx-filter-date-error-label ${errorVisible}`}><i
              className='material-icons warning crtx-filter-date-error-label-icon'>warning</i>{errorMessage}</label>
            <div className='crtx-filter-date-footer'>
              <Button onClick={this.closePopover} className='crtx-filter-date-footer-button'>
                {labels.close}
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    );
  }
}