/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define(['ember', 'text!../templates/Title.hbs'], function (Ember, TitleTemplate) {
  'use strict';
  return Ember.Component.extend({
    model: undefined,
    template: Ember.Handlebars.compile(TitleTemplate)
  });
});