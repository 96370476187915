import PropTypes from 'prop-types';

define(['react', 'components/common/popup/Popup', './MultiSelectDropdownOptionItem'], function (React, Popup, MultiSelectDropdownOptionItem) {
  'use strict';

  const { Component } = React;

  const hasValue = (values, value) => {
    return values.indexOf(value) >= 0;
  };

  const isDefined = (item) => {
    return item !== undefined;
  };

  const add = (values, value) => {
    return [
      ...values,
      value
    ];
  };

  const remove = (values, value) => {
    const index = values.indexOf(value);
    return [
      ...values.slice(0, index),
      ...values.slice(index + 1)
    ];
  };

  const getIndex = (options, value) => {
    return options.findIndex(function (option) {
      if (typeof option === 'string') return option === value;

      return (option.value === value) || (option === value);
    });
  };

  const valueToTextConverter = (options) => {
    return function (value) {
      const index = getIndex(options, value);
      return index < 0 || index >= options.length ? undefined : options[index].text || options[index];
    };
  };

  const getTextByValues = (options, values) => {
    const items = values.map(valueToTextConverter(options)).filter(isDefined);

    return `(${items.length}) ${items.join(', ')}`;
  };

  const renderOptionsList = (options, selectedValues, itemComponent, isOpen, handleOptionClick) => {
    const ItemComponent = itemComponent ? itemComponent : MultiSelectDropdownOptionItem;

    if (!isOpen) return <div></div>;

    return <ul className="dropdown-list">
      {
        options.map((option, index) => (
          <ItemComponent key={index}
            text={option.text || option}
            value={option.value || option}
            index={index}
            onClick={handleOptionClick}
            selected={hasValue(selectedValues, option.value || option)}
          />
        ))
      }
    </ul>;
  };

  return class MultiSelectDropdown extends Component {

    static propTypes = {

      values: PropTypes.array,

      options: PropTypes.array,

      className: PropTypes.string,

      style: PropTypes.object,

      itemComponent: PropTypes.func,

      handlerText: PropTypes.string,

      height: PropTypes.number,

      onChange: PropTypes.func

    };

    static defaultProps = {
      values: [],
      options: [],
      onChange: function () { },
      handlerText: '▼',
      height: 200
    };

    state = {
      isOpen: false
    };

    constructor(props) {
      super(props);
    }

    toggleOpenPopupState = (isOpen) => {

      this.setState({
        isOpen
      });

    };

    handleClickedOutside = () => {

      this.toggleOpenPopupState(false);

    };

    handleClick = () => {

      this.toggleOpenPopupState(!this.state.isOpen);

    };

    handleOptionClick = (value, index) => {

      var { values, onChange } = this.props;
      var newValues;
      if (
        hasValue(values, value)
      ) {
        newValues = remove(values, value);
      }
      else {
        newValues = add(values, value);
      }

      onChange(newValues);

    };

    render() {
      const { className, style, handlerText, values, options, itemComponent, height } = this.props;
      const isOpen = this.state.isOpen;
      const dropdownClassName = ['dropdown', className].join(' ');
      const dropdownValuesText = getTextByValues(options, values);
      const optionsList = renderOptionsList(options, values, itemComponent, isOpen, this.handleOptionClick);

      return <div ref="dropdown" className={dropdownClassName} style={style} tabIndex="-1" title={dropdownValuesText} onClick={this.handleClick}>
        <div className="dropdown-content">
          {dropdownValuesText}
        </div>
        <div className="dropdown-handler">
          <span className="dropdown-handler-inner">
            {handlerText}
          </span>
        </div>
        <Popup visible={isOpen} className="dropdown-popup multiselect-dropdown-popup" opener={this.refs.dropdown} height={height} onClickedOutside={this.handleClickedOutside}>
          {optionsList}
        </Popup>
      </div>;

    }

  }

});