import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import {
  getBinWidth,
  getBinHeight,
  getBinMargin,
  isSizeMismatch
} from './utils';
import Bin from './Bin';

const labels = {
  overlapped: translate('Bins with the same position in the same group are not allowed'),
  notFound: translate('This Bin is not defined in the sorter definition'),
  positionNotDefined: translate('The Bin position should be a number between 0 and 100'),
  sizeMismatch: translate('There is a mismatch between the bin and plate size'),
};

const groupNamesByKey = {
  'top-front': translate('Top Front'),
  'top-back': translate('Top Back'),
  'bottom-front': translate('Bottom Front'),
  'bottom-back': translate('Bottom Back'),
};

const getGroupName = (level, side) => {
  return groupNamesByKey[`${level}-${side}`];
};

function BinGroup(
  {
    module,
    scale = 1,
    runNwid,
    runBreadcrumbs,
    sorterName,
    level,
    side,
    positionedBins = [],
    unpositionedBins = [],
  }) {

  const getBinErrorMessage = (bin) => {
    if (!bin) {
      return;
    }

    let msg;

    if (bin.notFound) {
      msg = labels.notFound
    } else if (bin.overlapped) {
      msg = labels.overlapped;
    } else if (bin.positionNotDefined) {
      msg = labels.positionNotDefined;
    } else if (isSizeMismatch(bin.size, bin.plateSize)) {
      msg = labels.sizeMismatch;
    }

    return msg;
  };

  const getStyle = () => {
    const binMargin = getBinMargin(scale);
    const style = {
      margin: binMargin,
      padding: binMargin,
    };

    return style;
  };

  const getHeaderStyle = () => {
    if (scale !== 1) {
      const style = {
        fontSize: Math.max(8, Math.ceil(scale * 11)),
        paddingLeft: getBinMargin(scale),
      };

      return style;
    }
  };

  const getBinContainerStyle = (double) => {
    const style = {
      width: getBinWidth(scale, double),
      height: getBinHeight(scale),
      margin: getBinMargin(scale),
    };

    return style;
  };

  const bins = positionedBins.concat(unpositionedBins);

  return (
    <div
      className='bin-group'
      style={getStyle()}
    >
      <div className='group-caption' style={getHeaderStyle()}>
        {`${sorterName} - ${getGroupName(level, side)}`}
      </div>
      <div className='bin-list'>
        {bins.map((bin, index, arr) => {
            const binErrorMessage = getBinErrorMessage(bin);

            return (
              <div
                key={index}
                className={classNames('bin-container', { placeholder: !bin, error: !!binErrorMessage })}
                style={getBinContainerStyle(bin?.doubleSize)}
                title={binErrorMessage}
              >
                {!!bin &&
                <Bin
                  scale={scale}
                  module={module}
                  runNwid={runNwid}
                  runBreadcrumbs={runBreadcrumbs}
                  errorMessage={binErrorMessage}
                  {...bin}
                />}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

BinGroup.propTypes = {
  module: PropTypes.object,
  scale: PropTypes.number,
  runNwid: PropTypes.string,
  runBreadcrumbs: PropTypes.string,
  sorterName: PropTypes.string,
  level: PropTypes.string,
  side: PropTypes.string,
  positionedBins: PropTypes.array,
  unpositionedBins: PropTypes.array,
};

export default BinGroup;