import React from 'react';
import PropTypes from 'prop-types';
import SeperationPreview from 'components/private/separations/SeperationPreview';

import { translate } from 'core/services/localization';

const labels = {
  correction: translate('Correction'),
  none: translate('None'),
  direct: translate('Direct'),
}

const COLORS = ['black', 'cyan', 'magenta', 'yellow'];

function ProductionRun({ run }) {

  const { direct, shortLabel, correction } = run;

  const getColors = () => {
    if (!direct['black'] && !direct['cyan'] && !direct['magenta'] && !direct['yellow']) {
      return <span className='regular-colors-content'>{labels.none}</span>
    }
    return <span className='regular-colors-content'>{COLORS.map(renderColor)}</span>;
  }

  const renderColor = (color, index) => {

    if (direct[color]) {
      return <SeperationPreview key={index} colorName={color} />
    }
  }

  return (
    <div className='production-run'>
      <div className='custom-info-row'>
        <span className='custom-info-row-title'>{shortLabel}:</span>
      </div>
      <div className='custom-info-row'>
        <span className='custom-info-row-title'>{labels.direct}: </span>
        <span className='custom-info-row-content'>
          {getColors()}
        </span>
      </div>
      <div className='custom-info-row'>
        <span className='custom-info-row-title'>{labels.correction}: </span>
        <span className='custom-info-row-content'>
          {correction || labels.none}
        </span>
      </div>
    </div>
  );
}

ProductionRun.propTypes = {
  run: PropTypes.any,
};

export default ProductionRun;

