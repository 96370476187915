const ServerAction = require('./ServerAction');

module.exports = ServerAction.extend({
  isApplicable: function (objs) {
    if (typeof objs === "undefined"  || objs.length === 0) {return false;}
    if (objs.length > 1) {return false;}
    
    return (typeof objs[0].needAllRunPlates === "undefined" || "true" !== objs[0].needAllRunPlates);
    
  }
});
