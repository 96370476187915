import { translate } from 'core/services/localization';
import icons from 'core/services/iconService';

export const BYTES_MULTIPLIER = 1048576;

export const PAGE_STATUS_OPTIONS = [
  {
    value: 'error',
    data: { icon: icons.getGeneralIcon('status', 'error'), title: translate('Error') }
  },
  {
    value: 'waiting',
    data: { icon: icons.getGeneralIcon('status', 'wait'), title: translate('Waiting') }
  },
  {
    value: 'success',
    data: { icon: icons.getGeneralIcon('status', 'finished'), title: translate('Finished') }
  },
];

export const FILE_TYPE_OPTIONS = [
  {
    value: 'pdf',
    data: { icon: icons.getModuleIcon('TableView', 'pdf'), title: translate('PDF') }
  },
  {
    value: 'tif',
    data: { icon: icons.getModuleIcon('TableView', 'tif'), title: translate('TIFF') }
  }
];

export const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'inputTime'
  }
];