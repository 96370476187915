import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import iconService from 'core/services/iconService';
import StyleConverter from '../styleConverter';
import pageStyleMap from '../models/pagestylemap';
import segmentStyleMap from '../models/segmentStyleMap';
import BirdeyeElement from './birdeyeelement';
import SelectedElement from './selectedElement';
import ClickableElement from './clickableElement';
import AlignmentPoint from './alignmentPoint';
import DroppableArea from './droppableArea';
import toastService from 'core/services/toastService';
import pubsub from 'core/managers/pubsub';

const translate = sandbox.localization.translate;
const pageStyleConverter = new StyleConverter(pageStyleMap);
const segmentStyleConverter = new StyleConverter(segmentStyleMap);

export default class Page extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    page: undefined,
    pageSize: undefined,
    gridAlign: undefined,
    pageDefinition: undefined,
    alignmentPoints: undefined,
    onClick: undefined,
    onContextMenu: undefined,
    onDoubleClick: undefined
  };

  getGeneralProperties = (pageDefinition) => {
    return pageDefinition.generalProperties || {};
  };

  getPageViewportDefinition = (pageDefinition, page) => {
    var isPanorama = page.pageContent.isPanorama,
      pageFormat = page.pageContent.format,
      defaultViewport = typeof pageDefinition.single === 'undefined' ? pageDefinition : pageDefinition.single;

    if (isPanorama) {
      if (pageFormat === 'Tabloid') {
        return typeof pageDefinition['double-vertical'] === 'undefined' ? defaultViewport : pageDefinition['double-vertical'];
      }
      return typeof pageDefinition['double'] === 'undefined' ? defaultViewport : pageDefinition['double'];
    }

    return defaultViewport;
  };

  getPageStyleDefinition = (page, generalProperties, controller) => {
    var styleHelperFunctions = controller.styleHelperFunctions || {};

    return pageStyleConverter.toStyle(generalProperties, page, styleHelperFunctions, controller);
  };

  getPageContentStyle = (size, align, ignore, styleDefinition) => {
    var style = styleDefinition;
    style.width = size[0];
    style.height = size[1];
    style.float = align;
    style.backgroundImage = ignore ? 'url(' + iconService.getModuleIcon('MyBirdeye', 'ignore_l') + ')' : null;
    style.backgroundPosition = ignore ? '50% 50%' : null;
    style.backgroundRepeat = ignore ? 'no-repeat' : null;

    return style;
  };

  getElements = (alignmentPointDefinition, pageContentStyle, page, segmentStyle) => {
    return alignmentPointDefinition.elements.map(function (elementDefinition, elementIndex) {
      return <BirdeyeElement key={[page.id, alignmentPointDefinition.location, elementIndex].join('.')}
        point={alignmentPointDefinition}
        model={page}
        definition={elementDefinition}
        parentsStyle={[segmentStyle, pageContentStyle]}>
      </BirdeyeElement>;
    });
  };

  getAlignedElements = (
    alignmentPointDefinition,
    pageContentStyle,
    page,
    segmentLocation,
    segmentStyle,
  ) => {
    var elements = this.getElements(alignmentPointDefinition, pageContentStyle, page, segmentStyle);

    return <AlignmentPoint key={[page.id, alignmentPointDefinition.location].join('.')} style={segmentStyle} location={segmentLocation} definition={alignmentPointDefinition}>
      {elements}
    </AlignmentPoint>;
  };

  getSegments = (pageViewportDefinition, alignmentPoints, page, pageContentStyle) => {
    var that = this,
      alignedSegments = [],
      notAlignedSegments = {};

    if (typeof pageViewportDefinition.segments === 'undefined') {
      return {
        alignedSegments,
        notAlignedSegments
      };
    }

    pageViewportDefinition.segments.forEach(function (segmentDefinition) {
      var segment = {},
        segmentLocation = segmentDefinition.location,
        alignmentPointDefinition = Object.assign({}, alignmentPoints[segmentLocation], segmentDefinition),
        alignmentPoint,
        segmentStyle = segmentStyleConverter.toStyle(alignmentPointDefinition, page);

      if (typeof alignmentPoints[segmentLocation] !== 'undefined') {
        alignmentPoint = that.getAlignedElements(alignmentPointDefinition, pageContentStyle, page, segmentLocation, segmentStyle);
        alignedSegments.push(alignmentPoint);
      }
      else {
        alignmentPoint = that.getElements(alignmentPointDefinition, pageContentStyle, page, {});
        notAlignedSegments[segmentLocation] = alignmentPoint;
      }
    });

    return {
      alignedSegments,
      notAlignedSegments
    };
  };

  onDrop = (ev) => {

    ev.preventDefault();
    const { page } = this.props;
    if (page.aggregated) {
      return;
    }
    const unplannedPages = JSON.parse(ev.dataTransfer.getData('Text'));
    if (unplannedPages[0].publicationName && unplannedPages[0].publicationName.toLowerCase() !== page.publication.toLowerCase()) {
      sandbox.dialog.alert(translate('The source and target publications do not match'), translate('Cannot drop file'));
    } else {
      this.context.controller.mapUnplannedPage(page, unplannedPages).then((result) => {
        if (!result.data.success) {
          pubsub.publish('drop-on-page', { unplannedPages, success: false });
          toastService.errorToast(result.data.subject, result.data.message);
        }
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (nextProps.page.aggregated && nextProps.page.relatedPages.some(rp => rp.__isDirty === true)) ||
      nextProps.page.__isDirty === true
    );
  }

  render() {
    var {
      page,
      pageSize,
      gridAlign,
      pageDefinition,
      alignmentPoints,
      onClick,
      onContextMenu,
      onDoubleClick
    } = this.props,
      controller = this.context.controller,
      pageViewportDefinition = this.getPageViewportDefinition(pageDefinition, page),
      generalProperties = this.getGeneralProperties(pageDefinition),
      pageContentStyleDefinition = this.getPageStyleDefinition(page, generalProperties, controller),
      pageContentStyle = this.getPageContentStyle(pageSize, gridAlign, page.ignore, pageContentStyleDefinition),
      selectionColor = generalProperties.selectionColor,
      overColor = generalProperties.overColor,
      {
        alignedSegments,
        notAlignedSegments
      } = this.getSegments(pageViewportDefinition, alignmentPoints, page, pageContentStyle);

    return <div className="Page" id={page.id} key={page.id}>
      <div className="PageHeader">
        {notAlignedSegments['above']}
      </div>
      <div className="PageContent" style={pageContentStyle}>
        <ClickableElement onClick={onClick}
          onContextMenu={onContextMenu}
          onDoubleClick={onDoubleClick}
          overColor={overColor}>
          <SelectedElement model={page} style={{ borderColor: selectionColor }}>
          </SelectedElement>
          <DroppableArea onDrop={this.onDrop}>
            {alignedSegments}
          </DroppableArea>
        </ClickableElement>
      </div>
      <div className="PageFooter">
        {notAlignedSegments['under']}
      </div>
    </div>;
  }
}