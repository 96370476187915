/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define( [ 'sandbox', 'ember' ],
  function ( sandbox, Ember ) {
    'use strict';

    function SectionModel ( model ) {
      if ( model === undefined || model.id === undefined || model.nwid === undefined ) return undefined;
      return Ember.Object.create({
        id: model.id,
        index: model.index,
        nwid: model.nwid,
        type: model.type || 'section',
        name: model.name,
        'pages:sorting': model['pages:sorting'],
        pages: [],
        '_model': model
      });
    }

    return SectionModel;
  });