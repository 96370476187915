import { parseNewswayDateTime } from 'core/dates';
import iconService from 'core/services/iconService';
import colorService from 'core/services/colorService';
import { translate, toLocaleShortDate, toLocaleShortDateTime } from 'core/services/localization';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';
import { STATUS_BY_QUEUE_TYPE, LABEL_BY_QUEUE_TYPE, ALIGN_BY_COLUMN_KEY, STATE_OPTIONS } from './constants';
import { getBaseObjectType } from './utils';
import { arrayToDistinctEntries } from 'utilities/array';
import { NO_VALUE } from 'widgets/ReactDataGrid/utils';
import { ICON_SPRITE } from 'components/common/buttons/SvgIcon';

export function makeEventTableColumns(guiSettings, preferences) {
  const columnDefs = guiSettings.columns || [];
  const columnPrefs = preferences.table ? Array.isArray(preferences.table.columns) ? preferences.table.columns : [] : [];

  const totalWidth = columnPrefs.reduce((sum, pref) => sum + (pref.width || 0), 0);
  const percent = totalWidth < 105; // the old column width was in percent

  const columns = columnDefs.map(def => {
    const key = def.titles[0];
    const column = {
      caption: def.localized ? translate(def.displayName) : def.displayName,
      id: def.id,
      key,
      visible: true,
      align: ALIGN_BY_COLUMN_KEY[key],
      shouldCellUpdate: getShouldCellUpdate(key),
    };

    return column;
  });

  const result = [];
  columnPrefs.forEach(pref => {
    const idx = columns.findIndex(col => col && col.id === pref.id);
    if (idx >= 0) {
      const column = columns[idx];
      columns[idx] = undefined;
      result.push(column);

      column.visible = pref.isVisible !== false;
      column.filter = pref.filter;
      if (pref.filter && pref.filter.option === 'between') {
        column.filter = {
          ...pref.filter,
          dateFrom: parseNewswayDateTime(pref.filter.dateFrom),
          dateTo: parseNewswayDateTime(pref.filter.dateTo)
        };
      }

      if (!percent && pref.width >= 0) {
        column.width = pref.width;
      }
    }
  });

  return result.concat(columns.filter(col => !!col));
}

function getColumnFilterValueGetter(columnKey, viewModel) {
  if (columnKey === 'objectType') {
    return event => getObjectTypeFilterValueGetter(event);
  } else if (columnKey === 'lockedTo') {
    return (event) => getEventResourceName(event, viewModel) || NO_VALUE;
  } else if (columnKey === 'Publication Date') {
    return event => event[columnKey] ? String(event[columnKey].getTime()) : NO_VALUE;
  } else {
    return event => (typeof event[columnKey] !== 'undefined' && event[columnKey] !== '') ? event[columnKey] : NO_VALUE;
  }
}

function getShouldCellUpdate(columnKey) {
  if (columnKey === 'objectType' || columnKey === 'state') {
    return function (nextProps, props) {
      return nextProps.columnData !== props.columnData || nextProps.icon !== props.icon;
    };
  }
}

function getObjectTypeFilterValueGetter(event) {
  let objectType = getBaseObjectType(event.objectType) || 'file';
  if (objectType === 'page/separation' || objectType === 'form/separation' || objectType === 'plate') {
    objectType += event.colorName || '';
  }

  return objectType;
}

const getIcon = (objectType, contentType) => {
  let icon = '';
  switch (objectType) {
    case 'fragment':
      icon = contentType === 'AD' ? 'ad_20' : 'editorial_20';
      break;
    case 'fragment/separation':
      icon = contentType === 'AD' ? 'ad_separation_20' : 'editorial_separation_20';
      break;
    default: icon = iconService.getTemplateIcon(objectType, 'tiny');
  }
  return icon;
};

function getEventObjectTypeIcon(event) {

  const objectType = getBaseObjectType(event.objectType) || 'file';
  const icon = getIcon(objectType, event.contentType);
  let color;
  const isFragment = objectType.indexOf('fragment') !== -1;

  if (objectType === 'page/separation' || objectType === 'form/separation' || objectType === 'plate' || objectType === 'fragment/separation') {
    color = colorService.getUIColorRGB(colorService.getColorByName(event.colorName));
  }

  let columnData = {
    icon,
    iconStyle: isFragment ? { color } : { backgroundColor: color },
    title: isFragment ? translate('fragment') + ' ' + event.contentType : event.objectType,
    iconSprite: isFragment ? ICON_SPRITE.TEMPLATE : ''
  };

  return columnData;
}

function getEventObjectTypeTooltip(event) {
  return event.objectType || 'file';
}

function getEventStateIcon(event) {
  let iconName = STATUS_BY_QUEUE_TYPE[event.state] || '';
  if (event.state === 'queWait' || event.state === 'queHold') {
    const lockedTo = event.lockedTo ? event.lockedTo.split(',') : [];
    if (lockedTo.length === 1 && lockedTo[0]) {
      iconName += '_lock';
    }
  }

  return {
    icon: iconService.getGeneralIcon('status', iconName),
    title: LABEL_BY_QUEUE_TYPE[event.state]
  };
}

function getEventStateTooltip(event) {
  return LABEL_BY_QUEUE_TYPE[event.state] || '';
}

function getShortDate(data) {
  return data ? toLocaleShortDate(data) : '';
}

function getShortDateTime(data) {
  return data ? toLocaleShortDateTime(data, true) : '';
}

function getEventResourceName(event, viewModel) {
  let resourceName;

  if (event.resource) {
    const resource = viewModel.resourcesByNwid[event.resource];
    if (resource) {
      resourceName = resource.name;
    }
  } else {
    resourceName = event.lockedTo;
  }

  return resourceName || '';
}

export function getEventTableCellData(event, column, viewModel) {
  const columnKey = column.key;
  const data = event[columnKey];

  switch (columnKey) {
    case 'objectType':
      return getEventObjectTypeIcon(event);
    case 'state':
      return getEventStateIcon(event);
    case 'Publication Date':
      return {
        columnData: getShortDate(data)
      };
    case 'EventTime':
      return {
        columnData: getShortDateTime(data)
      };
    case 'lockedTo':
      return {
        columnData: getEventResourceName(event, viewModel)
      };
    case 'Breadcrumbs':
      return {
        columnData: formatBreadcrumbs(data)
      };
    default:
      return {
        columnData: data
      };
  }
}

export function createMultiselectFilterOptions(events, column, viewModel) {
  let filterOptions;

  column.filterValueGetter = getColumnFilterValueGetter(column.key, viewModel);
  if (column.key === 'state') {
    filterOptions = STATE_OPTIONS;
  } else {
    const entries = arrayToDistinctEntries(events, column.filterValueGetter);
    filterOptions = entries.map(([value, event]) => ({
      value,
      data: getEventTableCellData(event, column, viewModel)
    }));
  }

  return filterOptions;
}

export function getEventTableCellTooltip(event, column, viewModel) {
  const columnKey = column.key;
  const data = event[columnKey];

  switch (columnKey) {
    case 'objectType':
      return getEventObjectTypeTooltip(event);
    case 'state':
      return getEventStateTooltip(event);
    case 'Publication Date':
      return getShortDate(data);
    case 'EventTime':
      return getShortDateTime(data);
    case 'lockedTo':
      return getEventResourceName(event, viewModel);
    case 'Breadcrumbs':
      return formatBreadcrumbs(data);
    default:
      return typeof data !== 'undefined' ? data : '';
  }
}
