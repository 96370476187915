import React from 'react';
import PropTypes from 'prop-types';

export default class TextOption extends React.Component {
  render() {
    const { text } = this.props;
    return (
      <div className='crtx-filter-multiselect-item-text-option'>
        <span>{text}</span>
      </div>
    )
  }
}

TextOption.propTypes = {
  text: PropTypes.string
}