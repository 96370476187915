export const deg2rad = deg => deg * Math.PI / 180;
export const rad2deg = rad => rad * 180 / Math.PI;
export const getDistanceBetweenPoints = (a, b) => Math.sqrt(Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2));
export const getPointByRotation = ({ x, y }, { x: centerX, y: centerY }, angle) => {
  const rad = deg2rad(angle);
  const offsetX = x - centerX;
  const offsetY = y - centerY;
  const rotatedX = offsetX * Math.cos(rad) - offsetY * Math.sin(rad);
  const rotatedY = offsetX * Math.sin(rad) + offsetY * Math.cos(rad);

  return {
    x: rotatedX + centerX,
    y: rotatedY + centerY
  };
};

export const measureTextSize = (ctx, text = '') => {
  const metrics = ctx.measureText(text);

  return {
    width: metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight,
    height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
  }
};

export class CanvasUtilities {
  constructor(canvasWidth, canvasHeight) {
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.offsetX = 0;
    this.offsetY = 0;
    this.clientLeft = 0;
    this.clientTop = 0;
  }

  getCanvasSize = () => {
    return {
      width: this.canvasWidth,
      height: this.canvasHeight
    };
  };

  setCanvasSize = (width, height) => {
    this.canvasWidth = width;
    this.canvasHeight = height;

    return this;
  };

  getCanvasOffset = () => {
    return {
      x: this.offsetX,
      y: this.offsetY
    };
  };

  setCanvasOffset = (offsetX = 0, offsetY = 0) => {
    this.offsetX = offsetX;
    this.offsetY = offsetY;

    return this;
  };

  getCanvasClientOffset = () => {
    return {
      left: this.clientLeft,
      top: this.clientTop
    };
  };

  setCanvasClientOffset = (clientLeft = 0, clientTop = 0) => {
    this.clientLeft = clientLeft;
    this.clientTop = clientTop;

    return this;
  };

  getCanvasByScreenPoint = (x, y) => {
    const canvasCenterPoint = {
      x: this.canvasWidth / 2,
      y: this.canvasHeight / 2
    };
    const canvasOffsetCenterPoint = {
      x: ((canvasCenterPoint.x - (x - this.offsetX)) / canvasCenterPoint.x) * this.clientLeft,
      y: ((canvasCenterPoint.y - (y - this.offsetY)) / canvasCenterPoint.y) * this.clientTop
    }

    return {
      x: (x - canvasOffsetCenterPoint.x) - (canvasCenterPoint.x + this.offsetX),
      y: (y - canvasOffsetCenterPoint.y) - (canvasCenterPoint.y + this.offsetY)
    };
  };

  getScreenByCanvasPoint = (x, y) => {
    const canvasCenterPoint = {
      x: this.canvasWidth / 2,
      y: this.canvasHeight / 2
    };
    const canvasOffsetCenterPoint = {
      x: ((canvasCenterPoint.x - (x - this.offsetX)) / canvasCenterPoint.x) * this.clientLeft,
      y: ((canvasCenterPoint.y - (y - this.offsetY)) / canvasCenterPoint.y) * this.clientTop
    }

    return {
      x: this.offsetX + canvasCenterPoint.x + (x - canvasOffsetCenterPoint.x),
      y: this.offsetY + canvasCenterPoint.y + (y - canvasOffsetCenterPoint.y)
    };
  };
}

export class StageUtilities {
  constructor() {
    this.offsetX = 0;
    this.offsetY = 0;
    this.zoom = 1;
    this.rotation = 0;
    this.flipHorizontalValue = 1;
    this.flipVerticalValue = 1;
  }

  getOffset = () => {
    return {
      x: this.offsetX,
      y: this.offsetY
    };
  };

  setOffset = (x, y) => {
    this.offsetX = x;
    this.offsetY = y;

    return this;
  };

  getZoom = () => this.zoom;

  setZoom = (zoom) => {
    this.zoom = zoom;

    return this;
  };

  getRotation = () => this.rotation;

  setRotation = (rotation) => {
    this.rotation = rotation;

    return this;
  };

  getFlipHorizontal = () => this.flipHorizontalValue === -1 ? true : false;

  setFlipHorizontal = (flipHorizontal) => {
    this.flipHorizontalValue = !!flipHorizontal ? -1 : 1;

    return this;
  };

  getFlipVertical = () => this.flipVerticalValue === -1 ? true : false;

  setFlipVertical = (flipVertical) => {
    this.flipVerticalValue = !!flipVertical ? -1 : 1;

    return this;
  };

  getStageByCanvasPoint = (x, y) => {
    return getPointByRotation({
      x: (x - this.offsetX) / this.zoom * this.flipHorizontalValue,
      y: (y - this.offsetY) / this.zoom * this.flipVerticalValue
    }, { x: 0, y: 0 }, -this.rotation * this.flipHorizontalValue * this.flipVerticalValue);
  };

  getCanvasByStagePoint = (x, y) => {
    return getPointByRotation({
      x: x * this.zoom * this.flipHorizontalValue,
      y: y * this.zoom * this.flipVerticalValue
    }, { x: 0, y: 0 }, this.rotation * this.flipHorizontalValue * this.flipVerticalValue);
  };

  getStageByCanvasPoint_Limited = (x, y, imageW, imageH) => {

    const halfImageWidth = imageW / 2;
    const halfImageHeight = imageH / 2;
    const pointOnStage = this.getStageByCanvasPoint(x, y);

    // console.log('Stage before: ' + pointOnStage.x + ' y: ' + pointOnStage.y);

    if ((-(halfImageWidth) < pointOnStage.x ) ==( pointOnStage.x > (halfImageWidth))) {
      pointOnStage.x = pointOnStage.x > 0 ? halfImageWidth : -halfImageWidth;
    }
    if ((-(halfImageHeight) < pointOnStage.y ) ==( pointOnStage.y > (halfImageHeight))) {
      pointOnStage.y = pointOnStage.y > 0 ? halfImageHeight : -halfImageHeight;
    }
    // console.log('Stage after: ' + pointOnStage.x + ' y: ' + pointOnStage.y);

    return pointOnStage;
  };
}