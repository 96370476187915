import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from '../../common/popup/Popup';
import NumericInput from '../../common/inputs/NumericInput';
import DatePicker from '../../common/dropdown/DatePicker';
import {formatDate} from 'core/dates';
import localization from 'core/services/localization';
import KEYS from 'core/keys';

class DayTimeOffset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      datePickerPopupOpened: false
    };

    this.currentDate = new Date();
  }

  static propTypes = {
    value: PropTypes.shape({
      days: PropTypes.string.isRequired,
      hours: PropTypes.string.isRequired,
      minutes: PropTypes.string.isRequired
    }),
    timeFormat: PropTypes.string,
    timeTitle: PropTypes.string,
    offsetTitle: PropTypes.string,
    timeIntervals: PropTypes.number,
    min: PropTypes.shape({
      days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    max: PropTypes.shape({
      days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func
  };

  static defaultProps = {
    value: { days: '0', hours: '00', minutes: '00' },
    timeFormat: localization.getLocaleTimeFormat(),
    timeTitle: 'Time',
    offsetTitle: 'Days',
    onChange: () => { }
  };

  getDate(hours, minutes) {
    const date = new Date(this.currentDate.getTime());
    date.setHours(hours || 0, minutes || 0);

    return date;
  }

  handleDatePickerPopupOpen = value => {
    this.setState({
      datePickerPopupOpened: value
    });
  };

  togglePopup = () => {
    if (!this.props.disabled) {
      this.setState({
        visible: !this.state.visible
      });
    }
  };
  closePopup = () => {
    if (this.state.datePickerPopupOpened) {
      return;
    }
    this.setState({
      visible: false
    });
  };

  handleDaysChange = (event, days) => {
    const { value, onChange } = this.props;

    const newValue = {
      days: days.toString(),
      hours: value.hours,
      minutes: value.minutes
    };

    onChange(event, newValue);
  };

  handleTimeChange = (event, date) => {
    const { value, onChange } = this.props;
    const newValue = {
      days: value.days,
      hours: date.getHours().toString(),
      minutes: date.getMinutes().toString()
    };

    onChange(event, newValue);
  };

  handleKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode === KEYS.RETURN) {
      this.closePopup()
    }
  }

  renderPopup = value => {
    const { timeFormat, timeTitle, offsetTitle, timeIntervals } = this.props;

    const min = this.props.min || {};
    const max = this.props.max || {};

    const selectedTime = this.getDate(value.hours, value.minutes);
    let minTime, maxTime;
    if (min.hours || min.minutes || max.hours || max.minutes) {
      minTime =
        Number(value.days) === Number(min.days)
          ? this.getDate(min.hours, min.minutes)
          : this.getDate(0, 0);
      maxTime =
        Number(value.days) === Number(max.days)
          ? this.getDate(max.hours, max.minutes)
          : this.getDate(23, 59);
    }

    return (
      <div className='crtx-day-time-offset-popup-content'>
        <div>
          <div className='crtx-offset-title'>{offsetTitle}</div>
          <div className='crtx-offset-input'>
            <NumericInput
              style={{ width: '100%' }}
              value={Number(value.days)}
              onChange={this.handleDaysChange}
              onKeyPress={this.handleKeyPress}
            />
          </div>
        </div>
        <div className='crtx-offset-title'>{timeTitle}</div>

        <DatePicker
          displayTimeCaption={false}
          style={{ width: '100%' }}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          value={selectedTime}
          timeFormat={timeFormat}
          minTime={minTime}
          maxTime={maxTime}
          timeIntervals={timeIntervals}
          timeCaption={timeTitle}
          onChange={this.handleTimeChange}
          onPopupOpen={this.handleDatePickerPopupOpen}
          onKeyPress={this.handleKeyPress}
        />
      </div>
    );
  };

  render() {
    const { value, timeFormat, style, disabled } = this.props;
    const { visible } = this.state;
    const disabledClassName = disabled ? 'disabled' : '';
    const className = `crtx-day-time-offset ${this.props.className} ${disabledClassName}`;
    const handlerClassName = `crtx-day-time-offset-handler ${disabledClassName}`;
    const text = `${value.days}d - ${formatDate(
      this.getDate(value.hours, value.minutes),
      timeFormat
    )}`;

    return (
      <div
        ref='dayTimeOffsetRef'
        className={className}
        style={style}
        title={text}
        disabled={disabled}
        onClick={this.togglePopup}
      >
        <div className='crtx-day-time-offset-content'>{text}</div>
        <div className={handlerClassName}>▼</div>
        <Popup
          className='crtx-day-time-offset-popup'
          opener={this.refs.dayTimeOffsetRef}
          visible={visible}
          onClickedOutside={this.closePopup}
          width={140}
          height={100}
        >
          {visible ? this.renderPopup(value) : null}
        </Popup>
      </div>
    );
  }
}

export default DayTimeOffset;
