/**
 * @name
 * @fileOverview
 * @author Guy Behar
 */

define( [ 'sandbox', 'ember' ],
  function ( sandbox, Ember ) {
    'use strict';

    function AggregateModel ( model ) {
      if ( model === undefined || model.id === undefined || model.nwid === undefined ) return undefined;
      return Ember.Object.create({
        id: model.id,
        nwid: model.nwid,
        type: model.type || 'aggregate',
        totalFinishedPlates: model.totalFinishedPlates,
        totalPlates: model.totalPlates,
        '_model': model
      });
    }

    return AggregateModel;
  });
