import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'core/services/localization';
import {observer} from 'mobx-react';
import Book from './Book';
import {BOOKS_MODE} from './Book';


const GRID_MIN_SIZE = 5;

const labels = {
  name: translate('Name'),
  skipApproval: translate('Skip Approval'),
  layoutGroup: translate('Layout Group'),
  pmi: translate('PMI'),
  sections: translate('Sections'),
  paperType: translate('Paper Type'),
  commonProperties: translate('Common Properties'),
  propertyA: translate('Property A'),
  propertyB: translate('Property B'),
  propertyC: translate('Property C'),
  propertyD: translate('Property D'),
  removeBook: translate('Remove Book'),
  addSection: translate('Add Section'),
  removeSection: translate('Remove Section'),
  editPMITemplate: translate('Edit PMI Template'),
  pagesNumber: translate('#Pages'),
  applyToAllZones: translate('Apply to all zones'),
  thereShouldBeXPagesInTheZone: (total, pages) => translate('* There should be {1} pages in the zone, currently you selected {2}.', total, pages),
  booksWithZeroPagesAreNotAllowed: translate('* Books with 0 pages are not allowed.'),


};

class BookList extends Component {

  static propTypes = {
    model: PropTypes.object,
    booksMode: PropTypes.string,
    onPmiClick: PropTypes.func
  };

  handleRemoveBook = (index) => {
    model.books.remove(model.books[index]);
  };

  handlePmiClick = (book, index) => {
    const {onPmiClick} = this.props;
    onPmiClick(book, index);
  };

  handleApplyToAllZonesClick = () => {
    const {model} = this.props;
    model.onApplyClick(model);

  }

  renderBook = () => (book, index) => {
    const {model, booksMode} = this.props;

    return (
      <Book
        key={model.displayName + book.sourceId}
        booksMode={booksMode}
        book={book}
        model={model}
        index={index}
        onPmiClick={(book, index) => this.handlePmiClick(book, index)}>
      </Book>
    );
  };


  getStyleString = (number) => {

    let autoStr = '';

    autoStr = Array.from({length: number - 1}, () => 'auto').join(' ');

    return autoStr + ' minmax(auto, 1fr)';
  };

  getGridStyle = () => {
    const {model, booksMode} = this.props;
    let gridSize = GRID_MIN_SIZE;

    if (model.configuration.customFields && model.configuration.customFields.length > 0) {
      gridSize += model.configuration.customFields.length;
    }
    if (model.books.some(book => book.layoutType === 'custom')) {
      gridSize += 1;
    }
    if (model.configuration.paperTypeOptions && model.configuration.paperTypeOptions.length > 0) {
      gridSize += 1;
    }
    if (booksMode === BOOKS_MODE.saddleStitch) {
      gridSize += 1;
    }

    return this.getStyleString(gridSize);
  };

  renderCustomPropertyName = () => (prop, index) => {

    return <label key={index}>{prop.label} </label>;
  };

  render() {
    const {model, booksMode} = this.props;

    const gridStyle = this.getGridStyle();
    const isPmiAvailable = model.books.some(book => book.layoutType === 'custom');
    const pagesNumberIsNotValid = !(model.books.reduce((sum, book) => {return sum + book.numPages}, 0) === model.numPages);
    const pagesNumberIsZero = model.books.find(book => book.numPages === 0);
    const pagesTotalByBooks = model.books.reduce((sum, book) => {return sum + book.numPages}, 0);

    return (
      <div className='crtx-form-books-list-container'>
        <div className='crtx-form-books-list-container-main-zone'>
          {model.books.length > 0 && <div className='crtx-form-dynamic-columns' style={{gridTemplateColumns: gridStyle}}>
            <label>{labels.name}</label>
            <label>{labels.skipApproval}</label>
            <label>{labels.layoutGroup}</label>
            {isPmiAvailable && <label>{labels.pmi}</label>}
            {model.configuration.paperTypeOptions && model.configuration.paperTypeOptions.length > 0 && <label>{labels.paperType}</label>}
            {booksMode === BOOKS_MODE.saddleStitch && <label>{labels.pagesNumber}</label>}
            <label>{labels.sections}</label>
            {model.configuration.customFields && model.configuration.customFields && model.configuration.customFields.length > 0 && model.configuration.customFields.map(this.renderCustomPropertyName())}
            <label></label>

            {model.books && model.books.map(this.renderBook())}
          </div>
          }
          <div className='crtx-form-action-buttons-container'>
            <button className='crtx-form-right-alignment' onClick={this.handleApplyToAllZonesClick}>{labels.applyToAllZones}</button>
          </div>

        </div>

        {booksMode === BOOKS_MODE.saddleStitch && (pagesNumberIsNotValid || pagesNumberIsZero) && <div className='crtx-form-books-list-container-validation-zone'>
          {pagesNumberIsNotValid && <label className='crtx-form-warning-message'>{labels.thereShouldBeXPagesInTheZone(model.numPages, pagesTotalByBooks)}</label>}
          {pagesNumberIsZero && <label className='crtx-form-warning-message'>{labels.booksWithZeroPagesAreNotAllowed}</label>}
        </div>}
      </div>

    );
  }
}

export default observer(BookList);
