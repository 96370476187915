define(['sandbox', 'ember', 'logger'], function (sandbox, Ember, logger) {
    'use strict';
    var log = logger.getDefaultLogger();

    return Ember.ObjectController.extend({

        deleteMonitorProfile: function (monitorProfile) {
            var deleteMonitorProfileAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'DeleteMonitorProfileActionCR');
            if (typeof deleteMonitorProfileAction === 'undefined') {
                log.error('DeleteMonitorProfileAction instance is not found');
                return;
            }

            if (deleteMonitorProfileAction.isApplicable(monitorProfile)) {
                deleteMonitorProfileAction.execute(monitorProfile);
            }
        }
    });

});
