import React from 'react';
import PropTypes from 'prop-types';
import ConversionOutput from './ConversionOutput';
import { translate } from 'core/services/localization';

const SERVER_UNIT = 'inch';
const FACTOR_BY_UNIT = {
  mm: 25.4,
  inch: 1,
  pt: 72
};

const DECIMALS_BY_UNIT = {
  mm: 3,
  inch: 4,
  pt: 2
};

const SYMBOL_BY_UNIT = {
  inch: translate('in'),
  mm: translate('mm'),
  pt: translate('pt'),
};

export default function LengthOutput(
  {
    value,
    unit = SERVER_UNIT,
    className,
    style,
  }) {

  return (
    <ConversionOutput
      value={value}
      unitSymbol={SYMBOL_BY_UNIT[unit]}
      factor={FACTOR_BY_UNIT[unit]}
      decimalPlaces={DECIMALS_BY_UNIT[unit]}
      className={className}
      style={style}
    />
  );
}

LengthOutput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};
