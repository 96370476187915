import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Progressbar from '../../progressbar';

export default class SeparationPlatesProgress extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    separation: undefined,
    plates: undefined,
    definition: undefined,
    color: undefined
  };

  platesCount = plates => {
    return Array.isArray(plates) ? plates.length : 0;
  };

  finishedPlates = (plates, separation, controller) => {
    if (!Array.isArray(plates)) {
      return 0;
    }
    return plates.filter(function(plate) {
      return controller.isCompletedPlate(separation, plate);
    }).length;
  };

  render() {
    var { separation, plates, color } = this.props,
      controller = this.context.controller,
      platesCount = this.platesCount(plates),
      finishedPlates = this.finishedPlates(plates, separation, controller),
      progressStyle = {
        width: 33,
        height: 3,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: 'solid 1px ' + color
      };

    return (
      <div className="separation-plates-progress">
        <div className="separation-plates-progress-text">
          {finishedPlates}/{platesCount}
        </div>
        <div className="separation-plates-progress-progress">
          <Progressbar
            max={platesCount}
            current={finishedPlates}
            style={progressStyle}
            color={color}
          />
        </div>
      </div>
    );
  }
}

SeparationPlatesProgress.propTypes = {
  definition: PropTypes.object,
  separation: PropTypes.object,
  plates: PropTypes.array,
  color: PropTypes.string,
};