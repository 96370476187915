const COLORS = {
  COMPLETED: '#85ec85',
  WAITING_FOR_APPROVAL: '#5eced5',
  ERROR: '#ff4437',
  WARNING_IN_PREFLIGHT: '#ff7f50', // coral
};

export default {
  "background-progress": function (value, model, pairModel) {
    if (typeof pairModel !== 'undefined') {
      if (this.isCompleted(model) && this.isCompleted(pairModel)) {
        return COLORS.COMPLETED;
      }

      if (
        this.isError(model) || this.isRejectedItem(model) ||
        this.isError(pairModel) || this.isRejectedItem(pairModel)
      ) {
        return COLORS.ERROR;
      }

      if (this.isWarningInPreflight(model)) {
        return COLORS.WARNING_IN_PREFLIGHT;
      }

      if (this.isWaitingForApproval(model)) {
        return COLORS.WAITING_FOR_APPROVAL;
      }
    }
    else {
      if (this.isCompleted(model)) {
        return COLORS.COMPLETED;
      }

      if (this.isError(model) || this.isRejectedItem(model)) {
        return COLORS.ERROR;
      }

      if (this.isWarningInPreflight(model)) {
        return COLORS.WARNING_IN_PREFLIGHT;
      }
      
      if (this.isWaitingForApproval(model)) {
        return COLORS.WAITING_FOR_APPROVAL;
      }
    }
    return undefined;
  }
};