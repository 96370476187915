import React from 'react';
import { createRoot } from 'react-dom/client';
import { moveItem } from 'utilities/array';
import sandbox from 'sandbox';
import jsutils from 'base/jsUtils';
import AbstractModule from 'AbstractModule';
import columnDefinition from './columnDefinition';
import ProcessArray from './processarray';
import sorters from './sorters';
import Counters from './counters';
import View from './view';
import { restGet } from 'core/managers/rest2';
import pubsub from 'core/managers/pubsub';
import toastService from 'core/services/toastService';
import { getMainStatus } from 'utilities/statuses';
import TickableModel from '../TickableModel';

var { translate } = sandbox.localization;

const THROTTLE_WAIT = 1000;

var VIEW_MODEL_TYPE = {
  'pages': 'page',
  'pageSeparations': 'page/separation',
  'forms': 'form',
  'formSeparations': 'form/separation',
  'plates': 'plate'
};
var VIEW_MODEL_LABELS = {
  'pages': translate('Pages'),
  'pageSeparations': translate('Page Separations'),
  'forms': translate('Formes'),
  'formSeparations': translate('Forme Separations'),
  'plates': translate('Plates')
};
var MODELS_WITHOUT_SORTING_VALUE = [
  'page', 'form', 'plate', 'page/separation', 'form/separation'
];
var VIEW_TYPES_SORTING_TOKENS = {
  'page': ['alpha', 'sortingValue'],
  'page/separation': ['alpha', 'sortingValue'],
  'form': ['alpha', 'sortingValue'],
  'form/separation': ['alpha', 'sortingValue'],
  'plate': ['alpha', 'sortingValue']
};
var defaultFilters = {
  finished: isNotVirtualFilterWraper(isFinishedFilter),
  hold: isNotVirtualFilterWraper(isHoldFilter),
  missing: isNotVirtualFilterWraper(isMissingFilter),
  error: isNotVirtualFilterWraper(isErrorFilter),
  waiting_for_approval: isNotVirtualFilterWraper(isWaitingForApprovalFilter),
  reject: isNotVirtualFilterWraper(isRejectFilter),
  local: isNotVirtualFilterWraper(isLocalItemFilter),
  not_virtual: isNotVirtual,
  local_missing: isNotVirtualFilterWraper(isLocalFilterWrapper(isMissingFilter)),
  local_error: isNotVirtualFilterWraper(isLocalFilterWrapper(isErrorFilter)),
  local_waiting_for_approval: isNotVirtualFilterWraper(isLocalFilterWrapper(isWaitingForApprovalFilter))
};
var separationsFilters = {
  missing: isNotVirtualFilterWraper(isMissingFilter),
  error: isNotVirtualFilterWraper(isErrorFilter),
  waiting_for_approval: isNotVirtualFilterWraper(isWaitingForApprovalFilter),
  local: isNotVirtualFilterWraper(isLocalSeparationFilter),
  local_missing: isNotVirtualFilterWraper(isLocalSeparationFilterWrapper(isMissingFilter)),
  local_error: isNotVirtualFilterWraper(isLocalSeparationFilterWrapper(isErrorFilter)),
  local_waiting_for_approval: isNotVirtualFilterWraper(isLocalSeparationFilterWrapper(isWaitingForApprovalFilter))
};
var platesFilters = {
  missing: isMissingFilter,
  error: isErrorFilter,
  waiting_for_approval: isWaitingForApprovalFilter,
  actual: isActualFilter,
  actual_missing: isActualFilterWrapper(isMissingFilter),
  actual_error: isActualFilterWrapper(isErrorFilter),
  actual_waiting_for_approval: isActualFilterWrapper(isWaitingForApprovalFilter)
};
var filtersByViewType = {
  'pages': defaultFilters,
  'pageSeparations': separationsFilters,
  'forms': defaultFilters,
  'formSeparations': separationsFilters,
  'plates': platesFilters
};
var translate = sandbox.localization.translate;

function findParentByType(element, typeOfParent) {
  if (!element) return undefined;

  if (element.type === typeOfParent) return element;

  return findParentByType(element.__parent, typeOfParent);
}

function isNotVirtualFilterWraper(filter) {
  return function (item) {
    return isNotVirtual(item) && filter(item);
  };
}

function isLocalFilterWrapper(filter) {
  return function (item) {
    return isLocalItemFilter(item) && filter(item);
  };
}

function isActualFilterWrapper(filter) {
  return function (item) {
    return isActualFilter(item) && filter(item);
  };
}

function isLocalSeparationFilterWrapper(filter) {
  return function (item) {
    return isLocalSeparationFilter(item) && filter(item);
  };
}

function isFinishedFilter(item) {
  var status = getMainStatus(item);
  if (status) {
    if (status.statusType === 'Success' && status.progress === '100') {
      return true;
    }
  }
  return false;
}

function isHoldFilter(item) {
  if (typeof item.holdType === 'undefined' || item.holdType === 'none') {
    return false;
  }
  return true;
}

function isMissingFilter(item) {
  var status = getMainStatus(item);
  if (status) {
    var percentage = status.progress;
    if (percentage > -1) {
      return false;
    }
  }
  return true;
}

function isErrorFilter(item) {
  var status = getMainStatus(item);
  if (status) {
    var statusType = status.statusType;
    if (statusType === 'Error') {
      var type = status.flowStepType;
      if (type !== 'workflow/step/flow/approval') {
        return true;
      }
    }
  }
  return false;
}

function isWaitingForApprovalFilter(item) {
  var status = getMainStatus(item);
  if (status) {
    var type = status.flowStepType;
    if (type === 'workflow/step/flow/approval') {
      var statusType = status.statusType;
      if (statusType === 'Waiting') {
        return true;
      }
    }
  }
  return false;
}

function isRejectFilter(item) {
  var status = getMainStatus(item);
  if (status) {
    var statusType = status.statusType;
    if (statusType === 'Error') {
      var type = status.flowStepType;
      if (type === 'workflow/step/flow/approval') {
        return true;
      }
    }
  }
  return false;
}

function isLocalSeparationFilter(sep) {
  return sep.local && (typeof sep.baseSeparation === 'undefined' || sep.baseSeparation === null);
}

function isLocalItemFilter(item) {
  var separations = item.separations;
  var localSeparations = separations.filter(function (sep) {
    return isLocalSeparationFilter(sep);
  });
  return (localSeparations.length > 0);
}

function isActualFilter(item) {
  return typeof item.isActual !== 'undefined' && item.isActual === true;
}

function getComparator(viewType, columnKey, sortOrder) {
  var viewModelType = VIEW_MODEL_TYPE[viewType],
    sortingType = VIEW_TYPES_SORTING_TOKENS[viewModelType][0],
    propertyName = VIEW_TYPES_SORTING_TOKENS[viewModelType][1];

  if (typeof columnKey !== 'undefined' && typeof sortOrder !== 'undefined') {
    return sorters[columnKey](viewType, sortOrder);
  }

  return sandbox.sorting.getComparator(sortingType, propertyName);
}

function isNotVirtual(model) {
  if (model.type === 'page/separation') return model.__parent.__parent.virtual !== true;
  if (model.type === 'form/separation') return model.__parent.__parent.virtual !== true;
  return model.virtual !== true;
}

function isVirtual(model) {
  return model.virtual === true;
}

function getViwModelType(viewType) {
  return VIEW_MODEL_TYPE[viewType];
}

function isViewModelType(model, viewType) {
  return typeof model !== 'undefined' &&
    model.type !== undefined &&
    VIEW_MODEL_TYPE[viewType] !== undefined &&
    model.type === VIEW_MODEL_TYPE[viewType];
}

function shouldAssignSortingValue(model) {
  var modelType = model.type;

  return MODELS_WITHOUT_SORTING_VALUE.indexOf(modelType) >= 0;
}

function stringPadding(str, len, paddChr, side) {
  if (str.length >= len) {
    return str;
  }
  var ret = "";
  for (var i = str.length; i <= len; i++) {
    ret += paddChr;
  }
  if (side === "left") {
    ret += str;
  } else {
    ret = +str;
  }
  return ret;
}

function assignPlateSortingValue(plate, productionRun) {
  var formSeparationName, sortingValue;

  if (plate.groupPlateType === 'Image') {
    formSeparationName = plate.formSeparationName;
    sortingValue = [
      productionRun.name,
      plate.bookName,
      stringPadding(plate.form, 10, "0", "left"),
      plate.towerName || '',
      plate.cylinderName || '',
      plate.locationOnCylinder || '',
      plate.cylinderSector || '',
      formSeparationName || ''
    ].join('').replace(new RegExp(' ', 'g'), '');
  } else {
    formSeparationName = 'Blind';
    sortingValue = [
      productionRun.name,
      plate.bookName,
      stringPadding('', 10, '9', 'left'),
      plate.towerName || '',
      plate.cylinderName || '',
      plate.locationOnCylinder || '',
      plate.cylinderSector || ''
    ].join('').replace(new RegExp(' ', 'g'), '');
  }

  plate.formSeparationName = formSeparationName;
  plate.sortingValue = sortingValue;
}

function assignModelSortingValue(model) {
  var physicalNumber = model.physicalNumber;
  var name = model.name;
  model.sortingValue = physicalNumber;
}

function assignSeparationSortingValue(separation) {
  var physicalNumber = separation.parent.physicalNumber;
  var parentName = separation.parent.pageName || separation.parent.formName;
  separation.physicalNumber = physicalNumber;
  separation.sortingValue = parseFloat(physicalNumber + '.' + sandbox.sorting.getColorSortingNumericRepresentation(separation.separationContent.colorName));
}

function assignSortingValueToModel(model) {
  var modelType = model.type;

  if (modelType === 'form' || modelType === 'page') assignModelSortingValue(model);
  if (modelType === 'form/separation' || modelType === 'page/separation') assignSeparationSortingValue(model);
  if (modelType === 'plate') assignPlateSortingValue(model, model.__parent.__parent);
}

function addRowToSortedTable(tableRows, row, comparator) {
  var itemsToAdd = Array.isArray(row) ? row : [row];

  itemsToAdd.forEach(function (itemToAdd) {
    tableRows.set(itemToAdd);
  });
}

function removeRowFromTableById(tableRows, id) {

  tableRows.remove(id);
}

function orderedColumns(columns, preferences, rows, sortColumnKey, sortOrder) {
  const columnsKeyOrder = preferences.columnsKeyOrder;

  if (!Array.isArray(columnsKeyOrder) || columnsKeyOrder.length === 0) return columns;

  return columns.map(column => {
    const genratedColumn = column(rows, sortColumnKey, sortOrder);
    const index = columnsKeyOrder.indexOf(genratedColumn.columnKey);

    return {
      columnGetter: column,
      column: genratedColumn,
      index: index === -1 ? Number.MAX_VALUE : index
    };
  }).sort((a, b) => {
    if (a.index < b.index)
      return -1;
    if (a.index > b.index)
      return 1;
    return 0;
  }).map(column => column.columnGetter);
}

export default AbstractModule.extend({
  allowStandbyMode: true,
  init: function () {
    var that = this;
    this._super();
    this.viewType = null;
    this.columns = undefined;
    this.columnKey = undefined;
    this.tableRows = new ProcessArray();
    this.tableSelectedRows = [];
    this.preferences = {};
    this.filterDefinitions = {};
    this.filter = undefined;
    this.sortColumnKey = undefined;
    this.sortOrder = undefined;
    this.counters = this.initCounters();

    window.tableRows = this.tableRows;

    this.tickableModel = new TickableModel({
      markDirty: true,
      onAction: function (action) {
        if (action === 'remove') that.handleTickRemoveAction.apply(that, arguments);
        if (action === 'update') that.handleTickUpdateAction.apply(that, arguments);
        if (action === 'add') that.handleTickAddAction.apply(that, arguments);
      },
      onObjectParsed: function (model) {
        if (isViewModelType(model, this.viewType)) {// && !isVirtual(model)) {
          if (shouldAssignSortingValue(model)) {
            assignSortingValueToModel(model);
          }

          addRowToSortedTable(
            this.tableRows,
            model,
            getComparator(this.viewType)
          );

          this.counters.setItem(model.id, model);
        }
      }.bind(this)
    });

    this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    this.handleDoubleClickRow = this.handleDoubleClickRow.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleColumnClick = this.handleColumnClick.bind(this);
    this.handleConextMenu = this.handleConextMenu.bind(this);
    this.handleColumnFilter = this.handleColumnFilter.bind(this);
    this.handleColumnOrder = this.handleColumnOrder.bind(this);
    this.handleColumnResize = this.handleColumnResize.bind(this);
    this.handleTableViewKeyDown = this.handleTableViewKeyDown.bind(this);

  },

  initDone: function () {
    this.changeDeadlineAction = this.viewActions.find(viewAction => viewAction.actionClass === 'ChangeDeadlineAction');
    this.reactRoot = createRoot(this.domElement);
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  showFooter: function (model) {
    const { planImportFileName, planImportTime } = model;
    if (planImportFileName && planImportTime) {
      const footer = this.createFooter();

      footer.addPlanImportInfo(planImportFileName, planImportTime);
    }
  },

  firstTickReceived: function (data) {
    var model = data.model;
    var columns = columnDefinition(model.viewType);
    this.viewType = model.viewType;
    this.preferences = data.preferences || {};
    this.tableRows = new ProcessArray({
      defaultFilter: isNotVirtual
    });
    this.tableRows.sort(getComparator(this.viewType));
    this.tickableModel.firstTickHandler(model);
    this.columns = orderedColumns(columns.columns, this.preferences, this.tableRows, this.sortColumnKey, this.sortOrder);
    this.columnKey = columns.columnKey;
    this.filterDefinitions = filtersByViewType[this.viewType] || {};
    this.allowDrop = this.viewType === 'pages';

    restGet(this.nwid, 'conversion-tables/Approvers/data')
      .then(approversData => this.approvers = approversData);

    this.toolbar = this.createToolbar();
    this.showFooter(model);
    // The line below is here in order to remove the old property "pushedButton" from the preferences since now we use "filters"
    delete this.preferences.pushedButton;

    this.preferences.filters = this.preferences.filters || [];

    if (typeof this.filterDefinitions.missing === 'function') {
      this.toolbar.addItem({
        label: 'Missing Pages',
        name: 'missing',
        checked: this.preferences.filters.some(f => f === 'missing'),
        _isApplicable: true,
        icon: 'missing_pages',
        tooltip: translate('Show Missing'),
        itemType: 'push',
        groupName: "pageState",
        execute: this.buttonClick.bind(this)
      });
    }

    if (typeof this.filterDefinitions.error === 'function') {
      this.toolbar.addItem({
        label: 'Error Pages',
        name: 'error',
        checked: this.preferences.filters.some(f => f === 'error'),
        _isApplicable: true,
        icon: 'error_pages',
        tooltip: translate('Show Error'),
        itemType: 'push',
        groupName: "pageState",
        execute: this.buttonClick.bind(this)
      });
    }

    if (typeof this.filterDefinitions.waiting_for_approval === 'function') {
      this.toolbar.addItem({
        label: 'Waiting For Approval',
        name: 'waiting_for_approval',
        checked: this.preferences.filters.some(f => f === 'waiting_for_approval'),
        _isApplicable: true,
        icon: 'waiting_for_approval',
        tooltip: translate('Show Waiting For Approval'),
        itemType: 'push',
        groupName: "pageState",
        execute: this.buttonClick.bind(this)
      });
    }

    if (typeof this.filterDefinitions.local === 'function') {
      this.toolbar.addItem({
        label: 'Local Pages',
        name: 'local',
        checked: this.preferences.filters.some(f => f === 'local'),
        _isApplicable: true,
        icon: 'local_pages',
        tooltip: translate('Show Local Pages Only'),
        itemType: 'push',
        execute: this.buttonClick.bind(this)
      });
    }

    if (typeof this.filterDefinitions.actual === 'function') {
      this.toolbar.addItem({
        label: 'Expected',
        name: 'actual',
        checked: this.preferences.filters.some(f => f === 'actual'),
        _isApplicable: true,
        icon: 'actual_plates',
        tooltip: translate('Show Expected Plates Only'),
        itemType: 'push',
        execute: this.buttonClick.bind(this)
      });
    }

    this.preferences.columns = this.preferences.columns || {};

    this.preferences.filters.forEach(filter => {
      this.applyFilter(true, filter);
    });
    this.render();
  },

  buttonClick: function (apply, buttonName) {
    this.applyFilter(apply, buttonName);
    this.render();

    this.savePreferences({ filters: this.toolbar.items.filter(item => item.checked).map(item => item.name) });
  },

  applyFilter: function (apply, buttonName) {
    if (!apply) {
      const activeFilter = this.toolbar.items.find(item => item.checked);
      this.filter = activeFilter ? this.filterDefinitions[activeFilter.name] : undefined;
    } else {
      const activeFilters = this.toolbar.items.filter(item => item.checked);
      if (activeFilters.length === 1) {
        this.filter = this.filterDefinitions[buttonName];
      } else {
        const additionalFilter = activeFilters.find(item => item.name !== 'local' && item.name !== 'actual');
        this.filter = activeFilters.some(item => item.name === 'local') ? this.filterDefinitions[`local_${additionalFilter.name}`] : this.filterDefinitions[`actual_${additionalFilter.name}`];
      };
    };

    this.tableRows.setFilter(this.filter);
    this.tableRows.filter();
    this.counters.setFilter(this.filter);
    this.counters.filter();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.preferences);
  },


  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.render();
  },

  render: function () {

    this.reactRoot.render(<View
      tableName={VIEW_MODEL_LABELS[this.viewType]}
      preferences={this.preferences}
      columns={this.columns}
      columnKey={this.columnKey}
      rows={this.tableRows}
      selectedRows={this.tableSelectedRows}
      viewType={this.viewType}
      rootType={this.tickableModel._model.type}
      sortColumnKey={this.sortColumnKey}
      sortOrder={this.sortOrder}
      allowDrop={this.allowDrop}
      counters={this.counters.getCounters()}
      filter={this.filter}
      deadline={this.tickableModel._model.deadline}
      handleSelectionChanged={this.handleSelectionChanged}
      handleDoubleClickRow={this.handleDoubleClickRow}
      handleDrop={this.handleDrop}
      handleColumnClick={this.handleColumnClick}
      handleConextMenu={this.handleConextMenu}
      handleColumnFilter={this.handleColumnFilter}
      handleColumnOrder={this.handleColumnOrder}
      handleColumnResize={this.handleColumnResize}
      handleTableViewKeyDown={this.handleTableViewKeyDown}
      handleChangeDeadlineAction={typeof this.changeDeadlineAction !== 'undefined' ? () => {
        this.changeDeadlineAction.execute(this.tickableModel._model);
      } : undefined}
    />);

    setTimeout(() => this.tickableModel.clean(this.tickableModel.model()), 0);
  },

  handleTickRemoveAction: function (action, parent, model) {
    if (!isViewModelType(model, this.viewType)) return;

    this.tableRows.remove(model.id);
    this.counters.removeItem(model.id);
  },

  handleTickUpdateAction: function (action, newModel) {
    var item = isViewModelType(newModel, this.viewType) ? newModel :
      findParentByType(newModel, getViwModelType(this.viewType));

    if (typeof item === 'undefined') return;

    if (this.tableRows.hasId(newModel.id) && isVirtual(newModel)) {
      this.handleChangedToVirtual(newModel);
    }

    this.tableRows.set(item);
    this.counters.setItem(item.id, item);

  },

  handleTickAddAction: function (action, parent, newModel) {
    if (isViewModelType(parent, this.viewType)) {
      this.tableRows.set(parent);
      this.counters.setItem(parent.id, parent);
    }
    else {
      var tableRowModel = findParentByType(newModel, getViwModelType(this.viewType));
      if (typeof tableRowModel !== 'undefined') {
        this.tableRows.set(tableRowModel);
        this.counters.setItem(tableRowModel.id, tableRowModel);
      }
    }
  },

  initCounters: function () {
    var counters = new Counters();

    counters.createCounter('total', translate('Total'), defaultFilters.not_virtual, sandbox.icons.getGeneralIcon('status', 'total'));
    counters.createCounter('finished', translate('Finished'), defaultFilters.finished, sandbox.icons.getGeneralIcon('status', 'finished'));
    counters.createCounter('error', translate('Error'), defaultFilters.error, sandbox.icons.getGeneralIcon('status', 'error'));
    counters.createCounter('hold', translate('Hold'), defaultFilters.hold, sandbox.icons.getGeneralIcon('status', 'hold'));
    counters.createCounter('wait', translate('Waiting For Approval'), defaultFilters.waiting_for_approval, sandbox.icons.getGeneralIcon('status', 'wait'));
    counters.createCounter('reject', translate('Reject'), defaultFilters.reject, sandbox.icons.getGeneralIcon('status', 'reject'));

    return counters;
  },

  handleChangedToVirtual: function (model) {
    var selectedIndex = this.selected.indexOf(model);

    if (selectedIndex >= 0) this.selected.splice(selectedIndex, 1);
    this.tableSelectedRows = this.tableSelectedRows.filter(function (row) {
      return row.id !== model.id;
    });
  },

  handleSelectionChanged: function (rows) {
    this.updateSelected(rows);

    this.tableSelectedRows = rows;
  },

  handleDoubleClickRow: function (rowIndex, rowContent, ev) {
    this.navigateByViewLink(rowContent);
  },

  handleDrop: function (rowIndex, rowContent, ev) {
    var action = this.viewActions.find(a => a.actionDefinitionName === 'MapUnplannedPageActionCR');
    if (typeof action === 'undefined') return;

    const unplannedPages = JSON.parse(ev.dataTransfer.getData('Text'));
    pubsub.publish('drop-on-page', { unplannedPages, success: true });

    action.execute(rowContent, unplannedPages).then(result => {
      if (!result.data.success) {
        pubsub.publish('drop-on-page', { unplannedPages, success: false });
        toastService.errorToast(result.data.subject, result.data.message);
      };
    });
  },

  handleColumnClick: function (columnKey) {

    if (columnKey === this.sortColumnKey) {
      if (this.sortOrder === 'asc') this.sortOrder = 'desc';
      else if (this.sortOrder === 'desc') this.sortOrder = undefined;
      else this.sortOrder = 'asc';
    }
    else {
      this.sortOrder = 'asc';
    }
    this.sortColumnKey = columnKey;

    this.tableRows.sort(getComparator(this.viewType, this.sortColumnKey, this.sortOrder));

    this.render();
  },

  handleConextMenu: function (rowContent, selectedRows, ev) {
    this.showContextMenu.apply(this, [rowContent, selectedRows, ev]);
  },

  handleColumnFilter: function (columnKey, isVisible, columns) {
    this.savePreferences({ columns });
  },

  handleColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.columns, oldIndex, newIndex);

    this.savePreferences({ columnsKeyOrder: columns });

    this.render();
  },

  handleColumnResize: function (columns) {
    this.savePreferences({ columns });
  },

  handleTableViewKeyDown: function (ev) {
    //CTRL + C
    if (ev.keyCode === 67 && (ev.metaKey || ev.ctrlKey)) {
      let copyAction = this.viewActions.find(viewAction => viewAction.actionClass === 'CopyAction');
      if (copyAction && copyAction.isApplicable(this.selected)) {
        copyAction.execute(this.selected);
      }
    }
    //CTRL + V
    if (ev.keyCode === 86 && (ev.metaKey || ev.ctrlKey)) {
      let pasteAction = this.viewActions.find(viewAction => viewAction.actionClass === 'PasteAction');
      if (pasteAction && pasteAction.isApplicable(this.selected)) {
        pasteAction.execute(this.selected);
      }
    }
  }

});