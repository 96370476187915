/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 */


define(['ember', 'text!../templates/resources.hbs'], function (Ember, ResourcesTemplate) {
  'use strict';
  return Ember.View.extend({
    template: Ember.Handlebars.compile(ResourcesTemplate),
    ProgressView: Ember.View.extend({
      classNames: ['right'],
      progress: function () {
        var progress = this.get('content.progress');
        if (!isNaN(progress)) {
          return 'height: ' + (100 - this.get('content.progress')) + '%';
        }
        else {
          return 'height: 100%';
        }
      }.property('content.progress')
    })
  });
});