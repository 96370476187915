import PropTypes from 'prop-types';
import React from 'react';
import CanvasComponent from './canvasComponent'
import { deg2rad, StageUtilities } from './utilities';

const isUndefined = o => typeof o === 'undefined';
const isNull = o => o === null;

export default class Stage extends CanvasComponent {
  static propTypes = {
    rotation: PropTypes.number,
    zoom: PropTypes.number,
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    flipHorizontal: PropTypes.bool,
    flipVertical: PropTypes.bool,
    canvasDraw: PropTypes.func
  };

  static defaultProps = {
    rotation: 0,
    zoom: 1,
    point: { x: 0, y: 0 },
    flipHorizontal: false,
    flipVertical: false
  };

  childInstances = [];

  stageUtilities = new StageUtilities();

  constructor(props) {
    const { point, rotation, zoom, flipHorizontal, flipVertical } = props;
    super(props);

    this.stageUtilities = this.stageUtilities
      .setOffset(point.x, point.y)
      .setRotation(rotation)
      .setZoom(zoom)
      .setFlipHorizontal(flipHorizontal)
      .setFlipVertical(flipVertical);
  }

  subscribeToStage = (child) => {
    this.childInstances = this.childInstances.concat(child);

    return () => {
      this.childInstances = this.childInstances.filter(childInstance => childInstance !== child);
    }
  };

  draw = (ctx, canvas) => {
    if (isUndefined(canvas)) return;

    const { rotation, zoom, point, flipHorizontal, flipVertical } = this.props;
    const flipHorizontalValue = flipHorizontal ? -1 : 1;
    const flipVerticalValue = flipVertical ? -1 : 1;
    const canvasCenterPoint = {
      x: canvas.width / 2,
      y: canvas.height / 2
    };

    this.childInstances.forEach(child => {
      ctx.save();

      //rotation
      ctx.translate(canvasCenterPoint.x + point.x, canvasCenterPoint.y + point.y);
      ctx.rotate(deg2rad(rotation));
      //offset
      ctx.translate(child.props.point.x * zoom * flipHorizontalValue, child.props.point.y * zoom * flipVerticalValue);
      //zoom
      ctx.scale(zoom * flipHorizontalValue, zoom * flipVerticalValue);

      child.draw(ctx, canvas);

      ctx.restore();
    });
  };

  componentDidUpdate(...args) {
    const { point, rotation, zoom, flipHorizontal, flipVertical } = this.props;

    this.stageUtilities = this.stageUtilities
      .setOffset(point.x, point.y)
      .setRotation(rotation)
      .setZoom(zoom)
      .setFlipHorizontal(flipHorizontal)
      .setFlipVertical(flipVertical);

    super.componentDidUpdate(...args);
  }

  render() {
    const { canvasDraw, children } = this.props;

    return React.Children.map(children, child => isUndefined(child) || isNull(child) ? child : React.cloneElement(child, { subscribe: this.subscribeToStage, canvasDraw: this.props.canvasDraw }));
  }
}