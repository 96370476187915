import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

const isUndefined = o => typeof o === 'undefined';

export default class SkipStepIndicator extends React.Component {
  static propTypes = {
    skipSteps: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    skipSteps: undefined,
    title: ''
  };

  getSrcByHoldType = (holdType) => {
    if (isUndefined(holdTypesImageName[holdType])) return undefined;

    return sandbox.icons.getModuleIcon('MyBirdeye', holdTypesImageName[holdType], '.svg');
  };

  render() {
    var skipSteps = this.props.skipSteps,
      src = !!skipSteps ? sandbox.icons.getModuleIcon('MyBirdeye', 'SkipFlowstep', '.svg') : undefined,
      title = this.props.title;

    return isUndefined(src) ? <span></span> : <img src={src} title={title} />;
  }
}