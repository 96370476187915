import React from 'react';
import { createRoot } from 'react-dom/client';
import { translate } from 'core/services/localization';
import TickableModel from 'modules/TickableModel';
import appPromises from 'core/managers/appPromises';
import viewManager from 'core/managers/views';
import tickFacade from 'core/facades/tick';
import idWorker from 'core/workers/idWorker';
import settingsManager from 'core/managers/settings';

export default class License {
  constructor(hide, show, element) {
    this.hide = hide;
    this.show = show;
    this.domElement = element[0];

    this.subscribeToPromises();
  }

  subscribeToPromises() {
    appPromises.getPromise(appPromises.states.LOGGED_IN).then(res => {
      const viewLink = res.serviceLinks['ServerServiceCR'];
      this.licenseServiceLink = viewManager.getViewInfo(viewLink);
    });

    appPromises.getPromise(appPromises.states.CHANNEL_OPENED).then(() => {
      this.start();
    });
  }

  start() {
    if (typeof this.licenseServiceLink === 'undefined') {
      log.error("License.start(): instance not found, couldn't start Service");
      return;
    }

    this.nwid = this.licenseServiceLink.nwid;
    this.id = idWorker.generateId();

    const requestParams = {
      rootId: settingsManager.get('user').nwid
    };

    tickFacade.addProjector(this, this.firstTickReceived, this.tickUpdate, undefined, requestParams);
  }

  firstTickReceived(data) {
    // console.log('### License.firstTickReceived())');
    this.tickableModel = new TickableModel();
    this.tickableModel.firstTickHandler(data.model);
    this.reactRoot = createRoot(this.domElement);
    this.render();
  }

  tickUpdate(data) {
    // console.log('### License.tickUpdate())');
    this.tickableModel.tickUpdateHandler(data.model);

    this.render();
  }

  render() {
    const model = this.tickableModel.model();
    const {
      licenseInGracePeriod,
      licenseExpiring,
      unlicensedFolderCount = 0,
      unlicensedSiteCount = 0,
      subscriptionValidationExpiring,
    } = model;

    if (!licenseInGracePeriod && !licenseExpiring && unlicensedFolderCount <= 0 && unlicensedSiteCount <= 0 && !subscriptionValidationExpiring) {
      this.hide();
      return;
    }

    this.show();

    const {
      licenseGraceDays = 0,
      licenseGraceHours = 0,
      licenseType = '',
      licenseExpireDays = 0,
      licenseExpireHours = 0,
      subscriptionValidationExpireDays = 0,
      subscriptionValidationExpireHours = 0
    } = model;

    const graceMessage = translate('The application is running in grace mode. The license will expire in {1} days and {2} hours!',
      licenseGraceDays, licenseGraceHours);

    const expireMessage = translate('Your {1} license will expire in {2} days and {3} hours.',
      licenseType, licenseExpireDays, licenseExpireHours);

    const unlicensedFolderCountMessage = translate('You have {1} unlicensed folders.',
      unlicensedFolderCount);

    const unlicensedSiteCountMessage = translate('You have {1} unlicensed sites.',
      unlicensedSiteCount);

    const subscriptionValidationExpiringMessage = translate('You must connect your ELMS license tool to the internet or your license will stop working in {1} days and {2} hours.',
      subscriptionValidationExpireDays, subscriptionValidationExpireHours);

    this.reactRoot.render(
      <div className='license'>
        <div className='license-message'>
          {licenseInGracePeriod && graceMessage}
          {licenseExpiring && expireMessage}
          {unlicensedFolderCount > 0 && unlicensedFolderCountMessage + ' '}
          {unlicensedSiteCount > 0 && unlicensedSiteCountMessage}
          {subscriptionValidationExpiring && subscriptionValidationExpiringMessage}
        </div>
      </div>);
  }
}
