define(['react', 'prop-types', 'widgets/ReactDataGrid/src/ReactDataGrid', 'sandbox'], function (React, PropTypes, ReactDataGrid, sandbox) {

  const { Component } = React;
  var { Table, headers, Column } = ReactDataGrid;
  var { SortableHeader } = ReactDataGrid.headers;

  class Cell extends Component {
    constructor(props) {
      super(props);
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.columnData !== this.props.columnData;
    }

    render() {

      var { columnData } = this.props;

      return <div className="cell">
        <div className="cell-content">
          <div className="cell-content-padding">
            {columnData}
          </div>
        </div>
      </div>;
    }
  }

  class ClickableImageCell extends Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.columnData !== this.props.columnData;
    }

    handleClick() {
      var { rowIndex, onClick } = this.props;
      onClick(this.props.rowContent.sessionId);
    }

    render() {
      var { columnData } = this.props;
      var cellStyle = {
        width: '100%',
        height: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      };

      return <div style={cellStyle} onClick={this.handleClick}>
        <div className="cell-content">
          <div className="wraptocenter"><img className="disconnect" src={sandbox.icons.getGeneralIcon(null, "delete")} /></div>
        </div>
      </div>;
    }
  }

  return class SessionView extends Component {
    constructor(props) {
      super(props);
      this.onDisconnect = this.onDisconnect.bind(this);
    }

    onDisconnect(sessionId) {
      this.props.actions.disconnectSession.call(this.props.module, sessionId);
    }

    render() {
      var { model, actions, module } = this.props;
      var headerHeight = 35;
      var rowHeight = 29;

      return <div className="pro-modules-sessionview"><div className="table">
        <Table headerHeight={headerHeight}
          rowHeight={rowHeight}
          rows={model}
          selectableRows={false}
          onRowClick={function (rowIndex, rowContent, ev) { }}
          onRowContextMenu={function (rowIndex, rowContent, ev) { }}
          columnKey='sessionId'
          virtualScroll={false}>

          <Column columnKey="userName"
            width={50}
            align="left"
            autoWidth={true}
            header={<SortableHeader>
              {sandbox.localization.translate("Name")}
            </SortableHeader>}
            cell={<Cell />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: model[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="ip"
            width={50}
            align="left"
            autoWidth={true}
            header={<SortableHeader>
              {sandbox.localization.translate("IP")}
            </SortableHeader>}
            cell={<Cell />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: model[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="loginTime"
            width={50}
            align="left"
            autoWidth={true}
            header={<SortableHeader>
              {sandbox.localization.translate("login Time")}
            </SortableHeader>}
            cell={<Cell />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: model[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="userEmail"
            width={50}
            align="left"
            autoWidth={true}
            header={<SortableHeader>
              {sandbox.localization.translate("E-Mail")}
            </SortableHeader>}
            cell={<Cell />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: model[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="sessionId"
            width={50}
            align="left"
            autoWidth={true}
            header={<SortableHeader>
              {sandbox.localization.translate("Disconnect")}
            </SortableHeader>}
            cell={<ClickableImageCell onClick={this.onDisconnect} />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: model[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />
        </Table>
      </div></div>
    }
  }

});