define(['jquery', 'ember', './../../../utils/wizardUtils', "./../model/model", './../../../utils/wizardCommons'],
  function ($, Ember, wizardUtils, ModelHandler, wizardCommons) {
    'use strict';


    return Ember.ObjectController.extend(wizardCommons.controllerCommons, {
      actions: {
        applyAllAction: function () {
          this.modelHandler.applyAll();
        },
        addForm: function (model) {
          //model.forms.addObject({displayName:'new'});
          this.modelHandler.convertFromStepModel(this.model);
        },
        collaps: function (book) {
          var domobj = $(".clazz" + book.displayName)[0];
          var currentStyle = domobj.style.display;
          if (currentStyle === null || typeof currentStyle === 'undefined' || currentStyle === "") {
            currentStyle = 'inline';
          }
          domobj.style.display = currentStyle === 'inline' ? 'none' : 'inline';
          event.target.textContent = currentStyle === 'inline' ? "+" : "-";

        }
      },

      init: function () {
        this._super();
      },

      selectedZoneChanged: function (model) {
        try {
          this.selectedZone = model;
          //this.selectedEdition = wizardUtils.matchBySourceId(model.parent, Ember.get(this.model, 'editions'));
          //ModelHandler.selectedZone = this.selectedZone;
          //ModelHandler.selectedEdition = this.selectedEdition;
        } catch (err) {
          console.log("Error " + err);
        }

      },

      selectedZone: null,

      selectedEdition: null,

      setDinky: function (page, form) {
        this.modelHandler.updateDinkey.call(this.modelHandler, page, form);
      },

      rebuildForms: function (form) {
        this.modelHandler.rebuildForms.call(this.modelHandler, form);
      },
      propertyChangedListener: function (section, property) {
        console.log("property listener " + section.sourceId + " : " + property);
        this.modelHandler.notifyPropertyChange(section, property, Ember.get(section, property));
      },

      propertyChange: function (section, property, newValue) {
        console.log("property " + section.sourceId + ":" + property + " changed from " + section[property] + " to " + newValue);
        Ember.set(section, property, newValue);
      },

      getLayouts: function (layoutGroup) {
        return this.model.optionalImpositions[layoutGroup] || [];
      },

      getFormLayouts: function (form) {
        if (this.model.pmtMode) {
          var layoutType = form.layoutType;
          return this.model.pmtByType[layoutType];
        }

        if (!form.layoutGroup) {
          return [];
        }

        if (isNaN(form.rows) || isNaN(form.columns)) {
          return this.getLayouts(form.layoutGroup);
        }

        var rows = parseInt(form.rows, 10);
        var columns = parseInt(form.columns, 10);
        if (rows === 0 || columns === 0) {
          return this.getLayouts(form.layoutGroup);
        }

        //console.log("xxxxxxxxxxxxx " + rows + ":" + columns);
        var optionalImpositions = this.getLayouts(form.layoutGroup);
        var ret = [];
        for (var i = 0; i < optionalImpositions.length; i++) {
          var cellGrid = optionalImpositions[i].cellGrid;
          if (rows == cellGrid.rows && columns == cellGrid.columns)
            ret.push(optionalImpositions[i]);
        }
        return ret;
      },

      customFields: function (){
        var ret = [];
        var customFields = Ember.get(this.settings, "customFields") || [];
        customFields.forEach(function (h){
          ret.push(h);
        });
        return ret;
      }.property(),

      isDinkyOption: function (pageModel, layoutType) {
        //var sourceId = pageModel.sourceId;
        //var page = this.mappedModel[sourceId];
        var n = parseInt(pageModel.numberOnBook, 10);
        if (layoutType === '2ups') {
          return (pageModel.isDinky || (pageModel.isValidForDinky && n % 2 > 0));
        } else if (layoutType === '8ups') {
          return pageModel.isValidForDinky;
        }
        //return (n % 2 > 0);
        return true;

      },

      updateModel: function (model) {
        // maybe need to backup model before update
        return this.modelHandler.convertFromStepModel(model);
      },

      zones: function () {
        return this.modelHandler.zones();
      },

      isFormPanorama: function (form) {        
        for (var i = 0; i < form.pages.length; i++) {
          if (form.pages[i].panorama) {
            return true;
          }
        }
        return false;
      },

      matrixLayout: function (form) {
        
      }

    });

  });

